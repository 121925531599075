import { graphql } from "react-relay";

export const revoke_token = graphql`
    mutation RevokeTokenMutation($input: RevokeTokenInput!) {
        revokeToken(input: $input) {
            success
            errors
        }
    }
`;
