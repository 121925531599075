import { graphql } from "react-relay";

export const load_lesson_stages = graphql`
    query LoadLessonStagesQuery(
        $school: String
        $startingYear: Int!
        $block: Int!
        $skip: Boolean!
    ) {
        lessonStages(
            school: $school
            startingYear: $startingYear
            block: $block
            first: 20
        )
            @skip(if: $skip)
            @connection(key: "LoadLessonStages_query_lessonStages") {
            __id
            edges {
                node {
                    id
                    stage
                    lessonDuration
                    lessonType
                    costPerLesson
                }
            }
        }
    }
`;
