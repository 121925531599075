/**
 * @generated SignedSource<<ddf6dbd9a86fdef2e32b148349c5f332>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BulkUploadNewEnrolmentsInput = {
  clientMutationId?: string | null;
  googleDriveNewBulkEnrolmentsSheetUrl: string;
};
export type BulkUploadNewEnrolmentsMutation$variables = {
  input: BulkUploadNewEnrolmentsInput;
};
export type BulkUploadNewEnrolmentsMutation$data = {
  readonly bulkUploadNewEnrolments: {
    readonly errors: any | null;
    readonly success: boolean | null;
  } | null;
};
export type BulkUploadNewEnrolmentsMutation = {
  response: BulkUploadNewEnrolmentsMutation$data;
  variables: BulkUploadNewEnrolmentsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BulkUploadNewEnrolmentsPayload",
    "kind": "LinkedField",
    "name": "bulkUploadNewEnrolments",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BulkUploadNewEnrolmentsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BulkUploadNewEnrolmentsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a71b6fc51d3ba15247bced8bf2460d64",
    "id": null,
    "metadata": {},
    "name": "BulkUploadNewEnrolmentsMutation",
    "operationKind": "mutation",
    "text": "mutation BulkUploadNewEnrolmentsMutation(\n  $input: BulkUploadNewEnrolmentsInput!\n) {\n  bulkUploadNewEnrolments(input: $input) {\n    success\n    errors\n  }\n}\n"
  }
};
})();

(node as any).hash = "a861a8d13653575bc8c7298fc84dfa5e";

export default node;
