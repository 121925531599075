import { graphql } from "react-relay";

export const reset_password = graphql`
    mutation ResetPasswordMutation($input: PasswordResetInput!) {
        passwordReset(input: $input) {
            success
            errors
        }
    }
`;
