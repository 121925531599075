import React from "react";
import type { ComponentProps, ReactElement } from "react";

import emoji from "emoji-dictionary";
import { ChevronDownIcon, ChevronUpIcon, Image, Text } from "native-base";
import type { Icon, IIconProps, ITextProps } from "native-base";

import {
    AccountsIcon,
    DrumsIcon,
    GuitarIcon,
    OneOnOneIcon,
    PairedIcon,
    PianoIcon,
    ViolinIcon,
    InvoiceIcon,
    SchoolIcon,
    RegisterIcon,
    ProfileIcon,
    TimetableIcon,
    CloseIcon,
    BlankIcon,
    BlockIcon,
    DoubleTickIcon,
    TickIcon,
    NoLessonIcon,
    LeftSchoolIcon,
    MusicTheoryIcon,
    RecorderIcon,
    UkeleleIcon,
    SwapIcon,
    GroupIcon,
    WaitingListIcon,
    PayrollIcon,
} from "../components/Other/Icons";
import type { AlertError } from "../types/index";

import { LOGO_CROPPED_IMG } from "pianofunclub-crm/utils/assets";

import { titleCaseConverter } from "./converters";

export const extractConnectionText = (
    connection: any, // eslint-disable-line @typescript-eslint/no-explicit-any
    objectName: string,
): string | undefined => {
    if (Array.isArray(connection?.edges)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return connection.edges.map((x: any) => x.node[objectName]).join(", ");
    }
};

export const mapErrorToAlertText = (errors?: unknown): AlertError => {
    let errorCode;
    let alertHeader = "";
    let alertMessage = "Have another go!";
    // graphql_auth error responses are objects
    if (typeof errors === "object") {
        // @ts-expect-error errorType is unknown
        errorCode = Object.values(errors)?.[0]?.[0]?.code;
        if (!errorCode) {
            // @ts-expect-error errorType is unknown
            errorCode = errors?.nonFieldErrors?.[0]?.code;
        }
        // custom error responses are arrays
    } else if (Array.isArray(errors)) {
        errorCode = errors?.[0]?.code;
    }
    switch (errorCode) {
        case "unique":
            alertHeader = "A user with that email already exists";
            alertMessage = "Have another go with a different email address.";
            break;
        case "invalid_credentials":
            alertHeader = "Those details weren't right...";
            break;
        case "invalid_password":
            alertHeader = "The password you entered is incorrect";
            alertMessage =
                "It must be at least 8 characters long, and contian both letters & numbers.";
            break;
        case "password_mismatch":
            alertHeader = "Those passwords didn't match";
            break;
        case "password_too_short":
            alertHeader = "That password is too short";
            alertMessage = "It must be at least 8 characters long.";
            break;
        case "password_too_similar":
            alertHeader = "Password is too similar to your email";
            break;
        case "password_too_common":
            alertHeader = "Password is too common";
            break;
        case "password_entirely_numeric":
            alertHeader = "Password must contain both letters and numbers";
            break;
        case "email_fail":
            alertHeader = "Sorry, something went wrong on our end...";
            alertMessage =
                "Have another go, and contact support@pianofunclub.co.uk if it's still not working.";
            break;
        case "invalid_token":
            alertHeader = "The link you used is no longer valid";
            alertMessage =
                "Please request a new email and we'll send you an updated link.";
            break;
        case "already_verified":
            alertHeader = "Your email has already been verified";
            alertMessage =
                "Thank you for verifying it! Don't worry about clicking that link anymore.";
            break;
        default:
            alertHeader = "That didn't work...";
    }
    return {
        code: errorCode,
        alertHeader: alertHeader,
        alertMessage: alertMessage,
    };
};

export const getTrueKeys = (obj: Record<string, boolean>): string => {
    return Object.keys(obj)
        .filter((key) => obj[key])
        .join();
};

export const getStartingYear = (): number => {
    const currentDate = new Date();
    if (currentDate.getMonth() < 7) {
        return currentDate.getFullYear() - 1;
    } else {
        return currentDate.getFullYear();
    }
};

export const getStartingYearFromBlock = (
    block?: number | null,
    year?: number | null,
): number | null => {
    if (block && year) {
        if (block > 2) {
            return year - 1;
        } else {
            return year;
        }
    } else {
        return null;
    }
};

export const getDefaultStartingYearAndStartingYearOptions = (): [
    number,
    { label: string; value: number }[],
] => {
    const currentDate = new Date();
    const largestStartingYear =
        currentDate.getMonth() < 5
            ? currentDate.getFullYear() - 1
            : currentDate.getFullYear();
    const startingYearArray = [...Array(largestStartingYear - 2021).keys()].map(
        (i) => i + 2022,
    );
    const defaultStartingYear =
        currentDate.getMonth() >= 5 && currentDate.getMonth() < 7
            ? largestStartingYear - 1
            : largestStartingYear;

    return [
        defaultStartingYear,
        startingYearArray
            .map((item) => {
                return { label: `${item}/${item + 1}`, value: item };
            })
            .reverse(),
    ];
};

export const getCurrentBlock = (): number => {
    const currentMonth = new Date().getMonth();
    if (currentMonth < 2) {
        return 3;
    } else if (currentMonth < 3) {
        return 4;
    } else if (currentMonth < 5) {
        return 5;
    } else if (currentMonth < 7) {
        return 6;
    } else if (currentMonth < 10) {
        return 1;
    } else {
        return 2;
    }
};

export const getCurrentTerm = (): number => {
    const currentMonth = new Date().getMonth();
    if (currentMonth < 3) {
        return 2;
    } else if (currentMonth < 7) {
        return 3;
    } else {
        return 1;
    }
};

export const getYears = (since: number): { label: string; value: number }[] => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const yearArray = [...Array(currentYear - since).keys()].map(
        (i) => i + (since + 1),
    );
    return yearArray.map((item) => {
        return { label: item.toString(), value: item };
    });
};

export const getReduxHomeworkId = (
    term: string,
    lesson: string,
    pupilId: string,
): string => {
    return `homework_${term}_${lesson}_${pupilId}`;
};

export const sortIconExtractor = (
    columnName: string,
    orderBy?: string,
): ReactElement => {
    if (orderBy === columnName) {
        return <ChevronDownIcon color="surface.900" />;
    } else if (orderBy?.replaceAll("-", "") === columnName) {
        return <ChevronUpIcon color="surface.900" />;
    } else {
        return <ChevronDownIcon color="surface.400" />;
    }
};

export const getFullName = (
    firstName?: string | null,
    lastName?: string | null,
): string => {
    return `${firstName ?? ""}${lastName ? " " + lastName : ""}`;
};

export const getPreviousBlockAndStartingYear = (
    block?: number | null,
    startingYear?: number | null,
): (number | null)[] => {
    if (!block || !startingYear) {
        return [null, null];
    }

    if (block == 1) {
        return [6, startingYear - 1];
    } else {
        return [block - 1, startingYear];
    }
};

export const getTermInWords = (
    block?: number | null,
    startingYear?: number | null,
): string => {
    if (!block || !startingYear) {
        return "";
    }
    const half = block % 2 === 1 ? "1st" : "2nd";
    let termName;
    if (block <= 2) {
        termName = "Autumn";
    } else if (block <= 4) {
        termName = "Spring";
    } else {
        termName = "Summer";
    }
    return `${half} Half ${termName} ${
        block <= 2 ? startingYear : Number(startingYear) + 1
    }`;
};

export const getLessonTypeInWords = (
    lessonType: string | undefined | null,
    lessonDuration?: number | null,
): string => {
    if (typeof lessonType !== "string" || typeof lessonDuration !== "number") {
        return "";
    }
    return `${titleCaseConverter(lessonType)} ${lessonDuration} Minutes`;
};

export const getInstrumentIcon = (
    instrumentName?: string | null,
    iconProps?: ComponentProps<typeof Icon>,
): ReactElement | null => {
    switch (instrumentName) {
        case "PIANO":
            return <PianoIcon {...iconProps} />;
        case "GUITAR":
            return <GuitarIcon {...iconProps} />;
        case "VIOLIN":
            return <ViolinIcon {...iconProps} />;
        case "DRUMS":
            return <DrumsIcon {...iconProps} />;
        case "RECORDER":
            return <RecorderIcon {...iconProps} />;
        case "UKELELE":
            return <UkeleleIcon {...iconProps} />;
        case "THEORY":
            return <MusicTheoryIcon {...iconProps} />;
        default:
            return null;
    }
};

export const getLessonTypeIcon = (
    lessonType: string | undefined | null,
    iconProps?: ComponentProps<typeof Icon>,
): ReactElement | null => {
    switch (lessonType) {
        case "INDIVIDUAL":
            return <OneOnOneIcon size={5} {...iconProps} />;
        case "PAIRED":
            return <PairedIcon mr="1" size={6} {...iconProps} />;
        default:
            return <GroupIcon mr="1" size={6} {...iconProps} />;
    }
};

export const getTabIcon = (routeName: string, color: string): ReactElement => {
    switch (routeName) {
        case "AccountsHubTab":
            return (
                <>
                    <Image
                        resizeMode="contain"
                        size="sm"
                        src={LOGO_CROPPED_IMG}
                    />
                    <AccountsIcon color={color} size="md" />
                </>
            );
        case "InvoicingHubTab":
            return <InvoiceIcon color={color} size="md" />;
        case "SchoolsHubTab":
            return <SchoolIcon color={color} size="md" />;
        case "RegistersHubTab":
            return <RegisterIcon color={color} size="lg" />;
        case "TeacherRegistersHubTab":
            return (
                <>
                    <Image
                        resizeMode="contain"
                        size="sm"
                        src={LOGO_CROPPED_IMG}
                    />
                    <RegisterIcon color={color} size="lg" />;
                </>
            );
        case "TeacherTimetableHubTab":
            return <TimetableIcon color={color} size="lg" />;
        case "WaitingListHubTab":
            return <WaitingListIcon color={color} size="lg" />;
        case "PayrollHubTab":
            return <PayrollIcon color={color} size="lg" />;
        default:
            return <ProfileIcon color={color} size="xl" />;
    }
};

export const getLessonStatuses = ({
    blankText,
    iconProps,
    showBlankIcon,
    useShortLabels,
}: {
    blankText?: string;
    iconProps?: IIconProps;
    showBlankIcon?: boolean;
    useShortLabels?: boolean;
}): {
    color: string;
    icon?: ReactElement;
    label: string;
    textColor: string;
    value: string;
}[] => {
    return [
        {
            label: "Present",
            value: "PRESENT",
            icon: <TickIcon color="primary.600" size="7" {...iconProps} />,
            color: "primary.100",
            textColor: "primary.600",
        },
        {
            label: useShortLabels ? "Present (x2)" : "Present (Double)",
            value: "PRESENT_DOUBLE",
            icon: (
                <DoubleTickIcon color="primary.600" size="7" {...iconProps} />
            ),
            color: "primary.100",
            textColor: "primary.600",
        },
        {
            label: "Pupil Absent",
            value: "PUPIL_ABSENT",
            icon: <CloseIcon color="red.500" size="6" {...iconProps} />,
            color: "red.100",
            textColor: "red.500",
        },
        {
            label: "Teacher Absent",
            value: "TEACHER_ABSENT",
            icon: <CloseIcon color="red.500" size="6" {...iconProps} />,
            color: "red.100",
            textColor: "red.500",
        },
        {
            label: "Stopped",
            value: "STOPPED_LESSONS",
            icon: <BlockIcon color="muted.600" size="6" {...iconProps} />,
            color: "muted.200",
            textColor: "muted.500",
        },
        {
            label: "Left School",
            value: "LEFT_SCHOOL",
            icon: <LeftSchoolIcon color="muted.600" size="6" {...iconProps} />,
            color: "muted.200",
            textColor: "muted.500",
        },
        {
            label: "Lesson Missed",
            value: "MISSED",
            icon: <CloseIcon color="red.500" size="6" {...iconProps} />,
            color: "red.100",
            textColor: "red.500",
        },
        {
            label: "No Lesson",
            value: "NO_LESSON",
            icon: <NoLessonIcon color="muted.600" size="6" {...iconProps} />,
            color: "muted.200",
            textColor: "muted.500",
        },
        {
            label: "Other Teacher",
            value: "OTHER_TEACHER",
            icon: <SwapIcon color="muted.600" size="6" {...iconProps} />,
            color: "muted.200",
            textColor: "muted.500",
        },
        {
            label: "Break",
            value: "BREAK",
            icon: <TimetableIcon color="yellow.500" size="6" {...iconProps} />,
            color: "yellow.100",
            textColor: "muted.500",
        },
        {
            label: blankText ?? "Mark Register",
            icon: showBlankIcon ? (
                <BlankIcon color="muted.400" size="6" {...iconProps} />
            ) : undefined,
            value: "BLANK",
            color: "transparent",
            textColor: "muted.400",
        },
    ];
};

export const getAccountTypeEmoji = (
    accountType: string,
    props?: ITextProps,
): ReactElement => {
    switch (accountType) {
        case "PARENT":
            return (
                <Text fontSize="8xl" textAlign="center" {...props}>
                    {emoji.getUnicode("family_woman_girl")}
                </Text>
            );
        case "PUPIL":
            return (
                <Text fontSize="8xl" textAlign="center" {...props}>
                    {emoji.getUnicode("child")}
                </Text>
            );
        case "TEACHER":
            return (
                <Text fontSize="8xl" textAlign="center" {...props}>
                    {emoji.getUnicode("nerd_face")}
                </Text>
            );
        default:
            return (
                <Text fontSize="8xl" textAlign="center" {...props}>
                    {emoji.getUnicode("monocle")}
                </Text>
            );
    }
};

export const getErroredInvoiceLabel = (
    accountNumber: string | null | undefined,
    firstName: string | null | undefined,
    lastName: string | null | undefined,
    email: string | null | undefined,
): string => {
    let label = accountNumber ? accountNumber + " • " : "";
    if (firstName) {
        label += getFullName(firstName, lastName ?? undefined);
    } else {
        label += email ?? "";
    }
    return label;
};

export const getErroredBulkCommunicationLabel = (
    firstName: string | null | undefined,
    lastName: string | null | undefined,
    email: string | null | undefined,
): string => {
    let label = "";
    if (firstName) {
        label += getFullName(firstName, lastName ?? undefined);
        if (email) {
            label += " • ";
        }
    }

    label += email ?? "";

    return label;
};

export const getOrderBy = (column: string, currentOrderBy?: string): string => {
    if (
        currentOrderBy?.replaceAll("-", "") === column &&
        !currentOrderBy.startsWith("-")
    ) {
        return `-${column}`.replaceAll(",", ",-");
    } else {
        return column;
    }
};
