import React, { useReducer, useMemo, useCallback, useRef } from "react";
import type { ReactElement, ComponentProps, Dispatch } from "react";

import { format } from "date-fns";
import * as Linking from "expo-linking";
import {
    Box,
    Center,
    VStack,
    Text,
    HStack,
    Pressable,
    Button,
    AddIcon,
    PresenceTransition,
    MinusIcon,
    Spinner,
    useToast,
} from "native-base";
import { useWindowDimensions } from "react-native";
import { useMutation } from "react-relay";

import type { NavigationProps as AccountNavigationProps } from "../../screens/accounts/AccountScreen";
import type { NavigationProps as InvoiceNavigationProps } from "../../screens/invoicing/InvoiceScreen";
import { FULL_LOGO } from "../../utils/assets";
import CurrencyInput from "pianofunclub-shared/components/Inputs/CurrencyInput";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import {
    CloseIcon,
    PdfIcon,
    RestartIcon,
    SendIcon,
} from "pianofunclub-shared/components/Other/Icons";

import type {
    AddLineItemMutation,
    AddLineItemMutation$data,
} from "pianofunclub-shared/relay/graphql/invoicing/__generated__/AddLineItemMutation.graphql";
import type { LoadInvoiceQuery$data } from "pianofunclub-shared/relay/graphql/invoicing/__generated__/LoadInvoiceQuery.graphql";
import type {
    RemoveLineItemMutation,
    RemoveLineItemMutation$data,
} from "pianofunclub-shared/relay/graphql/invoicing/__generated__/RemoveLineItemMutation.graphql";
import type {
    UpdateLineItemAmountMutation,
    UpdateLineItemAmountMutation$data,
} from "pianofunclub-shared/relay/graphql/invoicing/__generated__/UpdateLineItemAmountMutation.graphql";
import { add_line_item } from "pianofunclub-shared/relay/graphql/invoicing/AddLineItem";
import { remove_line_item } from "pianofunclub-shared/relay/graphql/invoicing/RemoveLineItem";
import { update_line_item_amount } from "pianofunclub-shared/relay/graphql/invoicing/UpdateLineItemAmount";

import {
    titleCaseConverter,
    formatCurrency,
    getLineItemType,
    getScaledWindowDimension,
} from "pianofunclub-shared/utils/converters";
import {
    getTermInWords,
    getInstrumentIcon,
    getPreviousBlockAndStartingYear,
} from "pianofunclub-shared/utils/extractors";
import { createReducer } from "pianofunclub-shared/utils/reducers";
import type { Action, State } from "pianofunclub-shared/utils/reducers";

import type {
    ReducerTypes as InvoiceAndEmailReducerTypes,
    ReducerValues as InvoiceAndEmailReducerValues,
} from "./InvoiceAndEmail";
import InvoiceTableCell from "./InvoiceTableCell";

type VStackProps = ComponentProps<typeof VStack>;

interface Props extends VStackProps {
    dispatchOuterState?: Dispatch<
        Action<InvoiceAndEmailReducerValues, InvoiceAndEmailReducerTypes>
    >;
    generateDraftHandler?: ({
        isUpdate,
        overwriteOtherDraft,
    }: {
        isUpdate?: boolean | undefined;
        overwriteOtherDraft?: boolean | undefined;
    }) => void;
    generateOrUpdateDraftInvoiceInFlight?: boolean;
    invoiceData: LoadInvoiceQuery$data["invoice"];
    isAccountScreen?: boolean;
    isEditable: boolean;
    navigation: InvoiceNavigationProps | AccountNavigationProps;
    outerState?: State<InvoiceAndEmailReducerValues>;
    updateInvoiceStatusInFlight: boolean;
}

type LineItemType = NonNullable<
    NonNullable<LoadInvoiceQuery$data["invoice"]>["lineItems"]
>["edges"][0];

type ReducerValues = {
    activeLineItemIndex?: number;
    showAddLineItemModal: boolean;
};

type ReducerTypes = string | boolean | number | undefined;

const FLEX_ARRAY = [2.1, 2, 1.5, 1.3, 2, 1.1];

const Invoice = (props: Props): ReactElement | null => {
    const {
        dispatchOuterState,
        generateDraftHandler,
        generateOrUpdateDraftInvoiceInFlight,
        invoiceData,
        isAccountScreen,
        isEditable,
        navigation,
        outerState,
        updateInvoiceStatusInFlight,
    } = props;

    const initialState = useMemo(() => {
        return {
            values: {
                showAddLineItemModal: false,
                activeLineItemIndex: undefined,
            },
        };
    }, []);

    const depositInputRefs = useRef(new Map());
    const examFeeInputRefs = useRef(new Map());
    const blockOtherFeeOrDiscountInputRefs = useRef(new Map());
    const accountOtherFeeOrDiscountInputRefs = useRef(new Map());

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [state, dispatchState] = useReducer(reducer, initialState);

    const toast = useToast();

    const lineItemsConnectionId = useMemo(() => {
        return invoiceData?.lineItems.__id;
    }, [invoiceData?.lineItems.__id]);

    const pupilsAndInstruments = useMemo(() => {
        const pupilArray = [
            // remove duplicates
            ...new Set(
                invoiceData?.lessonBlocks.edges.map((item) => {
                    if (item?.node?.pupil) {
                        return {
                            pupilId: item.node.pupil.id,
                            pupilName: item.node.pupil.user.firstName,
                            instrument: item.node.instrument?.name,
                        };
                    }
                }),
            ),
        ];
        // sort by name
        pupilArray.sort((a, b) => {
            if (
                (a?.pupilName?.toUpperCase() ?? "") <
                (b?.pupilName?.toUpperCase() ?? "")
            ) {
                return -1;
            } else {
                return 1;
            }
        });
        return pupilArray;
    }, [invoiceData?.lessonBlocks.edges]);

    const groupedLessonLineItems = useMemo(() => {
        // group lesson line items by pupil, and then by stage and instrument
        // this means that if a pupil has multiple blocks of the same instrument and stage,
        // they show up as one on the invoice
        return (
            invoiceData?.lineItems.edges
                .filter(
                    (item) =>
                        item?.node?.itemType === "LESSONS" &&
                        item?.node?.quantity &&
                        item?.node?.unitAmount,
                )
                .sort((a, b) => {
                    if (
                        (a?.node?.pupilDescription ?? "") <
                        (b?.node?.pupilDescription ?? "")
                    ) {
                        return -1;
                    } else {
                        return 1;
                    }
                })
                .reduce(
                    (acc, item) => {
                        if (item?.node) {
                            const {
                                instrumentDescription,
                                pupilDescription,
                                typeDescription,
                                unitAmount,
                            } = item.node;

                            if (pupilDescription) {
                                if (!acc[pupilDescription]) {
                                    acc[pupilDescription] = {};
                                }

                                const key = `${typeDescription}_${instrumentDescription}_${unitAmount}`;

                                if (!acc[pupilDescription][key]) {
                                    acc[pupilDescription][key] = [];
                                }

                                acc[pupilDescription][key].push(item);
                            }
                        }

                        return acc;
                    },
                    {} as Record<string, Record<string, LineItemType[]>>,
                ) ?? {}
        );
    }, [invoiceData?.lineItems.edges]);

    const groupedMissedLessonDiscountLineItems = useMemo(() => {
        // group lesson line items by pupil, and then by stage and instrument
        // this means that if a pupil has multiple blocks of the same instrument and stage,
        // they show up as one on the invoice
        return (
            invoiceData?.lineItems.edges
                .filter(
                    (item) =>
                        item?.node?.itemType === "MISSED_LESSON_DISCOUNT" &&
                        item?.node?.quantity &&
                        item?.node?.unitAmount,
                )
                .sort((a, b) => {
                    if (
                        (a?.node?.pupilDescription ?? "") <
                        (b?.node?.pupilDescription ?? "")
                    ) {
                        return -1;
                    } else {
                        return 1;
                    }
                })
                .reduce(
                    (acc, item) => {
                        if (item?.node) {
                            const {
                                instrumentDescription,
                                pupilDescription,
                                typeDescription,
                                unitAmount,
                            } = item.node;

                            if (pupilDescription) {
                                if (!acc[pupilDescription]) {
                                    acc[pupilDescription] = {};
                                }

                                const key = `${typeDescription}_${instrumentDescription}_${unitAmount}`;

                                if (!acc[pupilDescription][key]) {
                                    acc[pupilDescription][key] = [];
                                }

                                acc[pupilDescription][key].push(item);
                            }
                        }

                        return acc;
                    },
                    {} as Record<string, Record<string, LineItemType[]>>,
                ) ?? {}
        );
    }, [invoiceData?.lineItems.edges]);

    // no longer displayed on invoices (legacy)
    const instrumentDepositLineItems = useMemo(() => {
        return invoiceData?.lineItems.edges
            .filter(
                (item) =>
                    item?.node?.itemType === "INSTRUMENT_DEPOSIT" &&
                    item?.node?.quantity &&
                    item?.node?.unitAmount,
            )
            .sort((a, b) => {
                if (
                    (a?.node?.pupilDescription ?? "") <
                    (b?.node?.pupilDescription ?? "")
                ) {
                    return -1;
                } else {
                    return 1;
                }
            });
    }, [invoiceData?.lineItems.edges]);

    const examFeeLineItems = useMemo(() => {
        return invoiceData?.lineItems.edges
            .filter(
                (item) =>
                    item?.node?.itemType === "EXAM_FEE" &&
                    item?.node?.quantity &&
                    item?.node?.unitAmount,
            )
            .sort((a, b) => {
                if (
                    (a?.node?.pupilDescription ?? "") <
                    (b?.node?.pupilDescription ?? "")
                ) {
                    return -1;
                } else {
                    return 1;
                }
            });
    }, [invoiceData?.lineItems.edges]);

    const blockOtherFeeOrDiscountLineItems = useMemo(() => {
        return invoiceData?.lineItems.edges
            .filter(
                (item) =>
                    item?.node?.itemType === "BLOCK_OTHER_FEE_OR_DISCOUNT" &&
                    item?.node?.quantity &&
                    item?.node?.unitAmount,
            )
            .sort((a, b) => {
                if (
                    (a?.node?.pupilDescription ?? "") <
                    (b?.node?.pupilDescription ?? "")
                ) {
                    return -1;
                } else {
                    return 1;
                }
            });
    }, [invoiceData?.lineItems.edges]);

    const accountOtherFeeOrDiscountLineItems = useMemo(() => {
        return invoiceData?.lineItems.edges
            .filter(
                (item) =>
                    item?.node?.itemType === "ACCOUNT_OTHER_FEE_OR_DISCOUNT" &&
                    item?.node?.quantity &&
                    item?.node?.unitAmount,
            )
            .sort((a, b) => {
                if (
                    (a?.node?.pupilDescription ?? "") <
                    (b?.node?.pupilDescription ?? "")
                ) {
                    return -1;
                } else {
                    return 1;
                }
            });
    }, [invoiceData?.lineItems.edges]);

    const [commitAddLineItem, addLineItemInFlight] =
        useMutation<AddLineItemMutation>(add_line_item);

    const addLineItem = useCallback(
        (
            invoiceId: string,
            lineItemType: string,
            pupilId?: string,
            instrument?: string | null,
        ) => {
            const addLineItemConfig = {
                variables: {
                    connections: lineItemsConnectionId
                        ? [lineItemsConnectionId]
                        : [],
                    input: {
                        invoiceId: invoiceId,
                        lineItemType: lineItemType,
                        pupilId: pupilId,
                        instrument: instrument,
                    },
                },
                onCompleted: (response: AddLineItemMutation$data) => {
                    if (!response?.addLineItem?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't add line item"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitAddLineItem(addLineItemConfig);
        },
        [commitAddLineItem, lineItemsConnectionId, toast],
    );

    const [commitRemoveLineItem, removeLineItemInFlight] =
        useMutation<RemoveLineItemMutation>(remove_line_item);

    const removeLineItem = useCallback(
        (lineItemId: string) => {
            const removeLineItemConfig = {
                variables: {
                    connections: lineItemsConnectionId
                        ? [lineItemsConnectionId]
                        : [],
                    input: {
                        lineItemId: lineItemId,
                    },
                },
                onCompleted: (response: RemoveLineItemMutation$data) => {
                    dispatchState({
                        input: "activeLineItemId",
                        value: undefined,
                    });
                    if (!response?.removeLineItem?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't remove line item"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitRemoveLineItem(removeLineItemConfig);
        },
        [commitRemoveLineItem, dispatchState, lineItemsConnectionId, toast],
    );

    const commitUpdateLineItemAmount =
        useMutation<UpdateLineItemAmountMutation>(update_line_item_amount)[0];

    const updateLineItemAmount = useCallback(
        (lineItemId: string, amount: number) => {
            const updateLineItemAmountConfig = {
                variables: {
                    input: {
                        lineItemId: lineItemId,
                        amount: amount,
                    },
                },
                onCompleted: (response: UpdateLineItemAmountMutation$data) => {
                    if (!response?.updateLineItemAmount?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't update line item"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitUpdateLineItemAmount(updateLineItemAmountConfig);
        },
        [commitUpdateLineItemAmount, toast],
    );

    const addLineItemHandler = useCallback(
        (
            lineItemIndex?: number,
            pupilId?: string,
            instrument?: string | null,
        ) => {
            if (
                typeof lineItemIndex === "number" &&
                invoiceData?.id &&
                addLineItem
            ) {
                const lineItemType = getLineItemType(lineItemIndex, pupilId);
                // navigate to registers if adding missed lessons (can't do this imperatively)
                if (lineItemType === "MISSED_LESSON_DISCOUNT") {
                    if (pupilId) {
                        const [previousBlock, previousStartingYear] =
                            getPreviousBlockAndStartingYear(
                                invoiceData.block,
                                invoiceData.startingYear,
                            );
                        navigation.push("Account", {
                            profileId: pupilId,
                            accountType: "PUPIL",
                            block: previousBlock ?? undefined,
                            startingYear: previousStartingYear ?? undefined,
                        });
                    }
                } else {
                    // for other types, first check if the line item already exists - if so, focus that input
                    if (lineItemType === "EXAM_FEE") {
                        const existingExamFee = examFeeLineItems?.filter(
                            (item) =>
                                item?.node?.pupil?.id === pupilId &&
                                item?.node?.instrumentDescription ===
                                    titleCaseConverter(instrument),
                        )[0];
                        if (existingExamFee?.node?.id) {
                            examFeeInputRefs.current
                                .get(existingExamFee.node.id)
                                .focus();
                        } else {
                            addLineItem(
                                invoiceData?.id,
                                lineItemType,
                                pupilId,
                                instrument,
                            );
                        }
                    } else if (lineItemType === "BLOCK_OTHER_FEE_OR_DISCOUNT") {
                        const existingBlockFeeOrDiscount =
                            blockOtherFeeOrDiscountLineItems?.filter(
                                (item) =>
                                    item?.node?.pupil?.id === pupilId &&
                                    item?.node?.instrumentDescription ===
                                        titleCaseConverter(instrument),
                            )[0];
                        if (existingBlockFeeOrDiscount?.node?.id) {
                            blockOtherFeeOrDiscountInputRefs.current
                                .get(existingBlockFeeOrDiscount.node.id)
                                .focus();
                        } else {
                            addLineItem(
                                invoiceData?.id,
                                lineItemType,
                                pupilId,
                                instrument,
                            );
                        }
                    } else if (
                        lineItemType === "ACCOUNT_OTHER_FEE_OR_DISCOUNT"
                    ) {
                        const existingAccountFeeOrDiscount =
                            accountOtherFeeOrDiscountLineItems?.[0];
                        if (existingAccountFeeOrDiscount?.node?.id) {
                            accountOtherFeeOrDiscountInputRefs.current
                                .get(existingAccountFeeOrDiscount.node.id)
                                .focus();
                        } else {
                            addLineItem(
                                invoiceData?.id,
                                lineItemType,
                                pupilId,
                                instrument,
                            );
                        }
                    }
                }
            }
            dispatchOuterState?.({
                input: "invoiceIsEdited",
                value: true,
            });
            dispatchState({
                input: "showAddLineItemModal",
                value: false,
            });
            setTimeout(
                () =>
                    dispatchState({
                        input: "activeLineItemIndex",
                        value: undefined,
                    }),
                400,
            );
        },
        [
            accountOtherFeeOrDiscountLineItems,
            addLineItem,
            blockOtherFeeOrDiscountLineItems,
            dispatchOuterState,
            examFeeLineItems,
            invoiceData?.block,
            invoiceData?.id,
            invoiceData?.startingYear,
            navigation,
        ],
    );

    const removeLineItemHandler = useCallback(
        (lineItemId?: string) => {
            if (lineItemId && removeLineItem && dispatchOuterState) {
                dispatchOuterState({
                    input: "activeLineItemId",
                    value: lineItemId,
                });
                removeLineItem(lineItemId);
                dispatchOuterState({
                    input: "invoiceIsEdited",
                    value: true,
                });
            }
            dispatchState({
                input: "showAddLineItemModal",
                value: false,
            });
            dispatchState({
                input: "activeLineItemIndex",
                value: undefined,
            });
        },
        [dispatchOuterState, removeLineItem],
    );

    const invoiceInputFinishEditingHandler = useCallback(
        (inputIdentifier: string | number, inputValue?: string | undefined) => {
            if (
                typeof inputIdentifier === "string" &&
                inputValue &&
                invoiceData?.id &&
                updateLineItemAmount &&
                dispatchOuterState
            ) {
                const [lineItemType, lineItemId] = inputIdentifier.split("-");
                if (lineItemType) {
                    updateLineItemAmount(lineItemId, parseFloat(inputValue));
                    dispatchOuterState({
                        input: "invoiceIsEdited",
                        value: true,
                    });
                }
            }
        },
        [dispatchOuterState, invoiceData?.id, updateLineItemAmount],
    );

    const downloadPdfHandler = useCallback(() => {
        if (invoiceData?.invoicePdfSignedUrl) {
            Linking.openURL(invoiceData.invoicePdfSignedUrl);
        }
    }, [invoiceData?.invoicePdfSignedUrl]);

    const navigateToAccountHandler = useCallback(() => {
        const navigateToAccount = () => {
            if (invoiceData?.assignedTo?.id) {
                navigation.push("Account", {
                    profileId: invoiceData.assignedTo.id,
                    accountType: "PARENT",
                    startingYear: invoiceData.startingYear ?? undefined,
                    block: invoiceData.block ?? undefined,
                });
            }
        };
        if (!outerState?.values.invoiceIsEdited) {
            navigateToAccount();
        } else {
            dispatchOuterState?.({
                input: "leaveAlertAction",
                value: navigateToAccount,
            });
        }
    }, [
        dispatchOuterState,
        invoiceData?.assignedTo?.id,
        invoiceData?.block,
        invoiceData?.startingYear,
        navigation,
        outerState?.values.invoiceIsEdited,
    ]);

    const renderLineItemModal = useMemo(() => {
        return (
            <Box alignItems="flex-end" my="2em" width="100%" zIndex={3}>
                <Button
                    borderRadius="full"
                    height="3.5em"
                    leftIcon={
                        addLineItemInFlight ? (
                            <Spinner color="white" />
                        ) : !state.values.showAddLineItemModal ? (
                            <AddIcon size="6" />
                        ) : (
                            <MinusIcon size="6" />
                        )
                    }
                    mr="4"
                    onPress={() => {
                        dispatchState({
                            input: "showAddLineItemModal",
                            value: !state.values.showAddLineItemModal,
                        });
                        if (!state.values.showAddLineItemModal) {
                            dispatchState({
                                input: "activeLineItemIndex",
                                value: undefined,
                            });
                        }
                    }}
                    shadow={3}
                    width="3.5em"
                />
                <Box position="absolute" right="90" zIndex={3}>
                    <PresenceTransition
                        animate={{
                            opacity: 1,
                            transition: {
                                duration: 400,
                            },
                        }}
                        initial={{
                            opacity: 0,
                        }}
                        visible={state.values.showAddLineItemModal}>
                        <Box
                            bg="surface.100"
                            borderRadius="2xl"
                            p="2"
                            shadow={3}
                            width="210">
                            {state.values.activeLineItemIndex === undefined ? (
                                <Button.Group direction="column">
                                    <Button
                                        onPress={() =>
                                            dispatchState({
                                                input: "activeLineItemIndex",
                                                value: 0,
                                            })
                                        }
                                        variant="ghost">
                                        Missed Lessons
                                    </Button>
                                    <Button
                                        onPress={() =>
                                            dispatchState({
                                                input: "activeLineItemIndex",
                                                value: 1,
                                            })
                                        }
                                        variant="ghost">
                                        Exam Fee
                                    </Button>
                                    <Button
                                        onPress={() =>
                                            dispatchState({
                                                input: "activeLineItemIndex",
                                                value: 2,
                                            })
                                        }
                                        variant="ghost">
                                        Other Discount or Fee
                                    </Button>
                                </Button.Group>
                            ) : pupilsAndInstruments ? (
                                <Button.Group direction="column">
                                    {pupilsAndInstruments.map((item, index) => {
                                        if (item) {
                                            return (
                                                <Button
                                                    key={index}
                                                    onPress={() => {
                                                        addLineItemHandler(
                                                            state.values
                                                                .activeLineItemIndex,
                                                            item.pupilId,
                                                            item.instrument,
                                                        );
                                                    }}
                                                    rightIcon={
                                                        getInstrumentIcon(
                                                            item.instrument,
                                                            {
                                                                size: 7,
                                                                ml: 1,
                                                            },
                                                        ) ?? undefined
                                                    }
                                                    variant="ghost">
                                                    {item.pupilName}
                                                </Button>
                                            );
                                        } else {
                                            return <></>;
                                        }
                                    }) ?? <></>}
                                </Button.Group>
                            ) : null}
                        </Box>
                    </PresenceTransition>
                </Box>
            </Box>
        );
    }, [
        addLineItemInFlight,
        state.values.showAddLineItemModal,
        state.values.activeLineItemIndex,
        pupilsAndInstruments,
        addLineItemHandler,
    ]);

    const { scale, width: windowWidth } = useWindowDimensions();

    const fontSize =
        getScaledWindowDimension(windowWidth, scale) > 1000 ? 18 : 16;

    const renderLessonLineItems = useMemo(() => {
        if (invoiceData?.startingYear) {
            return Object.entries(groupedLessonLineItems)?.map(
                ([pupilDescription, groupings], index) => {
                    return (
                        Object.entries(groupings).map(
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            ([_, items], innerIndex) => {
                                const totalQuantity = items.reduce(
                                    (acc, item) => {
                                        return (
                                            acc +
                                            parseFloat(
                                                (item?.node?.quantity ??
                                                    "0") as string,
                                            )
                                        );
                                    },
                                    0,
                                );

                                if (items[0]?.node && totalQuantity > 0) {
                                    return (
                                        <HStack key={index} width="100%">
                                            <InvoiceTableCell
                                                flex={FLEX_ARRAY[0]}
                                                isHidden={
                                                    index !== 0 ||
                                                    innerIndex !== 0
                                                }
                                                textStyle={{
                                                    fontSize: fontSize,
                                                }}>
                                                {
                                                    items[0].node
                                                        .timePeriodDescription
                                                }
                                            </InvoiceTableCell>
                                            <InvoiceTableCell
                                                flex={FLEX_ARRAY[1]}
                                                onPress={() => {
                                                    if (
                                                        items[0]?.node?.pupil
                                                            ?.id
                                                    ) {
                                                        navigation.push(
                                                            "Account",
                                                            {
                                                                profileId:
                                                                    items[0]
                                                                        .node
                                                                        .pupil
                                                                        .id,
                                                                accountType:
                                                                    "PUPIL",
                                                                startingYear:
                                                                    invoiceData?.startingYear ??
                                                                    undefined,
                                                                block:
                                                                    invoiceData?.block ??
                                                                    undefined,
                                                            },
                                                        );
                                                    }
                                                }}
                                                textStyle={{
                                                    fontSize: fontSize,
                                                }}>
                                                {`${
                                                    totalQuantity % 1 === 0
                                                        ? totalQuantity.toFixed(
                                                              0,
                                                          )
                                                        : totalQuantity.toFixed(
                                                              1,
                                                          )
                                                } Lesson${
                                                    totalQuantity == 1
                                                        ? ""
                                                        : "s"
                                                }`}
                                            </InvoiceTableCell>
                                            <InvoiceTableCell
                                                flex={FLEX_ARRAY[2]}
                                                onPress={() => {
                                                    if (
                                                        items[0]?.node?.pupil
                                                            ?.id
                                                    ) {
                                                        navigation.push(
                                                            "Account",
                                                            {
                                                                profileId:
                                                                    items[0]
                                                                        .node
                                                                        .pupil
                                                                        .id,
                                                                accountType:
                                                                    "PUPIL",
                                                                startingYear:
                                                                    invoiceData?.startingYear ??
                                                                    undefined,
                                                                block:
                                                                    invoiceData?.block ??
                                                                    undefined,
                                                            },
                                                        );
                                                    }
                                                }}
                                                textStyle={{
                                                    fontSize: fontSize,
                                                }}>
                                                {pupilDescription}
                                            </InvoiceTableCell>
                                            <InvoiceTableCell
                                                flex={FLEX_ARRAY[3]}
                                                textStyle={{
                                                    fontSize: fontSize,
                                                }}>
                                                {titleCaseConverter(
                                                    items[0].node
                                                        .instrumentDescription,
                                                )}
                                            </InvoiceTableCell>
                                            <InvoiceTableCell
                                                flex={FLEX_ARRAY[4]}
                                                textStyle={{
                                                    fontSize: fontSize,
                                                }}>
                                                {items[0].node.typeDescription}
                                            </InvoiceTableCell>
                                            <InvoiceTableCell
                                                flex={FLEX_ARRAY[5]}
                                                textStyle={{
                                                    fontSize: fontSize,
                                                }}>
                                                {items[0].node.unitAmount !==
                                                null
                                                    ? formatCurrency(
                                                          totalQuantity *
                                                              items[0].node
                                                                  .unitAmount,
                                                      )
                                                    : ""}
                                            </InvoiceTableCell>
                                        </HStack>
                                    );
                                } else {
                                    return null;
                                }
                            },
                        ) ?? null
                    );
                },
            );
        } else {
            return null;
        }
    }, [
        fontSize,
        groupedLessonLineItems,
        invoiceData?.block,
        invoiceData?.startingYear,
        navigation,
    ]);

    const renderMissedLessonDiscountLineItems = useMemo(() => {
        if (invoiceData?.startingYear) {
            const [previousBlock, previousStartingYear] =
                getPreviousBlockAndStartingYear(
                    invoiceData?.block,
                    invoiceData?.startingYear,
                );
            return Object.entries(groupedMissedLessonDiscountLineItems)?.map(
                ([pupilDescription, groupings], index) => {
                    return (
                        Object.entries(groupings).map(
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            ([_, items], innerIndex) => {
                                const totalQuantity = items.reduce(
                                    (acc, item) => {
                                        return (
                                            acc +
                                            parseFloat(
                                                (item?.node?.quantity ??
                                                    "0") as string,
                                            )
                                        );
                                    },
                                    0,
                                );

                                if (items[0]?.node && totalQuantity > 0) {
                                    return (
                                        <HStack
                                            key={index}
                                            mt={index == 0 ? "1em" : undefined}
                                            width="100%">
                                            <InvoiceTableCell
                                                flex={FLEX_ARRAY[0]}
                                                isHidden={
                                                    index !== 0 ||
                                                    innerIndex !== 0
                                                }
                                                textStyle={{
                                                    fontSize: fontSize,
                                                }}>
                                                {getTermInWords(
                                                    previousBlock,
                                                    previousStartingYear,
                                                )}
                                            </InvoiceTableCell>
                                            <InvoiceTableCell
                                                flex={FLEX_ARRAY[1]}
                                                onPress={() => {
                                                    if (
                                                        items[0]?.node?.pupil
                                                            ?.id
                                                    ) {
                                                        navigation.push(
                                                            "Account",
                                                            {
                                                                profileId:
                                                                    items[0]
                                                                        .node
                                                                        .pupil
                                                                        .id,
                                                                accountType:
                                                                    "PUPIL",
                                                                startingYear:
                                                                    previousStartingYear ??
                                                                    undefined,
                                                                block:
                                                                    previousBlock ??
                                                                    undefined,
                                                            },
                                                        );
                                                    }
                                                }}
                                                textStyle={{
                                                    fontSize: fontSize,
                                                }}>
                                                {`${
                                                    totalQuantity % 1 === 0
                                                        ? totalQuantity.toFixed(
                                                              0,
                                                          )
                                                        : totalQuantity.toFixed(
                                                              1,
                                                          )
                                                } Missed Lesson${
                                                    totalQuantity == 1
                                                        ? ""
                                                        : "s"
                                                }`}
                                            </InvoiceTableCell>
                                            <InvoiceTableCell
                                                flex={FLEX_ARRAY[2]}
                                                onPress={() => {
                                                    if (
                                                        items[0]?.node?.pupil
                                                            ?.id
                                                    ) {
                                                        navigation.push(
                                                            "Account",
                                                            {
                                                                profileId:
                                                                    items[0]
                                                                        .node
                                                                        .pupil
                                                                        .id,
                                                                accountType:
                                                                    "PUPIL",
                                                                startingYear:
                                                                    previousStartingYear ??
                                                                    undefined,
                                                                block:
                                                                    previousBlock ??
                                                                    undefined,
                                                            },
                                                        );
                                                    }
                                                }}
                                                textStyle={{
                                                    fontSize: fontSize,
                                                }}>
                                                {pupilDescription}
                                            </InvoiceTableCell>
                                            <InvoiceTableCell
                                                flex={FLEX_ARRAY[3]}
                                                textStyle={{
                                                    fontSize: fontSize,
                                                }}>
                                                {titleCaseConverter(
                                                    items[0].node
                                                        .instrumentDescription,
                                                )}
                                            </InvoiceTableCell>
                                            <InvoiceTableCell
                                                flex={FLEX_ARRAY[4]}
                                                textStyle={{
                                                    fontSize: fontSize,
                                                }}>
                                                {items[0].node.typeDescription}
                                            </InvoiceTableCell>
                                            <InvoiceTableCell
                                                flex={FLEX_ARRAY[5]}
                                                textStyle={{
                                                    fontSize: fontSize,
                                                }}>
                                                {items[0].node.unitAmount !==
                                                null
                                                    ? formatCurrency(
                                                          totalQuantity *
                                                              items[0].node
                                                                  .unitAmount,
                                                      )
                                                    : ""}
                                            </InvoiceTableCell>
                                        </HStack>
                                    );
                                } else {
                                    return null;
                                }
                            },
                        ) ?? null
                    );
                },
            );
        } else {
            return null;
        }
    }, [
        fontSize,
        groupedMissedLessonDiscountLineItems,
        invoiceData?.block,
        invoiceData?.startingYear,
        navigation,
    ]);

    if (invoiceData) {
        return (
            <Box>
                <Box
                    bg="white"
                    borderRadius="2xl"
                    p="20mm"
                    shadow={1}
                    width="100%">
                    {invoiceData.status !== "CANCELLED" &&
                    !outerState?.values.invoiceIsEdited &&
                    isEditable ? (
                        <Button
                            _spinner={{ mx: 2 }}
                            _text={{ fontSize: "20" }}
                            isLoading={
                                outerState?.values.isInvoiceSendButtonPress &&
                                updateInvoiceStatusInFlight
                            }
                            isLoadingText={
                                invoiceData.status === "DRAFT"
                                    ? "Send Invoice"
                                    : "Resend Invoice"
                            }
                            left="10"
                            leftIcon={<SendIcon mx="2" size="md" />}
                            onPress={() => {
                                dispatchOuterState?.({
                                    input: "sendAlertIsOpen",
                                    value: true,
                                });
                                dispatchOuterState?.({
                                    input: "isInvoiceSendButtonPress",
                                    value: true,
                                });
                            }}
                            position="absolute"
                            shadow={1}
                            top="10">
                            {invoiceData.status === "DRAFT"
                                ? "Send Invoice"
                                : "Resend Invoice"}
                        </Button>
                    ) : null}
                    {invoiceData.invoicePdfSignedUrl &&
                    !outerState?.values.invoiceIsEdited ? (
                        <Button
                            _text={{ fontSize: "20" }}
                            leftIcon={<PdfIcon mx="2" size="lg" />}
                            onPress={downloadPdfHandler}
                            position="absolute"
                            right="10"
                            shadow={1}
                            top="10">
                            Download PDF
                        </Button>
                    ) : outerState?.values.invoiceIsEdited &&
                      invoiceData.status === "DRAFT" ? (
                        <Button
                            _spinner={{ mr: 1, ml: 1 }}
                            _text={{ fontSize: "20" }}
                            isLoading={
                                generateOrUpdateDraftInvoiceInFlight &&
                                !outerState.values.isSideBarPress
                            }
                            isLoadingText={"Update Draft"}
                            leftIcon={<RestartIcon mr="1" size="lg" />}
                            onPress={() =>
                                generateDraftHandler?.({
                                    overwriteOtherDraft: true,
                                    isUpdate: true,
                                })
                            }
                            position="absolute"
                            pr="4"
                            right="10"
                            shadow={1}
                            top="10">
                            Update Draft
                        </Button>
                    ) : null}
                    <VStack alignItems="center">
                        <FULL_LOGO
                            height="8em"
                            viewBox="0 0 4745 1924"
                            width="20em"
                        />
                        <Pressable
                            alignItems="center"
                            bg="primary.700"
                            borderRadius="0.5em"
                            isDisabled={!isAccountScreen}
                            justifyContent="center"
                            my="2em"
                            onPress={() =>
                                navigation.push("Invoice", {
                                    invoiceId: invoiceData.id,
                                })
                            }
                            width="100%">
                            <Text
                                color="white"
                                fontSize="22"
                                fontWeight="bold"
                                letterSpacing="0.2em"
                                py="0.5em">
                                INVOICE
                            </Text>
                        </Pressable>
                        <HStack
                            alignItems="flex-start"
                            justifyContent="space-between"
                            mb="2em"
                            width="100%">
                            <HStack
                                alignItems="flex-start"
                                flex={0.7}
                                pl="2"
                                space="16">
                                <Text
                                    fontSize={fontSize + 2}
                                    fontWeight="light">
                                    <Text fontWeight="bold">From:</Text>
                                    {
                                        "\nPianoFunClub Partnership\n128 Finchley Road\nLondon\nNW3 5HT"
                                    }
                                </Text>
                                <Pressable
                                    isDisabled={isAccountScreen}
                                    onPress={navigateToAccountHandler}
                                    pointerEvents={
                                        isAccountScreen ? "none" : undefined
                                    }>
                                    <Text
                                        flexWrap="wrap"
                                        fontSize={fontSize + 2}
                                        fontWeight="light">
                                        <Text fontWeight="bold">To:</Text>
                                        {`\n${invoiceData.assignedToFullName}\n${invoiceData.assignedToEmail}`}
                                    </Text>
                                </Pressable>
                            </HStack>
                            <HStack flex={0.3} minWidth="320">
                                <VStack flex={1}>
                                    <InvoiceTableCell
                                        isHeader
                                        textStyle={{ fontSize: fontSize }}>
                                        {"Account #"}
                                    </InvoiceTableCell>
                                    <InvoiceTableCell
                                        isHeader
                                        textStyle={{ fontSize: fontSize }}>
                                        {"Invoice #"}
                                    </InvoiceTableCell>
                                    <InvoiceTableCell
                                        isHeader
                                        textStyle={{ fontSize: fontSize }}>
                                        {"Date"}
                                    </InvoiceTableCell>
                                    <InvoiceTableCell
                                        isHeader
                                        textStyle={{ fontSize: fontSize }}>
                                        {"Amount Due"}
                                    </InvoiceTableCell>
                                </VStack>
                                <VStack flex={1}>
                                    <InvoiceTableCell
                                        onPress={
                                            !isAccountScreen
                                                ? () =>
                                                      navigateToAccountHandler()
                                                : undefined
                                        }
                                        textStyle={{ fontSize: fontSize }}>
                                        {invoiceData.assignedToAccountNumber}
                                    </InvoiceTableCell>
                                    <InvoiceTableCell
                                        onPress={
                                            isAccountScreen
                                                ? () =>
                                                      navigation.push(
                                                          "Invoice",
                                                          {
                                                              invoiceId:
                                                                  invoiceData.id,
                                                          },
                                                      )
                                                : undefined
                                        }
                                        textStyle={{ fontSize: fontSize }}>
                                        {invoiceData.invoiceNumber}
                                    </InvoiceTableCell>
                                    <InvoiceTableCell
                                        textStyle={{ fontSize: fontSize }}>
                                        {format(
                                            new Date(invoiceData.createdOn),
                                            "dd/MM/yyyy",
                                        )}
                                    </InvoiceTableCell>
                                    <InvoiceTableCell
                                        textStyle={{ fontSize: fontSize }}>
                                        {formatCurrency(
                                            invoiceData.totalAmount,
                                        )}
                                    </InvoiceTableCell>
                                </VStack>
                            </HStack>
                        </HStack>
                        <HStack width="100%">
                            <InvoiceTableCell
                                flex={FLEX_ARRAY[0]}
                                isHeader
                                textStyle={{
                                    textAlign: "center",
                                    fontSize: fontSize,
                                }}>
                                {"Term"}
                            </InvoiceTableCell>
                            <InvoiceTableCell
                                flex={FLEX_ARRAY[1]}
                                isHeader
                                textStyle={{
                                    textAlign: "center",
                                    fontSize: fontSize,
                                }}>
                                {"Lessons"}
                            </InvoiceTableCell>
                            <InvoiceTableCell
                                flex={FLEX_ARRAY[2]}
                                isHeader
                                textStyle={{
                                    textAlign: "center",
                                    fontSize: fontSize,
                                }}>
                                {"Pupil"}
                            </InvoiceTableCell>
                            <InvoiceTableCell
                                flex={FLEX_ARRAY[3]}
                                isHeader
                                textStyle={{
                                    textAlign: "center",
                                    fontSize: fontSize,
                                }}>
                                {"Instrument"}
                            </InvoiceTableCell>
                            <InvoiceTableCell
                                flex={FLEX_ARRAY[4]}
                                isHeader
                                textStyle={{
                                    textAlign: "center",
                                    fontSize: fontSize,
                                }}>
                                {"Type"}
                            </InvoiceTableCell>
                            <InvoiceTableCell
                                flex={FLEX_ARRAY[5]}
                                isHeader
                                textStyle={{
                                    textAlign: "center",
                                    fontSize: fontSize,
                                }}>
                                {"Cost"}
                            </InvoiceTableCell>
                        </HStack>
                        {renderLessonLineItems}
                        {renderMissedLessonDiscountLineItems}
                        {instrumentDepositLineItems?.map((item, index) => {
                            if (item?.node) {
                                return (
                                    <HStack
                                        key={index}
                                        mt={index === 0 ? "1em" : 0}
                                        width="100%">
                                        <InvoiceTableCell
                                            flex={FLEX_ARRAY[0]}
                                            isHidden
                                            textStyle={{ fontSize: fontSize }}
                                        />
                                        <InvoiceTableCell
                                            flex={FLEX_ARRAY[1]}
                                            isHidden
                                            textStyle={{ fontSize: fontSize }}
                                        />
                                        <InvoiceTableCell
                                            flex={FLEX_ARRAY[2]}
                                            onPress={() => {
                                                if (item.node?.pupil?.id) {
                                                    navigation.push("Account", {
                                                        profileId:
                                                            item.node.pupil.id,
                                                        accountType: "PUPIL",
                                                        startingYear:
                                                            invoiceData.startingYear ??
                                                            undefined,
                                                        block:
                                                            invoiceData.block ??
                                                            undefined,
                                                    });
                                                }
                                            }}
                                            textStyle={{ fontSize: fontSize }}>
                                            {item.node.pupilDescription}
                                        </InvoiceTableCell>
                                        <InvoiceTableCell
                                            flex={FLEX_ARRAY[3]}
                                            textStyle={{ fontSize: fontSize }}>
                                            {titleCaseConverter(
                                                item.node.instrumentDescription,
                                            )}
                                        </InvoiceTableCell>
                                        <InvoiceTableCell
                                            flex={FLEX_ARRAY[4]}
                                            textStyle={{ fontSize: fontSize }}>
                                            {"Instrument Deposit"}
                                        </InvoiceTableCell>
                                        <InvoiceTableCell
                                            flex={FLEX_ARRAY[5]}
                                            hasInnerPressable
                                            textStyle={{ fontSize: fontSize }}>
                                            <Box mx="-0.5em">
                                                <CurrencyInput
                                                    ref={(ref) => {
                                                        if (
                                                            ref &&
                                                            !depositInputRefs.current.get(
                                                                item.node?.id,
                                                            )
                                                        ) {
                                                            depositInputRefs.current.set(
                                                                item.node?.id,
                                                                ref,
                                                            );
                                                        }
                                                    }}
                                                    _focus={{
                                                        borderWidth: 1,
                                                        borderColor:
                                                            "primary.200",
                                                    }}
                                                    _hover={{
                                                        borderWidth: 1,
                                                        borderColor:
                                                            "primary.400",
                                                    }}
                                                    bg="transparent"
                                                    borderColor="transparent"
                                                    borderRadius="0.5em"
                                                    borderWidth={1}
                                                    fontSize="18"
                                                    fontWeight="light"
                                                    height="2.5em"
                                                    id={`INSTRUMENT_DEPOSIT-${item.node.id}`}
                                                    initiallyValid
                                                    initialValue={
                                                        item.node.unitAmount
                                                    }
                                                    isDisabled={
                                                        invoiceData.status !==
                                                            "DRAFT" ||
                                                        !isEditable
                                                    }
                                                    my="-0.5em"
                                                    onFinishEditing={
                                                        invoiceInputFinishEditingHandler
                                                    }
                                                    pointerEvents={
                                                        invoiceData.status !==
                                                            "DRAFT" ||
                                                        !isEditable
                                                            ? "none"
                                                            : undefined
                                                    }
                                                    px="2"
                                                    updateValueOnInitialValueChange
                                                    width="100%"
                                                />
                                            </Box>
                                        </InvoiceTableCell>
                                        {invoiceData.status === "DRAFT" &&
                                        isEditable ? (
                                            <Button
                                                _hover={{
                                                    bg: "transparent",
                                                    opacity: 0.8,
                                                }}
                                                _pressed={{
                                                    bg: "transparent",
                                                    opacity: 0.7,
                                                }}
                                                leftIcon={
                                                    <Center size="7">
                                                        {removeLineItemInFlight &&
                                                        outerState?.values
                                                            .activeLineItemId ===
                                                            item.node?.id ? (
                                                            <Spinner color="surface.500" />
                                                        ) : (
                                                            <CloseIcon
                                                                color="surface.500"
                                                                size="7"
                                                            />
                                                        )}
                                                    </Center>
                                                }
                                                onPress={() =>
                                                    removeLineItemHandler(
                                                        item.node?.id,
                                                    )
                                                }
                                                position="absolute"
                                                right="-45"
                                                variant="ghost"
                                            />
                                        ) : null}
                                    </HStack>
                                );
                            } else {
                                return null;
                            }
                        }) ?? null}
                        {examFeeLineItems?.map((item, index) => {
                            if (item?.node) {
                                return (
                                    <HStack
                                        key={index}
                                        mt={index === 0 ? "1em" : 0}
                                        width="100%">
                                        <InvoiceTableCell
                                            flex={FLEX_ARRAY[0]}
                                            isHidden
                                            textStyle={{ fontSize: fontSize }}
                                        />
                                        <InvoiceTableCell
                                            flex={FLEX_ARRAY[1]}
                                            isHidden
                                            textStyle={{ fontSize: fontSize }}
                                        />
                                        <InvoiceTableCell
                                            flex={FLEX_ARRAY[2]}
                                            onPress={() => {
                                                if (item.node?.pupil?.id) {
                                                    navigation.push("Account", {
                                                        profileId:
                                                            item.node.pupil.id,
                                                        accountType: "PUPIL",
                                                        startingYear:
                                                            invoiceData.startingYear ??
                                                            undefined,
                                                        block:
                                                            invoiceData.block ??
                                                            undefined,
                                                    });
                                                }
                                            }}
                                            textStyle={{ fontSize: fontSize }}>
                                            {item.node.pupilDescription}
                                        </InvoiceTableCell>
                                        <InvoiceTableCell
                                            flex={FLEX_ARRAY[3]}
                                            textStyle={{ fontSize: fontSize }}>
                                            {titleCaseConverter(
                                                item.node.instrumentDescription,
                                            )}
                                        </InvoiceTableCell>
                                        <InvoiceTableCell
                                            flex={FLEX_ARRAY[4]}
                                            textStyle={{ fontSize: fontSize }}>
                                            {"Exam Fee"}
                                        </InvoiceTableCell>
                                        <InvoiceTableCell
                                            flex={FLEX_ARRAY[5]}
                                            hasInnerPressable
                                            textStyle={{ fontSize: fontSize }}>
                                            <Box mx="-0.5em">
                                                <CurrencyInput
                                                    ref={(ref) => {
                                                        if (
                                                            ref &&
                                                            !examFeeInputRefs.current.get(
                                                                item.node?.id,
                                                            )
                                                        ) {
                                                            examFeeInputRefs.current.set(
                                                                item.node?.id,
                                                                ref,
                                                            );
                                                        }
                                                    }}
                                                    _focus={{
                                                        borderWidth: 1,
                                                        borderColor:
                                                            "primary.200",
                                                    }}
                                                    _hover={{
                                                        borderWidth: 1,
                                                        borderColor:
                                                            "primary.400",
                                                    }}
                                                    bg="transparent"
                                                    borderColor="transparent"
                                                    borderRadius="0.5em"
                                                    borderWidth={1}
                                                    fontSize="18"
                                                    fontWeight="light"
                                                    height="2.5em"
                                                    id={`EXAM_FEE-${item.node.id}`}
                                                    initiallyValid
                                                    initialValue={
                                                        item.node.unitAmount
                                                    }
                                                    isDisabled={
                                                        invoiceData.status !==
                                                            "DRAFT" ||
                                                        !isEditable
                                                    }
                                                    keyboardType="numeric"
                                                    my="-0.5em"
                                                    onFinishEditing={
                                                        invoiceInputFinishEditingHandler
                                                    }
                                                    pointerEvents={
                                                        invoiceData.status !==
                                                            "DRAFT" ||
                                                        !isEditable
                                                            ? "none"
                                                            : undefined
                                                    }
                                                    px="2"
                                                    updateValueOnInitialValueChange
                                                    width="100%"
                                                    zIndex={2}
                                                />
                                            </Box>
                                        </InvoiceTableCell>
                                        {invoiceData.status === "DRAFT" &&
                                        isEditable ? (
                                            <Button
                                                _hover={{
                                                    bg: "transparent",
                                                    opacity: 0.8,
                                                }}
                                                _pressed={{
                                                    bg: "transparent",
                                                    opacity: 0.7,
                                                }}
                                                leftIcon={
                                                    <Center size="7">
                                                        {removeLineItemInFlight &&
                                                        outerState?.values
                                                            .activeLineItemId ===
                                                            item.node?.id ? (
                                                            <Spinner color="surface.500" />
                                                        ) : (
                                                            <CloseIcon
                                                                color="surface.500"
                                                                size="7"
                                                            />
                                                        )}
                                                    </Center>
                                                }
                                                onPress={() =>
                                                    removeLineItemHandler(
                                                        item.node?.id,
                                                    )
                                                }
                                                position="absolute"
                                                right="-45"
                                                variant="ghost"
                                            />
                                        ) : null}
                                    </HStack>
                                );
                            } else {
                                return null;
                            }
                        }) ?? null}
                        {blockOtherFeeOrDiscountLineItems?.map(
                            (item, index) => {
                                if (item?.node && item.node.unitAmount) {
                                    return (
                                        <HStack
                                            key={index}
                                            mt={index === 0 ? "1em" : 0}
                                            width="100%">
                                            <InvoiceTableCell
                                                flex={FLEX_ARRAY[0]}
                                                isHidden
                                                textStyle={{
                                                    fontSize: fontSize,
                                                }}
                                            />
                                            <InvoiceTableCell
                                                flex={FLEX_ARRAY[1]}
                                                isHidden
                                                textStyle={{
                                                    fontSize: fontSize,
                                                }}
                                            />
                                            <InvoiceTableCell
                                                flex={FLEX_ARRAY[2]}
                                                onPress={() => {
                                                    if (item.node?.pupil?.id) {
                                                        navigation.push(
                                                            "Account",
                                                            {
                                                                profileId:
                                                                    item.node
                                                                        .pupil
                                                                        .id,
                                                                accountType:
                                                                    "PUPIL",
                                                                startingYear:
                                                                    invoiceData.startingYear ??
                                                                    undefined,
                                                                block:
                                                                    invoiceData.block ??
                                                                    undefined,
                                                            },
                                                        );
                                                    }
                                                }}
                                                textStyle={{
                                                    fontSize: fontSize,
                                                }}>
                                                {item.node.pupilDescription}
                                            </InvoiceTableCell>
                                            <InvoiceTableCell
                                                flex={FLEX_ARRAY[3]}
                                                textStyle={{
                                                    fontSize: fontSize,
                                                }}>
                                                {titleCaseConverter(
                                                    item.node
                                                        .instrumentDescription,
                                                )}
                                            </InvoiceTableCell>
                                            <InvoiceTableCell
                                                flex={FLEX_ARRAY[4]}
                                                textStyle={{
                                                    fontSize: fontSize,
                                                }}>
                                                {item.node.typeDescription}
                                            </InvoiceTableCell>
                                            <InvoiceTableCell
                                                flex={FLEX_ARRAY[5]}
                                                hasInnerPressable
                                                textStyle={{
                                                    fontSize: fontSize,
                                                }}>
                                                <Box mx="-0.5em">
                                                    <CurrencyInput
                                                        ref={(ref) => {
                                                            if (
                                                                ref &&
                                                                !blockOtherFeeOrDiscountInputRefs.current.get(
                                                                    item.node
                                                                        ?.id,
                                                                )
                                                            ) {
                                                                blockOtherFeeOrDiscountInputRefs;
                                                                blockOtherFeeOrDiscountInputRefs.current.set(
                                                                    item.node
                                                                        ?.id,
                                                                    ref,
                                                                );
                                                            }
                                                        }}
                                                        _focus={{
                                                            borderWidth: 1,
                                                            borderColor:
                                                                "primary.200",
                                                        }}
                                                        _hover={{
                                                            borderWidth: 1,
                                                            borderColor:
                                                                "primary.400",
                                                        }}
                                                        bg="transparent"
                                                        borderColor="transparent"
                                                        borderRadius="0.5em"
                                                        borderWidth={1}
                                                        fontSize="18"
                                                        fontWeight="light"
                                                        height="2.5em"
                                                        id={`BLOCK_OTHER_FEE_OR_DISCOUNT-${item.node.id}`}
                                                        initiallyValid
                                                        initialValue={
                                                            item.node.unitAmount
                                                        }
                                                        isDisabled={
                                                            invoiceData.status !==
                                                                "DRAFT" ||
                                                            !isEditable
                                                        }
                                                        keyboardType="numeric"
                                                        my="-0.5em"
                                                        onFinishEditing={
                                                            invoiceInputFinishEditingHandler
                                                        }
                                                        pointerEvents={
                                                            invoiceData.status !==
                                                                "DRAFT" ||
                                                            !isEditable
                                                                ? "none"
                                                                : undefined
                                                        }
                                                        px="2"
                                                        updateValueOnInitialValueChange
                                                        width="100%"
                                                    />
                                                </Box>
                                            </InvoiceTableCell>
                                            {invoiceData.status === "DRAFT" &&
                                            isEditable ? (
                                                <Button
                                                    _hover={{
                                                        bg: "transparent",
                                                        opacity: 0.8,
                                                    }}
                                                    _pressed={{
                                                        bg: "transparent",
                                                        opacity: 0.7,
                                                    }}
                                                    leftIcon={
                                                        <Center size="7">
                                                            {removeLineItemInFlight &&
                                                            outerState?.values
                                                                .activeLineItemId ===
                                                                item.node
                                                                    ?.id ? (
                                                                <Spinner color="surface.500" />
                                                            ) : (
                                                                <CloseIcon
                                                                    color="surface.500"
                                                                    size="7"
                                                                />
                                                            )}
                                                        </Center>
                                                    }
                                                    onPress={() =>
                                                        removeLineItemHandler(
                                                            item.node?.id,
                                                        )
                                                    }
                                                    position="absolute"
                                                    right="-45"
                                                    variant="ghost"
                                                />
                                            ) : null}
                                        </HStack>
                                    );
                                } else {
                                    return null;
                                }
                            },
                        ) ?? null}
                        {accountOtherFeeOrDiscountLineItems?.map(
                            (item, index) => {
                                if (item?.node && item.node.unitAmount) {
                                    return (
                                        <HStack
                                            key={index}
                                            mt={index === 0 ? "1em" : 0}
                                            width="100%">
                                            <InvoiceTableCell
                                                flex={FLEX_ARRAY[0]}
                                                isHidden
                                                textStyle={{
                                                    fontSize: fontSize,
                                                }}
                                            />
                                            <InvoiceTableCell
                                                flex={FLEX_ARRAY[1]}
                                                isHidden
                                                textStyle={{
                                                    fontSize: fontSize,
                                                }}
                                            />
                                            <InvoiceTableCell
                                                flex={FLEX_ARRAY[2]}
                                                isHidden
                                                textStyle={{
                                                    fontSize: fontSize,
                                                }}
                                            />
                                            <InvoiceTableCell
                                                flex={FLEX_ARRAY[3]}
                                                isHidden
                                                textStyle={{
                                                    fontSize: fontSize,
                                                }}
                                            />
                                            <InvoiceTableCell
                                                flex={FLEX_ARRAY[4]}
                                                textStyle={{
                                                    fontSize: fontSize,
                                                }}>
                                                {item.node.typeDescription}
                                            </InvoiceTableCell>
                                            <InvoiceTableCell
                                                flex={FLEX_ARRAY[5]}
                                                hasInnerPressable
                                                textStyle={{
                                                    fontSize: fontSize,
                                                }}>
                                                <Box mx="-0.5em">
                                                    <CurrencyInput
                                                        ref={(ref) => {
                                                            if (
                                                                ref &&
                                                                !accountOtherFeeOrDiscountInputRefs.current.get(
                                                                    item.node
                                                                        ?.id,
                                                                )
                                                            ) {
                                                                accountOtherFeeOrDiscountInputRefs;
                                                                accountOtherFeeOrDiscountInputRefs.current.set(
                                                                    item.node
                                                                        ?.id,
                                                                    ref,
                                                                );
                                                            }
                                                        }}
                                                        _focus={{
                                                            borderWidth: 1,
                                                            borderColor:
                                                                "primary.200",
                                                        }}
                                                        _hover={{
                                                            borderWidth: 1,
                                                            borderColor:
                                                                "primary.400",
                                                        }}
                                                        bg="transparent"
                                                        borderColor="transparent"
                                                        borderRadius="0.5em"
                                                        borderWidth={1}
                                                        fontSize="18"
                                                        fontWeight="light"
                                                        height="2.5em"
                                                        id={`ACCOUNT_OTHER_FEE_OR_DISCOUNT-${item.node.id}`}
                                                        initiallyValid
                                                        initialValue={
                                                            item.node.unitAmount
                                                        }
                                                        isDisabled={
                                                            invoiceData.status !==
                                                                "DRAFT" ||
                                                            !isEditable
                                                        }
                                                        keyboardType="numeric"
                                                        my="-0.5em"
                                                        onFinishEditing={
                                                            invoiceInputFinishEditingHandler
                                                        }
                                                        pointerEvents={
                                                            invoiceData.status !==
                                                                "DRAFT" ||
                                                            !isEditable
                                                                ? "none"
                                                                : undefined
                                                        }
                                                        px="2"
                                                        updateValueOnInitialValueChange
                                                        width="100%"
                                                    />
                                                </Box>
                                            </InvoiceTableCell>
                                            {invoiceData.status === "DRAFT" &&
                                            isEditable ? (
                                                <Button
                                                    _hover={{
                                                        bg: "transparent",
                                                        opacity: 0.8,
                                                    }}
                                                    _pressed={{
                                                        bg: "transparent",
                                                        opacity: 0.7,
                                                    }}
                                                    leftIcon={
                                                        <Center size="7">
                                                            {removeLineItemInFlight &&
                                                            outerState?.values
                                                                .activeLineItemId ===
                                                                item.node
                                                                    ?.id ? (
                                                                <Spinner color="surface.500" />
                                                            ) : (
                                                                <CloseIcon
                                                                    color="surface.500"
                                                                    size="7"
                                                                />
                                                            )}
                                                        </Center>
                                                    }
                                                    onPress={() =>
                                                        removeLineItemHandler(
                                                            item.node?.id,
                                                        )
                                                    }
                                                    position="absolute"
                                                    right="-45"
                                                    variant="ghost"
                                                />
                                            ) : null}
                                        </HStack>
                                    );
                                } else {
                                    return null;
                                }
                            },
                        ) ?? null}
                        {invoiceData.status === "DRAFT" && isEditable ? (
                            renderLineItemModal
                        ) : (
                            <Box height="2em" />
                        )}
                        <HStack
                            justifyContent="flex-end"
                            mt={
                                invoiceData.status !== "DRAFT"
                                    ? "2em"
                                    : undefined
                            }
                            width="100%"
                            zIndex={1}>
                            <HStack width="300">
                                <InvoiceTableCell
                                    isHeader
                                    textStyle={{ fontSize: fontSize }}>
                                    {"Total"}
                                </InvoiceTableCell>
                                <InvoiceTableCell
                                    textStyle={{ fontSize: fontSize }}>
                                    {formatCurrency(invoiceData.totalAmount)}
                                </InvoiceTableCell>
                            </HStack>
                        </HStack>
                        <Center mt="4em" width="100%">
                            <VStack
                                borderColor="surface.200"
                                borderRadius="0.5em"
                                borderWidth="1"
                                px="5em"
                                py="1em">
                                <Text
                                    fontSize="18"
                                    fontWeight="light"
                                    textAlign="center">
                                    <Text fontWeight="bold">Payable to:</Text>
                                    {`\nPianoFunClub Partnership\nAccount Number: 24346047\nSort Code: 50 30 05\nPayment Reference: ${
                                        invoiceData.assignedTo?.accountNumber ??
                                        ""
                                    }`}
                                </Text>
                            </VStack>
                        </Center>
                    </VStack>
                </Box>
            </Box>
        );
    } else {
        return null;
    }
};

export default Invoice;
