import React from "react";
import type { ReactElement } from "react";

import type {
    CompositeNavigationProp,
    RouteProp,
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import type { StackNavigationProp } from "@react-navigation/stack";

import AccountScreen, {
    screenOptions as accountScreenOptions,
} from "../screens/accounts/AccountScreen";
import AccountsHubScreen, {
    screenOptions as accountsHubScreenOptions,
} from "../screens/accounts/AccountsHubScreen";
import InvoiceScreen, {
    screenOptions as invoiceScreenOptions,
} from "../screens/invoicing/InvoiceScreen";
import DefaultTermDatesScreen, {
    screenOptions as defaultTermDatesScreenOptions,
} from "../screens/schools/DefaultTermDatesScreen";
import SchoolScreen, {
    screenOptions as schoolScreenOptions,
} from "../screens/schools/SchoolScreen";

import type { HubTabNavigatorProps } from "./HubNavigator";
import defaultNavOptions from "./utils/DefaultNavigationOptions";
import type {
    AccountParams,
    SchoolParams,
    InvoiceParams,
    DefaultTermDatesParams,
} from "./utils/ScreenParams";

export type AccountsStackNavigatorParamList = {
    Account: AccountParams;
    AccountsHub?: {
        currentPageIndex?: number;
        discountType?: string;
        orderBy?: string;
        school?: string;
        searchTerm?: string;
        status?: string;
    };
    DefaultTermDates: DefaultTermDatesParams;
    Invoice: InvoiceParams;
    School: SchoolParams;
};

type NavigatorProps<T extends keyof AccountsStackNavigatorParamList> =
    StackNavigationProp<AccountsStackNavigatorParamList, T>;

export type AccountsStackNavigatorProps<
    T extends keyof AccountsStackNavigatorParamList,
> = CompositeNavigationProp<
    NavigatorProps<T>,
    HubTabNavigatorProps<"AccountsHubTab">
>;

export type AccountsStackRouteProps<
    T extends keyof AccountsStackNavigatorParamList,
> = RouteProp<AccountsStackNavigatorParamList, T>;

const AccountsStackNavigator =
    createStackNavigator<AccountsStackNavigatorParamList>();

const AccountsNavigator = (): ReactElement => {
    return (
        <AccountsStackNavigator.Navigator screenOptions={defaultNavOptions}>
            <AccountsStackNavigator.Screen
                component={AccountsHubScreen}
                name="AccountsHub"
                options={accountsHubScreenOptions}
            />
            <AccountsStackNavigator.Screen
                component={AccountScreen}
                name="Account"
                options={accountScreenOptions}
            />
            <AccountsStackNavigator.Screen
                component={SchoolScreen}
                name="School"
                options={schoolScreenOptions}
            />
            <AccountsStackNavigator.Screen
                component={InvoiceScreen}
                name="Invoice"
                options={invoiceScreenOptions}
            />
            <AccountsStackNavigator.Screen
                component={DefaultTermDatesScreen}
                name="DefaultTermDates"
                options={defaultTermDatesScreenOptions}
            />
        </AccountsStackNavigator.Navigator>
    );
};

export default AccountsNavigator;
