/**
 * @generated SignedSource<<223f3d04b831b9f93de4b6b28939980a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateBlacklistedDateInput = {
  clientMutationId?: string | null;
  date: string;
  reason?: string | null;
  schoolId?: string | null;
  teacherIds?: ReadonlyArray<string | null> | null;
};
export type CreateBlacklistedDateMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateBlacklistedDateInput;
};
export type CreateBlacklistedDateMutation$data = {
  readonly createBlacklistedDate: {
    readonly blacklistedDate: {
      readonly date: string;
      readonly id: string;
      readonly reason: string | null;
      readonly school: {
        readonly id: string;
      } | null;
      readonly teachers: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly user: {
              readonly firstName: string;
              readonly id: string;
              readonly lastName: string;
            };
          } | null;
        } | null>;
      };
    } | null;
    readonly errors: any | null;
    readonly numberOfAffectedExistingLessons: number | null;
    readonly success: boolean | null;
  } | null;
};
export type CreateBlacklistedDateMutation = {
  response: CreateBlacklistedDateMutation$data;
  variables: CreateBlacklistedDateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfAffectedExistingLessons",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "BlacklistedDateNode",
  "kind": "LinkedField",
  "name": "blacklistedDate",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SchoolNode",
      "kind": "LinkedField",
      "name": "school",
      "plural": false,
      "selections": [
        (v5/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileNodeConnection",
      "kind": "LinkedField",
      "name": "teachers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProfileNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DjangoUserNode",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v5/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastName",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateBlacklistedDateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateBlacklistedDatePayload",
        "kind": "LinkedField",
        "name": "createBlacklistedDate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateBlacklistedDateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateBlacklistedDatePayload",
        "kind": "LinkedField",
        "name": "createBlacklistedDate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "blacklistedDate",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "BlacklistedDateNodeEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "94dab44ce1f4d768b1cc15e1a6ec023f",
    "id": null,
    "metadata": {},
    "name": "CreateBlacklistedDateMutation",
    "operationKind": "mutation",
    "text": "mutation CreateBlacklistedDateMutation(\n  $input: CreateBlacklistedDateInput!\n) {\n  createBlacklistedDate(input: $input) {\n    success\n    errors\n    numberOfAffectedExistingLessons\n    blacklistedDate {\n      id\n      date\n      reason\n      school {\n        id\n      }\n      teachers {\n        edges {\n          node {\n            id\n            user {\n              id\n              firstName\n              lastName\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "72bb4dd699ea57ff8871b799b1264eba";

export default node;
