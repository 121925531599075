import React, {
    useRef,
    useCallback,
    useMemo,
    useReducer,
    useEffect,
    Suspense,
    useState,
} from "react";
import type { ReactElement } from "react";

import emoji from "emoji-dictionary";
import {
    Modal,
    VStack,
    Text,
    Box,
    Pressable,
    Checkbox,
    Center,
    PresenceTransition,
    HStack,
    Button,
    useToast,
} from "native-base";
import type { Mutable } from "pianofunclub-shared/types";
import {
    useMutation,
    usePaginationFragment,
    usePreloadedQuery,
    useQueryLoader,
    useRelayEnvironment,
} from "react-relay";
import type { PreloadedQuery } from "react-relay";
import {
    RecyclerListView,
    DataProvider,
    LayoutProvider,
} from "recyclerlistview";
import { fetchQuery } from "relay-runtime";
import type { Subscription } from "relay-runtime";

import type {
    EnrolmentMutation,
    EnrolmentMutation$data,
} from "pianofunclub-shared/relay/graphql/accounts/__generated__/EnrolmentMutation.graphql";
import type {
    LoadAccountsForModal_query_profiles$key,
    LoadAccountsForModal_query_profiles$data,
} from "pianofunclub-shared/relay/graphql/accounts/__generated__/LoadAccountsForModal_query_profiles.graphql";
import type { LoadAccountsForModalPaginationQuery } from "pianofunclub-shared/relay/graphql/accounts/__generated__/LoadAccountsForModalPaginationQuery.graphql";
import type { LoadAccountsForModalQuery } from "pianofunclub-shared/relay/graphql/accounts/__generated__/LoadAccountsForModalQuery.graphql";
import type {
    LoadEnrolments_query_enrolments$data,
    LoadEnrolments_query_enrolments$key,
} from "pianofunclub-shared/relay/graphql/accounts/__generated__/LoadEnrolments_query_enrolments.graphql";
import type { LoadEnrolmentsPaginationQuery } from "pianofunclub-shared/relay/graphql/accounts/__generated__/LoadEnrolmentsPaginationQuery.graphql";
import type { LoadEnrolmentsQuery } from "pianofunclub-shared/relay/graphql/accounts/__generated__/LoadEnrolmentsQuery.graphql";
import { enrolment_query } from "pianofunclub-shared/relay/graphql/accounts/Enrolment";
import {
    load_accounts_for_modal,
    load_accounts_for_modal_pagination,
} from "pianofunclub-shared/relay/graphql/accounts/LoadAccountsForModal";
import {
    load_enrolments,
    load_enrolments_pagination,
} from "pianofunclub-shared/relay/graphql/accounts/LoadEnrolments";

import { NO_STAGE } from "../../utils/constants";
import { useDebounceFunction } from "../../utils/hooks";
import LoadingBlobs from "../Animations/LoadingBlobs";
import ButtonDebounced from "../Buttons/ButtonDebounced";
import TopTabBar from "../Buttons/TopTabBar";
import ToastAlert from "../NativeBaseExtended/ToastAlert";
import { CloseIcon } from "../Other/Icons";

import DayPickerModal from "./DayPickerModal";

import ListEmptyBanner from "pianofunclub-shared/components/ListItems/ListEmptyBanner";
import CustomFlatListSpinner from "pianofunclub-shared/components/Other/CustomFlatListSpinner";
import SearchBar from "pianofunclub-shared/components/Other/SearchBar";

import { schoolYearConverter } from "pianofunclub-shared/utils/converters";
import { getFullName } from "pianofunclub-shared/utils/extractors";
import { createReducer } from "pianofunclub-shared/utils/reducers";

interface Props {
    dayIndex?: number;
    detailType?: "TEACHER" | "PUPIL" | "TEACHER_AND_DAY";
    hideModal: () => void;
    keepFree?: boolean;
    lessonBlockIds?: string[];
    lessonBlocksConnectionId?: string;
    loadEnrolmentsQueryReference: PreloadedQuery<
        LoadEnrolmentsQuery,
        Record<string, unknown>
    >;
    loadPupilsQueryReference: PreloadedQuery<
        LoadAccountsForModalQuery,
        Record<string, unknown>
    >;
    loadTeachersQueryReference: PreloadedQuery<
        LoadAccountsForModalQuery,
        Record<string, unknown>
    >;
    onUpdate?: () => void;
    refreshHandler?: () => void;
    schoolName?: string;
    selectedBlock?: number;
    selectedProfileFullName?: string;
    selectedProfileId?: string;
    selectedStartingYear?: number;
    showModal: boolean;
    updateLessonBlockDetails: (
        variables: {
            instrument?: string | null;
            keepFree?: boolean;
            lessonBlockIds: string[];
            pupilId?: string | null;
            staffNoteForTeacher?: string;
            stageId?: string | null;
            teacherId?: string | null;
            teacherNoteForStaff?: string;
        },
        lessonBlockCursor?: string,
        onComplete?: () => void,
    ) => void;
    updateLessonBlockDetailsInFlight: boolean;
    updateScheduledLessonBlockTime?: (
        lessonBlockIds: string[],
        dayIndex: number,
        hours?: number,
        minutes?: number,
        onComplete?: () => void,
        overrideRescheduledLessons?: boolean,
    ) => void;
    updateScheduledLessonBlockTimeInFlight?: boolean;
}

type ReducerValues = {
    contentIsRendered: boolean;
    currentPageIndex: number;
    dataProvider: DataProvider;
    isRefetching: boolean;
    keepFree?: boolean;
    lessonBlockIds?: string[];
    refreshHandler?: () => void;
    searchTerm: string;
    selectedAccount?: {
        fullName: string | undefined;
        id: string;
    };
    subscription?: Subscription;
};

type ReducerTypes =
    | string
    | string[]
    | boolean
    | number
    | Subscription
    | DataProvider
    | {
          fullName: string | undefined;
          id: string;
      }
    | (() => void)
    | undefined;

const LOAD_X_PROFILES = 12;

const UpdateLessonBlockTeacherOrPupilModal = (
    props: Props,
): ReactElement | null => {
    const {
        dayIndex,
        detailType,
        hideModal,
        keepFree,
        lessonBlockIds,
        lessonBlocksConnectionId,
        loadEnrolmentsQueryReference,
        loadPupilsQueryReference,
        loadTeachersQueryReference,
        onUpdate,
        schoolName,
        selectedBlock,
        selectedProfileFullName,
        selectedProfileId,
        selectedStartingYear,
        showModal,
        updateLessonBlockDetails,
        updateLessonBlockDetailsInFlight,
        updateScheduledLessonBlockTime,
        updateScheduledLessonBlockTimeInFlight,
    } = props;

    const detailTypeText =
        detailType === "TEACHER" || detailType === "TEACHER_AND_DAY"
            ? "Teacher"
            : "Pupil";

    const initialState = useMemo(() => {
        return {
            values: {
                searchTerm: "",
                isRefetching: false,
                subscription: undefined,
                dataProvider: new DataProvider((r1, r2) => {
                    return r1 !== r2;
                }),
                contentIsRendered: false,
                keepFree: keepFree,
                selectedAccount: selectedProfileId
                    ? {
                          id: selectedProfileId,
                          fullName: selectedProfileFullName,
                      }
                    : undefined,
                currentPageIndex: 0,
                refreshHandler: undefined,
                lessonBlockIds: lessonBlockIds,
            },
        };
    }, [keepFree, lessonBlockIds, selectedProfileFullName, selectedProfileId]);

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [state, dispatchState] = useReducer(reducer, initialState);

    const data = usePreloadedQuery(
        load_accounts_for_modal,
        detailType === "TEACHER" || detailType === "TEACHER_AND_DAY"
            ? loadTeachersQueryReference
            : loadPupilsQueryReference,
    );

    const waitingListData = usePreloadedQuery(
        load_enrolments,
        loadEnrolmentsQueryReference,
    );

    const accountsData = usePaginationFragment<
        LoadAccountsForModalPaginationQuery,
        LoadAccountsForModal_query_profiles$key
    >(load_accounts_for_modal_pagination, data);

    const enrolmentsData = usePaginationFragment<
        LoadEnrolmentsPaginationQuery,
        LoadEnrolments_query_enrolments$key
    >(load_enrolments_pagination, waitingListData);

    const [commitEnrolment, enrolmentInFlight] =
        useMutation<EnrolmentMutation>(enrolment_query);

    const toast = useToast();

    useEffect(() => {
        if (state.values.currentPageIndex == 0) {
            if (
                accountsData.data.profiles?.edges &&
                accountsData.data.profiles.edges.length > 0
            ) {
                dispatchState({
                    input: "dataProvider",
                    value: state.values.dataProvider.cloneWithRows(
                        accountsData.data.profiles.edges as Mutable<
                            typeof accountsData.data.profiles.edges
                        >,
                    ),
                });
                // if there is no data, create a fresh DataProvider
            } else {
                dispatchState({
                    input: "dataProvider",
                    value: new DataProvider((r1, r2) => {
                        return r1 !== r2;
                    }),
                });
            }
        } else if (state.values.currentPageIndex == 1) {
            if (
                enrolmentsData.data.enrolments?.edges &&
                enrolmentsData.data.enrolments?.edges.length > 0
            ) {
                dispatchState({
                    input: "dataProvider",
                    value: state.values.dataProvider.cloneWithRows(
                        enrolmentsData.data.enrolments?.edges as Mutable<
                            typeof enrolmentsData.data.enrolments.edges
                        >,
                    ),
                });
                // if there is no data, create a fresh DataProvider
            } else {
                dispatchState({
                    input: "dataProvider",
                    value: new DataProvider((r1, r2) => {
                        return r1 !== r2;
                    }),
                });
            }
        }

        dispatchState({
            input: "refreshHandler",
            value: () => {
                dispatchState({ input: "contentIsRendered", value: false });
                enrolmentsData.refetch(
                    {
                        first: LOAD_X_PROFILES,
                        searchTerm: state.values.searchTerm.trim(),
                        orderBy: "positionInWaitingList",
                    },
                    {
                        fetchPolicy: "network-only",
                    },
                );
            },
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.values.currentPageIndex, enrolmentsData.data, accountsData.data]);

    const layoutProvider = useMemo(() => {
        return new LayoutProvider(
            () => "VSEL",
            (type, dim) => {
                switch (type) {
                    case "VSEL":
                        dim.width = 800;
                        dim.height = 43;
                        break;
                    default:
                        dim.width = 0;
                        dim.height = 0;
                }
            },
        );
    }, []);

    const onSearchEndReached = useCallback(() => {
        if (
            state.values.currentPageIndex == 0 &&
            accountsData.hasNext &&
            !accountsData.isLoadingNext
        ) {
            accountsData.loadNext(LOAD_X_PROFILES);
        }

        if (
            state.values.currentPageIndex == 1 &&
            enrolmentsData.hasNext &&
            !enrolmentsData.isLoadingNext
        ) {
            enrolmentsData.loadNext(LOAD_X_PROFILES);
        }
    }, [accountsData, enrolmentsData, state.values.currentPageIndex]);

    // stop refetch useEffects firing on first render
    const renderCount = useRef(0);
    const environment = useRelayEnvironment();

    useDebounceFunction(
        () => {
            if (renderCount.current < 1) {
                renderCount.current += 1;
                return;
            }

            state.values.subscription?.unsubscribe();
            dispatchState({ input: "isRefetching", value: true });

            if (state.values.currentPageIndex == 0) {
                const variables = {
                    searchTerm:
                        state.values.searchTerm.trim() !== ""
                            ? state.values.searchTerm.trim()
                            : undefined,
                    orderBy: "user__firstName,user__lastName",
                    accountType:
                        detailType === "TEACHER_AND_DAY" ||
                        detailType === "TEACHER"
                            ? "TEACHER"
                            : "PUPIL",
                    school:
                        detailType === "TEACHER" ||
                        detailType === "TEACHER_AND_DAY"
                            ? undefined
                            : schoolName,
                    first: LOAD_X_PROFILES,
                };

                dispatchState({
                    input: "subscription",
                    value: fetchQuery<LoadAccountsForModalQuery>(
                        environment,
                        load_accounts_for_modal,
                        variables,
                        {
                            fetchPolicy: "store-or-network",
                        },
                    ).subscribe({
                        complete: () => {
                            dispatchState({
                                input: "isRefetching",
                                value: false,
                            });
                            accountsData.refetch(variables, {
                                fetchPolicy: "store-only",
                            });
                        },
                        error: () => {
                            dispatchState({
                                input: "isRefetching",
                                value: false,
                            });
                        },
                    }),
                });
            } else if (state.values.currentPageIndex == 1) {
                const variables = {
                    searchTerm:
                        state.values.searchTerm.trim() !== ""
                            ? state.values.searchTerm.trim()
                            : undefined,
                    orderBy: "positionInWaitingList",
                    school: schoolName,
                    first: LOAD_X_PROFILES,
                };

                dispatchState({
                    input: "subscription",
                    value: fetchQuery<LoadEnrolmentsQuery>(
                        environment,
                        load_enrolments,
                        variables,
                        {
                            fetchPolicy: "network-only",
                        },
                    ).subscribe({
                        complete: () => {
                            dispatchState({
                                input: "isRefetching",
                                value: false,
                            });
                            enrolmentsData.refetch(variables, {
                                fetchPolicy: "network-only",
                            });
                        },
                        error: () => {
                            dispatchState({
                                input: "isRefetching",
                                value: false,
                            });
                        },
                    }),
                });
            }
        }, // no delay if clearing text
        state.values.searchTerm !== "" ? 750 : 0,

        [state.values.searchTerm, state.values.currentPageIndex, schoolName],
    );

    const inputChangeHandler = useCallback(
        (_: unknown, inputValue?: string) => {
            dispatchState({
                input: "searchTerm",
                value: inputValue,
            });
        },
        [dispatchState],
    );

    const inputClearHandler = useCallback(() => {
        if (state.values.searchTerm !== "") {
            dispatchState({
                input: "searchTerm",
                value: "",
            });
        }
    }, [dispatchState, state.values.searchTerm]);

    const checkboxChangeHandler = useCallback((isChecked: boolean) => {
        dispatchState({ input: "keepFree", value: isChecked });
    }, []);

    const updateLessonBlockDetailsHandler = useCallback(
        (variables: {
            instrument?: string | null;
            keepFree?: boolean;
            pupilId?: string | null;
            stageId?: string | null;
            teacherId?: string | null;
        }) => {
            if (lessonBlockIds && lessonBlockIds.length > 0) {
                updateLessonBlockDetails(
                    {
                        ...variables,
                        lessonBlockIds: lessonBlockIds,
                    },
                    undefined,
                    () => {
                        hideModal();
                        onUpdate?.();
                    },
                );
            }
        },
        [hideModal, lessonBlockIds, onUpdate, updateLessonBlockDetails],
    );

    const enrolment = useCallback(
        (
            variables: {
                block: number;
                enrolmentId: string;
                startingYear: number;
            },
            onComplete?: () => void,
        ) => {
            const enrolmentConfig = {
                variables: {
                    input: {
                        enrolmentId: variables.enrolmentId,
                        startingYear: variables.startingYear,
                        block: variables.block,
                    },
                },
                onCompleted: (response: EnrolmentMutation$data) => {
                    if (response?.enrolment?.success) {
                        // refresh on complete
                        state.values.refreshHandler?.();

                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={"Successfully enroled pupil"}
                                    toast={toast}
                                />
                            ),
                        });

                        // Assign the newly enrolled pupil to the selected lesson block
                        updateLessonBlockDetailsHandler({
                            pupilId: response?.enrolment.enrolledProfileId,
                            stageId: response?.enrolment.stageId,
                            instrument: response?.enrolment.instrumentName,
                            keepFree: !state.values.selectedAccount?.id
                                ? state.values.keepFree
                                : false,
                        });

                        onComplete?.();
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        response?.enrolment?.errors[0].message
                                    }
                                    id={id}
                                    status="error"
                                    title={"Failed to enrol pupil"}
                                    toast={toast}
                                />
                            ),
                            duration: null,
                        });
                    }
                },
            };

            commitEnrolment(enrolmentConfig);
        },
        [commitEnrolment, state.values, toast, updateLessonBlockDetailsHandler],
    );

    const enrolmentHandler = useCallback(
        (startingYear: number, block: number, enrolmentId?: string) => {
            if (enrolmentId || state.values.selectedAccount?.id) {
                enrolment(
                    {
                        enrolmentId: (enrolmentId ??
                            state.values.selectedAccount?.id) as string,
                        startingYear: startingYear,
                        block: block,
                    },
                    () => {
                        hideModal();
                        onUpdate?.();
                    },
                );
            }
        },
        [enrolment, hideModal, onUpdate, state.values.selectedAccount?.id],
    );

    const accountsItemPressHandler = useCallback(
        (
            item: NonNullable<
                LoadAccountsForModal_query_profiles$data["profiles"]
            >["edges"][0],
            fullName: string,
        ) => {
            if (item?.node) {
                dispatchState({
                    input: "selectedAccount",
                    value: {
                        id: item.node.id,
                        fullName: fullName,
                    },
                });
                dispatchState({
                    input: "keepFree",
                    value: false,
                });
            }
        },
        [],
    );

    const waitingListItemPressHandler = useCallback(
        (
            item: NonNullable<
                LoadEnrolments_query_enrolments$data["enrolments"]
            >["edges"][0],
            fullName: string,
        ) => {
            if (item?.node) {
                dispatchState({
                    input: "selectedAccount",
                    value: {
                        id: item.node.id,
                        fullName: fullName,
                    },
                });
                dispatchState({
                    input: "keepFree",
                    value: false,
                });
            }
        },
        [],
    );

    const accountsRowRenderer = useCallback(
        (
            _: unknown,
            item: NonNullable<
                LoadAccountsForModal_query_profiles$data["profiles"]
            >["edges"][0],
            index: number,
        ): ReactElement | null => {
            if (item?.node) {
                const label = item.node.user.firstName
                    ? getFullName(
                          item.node.user.firstName,
                          item.node.user.lastName,
                      )
                    : item.node.user.email;
                return (
                    <Pressable
                        key={index}
                        _hover={{ opacity: 0.8 }}
                        _pressed={{ opacity: 0.7 }}
                        bg="surface.50"
                        borderBottomWidth={1}
                        borderColor="surface.200"
                        borderTopWidth={index === 0 ? 1 : 0}
                        onPress={() => accountsItemPressHandler(item, label)}
                        px="4"
                        py="2"
                    >
                        <Text
                            fontFamily="Poppins-Regular"
                            fontSize="md"
                            numberOfLines={1}
                        >
                            {label}
                            {detailType === "PUPIL" &&
                            schoolName &&
                            item.node.school?.name
                                ? ` • ${item.node.school.name}`
                                : ""}
                            {detailType === "PUPIL" && item.node.schoolYear
                                ? ` • ${schoolYearConverter(
                                      item.node.schoolYear,
                                  )}`
                                : ""}
                        </Text>
                    </Pressable>
                );
            } else {
                return null;
            }
        },
        [detailType, accountsItemPressHandler, schoolName],
    );

    const waitingListRowRenderer = useCallback(
        (
            _: unknown,
            item: NonNullable<
                LoadEnrolments_query_enrolments$data["enrolments"]
            >["edges"][0],
            index: number,
        ): ReactElement | null => {
            if (item?.node) {
                const label = item.node.pupilFirstName
                    ? getFullName(
                          item.node.pupilFirstName ?? "",
                          item.node.pupilLastName ?? "",
                      )
                    : "";
                return (
                    <Pressable
                        key={index}
                        _hover={{ opacity: 0.8 }}
                        _pressed={{ opacity: 0.7 }}
                        bg="surface.50"
                        borderBottomWidth={1}
                        borderColor="surface.200"
                        borderTopWidth={index === 0 ? 1 : 0}
                        onPress={() => waitingListItemPressHandler(item, label)}
                        px="4"
                        py="2"
                    >
                        <Text
                            fontFamily="Poppins-Regular"
                            fontSize="md"
                            numberOfLines={1}
                        >
                            {item.node.positionInWaitingList}
                            {" • "}
                            {label}
                            {" • "}
                            {item.node.instrument}
                            {" • "}
                            {item.node.lessonStage &&
                            parseInt(item.node.lessonStage) !== NO_STAGE
                                ? `Stage ${item.node.lessonStage}`
                                : "Any Stage"}
                            {" • "}
                            {detailType === "PUPIL" &&
                            item.node.schoolYear === 0
                                ? "Reception"
                                : ""}
                            {detailType === "PUPIL" &&
                            item.node.schoolYear !== 0
                                ? `${schoolYearConverter(item.node.schoolYear)}`
                                : ""}
                            {detailType === "PUPIL" &&
                            schoolName &&
                            item.node.schoolName
                                ? ` • ${item.node.schoolName}`
                                : ""}
                        </Text>
                    </Pressable>
                );
            } else {
                return null;
            }
        },
        [detailType, schoolName, waitingListItemPressHandler],
    );

    const renderFooter = useMemo(() => {
        const renderFooter = () =>
            accountsData.isLoadingNext || enrolmentsData.isLoadingNext ? (
                <CustomFlatListSpinner position="relative" py="4" top="0" />
            ) : null;
        return renderFooter;
    }, [accountsData.isLoadingNext, enrolmentsData.isLoadingNext]);

    const renderRefetchIndicator = useMemo(() => {
        return state.values.isRefetching ? (
            <CustomFlatListSpinner top="4" />
        ) : null;
    }, [state.values.isRefetching]);

    const renderAccountsSearchResults = useMemo(() => {
        return state.values.dataProvider.getSize() > 0 ? (
            <RecyclerListView
                dataProvider={state.values.dataProvider}
                layoutProvider={layoutProvider}
                onEndReached={onSearchEndReached}
                onEndReachedThreshold={100}
                renderFooter={renderFooter}
                rowRenderer={accountsRowRenderer}
                scrollViewProps={{
                    contentContainerStyle: { marginBottom: 16 },
                }}
                style={{
                    flex: 1,
                    minHeight: 1,
                }}
                suppressBoundedSizeException
                useWindowScroll
            />
        ) : !state.values.contentIsRendered ? (
            <LoadingBlobs bg="transparent" textProps={{ color: "surface.900" }}>
                Loading...
            </LoadingBlobs>
        ) : !state.values.isRefetching ? (
            <Center flex={1} flexGrow={1}>
                <PresenceTransition
                    animate={{
                        opacity: 1,
                        transition: {
                            delay: 500,
                            duration: 300,
                        },
                    }}
                    initial={{
                        opacity: 0,
                    }}
                    visible={true}
                >
                    <ListEmptyBanner explainer={"No accounts found..."}>
                        <Text fontSize="6xl">
                            {emoji.getUnicode("neutral_face")}
                        </Text>
                    </ListEmptyBanner>
                </PresenceTransition>
            </Center>
        ) : null;
    }, [
        state.values.dataProvider,
        state.values.isRefetching,
        state.values.contentIsRendered,
        accountsRowRenderer,
        layoutProvider,
        onSearchEndReached,
        renderFooter,
    ]);

    const renderWaitingListSearchResults = useMemo(() => {
        return state.values.dataProvider.getSize() > 0 ? (
            <RecyclerListView
                dataProvider={state.values.dataProvider}
                layoutProvider={layoutProvider}
                onEndReached={onSearchEndReached}
                onEndReachedThreshold={100}
                renderFooter={renderFooter}
                rowRenderer={waitingListRowRenderer}
                scrollViewProps={{
                    contentContainerStyle: { marginBottom: 16 },
                }}
                style={{
                    flex: 1,
                    minHeight: 1,
                }}
                suppressBoundedSizeException
                useWindowScroll
            />
        ) : !state.values.contentIsRendered ? (
            <LoadingBlobs bg="transparent" textProps={{ color: "surface.900" }}>
                Loading...
            </LoadingBlobs>
        ) : !state.values.isRefetching ? (
            <Center flex={1} flexGrow={1}>
                <PresenceTransition
                    animate={{
                        opacity: 1,
                        transition: {
                            delay: 500,
                            duration: 300,
                        },
                    }}
                    initial={{
                        opacity: 0,
                    }}
                    visible={true}
                >
                    <ListEmptyBanner explainer={"No enrolments found..."}>
                        <Text fontSize="6xl">
                            {emoji.getUnicode("neutral_face")}
                        </Text>
                    </ListEmptyBanner>
                </PresenceTransition>
            </Center>
        ) : null;
    }, [
        state.values.dataProvider,
        state.values.isRefetching,
        state.values.contentIsRendered,
        waitingListRowRenderer,
        layoutProvider,
        onSearchEndReached,
        renderFooter,
    ]);

    const renderSelectedAccountCard = useMemo(() => {
        return (
            <VStack alignItems="center" space="2" width="100%">
                <Text color="surface.100" fontSize="md">
                    {`Selected ${detailTypeText}`}
                </Text>
                <HStack
                    bg={
                        state.values.selectedAccount
                            ? "primary.500"
                            : "muted.400"
                    }
                    borderRadius="2xl"
                    height="60px"
                    maxWidth="70%"
                    px="4"
                    py="3"
                    shadow={1}
                >
                    {state.values.selectedAccount ? (
                        <>
                            <Text
                                color="surface.100"
                                fontFamily="Poppins-Regular"
                                fontSize="md"
                                isTruncated
                                numberOfLines={1}
                            >
                                {state.values.selectedAccount.fullName}
                                {schoolName && detailType === "PUPIL"
                                    ? ` • ${schoolName}`
                                    : ""}
                            </Text>
                            <Button
                                _hover={{
                                    bg: "transparent",
                                    opacity: 0.8,
                                }}
                                _pressed={{
                                    bg: "transparent",
                                    opacity: 0.7,
                                }}
                                leftIcon={
                                    <CloseIcon color="surface.100" size="6" />
                                }
                                ml="2"
                                onPress={() =>
                                    dispatchState({
                                        input: "selectedAccount",
                                        value: undefined,
                                    })
                                }
                                p="1"
                                variant="ghost"
                            />
                        </>
                    ) : (
                        <Text
                            color="surface.100"
                            fontFamily="Poppins-Regular"
                            fontSize="md"
                        >
                            {`No ${detailTypeText} Selected`}
                        </Text>
                    )}
                </HStack>
            </VStack>
        );
    }, [detailType, detailTypeText, schoolName, state.values.selectedAccount]);

    const searchBarRef = useRef(null);

    const [dayPickerAfterTeacherIsOpen, setDayPickerAfterTeacherIsOpen] =
        useState(false);

    useEffect(() => {
        setTimeout(() => {
            dispatchState({ input: "contentIsRendered", value: true });
        }, 1000);
        // unsubscribe from refetch on unmount
        return () => state.values.subscription?.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderEnrolledProfiles = useMemo(() => {
        return (
            <>
                <VStack flex={1} space="6" width="85%">
                    <SearchBar
                        ref={searchBarRef}
                        inputClearHandler={inputClearHandler}
                        inputOnChangeHandler={inputChangeHandler}
                        inputSearchHandler={inputChangeHandler}
                        placeholderText={`Search for a ${detailTypeText.toLowerCase()}`}
                        searchText={state.values.searchTerm}
                        showSearchIcon
                    />
                    <Box
                        bg="surface.100"
                        borderRadius="2xl"
                        height="300px"
                        overflow="hidden"
                        width="100%"
                    >
                        {renderAccountsSearchResults}
                        {renderRefetchIndicator}
                    </Box>
                    <VStack alignItems="center" space="6">
                        {renderSelectedAccountCard}
                        {detailType === "PUPIL" ? (
                            <PresenceTransition
                                animate={{
                                    opacity: 1,
                                    transition: {
                                        duration: 500,
                                    },
                                }}
                                initial={{
                                    opacity: 0,
                                }}
                                visible={!state.values.selectedAccount}
                            >
                                <HStack space="4">
                                    <Text color="surface.100" fontSize="md">
                                        {"Mark slot as "}
                                        <Text fontStyle="italic">
                                            keep free
                                        </Text>
                                    </Text>
                                    <Checkbox
                                        defaultIsChecked={state.values.keepFree}
                                        onChange={checkboxChangeHandler}
                                        size="lg"
                                        value="keepFree"
                                    />
                                </HStack>
                            </PresenceTransition>
                        ) : null}
                    </VStack>
                </VStack>
                <ButtonDebounced
                    _text={{ fontSize: "xl" }}
                    height="56px"
                    isLoading={updateLessonBlockDetailsInFlight}
                    mb="4"
                    mt="8"
                    onPress={() => {
                        if (detailType === "TEACHER") {
                            updateLessonBlockDetailsHandler({
                                teacherId:
                                    state.values.selectedAccount?.id ?? null,
                                keepFree: !state.values.selectedAccount?.id
                                    ? state.values.keepFree
                                    : false,
                            });
                        } else if (detailType === "PUPIL") {
                            updateLessonBlockDetailsHandler({
                                pupilId:
                                    state.values.selectedAccount?.id ?? null,
                                keepFree: !state.values.selectedAccount?.id
                                    ? state.values.keepFree
                                    : false,
                            });
                        } else if (detailType === "TEACHER_AND_DAY") {
                            dispatchState({
                                input: "lessonBlockIds",
                                value: state.values.lessonBlockIds,
                            });
                            setDayPickerAfterTeacherIsOpen(true);
                        }
                    }}
                    width="160px"
                >
                    {"Save"}
                </ButtonDebounced>
            </>
        );
    }, [
        state.values.searchTerm,
        state.values.selectedAccount,
        state.values.keepFree,
        state.values.lessonBlockIds,
        detailTypeText,
        inputChangeHandler,
        inputClearHandler,
        renderAccountsSearchResults,
        renderRefetchIndicator,
        renderSelectedAccountCard,
        detailType,
        checkboxChangeHandler,
        updateLessonBlockDetailsInFlight,
        updateLessonBlockDetailsHandler,
    ]);

    const renderWaitingList = useMemo(() => {
        return (
            <>
                <VStack flex={1} space="6" width="85%">
                    <SearchBar
                        ref={searchBarRef}
                        inputClearHandler={inputClearHandler}
                        inputOnChangeHandler={inputChangeHandler}
                        inputSearchHandler={inputChangeHandler}
                        placeholderText={"Search for a pupil"}
                        searchText={state.values.searchTerm}
                        showSearchIcon
                    />
                    <Box
                        bg="surface.100"
                        borderRadius="2xl"
                        height="300px"
                        overflow="hidden"
                        width="100%"
                    >
                        {renderWaitingListSearchResults}
                        {renderRefetchIndicator}
                    </Box>
                    <VStack alignItems="center" space="6">
                        {renderSelectedAccountCard}
                        {detailType === "PUPIL" ? (
                            <PresenceTransition
                                animate={{
                                    opacity: 1,
                                    transition: {
                                        duration: 500,
                                    },
                                }}
                                initial={{
                                    opacity: 0,
                                }}
                                visible={!state.values.selectedAccount}
                            >
                                <HStack space="4">
                                    <Text color="surface.100" fontSize="md">
                                        {"Mark slot as "}
                                        <Text fontStyle="italic">
                                            keep free
                                        </Text>
                                    </Text>
                                    <Checkbox
                                        defaultIsChecked={state.values.keepFree}
                                        onChange={checkboxChangeHandler}
                                        size="lg"
                                        value="keepFree"
                                    />
                                </HStack>
                            </PresenceTransition>
                        ) : null}
                    </VStack>
                </VStack>
                <ButtonDebounced
                    _text={{ fontSize: "xl" }}
                    height="56px"
                    isLoading={enrolmentInFlight}
                    mb="4"
                    mt="8"
                    onPress={() => {
                        if (
                            detailType === "PUPIL" &&
                            selectedStartingYear !== undefined &&
                            selectedBlock !== undefined
                        ) {
                            enrolmentHandler(
                                selectedStartingYear,
                                selectedBlock,
                                state.values.selectedAccount?.id ?? undefined,
                            );
                        }
                    }}
                    width="180px"
                >
                    {"Enrol and Save"}
                </ButtonDebounced>
            </>
        );
    }, [
        state.values.searchTerm,
        state.values.selectedAccount,
        state.values.keepFree,
        inputChangeHandler,
        inputClearHandler,
        renderWaitingListSearchResults,
        renderRefetchIndicator,
        renderSelectedAccountCard,
        detailType,
        checkboxChangeHandler,
        enrolmentInFlight,
        selectedStartingYear,
        selectedBlock,
        enrolmentHandler,
    ]);

    if (detailType === null) {
        return null;
    }

    return (
        <>
            <Modal
                initialFocusRef={searchBarRef}
                isOpen={showModal && !dayPickerAfterTeacherIsOpen}
                onClose={() => {
                    hideModal();
                    dispatchState({ input: "searchTerm", value: "" });
                }}
                size="xl"
            >
                <Modal.Content bg="primary.800" maxHeight="92%" maxWidth="35%">
                    <Modal.CloseButton
                        _hover={{ bg: "transparent", opacity: 0.7 }}
                        _icon={{ color: "surface.100" }}
                        _pressed={{ bg: "transparent", opacity: 0.7 }}
                    />
                    <Modal.Header
                        _text={{ color: "surface.100" }}
                        bg="primary.800"
                    >
                        {`Select ${detailTypeText}`}
                    </Modal.Header>
                    <Modal.Body alignItems="center" mb="4" px="6">
                        <>
                            {detailType === "PUPIL" && (
                                <>
                                    <TopTabBar
                                        bg="primary.100"
                                        borderRadius="lg"
                                        buttonStyle={{
                                            // reset filters and ordering when changing tab
                                            bg: "primary.50",
                                            alignItems: "center",
                                            pt: 2,
                                            pb: 2,
                                            _hover: { bg: "primary.200" },
                                            _pressed: { bg: "primary.300" },
                                        }}
                                        currentPageIndex={
                                            state.values.currentPageIndex
                                        }
                                        hideBottomBar
                                        onChangePage={() => {
                                            if (
                                                state.values.searchTerm === ""
                                            ) {
                                                dispatchState({
                                                    input: "orderBy",
                                                    value: "positionInWaitingList",
                                                });
                                            }
                                        }}
                                        overflow="hidden"
                                        pages={["Enrolled", "Waiting List"]}
                                        selectedButtonStyle={{
                                            bg: "primary.100",
                                        }}
                                        selectedTextStyle={{
                                            color: "primary.600",
                                        }}
                                        setCurrentPageIndex={(index) => {
                                            dispatchState({
                                                input: "currentPageIndex",
                                                value: index,
                                            });
                                        }}
                                        shadow={1}
                                        textStyle={{ color: "primary.800" }}
                                        width="100%"
                                    />
                                    {state.values.currentPageIndex === 0 && (
                                        <Box
                                            alignItems={"center"}
                                            flex={1}
                                            justifyContent={"center"}
                                            mt="6"
                                            width="100%"
                                        >
                                            {renderEnrolledProfiles}
                                        </Box>
                                    )}
                                    {state.values.currentPageIndex === 1 && (
                                        <Box
                                            alignItems={"center"}
                                            flex={1}
                                            justifyContent={"center"}
                                            mt="6"
                                            width="100%"
                                        >
                                            {renderWaitingList}
                                        </Box>
                                    )}
                                </>
                            )}
                            {(detailType == "TEACHER" ||
                                detailType == "TEACHER_AND_DAY") && (
                                <>
                                    <Box
                                        alignItems={"center"}
                                        flex={1}
                                        justifyContent={"center"}
                                        mt="6"
                                        width="100%"
                                    >
                                        {renderEnrolledProfiles}
                                    </Box>
                                </>
                            )}
                        </>
                    </Modal.Body>
                </Modal.Content>
            </Modal>
            {updateScheduledLessonBlockTime &&
            state.values.lessonBlockIds &&
            state.values.lessonBlockIds.length > 0 ? (
                <DayPickerModal
                    hideModal={() => {
                        setDayPickerAfterTeacherIsOpen(false);
                        hideModal();
                    }}
                    initialLessonDay={dayIndex}
                    isAfterAssignTeacherStep
                    lessonBlockIds={state.values.lessonBlockIds}
                    lessonBlocksConnectionId={lessonBlocksConnectionId}
                    onUpdate={onUpdate}
                    selectedBlock={selectedBlock ?? 0}
                    selectedProfileId={state.values.selectedAccount?.id ?? ""}
                    selectedStartingYear={selectedStartingYear ?? 0}
                    showModal={dayPickerAfterTeacherIsOpen}
                    updateScheduledLessonBlockTime={
                        updateScheduledLessonBlockTime
                    }
                    updateScheduledLessonBlockTimeInFlight={
                        updateScheduledLessonBlockTimeInFlight ?? false
                    }
                />
            ) : null}
        </>
    );
};

const UpdateLessonBlockTeacherOrPupilModalWrapper = (
    props: Omit<
        Omit<
            Omit<Props, "loadPupilsQueryReference">,
            "loadTeachersQueryReference"
        >,
        "loadEnrolmentsQueryReference"
    >,
): ReactElement => {
    const { detailType, schoolName } = props;

    const [loadTeachersQueryReference, loadTeachersQuery] =
        useQueryLoader<LoadAccountsForModalQuery>(load_accounts_for_modal);

    const [loadPupilsQueryReference, loadPupilsQuery] =
        useQueryLoader<LoadAccountsForModalQuery>(load_accounts_for_modal);

    const [loadEnrolmentsQueryReference, loadEnrolmentsQuery] =
        useQueryLoader<LoadEnrolmentsQuery>(load_enrolments);

    useEffect(() => {
        loadTeachersQuery(
            {
                accountType: "TEACHER",
                first: LOAD_X_PROFILES,
                orderBy: "user__firstName,user__lastName",
            },
            { fetchPolicy: "store-or-network" },
        );
        loadPupilsQuery(
            {
                accountType: "PUPIL",
                school: schoolName,
                first: LOAD_X_PROFILES,
                orderBy: "user__firstName,user__lastName",
            },
            { fetchPolicy: "store-or-network" },
        );
        loadEnrolmentsQuery(
            {
                school: schoolName,
                first: LOAD_X_PROFILES,
                orderBy: "pupilFirstName",
            },
            { fetchPolicy: "store-or-network" },
        );
    }, [loadPupilsQuery, loadTeachersQuery, loadEnrolmentsQuery, schoolName]);

    return loadTeachersQueryReference != null &&
        loadPupilsQueryReference != null &&
        loadEnrolmentsQueryReference != null &&
        detailType != null ? (
        <Suspense fallback={<></>}>
            <UpdateLessonBlockTeacherOrPupilModal
                loadEnrolmentsQueryReference={loadEnrolmentsQueryReference}
                loadPupilsQueryReference={loadPupilsQueryReference}
                loadTeachersQueryReference={loadTeachersQueryReference}
                {...props}
            />
        </Suspense>
    ) : (
        <></>
    );
};

export default React.memo(UpdateLessonBlockTeacherOrPupilModalWrapper);
