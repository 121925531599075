import { graphql } from "react-relay";

export const change_password = graphql`
    mutation ChangePasswordMutation($input: PasswordChangeInput!) {
        passwordChange(input: $input) {
            success
            errors
            token
            refreshToken
        }
    }
`;
