import React, { useRef, useCallback, useMemo, useReducer } from "react";
import type { ReactElement } from "react";

import { Modal, VStack, Text, Box, HStack } from "native-base";

import ButtonDebounced from "pianofunclub-shared/components/Buttons/ButtonDebounced";
import TextInput from "pianofunclub-shared/components/Inputs/TextInput";

import { isNumeric } from "pianofunclub-shared/utils/converters";
import { createFormReducer } from "pianofunclub-shared/utils/reducers";

interface Props {
    addNewLessonBlock: (
        variables: {
            block: number;
            dayIndex: number;
            durationMinutes: number;
            hours: number;
            minutes: number;
            school: string;
            startingYear: number;
            type: string;
        },
        onComplete?: () => void,
    ) => void;
    addNewLessonBlockInFlight: boolean;
    block: number;
    dayIndex: number;
    hours: number;
    minutes: number;
    school: string;
    setShowModal: (isHidden: boolean) => void;
    showModal: boolean;
    startingYear: number;
}

type ReducerValues = {
    lessonDuration?: number;
};

type ReducerTypes = boolean | number | string | undefined;

const CreateBreakBlockModal = (props: Props): ReactElement => {
    const {
        addNewLessonBlock,
        addNewLessonBlockInFlight,
        setShowModal,
        showModal,
    } = props;

    const initialState = useMemo(() => {
        return {
            inputValues: {
                stage: undefined,
                lessonType: undefined,
                lessonDuration: undefined,
                costPerLesson: undefined,
            },
            inputValidities: {
                stage: true,
                lessonType: false,
                lessonDuration: false,
                costPerLesson: false,
            },
            formIsValid: false,
            formIsEdited: false,
        };
    }, []);

    const reducer = createFormReducer<ReducerValues, ReducerTypes>(
        initialState,
    );
    const [state, dispatchState] = useReducer(reducer, initialState);

    const lessonDurationInputRef = useRef<{
        clear: () => void;
        focus: () => void;
    }>();
    const createButtonRef = useRef<{
        focus: () => void;
    }>();

    const inputChangeHandler = useCallback(
        (
            inputIdentifier?: string | number,
            inputValue?: string,
            isValid?: boolean,
        ) => {
            if (
                typeof inputIdentifier === "string" &&
                typeof inputValue === "string"
            ) {
                const cleanedInput = inputValue.replace("£", "");
                dispatchState({
                    input: inputIdentifier,
                    value: isNumeric(cleanedInput)
                        ? parseFloat(cleanedInput)
                        : undefined,
                    isValid: isValid && isNumeric(cleanedInput),
                });
            }
        },
        [],
    );

    const addNewBreakBlockHandler = useCallback(() => {
        createButtonRef.current?.focus();
        if (typeof state.inputValues.lessonDuration === "number") {
            addNewLessonBlock(
                {
                    block: props.block,
                    dayIndex: props.dayIndex,
                    hours: props.hours,
                    minutes: props.minutes,
                    school: props.school,
                    startingYear: props.startingYear,
                    type: "BREAK",
                    durationMinutes: state.inputValues.lessonDuration,
                },
                () => dispatchState({ type: "RESET" }),
            );

            setShowModal(false);
        }
    }, [
        addNewLessonBlock,
        props.block,
        props.dayIndex,
        props.hours,
        props.minutes,
        props.school,
        props.startingYear,
        setShowModal,
        state.inputValues.lessonDuration,
    ]);

    return (
        <Modal
            isOpen={showModal}
            onClose={() => {
                setShowModal(false);
            }}
            size="md"
        >
            <Modal.Content bg="primary.800">
                <Modal.CloseButton
                    _hover={{ bg: "transparent", opacity: 0.7 }}
                    _icon={{ color: "surface.100" }}
                    _pressed={{ bg: "transparent", opacity: 0.7 }}
                />
                <Modal.Header _text={{ color: "surface.100" }} bg="primary.800">
                    Create Break Block
                </Modal.Header>
                <Modal.Body alignItems="center"></Modal.Body>
                <VStack
                    alignItems="center"
                    justifyContent="center"
                    pb="6"
                    pt="-2"
                    space={5}
                >
                    <VStack
                        alignItems="center"
                        justifyContent="center"
                        space="2"
                    >
                        <Text color="surface.100" fontSize="sm">
                            Break Duration
                        </Text>
                        <HStack alignItems="center" space="2">
                            <Box width="70">
                                <TextInput
                                    ref={lessonDurationInputRef}
                                    id="lessonDuration"
                                    initialValue={state.inputValues.lessonDuration?.toString()}
                                    invalidIndicator
                                    keyboardType="number-pad"
                                    maxLength={3}
                                    numOnly
                                    onInputChange={inputChangeHandler}
                                    textAlign="center"
                                />
                            </Box>
                            <Text color="surface.100" fontWeight="light">
                                minutes
                            </Text>
                        </HStack>
                    </VStack>
                    <ButtonDebounced
                        ref={createButtonRef}
                        _text={{ fontSize: "xl" }}
                        height="50px"
                        isLoading={addNewLessonBlockInFlight}
                        mb="4"
                        mt="8"
                        onPress={addNewBreakBlockHandler}
                        width="35%"
                    >
                        Create
                    </ButtonDebounced>
                </VStack>
            </Modal.Content>
        </Modal>
    );
};

export default React.memo(CreateBreakBlockModal);
