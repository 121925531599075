import React, { useCallback, useState, useEffect, useRef } from "react";
import type { ReactElement, Dispatch } from "react";

import {
    Text,
    Pressable,
    VStack,
    Spinner,
    Center,
    Button,
    PresenceTransition,
    Box,
} from "native-base";
import {
    usePreloadedQuery,
    useMutation,
    useRelayEnvironment,
    fetchQuery,
} from "react-relay";
import type {
    PreloadedQuery,
    UseQueryLoaderLoadQueryOptions,
} from "react-relay";

import { useAuth } from "pianofunclub-shared/providers/AuthProvider";

import type {
    ReducerValues as AccountsHubReducerValues,
    ReducerTypes as AccountsHubReducerTypes,
} from "../../screens/accounts/AccountsHubScreen";
import { CloseIcon } from "pianofunclub-shared/components/Other/Icons";

import type { DismissMutationProgressIndicatorMutation } from "pianofunclub-shared/relay/graphql/general/__generated__/DismissMutationProgressIndicatorMutation.graphql";
import type {
    LoadMutationProgressQuery,
    LoadMutationProgressQuery$variables,
} from "pianofunclub-shared/relay/graphql/general/__generated__/LoadMutationProgressQuery.graphql";
import { dismiss_mutation_progress_indicator } from "pianofunclub-shared/relay/graphql/general/DismissMutationProgressIndicator";
import { load_mutation_progress } from "pianofunclub-shared/relay/graphql/general/LoadMutationProgress";

import type { Action, State } from "pianofunclub-shared/utils/reducers";

interface Props {
    dispatchState: Dispatch<
        Action<AccountsHubReducerValues, AccountsHubReducerTypes>
    >;
    loadMutationProgressQuery: (
        variables: LoadMutationProgressQuery$variables,
        options?: UseQueryLoaderLoadQueryOptions | undefined,
    ) => void;
    loadMutationProgressQueryReference: PreloadedQuery<
        LoadMutationProgressQuery,
        Record<string, unknown>
    >;
    state: State<AccountsHubReducerValues>;
}

const AccountMutationProgressIndicators = (props: Props): ReactElement => {
    const {
        dispatchState,
        loadMutationProgressQuery,
        loadMutationProgressQueryReference,
        state,
    } = props;

    const { user } = useAuth();

    const mutationProgressData = usePreloadedQuery(
        load_mutation_progress,
        loadMutationProgressQueryReference,
    );

    const [isRefetching, setIsRefetching] = useState(false);

    const environment = useRelayEnvironment();

    const refreshMutationProgress = useCallback(() => {
        if (isRefetching) {
            return;
        }

        setIsRefetching(true);

        if (user?.profile?.id) {
            fetchQuery<LoadMutationProgressQuery>(
                environment,
                load_mutation_progress,
                {
                    profileId: user.profile.id,
                    skipInvoiceTotals: true,
                    // not actually used but required by GraphQL schema - could be cleaned up
                    startingYear: 2022,
                    block: 1,
                },
                { fetchPolicy: "network-only" },
            ).subscribe({
                complete: () => {
                    setIsRefetching(false);
                    if (user?.profile?.id) {
                        loadMutationProgressQuery(
                            {
                                profileId: user?.profile?.id,
                                skipInvoiceTotals: true,
                                startingYear: 2022,
                                block: 1,
                            },
                            {
                                // @ts-expect-error relay typing error
                                fetchPolicy: "store-only",
                            },
                        );
                    }
                },
                error: () => {
                    setIsRefetching(false);
                },
            });
        }
    }, [
        environment,
        isRefetching,
        loadMutationProgressQuery,
        user?.profile?.id,
    ]);

    const commitDismissMutationProgressIndicator =
        useMutation<DismissMutationProgressIndicatorMutation>(
            dismiss_mutation_progress_indicator,
        )[0];

    const dismissMutationProgressIndicator = useCallback(
        (variables: {
            dismissBulkCommunicationProgressIndicator?: boolean;
        }) => {
            const dismissMutationProgressIndicatorConfig = {
                variables: {
                    input: variables,
                },
                optimisticResponse: {
                    dismissMutationProgressIndicator: {
                        success: true,
                        errors: null,
                        profileGroup: {
                            id: user?.profile?.profileGroup?.id,
                            generateGoogleRegistersProgress:
                                variables.dismissBulkCommunicationProgressIndicator
                                    ? null
                                    : mutationProgressData.profile?.profileGroup
                                          ?.sendBulkCommunicationProgress,
                        },
                    },
                },
            };
            commitDismissMutationProgressIndicator(
                dismissMutationProgressIndicatorConfig,
            );
        },
        [
            commitDismissMutationProgressIndicator,
            mutationProgressData.profile?.profileGroup
                ?.sendBulkCommunicationProgress,
            user?.profile?.profileGroup?.id,
        ],
    );

    const renderIndicator = useCallback(
        ({
            errorMessage,
            loadingMessage,
            onDismiss,
            progress,
            showIndicatorState,
            successMessage,
        }: {
            errorMessage: string;
            loadingMessage: string;
            onDismiss: () => void;
            progress: number | null | undefined;
            showIndicatorState: boolean;
            successMessage: string;
        }) => {
            if (typeof progress === "number" || showIndicatorState) {
                return (
                    <PresenceTransition
                        animate={{
                            opacity: 1,
                            scale: 1,
                            transition: {
                                duration: 250,
                            },
                        }}
                        initial={{
                            opacity: 0,
                            scale: 0,
                        }}
                        visible
                    >
                        <Pressable
                            bg={
                                progress === 100
                                    ? "primary.700"
                                    : // progress of -1 signifies error
                                      (progress ?? 0) < 0
                                      ? "red.500"
                                      : "primary.500"
                            }
                            borderRadius="xl"
                            flexDirection="row"
                            onPress={refreshMutationProgress}
                            shadow={1}
                        >
                            <Text color="surface.100" pl="3" py="2">
                                {progress === 100
                                    ? successMessage
                                    : (progress ?? 0) < 0
                                      ? errorMessage
                                      : loadingMessage}
                            </Text>
                            {(progress ?? 0) >= 0 ? (
                                <Center pl="1" pr="3" py="2" width="65px">
                                    {isRefetching ? (
                                        <Spinner color="surface.100" />
                                    ) : (
                                        <Text color="surface.100">
                                            {"(" + (progress ?? 0) + "%)"}
                                        </Text>
                                    )}
                                </Center>
                            ) : (
                                <Box pr="3" />
                            )}
                        </Pressable>
                        <Button
                            bg="surface.900"
                            borderRadius="full"
                            leftIcon={
                                <CloseIcon color="surface.100" size="3" />
                            }
                            onPress={onDismiss}
                            p="1"
                            position="absolute"
                            right="-7"
                            shadow={1}
                            top="-7"
                            variant="dark"
                            zIndex={2}
                        />
                    </PresenceTransition>
                );
            } else {
                return null;
            }
        },
        [isRefetching, refreshMutationProgress],
    );

    // stop effects firing on first render
    const renderCount = useRef(0);

    // this series of effects closes loading indicator when progress finishes
    useEffect(() => {
        if (renderCount.current < 1) {
            renderCount.current += 1;
            return;
        }

        if (
            mutationProgressData.profile?.profileGroup
                ?.sendBulkCommunicationProgress === null
        ) {
            dispatchState({
                input: "showSendBulkCommunicationProgressIndicator",
                value: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        mutationProgressData.profile?.profileGroup
            ?.sendBulkCommunicationProgress,
    ]);

    return (
        <VStack bottom="5" position="absolute" right="5" space="4">
            {renderIndicator({
                progress:
                    mutationProgressData.profile?.profileGroup
                        ?.sendBulkCommunicationProgress,
                showIndicatorState:
                    state.values.showSendBulkCommunicationProgressIndicator,
                successMessage: "Sent Bulk Communication",
                errorMessage: "Error Sending Bulk Communication",
                loadingMessage: "Sending Bulk Communication",
                onDismiss: () => {
                    dismissMutationProgressIndicator({
                        dismissBulkCommunicationProgressIndicator: true,
                    });
                    dispatchState({
                        input: "showSendBulkCommunicationProgressIndicator",
                        value: false,
                    });
                },
            })}
        </VStack>
    );
};

export default React.memo(AccountMutationProgressIndicators);
