import React, { useCallback, useRef } from "react";
import type { ReactElement } from "react";

import { Button, useToast } from "native-base";
import { useMutation } from "react-relay";

import type {
    ArchivePupilsMutation,
    ArchivePupilsMutation$data,
} from "pianofunclub-shared/relay/graphql/accounts/__generated__/ArchivePupilsMutation.graphql";
import { archive_pupils } from "pianofunclub-shared/relay/graphql/accounts/ArchivePupils";

import ButtonDebounced from "pianofunclub-shared/components/Buttons/ButtonDebounced";
import AlertPopup from "pianofunclub-shared/components/NativeBaseExtended/AlertPopup";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";

import { listToStringWithCommasConverter } from "pianofunclub-shared/utils/converters";

export type PupilsToArchiveModalInfo =
    | {
          ids: string[];
          pupilNames: string[];
      }
    | undefined;

interface Props {
    block: number;
    hideModal: () => void;
    pupilIds: string[] | undefined;
    pupilNames: string[] | undefined;
    showModal: boolean;
    startingYear: number;
}

const PupilsToArchiveModal = (props: Props): ReactElement | null => {
    const { block, hideModal, pupilIds, pupilNames, showModal, startingYear } =
        props;

    const toast = useToast();

    const safeButtonRef = useRef(null);

    const [commitArchivePupils, archivePupilsInFlight] =
        useMutation<ArchivePupilsMutation>(archive_pupils);

    const archivePupils = useCallback(() => {
        if (!pupilIds?.length) {
            return;
        }

        const archivePupilsConfig = {
            variables: {
                input: {
                    pupilIds,
                    startingYear: Number(startingYear),
                    block: Number(block),
                },
            },
            onCompleted: (response: ArchivePupilsMutation$data) => {
                hideModal();

                if (response?.archivePupils?.success) {
                    toast.show({
                        render: ({ id }: { id: string }) => (
                            <ToastAlert
                                id={id}
                                status="success"
                                title={"Successfully archived pupils"}
                                toast={toast}
                            />
                        ),
                    });
                } else {
                    toast.show({
                        render: ({ id }: { id: string }) => (
                            <ToastAlert
                                description={
                                    "Please get in touch with one of the team"
                                }
                                id={id}
                                status="error"
                                title={"Couldn't archive pupils"}
                                toast={toast}
                            />
                        ),
                    });
                }
            },
        };

        commitArchivePupils(archivePupilsConfig);
    }, [block, commitArchivePupils, hideModal, pupilIds, startingYear, toast]);

    const alertTitle =
        pupilNames?.length && pupilNames.length > 1
            ? "Archive pupils who have stopped lessons?"
            : "Arcive pupil who has stopped lessons?";

    const alertBody =
        (pupilNames?.length
            ? pupilNames?.length > 1
                ? `You marked the following pupils as having stopped lessons or left school: ${listToStringWithCommasConverter(pupilNames)}. `
                : `You marked ${pupilNames[0]} as having stopped lessons or left school. `
            : "") +
        "Do you want to archive them?\n\nNote that this will also archive their parents (if they do not have any other active children) and remove them from the next block's registers (if they have already been generated).";

    return (
        <AlertPopup
            alertIsOpen={showModal}
            body={alertBody}
            header={alertTitle}
            safeButtonRef={safeButtonRef}
            setAlertIsOpen={hideModal}>
            <Button
                ref={safeButtonRef}
                _text={{ fontSize: "lg" }}
                colorScheme="surface"
                height="50"
                minWidth="56"
                onPress={hideModal}
                width="25%">
                No, don't archive
            </Button>
            <ButtonDebounced
                _loading={{ opacity: 1 }}
                _text={{ fontSize: "lg" }}
                colorScheme="red"
                height="50"
                isLoading={archivePupilsInFlight}
                minWidth="56"
                onPress={archivePupils}
                width="25%">
                Yes, archive them
            </ButtonDebounced>
        </AlertPopup>
    );
};

export default React.memo(PupilsToArchiveModal);
