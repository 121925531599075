/**
 * @generated SignedSource<<7eeffde016a227391758151c966ba617>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PasswordResetInput = {
  clientMutationId?: string | null;
  newPassword1: string;
  newPassword2: string;
  token: string;
};
export type ResetPasswordMutation$variables = {
  input: PasswordResetInput;
};
export type ResetPasswordMutation$data = {
  readonly passwordReset: {
    readonly errors: any | null;
    readonly success: boolean | null;
  } | null;
};
export type ResetPasswordMutation = {
  response: ResetPasswordMutation$data;
  variables: ResetPasswordMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PasswordResetPayload",
    "kind": "LinkedField",
    "name": "passwordReset",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResetPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResetPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "63393a350cc0f63ca9a4ed6301405fd2",
    "id": null,
    "metadata": {},
    "name": "ResetPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation ResetPasswordMutation(\n  $input: PasswordResetInput!\n) {\n  passwordReset(input: $input) {\n    success\n    errors\n  }\n}\n"
  }
};
})();

(node as any).hash = "1a3f7d6e1429c4e60245136ccb2e60b6";

export default node;
