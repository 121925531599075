import React from "react";
import type { ReactElement, ReactNode, ComponentProps } from "react";

import { VStack, Text } from "native-base";

type VStackProps = ComponentProps<typeof VStack>;

interface Props extends VStackProps {
    actionButton?: ReactElement;
    callToAction?: string;
    children?: ReactNode;
    explainer?: string;
    fontSize?: string;
}

const ListEmptyBanner = (props: Props): ReactElement => {
    const { actionButton, callToAction, explainer, fontSize } = props;

    return (
        <VStack
            alignItems="center"
            flex={1}
            justifyContent="center"
            px="4"
            space={4}
            {...props}
        >
            {explainer ? (
                <Text fontSize={fontSize ?? "lg"} textAlign="center">
                    {explainer}
                </Text>
            ) : null}
            {props.children}
            {callToAction ? (
                <Text fontSize={fontSize ?? "lg"} textAlign="center">
                    {callToAction}
                </Text>
            ) : null}
            {actionButton}
        </VStack>
    );
};

export default ListEmptyBanner;
