import React, { useState, useMemo } from "react";
import type { ReactElement, ComponentProps, Dispatch } from "react";

import { format } from "date-fns";
import { Box, Center, Text, Pressable, Tooltip, HStack } from "native-base";
import type { LessonData } from "pianofunclub-shared/types";
import { Platform } from "react-native";

import type { NavigationProps as AccountScreenNavigationProps } from "pianofunclub-crm/screens/accounts/AccountScreen";
import type {
    ReducerValues as AccountScreenReducerValues,
    ReducerTypes as AccountScreenReducerTypes,
} from "pianofunclub-crm/screens/accounts/AccountScreen";

import TimetableLesson from "./TimetableLesson";
import type {
    ReducerValues as TimetableScreenReducerValues,
    ReducerTypes as TimetableScreenReducerTypes,
} from "pianofunclub-teaching-app/screens/TimetableScreen";

import { HOUR_TIMETABLE_HEIGHT } from "pianofunclub-shared/utils/constants";
import {
    combineAddress,
    titleCaseConverter,
} from "pianofunclub-shared/utils/converters";
import type { Action, State } from "pianofunclub-shared/utils/reducers";

type BoxProps = ComponentProps<typeof Box>;

interface Props extends BoxProps {
    dispatchState: Dispatch<
        Action<
            AccountScreenReducerValues & TimetableScreenReducerValues,
            AccountScreenReducerTypes | TimetableScreenReducerTypes
        >
    >;
    lessonTimestampDate: Date;
    lessonsData: LessonData[];
    navigation: AccountScreenNavigationProps;
    showSchoolLabel: boolean;
    state: State<AccountScreenReducerValues | TimetableScreenReducerValues>;
}

const TimetableLessonGroup = (props: Props): ReactElement | null => {
    const {
        dispatchState,
        lessonsData,
        lessonTimestampDate,
        navigation,
        showSchoolLabel,
        state,
    } = props;

    const [schoolTooltipVisible, setSchoolTooltipVisible] = useState(false);

    const schoolAddress = useMemo(() => {
        if (showSchoolLabel) {
            return combineAddress(
                lessonsData[0].lessonBlock?.school?.mainOfficeContact
                    ?.addressFirstLine,
                lessonsData[0].lessonBlock?.school?.mainOfficeContact
                    ?.addressSecondLine,
                lessonsData[0].lessonBlock?.school?.mainOfficeContact?.city,
                lessonsData[0].lessonBlock?.school?.mainOfficeContact?.postcode,
            );
        } else {
            return "";
        }
    }, [lessonsData, showSchoolLabel]);

    const lessonDuration = useMemo(() => {
        return (
            lessonsData[0].lessonDuration ??
            lessonsData[0].lessonBlock?.lessonStage?.lessonDuration
        );
    }, [lessonsData]);

    const lessonTimestampString = useMemo(() => {
        if (lessonDuration) {
            return `${format(lessonTimestampDate, "H:mm")} - ${format(
                new Date(
                    lessonTimestampDate.getTime() + lessonDuration * 60000,
                ),
                "H:mm",
            )}`;
        } else {
            return "";
        }
    }, [lessonDuration, lessonTimestampDate]);

    if (lessonDuration) {
        return (
            <Box
                height={`${
                    (lessonDuration / 60) * HOUR_TIMETABLE_HEIGHT - 2
                }px`}
                left={
                    state.values.timetableView === "WEEK"
                        ? `${((lessonTimestampDate.getDay() - 1) / 5) * 100}%`
                        : undefined
                }
                position="absolute"
                top={
                    (lessonTimestampDate.getHours() - 7) *
                        HOUR_TIMETABLE_HEIGHT +
                    (lessonTimestampDate.getMinutes() / 60) *
                        HOUR_TIMETABLE_HEIGHT +
                    1
                }
                width={state.values.timetableView === "DAY" ? "100%" : "20%"}
            >
                {showSchoolLabel && lessonsData[0].lessonBlock?.school?.name ? (
                    <Tooltip
                        isOpen={schoolTooltipVisible && Boolean(schoolAddress)}
                        label={schoolAddress ?? ""}
                        textAlign="center"
                    >
                        <Pressable
                            _pressed={{ opacity: 0.7 }}
                            isDisabled={Platform.OS !== "web"}
                            onHoverIn={() => setSchoolTooltipVisible(true)}
                            onHoverOut={() => setSchoolTooltipVisible(false)}
                            onPress={() => {
                                if (lessonsData[0].lessonBlock?.school?.id) {
                                    navigation.push("School", {
                                        schoolId:
                                            lessonsData[0].lessonBlock?.school
                                                ?.id,
                                        startingYear: state.values.startingYear,
                                        block: state.values.block,
                                    });
                                }
                            }}
                            onPressIn={() => setSchoolTooltipVisible(true)}
                        >
                            <Center
                                position="absolute"
                                top="-40"
                                width="100%"
                                zIndex={2}
                            >
                                <Text
                                    color="primary.700"
                                    fontSize={
                                        Platform.OS === "web" ? "2xl" : "lg"
                                    }
                                    isTruncated
                                >
                                    {titleCaseConverter(
                                        lessonsData[0].lessonBlock.school.name,
                                    )}
                                </Text>
                            </Center>
                        </Pressable>
                    </Tooltip>
                ) : null}
                <HStack flex={1}>
                    {lessonsData.map((lessonData, index) => {
                        return (
                            <TimetableLesson
                                key={index}
                                dispatchState={dispatchState}
                                index={index}
                                instrument={
                                    lessonData.lessonBlock?.instrument?.name
                                }
                                lessonData={lessonData}
                                lessonTimestampDate={lessonTimestampDate}
                                lessonTimestampString={lessonTimestampString}
                                lessonType={
                                    lessonsData[0].lessonBlock?.lessonStage
                                        ?.lessonType ?? "INDIVIDUAL"
                                }
                                pupilFirstName={
                                    lessonData.lessonBlock?.pupil?.user
                                        .firstName
                                }
                                pupilLastName={
                                    lessonData.lessonBlock?.pupil?.user.lastName
                                }
                                schoolAddress={schoolAddress}
                                state={state}
                            />
                        );
                    })}
                </HStack>
            </Box>
        );
    } else {
        return null;
    }
};

export default React.memo(TimetableLessonGroup);
