import { graphql } from "react-relay";

export const reschedule_lessons_for_week = graphql`
    mutation RescheduleLessonsForWeekMutation(
        $input: RescheduleLessonsForWeekInput!
    ) {
        rescheduleLessonsForWeek(input: $input) {
            success
            errors
            lessons {
                id
                scheduledTimestamp
                rearrangedTimestamp
                status
                replacementLessonColumnIndex
                weekWasRearranged
            }
        }
    }
`;
