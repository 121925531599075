/**
 * @generated SignedSource<<cf6020408513a8ac7f6c83a03ad1965e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RegisterInfoFragment_query$data = {
  readonly profile: {
    readonly firstLessonTimestamp: string | null;
    readonly id: string;
    readonly lastLessonTimestamp: string | null;
    readonly teachingDays: ReadonlyArray<number | null> | null;
    readonly totalLessons: number | null;
    readonly totalLessonsMarked: number | null;
  } | null;
  readonly " $fragmentType": "RegisterInfoFragment_query";
};
export type RegisterInfoFragment_query$key = {
  readonly " $data"?: RegisterInfoFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"RegisterInfoFragment_query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "block",
  "variableName": "block"
},
v1 = {
  "kind": "Variable",
  "name": "startingYear",
  "variableName": "startingYear"
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = [
  (v0/*: any*/),
  {
    "kind": "Variable",
    "name": "dayIndex",
    "variableName": "dayIndex"
  },
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "block"
    },
    {
      "kind": "RootArgument",
      "name": "dayIndex"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "profileId"
    },
    {
      "kind": "RootArgument",
      "name": "startingYear"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./RegisterInfoRefreshQuery.graphql')
    }
  },
  "name": "RegisterInfoFragment_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "profileId"
        }
      ],
      "concreteType": "ProfileNode",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v2/*: any*/),
          "kind": "ScalarField",
          "name": "totalLessonsMarked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v2/*: any*/),
          "kind": "ScalarField",
          "name": "totalLessons",
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v3/*: any*/),
          "kind": "ScalarField",
          "name": "firstLessonTimestamp",
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v3/*: any*/),
          "kind": "ScalarField",
          "name": "lastLessonTimestamp",
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v2/*: any*/),
          "kind": "ScalarField",
          "name": "teachingDays",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "ab73351c428f989bfa720546ad4cc99c";

export default node;
