import React from "react";
import type { ReactElement } from "react";

import type {
    CompositeNavigationProp,
    RouteProp,
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import type { StackNavigationProp } from "@react-navigation/stack";

import AccountScreen, {
    screenOptions as accountScreenOptions,
} from "../screens/accounts/AccountScreen";
import InvoiceScreen, {
    screenOptions as invoiceScreenOptions,
} from "../screens/invoicing/InvoiceScreen";
import RegistersHubScreen, {
    screenOptions as registersHubScreenOptions,
} from "../screens/registers/RegistersHubScreen";
import DefaultTermDatesScreen, {
    screenOptions as defaultTermDatesScreenOptions,
} from "../screens/schools/DefaultTermDatesScreen";
import SchoolScreen, {
    screenOptions as schoolScreenOptions,
} from "../screens/schools/SchoolScreen";

import type { HubTabNavigatorProps } from "./HubNavigator";
import defaultNavOptions from "./utils/DefaultNavigationOptions";
import type {
    AccountParams,
    SchoolParams,
    InvoiceParams,
    DefaultTermDatesParams,
} from "./utils/ScreenParams";

export type RegistersStackNavigatorParamList = {
    Account: AccountParams;
    DefaultTermDates: DefaultTermDatesParams;
    Invoice: InvoiceParams;
    RegistersHub?: {
        block?: number;
        searchTerm?: string;
        startingYear?: number;
    };
    School: SchoolParams;
};

type NavigatorProps<T extends keyof RegistersStackNavigatorParamList> =
    StackNavigationProp<RegistersStackNavigatorParamList, T>;

export type RegistersStackNavigatorProps<
    T extends keyof RegistersStackNavigatorParamList,
> = CompositeNavigationProp<
    NavigatorProps<T>,
    HubTabNavigatorProps<"RegistersHubTab">
>;

export type RegistersStackRouteProps<
    T extends keyof RegistersStackNavigatorParamList,
> = RouteProp<RegistersStackNavigatorParamList, T>;

const RegistersStackNavigator =
    createStackNavigator<RegistersStackNavigatorParamList>();

const RegistersNavigator = (): ReactElement => {
    return (
        <RegistersStackNavigator.Navigator screenOptions={defaultNavOptions}>
            <RegistersStackNavigator.Screen
                component={RegistersHubScreen}
                name="RegistersHub"
                options={registersHubScreenOptions}
            />
            <RegistersStackNavigator.Screen
                component={AccountScreen}
                name="Account"
                options={accountScreenOptions}
            />
            <RegistersStackNavigator.Screen
                component={SchoolScreen}
                name="School"
                options={schoolScreenOptions}
            />
            <RegistersStackNavigator.Screen
                component={InvoiceScreen}
                name="Invoice"
                options={invoiceScreenOptions}
            />
            <RegistersStackNavigator.Screen
                component={DefaultTermDatesScreen}
                name="DefaultTermDates"
                options={defaultTermDatesScreenOptions}
            />
        </RegistersStackNavigator.Navigator>
    );
};

export default RegistersNavigator;
