import { graphql } from "react-relay";

export const token_auth = graphql`
    mutation TokenAuthMutation($input: ObtainJSONWebTokenInput!) {
        tokenAuth(input: $input) {
            success
            errors
            token
            refreshToken
            user {
                id
                username
                verified
                profile {
                    id
                }
            }
        }
    }
`;
