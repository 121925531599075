import { graphql } from "react-relay";

export const bulk_upload_new_registers = graphql`
    mutation BulkUploadNewRegistersMutation(
        $input: BulkUploadNewRegistersInput!
    ) {
        bulkUploadNewRegisters(input: $input) {
            success
            errors
            erroredSchool {
                id
                name
            }
            profileGroup {
                id
                bulkUploadNewRegistersProgress
            }
        }
    }
`;
