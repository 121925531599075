import { graphql } from "react-relay";

export const load_term_dates = graphql`
    query LoadTermDatesQuery(
        $school: String
        $startingYear: Int!
        $skipSchoolTermDates: Boolean!
    ) {
        termDates(startingYear: $startingYear) {
            edges {
                node {
                    id
                    term
                    firstDayOfTerm
                    lastDayOfFirstHalfOfTerm
                    firstDayOfSecondHalfOfTerm
                    lastDayOfTerm
                }
            }
        }
        schoolTermDates: termDates(
            school: $school
            startingYear: $startingYear
        ) @skip(if: $skipSchoolTermDates) {
            __id
            edges {
                node {
                    id
                    term
                    firstDayOfTerm
                    lastDayOfFirstHalfOfTerm
                    firstDayOfSecondHalfOfTerm
                    lastDayOfTerm
                }
            }
        }
    }
`;
