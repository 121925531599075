import React, { forwardRef } from "react";
import type { ReactElement, ReactNode, ComponentProps } from "react";

import debounce from "lodash.debounce";
import { Button } from "native-base";
import type { GestureResponderEvent } from "react-native";

type ButtonProps = ComponentProps<typeof Button>;

interface Props extends ButtonProps {
    children?: ReactNode;
    debounceTime?: number;
}

const ButtonDebounced = forwardRef((props: Props, ref): ReactElement => {
    const debouncedOnPress = (event: GestureResponderEvent) => {
        props.onPress && props.onPress(event);
    };

    const onPress = debounce(debouncedOnPress, props.debounceTime ?? 500, {
        leading: true,
        trailing: false,
    });

    return (
        // @ts-expect-error unable to declare type of ForwardedRef properly
        <Button {...props} ref={ref} onPress={onPress}>
            {props.children}
        </Button>
    );
});

ButtonDebounced.displayName = "ButtonDebounced";

export default ButtonDebounced;
