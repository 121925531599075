/**
 * @generated SignedSource<<469a11cf2547569bf0d817dbbd2e5b8c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LoadEnrolmentsPaginationQuery$variables = {
  after?: string | null;
  first: number;
  instrument?: string | null;
  lessonStage?: string | null;
  orderBy?: string | null;
  parentEmail?: string | null;
  pupil?: string | null;
  school?: string | null;
  schoolYear?: string | null;
  searchTerm?: string | null;
};
export type LoadEnrolmentsPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LoadEnrolments_query_enrolments">;
};
export type LoadEnrolmentsPaginationQuery = {
  response: LoadEnrolmentsPaginationQuery$data;
  variables: LoadEnrolmentsPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "instrument"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lessonStage"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "parentEmail"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pupil"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "school"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "schoolYear"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "instrument",
    "variableName": "instrument"
  },
  {
    "kind": "Variable",
    "name": "lessonStage",
    "variableName": "lessonStage"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "parentEmail",
    "variableName": "parentEmail"
  },
  {
    "kind": "Variable",
    "name": "pupil",
    "variableName": "pupil"
  },
  {
    "kind": "Variable",
    "name": "school",
    "variableName": "school"
  },
  {
    "kind": "Variable",
    "name": "schoolYear",
    "variableName": "schoolYear"
  },
  {
    "kind": "Variable",
    "name": "searchTerm",
    "variableName": "searchTerm"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoadEnrolmentsPaginationQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "LoadEnrolments_query_enrolments"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoadEnrolmentsPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EnrolmentNodeConnection",
        "kind": "LinkedField",
        "name": "enrolments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EnrolmentNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EnrolmentNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "schoolName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "schoolBorough",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "schoolPostcode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pupilFirstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pupilLastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "schoolYear",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "schoolClass",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "instrument",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "paidBySchool",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "premiumPupil",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "discountPercentage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "teacherEmail",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lessonDay",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lessonStartTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lessonStage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lessonType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lessonDuration",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "costPerLesson",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "numberOfLessons",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "parentName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "parentPhoneNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pupilNotes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lessonBlockNotes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "positionInWaitingList",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "addedOn",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "enroled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "offered",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "offeredOn",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adminNotes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sentWaitingListEmail",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "orderBy",
          "searchTerm",
          "pupil",
          "school",
          "lessonStage",
          "schoolYear",
          "parentEmail",
          "instrument"
        ],
        "handle": "connection",
        "key": "LoadEnrolments_query_enrolments",
        "kind": "LinkedHandle",
        "name": "enrolments"
      }
    ]
  },
  "params": {
    "cacheID": "d9c9c7751aecb9d129542519447badc2",
    "id": null,
    "metadata": {},
    "name": "LoadEnrolmentsPaginationQuery",
    "operationKind": "query",
    "text": "query LoadEnrolmentsPaginationQuery(\n  $after: String\n  $first: Int!\n  $instrument: String\n  $lessonStage: String\n  $orderBy: String\n  $parentEmail: String\n  $pupil: String\n  $school: String\n  $schoolYear: String\n  $searchTerm: String\n) {\n  ...LoadEnrolments_query_enrolments_GxjuH\n}\n\nfragment LoadEnrolments_query_enrolments_GxjuH on Query {\n  enrolments(orderBy: $orderBy, searchTerm: $searchTerm, pupil: $pupil, school: $school, lessonStage: $lessonStage, schoolYear: $schoolYear, parentEmail: $parentEmail, instrument: $instrument, first: $first, after: $after) {\n    edges {\n      node {\n        id\n        schoolName\n        schoolBorough\n        schoolPostcode\n        pupilFirstName\n        pupilLastName\n        schoolYear\n        schoolClass\n        instrument\n        paidBySchool\n        premiumPupil\n        discountPercentage\n        teacherEmail\n        lessonDay\n        lessonStartTime\n        lessonStage\n        lessonType\n        lessonDuration\n        costPerLesson\n        numberOfLessons\n        parentName\n        parentPhoneNumber\n        pupilNotes\n        lessonBlockNotes\n        positionInWaitingList\n        addedOn\n        enroled\n        offered\n        offeredOn\n        adminNotes\n        sentWaitingListEmail\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "805324be12177c05499394f0299ceed9";

export default node;
