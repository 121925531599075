import { graphql } from "react-relay";

export const logout_user = graphql`
    mutation LogoutUserMutation($input: LogoutUserInput!) {
        logoutUser(input: $input) {
            success
            errors
        }
    }
`;
