import { graphql } from "react-relay";

export const edit_payslip = graphql`
    mutation EditPayslipMutation($input: EditPayslipInput!) {
        editPayslip(input: $input) {
            success
            errors
            payslip {
                id
                amountDue
                status
                amountAdjusted
                adjustedReason
                teacher {
                    user {
                        firstName
                        lastName
                        email
                    }
                }
            }
        }
    }
`;
