import { graphql } from "react-relay";

export const remove_payment = graphql`
    mutation RemovePaymentMutation(
        $connections: [ID!]!
        $input: RemovePaymentInput!
    ) {
        removePayment(input: $input) {
            success
            errors
            deletedPaymentId @deleteEdge(connections: $connections)
            invoice {
                id
                amountPaid
                status
            }
        }
    }
`;
