/**
 * @generated SignedSource<<6d23c80a33d09f027acc302aff95442e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InvoiceModelStatus = "CANCELLED" | "DRAFT" | "OVERPAID" | "PAID" | "PENDING" | "REFUND" | "SENT" | "%future added value";
export type UpdateInvoiceStatusInput = {
  clientMutationId?: string | null;
  draftInvoiceEmail?: boolean | null;
  invoiceId: string;
  preferText?: boolean | null;
  relatedInvoiceId?: string | null;
  sendInvoice?: boolean | null;
  sentDate?: string | null;
  status: string;
};
export type UpdateInvoiceStatusMutation$variables = {
  input: UpdateInvoiceStatusInput;
};
export type UpdateInvoiceStatusMutation$data = {
  readonly updateInvoiceStatus: {
    readonly errors: any | null;
    readonly invoice: {
      readonly amountPaid: number | null;
      readonly dueDate: string | null;
      readonly id: string;
      readonly initialSentTimestamp: string | null;
      readonly payments: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
          } | null;
        } | null>;
      };
      readonly status: InvoiceModelStatus;
    } | null;
    readonly relatedInvoice: {
      readonly id: string;
      readonly otherInvoicesForBlock: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly createdOn: string;
            readonly id: string;
            readonly invoiceNumber: string;
            readonly status: InvoiceModelStatus;
            readonly totalAmount: number | null;
          } | null;
        } | null>;
      };
    } | null;
    readonly success: boolean | null;
  } | null;
};
export type UpdateInvoiceStatusMutation = {
  response: UpdateInvoiceStatusMutation$data;
  variables: UpdateInvoiceStatusMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateInvoiceStatusPayload",
    "kind": "LinkedField",
    "name": "updateInvoiceStatus",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceNode",
        "kind": "LinkedField",
        "name": "invoice",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "initialSentTimestamp",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dueDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amountPaid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PaymentNodeConnection",
            "kind": "LinkedField",
            "name": "payments",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PaymentNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PaymentNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceNode",
        "kind": "LinkedField",
        "name": "relatedInvoice",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceNodeConnection",
            "kind": "LinkedField",
            "name": "otherInvoicesForBlock",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "InvoiceNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InvoiceNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "invoiceNumber",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalAmount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdOn",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateInvoiceStatusMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateInvoiceStatusMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "cebd0b411a429ed5ab63f7ab2a16f31c",
    "id": null,
    "metadata": {},
    "name": "UpdateInvoiceStatusMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateInvoiceStatusMutation(\n  $input: UpdateInvoiceStatusInput!\n) {\n  updateInvoiceStatus(input: $input) {\n    success\n    errors\n    invoice {\n      id\n      status\n      initialSentTimestamp\n      dueDate\n      amountPaid\n      payments {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n    }\n    relatedInvoice {\n      id\n      otherInvoicesForBlock {\n        edges {\n          node {\n            id\n            invoiceNumber\n            status\n            totalAmount\n            createdOn\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e0410a108b3270ff9b027b9e47cbe074";

export default node;
