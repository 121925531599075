import { graphql } from "react-relay";

export const generate_payslips = graphql`
    mutation GeneratePayslipsMutation($input: GeneratePayslipsInput!) {
        generatePayslips(input: $input) {
            success
            errors
            payslips {
                id
                amountDue
                status
                amountAdjusted
                adjustedReason
            }
        }
    }
`;
