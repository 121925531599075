import React, {
    useMemo,
    useState,
    useCallback,
    forwardRef,
    useImperativeHandle,
    useEffect,
} from "react";
import type { ReactElement, MutableRefObject } from "react";

import { format } from "date-fns";
import { HStack, Text, Pressable, Box, Checkbox } from "native-base";
import { Platform } from "react-native";
import { DayTimePickerModal } from "react-native-paper-dates";

import type { LessonBlockData } from "../Tabs/SchoolLessons";
import type { CellProps } from "pianofunclub-shared/components/Base/Cell";
import Row from "pianofunclub-shared/components/Base/Row";
import type { TableData } from "pianofunclub-shared/components/Base/Row";
import ManageRegisterLessonModal from "pianofunclub-shared/components/Modals/ManageRegisterLessonModal";
import type {
    LessonInfo as RegisterLessonInfo,
    RegisterUpdate,
} from "pianofunclub-shared/components/Modals/ManageRegisterLessonModal";
import LessonStatus from "pianofunclub-shared/components/Registers/LessonStatus";

import type { LessonModelStatus } from "pianofunclub-shared/relay/graphql/registers/__generated__/UpdateRegistersMutation.graphql";

import type { AccountType } from "pianofunclub-shared/types";
import { NO_STAGE, TEACHING_DAYS } from "pianofunclub-shared/utils/constants";
import {
    combineNotes,
    schoolYearConverter,
    titleCaseConverter,
} from "pianofunclub-shared/utils/converters";
import { getFullName } from "pianofunclub-shared/utils/extractors";

interface Props {
    cellProps?: CellProps;
    checkboxChangeHandler: (blockId: string, isSelected: boolean) => void;
    currentStartingYear: number;
    cursor: string;
    data: NonNullable<NonNullable<LessonBlockData>["node"]>;
    flexArray: number[];
    isRegisterTable?: boolean;
    onPressProfile: (
        profileId?: string | null,
        accountType?: AccountType | null,
    ) => void;
    onPressSchool?: (schoolId?: string | null) => void;
    registerUpdates?: MutableRefObject<RegisterUpdate[]>;
    rowHeight: number;
    selectedStartingYear: number;
    showUpdateBlockDetailsModalHandler: (variables: {
        keepFree?: boolean;
        lessonBlockCursor?: string;
        lessonBlockIds: string[];
        onUpdate?: () => void;
        school?: string;
        selectedProfileFullName?: string;
        selectedProfileId?: string;
        type: string;
    }) => void;
    tableBorderColor?: string;
    tableBorderWidth?: number;
    updateRegisterState?: (
        variables: {
            lessonId: string;
            registerNote?: string;
            status: string;
        },
        progressChange?: number,
    ) => void;
    updateScheduledLessonBlockTime?: (
        lessonBlockIds: string[],
        dayIndex: number,
        hours?: number,
        minutes?: number,
        onComplete?: () => void,
        overrideRescheduledLessons?: boolean,
    ) => void;
    userIsTeacher?: boolean;
}

const LessonBlockTableRow = forwardRef((props: Props, ref): ReactElement => {
    const {
        cellProps: generalCellProps,
        checkboxChangeHandler,
        currentStartingYear,
        cursor,
        data,
        flexArray,
        isRegisterTable,
        onPressProfile,
        onPressSchool,
        // used to modify pupil's school year based on the selected year
        registerUpdates,
        rowHeight,
        selectedStartingYear,
        showUpdateBlockDetailsModalHandler,
        tableBorderColor,
        tableBorderWidth,
        updateRegisterState,
        updateScheduledLessonBlockTime,
        userIsTeacher,
    } = props;

    const cellProps = useMemo(() => {
        if (generalCellProps) {
            return {
                ...generalCellProps,
                textProps: {
                    ...generalCellProps.textProps,
                    color: !data.pupil ? "surface.700" : undefined,
                },
            };
        }
    }, [data.pupil, generalCellProps]);

    const [checkBoxIsChecked, setCheckBoxIsChecked] = useState(false);
    const [dayTimePickerIsOpen, setDayTimePickerIsOpen] = useState(false);

    const intialiseScheduledDayTimeState = useCallback(() => {
        let hours, minutes: string | undefined;
        if (data.lessonTime) {
            [hours, minutes] = data.lessonTime.split(":").slice(0, 2);
        }
        return {
            dayIndex: data.lessonDay ?? undefined,
            hours: hours ? parseInt(hours) : undefined,
            minutes: minutes ? parseInt(minutes) : undefined,
        };
    }, [data.lessonDay, data.lessonTime]);

    const [scheduledDayTime, setScheduledDayTime] = useState<{
        dayIndex?: number;
        hours?: number;
        minutes?: number;
    }>(() => intialiseScheduledDayTimeState());

    useEffect(() => {
        setScheduledDayTime(() => intialiseScheduledDayTimeState());
    }, [intialiseScheduledDayTimeState]);

    const initialiseRegisterState = useCallback(
        (lessons: typeof data.lessons.edges) => {
            return (
                lessons.map((lesson) => {
                    const existingUpdate = registerUpdates?.current.find(
                        (item) => item.lessonId === lesson?.node?.id,
                    );
                    return {
                        status:
                            existingUpdate?.status ??
                            lesson?.node?.status ??
                            "NO_LESSON",
                        teacherRegisterNoteForStaff:
                            userIsTeacher && existingUpdate?.registerNote
                                ? existingUpdate.registerNote
                                : lesson?.node?.teacherRegisterNoteForStaff,
                        staffRegisterNoteForTeacher:
                            !userIsTeacher && existingUpdate?.registerNote
                                ? existingUpdate.registerNote
                                : lesson?.node?.staffRegisterNoteForTeacher
                                  ? lesson?.node?.staffRegisterNoteForTeacher
                                  : lesson?.node?.blacklistedDate?.reason,
                    };
                }) ?? []
            );
        },
        [data, registerUpdates, userIsTeacher],
    );

    const [lessonStatuses, setLessonStatuses] = useState(() =>
        initialiseRegisterState(data.lessons.edges ?? []),
    );

    useEffect(() => {
        setLessonStatuses(() =>
            initialiseRegisterState(data.lessons.edges ?? []),
        );
    }, [
        data.lessons.edges,
        initialiseRegisterState,
        intialiseScheduledDayTimeState,
    ]);

    const lessons = useMemo(() => {
        return data.lessons.edges ?? [];
    }, [data.lessons.edges]);

    const [registerModalLessonInfo, setRegisterModalLessonInfo] = useState<
        RegisterLessonInfo | undefined
    >(undefined);

    const onConfirmDayTime = useCallback(
        (params: { dayIndex: number; hours: number; minutes: number }) => {
            setDayTimePickerIsOpen(false);
            setScheduledDayTime({
                dayIndex: params.dayIndex - 1,
                hours: params.hours,
                minutes: params.minutes,
            });
            updateScheduledLessonBlockTime?.(
                [data.id],
                params.dayIndex - 1,
                params.hours,
                params.minutes,
            );
        },
        [data.id, updateScheduledLessonBlockTime],
    );

    useImperativeHandle(ref, () => ({
        setIsChecked: (isChecked: boolean) => {
            setCheckBoxIsChecked(isChecked);
        },
    }));

    const rowData = useMemo(() => {
        const lessonDayString =
            data.lessonDay !== null
                ? TEACHING_DAYS[data.lessonDay]?.label.slice(0, 3)
                : undefined;
        let lessonTime: Date | undefined;
        if (data.lessonTime) {
            const [hours, minutes, seconds] = data.lessonTime.split(":");
            const currentDate = new Date();
            lessonTime = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate(),
                parseInt(hours),
                parseInt(minutes),
                parseInt(seconds),
            );
        }
        // this is to guard against an error when quickly changing days on the register
        let updatedLessonStatuses = lessonStatuses;
        if (lessonStatuses.length !== (data.lessons.edges ?? []).length) {
            updatedLessonStatuses = initialiseRegisterState(
                data.lessons.edges ?? [],
            );
            setLessonStatuses(updatedLessonStatuses);
        }

        const rowData = [
            {
                ...(lessonDayString && lessonTime
                    ? {
                          data: (
                              <Box flex={1} mx="-2" px="1" width="100%">
                                  <Text
                                      {...cellProps?.textProps}
                                      {...(!userIsTeacher
                                          ? { ...cellProps?.pressableTextProps }
                                          : {})}
                                      isTruncated
                                      textAlign="center">{`${
                                      !isRegisterTable
                                          ? lessonDayString + " "
                                          : ""
                                  }${format(lessonTime, "H:mm")}
                                  ${
                                      data.lessonStage?.lessonDuration
                                          ? "-" +
                                            format(
                                                new Date(
                                                    lessonTime.getTime() +
                                                        data.lessonStage
                                                            .lessonDuration *
                                                            60000,
                                                ),
                                                "H:mm",
                                            )
                                          : ""
                                  }`}</Text>
                                  {data?.staffNoteForTeacher ||
                                  data?.teacherNoteForStaff ? (
                                      <Box
                                          bg="transparent"
                                          borderRightColor="transparent"
                                          borderRightWidth="10"
                                          borderStyle="solid"
                                          borderTopColor="primary.500"
                                          borderTopWidth="10"
                                          height="0"
                                          position="absolute"
                                          right="0"
                                          style={{
                                              transform: [{ rotate: "90deg" }],
                                          }}
                                          top={
                                              Platform.OS === "web"
                                                  ? "-8"
                                                  : "-4"
                                          }
                                          width="0"
                                      />
                                  ) : null}
                              </Box>
                          ),
                      }
                    : { data: "Select Time", isPlaceholder: true }),
                tooltipLabel: combineNotes(
                    data?.teacherNoteForStaff,
                    data?.staffNoteForTeacher,
                ),
                onPress: !userIsTeacher
                    ? () => setDayTimePickerIsOpen(true)
                    : undefined,
            },
            // no need to show teacher if on a teacher's register page
            !isRegisterTable
                ? {
                      ...(data.teacher
                          ? {
                                data: (
                                    <HStack
                                        flex={1}
                                        mr="-2"
                                        px="1"
                                        space="1"
                                        width="100%">
                                        <Text
                                            {...cellProps?.textProps}
                                            {...(!userIsTeacher
                                                ? {
                                                      ...cellProps?.pressableTextProps,
                                                  }
                                                : {})}
                                            flex={1}
                                            isTruncated>
                                            {Platform.OS == "web"
                                                ? getFullName(
                                                      data.teacher.user
                                                          .firstName,
                                                      data.teacher.user
                                                          .lastName,
                                                  )
                                                : (data.teacher.user
                                                      .firstName ?? "")}
                                        </Text>
                                        {!userIsTeacher && data.teacher ? (
                                            <Pressable
                                                alignItems="center"
                                                justifyContent="center"
                                                onPress={() =>
                                                    showUpdateBlockDetailsModalHandler(
                                                        {
                                                            type: "TEACHER",
                                                            lessonBlockIds: [
                                                                data.id,
                                                            ],
                                                            selectedProfileId:
                                                                data.teacher
                                                                    ?.id,
                                                            selectedProfileFullName:
                                                                data.teacher
                                                                    ?.user
                                                                    .firstName
                                                                    ? getFullName(
                                                                          data
                                                                              .teacher
                                                                              .user
                                                                              .firstName,
                                                                          data
                                                                              .teacher
                                                                              .user
                                                                              .lastName,
                                                                      )
                                                                    : undefined,
                                                        },
                                                    )
                                                }
                                                px="1">
                                                <Box
                                                    bg="transparent"
                                                    borderBottomColor="surface.500"
                                                    borderBottomWidth="9"
                                                    borderLeftColor="transparent"
                                                    borderLeftWidth="6"
                                                    borderRightColor="transparent"
                                                    borderRightWidth="6"
                                                    borderStyle="solid"
                                                    style={{
                                                        transform: [
                                                            {
                                                                rotate: "180deg",
                                                            },
                                                        ],
                                                    }}
                                                />
                                            </Pressable>
                                        ) : null}
                                    </HStack>
                                ),
                            }
                          : { data: "Select Teacher", isPlaceholder: true }),
                      onPress: () => {
                          if (data.teacher) {
                              onPressProfile(
                                  data.teacher.id,
                                  "TEACHER" as AccountType,
                              );
                          } else {
                              showUpdateBlockDetailsModalHandler({
                                  type: "TEACHER",
                                  lessonBlockIds: [data.id],
                              });
                          }
                      },
                  }
                : {
                      ...(data.school?.name
                          ? {
                                data: data.school.name,
                            }
                          : {
                                data: !userIsTeacher ? "Select School" : "",
                                isPlaceholder: true,
                            }),
                      onPress: !userIsTeacher
                          ? () => {
                                if (data.school) {
                                    onPressSchool?.(data.school.id);
                                } else {
                                    showUpdateBlockDetailsModalHandler({
                                        type: "SCHOOL",
                                        lessonBlockIds: [data.id],
                                    });
                                }
                            }
                          : undefined,
                  },
            ...(Platform.OS === "web"
                ? [
                      {
                          ...(data.lessonStage
                              ? {
                                    data:
                                        data.type === "BREAK"
                                            ? ""
                                            : data.lessonStage.lessonType ===
                                                "INDIVIDUAL"
                                              ? "1-on-1"
                                              : data.lessonStage.lessonType ===
                                                  "PAIRED"
                                                ? "Paired"
                                                : "Group",
                                }
                              : {
                                    data: !userIsTeacher ? "Select Type" : "",
                                    isPlaceholder: true,
                                }),
                          onPress: !userIsTeacher
                              ? () => {
                                    showUpdateBlockDetailsModalHandler({
                                        type: "STAGE",
                                        lessonBlockIds: [data.id],
                                        lessonBlockCursor: cursor,
                                        school: data.school?.name,
                                    });
                                }
                              : undefined,
                      },
                      {
                          ...(data.lessonStage
                              ? {
                                    data:
                                        data.type === "BREAK"
                                            ? ""
                                            : data.lessonStage.stage !==
                                                NO_STAGE
                                              ? data.lessonStage.stage?.toString()
                                              : "Any Stage",
                                }
                              : {
                                    data: !userIsTeacher ? "Select Stage" : "",
                                    isPlaceholder: true,
                                }),
                          onPress: !userIsTeacher
                              ? () => {
                                    showUpdateBlockDetailsModalHandler({
                                        type: "STAGE",
                                        lessonBlockIds: [data.id],
                                        lessonBlockCursor: cursor,
                                        school: data.school?.name,
                                    });
                                }
                              : undefined,
                      },
                  ]
                : []),
            {
                ...(data.instrument
                    ? {
                          data: titleCaseConverter(data.instrument.name) ?? "",
                      }
                    : {
                          data: "Select Instrument",
                          isPlaceholder: true,
                      }),
                onPress: () => {
                    showUpdateBlockDetailsModalHandler({
                        type: "INSTRUMENT",
                        lessonBlockIds: [data.id],
                    });
                },
            },
            {
                ...(data.pupil
                    ? {
                          data: (
                              <HStack
                                  flex={1}
                                  mr="-2"
                                  px="1"
                                  space="1"
                                  width="100%">
                                  <Text
                                      {...cellProps?.textProps}
                                      {...(!userIsTeacher
                                          ? { ...cellProps?.pressableTextProps }
                                          : {})}
                                      flex={1}
                                      isTruncated>
                                      {Platform.OS == "web"
                                          ? getFullName(
                                                data.pupil.user.firstName,
                                                data.pupil.user.lastName,
                                            )
                                          : (data.pupil.user.firstName ?? "")}
                                  </Text>
                                  {!userIsTeacher && data.pupil ? (
                                      <Pressable
                                          alignItems="center"
                                          justifyContent="center"
                                          onPress={() =>
                                              showUpdateBlockDetailsModalHandler(
                                                  {
                                                      type: "PUPIL",
                                                      lessonBlockIds: [data.id],
                                                      school: data.school?.name,
                                                      keepFree: data.keepFree,
                                                      selectedProfileId:
                                                          data.pupil?.id,
                                                      selectedProfileFullName:
                                                          data.pupil?.user
                                                              .firstName
                                                              ? getFullName(
                                                                    data.pupil
                                                                        .user
                                                                        .firstName,
                                                                    data.pupil
                                                                        .user
                                                                        .lastName,
                                                                )
                                                              : undefined,
                                                  },
                                              )
                                          }
                                          px="1">
                                          <Box
                                              bg="transparent"
                                              borderBottomColor="surface.500"
                                              borderBottomWidth="9"
                                              borderLeftColor="transparent"
                                              borderLeftWidth="6"
                                              borderRightColor="transparent"
                                              borderRightWidth="6"
                                              borderStyle="solid"
                                              style={{
                                                  transform: [
                                                      { rotate: "180deg" },
                                                  ],
                                              }}
                                          />
                                      </Pressable>
                                  ) : null}
                                  {data?.pupil?.staffNoteForTeacher ||
                                  data?.pupil?.teacherNoteForStaff ? (
                                      <Box
                                          bg="transparent"
                                          borderRightColor="transparent"
                                          borderRightWidth="10"
                                          borderStyle="solid"
                                          borderTopColor="primary.500"
                                          borderTopWidth="10"
                                          height="0"
                                          position="absolute"
                                          right="0"
                                          style={{
                                              transform: [{ rotate: "90deg" }],
                                          }}
                                          top={
                                              Platform.OS === "web"
                                                  ? "-8"
                                                  : "-4"
                                          }
                                          width="0"
                                      />
                                  ) : null}
                              </HStack>
                          ),
                      }
                    : {
                          data: data?.keepFree
                              ? "Keep Free"
                              : !userIsTeacher
                                ? "Select Pupil"
                                : "Free Slot",
                          isPlaceholder: !data?.keepFree,
                      }),
                tooltipLabel: combineNotes(
                    data?.pupil?.teacherNoteForStaff,
                    data?.pupil?.staffNoteForTeacher,
                ),
                onPress: !userIsTeacher
                    ? () => {
                          if (data.pupil) {
                              onPressProfile(
                                  data.pupil.id,
                                  "PUPIL" as AccountType,
                              );
                          } else {
                              showUpdateBlockDetailsModalHandler({
                                  type: "PUPIL",
                                  lessonBlockIds: [data.id],
                                  school: data.school?.name,
                                  keepFree: data.keepFree,
                              });
                          }
                      }
                    : undefined,
            },
            {
                data: schoolYearConverter(data.pupil?.schoolYear, {
                    short: true,
                    selectedStartingYear,
                    currentStartingYear,
                }),
            },
        ] as TableData;

        if (isRegisterTable) {
            if (data.pupil) {
                rowData.push(
                    ...(lessons?.map((lesson, index) => {
                        return {
                            data: (
                                <LessonStatus
                                    hasRegisterNote={Boolean(
                                        updatedLessonStatuses[index]
                                            .teacherRegisterNoteForStaff ||
                                            updatedLessonStatuses[index]
                                                .staffRegisterNoteForTeacher,
                                    )}
                                    height="39px"
                                    hideLabel
                                    isRearranged={Boolean(
                                        lesson?.node?.rearrangedTimestamp &&
                                            !lesson?.node?.weekWasRearranged,
                                    )}
                                    mx="-2"
                                    status={updatedLessonStatuses[index].status}
                                />
                            ),
                            tooltipLabel: combineNotes(
                                updatedLessonStatuses[index]
                                    .teacherRegisterNoteForStaff,
                                updatedLessonStatuses[index]
                                    .staffRegisterNoteForTeacher,
                                !lesson?.node?.weekWasRearranged
                                    ? lesson?.node?.rearrangedTimestamp
                                    : undefined,
                            ),
                            onPress: () => {
                                // If register is break, do not make the lesson marking editable
                                if (
                                    lesson?.node &&
                                    lesson?.node.status !== "BREAK"
                                ) {
                                    // if register is blank, default to marking as present
                                    if (
                                        updatedLessonStatuses[index].status ===
                                        "BLANK"
                                    ) {
                                        const clonedLessonStatuses =
                                            updatedLessonStatuses.slice();
                                        clonedLessonStatuses[index].status =
                                            "PRESENT";
                                        setLessonStatuses(clonedLessonStatuses);
                                        updateRegisterState?.(
                                            {
                                                lessonId: lesson.node.id,
                                                status: "PRESENT",
                                            },
                                            1,
                                        );
                                    } else {
                                        setRegisterModalLessonInfo({
                                            id: lesson.node.id,
                                            index: index,
                                            status:
                                                updatedLessonStatuses[index]
                                                    .status ?? "BLANK",
                                            teacherRegisterNoteForStaff:
                                                updatedLessonStatuses[index]
                                                    .teacherRegisterNoteForStaff ??
                                                undefined,
                                            staffRegisterNoteForTeacher:
                                                updatedLessonStatuses[index]
                                                    .staffRegisterNoteForTeacher ??
                                                undefined,
                                            pupilName: getFullName(
                                                data.pupil?.user.firstName,
                                                data.pupil?.user.lastName,
                                            ),
                                            instrument: titleCaseConverter(
                                                data.instrument?.name,
                                            ),
                                            scheduledTimestamp: lesson.node
                                                .scheduledTimestamp
                                                ? format(
                                                      new Date(
                                                          lesson?.node
                                                              ?.rearrangedTimestamp ??
                                                              lesson.node
                                                                  .scheduledTimestamp,
                                                      ),
                                                      "EEE d MMM HH:mm",
                                                  )
                                                : undefined,
                                        });
                                    }
                                }
                            },
                        };
                    }) as TableData),
                );
            } else {
                rowData.push(
                    ...(lessons?.map((lesson, index) => {
                        return {
                            data: (
                                <LessonStatus
                                    height="39px"
                                    hideBlankIcon
                                    hideDropdownIcon
                                    hideLabel
                                    mx="-2"
                                    status={updatedLessonStatuses[index].status}
                                />
                            ),
                            onPress: () => {
                                // If register is break, do not make the lesson marking editable
                                if (
                                    lesson?.node &&
                                    lesson?.node.status !== "BREAK"
                                ) {
                                    const clonedLessonStatuses =
                                        updatedLessonStatuses.slice();
                                    const newStatus =
                                        updatedLessonStatuses[index].status ===
                                        "BLANK"
                                            ? "NO_LESSON"
                                            : "BLANK";
                                    clonedLessonStatuses[index].status =
                                        newStatus;
                                    setLessonStatuses(clonedLessonStatuses);
                                    updateRegisterState?.({
                                        lessonId: lesson.node.id,
                                        status: newStatus,
                                    });
                                }
                            },
                        };
                    }) ?? []),
                );
            }
        }

        if (!userIsTeacher) {
            rowData.unshift({
                data: (
                    <Checkbox
                        isChecked={checkBoxIsChecked}
                        onChange={(isSelected) => {
                            setCheckBoxIsChecked(isSelected);
                            checkboxChangeHandler(data.id, isSelected);
                        }}
                        size="md"
                        value={data.id}
                    />
                ),
                onPress: () => {
                    return;
                },
            });
        }
        return rowData;
    }, [
        data.lessonDay,
        data.lessonTime,
        data.lessons.edges,
        data.lessonStage,
        data?.staffNoteForTeacher,
        data?.teacherNoteForStaff,
        data.teacher,
        data.school,
        data.type,
        data.instrument,
        data.pupil,
        data.keepFree,
        data.id,
        lessonStatuses,
        cellProps?.textProps,
        cellProps?.pressableTextProps,
        userIsTeacher,
        isRegisterTable,
        selectedStartingYear,
        currentStartingYear,
        initialiseRegisterState,
        showUpdateBlockDetailsModalHandler,
        onPressProfile,
        onPressSchool,
        cursor,
        lessons,
        updateRegisterState,
        checkBoxIsChecked,
        checkboxChangeHandler,
    ]);

    const updateRegisterHandler = useCallback(
        (
            variables: {
                lessonId: string;
                staffRegisterNoteForTeacher?: string | undefined;
                status: string;
                teacherRegisterNoteForStaff?: string | undefined;
            },
            lessonIndex: number,
            progressChange?: number | undefined,
        ) => {
            const clonedLessonStatuses = lessonStatuses.slice();
            clonedLessonStatuses[lessonIndex].status =
                variables.status as LessonModelStatus;
            if (variables.teacherRegisterNoteForStaff) {
                clonedLessonStatuses[lessonIndex].teacherRegisterNoteForStaff =
                    variables.teacherRegisterNoteForStaff;
            }
            if (variables.staffRegisterNoteForTeacher) {
                clonedLessonStatuses[lessonIndex].staffRegisterNoteForTeacher =
                    variables.staffRegisterNoteForTeacher;
            }
            clonedLessonStatuses[lessonIndex].status =
                variables.status as LessonModelStatus;
            setLessonStatuses(clonedLessonStatuses);
            updateRegisterState?.(variables, progressChange);
        },
        [lessonStatuses, updateRegisterState],
    );

    return (
        <>
            <Row
                bg={
                    data.type === "BREAK"
                        ? "yellow.100"
                        : !data.pupil
                          ? "muted.200"
                          : data.lessonStage?.lessonType === "INDIVIDUAL"
                            ? "transparent"
                            : data.lessonStage?.lessonType === "PAIRED"
                              ? "secondary.100"
                              : "primary.50"
                }
                cellProps={cellProps}
                data={rowData}
                flexArray={flexArray}
                rowHeight={rowHeight}
                rowIndex={0}
                tableBorderColor={tableBorderColor}
                tableBorderWidth={tableBorderWidth}
            />
            <DayTimePickerModal
                animationType="fade"
                dayIndex={(scheduledDayTime.dayIndex ?? 0) + 1}
                duration={data.lessonStage?.lessonDuration}
                hours={scheduledDayTime.hours ?? 9}
                label="Reschedule Lesson Slot"
                locale="en"
                minutes={scheduledDayTime.minutes ?? 0}
                onConfirm={onConfirmDayTime}
                onDismiss={() => setDayTimePickerIsOpen(false)}
                saveLabel="Update"
                uppercase={false}
                visible={dayTimePickerIsOpen}
            />
            {updateRegisterState ? (
                <ManageRegisterLessonModal
                    hideModal={() => setRegisterModalLessonInfo(undefined)}
                    lessonInfo={registerModalLessonInfo}
                    showModal={typeof registerModalLessonInfo !== "undefined"}
                    updateRegister={updateRegisterHandler}
                    userIsTeacher={userIsTeacher}
                />
            ) : null}
        </>
    );
});

LessonBlockTableRow.displayName = "LessonBlockTableRow";

export default React.memo(LessonBlockTableRow);
