/**
 * @generated SignedSource<<63ec11cc3a3cdf6ed70e25ea331e1eed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LessonModelStatus = "BLANK" | "BREAK" | "LEFT_SCHOOL" | "MISSED" | "NO_LESSON" | "OTHER_TEACHER" | "PRESENT" | "PRESENT_DOUBLE" | "PUPIL_ABSENT" | "STOPPED_LESSONS" | "TEACHER_ABSENT" | "%future added value";
export type UpdateRegistersForWeekInput = {
  block: number;
  clientMutationId?: string | null;
  dayIndex: number;
  replacementLessonIndex?: number | null;
  scheduledDate?: string | null;
  schoolName?: string | null;
  startingYear: number;
  status: string;
  teacherId: string;
};
export type UpdateRegistersForWeekMutation$variables = {
  input: UpdateRegistersForWeekInput;
};
export type UpdateRegistersForWeekMutation$data = {
  readonly updateRegistersForWeek: {
    readonly errors: any | null;
    readonly lessons: ReadonlyArray<{
      readonly id: string;
      readonly lessonDuration: number | null;
      readonly staffRegisterNoteForTeacher: string | null;
      readonly status: LessonModelStatus;
      readonly teacherRegisterNoteForStaff: string | null;
    } | null> | null;
    readonly success: boolean | null;
  } | null;
};
export type UpdateRegistersForWeekMutation = {
  response: UpdateRegistersForWeekMutation$data;
  variables: UpdateRegistersForWeekMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateRegistersForWeekPayload",
    "kind": "LinkedField",
    "name": "updateRegistersForWeek",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LessonNode",
        "kind": "LinkedField",
        "name": "lessons",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lessonDuration",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "staffRegisterNoteForTeacher",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "teacherRegisterNoteForStaff",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateRegistersForWeekMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateRegistersForWeekMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6da71f772541186038b392522b2f4f36",
    "id": null,
    "metadata": {},
    "name": "UpdateRegistersForWeekMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateRegistersForWeekMutation(\n  $input: UpdateRegistersForWeekInput!\n) {\n  updateRegistersForWeek(input: $input) {\n    success\n    errors\n    lessons {\n      id\n      status\n      lessonDuration\n      staffRegisterNoteForTeacher\n      teacherRegisterNoteForStaff\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "79ed199080f5c88172d877289d993279";

export default node;
