import { graphql } from "react-relay";

export const remove_term_dates = graphql`
    mutation RemoveTermDatesMutation(
        $connections: [ID!]!
        $input: RemoveTermDatesInput!
    ) {
        removeTermDates(input: $input) {
            success
            errors
            deletedTermDateId @deleteEdge(connections: $connections)
        }
    }
`;
