import { graphql } from "react-relay";

export const edit_payrate = graphql`
    mutation EditPayrateMutation($input: EditPayrateInput!) {
        editPayrate(input: $input) {
            success
            errors
            payrate {
                id
                startDate
                hourlyRate
            }
        }
    }
`;
