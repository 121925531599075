/**
 * @generated SignedSource<<197310c6f9224281c61703d2a894481c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SendDraftInvoicesForBlockInput = {
  block: number;
  clientMutationId?: string | null;
  invoicesInBatch: number;
  numberOfInvoicesSent?: number | null;
  onlyDrafts?: boolean | null;
  schoolIds: ReadonlyArray<string | null>;
  startingYear: number;
  totalToSend?: number | null;
};
export type SendDraftInvoicesForBlockMutation$variables = {
  block: number;
  input: SendDraftInvoicesForBlockInput;
  startingYear: number;
};
export type SendDraftInvoicesForBlockMutation$data = {
  readonly sendDraftInvoicesForBlock: {
    readonly erroredInvoice: {
      readonly assignedTo: {
        readonly accountNumber: string | null;
        readonly id: string;
        readonly user: {
          readonly email: string;
          readonly firstName: string;
          readonly lastName: string;
        };
      } | null;
      readonly id: string;
    } | null;
    readonly errors: any | null;
    readonly numberOfInvoicesSent: number | null;
    readonly profile: {
      readonly id: string;
      readonly profileGroup: {
        readonly id: string;
        readonly sendDraftsProgress: number | null;
      } | null;
      readonly totalAmountInvoiced: number | null;
      readonly totalAmountPaid: number | null;
      readonly totalInvoices: number | null;
      readonly totalInvoicesSent: number | null;
      readonly totalInvoicesUnpaid: number | null;
    } | null;
    readonly success: boolean | null;
    readonly totalToSend: number | null;
  } | null;
};
export type SendDraftInvoicesForBlockMutation = {
  response: SendDraftInvoicesForBlockMutation$data;
  variables: SendDraftInvoicesForBlockMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "block"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startingYear"
},
v3 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfInvoicesSent",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalToSend",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumber",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v13 = [
  {
    "kind": "Variable",
    "name": "block",
    "variableName": "block"
  },
  {
    "kind": "Variable",
    "name": "startingYear",
    "variableName": "startingYear"
  }
],
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "ProfileNode",
  "kind": "LinkedField",
  "name": "profile",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": (v13/*: any*/),
      "kind": "ScalarField",
      "name": "totalInvoices",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v13/*: any*/),
      "kind": "ScalarField",
      "name": "totalAmountInvoiced",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v13/*: any*/),
      "kind": "ScalarField",
      "name": "totalInvoicesSent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v13/*: any*/),
      "kind": "ScalarField",
      "name": "totalAmountPaid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v13/*: any*/),
      "kind": "ScalarField",
      "name": "totalInvoicesUnpaid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileGroupNode",
      "kind": "LinkedField",
      "name": "profileGroup",
      "plural": false,
      "selections": [
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sendDraftsProgress",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SendDraftInvoicesForBlockMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "SendDraftInvoicesForBlockPayload",
        "kind": "LinkedField",
        "name": "sendDraftInvoicesForBlock",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceNode",
            "kind": "LinkedField",
            "name": "erroredInvoice",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileNode",
                "kind": "LinkedField",
                "name": "assignedTo",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DjangoUserNode",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SendDraftInvoicesForBlockMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "SendDraftInvoicesForBlockPayload",
        "kind": "LinkedField",
        "name": "sendDraftInvoicesForBlock",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceNode",
            "kind": "LinkedField",
            "name": "erroredInvoice",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileNode",
                "kind": "LinkedField",
                "name": "assignedTo",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DjangoUserNode",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f10e91e7ba3cf0dd43df3e125668613c",
    "id": null,
    "metadata": {},
    "name": "SendDraftInvoicesForBlockMutation",
    "operationKind": "mutation",
    "text": "mutation SendDraftInvoicesForBlockMutation(\n  $input: SendDraftInvoicesForBlockInput!\n  $startingYear: Int!\n  $block: Int!\n) {\n  sendDraftInvoicesForBlock(input: $input) {\n    success\n    errors\n    numberOfInvoicesSent\n    totalToSend\n    erroredInvoice {\n      id\n      assignedTo {\n        id\n        accountNumber\n        user {\n          firstName\n          lastName\n          email\n          id\n        }\n      }\n    }\n    profile {\n      id\n      totalInvoices(startingYear: $startingYear, block: $block)\n      totalAmountInvoiced(startingYear: $startingYear, block: $block)\n      totalInvoicesSent(startingYear: $startingYear, block: $block)\n      totalAmountPaid(startingYear: $startingYear, block: $block)\n      totalInvoicesUnpaid(startingYear: $startingYear, block: $block)\n      profileGroup {\n        id\n        sendDraftsProgress\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8556fbabb32c34e6ea4a74fc1a6fbde0";

export default node;
