import React, { useRef, useCallback, useMemo, useReducer } from "react";
import type { ReactElement, ComponentProps } from "react";

import { Text, HStack, Box, Center, Button, CloseIcon } from "native-base";
import type { VStack } from "native-base";

import type { LessonStageData } from "../Tabs/SchoolRates";
import ButtonDebounced from "pianofunclub-shared/components/Buttons/ButtonDebounced";
import CurrencyInput from "pianofunclub-shared/components/Inputs/CurrencyInput";
import TextInput from "pianofunclub-shared/components/Inputs/TextInput";
import AlertPopup from "pianofunclub-shared/components/NativeBaseExtended/AlertPopup";
import Select from "pianofunclub-shared/components/NativeBaseExtended/Select";

import { LESSON_TYPES, NO_STAGE } from "pianofunclub-shared/utils/constants";
import { isNumeric } from "pianofunclub-shared/utils/converters";
import { createReducer } from "pianofunclub-shared/utils/reducers";

type VStackProps = ComponentProps<typeof VStack>;

interface Props extends VStackProps {
    data: NonNullable<NonNullable<NonNullable<LessonStageData>[0]>["node"]>;
    deleteLessonStage: (
        variables: { lessonStageId: string },
        onComplete?: () => void,
    ) => void;
    deleteLessonStageInFlight: boolean;
    editLessonStage: (variables: {
        costPerLesson?: number;
        lessonDuration?: number;
        lessonStageId: string;
        lessonType?: string;
    }) => void;
}

type ReducerValues = {
    costPerLessonIsValid: boolean;
    deleteAlertIsOpen: boolean;
    lessonDurationIsValid: boolean;
};

type ReducerTypes = boolean;

const LessonStage = (props: Props): ReactElement => {
    const {
        data,
        deleteLessonStage,
        deleteLessonStageInFlight,
        editLessonStage,
    } = props;

    const initialState = useMemo(() => {
        return {
            values: {
                lessonDurationIsValid: data.lessonDuration != null,
                costPerLessonIsValid: data.costPerLesson != null,
                deleteAlertIsOpen: false,
            },
        };
    }, [data.costPerLesson, data.lessonDuration]);

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [state, dispatchState] = useReducer(reducer, initialState);

    const safeDeleteRef = useRef(null); // used to highlight the 'back' button in the logout button alert dialogue
    const lessonDurationInputRef = useRef(null);
    const costPerLessonInputRef = useRef(null);

    const selectChangeHandler = useCallback(
        (itemValue: string) => {
            editLessonStage({
                lessonStageId: data.id,
                lessonType: itemValue,
            });
        },
        [data.id, editLessonStage],
    );

    const inputFinishEditingHandler = useCallback(
        (
            inputIdentifier?: string | number,
            inputValue?: string,
            isValid?: boolean,
        ) => {
            if (
                typeof inputValue === "string" &&
                typeof inputIdentifier === "string"
            ) {
                const cleanedInput = inputValue.replace("£", "");
                if (isValid && isNumeric(cleanedInput)) {
                    editLessonStage({
                        lessonStageId: data.id,
                        [inputIdentifier]: parseFloat(cleanedInput),
                    });
                }
                dispatchState({
                    input: `${inputIdentifier}IsValid`,
                    value: isValid && isNumeric(cleanedInput),
                });
            } else {
                dispatchState({
                    input: `${inputIdentifier}IsValid`,
                    value: false,
                });
            }
        },
        [data.id, editLessonStage],
    );

    return (
        <HStack
            alignItems="center"
            bg="primary.50"
            borderRadius="2xl"
            flexDirection="row"
            justifyContent="space-between"
            mb="4"
            overflow="hidden"
            pl="6"
            py="4"
            shadow={1}
            space="12"
            width="980px"
            {...props}
        >
            <Text flex={1} fontSize="xl" pr="6">
                {data.stage !== NO_STAGE ? `Stage ${data.stage}` : "Any Stage"}
            </Text>
            <Center>
                <Select
                    bg="surface.100"
                    color="surface.900"
                    fontSize="md"
                    onValueChange={selectChangeHandler}
                    placeholder="Select lesson type"
                    selectedValue={data.lessonType}
                    textAlign="center"
                    width="210"
                >
                    {LESSON_TYPES.map((item) => {
                        return (
                            <Select.Item
                                key={item.value}
                                actionSheetLabel={item.label}
                                startIcon={item.icon}
                                value={item.value}
                            />
                        );
                    })}
                </Select>
            </Center>
            <HStack justifyContent="center" space="2">
                <Box width="70">
                    <TextInput
                        ref={lessonDurationInputRef}
                        id="lessonDuration"
                        initiallyValid={state.values.lessonDurationIsValid}
                        initialValue={data.lessonDuration?.toString()}
                        invalidIndicator
                        keyboardType="number-pad"
                        maxLength={3}
                        numOnly
                        onFinishEditing={inputFinishEditingHandler}
                        textAlign="center"
                    />
                </Box>
                <Text fontWeight="light">minutes</Text>
            </HStack>
            <HStack justifyContent="center" space="2">
                <Box width="100">
                    <CurrencyInput
                        ref={costPerLessonInputRef}
                        fontSize="md"
                        id="costPerLesson"
                        initiallyValid={state.values.costPerLessonIsValid}
                        initialValue={data.costPerLesson}
                        invalidIndicator
                        isInvalid={!state.values.costPerLessonIsValid}
                        keyboardType="number-pad"
                        onFinishEditing={inputFinishEditingHandler}
                        size="lg"
                        textAlign="center"
                    />
                </Box>
                <Text fontWeight="light">per lesson</Text>
            </HStack>
            <Button
                _hover={{
                    bg: "transparent",
                    opacity: 0.8,
                }}
                _pressed={{
                    bg: "transparent",
                    opacity: 0.7,
                }}
                leftIcon={<CloseIcon color="surface.500" size="5" />}
                onPress={() =>
                    dispatchState({ input: "deleteAlertIsOpen", value: true })
                }
                variant="ghost"
            />
            <AlertPopup
                alertIsOpen={state.values.deleteAlertIsOpen}
                body="Be especially careful when deleting lesson stages for past blocks."
                header="Are you sure you want to delete this lesson stage?"
                safeButtonRef={safeDeleteRef}
                setAlertIsOpen={() =>
                    dispatchState({ input: "deleteAlertIsOpen", value: false })
                }
            >
                <Button
                    ref={safeDeleteRef}
                    _text={{ fontSize: "lg" }}
                    colorScheme="surface"
                    height="50"
                    minWidth="56"
                    onPress={() => {
                        dispatchState({
                            input: "deleteAlertIsOpen",
                            value: false,
                        });
                    }}
                    width="25%"
                >
                    No, go back
                </Button>
                <ButtonDebounced
                    _loading={{ opacity: 1 }}
                    _text={{ fontSize: "lg" }}
                    colorScheme="red"
                    height="50"
                    isLoading={deleteLessonStageInFlight}
                    minWidth="56"
                    onPress={() => {
                        deleteLessonStage({ lessonStageId: data.id }, () =>
                            dispatchState({
                                input: "deleteAlertIsOpen",
                                value: false,
                            }),
                        );
                    }}
                    width="25%"
                >
                    Yes, delete it
                </ButtonDebounced>
            </AlertPopup>
        </HStack>
    );
};

export default LessonStage;
