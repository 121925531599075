import React, { useState } from "react";
import type { ReactElement, ComponentProps } from "react";

import { Box, Pressable, Text, Tooltip } from "native-base";
import { Platform } from "react-native";

type TextProps = ComponentProps<typeof Text>;
type BoxProps = ComponentProps<typeof Box>;
type PressableProps = ComponentProps<typeof Pressable>;

interface Props extends BoxProps {
    data: string | number | ReactElement;
    hideOuterSideBorder?: boolean;
    hideTopBorder?: boolean;
    icon?: React.JSX.Element;
    iconPosition?: "before" | "after";
    isFinal: boolean;
    isPlaceholder?: boolean;
    onPress?: () => void;
    placeholderTextProps?: TextProps;
    pressableProps?: PressableProps;
    pressableTextProps?: TextProps;
    rowIndex?: number;
    tableBorderColor?: string;
    tableBorderWidth?: number;
    textProps?: TextProps;
    tooltipLabel?: string;
}

export type CellProps = Omit<Props, "data" | "isFinal" | "index">;

const Cell = (props: Props): ReactElement => {
    const {
        data,
        hideOuterSideBorder,
        hideTopBorder,
        icon,
        iconPosition,
        isFinal,
        isPlaceholder,
        onPress,
        placeholderTextProps,
        pressableProps,
        pressableTextProps,
        rowIndex,
        tableBorderColor,
        tableBorderWidth = 0,
        textProps,
        tooltipLabel,
    } = props;

    const [tooltipVisible, setTooltipVisible] = useState(false);

    return (
        <Box
            borderColor={tableBorderColor}
            borderRightWidth={
                hideOuterSideBorder && isFinal ? 0 : tableBorderWidth
            }
            borderTopWidth={
                hideTopBorder && rowIndex === 0 ? 0 : tableBorderWidth
            }
            justifyContent="center"
            {...props}
        >
            <Tooltip
                isOpen={tooltipVisible && Boolean(tooltipLabel)}
                label={tooltipLabel ?? ""}
                placement={Platform.OS !== "web" ? "left top" : undefined}
                textAlign="center"
            >
                <Pressable
                    _pressed={{ opacity: 0.6 }}
                    alignItems="center"
                    flexDirection={
                        iconPosition === "before" ? "row-reverse" : "row"
                    }
                    isDisabled={!onPress && !tooltipLabel}
                    justifyContent="center"
                    onHoverIn={() => setTooltipVisible(true)}
                    onHoverOut={() => setTooltipVisible(false)}
                    onPress={() => onPress?.()}
                    onPressIn={
                        !onPress ? () => setTooltipVisible(true) : undefined
                    }
                    onPressOut={
                        !onPress ? () => setTooltipVisible(false) : undefined
                    }
                    {...pressableProps}
                >
                    {typeof data === "string" || typeof data === "number" ? (
                        <Text
                            flex={1}
                            isTruncated
                            {...(onPress
                                ? {
                                      ...textProps,
                                      ...pressableTextProps,
                                      ...(isPlaceholder &&
                                          placeholderTextProps),
                                  }
                                : textProps)}
                        >
                            {data}
                        </Text>
                    ) : (
                        data
                    )}
                    {icon}
                </Pressable>
            </Tooltip>
        </Box>
    );
};

export default React.memo(Cell);
