import { graphql } from "react-relay";

export const remove_line_item = graphql`
    mutation RemoveLineItemMutation(
        $connections: [ID!]!
        $input: RemoveLineItemInput!
    ) {
        removeLineItem(input: $input) {
            success
            errors
            deletedLineItemId @deleteEdge(connections: $connections)
            invoice {
                id
                lastModified
                totalAmount
                status
            }
        }
    }
`;
