import React from "react";
import type { ReactElement } from "react";

import type {
    CompositeNavigationProp,
    RouteProp,
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import type { StackNavigationProp } from "@react-navigation/stack";

import ChangeNameScreen, {
    screenOptions as changeNameScreenOptions,
} from "../screens/profile/ChangeNameScreen";
import ChangePasswordScreen, {
    screenOptions as changePasswordScreenOptions,
} from "../screens/profile/ChangePasswordScreen";
import ProfileHubScreen, {
    screenOptions as profileHubScreenOptions,
} from "../screens/profile/ProfileHubScreen";
import UpdateEmailScreen, {
    screenOptions as updateEmailScreenOptions,
} from "../screens/profile/UpdateEmailScreen";

import type { HubTabNavigatorProps } from "./HubNavigator";
import defaultNavOptions from "./utils/DefaultNavigationOptions";

export type ProfileStackNavigatorParamList = {
    ChangeName: undefined;
    ChangePassword: undefined;
    ProfileHub: undefined;
    UpdateEmail: undefined;
};

type NavigatorProps<T extends keyof ProfileStackNavigatorParamList> =
    StackNavigationProp<ProfileStackNavigatorParamList, T>;

export type ProfileStackNavigatorProps<
    T extends keyof ProfileStackNavigatorParamList,
> = CompositeNavigationProp<
    NavigatorProps<T>,
    HubTabNavigatorProps<"ProfileHubTab">
>;

export type ProfileStackRouteProps<
    T extends keyof ProfileStackNavigatorParamList,
> = RouteProp<ProfileStackNavigatorParamList, T>;

const ProfileStackNavigator =
    createStackNavigator<ProfileStackNavigatorParamList>();

const ProfileNavigator = (): ReactElement => {
    return (
        <ProfileStackNavigator.Navigator screenOptions={defaultNavOptions}>
            <ProfileStackNavigator.Screen
                component={ProfileHubScreen}
                name="ProfileHub"
                options={profileHubScreenOptions}
            />
            <ProfileStackNavigator.Screen
                component={UpdateEmailScreen}
                name="UpdateEmail"
                options={updateEmailScreenOptions}
            />
            <ProfileStackNavigator.Screen
                component={ChangePasswordScreen}
                name="ChangePassword"
                options={changePasswordScreenOptions}
            />
            <ProfileStackNavigator.Screen
                component={ChangeNameScreen}
                name="ChangeName"
                options={changeNameScreenOptions}
            />
        </ProfileStackNavigator.Navigator>
    );
};

export default ProfileNavigator;
