import React, { useMemo, useCallback, useState, useEffect } from "react";
import type {
    ReactElement,
    ComponentProps,
    Dispatch,
    MutableRefObject,
} from "react";

import { format } from "date-fns";
import { HStack, VStack, Text, Box, Pressable, Button } from "native-base";
import { useWindowDimensions, Platform } from "react-native";

import type {
    ReducerTypes as AccountScreenReducerTypes,
    ReducerValues as AccountScreenReducerValues,
} from "../../screens/accounts/AccountScreen";
import type { NavigationProps as AccountScreenNavigationProps } from "../../screens/accounts/AccountScreen";
import Cell from "pianofunclub-shared/components/Base/Cell";
import Row from "pianofunclub-shared/components/Base/Row";
import type { TableData } from "pianofunclub-shared/components/Base/Row";
import ManageRegisterLessonModal from "pianofunclub-shared/components/Modals/ManageRegisterLessonModal";
import type {
    LessonInfo as RegisterLessonInfo,
    RegisterUpdate,
} from "pianofunclub-shared/components/Modals/ManageRegisterLessonModal";
import { OptionsIcon } from "pianofunclub-shared/components/Other/Icons";
import LessonStatus from "pianofunclub-shared/components/Registers/LessonStatus";
import type { LessonBlockData } from "pianofunclub-shared/components/Registers/TeacherRegisters";

import type { LessonModelStatus } from "pianofunclub-shared/relay/graphql/registers/__generated__/UpdateRegistersMutation.graphql";

import type { Mutable } from "pianofunclub-shared/types";
import { NO_STAGE } from "pianofunclub-shared/utils/constants";
import {
    combineNotes,
    getScaledWindowDimension,
    titleCaseConverter,
} from "pianofunclub-shared/utils/converters";
import {
    getFullName,
    getInstrumentIcon,
    getLessonTypeIcon,
} from "pianofunclub-shared/utils/extractors";
import type { Action } from "pianofunclub-shared/utils/reducers";

type VStackProps = ComponentProps<typeof VStack>;

interface Props extends VStackProps {
    data: NonNullable<NonNullable<LessonBlockData>["node"]>;
    dispatchState: Dispatch<
        Action<AccountScreenReducerValues, AccountScreenReducerTypes>
    >;
    navigation: AccountScreenNavigationProps;
    openLessonBlockOptionsHandler: (lessonBlockId: string) => void;
    registerUpdates: MutableRefObject<RegisterUpdate[]>;
    showUpdateBlockDetailsModalHandler: (variables: {
        keepFree?: boolean;
        lessonBlockIds: string[];
        onUpdate?: () => void;
        school?: string;
        selectedProfileFullName?: string;
        selectedProfileId?: string;
        type: string;
    }) => void;
    sideBarWidth: number;
    startingYear: number;
    updateRegisterState: (
        variables: {
            lessonId: string;
            registerNote?: string;
            status: string;
        },
        progressChange?: number,
    ) => void;
}

const TABLE_BORDER_COLOR = "surface.400";
const TABLE_BORDER_WIDTH = 1;
const TABLE_BORDER_RADIUS = 10;

const LessonBlockRegister = (props: Props): ReactElement => {
    const {
        data,
        dispatchState,
        navigation,
        openLessonBlockOptionsHandler,
        registerUpdates,
        showUpdateBlockDetailsModalHandler,
        sideBarWidth,
        startingYear,
        updateRegisterState,
    } = props;

    const lessons = useMemo(() => {
        return data.lessons.edges ?? [];
    }, [data]);

    const flexArray = useMemo(() => {
        return Array<number>(lessons.length).fill(0.5);
    }, [lessons.length]);

    const tableTeacherName = useMemo(() => {
        return [
            {
                ...(data.teacher
                    ? {
                          data: (
                              <HStack
                                  flex={1}
                                  mr="-2"
                                  px="1"
                                  space="1"
                                  width="100%">
                                  <Text
                                      flex={1}
                                      fontFamily="Poppins-Regular"
                                      fontSize="md"
                                      isTruncated={false}
                                      textAlign="center">
                                      {Platform.OS == "web"
                                          ? getFullName(
                                                data.teacher.user.firstName,
                                                data.teacher.user.lastName,
                                            )
                                          : (data.teacher.user.firstName ?? "")}
                                  </Text>
                                  {data.teacher ? (
                                      <Pressable
                                          alignItems="center"
                                          justifyContent="center"
                                          onPress={() =>
                                              showUpdateBlockDetailsModalHandler(
                                                  {
                                                      type: "TEACHER",
                                                      lessonBlockIds: [data.id],
                                                      selectedProfileId:
                                                          data.teacher?.id,
                                                      selectedProfileFullName:
                                                          data.teacher?.user
                                                              .firstName
                                                              ? getFullName(
                                                                    data.teacher
                                                                        .user
                                                                        .firstName,
                                                                    data.teacher
                                                                        .user
                                                                        .lastName,
                                                                )
                                                              : undefined,
                                                  },
                                              )
                                          }
                                          px="2">
                                          <Box
                                              bg="transparent"
                                              borderBottomColor="surface.500"
                                              borderBottomWidth="9"
                                              borderLeftColor="transparent"
                                              borderLeftWidth="6"
                                              borderRightColor="transparent"
                                              borderRightWidth="6"
                                              borderStyle="solid"
                                              style={{
                                                  transform: [
                                                      {
                                                          rotate: "180deg",
                                                      },
                                                  ],
                                              }}
                                          />
                                      </Pressable>
                                  ) : null}
                              </HStack>
                          ),
                      }
                    : { data: "Select Teacher", isPlaceholder: true }),
                onPress: () => {
                    if (data.teacher?.id) {
                        navigation.push("Account", {
                            profileId: data.teacher.id,
                            accountType: "TEACHER",
                            startingYear: startingYear,
                            block: data.block ?? undefined,
                            dayIndex: data.lessonDay ?? undefined,
                        });
                    } else {
                        showUpdateBlockDetailsModalHandler({
                            type: "TEACHER",
                            lessonBlockIds: [data.id],
                        });
                    }
                },
            },
        ];
    }, [
        data.block,
        data.id,
        data.lessonDay,
        data.teacher,
        navigation,
        showUpdateBlockDetailsModalHandler,
        startingYear,
    ]);

    const tableHeaders = useMemo(() => {
        return lessons.map((item) => {
            if (item?.node?.scheduledTimestamp) {
                const timestamp = new Date(
                    item.node?.rearrangedTimestamp ??
                        item.node.scheduledTimestamp,
                );
                return {
                    data: `${format(timestamp, "EEE d MMM")}\n${format(
                        timestamp,
                        "HH:mm",
                    )}${
                        typeof item.node.lessonDuration === "number" ||
                        typeof data.lessonStage?.lessonDuration === "number"
                            ? " - " + 
                              format(
                                  new Date(
                                      new Date(timestamp).getTime() +
                                          ((item.node.lessonDuration ??
                                              data.lessonStage
                                                  ?.lessonDuration) as number) *
                                              60000,
                                  ),
                                  "HH:mm",
                              )
                            : ""
                    }`,
                    onPress: () => {
                        dispatchState({
                            input: "dateTimePickerModalDate",
                            value: timestamp,
                        });
                        dispatchState({
                            input: "dateTimePickerModalLessonId",
                            value: item.node?.id,
                        });
                        dispatchState({
                            input: "dateTimePickerModalLessonDuration",
                            value:
                                item.node?.lessonDuration ??
                                data.lessonStage?.lessonDuration ??
                                undefined,
                        });
                    },
                };
            } else if (
                typeof item?.node?.replacementLessonColumnIndex === "number"
            ) {
                return {
                    data: `Replacement Lesson ${
                        item?.node?.replacementLessonColumnIndex + 1
                    }`,
                };
            } else {
                return { data: "" };
            }
        });
    }, [data.lessonStage?.lessonDuration, dispatchState, lessons]);

    const initialiseState = useCallback(() => {
        return (
            lessons?.map((lesson) => {
                const existingUpdate = registerUpdates?.current.find(
                    (item) => item.lessonId === lesson?.node?.id,
                );
                return {
                    status:
                        existingUpdate?.status ??
                        lesson?.node?.status ??
                        "NO_LESSON",
                    teacherRegisterNoteForStaff:
                        lesson?.node?.teacherRegisterNoteForStaff,
                    staffRegisterNoteForTeacher: existingUpdate?.registerNote
                        ? existingUpdate.registerNote
                        : lesson?.node?.staffRegisterNoteForTeacher,
                };
            }) ?? []
        );
    }, [lessons, registerUpdates]);

    const [lessonStatuses, setLessonStatuses] = useState(initialiseState());

    useEffect(() => {
        setLessonStatuses(initialiseState());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lessons]);

    const [registerModalLessonInfo, setRegisterModalLessonInfo] = useState<
        RegisterLessonInfo | undefined
    >(undefined);

    const lessonAttendances = useMemo(() => {
        return lessons.map((item, index) => {
            return {
                data: (
                    <LessonStatus
                        borderBottomColor={TABLE_BORDER_COLOR}
                        borderBottomWidth={TABLE_BORDER_WIDTH}
                        hasRegisterNote={Boolean(
                            lessonStatuses[index]
                                ?.teacherRegisterNoteForStaff ||
                                lessonStatuses[index]
                                    ?.staffRegisterNoteForTeacher,
                        )}
                        height={10}
                        status={lessonStatuses[index]?.status}
                    />
                ),
                tooltipLabel: combineNotes(
                    lessonStatuses[index]?.teacherRegisterNoteForStaff,
                    lessonStatuses[index]?.staffRegisterNoteForTeacher,
                ),
                onPress: () => {
                    if (item?.node && lessonStatuses[index]) {
                        if (lessonStatuses[index].status === "BLANK") {
                            const clonedLessonStatuses = lessonStatuses.slice();
                            (
                                clonedLessonStatuses[index] as Mutable<
                                    (typeof lessonStatuses)[0]
                                >
                            ).status = "PRESENT";
                            setLessonStatuses(clonedLessonStatuses);
                            updateRegisterState(
                                {
                                    lessonId: item.node.id,
                                    status: "PRESENT",
                                },
                                1,
                            );
                        } else {
                            setRegisterModalLessonInfo({
                                id: item.node.id,
                                index: index,
                                status: lessonStatuses[index].status ?? "BLANK",
                                teacherRegisterNoteForStaff:
                                    lessonStatuses[index]
                                        .teacherRegisterNoteForStaff ??
                                    undefined,
                                staffRegisterNoteForTeacher:
                                    lessonStatuses[index]
                                        .staffRegisterNoteForTeacher ??
                                    undefined,
                                pupilName: getFullName(
                                    data.pupil?.user.firstName,
                                    data.pupil?.user.lastName,
                                ),
                                instrument: titleCaseConverter(
                                    data.instrument?.name,
                                ),
                                scheduledTimestamp: item.node.scheduledTimestamp
                                    ? format(
                                          new Date(
                                              item.node.rearrangedTimestamp ??
                                                  item.node.scheduledTimestamp,
                                          ),
                                          "EEE d MMM HH:mm",
                                      )
                                    : undefined,
                            });
                        }
                    }
                },
            };
        }) as TableData;
    }, [
        data.instrument?.name,
        data.pupil?.user.firstName,
        data.pupil?.user.lastName,
        lessonStatuses,
        lessons,
        updateRegisterState,
    ]);

    const tableMinutesTaught = useMemo(() => {
        const totalMinutes = lessons.reduce((output, item, index) => {
            if (
                ![
                    "NO_LESSON",
                    "STOPPED_LESSONS",
                    "LEFT_SCHOOL",
                    "OTHER_TEACHER",
                ].includes(lessonStatuses[index]?.status)
            ) {
                return output + (data.lessonStage?.lessonDuration ?? 0);
            } else {
                return output;
            }
        }, 0);
        const totalMinutesTaught = lessons.reduce((output, item, index) => {
            if (lessonStatuses[index]?.status.includes("PRESENT")) {
                if (
                    lessonStatuses[index]?.status === "PRESENT_DOUBLE" &&
                    item?.node?.status !== "PRESENT_DOUBLE"
                ) {
                    return (
                        output +
                        (item?.node?.lessonDuration ??
                            data.lessonStage?.lessonDuration ??
                            0) *
                            2
                    );
                }
                return (
                    output +
                    (item?.node?.lessonDuration ??
                        data.lessonStage?.lessonDuration ??
                        0)
                );
            } else {
                return output;
            }
        }, 0);
        const percentageProgress =
            totalMinutes > 0 ? 100 * (totalMinutesTaught / totalMinutes) : 0;
        let bgColor = "primary.100";
        let textColor = "primary.600";
        if (percentageProgress === 0) {
            bgColor = "muted.200";
            textColor = "muted.500";
        } else if (percentageProgress < 50) {
            bgColor = "red.300";
            textColor = "red.600";
        } else if (percentageProgress < 90) {
            bgColor = "warning.300";
            textColor = "warning.600";
        } else if (percentageProgress < 100) {
            bgColor = "primary.200";
            textColor = "primary.700";
        }
        return {
            data: [
                {
                    data: `${totalMinutesTaught}/${totalMinutes}`,
                },
            ],
            bgColor: bgColor,
            textColor: textColor,
        };
    }, [data.lessonStage?.lessonDuration, lessonStatuses, lessons]);

    const updateRegisterHandler = useCallback(
        (
            variables: {
                lessonId: string;
                staffRegisterNoteForTeacher?: string | undefined;
                status: string;
                teacherRegisterNoteForStaff?: string | undefined;
            },
            lessonIndex: number,
            progressChange?: number | undefined,
        ) => {
            const clonedLessonStatuses = lessonStatuses.slice();
            (
                clonedLessonStatuses[lessonIndex] as Mutable<
                    (typeof lessonStatuses)[0]
                >
            ).status = variables.status as LessonModelStatus;
            if (variables.teacherRegisterNoteForStaff) {
                (
                    clonedLessonStatuses[lessonIndex] as Mutable<
                        (typeof lessonStatuses)[0]
                    >
                ).teacherRegisterNoteForStaff =
                    variables.teacherRegisterNoteForStaff;
            }
            if (variables.staffRegisterNoteForTeacher) {
                (
                    clonedLessonStatuses[lessonIndex] as Mutable<
                        (typeof lessonStatuses)[0]
                    >
                ).staffRegisterNoteForTeacher =
                    variables.staffRegisterNoteForTeacher;
            }
            (
                clonedLessonStatuses[lessonIndex] as Mutable<
                    (typeof lessonStatuses)[0]
                >
            ).status = variables.status as LessonModelStatus;
            setLessonStatuses(clonedLessonStatuses);
            updateRegisterState?.(variables, progressChange);
        },
        [lessonStatuses, updateRegisterState],
    );

    const renderHeader = useCallback(() => {
        return (
            <HStack justifyContent="space-between">
                <HStack>
                    <Pressable
                        flexDirection="row"
                        onPress={() => {
                            showUpdateBlockDetailsModalHandler({
                                type: "INSTRUMENT",
                                lessonBlockIds: [data.id],
                            });
                        }}>
                        {getInstrumentIcon(data.instrument?.name, {
                            color: "coolGray.800",
                            size: 7,
                        })}
                        <Text fontFamily="Poppins-Regular" fontSize="lg">
                            {`  ${
                                data.instrument?.name
                                    ? titleCaseConverter(data.instrument.name)
                                    : "No Assigned Instrument"
                            }  •  `}
                        </Text>
                    </Pressable>
                    <Pressable
                        flexDirection="row"
                        onPress={() => {
                            if (data.school?.name) {
                                showUpdateBlockDetailsModalHandler({
                                    type: "STAGE",
                                    lessonBlockIds: [data.id],
                                    school: data.school.name,
                                });
                            }
                        }}>
                        {getLessonTypeIcon(data.lessonStage?.lessonType, {
                            color: "coolGray.800",
                        })}
                        <Text fontFamily="Poppins-Regular" fontSize="lg">{`   ${
                            data.lessonStage
                                ? `${
                                      data.lessonStage?.lessonType ===
                                      "INDIVIDUAL"
                                          ? "1-on-1"
                                          : data.lessonStage?.lessonType ===
                                              "PAIRED"
                                            ? "Paired"
                                            : "Group"
                                  }  •  ${data.lessonStage.lessonDuration} min${
                                      data.lessonStage.stage !== NO_STAGE
                                          ? `  •  Stage ${data.lessonStage.stage}`
                                          : "  •  Any Stage"
                                  }`
                                : "No Assigned Stage"
                        }`}</Text>
                    </Pressable>
                </HStack>
                <Button
                    _hover={{ bg: "transparent", opacity: 0.8 }}
                    _pressed={{ bg: "transparent", opacity: 0.7 }}
                    colorScheme="muted"
                    leftIcon={<OptionsIcon size="6" />}
                    onPress={() => openLessonBlockOptionsHandler(data.id)}
                    variant="ghost"
                />
            </HStack>
        );
    }, [
        data.id,
        data.instrument?.name,
        data.lessonStage,
        data.school?.name,
        openLessonBlockOptionsHandler,
        showUpdateBlockDetailsModalHandler,
    ]);

    const { scale, width: windowWidth } = useWindowDimensions();

    return (
        <VStack
            mt="2"
            space="4"
            width={
                getScaledWindowDimension(windowWidth, scale) -
                (sideBarWidth > 300
                    ? sideBarWidth
                    : // hacky adjustment for collapsed side bar
                      sideBarWidth + 36) -
                49
            }
            {...props}>
            {renderHeader()}
            <HStack width="100%">
                <Box
                    bg={data.pupil ? "primary.50" : "muted.200"}
                    borderBottomWidth={TABLE_BORDER_WIDTH}
                    borderColor={TABLE_BORDER_COLOR}
                    borderLeftRadius={TABLE_BORDER_RADIUS}
                    borderLeftWidth={TABLE_BORDER_WIDTH}
                    borderTopWidth={TABLE_BORDER_WIDTH}
                    flex={0.25}>
                    {tableTeacherName ? (
                        <Row
                            cellProps={{
                                px: 2,
                                hideTopBorder: true,
                                hideOuterSideBorder: true,
                                pressableTextProps: {
                                    fontFamily: "Poppins-Regular",
                                },
                                placeholderTextProps: { color: "muted.400" },
                                textProps: {
                                    fontSize: "md",
                                    textAlign: "center",
                                    isTruncated: false,
                                    fontFamily: "Poppins-Light",
                                },
                            }}
                            data={tableTeacherName}
                            flexArray={[1]}
                            rowHeight={24}
                            rowIndex={0}
                            tableBorderColor={TABLE_BORDER_COLOR}
                            tableBorderWidth={TABLE_BORDER_WIDTH}
                        />
                    ) : null}
                </Box>
                {lessons.length > 0 ? (
                    <VStack flex={1}>
                        <Box
                            bg={data.pupil ? "primary.50" : "muted.200"}
                            borderBottomWidth={0}
                            borderColor={TABLE_BORDER_COLOR}
                            borderLeftWidth={TABLE_BORDER_WIDTH}
                            borderTopWidth={TABLE_BORDER_WIDTH}
                            flex={1}>
                            <Row
                                cellProps={{
                                    px: 2,
                                    py: 1,
                                    hideTopBorder: true,
                                    hideOuterSideBorder: true,
                                    pressableTextProps: {
                                        fontFamily: "Poppins-Regular",
                                    },
                                    textProps: {
                                        fontSize: "md",
                                        textAlign: "center",
                                        isTruncated: false,
                                        fontFamily: "Poppins-Regular",
                                    },
                                }}
                                data={tableHeaders}
                                flexArray={flexArray}
                                rowIndex={0}
                                tableBorderColor={TABLE_BORDER_COLOR}
                                tableBorderWidth={TABLE_BORDER_WIDTH}
                            />
                        </Box>
                        <Box
                            borderBottomWidth={TABLE_BORDER_WIDTH}
                            borderColor={TABLE_BORDER_COLOR}
                            borderLeftWidth={TABLE_BORDER_WIDTH}
                            borderTopWidth={0}
                            flex={1}
                            overflow="hidden">
                            <Row
                                cellProps={{
                                    p: 0,
                                    hideTopBorder: true,
                                    hideOuterSideBorder: true,
                                }}
                                data={lessonAttendances}
                                flexArray={flexArray}
                                rowHeight={10}
                                rowIndex={1}
                                tableBorderColor={TABLE_BORDER_COLOR}
                                tableBorderWidth={TABLE_BORDER_WIDTH}
                            />
                        </Box>
                    </VStack>
                ) : (
                    <Box
                        borderBottomWidth={TABLE_BORDER_WIDTH}
                        borderColor={TABLE_BORDER_COLOR}
                        borderLeftWidth={TABLE_BORDER_WIDTH}
                        borderTopWidth={TABLE_BORDER_WIDTH}
                        flex={1}>
                        <Cell
                            data="No Lessons"
                            height={24}
                            isFinal={true}
                            textProps={{
                                fontSize: "md",
                                textAlign: "center",
                            }}
                        />
                    </Box>
                )}
                <VStack>
                    <Box
                        bg={data.pupil ? "primary.50" : "muted.200"}
                        borderBottomWidth={0}
                        borderColor={TABLE_BORDER_COLOR}
                        borderTopRightRadius={TABLE_BORDER_RADIUS}
                        borderWidth={TABLE_BORDER_WIDTH}
                        flex={1}>
                        <Row
                            cellProps={{
                                px: 2,
                                py: 4,
                                hideTopBorder: true,
                                hideOuterSideBorder: true,
                                textProps: {
                                    fontSize: "md",
                                    textAlign: "center",
                                    isTruncated: false,
                                    fontFamily: "Poppins-Regular",
                                },
                            }}
                            data={[{ data: "Minutes Taught" }]}
                            flexArray={[1]}
                            rowIndex={0}
                            tableBorderColor={TABLE_BORDER_COLOR}
                            tableBorderWidth={TABLE_BORDER_WIDTH}
                        />
                    </Box>
                    <Box
                        bg={
                            data.pupil
                                ? tableMinutesTaught.bgColor
                                : "muted.200"
                        }
                        borderBottomRightRadius={TABLE_BORDER_RADIUS}
                        borderColor={TABLE_BORDER_COLOR}
                        borderTopWidth={0}
                        borderWidth={TABLE_BORDER_WIDTH}
                        flex={1}
                        overflow="hidden">
                        <Row
                            cellProps={{
                                p: 0,
                                hideTopBorder: true,
                                hideOuterSideBorder: true,
                                textProps: {
                                    fontSize: "md",
                                    textAlign: "center",
                                    isTruncated: false,
                                    fontFamily: "Poppins-Regular",
                                    color: data.pupil
                                        ? tableMinutesTaught.textColor
                                        : "muted.400",
                                },
                            }}
                            data={tableMinutesTaught.data}
                            flexArray={[1]}
                            rowHeight={10}
                            rowIndex={1}
                            tableBorderColor={TABLE_BORDER_COLOR}
                            tableBorderWidth={TABLE_BORDER_WIDTH}
                        />
                    </Box>
                </VStack>
            </HStack>
            <ManageRegisterLessonModal
                hideModal={() => setRegisterModalLessonInfo(undefined)}
                lessonInfo={registerModalLessonInfo}
                showModal={typeof registerModalLessonInfo !== "undefined"}
                updateRegister={updateRegisterHandler}
                userIsTeacher={false}
            />
        </VStack>
    );
};

export default LessonBlockRegister;
