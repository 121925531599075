import { graphql } from "react-relay";

export const load_invoices = graphql`
    query LoadInvoicesQuery(
        $orderBy: String
        $searchTerm: String
        $startingYear: Int!
        $block: Int!
        $status: String
        $school: String
        $first: Int!
        $after: String
    ) {
        ...LoadInvoices_query_invoices
            @arguments(
                orderBy: $orderBy
                searchTerm: $searchTerm
                startingYear: $startingYear
                block: $block
                status: $status
                school: $school
                first: $first
                after: $after
            )
        ...InvoicingHeadlineDataFragment_query
            @arguments(startingYear: $startingYear, block: $block)
        me {
            profile {
                profileGroup {
                    id
                    generateDraftsProgress
                    sendDraftsProgress
                    sendRemindersProgress
                    matchPaymentsProgress
                }
            }
        }
    }
`;

export const load_invoices_pagination = graphql`
    fragment LoadInvoices_query_invoices on Query
    @argumentDefinitions(
        orderBy: { type: "String" }
        searchTerm: { type: "String" }
        startingYear: { type: "Int!" }
        block: { type: "Int!" }
        status: { type: "String" }
        school: { type: "String" }
        first: { type: "Int!" }
        after: { type: "String" }
    )
    @refetchable(queryName: "LoadInvoicesPaginationQuery") {
        invoices(
            orderBy: $orderBy
            searchTerm: $searchTerm
            startingYear: $startingYear
            block: $block
            status: $status
            school: $school
            first: $first
            after: $after
        ) @connection(key: "LoadInvoices_query_invoices") {
            edges {
                node {
                    id
                    status
                    totalAmount
                    isUpdate
                    assignedTo {
                        id
                        accountNumber
                        phoneNumber
                        user {
                            id
                            firstName
                            lastName
                            email
                        }
                        children {
                            edges {
                                node {
                                    id
                                    user {
                                        firstName
                                        lastName
                                    }
                                }
                            }
                        }
                    }
                    initialSentTimestamp
                    reminder1SentTimestamp
                    reminder2SentTimestamp
                    reminder3SentTimestamp
                }
            }
        }
    }
`;
