import React from "react";
import type { ReactElement } from "react";

import type {
    CompositeNavigationProp,
    RouteProp,
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import type { StackNavigationProp } from "@react-navigation/stack";

import PayratesScreen, {
    screenOptions as payratesScreenOptions,
} from "../screens/payroll/PayratesScreen";
import PayrollHubScreen, {
    screenOptions as payrollHubScreenOptions,
} from "../screens/payroll/PayrollHubScreen";
import PayslipScreen, {
    screenOptions as payrollScreenOptions,
} from "../screens/payroll/PayslipScreen";

import type { HubTabNavigatorProps } from "./HubNavigator";
import defaultNavOptions from "./utils/DefaultNavigationOptions";
import type { PayslipParams } from "./utils/ScreenParams";

export type PayrollNavigatorParamList = {
    Payrates: undefined;
    PayrollHub?: {
        month: number;
        orderBy?: string;
        searchTerm?: string;
        year: number;
    };
    Payslip: PayslipParams;
};

type NavigatorProps<T extends keyof PayrollNavigatorParamList> =
    StackNavigationProp<PayrollNavigatorParamList, T>;

export type PayrollStackNavigatorProps<
    T extends keyof PayrollNavigatorParamList,
> = CompositeNavigationProp<
    NavigatorProps<T>,
    HubTabNavigatorProps<"PayrollHubTab">
>;

export type PayrollStackRouteProps<T extends keyof PayrollNavigatorParamList> =
    RouteProp<PayrollNavigatorParamList, T>;

const PayrollStackNavigator = createStackNavigator<PayrollNavigatorParamList>();

const PayrollNavigator = (): ReactElement => {
    return (
        <PayrollStackNavigator.Navigator screenOptions={defaultNavOptions}>
            <PayrollStackNavigator.Screen
                component={PayrollHubScreen}
                name="PayrollHub"
                options={payrollHubScreenOptions}
            />
            <PayrollStackNavigator.Screen
                component={PayslipScreen}
                name="Payslip"
                options={payrollScreenOptions}
            />
            <PayrollStackNavigator.Screen
                component={PayratesScreen}
                name="Payrates"
                options={payratesScreenOptions}
            />
        </PayrollStackNavigator.Navigator>
    );
};

export default PayrollNavigator;
