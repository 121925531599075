/**
 * @generated SignedSource<<6233512efea1b973f66bfb837a416ea5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InvoiceModelStatus = "CANCELLED" | "DRAFT" | "OVERPAID" | "PAID" | "PENDING" | "REFUND" | "SENT" | "%future added value";
export type LineItemModelItemType = "ACCOUNT_OTHER_FEE_OR_DISCOUNT" | "BLOCK_OTHER_FEE_OR_DISCOUNT" | "EXAM_FEE" | "INSTRUMENT_DEPOSIT" | "LESSONS" | "MISSED_LESSON_DISCOUNT" | "%future added value";
export type UpdateLineItemAmountInput = {
  amount: number;
  clientMutationId?: string | null;
  lineItemId: string;
};
export type UpdateLineItemAmountMutation$variables = {
  input: UpdateLineItemAmountInput;
};
export type UpdateLineItemAmountMutation$data = {
  readonly updateLineItemAmount: {
    readonly accountFeeOrCredit: {
      readonly amount: number | null;
      readonly id: string;
    } | null;
    readonly errors: any | null;
    readonly invoice: {
      readonly id: string;
      readonly lastModified: string;
      readonly status: InvoiceModelStatus;
      readonly totalAmount: number | null;
    } | null;
    readonly lineItem: {
      readonly id: string;
      readonly instrumentDescription: string | null;
      readonly itemType: LineItemModelItemType;
      readonly pupil: {
        readonly id: string;
      } | null;
      readonly pupilDescription: string | null;
      readonly quantity: number | null;
      readonly timePeriodDescription: string | null;
      readonly typeDescription: string | null;
      readonly unitAmount: number | null;
    } | null;
    readonly success: boolean | null;
  } | null;
};
export type UpdateLineItemAmountMutation = {
  response: UpdateLineItemAmountMutation$data;
  variables: UpdateLineItemAmountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateLineItemAmountPayload",
    "kind": "LinkedField",
    "name": "updateLineItemAmount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LineItemNode",
        "kind": "LinkedField",
        "name": "lineItem",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "itemType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "quantity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unitAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timePeriodDescription",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pupilDescription",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "instrumentDescription",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "typeDescription",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "pupil",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceNode",
        "kind": "LinkedField",
        "name": "invoice",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastModified",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountFeeOrCreditNode",
        "kind": "LinkedField",
        "name": "accountFeeOrCredit",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateLineItemAmountMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateLineItemAmountMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "61ca950f00f21456b20e598af8725511",
    "id": null,
    "metadata": {},
    "name": "UpdateLineItemAmountMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateLineItemAmountMutation(\n  $input: UpdateLineItemAmountInput!\n) {\n  updateLineItemAmount(input: $input) {\n    success\n    errors\n    lineItem {\n      id\n      itemType\n      quantity\n      unitAmount\n      timePeriodDescription\n      pupilDescription\n      instrumentDescription\n      typeDescription\n      pupil {\n        id\n      }\n    }\n    invoice {\n      id\n      lastModified\n      totalAmount\n      status\n    }\n    accountFeeOrCredit {\n      id\n      amount\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4c39f024ddaa29ce5c6e9718e2c304cc";

export default node;
