/**
 * @generated SignedSource<<a8f129bb1554c1b28b8283975ca906c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteBlacklistedDatesInput = {
  blacklistedDateIds: ReadonlyArray<string | null>;
  clientMutationId?: string | null;
};
export type DeleteBlacklistedDatesMutation$variables = {
  connections: ReadonlyArray<string>;
  input: DeleteBlacklistedDatesInput;
};
export type DeleteBlacklistedDatesMutation$data = {
  readonly deleteBlacklistedDates: {
    readonly deletedBlacklistedDateIds: ReadonlyArray<string | null> | null;
    readonly errors: any | null;
    readonly success: boolean | null;
  } | null;
};
export type DeleteBlacklistedDatesMutation = {
  response: DeleteBlacklistedDatesMutation$data;
  variables: DeleteBlacklistedDatesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedBlacklistedDateIds",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteBlacklistedDatesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteBlacklistedDatesPayload",
        "kind": "LinkedField",
        "name": "deleteBlacklistedDates",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteBlacklistedDatesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteBlacklistedDatesPayload",
        "kind": "LinkedField",
        "name": "deleteBlacklistedDates",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteEdge",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedBlacklistedDateIds",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c89f33dccc5a67c2e3a8ac8a6b25def0",
    "id": null,
    "metadata": {},
    "name": "DeleteBlacklistedDatesMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteBlacklistedDatesMutation(\n  $input: DeleteBlacklistedDatesInput!\n) {\n  deleteBlacklistedDates(input: $input) {\n    success\n    errors\n    deletedBlacklistedDateIds\n  }\n}\n"
  }
};
})();

(node as any).hash = "5224e2ad0bbcbc8efa9d6ad09b94349b";

export default node;
