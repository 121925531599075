/**
 * @generated SignedSource<<61888daa838e315051de67611183d9f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SendPasswordResetEmailInput = {
  clientMutationId?: string | null;
  email: string;
  redirectUrl: string;
};
export type SendPasswordResetEmailMutation$variables = {
  input: SendPasswordResetEmailInput;
};
export type SendPasswordResetEmailMutation$data = {
  readonly sendPasswordResetEmail: {
    readonly errors: any | null;
    readonly success: boolean | null;
  } | null;
};
export type SendPasswordResetEmailMutation = {
  response: SendPasswordResetEmailMutation$data;
  variables: SendPasswordResetEmailMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SendPasswordResetEmailPayload",
    "kind": "LinkedField",
    "name": "sendPasswordResetEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendPasswordResetEmailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendPasswordResetEmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "330a6efa63bf8f6611361696928de3ac",
    "id": null,
    "metadata": {},
    "name": "SendPasswordResetEmailMutation",
    "operationKind": "mutation",
    "text": "mutation SendPasswordResetEmailMutation(\n  $input: SendPasswordResetEmailInput!\n) {\n  sendPasswordResetEmail(input: $input) {\n    success\n    errors\n  }\n}\n"
  }
};
})();

(node as any).hash = "de7b5d2d6b83ba2d6bc775e0b0606965";

export default node;
