/**
 * @generated SignedSource<<1d8a0f103035d9c8d77776e7834fa483>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProfileModelStatus = "ACTIVE" | "ARCHIVED" | "NEW_ENROLLMENT" | "WAITING_LIST" | "%future added value";
export type UpdateAccountStatusInput = {
  clientMutationId?: string | null;
  profileId: string;
  status: string;
};
export type UpdateAccountStatusMutation$variables = {
  connections: ReadonlyArray<string>;
  input: UpdateAccountStatusInput;
};
export type UpdateAccountStatusMutation$data = {
  readonly updateAccountStatus: {
    readonly deletedProfileId: string | null;
    readonly errors: any | null;
    readonly profiles: ReadonlyArray<{
      readonly id: string;
      readonly status: ProfileModelStatus;
    } | null> | null;
    readonly success: boolean | null;
  } | null;
};
export type UpdateAccountStatusMutation = {
  response: UpdateAccountStatusMutation$data;
  variables: UpdateAccountStatusMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedProfileId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ProfileNode",
  "kind": "LinkedField",
  "name": "profiles",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAccountStatusMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UpdateAccountStatusPayload",
        "kind": "LinkedField",
        "name": "updateAccountStatus",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateAccountStatusMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UpdateAccountStatusPayload",
        "kind": "LinkedField",
        "name": "updateAccountStatus",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteEdge",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedProfileId",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "645697e38c7b4afc8ba1fb159568fbd7",
    "id": null,
    "metadata": {},
    "name": "UpdateAccountStatusMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAccountStatusMutation(\n  $input: UpdateAccountStatusInput!\n) {\n  updateAccountStatus(input: $input) {\n    success\n    errors\n    deletedProfileId\n    profiles {\n      id\n      status\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c32b2e0f5eb52fef95ee111f91282cde";

export default node;
