import { graphql } from "react-relay";

export const load_accounts_for_modal = graphql`
    query LoadAccountsForModalQuery(
        $orderBy: String
        $searchTerm: String
        $accountType: String!
        $school: String
        $first: Int!
        $after: String
    ) {
        ...LoadAccountsForModal_query_profiles
            @arguments(
                orderBy: $orderBy
                searchTerm: $searchTerm
                accountType: $accountType
                school: $school
                first: $first
                after: $after
            )
    }
`;

export const load_accounts_for_modal_pagination = graphql`
    fragment LoadAccountsForModal_query_profiles on Query
    @argumentDefinitions(
        orderBy: { type: "String" }
        searchTerm: { type: "String" }
        accountType: { type: "String!" }
        school: { type: "String" }
        first: { type: "Int!" }
        after: { type: "String" }
    )
    @refetchable(queryName: "LoadAccountsForModalPaginationQuery") {
        profiles(
            orderBy: $orderBy
            searchTerm: $searchTerm
            accountType: $accountType
            school: $school
            first: $first
            after: $after
        ) @connection(key: "LoadAccountsForModal_query_profiles") {
            edges {
                node {
                    id
                    accountNumber
                    user {
                        id
                        email
                        firstName
                        lastName
                    }
                    schoolYear
                    school {
                        id
                        name
                    }
                }
            }
        }
    }
`;
