/**
 * @generated SignedSource<<941b54058df065bf2ded6e738a0cbf5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InvoicingHeadlineDataFragment_query$data = {
  readonly me: {
    readonly profile: {
      readonly id: string;
      readonly lessonBlocksNotInvoicedFor: ReadonlyArray<{
        readonly id: string;
        readonly pupil: {
          readonly id: string;
          readonly schoolYear: number | null;
          readonly user: {
            readonly firstName: string;
            readonly id: string;
            readonly lastName: string;
          };
        } | null;
        readonly school: {
          readonly name: string;
        } | null;
      } | null> | null;
      readonly percentLessonBlocksInvoicedFor: number | null;
      readonly totalAmountInvoiced: number | null;
      readonly totalAmountPaid: number | null;
      readonly totalInvoices: number | null;
      readonly totalInvoicesSent: number | null;
      readonly totalInvoicesUnpaid: number | null;
    } | null;
  } | null;
  readonly " $fragmentType": "InvoicingHeadlineDataFragment_query";
};
export type InvoicingHeadlineDataFragment_query$key = {
  readonly " $data"?: InvoicingHeadlineDataFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"InvoicingHeadlineDataFragment_query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Variable",
    "name": "block",
    "variableName": "block"
  },
  {
    "kind": "Variable",
    "name": "startingYear",
    "variableName": "startingYear"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "block"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startingYear"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./InvoicingHeadlineDataRefreshQuery.graphql')
    }
  },
  "name": "InvoicingHeadlineDataFragment_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DjangoUserNode",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileNode",
          "kind": "LinkedField",
          "name": "profile",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": (v1/*: any*/),
              "kind": "ScalarField",
              "name": "totalInvoices",
              "storageKey": null
            },
            {
              "alias": null,
              "args": (v1/*: any*/),
              "kind": "ScalarField",
              "name": "totalAmountInvoiced",
              "storageKey": null
            },
            {
              "alias": null,
              "args": (v1/*: any*/),
              "kind": "ScalarField",
              "name": "totalInvoicesSent",
              "storageKey": null
            },
            {
              "alias": null,
              "args": (v1/*: any*/),
              "kind": "ScalarField",
              "name": "totalAmountPaid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": (v1/*: any*/),
              "kind": "ScalarField",
              "name": "totalInvoicesUnpaid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": (v1/*: any*/),
              "kind": "ScalarField",
              "name": "percentLessonBlocksInvoicedFor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": (v1/*: any*/),
              "concreteType": "LessonBlockNode",
              "kind": "LinkedField",
              "name": "lessonBlocksNotInvoicedFor",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SchoolNode",
                  "kind": "LinkedField",
                  "name": "school",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProfileNode",
                  "kind": "LinkedField",
                  "name": "pupil",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "schoolYear",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "DjangoUserNode",
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "firstName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "lastName",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "c5b61569f91243b6d16c91b92e506c7c";

export default node;
