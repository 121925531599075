/**
 * @generated SignedSource<<48fbdcc6f29a3f9037413c97bed8bbbb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LessonStageModelLessonType = "GROUP" | "INDIVIDUAL" | "PAIRED" | "%future added value";
export type EditLessonStageInput = {
  clientMutationId?: string | null;
  costPerLesson?: number | null;
  lessonDuration?: number | null;
  lessonStageId: string;
  lessonType?: string | null;
};
export type EditLessonStageMutation$variables = {
  input: EditLessonStageInput;
};
export type EditLessonStageMutation$data = {
  readonly editLessonStage: {
    readonly errors: any | null;
    readonly lessonStage: {
      readonly costPerLesson: number | null;
      readonly id: string;
      readonly lessonDuration: number | null;
      readonly lessonType: LessonStageModelLessonType;
      readonly stage: number | null;
    } | null;
    readonly success: boolean | null;
  } | null;
};
export type EditLessonStageMutation = {
  response: EditLessonStageMutation$data;
  variables: EditLessonStageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EditLessonStagePayload",
    "kind": "LinkedField",
    "name": "editLessonStage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LessonStageNode",
        "kind": "LinkedField",
        "name": "lessonStage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "stage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lessonDuration",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lessonType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "costPerLesson",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditLessonStageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditLessonStageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3dcb5eba3fa8203fae2feffe2a4b0487",
    "id": null,
    "metadata": {},
    "name": "EditLessonStageMutation",
    "operationKind": "mutation",
    "text": "mutation EditLessonStageMutation(\n  $input: EditLessonStageInput!\n) {\n  editLessonStage(input: $input) {\n    success\n    errors\n    lessonStage {\n      id\n      stage\n      lessonDuration\n      lessonType\n      costPerLesson\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "76fd5fe5e80f7baaa934f35c18f998e4";

export default node;
