import React, {
    useRef,
    useCallback,
    useMemo,
    useEffect,
    useReducer,
} from "react";
import type { ReactElement } from "react";

import { format } from "date-fns";
import { Modal, VStack, HStack, useToast, Pressable } from "native-base";
import { DatePickerModal } from "react-native-paper-dates";
import { useMutation } from "react-relay";

import ButtonDebounced from "pianofunclub-shared/components/Buttons/ButtonDebounced";
import TextInput from "pianofunclub-shared/components/Inputs/TextInput";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";

import type { GenerateEstimatedPayoutTotalMutation } from "pianofunclub-shared/relay/graphql/payslips/__generated__/GenerateEstimatedPayoutTotalMutation.graphql";
import type { GenerateEstimatedPayoutTotalMutation$data } from "pianofunclub-shared/relay/graphql/payslips/__generated__/GenerateEstimatedPayoutTotalMutation.graphql";
import type { GeneratePayslipsMutation$data } from "pianofunclub-shared/relay/graphql/payslips/__generated__/GeneratePayslipsMutation.graphql";
import type { GeneratePayslipsMutation } from "pianofunclub-shared/relay/graphql/payslips/__generated__/GeneratePayslipsMutation.graphql";
import { generate_estimated_payout_total } from "pianofunclub-shared/relay/graphql/payslips/GenerateEstimatedPayoutTotal";
import { generate_payslips } from "pianofunclub-shared/relay/graphql/payslips/GeneratePayslips";

import {
    ensureDateType,
    ensureDateTypeIfDefined,
} from "pianofunclub-shared/utils/helpers";
import { createFormReducer } from "pianofunclub-shared/utils/reducers";

export enum Mode {
    GeneratePayslips,
    GenerateEstimatedPayroll,
}

interface Props {
    defaultMonth?: number;
    defaultYear?: number;
    hideModal: () => void;
    mode: Mode;
    refreshHandler?: (orderBy?: string) => void;
    showModal: boolean;
    teacherIds?: string[];
}

type ReducerValues = {
    dateFrom?: string;
    dateFromPickerModalIsOpen?: boolean;
    dateTo?: string;
    dateToPickerModalIsOpen?: boolean;
    lessonDuration?: number;
    refreshHandler?: () => void;
};

type ReducerTypes =
    | boolean
    | number
    | string
    | undefined
    | ((orderBy: string) => void);

const CreatePayslipModal = (props: Props): ReactElement => {
    const { defaultMonth, defaultYear, hideModal, showModal } = props;

    const toast = useToast();

    const [commitGeneratePayslips, generatePayslipsInFlight] =
        useMutation<GeneratePayslipsMutation>(generate_payslips);

    const [
        commitGenerateEstimatedPayoutTotal,
        generateEstimatedPayoutTotalInFlight,
    ] = useMutation<GenerateEstimatedPayoutTotalMutation>(
        generate_estimated_payout_total,
    );

    const initialState = useMemo(() => {
        return {
            inputValues: {
                stage: undefined,
                lessonType: undefined,
                lessonDuration: undefined,
                costPerLesson: undefined,
                dateFrom: undefined,
                dateTo: undefined,
                datePickerModalIsOpen: undefined,
                refreshHandler: undefined,
            },
            inputValidities: {
                stage: true,
                lessonType: false,
                lessonDuration: false,
                costPerLesson: false,
            },
            formIsValid: false,
            formIsEdited: false,
        };
    }, []);

    const reducer = createFormReducer<ReducerValues, ReducerTypes>(
        initialState,
    );
    const [state, dispatchState] = useReducer(reducer, initialState);

    const createButtonRef = useRef<{
        focus: () => void;
    }>();

    useEffect(() => {
        dispatchState({
            input: "refreshHandler",
            value: props.refreshHandler,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.inputValues.refreshHandler]);

    useEffect(() => {
        if (showModal) {
            const defaultDateFrom = format(
                new Date(
                    defaultYear ?? new Date().getFullYear(),
                    defaultMonth ?? 0,
                ),
                "yyyy-MM-dd",
            );
            const defaultDateTo = format(
                new Date(
                    defaultYear ?? new Date().getFullYear(),
                    (defaultMonth ?? 0) + 1,
                    0,
                ),
                "yyyy-MM-dd",
            );

            dispatchState({
                input: "dateFrom",
                value: defaultDateFrom,
            });
            dispatchState({
                input: "dateTo",
                value: defaultDateTo,
            });
        }
    }, [defaultMonth, defaultYear, showModal]);

    const generatePayslips = useCallback(
        (
            variables: {
                dateFrom: string;
                dateTo: string;
                teacherIds: string[];
            },
            onComplete?: () => void,
        ) => {
            const generatePayslipsConfig = {
                variables: {
                    input: {
                        teacherIds: variables.teacherIds,
                        dateFrom: variables.dateFrom,
                        dateTo: variables.dateTo,
                    },
                },
                onCompleted: (response: GeneratePayslipsMutation$data) => {
                    if (response?.generatePayslips?.success) {
                        // refresh on complete
                        state.inputValues.refreshHandler?.();

                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={
                                        "Successfully generated teacher payouts"
                                    }
                                    toast={toast}
                                />
                            ),
                        });
                        hideModal();
                        onComplete?.();
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        response?.generatePayslips?.errors[0]
                                            .message
                                    }
                                    id={id}
                                    status="error"
                                    title={
                                        "Failed to generate payslip, please contact a member of the team"
                                    }
                                    toast={toast}
                                />
                            ),
                            duration: null,
                        });
                    }
                },
            };

            commitGeneratePayslips(generatePayslipsConfig);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [commitGeneratePayslips, state],
    );

    const generateEstimatedPayoutTotal = useCallback(
        (
            variables: {
                dateFrom: string;
                dateTo: string;
            },
            onComplete?: () => void,
        ) => {
            const generateEstimatedPayoutTotalConfig = {
                variables: {
                    input: {
                        dateFrom: variables.dateFrom,
                        dateTo: variables.dateTo,
                    },
                },
                onCompleted: (
                    response: GenerateEstimatedPayoutTotalMutation$data,
                ) => {
                    if (response?.generateEstimatedPayoutTotal?.success) {
                        // refresh on complete
                        state.inputValues.refreshHandler?.();
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={
                                        "Successfully generated estimated payouts"
                                    }
                                    toast={toast}
                                />
                            ),
                        });
                        hideModal();
                        onComplete?.();
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        response?.generateEstimatedPayoutTotal
                                            ?.errors[0].message
                                    }
                                    id={id}
                                    status="error"
                                    title={"Failed to generate statistics"}
                                    toast={toast}
                                />
                            ),
                            duration: null,
                        });
                    }
                },
            };

            commitGenerateEstimatedPayoutTotal(
                generateEstimatedPayoutTotalConfig,
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [commitGenerateEstimatedPayoutTotal, state],
    );

    const renderDatePickerModal = useMemo(() => {
        return (
            <DatePickerModal
                animationType="fade"
                date={
                    (state.inputValues.dateFromPickerModalIsOpen
                        ? ensureDateTypeIfDefined(state.inputValues.dateFrom)
                        : ensureDateTypeIfDefined(state.inputValues.dateTo)) ??
                    new Date()
                }
                endYear={new Date().getFullYear() + 1}
                label="Select Date"
                locale="en"
                mode="single"
                onConfirm={(params) => {
                    if (params.date) {
                        if (state.inputValues.dateFromPickerModalIsOpen) {
                            dispatchState({
                                input: "dateFrom",
                                value: format(params.date, "yyyy-MM-dd"),
                            });
                            dispatchState({
                                input: "dateFromPickerModalIsOpen",
                                value: false,
                            });
                        } else if (state.inputValues.dateToPickerModalIsOpen) {
                            dispatchState({
                                input: "dateTo",
                                value: format(params.date, "yyyy-MM-dd"),
                            });
                            dispatchState({
                                input: "dateToPickerModalIsOpen",
                                value: false,
                            });
                        }
                    }
                }}
                onDismiss={() => {
                    dispatchState({
                        input: "dateFromPickerModalIsOpen",
                        value: false,
                    });
                    dispatchState({
                        input: "dateToPickerModalIsOpen",
                        value: false,
                    });
                }}
                saveLabel="Select"
                startYear={2022}
                uppercase={false}
                visible={Boolean(
                    state.inputValues.dateFromPickerModalIsOpen ||
                        state.inputValues.dateToPickerModalIsOpen,
                )}
            />
        );
    }, [
        state.inputValues.dateFrom,
        state.inputValues.dateFromPickerModalIsOpen,
        state.inputValues.dateTo,
        state.inputValues.dateToPickerModalIsOpen,
    ]);

    return (
        <>
            <Modal
                isOpen={showModal}
                onClose={() => {
                    hideModal();
                }}
                size="xl">
                <Modal.Content>
                    <Modal.CloseButton
                        _hover={{ bg: "transparent", opacity: 0.7 }}
                        _pressed={{ bg: "transparent", opacity: 0.7 }}
                    />
                    <Modal.Header>
                        {props.mode === Mode.GeneratePayslips
                            ? "Generate Teacher Payments"
                            : "Generate Estimated Teacher Payments"}
                    </Modal.Header>
                    <Modal.Body alignItems="center">
                        <VStack
                            alignItems="center"
                            justifyContent="center"
                            pb="6"
                            pt="-2"
                            space="8">
                            <VStack
                                alignItems="center"
                                justifyContent="center"
                                space="2">
                                <HStack alignItems="center" space="2">
                                    <Pressable
                                        onPress={() =>
                                            dispatchState({
                                                input: "dateFromPickerModalIsOpen",
                                                value: true,
                                            })
                                        }>
                                        <TextInput
                                            centerLabel
                                            id="dateFrom"
                                            initiallyValid
                                            label="Date From"
                                            labelStyle={{
                                                color: "surface.800",
                                            }}
                                            pointerEvents="none"
                                            size="lg"
                                            textAlign={"center"}
                                            value={
                                                state.inputValues.dateFrom
                                                    ? format(
                                                          ensureDateType(
                                                              state.inputValues
                                                                  .dateFrom,
                                                          ),
                                                          "do MMM yyyy",
                                                      )
                                                    : ""
                                            }
                                        />
                                    </Pressable>
                                </HStack>
                                <HStack alignItems="center" space="2">
                                    <Pressable
                                        onPress={() =>
                                            dispatchState({
                                                input: "dateToPickerModalIsOpen",
                                                value: true,
                                            })
                                        }>
                                        <TextInput
                                            centerLabel
                                            id="dateTo"
                                            initiallyValid
                                            initialValue=""
                                            label="Date To"
                                            labelStyle={{
                                                color: "surface.800",
                                            }}
                                            pointerEvents="none"
                                            size="lg"
                                            textAlign={"center"}
                                            value={
                                                state.inputValues.dateTo
                                                    ? format(
                                                          ensureDateType(
                                                              state.inputValues
                                                                  .dateTo,
                                                          ),
                                                          "do MMM yyyy",
                                                      )
                                                    : ""
                                            }
                                        />
                                    </Pressable>
                                </HStack>
                            </VStack>
                            <ButtonDebounced
                                ref={createButtonRef}
                                _text={{ fontSize: "xl" }}
                                height="50px"
                                mb="4"
                                mt="8"
                                px="8"
                                {...(generatePayslipsInFlight
                                    ? {
                                          isLoading: true,
                                          isLoadingText: "Generating",
                                      }
                                    : generateEstimatedPayoutTotalInFlight
                                      ? {
                                            isLoading: true,
                                            isLoadingText: "Generating",
                                        }
                                      : {})}
                                onPress={() => {
                                    switch (props.mode) {
                                        case Mode.GeneratePayslips:
                                            generatePayslips({
                                                teacherIds:
                                                    props.teacherIds ?? [],
                                                dateFrom:
                                                    state.inputValues
                                                        .dateFrom ?? "",
                                                dateTo:
                                                    state.inputValues.dateTo ??
                                                    "",
                                            });
                                            break;
                                        case Mode.GenerateEstimatedPayroll:
                                            generateEstimatedPayoutTotal({
                                                dateFrom:
                                                    state.inputValues
                                                        .dateFrom ?? "",
                                                dateTo:
                                                    state.inputValues.dateTo ??
                                                    "",
                                            });
                                            break;
                                    }
                                }}>
                                Generate
                            </ButtonDebounced>
                        </VStack>
                    </Modal.Body>
                </Modal.Content>
            </Modal>
            {renderDatePickerModal}
        </>
    );
};

export default React.memo(CreatePayslipModal);
