/**
 * @generated SignedSource<<64308f1f4311bde19e4e43d452409054>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProfileModelStatus = "ACTIVE" | "ARCHIVED" | "NEW_ENROLLMENT" | "WAITING_LIST" | "%future added value";
export type ArchivePupilsInput = {
  block: number;
  clientMutationId?: string | null;
  pupilIds: ReadonlyArray<string | null>;
  startingYear: number;
};
export type ArchivePupilsMutation$variables = {
  input: ArchivePupilsInput;
};
export type ArchivePupilsMutation$data = {
  readonly archivePupils: {
    readonly archivedParents: ReadonlyArray<{
      readonly id: string;
      readonly status: ProfileModelStatus;
    } | null> | null;
    readonly archivedPupils: ReadonlyArray<{
      readonly id: string;
      readonly status: ProfileModelStatus;
    } | null> | null;
    readonly errors: any | null;
    readonly success: boolean | null;
    readonly vacatedLessonBlocks: ReadonlyArray<{
      readonly id: string;
      readonly pupil: {
        readonly id: string;
      } | null;
    } | null> | null;
  } | null;
};
export type ArchivePupilsMutation = {
  response: ArchivePupilsMutation$data;
  variables: ArchivePupilsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ArchivePupilsPayload",
    "kind": "LinkedField",
    "name": "archivePupils",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProfileNode",
        "kind": "LinkedField",
        "name": "archivedPupils",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProfileNode",
        "kind": "LinkedField",
        "name": "archivedParents",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LessonBlockNode",
        "kind": "LinkedField",
        "name": "vacatedLessonBlocks",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "pupil",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ArchivePupilsMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ArchivePupilsMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "f3bdaf874c8ce3baee563d6ad7ca89a5",
    "id": null,
    "metadata": {},
    "name": "ArchivePupilsMutation",
    "operationKind": "mutation",
    "text": "mutation ArchivePupilsMutation(\n  $input: ArchivePupilsInput!\n) {\n  archivePupils(input: $input) {\n    success\n    errors\n    archivedPupils {\n      id\n      status\n    }\n    archivedParents {\n      id\n      status\n    }\n    vacatedLessonBlocks {\n      id\n      pupil {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6db0a39b8c1a677ad85ec9c3b074f3c5";

export default node;
