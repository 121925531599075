import React, {
    useReducer,
    useEffect,
    useCallback,
    useImperativeHandle,
    useRef,
    forwardRef,
} from "react";
import type { ReactElement, ComponentProps } from "react";

import { Input, Button, FormControl, Tooltip } from "native-base";
import type { Text } from "native-base";

import { createInputReducer } from "../../utils/reducers";
import { ClearIcon, SendIcon } from "../Other/Icons";

type InputProps = ComponentProps<typeof Input>;
type TooltipProps = Omit<
    ComponentProps<typeof Tooltip>,
    "label" | "isOpen" | "children"
>;
type TextProps = ComponentProps<typeof Text>;

interface Props extends InputProps {
    centerLabel?: boolean;
    clearButton?: boolean;
    email?: boolean;
    errorMessage?: string;
    errorMessageOffset?: number;
    floatingErrorMessage?: string;
    floatingErrorOffset?: number;
    floatingErrorProps?: TooltipProps;
    id: string;
    initialValue?: string;
    initiallyValid?: boolean;
    intOnly?: boolean;
    invalidIndicator?: boolean;
    isInvalid?: boolean;
    // override invalid state from parent
    label?: string | null;
    labelStyle?: TextProps;
    max?: number;
    min?: number;
    minLength?: number;
    mustContainNumbers?: boolean;
    numOnly?: boolean;
    onClear?: (
        inputIdentifier?: string | number,
        inputValue?: string,
        isValid?: boolean,
    ) => void;
    onFinishEditing?: (
        inputIdentifier?: string | number,
        inputValue?: string,
        isValid?: boolean,
    ) => void | string;
    onInputChange?: (
        inputIdentifier?: string | number,
        inputValue?: string,
        isValid?: boolean,
    ) => void;
    onLayoutOrMount?: (
        inputIdentifier?: string | number,
        inputValue?: string,
        isValid?: boolean,
    ) => void | string;
    onPress?: (
        inputIdentifier?: string | number,
        inputValue?: string,
        isValid?: boolean,
    ) => void;
    onSubmit?: (
        inputIdentifier?: string | number,
        inputValue?: string,
        isValid?: boolean,
    ) => void;
    required?: boolean;
    shouldAutoFocus?: boolean;
    submitButton?: boolean;
    updateValueOnInitialValueChange?: boolean;
}

const TextInput = forwardRef((props: Props, ref): ReactElement => {
    const {
        centerLabel,
        clearButton,
        email,
        errorMessage,
        errorMessageOffset,
        floatingErrorMessage,
        floatingErrorOffset,
        floatingErrorProps,
        id,
        initiallyValid = false,
        initialValue = "",
        intOnly,
        invalidIndicator,
        isInvalid,
        label,
        labelStyle,
        max,
        min,
        minLength,
        mustContainNumbers,
        numOnly,
        onClear,
        onFinishEditing,
        onInputChange,
        onLayoutOrMount,
        onPress,
        onSubmit,
        required,
        shouldAutoFocus,
        submitButton,
        updateValueOnInitialValueChange,
    } = props;

    const inputReducer = createInputReducer();
    const [inputState, dispatch] = useReducer(inputReducer, {
        value: initialValue,
        isValid: initiallyValid,
        touched: false,
        focussed: shouldAutoFocus ? true : false,
        hasBeenBlurred: false,
    });

    const inputRef = useRef<{
        blur: () => void;
        clear: () => void;
        focus: () => void;
    }>();
    useImperativeHandle(ref, () => ({
        focus: () => {
            inputRef.current?.focus();
        },
        blur: () => {
            inputRef.current?.blur();
        },
        setValue: (text: string, isValid: boolean) => {
            dispatch({
                type: "INPUT_CHANGE_NO_FOCUS",
                value: text,
                isValid: isValid,
            });
        },
        clear: () => {
            inputRef.current?.clear();
            dispatch({
                type: "INPUT_CHANGE",
                value: "",
                isValid: !required,
            });
        },
    }));

    useEffect(() => {
        if (updateValueOnInitialValueChange) {
            dispatch({
                type: "INPUT_CHANGE_NO_FOCUS",
                value: initialValue,
                isValid: initiallyValid,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    useEffect(() => {
        if (inputState.touched && onInputChange) {
            onInputChange(id, inputState.value, inputState.isValid);
        }
    }, [inputState, onInputChange, id]);

    const textChangeHandler = useCallback(
        (text: string) => {
            const emailRegex =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const integerRegex = /(^$)|(^\d+$)/;
            const numberRegex = /(^$)|(^(?=.)(?:\d+,)*\d*(?:\.\d+)?$)/;
            let isValid = true;
            if (required && text.trim().length === 0) {
                isValid = false;
            } else if (
                email &&
                !emailRegex.test(text.toLowerCase().trim()) &&
                text !== ""
            ) {
                isValid = false;
            } else if (min != null && +text < min) {
                isValid = false;
            } else if (max != null && +text > max) {
                isValid = false;
            } else if (minLength != null && text.length < minLength) {
                isValid = false;
            }
            // regex tests don't allow blank strings so allow for this if minLength = 0
            else if (
                (intOnly && !integerRegex.test(text)) ||
                ((minLength ?? 0) > 0 && text === "")
            ) {
                isValid = false;
            } else if (
                (numOnly && !numberRegex.test(text)) ||
                ((minLength ?? 0) > 0 && text === "")
            ) {
                isValid = false;
            } else if (mustContainNumbers && !/\d/.test(text)) {
                isValid = false;
            }
            dispatch({ type: "INPUT_CHANGE", value: text, isValid: isValid });
        },
        [
            email,
            intOnly,
            max,
            min,
            minLength,
            numOnly,
            mustContainNumbers,
            required,
        ],
    );

    const onFocusHandler = useCallback(() => {
        onPress?.(id, inputState.value, inputState.isValid);
    }, [id, inputState.isValid, inputState.value, onPress]);

    const lostFocusHandler = useCallback(() => {
        dispatch({ type: "INPUT_BLUR" });
        const newValue = onFinishEditing?.(
            id,
            inputState.value,
            inputState.isValid,
        );
        if (newValue) {
            dispatch({
                type: "INPUT_CHANGE",
                value: newValue,
                isValid: true,
            });
        }
    }, [id, inputState.isValid, inputState.value, onFinishEditing]);

    const onSubmitHandler = useCallback(() => {
        dispatch({ type: "INPUT_BLUR" });
        onSubmit?.(id, inputState.value, inputState.isValid);
    }, [id, inputState.isValid, inputState.value, onSubmit]);

    const onLayoutOrMountHandler = useCallback(() => {
        const newValue = onLayoutOrMount?.(
            id,
            inputState.value,
            inputState.isValid,
        );
        // change the input whenever it is mounted
        // by making onLayoutOrMount return the modified value
        if (newValue) {
            dispatch({
                type: "INPUT_CHANGE",
                value: newValue,
                isValid: true,
            });
        }
    }, [id, inputState.isValid, inputState.value, onLayoutOrMount]);

    const clearInput = useCallback(() => {
        dispatch({
            type: "INPUT_CHANGE",
            value: "",
            isValid: true,
        });
        onClear?.(id, inputState.value, inputState.isValid);
    }, [id, inputState.isValid, inputState.value, onClear]);

    return (
        <FormControl
            isInvalid={
                invalidIndicator &&
                (!inputState.isValid || isInvalid) &&
                inputState.hasBeenBlurred
            }
            isRequired={required}
            pb={errorMessage ? "2" : undefined}>
            {label ? (
                <FormControl.Label
                    _astrick={{ color: "surface.700" }}
                    _text={{
                        fontFamily: "Poppins-SemiBold",
                        color: "surface.100",
                        fontSize: "md",
                        pl: centerLabel ? "0" : "1",
                        ...labelStyle,
                    }}
                    justifyContent={centerLabel ? "center" : undefined}>
                    {label}
                </FormControl.Label>
            ) : null}
            <Tooltip
                _text={{ color: "warmGray.500" }}
                bg="surface.500"
                mt={floatingErrorOffset ?? 9}
                opacity={0.7}
                placement="bottom"
                px="1"
                py="0.5"
                {...floatingErrorProps}
                isOpen={
                    floatingErrorMessage
                        ? invalidIndicator &&
                          !inputState.isValid &&
                          inputState.focussed
                        : false
                }
                label={floatingErrorMessage ?? ""}>
                <Input
                    ref={inputRef}
                    _disabled={{
                        bg: "transparent",
                        borderWidth: 0,
                        opacity: 1,
                        py: 0,
                    }}
                    _focus={{
                        bg: "surface.100",
                        borderColor: "surface.300",
                    }}
                    _hover={{
                        bg: "surface.100",
                        borderColor: "surface.400",
                    }}
                    autoFocus={shouldAutoFocus}
                    bg="surface.100"
                    borderColor="surface.200"
                    color="surface.900"
                    disableFullscreenUI={true}
                    InputRightElement={
                        clearButton ? (
                            <Button
                                _hover={{ bg: "transparent", opacity: 0.8 }}
                                _pressed={{ bg: "transparent", opacity: 0.7 }}
                                colorScheme="surface"
                                leftIcon={<ClearIcon size="lg" />}
                                onPress={clearInput}
                                variant="ghost"
                                zIndex={2}
                            />
                        ) : submitButton ? (
                            <Button
                                _hover={{ bg: "transparent", opacity: 0.8 }}
                                _pressed={{ bg: "transparent", opacity: 0.7 }}
                                colorScheme={
                                    inputState.isValid ? "surface" : "warmGray"
                                }
                                disabled={!inputState.isValid}
                                leftIcon={<SendIcon size="5" />}
                                mr="2"
                                onPress={onSubmitHandler}
                                variant="ghost"
                            />
                        ) : undefined
                    }
                    onBlur={lostFocusHandler}
                    onChangeText={textChangeHandler}
                    onFocus={onFocusHandler}
                    onLayout={onLayoutOrMountHandler}
                    onSubmitEditing={onSubmitHandler}
                    placeholderTextColor="muted.400"
                    size="lg"
                    value={inputState.value}
                    {...props}
                />
            </Tooltip>
            {errorMessage ? (
                <FormControl.ErrorMessage
                    _text={{ fontSize: "sm", pl: "1", color: "red.400" }}
                    bottom={errorMessageOffset ?? 0}
                    position="absolute">
                    {errorMessage}
                </FormControl.ErrorMessage>
            ) : null}
        </FormControl>
    );
});

TextInput.displayName = "TextInput";

export default React.memo(TextInput);
