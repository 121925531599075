/**
 * @generated SignedSource<<0448b84a7e54b54294121837dbb3ba66>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateAccountInput = {
  accountType: string;
  clientMutationId?: string | null;
  discountCategory?: string | null;
  discountPercentage?: number | null;
  email?: string | null;
  firstName: string;
  lastName?: string | null;
  paidBySchool?: boolean | null;
  parentOrChildren?: ReadonlyArray<ParentOrChildrenAccountInput | null> | null;
  password?: string | null;
  phoneNumber?: string | null;
  profileId?: string | null;
  schoolName?: string | null;
  schoolYear?: number | null;
};
export type ParentOrChildrenAccountInput = {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
  phoneNumber?: string | null;
  profileId?: string | null;
  schoolName?: string | null;
  schoolYear?: number | null;
};
export type CreateAccountMutation$variables = {
  input: CreateAccountInput;
  parentConnections: ReadonlyArray<string>;
  pupilConnections: ReadonlyArray<string>;
  relevantConnections: ReadonlyArray<string>;
};
export type CreateAccountMutation$data = {
  readonly createAccount: {
    readonly children: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"AccountListFragment_ProfileNode">;
    } | null> | null;
    readonly errors: any | null;
    readonly parent: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"AccountListFragment_ProfileNode">;
    } | null;
    readonly profile: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"AccountListFragment_ProfileNode">;
    } | null;
    readonly success: boolean | null;
  } | null;
};
export type CreateAccountMutation = {
  response: CreateAccountMutation$data;
  variables: CreateAccountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "parentConnections"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pupilConnections"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "relevantConnections"
},
v4 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  (v7/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "AccountListFragment_ProfileNode"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumber",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v12 = [
  (v7/*: any*/),
  (v9/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "accountType",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "phoneNumber",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "schoolYear",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "discountCategory",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "discountPercentage",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "dbsNumber",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "dbsExpirationDate",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "SchoolNode",
    "kind": "LinkedField",
    "name": "school",
    "plural": false,
    "selections": [
      (v7/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "DjangoUserNode",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      (v10/*: any*/),
      (v11/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isSuperuser",
        "storageKey": null
      },
      (v7/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ProfileNode",
    "kind": "LinkedField",
    "name": "primaryParent",
    "plural": false,
    "selections": [
      (v7/*: any*/),
      (v9/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "DjangoUserNode",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v11/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v13 = {
  "kind": "Literal",
  "name": "edgeTypeName",
  "value": "ProfileNodeEdge"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "CreateAccountPayload",
        "kind": "LinkedField",
        "name": "createAccount",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "parent",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "children",
            "plural": true,
            "selections": (v8/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "CreateAccountPayload",
        "kind": "LinkedField",
        "name": "createAccount",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": (v12/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "prependNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "profile",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "relevantConnections"
              },
              (v13/*: any*/)
            ]
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "parent",
            "plural": false,
            "selections": (v12/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "prependNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "parent",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "parentConnections"
              },
              (v13/*: any*/)
            ]
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "children",
            "plural": true,
            "selections": (v12/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "prependNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "children",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "pupilConnections"
              },
              (v13/*: any*/)
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "84e0204ddaf0b0ff2e24d0fd119c180e",
    "id": null,
    "metadata": {},
    "name": "CreateAccountMutation",
    "operationKind": "mutation",
    "text": "mutation CreateAccountMutation(\n  $input: CreateAccountInput!\n) {\n  createAccount(input: $input) {\n    success\n    errors\n    profile {\n      id\n      ...AccountListFragment_ProfileNode\n    }\n    parent {\n      id\n      ...AccountListFragment_ProfileNode\n    }\n    children {\n      id\n      ...AccountListFragment_ProfileNode\n    }\n  }\n}\n\nfragment AccountListFragment_ProfileNode on ProfileNode {\n  id\n  accountNumber\n  accountType\n  phoneNumber\n  schoolYear\n  status\n  discountCategory\n  discountPercentage\n  dbsNumber\n  dbsExpirationDate\n  school {\n    id\n    name\n  }\n  user {\n    firstName\n    lastName\n    email\n    isSuperuser\n    id\n  }\n  primaryParent {\n    id\n    accountNumber\n    user {\n      firstName\n      lastName\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4df229b2893e3a485e15048d278a6fc6";

export default node;
