/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useState } from "react";

import lodashDebounce from "lodash.debounce";

export const useDebounceFunction = (
    fn: (...args: any[]) => any,
    delay: number,
    deps: any[],
): void => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const callback = useCallback(fn, deps);

    useEffect(() => {
        const handler = setTimeout(() => {
            callback();
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [callback, delay]);
};

export const useDebounceState = (obj = "", wait = 1000) => {
    const [state, setState] = useState(obj);

    const debounce = useMemo(
        () =>
            lodashDebounce((prop: string) => {
                setState(prop);
            }, wait),
        [setState, wait],
    );

    const setDebouncedState = (value: string) => {
        debounce(value);
    };

    return { state, setDebouncedState, debounce };
};
