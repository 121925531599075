import React, {
    useCallback,
    useReducer,
    useMemo,
    useRef,
    useState,
} from "react";
import type { FC } from "react";

import { VStack, Button, useToast, Center, Text, Spinner } from "native-base";
import { useMutation } from "react-relay";

import type {
    AuthStackNavigatorProps,
    AuthStackRouteProps,
} from "../../navigation/AuthNavigator";
import TextInput from "pianofunclub-shared/components/Inputs/TextInput";
import AlertPopup from "pianofunclub-shared/components/NativeBaseExtended/AlertPopup";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import {
    LockIcon,
    SecurityIcon,
} from "pianofunclub-shared/components/Other/Icons";

import type {
    ResetPasswordMutation,
    ResetPasswordMutation$data,
} from "pianofunclub-shared/relay/graphql/auth/__generated__/ResetPasswordMutation.graphql";
import { reset_password } from "pianofunclub-shared/relay/graphql/auth/ResetPassword";

import type { AlertError } from "pianofunclub-shared/types";
import { mapErrorToAlertText } from "pianofunclub-shared/utils/extractors";
import { createFormReducer } from "pianofunclub-shared/utils/reducers";

type NavigationProps = AuthStackNavigatorProps<"ResetPassword">;

type RouteProps = AuthStackRouteProps<"ResetPassword">;

interface Props {
    navigation: NavigationProps;
    route: RouteProps;
}

type InputValues = {
    newPassword1: string;
    newPassword2: string;
};

type InputTypes = string;

const ResetPasswordScreen: FC<Props> = (props) => {
    const { navigation, route } = props;

    const newPassword1InputRef = useRef<{
        clear: () => void;
        focus: () => void;
    }>();
    const newPassword2InputRef = useRef<{
        clear: () => void;
        focus: () => void;
    }>();

    const initialState = useMemo(() => {
        return {
            inputValues: {
                newPassword1: "",
                newPassword2: "",
            },
            inputValidities: {
                newPassword1: false,
                newPassword2: false,
            },
            formIsValid: false,
            formIsEdited: false,
        };
    }, []);

    const formReducer = createFormReducer<InputValues, InputTypes>(
        initialState,
    );
    const [formState, dispatchFormState] = useReducer(
        formReducer,
        initialState,
    );

    const updateToast = useToast();
    const updateToastId = "updateToast";
    const [failedAlertIsOpen, setFailedAlertIsOpen] = useState(false);
    const [failedError, setFailedError] = useState<AlertError>({
        code: undefined,
        alertHeader: "",
        alertMessage: "",
    });

    const clearInputs = useCallback(() => {
        newPassword1InputRef.current?.clear();
        dispatchFormState({ value: "", isValid: false, input: "newPassword1" });
        newPassword2InputRef.current?.clear();
        dispatchFormState({ value: "", isValid: false, input: "newPassword2" });
    }, []);

    const [commitResetPassword, resetPasswordInFlight] =
        useMutation<ResetPasswordMutation>(reset_password);

    const resetPassword = useCallback(
        (token: string) => {
            const resetPasswordConfig = {
                variables: {
                    input: {
                        token: token,
                        newPassword1: formState.inputValues.newPassword1,
                        newPassword2: formState.inputValues.newPassword2,
                    },
                },
                onCompleted: (response: ResetPasswordMutation$data) => {
                    if (response?.passwordReset?.success) {
                        navigation.navigate("Login");
                        if (!updateToast.isActive(updateToastId)) {
                            updateToast.show({
                                id: updateToastId,
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        alertProps={{ ml: 0 }}
                                        id={id}
                                        status="success"
                                        title={"Password updated"}
                                        toast={updateToast}
                                    />
                                ),
                            });
                        }
                    } else {
                        setFailedError(
                            mapErrorToAlertText(response.passwordReset?.errors),
                        );
                        setFailedAlertIsOpen(true);
                        clearInputs();
                    }
                },
                onError: () => {
                    if (!updateToast.isActive(updateToastId)) {
                        updateToast.show({
                            id: updateToastId,
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    alertProps={{ ml: 0 }}
                                    id={id}
                                    status="error"
                                    title={"Password could not be updated"}
                                    toast={updateToast}
                                />
                            ),
                        });
                    }
                },
            };

            commitResetPassword(resetPasswordConfig);
        },
        [
            clearInputs,
            commitResetPassword,
            formState.inputValues.newPassword1,
            formState.inputValues.newPassword2,
            navigation,
            updateToast,
        ],
    );

    const inputChangeHandler = useCallback(
        (
            inputIdentifier?: string | number,
            inputValue?: string,
            isValid?: boolean,
        ) => {
            if (
                typeof inputIdentifier === "string" &&
                typeof inputValue === "string"
            ) {
                dispatchFormState({
                    value: inputValue,
                    isValid: isValid,
                    input: inputIdentifier,
                });
            }
        },
        [dispatchFormState],
    );

    const inputSubmitHandler = useCallback(
        (inputIdentifier?: string | number) => {
            switch (inputIdentifier) {
                case "oldPassword":
                    newPassword1InputRef.current?.focus();
                    break;
                case "newPassword1":
                    newPassword2InputRef.current?.focus();
                    break;
            }
        },
        [],
    );

    if (!route.params?.token) {
        return (
            <VStack
                alignItems="center"
                flex={1}
                justifyContent="center"
                px="8"
                space="2"
            >
                <Text
                    color="surface.100"
                    fontSize="2xl"
                    fontWeight="600"
                    textAlign="center"
                >
                    That link is invalid...
                </Text>
                <Text color="surface.100" fontSize="6xl">
                    🤕
                </Text>
                <Text
                    color="surface.100"
                    fontSize="2xl"
                    fontWeight="600"
                    textAlign="center"
                >
                    Try requesting another link
                </Text>
                <Button
                    _text={{ fontSize: "lg" }}
                    borderRadius="full"
                    colorScheme="surface"
                    mt="5"
                    onPress={() => {
                        navigation.navigate("Login");
                    }}
                    px="4"
                >
                    Go back
                </Button>
                <Text
                    key="contact"
                    color="surface.100"
                    fontSize="md"
                    pt="8"
                    textAlign="center"
                >
                    {"Please get in touch if the problem doesn't go away."}
                </Text>
            </VStack>
        );
    }

    return (
        <Center flex={1}>
            <VStack alignItems="center" space="6">
                <Text
                    color="surface.100"
                    fontSize="2xl"
                    fontWeight={700}
                    mb="4"
                >
                    Reset your Password
                </Text>
                <VStack
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                >
                    <TextInput
                        ref={newPassword1InputRef}
                        autoCapitalize="none"
                        blurOnSubmit={false}
                        borderColor="surface.800"
                        errorMessage="8+ characters, includes both A-Z and 0-9"
                        id="newPassword1"
                        initiallyValid={false}
                        initialValue=""
                        InputLeftElement={
                            <SecurityIcon
                                color={
                                    formState.inputValues.newPassword1 == "" ||
                                    !formState.inputValidities.newPassword1
                                        ? "muted.400"
                                        : "emerald.400"
                                }
                                ml="3"
                                size="6"
                            />
                        }
                        invalidIndicator
                        keyboardType="default"
                        label="New Password"
                        mb="4"
                        minLength={8}
                        mustContainNumbers
                        onInputChange={inputChangeHandler}
                        onSubmit={inputSubmitHandler}
                        p="3"
                        placeholder="Enter your new password"
                        returnKeyType="next"
                        secureTextEntry
                        size="xl"
                    />
                    <TextInput
                        ref={newPassword2InputRef}
                        autoCapitalize="none"
                        borderColor="surface.800"
                        errorMessage="Passwords do not match"
                        errorMessageOffset={-20}
                        id="newPassword2"
                        initiallyValid={false}
                        initialValue=""
                        InputLeftElement={
                            <LockIcon
                                color={
                                    formState.inputValues.newPassword2 == "" ||
                                    !formState.inputValidities.newPassword2 ||
                                    formState.inputValues.newPassword1 !=
                                        formState.inputValues.newPassword2
                                        ? "muted.400"
                                        : "emerald.400"
                                }
                                ml="3"
                                size="6"
                            />
                        }
                        invalidIndicator
                        isInvalid={
                            formState.inputValues.newPassword1 !=
                                formState.inputValues.newPassword2 &&
                            formState.inputValues.newPassword2 != ""
                        }
                        keyboardType="default"
                        label="Confirm New Password"
                        minLength={8}
                        onInputChange={inputChangeHandler}
                        p="3"
                        placeholder="Re-enter your password"
                        secureTextEntry
                        size="xl"
                    />
                    <Button
                        _disabled={{ opacity: 0.6 }}
                        _hover={{ bg: "primary.600" }}
                        _pressed={{ bg: "primary.700" }}
                        _text={{ fontSize: "xl", fontWeight: "600" }}
                        colorScheme="primary"
                        height="64px"
                        isDisabled={
                            resetPasswordInFlight || !formState.formIsValid
                        }
                        mt="12"
                        onPress={() => resetPassword(route.params.token)}
                        p="4"
                        shadow={5}
                        width="75%"
                    >
                        {!resetPasswordInFlight ? (
                            "Reset Password"
                        ) : (
                            <Spinner color="white" />
                        )}
                    </Button>
                </VStack>
                <Button
                    _hover={{ bg: "transparent", opacity: 0.8 }}
                    _pressed={{ bg: "transparent", opacity: 0.7 }}
                    colorScheme="surface"
                    mt="4"
                    onPress={() => navigation.navigate("Login")}
                    variant="ghost"
                >
                    Go Back
                </Button>
            </VStack>
            <AlertPopup
                alertIsOpen={failedAlertIsOpen}
                body={failedError.alertMessage}
                closeOnOverlayClick
                header={failedError.alertHeader}
                setAlertIsOpen={setFailedAlertIsOpen}
            >
                <Button
                    _text={{ fontSize: "lg" }}
                    colorScheme="primary"
                    onPress={() => {
                        setFailedAlertIsOpen(false);
                    }}
                    width="40%"
                >
                    Ok
                </Button>
            </AlertPopup>
        </Center>
    );
};

export const screenOptions = {
    headerShown: false,
};

export default React.memo(ResetPasswordScreen);
