import React, {
    useRef,
    useCallback,
    useMemo,
    useReducer,
    useEffect,
    Suspense,
    useState,
} from "react";
import type { ReactElement, ComponentProps } from "react";

import emoji from "emoji-dictionary";
import {
    Modal,
    VStack,
    Text,
    Box,
    Center,
    PresenceTransition,
    HStack,
    Checkbox,
    Button,
} from "native-base";
import type { Mutable } from "pianofunclub-shared/types";
import {
    usePreloadedQuery,
    useQueryLoader,
    useRelayEnvironment,
    useSubscribeToInvalidationState,
} from "react-relay";
import type {
    PreloadedQuery,
    UseQueryLoaderLoadQueryOptions,
} from "react-relay";
import {
    RecyclerListView,
    DataProvider,
    LayoutProvider,
} from "recyclerlistview";
import { fetchQuery } from "relay-runtime";
import type { Subscription } from "relay-runtime";

import type {
    LoadTeachersForModalQuery,
    LoadTeachersForModalQuery$data,
    LoadTeachersForModalQuery$variables,
} from "pianofunclub-shared/relay/graphql/accounts/__generated__/LoadTeachersForModalQuery.graphql";
import { load_teachers_for_modal } from "pianofunclub-shared/relay/graphql/accounts/LoadTeachersForModal";

import { addOrRemoveFromSet } from "../../utils/helpers";
import { useDebounceFunction } from "../../utils/hooks";
import LoadingBlobs from "../Animations/LoadingBlobs";
import ButtonDebounced from "../Buttons/ButtonDebounced";
import MultiSelectRow from "../ListItems/MultiSelectRow";
import { ChevronLeftIcon } from "../Other/Icons";

import ListEmptyBanner from "pianofunclub-shared/components/ListItems/ListEmptyBanner";
import CustomFlatListSpinner from "pianofunclub-shared/components/Other/CustomFlatListSpinner";
import SearchBar from "pianofunclub-shared/components/Other/SearchBar";

import { getFullName } from "pianofunclub-shared/utils/extractors";
import { createReducer } from "pianofunclub-shared/utils/reducers";

interface WrapperProps {
    allowNoSelection?: boolean;
    block?: number;
    buttonText: string;
    checkboxDefaultIsSelected?: boolean;
    checkboxText?: string;
    doNotHideOnSave?: boolean;
    hideCountFromButton?: boolean;
    hideModal: () => void;
    includeBackButton?: boolean;
    includeCheckbox?: boolean;
    initiallySelectAllTeachers?: boolean;
    initiallySelectedTeacherIds?: string[];
    modalBodyProps?: ComponentProps<typeof Modal.Body>;
    modalContentProps?: ComponentProps<typeof Modal.Content>;
    modalProps?: ComponentProps<typeof Modal>;
    onBackButtonPress?: () => void;
    onSave: (variables: {
        checkboxOptionIsTrue?: boolean;
        hasSelectedAllTeachers?: boolean;
        teacherIds: string[];
        teacherNames: string[];
    }) => void;
    schoolName?: string;
    showModal: boolean;
    showRegisterProgress?: boolean;
    startingYear?: number;
    termInWords?: string;
    title: string;
    width?: number;
}

interface ContentProps extends WrapperProps {
    loadTeachersQuery: (
        variables: LoadTeachersForModalQuery$variables,
        options?: UseQueryLoaderLoadQueryOptions | undefined,
    ) => void;
    loadTeachersQueryReference: PreloadedQuery<
        LoadTeachersForModalQuery,
        Record<string, unknown>
    >;
}

type ReducerValues = {
    checkboxOption?: boolean;
    contentIsRendered: boolean;
    dataProvider: DataProvider;
    isRefetching: boolean;
    searchTerm: string;
    subscription?: Subscription;
};

type ReducerTypes = string | boolean | Subscription | DataProvider | undefined;

const TeacherMultiSelectModal = (props: ContentProps): ReactElement | null => {
    const {
        allowNoSelection,
        block,
        buttonText,
        checkboxDefaultIsSelected,
        checkboxText,
        doNotHideOnSave,
        hideCountFromButton,
        hideModal,
        includeBackButton,
        includeCheckbox,
        initiallySelectAllTeachers,
        initiallySelectedTeacherIds,
        loadTeachersQuery,
        loadTeachersQueryReference,
        modalBodyProps,
        modalContentProps,
        modalProps,
        onBackButtonPress,
        onSave,
        schoolName,
        showModal,
        showRegisterProgress,
        startingYear,
        termInWords,
        title,
        width,
    } = props;

    const initialStartingYear = useRef(startingYear);
    const initialBlock = useRef(block);

    const teachersData = usePreloadedQuery(
        load_teachers_for_modal,
        loadTeachersQueryReference,
    );

    const allTeachers = useMemo(() => {
        return {
            ids:
                teachersData.profiles?.edges.map(
                    (item) => item?.node?.id ?? "",
                ) ?? [],
            names:
                teachersData.profiles?.edges.map((item) =>
                    getFullName(
                        item?.node?.user?.firstName,
                        item?.node?.user?.lastName,
                    ),
                ) ?? [],
        };
    }, [teachersData.profiles?.edges]);

    useSubscribeToInvalidationState(allTeachers.ids, () => {
        loadTeachersQuery(
            {
                orderBy: "user__firstName,user__lastName",
                status: "ACTIVE",
                startingYear: startingYear ?? 0,
                block: block ?? 0,
                school: schoolName,
            },
            { fetchPolicy: "network-only" },
        );
    });

    const [selectedTeachers, setSelectedTeachers] = useState(
        initiallySelectedTeacherIds && initiallySelectedTeacherIds.length > 0
            ? {
                  ids: new Set(initiallySelectedTeacherIds),
                  // add teacher names
                  names: new Set(
                      initiallySelectedTeacherIds
                          .map((id) => {
                              const teacher = teachersData.profiles?.edges.find(
                                  (item) => item?.node?.id === id,
                              )?.node;
                              return getFullName(
                                  teacher?.user.firstName,
                                  teacher?.user.lastName,
                              );
                          })
                          .filter((name) => name) as string[],
                  ),
              }
            : initiallySelectAllTeachers
              ? {
                    ids: new Set(allTeachers.ids),
                    names: new Set(allTeachers.names),
                }
              : { ids: new Set([]), names: new Set([]) },
    );

    const initialState = useMemo(() => {
        return {
            values: {
                searchTerm: "",
                isRefetching: false,
                subscription: undefined,
                dataProvider: new DataProvider((r1, r2) => {
                    return r1 !== r2;
                }),
                contentIsRendered: false,
                checkboxOption: includeCheckbox
                    ? (checkboxDefaultIsSelected ?? false)
                    : undefined,
            },
        };
    }, [checkboxDefaultIsSelected, includeCheckbox]);

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [state, dispatchState] = useReducer(reducer, initialState);

    useEffect(() => {
        rowRefs.current = new Map();

        // Sort teachers by ascending register completion progress before setting the data provider
        const sortedTeachersDataByRegisterCompletionProgress =
            teachersData.profiles?.edges.toSorted((profileA, profileB) => {
                const registerPercentageProgressProfileA =
                    profileA?.node &&
                    profileA.node.totalLessons &&
                    profileA.node.totalLessonsMarked &&
                    (profileA.node.totalLessons ?? 0) > 0
                        ? 100 *
                          (profileA.node.totalLessonsMarked /
                              profileA.node.totalLessons)
                        : 0;

                const registerPercentageProgressProfileB =
                    profileB?.node &&
                    profileB.node.totalLessons &&
                    profileB.node.totalLessonsMarked &&
                    (profileB.node.totalLessons ?? 0) > 0
                        ? 100 *
                          (profileB.node.totalLessonsMarked /
                              profileB.node.totalLessons)
                        : 0;

                return registerPercentageProgressProfileA >
                    registerPercentageProgressProfileB
                    ? -1
                    : 1;
            });

        if (
            sortedTeachersDataByRegisterCompletionProgress &&
            teachersData.profiles?.edges &&
            teachersData.profiles.edges.length > 0
        ) {
            dispatchState({
                input: "dataProvider",
                value: state.values.dataProvider.cloneWithRows(
                    sortedTeachersDataByRegisterCompletionProgress as Mutable<
                        typeof teachersData.profiles.edges
                    >,
                ),
            });
            // if there is no data, create a fresh DataProvider
        } else {
            dispatchState({
                input: "dataProvider",
                value: new DataProvider((r1, r2) => {
                    return r1 !== r2;
                }),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teachersData.profiles?.edges]);

    useEffect(() => {
        // Reload the teachers query with any newly updated state variables when modal is shown
        if (
            !showModal ||
            (initialStartingYear.current === startingYear &&
                initialBlock.current === block)
        ) {
            return;
        }

        state.values.subscription?.unsubscribe();
        dispatchState({ input: "isRefetching", value: true });

        const variables = {
            searchTerm:
                state.values.searchTerm.trim() !== ""
                    ? state.values.searchTerm.trim()
                    : undefined,
            orderBy: "user__firstName,user__lastName",
            startingYear: startingYear ?? 0,
            block: block ?? 0,
            school: schoolName,
        };

        dispatchState({
            input: "subscription",
            value: fetchQuery<LoadTeachersForModalQuery>(
                environment,
                load_teachers_for_modal,
                variables,
                {
                    fetchPolicy: "store-or-network",
                },
            ).subscribe({
                complete: () => {
                    dispatchState({ input: "isRefetching", value: false });
                    loadTeachersQuery(variables, {
                        // @ts-expect-error relay typing deficiency
                        fetchPolicy: "store-only",
                    });
                },
                error: () => {
                    dispatchState({ input: "isRefetching", value: false });
                },
            }),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal, startingYear, block]);

    const layoutProvider = useMemo(() => {
        return new LayoutProvider(
            () => "VSEL",
            (type, dim) => {
                switch (type) {
                    case "VSEL":
                        dim.width = width ?? 450;
                        dim.height = 43;
                        break;
                    default:
                        dim.width = 0;
                        dim.height = 0;
                }
            },
        );
    }, [width]);

    const rowRefs = useRef(new Map());
    // stop refetch useEffects firing on first render
    const renderCount = useRef(0);
    const environment = useRelayEnvironment();

    useDebounceFunction(
        () => {
            if (renderCount.current < 1) {
                renderCount.current += 1;
                return;
            }

            state.values.subscription?.unsubscribe();
            dispatchState({ input: "isRefetching", value: true });

            const variables = {
                searchTerm:
                    state.values.searchTerm.trim() !== ""
                        ? state.values.searchTerm.trim()
                        : undefined,
                orderBy: "user__firstName,user__lastName",
                startingYear: startingYear ?? 0,
                block: block ?? 0,
            };

            dispatchState({
                input: "subscription",
                value: fetchQuery<LoadTeachersForModalQuery>(
                    environment,
                    load_teachers_for_modal,
                    variables,
                    {
                        fetchPolicy: "store-or-network",
                    },
                ).subscribe({
                    complete: () => {
                        dispatchState({ input: "isRefetching", value: false });
                        loadTeachersQuery(variables, {
                            // @ts-expect-error relay typing deficiency
                            fetchPolicy: "store-only",
                        });
                    },
                    error: () => {
                        dispatchState({ input: "isRefetching", value: false });
                    },
                }),
            });
        }, // no delay if clearing text
        state.values.searchTerm !== "" ? 750 : 0,

        [state.values.searchTerm],
    );

    const inputChangeHandler = useCallback(
        (_: unknown, inputValue?: string) => {
            dispatchState({
                input: "searchTerm",
                value: inputValue,
            });
        },
        [dispatchState],
    );

    const inputClearHandler = useCallback(() => {
        if (state.values.searchTerm !== "") {
            dispatchState({
                input: "searchTerm",
                value: "",
            });
        }
    }, [dispatchState, state.values.searchTerm]);

    const itemPressHandler = useCallback(
        (variables: { id: string; label?: string }) => {
            setSelectedTeachers((currentlySelectedTeachers) => {
                const currentlySelectedTeachersClone = {
                    ids: new Set(currentlySelectedTeachers.ids),
                    names: new Set(currentlySelectedTeachers.names),
                };
                return {
                    ids: addOrRemoveFromSet(
                        currentlySelectedTeachersClone.ids,
                        variables.id,
                    ),
                    names: addOrRemoveFromSet(
                        currentlySelectedTeachersClone.names,
                        variables.label as string,
                    ),
                };
            });
        },
        [],
    );

    const selectAllCheckboxChangeHandler = useCallback(
        (isSelected: boolean) => {
            rowRefs.current.forEach((ref) => {
                ref.setIsChecked(isSelected);
            });
            setSelectedTeachers(
                isSelected
                    ? {
                          ids: new Set(allTeachers.ids),
                          names: new Set(allTeachers.names),
                      }
                    : {
                          ids: new Set([]),
                          names: new Set([]),
                      },
            );
        },
        [allTeachers],
    );

    const rowRenderer = useCallback(
        (
            _: unknown,
            item: NonNullable<
                LoadTeachersForModalQuery$data["profiles"]
            >["edges"][0],
            index: number,
        ): ReactElement | null => {
            const registerPercentageProgress =
                item?.node &&
                item.node.totalLessons &&
                item.node.totalLessonsMarked &&
                (item.node.totalLessons ?? 0) > 0
                    ? 100 *
                      (item.node.totalLessonsMarked / item.node.totalLessons)
                    : 0;

            if (item?.node) {
                let label: string;
                if (showRegisterProgress) {
                    label = item.node.user.firstName
                        ? `${getFullName(
                              item.node.user.firstName,
                              item.node.user.lastName,
                          )} - ${registerPercentageProgress.toFixed(1)}% (${
                              item.node.totalLessonsMarked
                          }/${item.node.totalLessons})`
                        : item.node.user.email;
                } else {
                    label = item.node.user.firstName
                        ? getFullName(
                              item.node.user.firstName,
                              item.node.user.lastName,
                          )
                        : item.node.user.email;
                }

                return (
                    <MultiSelectRow
                        key={item.node.id}
                        ref={(ref) => {
                            if (
                                ref &&
                                item.node?.id &&
                                !rowRefs.current.get(item.node.id)
                            ) {
                                rowRefs.current.set(item.node.id, ref);
                            }
                        }}
                        id={item.node.id}
                        index={index}
                        initiallySelected={selectedTeachers.ids.has(
                            item.node.id,
                        )}
                        itemPressHandler={itemPressHandler}
                        label={label}
                    />
                );
            } else {
                return null;
            }
        },
        [itemPressHandler, selectedTeachers.ids, showRegisterProgress],
    );

    const renderRefetchIndicator = useMemo(() => {
        return state.values.isRefetching ? (
            <CustomFlatListSpinner top="4" />
        ) : null;
    }, [state.values.isRefetching]);

    const renderSearchResults = useMemo(() => {
        return state.values.dataProvider.getSize() > 0 ? (
            <RecyclerListView
                dataProvider={state.values.dataProvider}
                layoutProvider={layoutProvider}
                rowRenderer={rowRenderer}
                scrollViewProps={{
                    contentContainerStyle: { marginBottom: 16 },
                }}
                style={{
                    flex: 1,
                    minHeight: 1,
                }}
                suppressBoundedSizeException
                useWindowScroll
            />
        ) : !state.values.contentIsRendered ? (
            <LoadingBlobs bg="transparent" textProps={{ color: "surface.900" }}>
                Loading...
            </LoadingBlobs>
        ) : !state.values.isRefetching ? (
            <Center flex={1} flexGrow={1}>
                <PresenceTransition
                    animate={{
                        opacity: 1,
                        transition: {
                            delay: 500,
                            duration: 300,
                        },
                    }}
                    initial={{
                        opacity: 0,
                    }}
                    visible={true}>
                    <ListEmptyBanner explainer={"No accounts found..."}>
                        <Text fontSize="6xl">
                            {emoji.getUnicode("neutral_face")}
                        </Text>
                    </ListEmptyBanner>
                </PresenceTransition>
            </Center>
        ) : null;
    }, [
        state.values.dataProvider,
        state.values.isRefetching,
        state.values.contentIsRendered,
        rowRenderer,
        layoutProvider,
    ]);

    const searchBarRef = useRef(null);

    const renderContent = useMemo(() => {
        return (
            <>
                <VStack flex={1} space="6" width="85%">
                    <SearchBar
                        ref={searchBarRef}
                        inputClearHandler={inputClearHandler}
                        inputOnChangeHandler={inputChangeHandler}
                        inputSearchHandler={inputChangeHandler}
                        placeholderText={"Search for a teacher"}
                        searchText={state.values.searchTerm}
                        showSearchIcon
                    />
                    <Box
                        bg="surface.100"
                        borderColor="surface.200"
                        borderRadius="2xl"
                        borderWidth={1}
                        height="300px"
                        overflow="hidden"
                        width="100%">
                        <HStack
                            alignItems="center"
                            bg="primary.50"
                            borderBottomWidth={1}
                            borderColor="surface.200"
                            pb="2"
                            pt="3"
                            px="4"
                            space="3">
                            <Checkbox
                                isChecked={
                                    allTeachers.ids.length > 0 &&
                                    allTeachers.ids.length <=
                                        selectedTeachers.ids.size &&
                                    allTeachers.ids.every((id) =>
                                        selectedTeachers.ids.has(id),
                                    )
                                }
                                onChange={selectAllCheckboxChangeHandler}
                                value="SELECT_ALL"
                            />
                            <Text fontSize="md">Select All</Text>
                        </HStack>
                        {renderSearchResults}
                        {renderRefetchIndicator}
                    </Box>
                </VStack>
                {includeCheckbox ? (
                    <VStack alignItems="center" mt="6" space="2">
                        <Text fontSize="md">{checkboxText}</Text>
                        <Checkbox
                            defaultIsChecked={
                                checkboxDefaultIsSelected ?? false
                            }
                            onChange={(isSelected) =>
                                dispatchState({
                                    input: "checkboxOption",
                                    value: isSelected,
                                })
                            }
                            size="lg"
                            value="checkboxOption"
                        />
                    </VStack>
                ) : null}
                <ButtonDebounced
                    _text={{ fontSize: "xl" }}
                    height="56px"
                    isDisabled={
                        selectedTeachers.ids.size === 0 && !allowNoSelection
                    }
                    mb="4"
                    minWidth="200px"
                    mt="8"
                    onPress={() => {
                        onSave({
                            teacherIds: Array.from(selectedTeachers.ids),
                            teacherNames: Array.from(selectedTeachers.names),
                            hasSelectedAllTeachers:
                                selectedTeachers.ids.size ===
                                allTeachers.ids.length,
                            checkboxOptionIsTrue: state.values.checkboxOption,
                        });
                        if (!doNotHideOnSave) {
                            hideModal();
                        }
                    }}
                    px="6">
                    {`${buttonText}${
                        !hideCountFromButton
                            ? ` (${selectedTeachers.ids.size})`
                            : ""
                    }`}
                </ButtonDebounced>
            </>
        );
    }, [
        allTeachers.ids,
        allowNoSelection,
        buttonText,
        checkboxDefaultIsSelected,
        checkboxText,
        doNotHideOnSave,
        hideCountFromButton,
        hideModal,
        includeCheckbox,
        inputChangeHandler,
        inputClearHandler,
        onSave,
        renderRefetchIndicator,
        renderSearchResults,
        selectAllCheckboxChangeHandler,
        selectedTeachers.ids,
        selectedTeachers.names,
        state.values.checkboxOption,
        state.values.searchTerm,
    ]);

    useEffect(() => {
        setTimeout(() => {
            dispatchState({ input: "contentIsRendered", value: true });
        }, 1000);
        // unsubscribe from refetch on unmount
        return () => state.values.subscription?.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal
            initialFocusRef={searchBarRef}
            size="xl"
            {...modalProps}
            isOpen={showModal}
            onClose={() => {
                dispatchState({ input: "searchTerm", value: "" });
                hideModal();
            }}>
            <Modal.Content {...modalContentProps}>
                {includeBackButton ? (
                    <Button
                        _hover={{ opacity: 0.8, bg: "transparent" }}
                        _pressed={{ opacity: 0.7, bg: "transparent" }}
                        bg="transparent"
                        left="3"
                        leftIcon={<ChevronLeftIcon ml="0" p="0" size="5" />}
                        onPress={onBackButtonPress}
                        p="1"
                        position="absolute"
                        top="4"
                        variant="ghost"
                        zIndex="1"
                    />
                ) : null}
                <Modal.CloseButton
                    _hover={{ bg: "transparent", opacity: 0.7 }}
                    _pressed={{ bg: "transparent", opacity: 0.7 }}
                />
                <Modal.Header>{title}</Modal.Header>
                {termInWords ? (
                    <Text alignSelf="center" fontSize="md" pb="5">
                        {termInWords}
                    </Text>
                ) : null}
                <Modal.Body
                    alignItems="center"
                    mb="4"
                    px="6"
                    {...modalBodyProps}>
                    {renderContent}
                </Modal.Body>
            </Modal.Content>
        </Modal>
    );
};

const TeacherMultiSelectModalWrapper = (props: WrapperProps): ReactElement => {
    const { block, schoolName, startingYear } = props;

    const [loadTeachersQueryReference, loadTeachersQuery] =
        useQueryLoader<LoadTeachersForModalQuery>(load_teachers_for_modal);

    useEffect(() => {
        loadTeachersQuery(
            {
                orderBy: "user__firstName,user__lastName",
                status: "ACTIVE",
                startingYear: startingYear ?? 0,
                block: block ?? 0,
                school: schoolName,
            },
            { fetchPolicy: "store-or-network" },
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadTeachersQuery]);

    return loadTeachersQueryReference != null ? (
        <Suspense fallback={<></>}>
            <TeacherMultiSelectModal
                loadTeachersQuery={loadTeachersQuery}
                loadTeachersQueryReference={loadTeachersQueryReference}
                {...props}
            />
        </Suspense>
    ) : (
        <></>
    );
};

export default React.memo(TeacherMultiSelectModalWrapper);
