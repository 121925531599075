/**
 * @generated SignedSource<<1e199ddef3e6f7181dd692bcf275a092>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GenerateOrUpdateGoogleRegistersInput = {
  block: number;
  clientMutationId?: string | null;
  inputIds: ReadonlyArray<string | null>;
  isSchoolRegisters?: boolean | null;
  numberOfRegistersGenerated?: number | null;
  readInRegisterMarkings?: boolean | null;
  registersInBatch: number;
  startingYear: number;
};
export type GenerateOrUpdateGoogleRegistersMutation$variables = {
  input: GenerateOrUpdateGoogleRegistersInput;
};
export type GenerateOrUpdateGoogleRegistersMutation$data = {
  readonly generateOrUpdateGoogleRegisters: {
    readonly erroredSchool: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly erroredTeacher: {
      readonly id: string;
      readonly user: {
        readonly email: string;
        readonly firstName: string;
        readonly lastName: string;
      };
    } | null;
    readonly errors: any | null;
    readonly numberOfRegistersGenerated: number | null;
    readonly profileGroup: {
      readonly generateGoogleRegistersProgress: number | null;
      readonly id: string;
    } | null;
    readonly success: boolean | null;
    readonly totalToGenerate: number | null;
  } | null;
};
export type GenerateOrUpdateGoogleRegistersMutation = {
  response: GenerateOrUpdateGoogleRegistersMutation$data;
  variables: GenerateOrUpdateGoogleRegistersMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalToGenerate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfRegistersGenerated",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "SchoolNode",
  "kind": "LinkedField",
  "name": "erroredSchool",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "ProfileGroupNode",
  "kind": "LinkedField",
  "name": "profileGroup",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "generateGoogleRegistersProgress",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GenerateOrUpdateGoogleRegistersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GenerateOrUpdateGoogleRegistersPayload",
        "kind": "LinkedField",
        "name": "generateOrUpdateGoogleRegisters",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "erroredTeacher",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DjangoUserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v10/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GenerateOrUpdateGoogleRegistersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GenerateOrUpdateGoogleRegistersPayload",
        "kind": "LinkedField",
        "name": "generateOrUpdateGoogleRegisters",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "erroredTeacher",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DjangoUserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v10/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d35bf94703fb91fd68811b8c8091bbc7",
    "id": null,
    "metadata": {},
    "name": "GenerateOrUpdateGoogleRegistersMutation",
    "operationKind": "mutation",
    "text": "mutation GenerateOrUpdateGoogleRegistersMutation(\n  $input: GenerateOrUpdateGoogleRegistersInput!\n) {\n  generateOrUpdateGoogleRegisters(input: $input) {\n    success\n    errors\n    totalToGenerate\n    numberOfRegistersGenerated\n    erroredTeacher {\n      id\n      user {\n        firstName\n        lastName\n        email\n        id\n      }\n    }\n    erroredSchool {\n      id\n      name\n    }\n    profileGroup {\n      id\n      generateGoogleRegistersProgress\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "35e6b8e8bb0908fd335d22e126055fe0";

export default node;
