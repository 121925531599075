import React, {
    useMemo,
    useState,
    forwardRef,
    useImperativeHandle,
} from "react";
import type { ReactElement } from "react";

import { format } from "date-fns";
import { Checkbox, Center, Pressable } from "native-base";

import type { BlacklistedDateData } from "../Schools/BlacklistedDates";
import type { CellProps } from "pianofunclub-shared/components/Base/Cell";
import Row from "pianofunclub-shared/components/Base/Row";
import TextInput from "pianofunclub-shared/components/Inputs/TextInput";
import { TickIcon } from "pianofunclub-shared/components/Other/Icons";

import { listToStringWithCommasConverter } from "pianofunclub-shared/utils/converters";
import { getFullName } from "pianofunclub-shared/utils/extractors";
import { ensureDateTypeIfDefined } from "pianofunclub-shared/utils/helpers";

interface Props {
    cellProps?: CellProps;
    checkboxChangeHandler: (
        blacklistedDateId: string,
        isSelected: boolean,
    ) => void;
    data: NonNullable<BlacklistedDateData>["node"];
    flexArray: number[];
    isSchoolScreen?: boolean;
    onFinishEditingReason: (
        inputIdentifier?: string | number,
        inputValue?: string,
        isValid?: boolean,
    ) => void | string;
    onPressAllSchoolsBlacklistedDateRow: () => void;
    onPressDate: (variables: {
        blacklistedDateId: string;
        date: Date | undefined;
    }) => void;
    onPressTeachers: (variables: {
        blacklistedDateId: string;
        teacherIds: string[] | undefined;
    }) => void;
    rowHeight?: number;
    tableBorderColor?: string;
    tableBorderWidth?: number;
}

const BlacklistedDatesTableRow = forwardRef(
    (props: Props, ref): ReactElement => {
        const {
            cellProps,
            checkboxChangeHandler,
            data,
            flexArray,
            isSchoolScreen,
            onFinishEditingReason,
            onPressAllSchoolsBlacklistedDateRow,
            onPressDate,
            onPressTeachers,
            rowHeight,
            tableBorderColor,
            tableBorderWidth,
        } = props;

        const [checkBoxIsChecked, setCheckBoxIsChecked] = useState(false);

        useImperativeHandle(ref, () => ({
            setIsChecked: (isChecked: boolean) => {
                // not possible to check a school-wide blacklisted date from the school screen
                if (isSchoolScreen && !data?.school?.id) {
                    return;
                }

                setCheckBoxIsChecked(isChecked);
            },
        }));

        const rowData = useMemo(() => {
            if (!data?.id) {
                return [];
            }

            const appliesToAllSchools = !data?.school?.id;

            const teacherNames =
                data?.teachers?.edges?.map((teacher) =>
                    getFullName(
                        teacher?.node?.user.firstName,
                        teacher?.node?.user.lastName,
                    ),
                ) ?? [];

            const teacherIds =
                (data?.teachers?.edges
                    ?.map((teacher) => teacher?.node?.id)
                    ?.filter(Boolean) as string[]) ?? undefined;

            return [
                {
                    data: (
                        <Checkbox
                            isChecked={checkBoxIsChecked}
                            onChange={(isSelected) => {
                                setCheckBoxIsChecked(isSelected);
                                checkboxChangeHandler(data?.id, isSelected);
                            }}
                            size="md"
                            value={data?.id}
                        />
                    ),
                    onPress: () => {
                        return;
                    },
                },
                {
                    data: data?.date
                        ? format(new Date(data?.date), "EEE do MMM yyyy")
                        : "",
                    onPress: () =>
                        onPressDate({
                            blacklistedDateId: data?.id,
                            date: ensureDateTypeIfDefined(data?.date),
                        }),
                },
                ...(isSchoolScreen
                    ? [
                          {
                              data: appliesToAllSchools ? (
                                  <Center>
                                      <TickIcon
                                          color="surface.700"
                                          opacity={0.6}
                                          size="6"
                                      />
                                  </Center>
                              ) : (
                                  ""
                              ),
                          },
                          {
                              data:
                                  teacherNames.length > 3
                                      ? `${teacherNames.length} Teachers`
                                      : teacherNames.length > 0
                                        ? listToStringWithCommasConverter(
                                              teacherNames,
                                          )
                                        : "All",
                              onPress: !appliesToAllSchools
                                  ? () =>
                                        onPressTeachers({
                                            blacklistedDateId: data?.id,
                                            teacherIds,
                                        })
                                  : undefined,
                          },
                      ]
                    : []),
                {
                    data: (
                        <Center flex={1}>
                            <TextInput
                                _focus={{
                                    bg: "transparent",
                                    opacity: 1,
                                    borderWidth: tableBorderWidth,
                                }}
                                _hover={{
                                    bg: "transparent",
                                    opacity: 1,
                                    borderWidth: tableBorderWidth,
                                    color: "black",
                                }}
                                bg="transparent"
                                borderColor="transparent"
                                borderRadius={0}
                                borderWidth={tableBorderWidth}
                                fontFamily="Poppins-Regular"
                                height={39}
                                id={data?.id ?? ""}
                                initialValue={data?.reason ?? undefined}
                                mx="-2"
                                onFinishEditing={onFinishEditingReason}
                            />
                        </Center>
                    ),
                    onPress: () => {
                        return;
                    },
                },
            ];
        }, [
            checkBoxIsChecked,
            checkboxChangeHandler,
            data?.date,
            data?.id,
            data?.reason,
            data?.school?.id,
            data?.teachers?.edges,
            isSchoolScreen,
            onFinishEditingReason,
            onPressDate,
            onPressTeachers,
            tableBorderWidth,
        ]);

        return (
            <Pressable
                _pressed={{ opacity: 0.6 }}
                isDisabled={Boolean(!isSchoolScreen || data?.school?.id)}
                onPress={onPressAllSchoolsBlacklistedDateRow}
            >
                <Row
                    cellProps={{
                        ...cellProps,
                        ...(isSchoolScreen && !data?.school?.id
                            ? {
                                  textProps: {
                                      ...cellProps?.textProps,
                                      opacity: 0.6,
                                  },
                                  pointerEvents: "none",
                              }
                            : {}),
                    }}
                    data={rowData}
                    flexArray={flexArray}
                    rowHeight={rowHeight}
                    rowIndex={0}
                    tableBorderColor={tableBorderColor}
                    tableBorderWidth={tableBorderWidth}
                    tooltipLabel={
                        isSchoolScreen && !data?.school?.id
                            ? "You cannot edit school-wide blacklisted dates here ─ press to edit"
                            : undefined
                    }
                />
            </Pressable>
        );
    },
);

export default BlacklistedDatesTableRow;
