import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import type { FC } from "react";

import type { NavigationAction } from "@react-navigation/native";
import { endOfWeek, startOfWeek } from "date-fns";
import { Box, Heading, HStack, useToast } from "native-base";
import { useWindowDimensions } from "react-native";
import { DateTimePickerModal } from "react-native-paper-dates";
import { useQueryLoader, useMutation } from "react-relay";
import type { Subscription } from "relay-runtime";

import { useAuth } from "pianofunclub-shared/providers/AuthProvider";

import type {
    TeacherTimetableStackNavigatorProps,
    TeacherTimetableStackRouteProps,
} from "../../navigation/TeacherTimetableNavigator";
import type { TimetableLessonInfo } from "pianofunclub-shared/components/Modals/LessonDetailsModal";
import type { LessonInfo as RegisterLessonInfo } from "pianofunclub-shared/components/Modals/ManageRegisterLessonModal";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import { TimetableIcon } from "pianofunclub-shared/components/Other/Icons";
import TeacherTimetable from "pianofunclub-shared/components/Registers/TeacherTimetable";

import type { LoadLessonsQuery } from "pianofunclub-shared/relay/graphql/registers/__generated__/LoadLessonsQuery.graphql";
import type {
    RescheduleLessonMutation,
    RescheduleLessonMutation$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/RescheduleLessonMutation.graphql";
import { load_lessons } from "pianofunclub-shared/relay/graphql/registers/LoadLessons";
import { reschedule_lesson } from "pianofunclub-shared/relay/graphql/registers/RescheduleLesson";

import { getScaledWindowDimension } from "pianofunclub-shared/utils/converters";
import {
    getCurrentBlock,
    getDefaultStartingYearAndStartingYearOptions,
} from "pianofunclub-shared/utils/extractors";
import { createReducer } from "pianofunclub-shared/utils/reducers";

export type NavigationProps =
    TeacherTimetableStackNavigatorProps<"TeacherTimetableHub">;

type RouteProps = TeacherTimetableStackRouteProps<"TeacherTimetableHub">;

interface ScreenProps {
    navigation: NavigationProps;
    route: RouteProps;
}

type UpdateBlockDetailsModalInfo =
    | {
          id: string;
          school?: string;
          type: "INSTRUMENT" | "STAGE" | "PUPIL" | "TEACHER";
      }
    | undefined;

export type ReducerValues = {
    block: number;
    contentIsRendered: boolean;
    dateTimePickerModalDate?: Date;
    dateTimePickerModalLessonDuration?: number;
    dateTimePickerModalLessonId?: string;
    isRefetching: boolean;
    leaveAlertAction?: NavigationAction | (() => void);
    school?: string;
    searchTerm: string;
    startingYear: number;
    subscription?: Subscription;
    teachingDays?: readonly (number | null)[] | undefined;
    timetableDate: Date;
    timetableDatePickerIsOpen: boolean;
    timetableLessonModalInfo?: TimetableLessonInfo;
    timetableView: "DAY" | "WEEK";
};

export type ReducerTypes =
    | string
    | number
    | boolean
    | Subscription
    | RegisterLessonInfo
    | Date
    | UpdateBlockDetailsModalInfo
    | TimetableLessonInfo
    | NavigationAction
    | (() => void)
    | readonly (number | null)[]
    | undefined;

const TeacherTimetableHubScreen: FC<ScreenProps> = (props) => {
    const { navigation, route } = props;

    const { user } = useAuth();

    const defaultStartingYear = useMemo(
        () => getDefaultStartingYearAndStartingYearOptions()[0],
        [],
    );

    const initialState = useMemo(() => {
        // select the current day on the teacher register tab
        let dayIndex = new Date().getDay() - 1;
        // only allowed to select mon-fri
        if (dayIndex < 0 || dayIndex > 4) {
            dayIndex = 0;
        }
        let initialStartingYear = route.params?.startingYear;
        if (initialStartingYear === undefined) {
            initialStartingYear =
                user?.profile?.profileGroup?.currentStartingYear ??
                defaultStartingYear;
            navigation.setParams({
                startingYear: initialStartingYear,
            });
        }
        let initialBlock = route.params?.block;
        if (initialBlock === undefined) {
            if (
                user?.profile?.profileGroup?.currentStartingYear &&
                user?.profile?.profileGroup?.currentStartingYear >
                    defaultStartingYear
            ) {
                initialBlock = 1;
            } else {
                initialBlock = user?.profile?.currentBlock ?? getCurrentBlock();
            }
            navigation.setParams({
                block: initialBlock,
            });
        }
        return {
            values: {
                startingYear: initialStartingYear,
                block: initialBlock,
                school: route.params?.school,
                searchTerm: route.params?.searchTerm ?? "",
                isRefetching: false,
                contentIsRendered: false,
                subscription: undefined,
                dateTimePickerModalDate: undefined,
                dateTimePickerModalLessonId: undefined,
                dateTimePickerModalLessonDuration: undefined,
                timetableDate: new Date(),
                timetableView: "WEEK" as "DAY" | "WEEK",
                timetableDatePickerIsOpen: false,
                timetableLessonModalInfo: undefined,
            },
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [state, dispatchState] = useReducer(reducer, initialState);

    const [loadLessonsQueryReference, loadLessonsQuery] =
        useQueryLoader<LoadLessonsQuery>(load_lessons);

    useEffect(() => {
        if (user?.profile?.id) {
            let startDate = state.values.timetableDate;
            let endDate;
            if (state.values.timetableView === "WEEK") {
                startDate = startOfWeek(startDate, { weekStartsOn: 1 });
                endDate = endOfWeek(startDate, { weekStartsOn: 1 });
            }

            loadLessonsQuery(
                {
                    profileId: user.profile.id,
                    startDate: startDate.toString(),
                    endDate: endDate?.toString(),
                    orderBy: "rearrangedTimestamp,scheduledTimestamp",
                    skip: false,
                },
                { fetchPolicy: "store-or-network" },
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadLessonsQuery, user?.profile?.id]);

    const toast = useToast();

    const commitRescheduleLesson =
        useMutation<RescheduleLessonMutation>(reschedule_lesson)[0];

    const rescheduleLesson = useCallback(
        (
            lessonId: string,
            rearrangedDatetime: Date,
            lessonDuration?: number,
        ) => {
            const rescheduleLessonConfig = {
                variables: {
                    input: {
                        lessonId: lessonId,
                        rearrangedDatetime: String(rearrangedDatetime),
                        lessonDuration: lessonDuration,
                    },
                },
                optimsticResponse: {
                    rescheduleLesson: {
                        success: true,
                        errors: null,
                        lesson: {
                            id: lessonId,
                            rearrangedTimestamp: rearrangedDatetime,
                        },
                    },
                },
                onCompleted: (response: RescheduleLessonMutation$data) => {
                    if (response?.rescheduleLesson?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={"Rescheduled lesson"}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't reschedule lesson"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitRescheduleLesson(rescheduleLessonConfig);
        },
        [commitRescheduleLesson, toast],
    );

    const { height: windowHeight, scale } = useWindowDimensions();

    if (!user?.profile?.id) {
        return null;
    }

    return (
        <Box bg="surface.100" flex={1} pt="70">
            <Box flex={1} mx="30px" pt="6">
                <HStack
                    mb={
                        getScaledWindowDimension(windowHeight, scale) > 1000
                            ? "4"
                            : "-8"
                    }
                    ml="1"
                    space="3"
                >
                    <TimetableIcon color="primary.600" size="2xl" />
                    <Heading color="primary.600" fontSize="xl">
                        Timetable
                    </Heading>
                </HStack>
                <TeacherTimetable
                    // @ts-expect-error state is merged on component
                    dispatchState={dispatchState}
                    loadLessonsQuery={loadLessonsQuery}
                    loadLessonsQueryReference={loadLessonsQueryReference}
                    // @ts-expect-error nav props are merged on component
                    navigation={navigation}
                    profileId={user.profile.id}
                    state={state}
                    userIsTeacher
                    viewToggleOnRight
                />
            </Box>
            <DateTimePickerModal
                animationType="fade"
                canChooseEndTime
                date={state.values.dateTimePickerModalDate}
                duration={state.values.dateTimePickerModalLessonDuration}
                hours={state.values.dateTimePickerModalDate?.getHours()}
                label="Reschedule Lesson"
                locale="en"
                minutes={state.values.dateTimePickerModalDate?.getMinutes()}
                onConfirm={(params) => {
                    if (state.values.dateTimePickerModalLessonId) {
                        rescheduleLesson(
                            state.values.dateTimePickerModalLessonId,
                            params.date as Date,
                            params.duration,
                        );
                    }
                    dispatchState({
                        input: "dateTimePickerModalDate",
                        value: undefined,
                    });
                    dispatchState({
                        input: "dateTimePickerModalLessonId",
                        value: undefined,
                    });
                }}
                onDismiss={() =>
                    dispatchState({
                        input: "dateTimePickerModalDate",
                        value: undefined,
                    })
                }
                saveLabel="Update"
                uppercase={false}
                visible={
                    typeof state.values.dateTimePickerModalDate !== "undefined"
                }
            />
        </Box>
    );
};

export const screenOptions = {
    headerTitle: "Timetable",
};

export default TeacherTimetableHubScreen;
