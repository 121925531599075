/**
 * @generated SignedSource<<bb6db43cd583baa886ab6ed078050aeb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProfileModelDiscountCategory = "DISCOUNT_PUPIL_OTHER" | "DISCOUNT_PUPIL_PPKID" | "FULL_PRICE" | "%future added value";
export type ProfileModelStatus = "ACTIVE" | "ARCHIVED" | "NEW_ENROLLMENT" | "WAITING_LIST" | "%future added value";
export type LoadAccountQuery$variables = {
  block?: number | null;
  profileId: string;
  startingYear?: number | null;
};
export type LoadAccountQuery$data = {
  readonly profile: {
    readonly accountNumber: string | null;
    readonly addressFirstLine: string | null;
    readonly addressSecondLine: string | null;
    readonly children: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly user: {
            readonly firstName: string;
            readonly id: string;
            readonly lastName: string;
          };
        } | null;
      } | null>;
    };
    readonly city: string | null;
    readonly dbsExpirationDate: string | null;
    readonly dbsNumber: string | null;
    readonly discountCategory: ProfileModelDiscountCategory | null;
    readonly discountPercentage: number | null;
    readonly hasBook: boolean | null;
    readonly hasInstrument: boolean | null;
    readonly hasReturnedInstrument: boolean | null;
    readonly id: string;
    readonly instrumentSuppliedByUs: boolean | null;
    readonly instruments: ReadonlyArray<string | null> | null;
    readonly paidBySchool: boolean | null;
    readonly phoneNumber: string | null;
    readonly postcode: string | null;
    readonly primaryParent: {
      readonly id: string;
      readonly phoneNumber: string | null;
      readonly user: {
        readonly email: string;
        readonly firstName: string;
        readonly id: string;
        readonly lastName: string;
      };
    } | null;
    readonly school: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly schoolClass: string | null;
    readonly schoolYear: number | null;
    readonly schools: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    } | null> | null;
    readonly staffNoteForTeacher: string | null;
    readonly status: ProfileModelStatus;
    readonly teacherNoteForStaff: string | null;
    readonly teachers: ReadonlyArray<{
      readonly profileFirstName: string | null;
      readonly profileId: string | null;
      readonly profileLastName: string | null;
    } | null> | null;
    readonly teachingDays: ReadonlyArray<number | null> | null;
    readonly user: {
      readonly email: string;
      readonly firstName: string;
      readonly id: string;
      readonly lastName: string;
    };
  } | null;
};
export type LoadAccountQuery = {
  response: LoadAccountQuery$data;
  variables: LoadAccountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "block"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "profileId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startingYear"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "DjangoUserNode",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    (v6/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "profileId"
      }
    ],
    "concreteType": "ProfileNode",
    "kind": "LinkedField",
    "name": "profile",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accountNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "schoolYear",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "schoolClass",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "instruments",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "addressFirstLine",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "addressSecondLine",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "city",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postcode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dbsNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dbsExpirationDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "discountCategory",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "paidBySchool",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "discountPercentage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasBook",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasInstrument",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "instrumentSuppliedByUs",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasReturnedInstrument",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "staffNoteForTeacher",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "teacherNoteForStaff",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "block",
            "variableName": "block"
          },
          {
            "kind": "Variable",
            "name": "startingYear",
            "variableName": "startingYear"
          }
        ],
        "kind": "ScalarField",
        "name": "teachingDays",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SchoolNode",
        "kind": "LinkedField",
        "name": "school",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SchoolNode",
        "kind": "LinkedField",
        "name": "schools",
        "plural": true,
        "selections": (v5/*: any*/),
        "storageKey": null
      },
      (v8/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProfileNode",
        "kind": "LinkedField",
        "name": "primaryParent",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProfileNodeConnection",
        "kind": "LinkedField",
        "name": "children",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DjangoUserNode",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomProfileNode",
        "kind": "LinkedField",
        "name": "teachers",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "profileId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "profileFirstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "profileLastName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoadAccountQuery",
    "selections": (v9/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "LoadAccountQuery",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "8e3e73a4043ceaba4fb90be4e1adee6a",
    "id": null,
    "metadata": {},
    "name": "LoadAccountQuery",
    "operationKind": "query",
    "text": "query LoadAccountQuery(\n  $profileId: ID!\n  $startingYear: Int\n  $block: Int\n) {\n  profile(id: $profileId) {\n    id\n    accountNumber\n    status\n    phoneNumber\n    schoolYear\n    schoolClass\n    instruments\n    addressFirstLine\n    addressSecondLine\n    city\n    postcode\n    dbsNumber\n    dbsExpirationDate\n    discountCategory\n    paidBySchool\n    discountPercentage\n    hasBook\n    hasInstrument\n    instrumentSuppliedByUs\n    hasReturnedInstrument\n    staffNoteForTeacher\n    teacherNoteForStaff\n    teachingDays(startingYear: $startingYear, block: $block)\n    school {\n      id\n      name\n    }\n    schools {\n      id\n      name\n    }\n    user {\n      id\n      email\n      firstName\n      lastName\n    }\n    primaryParent {\n      id\n      phoneNumber\n      user {\n        id\n        email\n        firstName\n        lastName\n      }\n    }\n    children {\n      edges {\n        node {\n          id\n          user {\n            id\n            firstName\n            lastName\n          }\n        }\n      }\n    }\n    teachers {\n      profileId\n      profileFirstName\n      profileLastName\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b089b3efd110f6c3e654c056c357aed3";

export default node;
