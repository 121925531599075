import React from "react";
import type { ReactElement, Dispatch, MutableRefObject } from "react";

import { Text, Center, Spinner } from "native-base";

import type {
    ReducerValues as InvoiceAndEmailReducerValues,
    ReducerTypes as InvoiceAndEmailReducerTypes,
} from "../../components/Invoicing/InvoiceAndEmail";
import Actionsheet from "pianofunclub-shared/components/NativeBaseExtended/Actionsheet";
import {
    BlockIcon,
    DraftFilledIcon,
    EditIcon,
    InvoiceIcon,
    NotificationIcon,
    RestartIcon,
    SendIcon,
    TrashIcon,
} from "pianofunclub-shared/components/Other/Icons";

import type { LoadInvoiceQuery$data } from "pianofunclub-shared/relay/graphql/invoicing/__generated__/LoadInvoiceQuery.graphql";

import type { State, Action } from "pianofunclub-shared/utils/reducers";

interface Props {
    changeStatusActionsheetIsOpen: boolean;
    changeStatusActionsheetOnClose: () => void;
    deleteDraftInvoice: (invoiceId: string) => void;
    deleteDraftInvoiceInFlight: boolean;
    dispatchState: Dispatch<
        Action<InvoiceAndEmailReducerValues, InvoiceAndEmailReducerTypes>
    >;
    generateDraftHandler: (
        {
            isSideBarPress,
            isUpdate,
            overwriteOtherDraft,
        }: {
            isSideBarPress?: boolean | undefined;
            isUpdate?: boolean | undefined;
            overwriteOtherDraft?: boolean | undefined;
        },
        onComplete?: () => void,
    ) => void;
    invoiceData: LoadInvoiceQuery$data["invoice"];
    paymentModalKey: MutableRefObject<number>;
    state: State<InvoiceAndEmailReducerValues>;
    updateInvoiceStatusHandler: (
        invoiceId: string | undefined,
        status: string,
        options?: {
            draftInvoiceEmail?: boolean;
            ignoreActiveInvoiceCheck?: boolean;
            ignorePaymentsCheck?: boolean;
            sendInvoice?: boolean;
            sentDate?: string;
        },
        onComplete?: () => void,
    ) => void;
    updateInvoiceStatusInFlight: boolean;
}

const InvoiceStatusActionsheet = (props: Props): ReactElement => {
    const {
        changeStatusActionsheetIsOpen,
        changeStatusActionsheetOnClose,
        deleteDraftInvoice,
        deleteDraftInvoiceInFlight,
        dispatchState,
        generateDraftHandler,
        invoiceData,
        paymentModalKey,
        state,
        updateInvoiceStatusHandler,
        updateInvoiceStatusInFlight,
    } = props;

    return (
        <Actionsheet
            animationType="fade"
            hideDragIndicator
            isOpen={changeStatusActionsheetIsOpen}
            justifyContent="center"
            onClose={changeStatusActionsheetOnClose}
            size="lg"
        >
            <Actionsheet.Content
                alignItems="center"
                closeButtonProps={{ top: "5", right: "5" }}
                justifyContent="center"
                mx="auto"
                roundedBottom={12}
                roundedTop={12}
                showCloseButton
                w="40%"
            >
                <Text alignSelf="center" fontSize="xl" fontWeight="bold" py="4">
                    Update Invoice
                </Text>
                {invoiceData?.status === "DRAFT" ? (
                    !state.values.invoiceIsEdited ? (
                        <>
                            <Actionsheet.Item
                                _text={{ fontSize: "lg" }}
                                leftIcon={
                                    <Center size="7">
                                        {!updateInvoiceStatusInFlight ? (
                                            <SendIcon
                                                color="primary.400"
                                                mr="0.5"
                                                size="5"
                                            />
                                        ) : (
                                            <Spinner color="primary.400" />
                                        )}
                                    </Center>
                                }
                                onPress={() =>
                                    updateInvoiceStatusHandler(
                                        invoiceData?.id,
                                        "SENT",
                                        { sendInvoice: true },
                                    )
                                }
                            >
                                Send invoice
                            </Actionsheet.Item>
                            <Actionsheet.Item
                                _text={{ fontSize: "lg" }}
                                leftIcon={
                                    <Center size="7">
                                        {!updateInvoiceStatusInFlight ? (
                                            <EditIcon
                                                color="primary.400"
                                                mr="0.5"
                                                size="6"
                                            />
                                        ) : (
                                            <Spinner color="primary.400" />
                                        )}
                                    </Center>
                                }
                                onPress={() => {
                                    changeStatusActionsheetOnClose();
                                    dispatchState({
                                        input: "datePickerType",
                                        value: "SENT_DATE",
                                    });
                                    dispatchState({
                                        input: "datePickerIsOpen",
                                        value: true,
                                    });
                                }}
                            >
                                Mark as sent
                            </Actionsheet.Item>
                            <Actionsheet.Item
                                _text={{ fontSize: "lg" }}
                                leftIcon={
                                    <Center size="7">
                                        {!updateInvoiceStatusInFlight ? (
                                            <DraftFilledIcon
                                                color="surface.600"
                                                mr="0.5"
                                                size="6"
                                            />
                                        ) : (
                                            <Spinner color="primary.600" />
                                        )}
                                    </Center>
                                }
                                onPress={() =>
                                    updateInvoiceStatusHandler(
                                        invoiceData?.id,
                                        "SENT",
                                        { draftInvoiceEmail: true },
                                    )
                                }
                            >
                                Create gmail draft
                            </Actionsheet.Item>
                        </>
                    ) : (
                        <Actionsheet.Item
                            _text={{ fontSize: "lg" }}
                            leftIcon={
                                <Center size="7">
                                    <RestartIcon
                                        color="surface.600"
                                        mr="0.5"
                                        size="6"
                                    />
                                </Center>
                            }
                            onPress={() => {
                                generateDraftHandler({
                                    overwriteOtherDraft: true,
                                    isUpdate: true,
                                });
                                changeStatusActionsheetOnClose();
                            }}
                        >
                            Update draft with latest data
                        </Actionsheet.Item>
                    )
                ) : null}
                {invoiceData?.status === "SENT" ||
                (invoiceData?.status === "PENDING" &&
                    !invoiceData?.reminder3SentTimestamp) ? (
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="7">
                                <NotificationIcon color="orange.400" size="6" />
                            </Center>
                        }
                        onPress={() => {
                            changeStatusActionsheetOnClose();
                            dispatchState({
                                input: "sendReminderAlertIsOpen",
                                value: true,
                            });
                        }}
                    >
                        Send payment reminder
                    </Actionsheet.Item>
                ) : null}
                {invoiceData?.status !== "CANCELLED" ? (
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="7">
                                <InvoiceIcon color="primary.600" size="6" />
                            </Center>
                        }
                        onPress={() => {
                            paymentModalKey.current += 1;
                            dispatchState({
                                input: "paymentModalDetails",
                                value: {
                                    isNewPayment: true,
                                },
                            });
                            dispatchState({
                                input: "showPaymentModal",
                                value: true,
                            });
                            changeStatusActionsheetOnClose();
                        }}
                    >
                        Record payment
                    </Actionsheet.Item>
                ) : null}
                {invoiceData?.status !== "DRAFT" &&
                invoiceData?.status !== "CANCELLED" ? (
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="7">
                                {!updateInvoiceStatusInFlight ? (
                                    <BlockIcon color="surface.800" size="7" />
                                ) : (
                                    <Spinner color="surface.800" />
                                )}
                            </Center>
                        }
                        onPress={() =>
                            updateInvoiceStatusHandler(
                                invoiceData?.id,
                                "CANCELLED",
                            )
                        }
                    >
                        Cancel invoice
                    </Actionsheet.Item>
                ) : null}
                {invoiceData?.status === "DRAFT" ? (
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="7">
                                {!deleteDraftInvoiceInFlight ? (
                                    <TrashIcon color="surface.800" size="6" />
                                ) : (
                                    <Spinner color="surface.800" />
                                )}
                            </Center>
                        }
                        onPress={() => {
                            if (invoiceData?.id) {
                                deleteDraftInvoice(invoiceData.id);
                            }
                        }}
                    >
                        Delete draft
                    </Actionsheet.Item>
                ) : null}
            </Actionsheet.Content>
        </Actionsheet>
    );
};

export default React.memo(InvoiceStatusActionsheet);
