import { graphql } from "react-relay";

export const edit_enrolment = graphql`
    mutation EditEnrolmentMutation($input: EditEnrolmentInput!) {
        editEnrolment(input: $input) {
            success
            errors
            enrolment {
                id
                schoolName
                schoolBorough
                schoolPostcode
                pupilFirstName
                pupilLastName
                schoolYear
                schoolClass
                instrument
                paidBySchool
                premiumPupil
                discountPercentage
                teacherEmail
                lessonDay
                lessonStartTime
                lessonStage
                lessonType
                lessonDuration
                costPerLesson
                numberOfLessons
                parentName
                parentEmail
                parentPhoneNumber
                pupilNotes
                lessonBlockNotes
                positionInWaitingList
                addedOn
                enroled
                offered
                adminNotes
                sentWaitingListEmail
            }
        }
    }
`;
