import React, {
    Suspense,
    useMemo,
    useReducer,
    useEffect,
    useCallback,
    useRef,
    useState,
} from "react";
import type { FC, Dispatch, RefObject } from "react";

import type { NavigationAction } from "@react-navigation/native";
import emoji from "emoji-dictionary";
import {
    Box,
    Text,
    VStack,
    Button,
    useToast,
    HStack,
    CloseIcon,
    Pressable,
    useDisclose,
    Center,
} from "native-base";
import { useQueryLoader, usePreloadedQuery, useMutation } from "react-relay";
import type { PreloadedQuery } from "react-relay";
import type { Subscription, RecordSourceSelectorProxy } from "relay-runtime";

import { useAuth } from "pianofunclub-shared/providers/AuthProvider";

import AccountStatus from "../../components/Accounts/AccountStatus";
import SideBar from "../../components/Drawers/SideBar";
import { LOAD_X_BLACKLISTED_DATES } from "../../components/Schools/BlacklistedDates";
import SchoolDates from "../../components/Tabs/SchoolDates";
import SchoolLessons, {
    LOAD_X_LESSON_BLOCKS,
} from "../../components/Tabs/SchoolLessons";
import SchoolRates from "../../components/Tabs/SchoolRates";
import type {
    AccountsStackNavigatorProps,
    AccountsStackRouteProps,
} from "../../navigation/AccountsNavigator";
import type {
    RegistersStackNavigatorProps,
    RegistersStackRouteProps,
} from "../../navigation/RegistersNavigator";
import type {
    SchoolsStackNavigatorProps,
    SchoolsStackRouteProps,
} from "../../navigation/SchoolsNavigator";
import LoadingBlobs from "pianofunclub-shared/components/Animations/LoadingBlobs";
import ProblemSplash from "pianofunclub-shared/components/Base/ProblemSplash";
import ButtonDebounced from "pianofunclub-shared/components/Buttons/ButtonDebounced";
import TopTabBar from "pianofunclub-shared/components/Buttons/TopTabBar";
import TextInput from "pianofunclub-shared/components/Inputs/TextInput";
import ListEmptyBanner from "pianofunclub-shared/components/ListItems/ListEmptyBanner";
import Actionsheet from "pianofunclub-shared/components/NativeBaseExtended/Actionsheet";
import AlertPopup from "pianofunclub-shared/components/NativeBaseExtended/AlertPopup";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import {
    AddCircleIcon,
    ArchivedIcon,
    TickIcon,
    TrashIcon,
} from "pianofunclub-shared/components/Other/Icons";

import type { LoadLessonBlocksQuery } from "pianofunclub-shared/relay/graphql/registers/__generated__/LoadLessonBlocksQuery.graphql";
import { load_lesson_blocks } from "pianofunclub-shared/relay/graphql/registers/LoadLessonBlocks";
import type {
    EditSchoolMutation,
    EditSchoolMutation$data,
} from "pianofunclub-shared/relay/graphql/schools/__generated__/EditSchoolMutation.graphql";
import type { LoadBlacklistedDatesQuery } from "pianofunclub-shared/relay/graphql/schools/__generated__/LoadBlacklistedDatesQuery.graphql";
import type { LoadSchoolQuery } from "pianofunclub-shared/relay/graphql/schools/__generated__/LoadSchoolQuery.graphql";
import type { LoadTermDatesQuery } from "pianofunclub-shared/relay/graphql/schools/__generated__/LoadTermDatesQuery.graphql";
import { edit_school } from "pianofunclub-shared/relay/graphql/schools/EditSchool";
import { load_blacklisted_dates } from "pianofunclub-shared/relay/graphql/schools/LoadBlacklistedDates";
import { load_school } from "pianofunclub-shared/relay/graphql/schools/LoadSchool";
import { load_term_dates } from "pianofunclub-shared/relay/graphql/schools/LoadTermDates";

import type { UpdateBlockDetailsModalInfo } from "pianofunclub-shared/types";
import { titleCaseConverter } from "pianofunclub-shared/utils/converters";
import {
    getCurrentBlock,
    getDefaultStartingYearAndStartingYearOptions,
} from "pianofunclub-shared/utils/extractors";
import { createReducer } from "pianofunclub-shared/utils/reducers";
import type { State, Action } from "pianofunclub-shared/utils/reducers";

export type NavigationProps = SchoolsStackNavigatorProps<"School"> &
    AccountsStackNavigatorProps<"School"> &
    RegistersStackNavigatorProps<"School">;

type RouteProps = SchoolsStackRouteProps<"School"> &
    AccountsStackRouteProps<"School"> &
    RegistersStackRouteProps<"School">;

interface ScreenProps {
    navigation: NavigationProps;
    route: RouteProps;
}

interface ContentProps {
    dispatchState: Dispatch<Action<ReducerValues, ReducerTypes>>;
    loadSchoolQueryReference: PreloadedQuery<
        LoadSchoolQuery,
        Record<string, unknown>
    >;
    navigation: NavigationProps;
    route: RouteProps;
    schoolId: string;
    startingYears: {
        label: string;
        value: number;
    }[];
    state: State<ReducerValues>;
}

export type ReducerValues = {
    block: number;
    contentIsRendered: boolean;
    currentPageIndex: number;
    dayIndex?: number;
    deleteSchoolAlertIsOpen: boolean;
    isRefetching: boolean;
    leaveAlertAction?: NavigationAction | (() => void);
    lessonBlocksConnectionId?: string;
    orderBy?: string;
    searchTerm: string;
    showAddLessonBlockModal: boolean;
    showCreateLessonStageModal: boolean;
    sideBarIsCollapsed: boolean;
    startingYear: number;
    statusChangeInFlight: boolean;
    subscription?: Subscription;
    termDatePickerDate?: Date;
    termDatePickerInputRef?: RefObject<{
        setValue: (text: string, isValid: boolean) => void;
    }>;
    termDatePickerIsOpen: boolean;
    termDatesHaveBeenModified?: boolean;
    updateBlockDetailsModalInfo?: UpdateBlockDetailsModalInfo;
};

export type ReducerTypes =
    | string
    | number
    | boolean
    | Subscription
    | UpdateBlockDetailsModalInfo
    | Date
    | RefObject<{
          setValue: (text: string, isValid: boolean) => void;
      }>
    | NavigationAction
    | (() => void)
    | undefined;

const SIDE_BAR_EXPANDED_WIDTH = 400;
const SIDE_BAR_COLLAPSED_WIDTH = 12;

const SchoolContent: FC<ContentProps> = (props) => {
    const {
        dispatchState,
        loadSchoolQueryReference,
        navigation,
        route,
        schoolId,
        startingYears,
        state,
    } = props;

    const schoolData = usePreloadedQuery(load_school, loadSchoolQueryReference);

    const [loadLessonBlocksQueryReference, loadLessonBlocksQuery] =
        useQueryLoader<LoadLessonBlocksQuery>(load_lesson_blocks);

    useEffect(() => {
        if (
            schoolData.school &&
            (!state.values.contentIsRendered ||
                state.values.currentPageIndex == 2)
        ) {
            loadLessonBlocksQuery(
                {
                    first: LOAD_X_LESSON_BLOCKS,
                    school: schoolData.school.name,
                    startingYear: state.values.startingYear,
                    block: state.values.block,
                    searchTerm: state.values.searchTerm.trim(),
                    dayIndex: state.values.dayIndex,
                    orderBy: state.values.orderBy,
                    profileId: "",
                    skipRegisterProgress: true,
                    skipLessonBlocks: false,
                    skipLessonStages: false,
                },
                { fetchPolicy: "store-or-network" },
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        loadLessonBlocksQuery,
        schoolData.school,
        state.values.block,
        state.values.dayIndex,
        state.values.orderBy,
        state.values.searchTerm,
        state.values.startingYear,
    ]);

    const refreshHandler = useCallback(
        (onRefresh?: () => void) => {
            if (schoolData.school) {
                onRefresh?.();
                loadLessonBlocksQuery(
                    {
                        first: LOAD_X_LESSON_BLOCKS,
                        searchTerm: state.values.searchTerm.trim(),
                        school: schoolData.school.name,
                        profileId: "",
                        startingYear: state.values.startingYear,
                        block: state.values.block,
                        dayIndex: state.values.dayIndex,
                        orderBy: state.values.orderBy,
                        skipLessonBlocks: false,
                        skipRegisterProgress: true,
                        skipLessonStages: false,
                    },
                    { fetchPolicy: "network-only" },
                );
            }
        },
        [
            loadLessonBlocksQuery,
            schoolData.school,
            state.values.block,
            state.values.dayIndex,
            state.values.orderBy,
            state.values.searchTerm,
            state.values.startingYear,
        ],
    );

    const [loadTermDatesQueryReference, loadTermDatesQuery] =
        useQueryLoader<LoadTermDatesQuery>(load_term_dates);

    const [loadBlacklistedDatesQueryReference, loadBlacklistedDatesQuery] =
        useQueryLoader<LoadBlacklistedDatesQuery>(load_blacklisted_dates);

    useEffect(() => {
        if (!schoolData.school) {
            return;
        }

        loadTermDatesQuery(
            {
                school: schoolData.school.name,
                startingYear: state.values.startingYear,
                skipSchoolTermDates: false,
            },
            { fetchPolicy: "store-or-network" },
        );

        loadBlacklistedDatesQuery({
            school: schoolData.school.name,
            startingYear: state.values.startingYear,
            first: LOAD_X_BLACKLISTED_DATES,
        });
    }, [
        loadBlacklistedDatesQuery,
        loadTermDatesQuery,
        schoolData.school,
        state.values.block,
        state.values.startingYear,
    ]);

    const termDatesAndBlacklistedDatesRefreshHandler = useCallback(() => {
        if (!schoolData.school?.name) {
            return;
        }

        loadTermDatesQuery(
            {
                school: schoolData.school?.name,
                startingYear: state.values.startingYear,
                skipSchoolTermDates: false,
            },
            { fetchPolicy: "network-only" },
        );

        loadBlacklistedDatesQuery(
            {
                school: schoolData.school?.name,
                startingYear: state.values.startingYear,
                first: LOAD_X_BLACKLISTED_DATES,
            },
            { fetchPolicy: "network-only" },
        );
    }, [
        loadBlacklistedDatesQuery,
        loadTermDatesQuery,
        schoolData.school?.name,
        state.values.startingYear,
    ]);

    const [otherContacts, setOtherContacts] = useState(
        schoolData.school?.otherContacts.edges.map((item, index) => {
            if (item?.node) {
                return {
                    contactIdentifier: `otherContact${index + 1}`,
                    contactLabel: item.node.label,
                    initialFirstName: item.node.user?.firstName,
                    initialLastName: item.node.user?.lastName,
                    initialEmail: item.node.user?.email,
                    initialPhoneNumber: item.node.phoneNumber,
                };
            }
        }) ?? [],
    );

    const toast = useToast();
    const addressSecondLineInputRef = useRef<{ focus: () => void }>(null);
    const cityInputRef = useRef<{ focus: () => void }>(null);
    const postcodeInputRef = useRef<{ focus: () => void }>(null);
    const {
        isOpen: changeStatusActionsheetIsOpen,
        onClose: changeStatusActionsheetOnClose,
        onOpen: changeStatusActionsheetOnOpen,
    } = useDisclose();

    const [commitEditSchool, editSchoolInFlight] =
        useMutation<EditSchoolMutation>(edit_school);

    const editSchool = useCallback(
        (
            variables: {
                addressFirstLine?: string;
                addressSecondLine?: string;
                archived?: boolean;
                city?: string;
                deleteSchool?: boolean;
                headTeacherEmail?: string;
                headTeacherFirstName?: string;
                headTeacherLastName?: string;
                headTeacherPhoneNumber?: string;
                mainOfficeContactEmail?: string;
                mainOfficeContactFirstName?: string;
                mainOfficeContactLastName?: string;
                mainOfficeContactPhoneNumber?: string;
                name?: string;
                otherContactEmail?: string;
                otherContactFirstName?: string;
                otherContactIndex?: number;
                otherContactLabel?: string;
                otherContactLastName?: string;
                otherContactPhoneNumber?: string;
                postcode?: string;
                schoolId: string;
            },
            isContactUpdate?: boolean,
        ) => {
            const editSchoolConfig = {
                variables: {
                    connections: route.params.schoolsConnectionIds ?? [],
                    input: variables,
                },
                updater: (store: RecordSourceSelectorProxy) => {
                    // invalidate record in store so that queries for register action modals are refetched
                    // (archived teachers are removed from those modals)
                    if (
                        variables.archived !== undefined ||
                        variables.deleteSchool
                    ) {
                        const schoolRecord = store.get(variables.schoolId);
                        if (schoolRecord) {
                            schoolRecord.invalidateRecord();
                        }
                    }
                },
                onCompleted: (response: EditSchoolMutation$data) => {
                    if (response?.editSchool?.success) {
                        if (variables.deleteSchool) {
                            navigation.popToTop();
                        }
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status={
                                        variables.deleteSchool ||
                                        variables.archived
                                            ? "info"
                                            : "success"
                                    }
                                    title={`${
                                        isContactUpdate ? "Contact" : "School"
                                    } ${
                                        variables.deleteSchool
                                            ? "deleted"
                                            : variables.archived
                                              ? "archived"
                                              : "updated"
                                    }`}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={`Couldn't ${
                                        variables.deleteSchool
                                            ? "delete"
                                            : variables.archived
                                              ? "archive"
                                              : "update"
                                    } ${
                                        isContactUpdate ? "contact" : "school"
                                    }`}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                    dispatchState({
                        input: "statusChangeInFlight",
                        value: false,
                    });
                },
            };

            commitEditSchool(editSchoolConfig);
        },
        [
            commitEditSchool,
            dispatchState,
            navigation,
            route.params.schoolsConnectionIds,
            toast,
        ],
    );

    const schoolInputFinishEditingHandler = useCallback(
        (
            inputIdentifier?: string | number,
            inputValue?: string,
            isValid?: boolean,
        ) => {
            if (isValid && inputIdentifier) {
                if (
                    // check if the value has changed before firing mutation
                    // @ts-expect-error string indexing
                    inputValue !== (schoolData.school?.[inputIdentifier] ?? "")
                ) {
                    editSchool({
                        schoolId: schoolId,
                        [inputIdentifier]: inputValue?.trim(),
                    });
                }
            }
        },
        [editSchool, schoolData.school, schoolId],
    );

    const headTeacherInputFinishEditingHandler = useCallback(
        (
            inputIdentifier?: string | number,
            inputValue?: string,
            isValid?: boolean,
        ) => {
            if (isValid && typeof inputIdentifier === "string") {
                let fieldName = inputIdentifier.replace("headTeacher", "");
                fieldName =
                    fieldName.charAt(0).toLowerCase() + fieldName.slice(1);
                if (
                    // check if the value has changed before firing mutation
                    (fieldName === "phoneNumber" &&
                        inputValue !=
                            (schoolData.school?.headTeacher?.[fieldName] ??
                                "")) ||
                    (fieldName !== "phoneNumber" &&
                        inputValue !=
                            // @ts-expect-error string indexing
                            (schoolData.school?.headTeacher?.user?.[
                                fieldName
                            ] ?? ""))
                ) {
                    editSchool(
                        {
                            schoolId: schoolId,
                            [inputIdentifier]: inputValue?.trim(),
                        },
                        true,
                    );
                }
            }
        },
        [editSchool, schoolData.school, schoolId],
    );

    const mainOfficeContactInputFinishEditingHandler = useCallback(
        (
            inputIdentifier?: string | number,
            inputValue?: string,
            isValid?: boolean,
        ) => {
            if (isValid && typeof inputIdentifier === "string") {
                let fieldName = inputIdentifier.replace(
                    "mainOfficeContact",
                    "",
                );
                fieldName =
                    fieldName.charAt(0).toLowerCase() + fieldName.slice(1);
                if (
                    // check if the value has changed before firing mutation
                    ([
                        "phoneNumber",
                        "addressFirstLine",
                        "addressSecondLine",
                        "city",
                        "postcode",
                    ].includes(fieldName) &&
                        inputValue !=
                            // @ts-expect-error string indexing
                            (schoolData.school?.mainOfficeContact?.[
                                fieldName
                            ] ?? "")) ||
                    (![
                        "phoneNumber",
                        "addressFirstLine",
                        "addressSecondLine",
                        "city",
                        "postcode",
                    ].includes(fieldName) &&
                        inputValue !=
                            // @ts-expect-error string indexing
                            (schoolData.school?.mainOfficeContact?.user?.[
                                fieldName
                            ] ?? ""))
                ) {
                    editSchool(
                        {
                            schoolId: schoolId,
                            [inputIdentifier]: inputValue?.trim(),
                        },
                        ![
                            "addressFirstLine",
                            "addressSecondLine",
                            "city",
                            "postcode",
                        ].includes(fieldName),
                    );
                }
            }
        },
        [editSchool, schoolData.school, schoolId],
    );

    const otherContactsInputFinishEditingHandler = useCallback(
        (
            inputIdentifier?: string | number,
            inputValue?: string,
            isValid?: boolean,
        ) => {
            if (isValid && typeof inputIdentifier === "string") {
                let fieldName = inputIdentifier
                    .replace("otherContact", "")
                    .replace(/[0-9]/g, "");
                fieldName =
                    fieldName.charAt(0).toLowerCase() + fieldName.slice(1);
                const contactIndex = inputIdentifier.match(/\d+/g)?.join("");
                if (
                    contactIndex &&
                    // check if the value has changed before firing mutation
                    ((fieldName === "phoneNumber" &&
                        inputValue !=
                            (schoolData.school?.otherContacts.edges?.[
                                parseInt(contactIndex) - 1
                            ]?.node?.[fieldName] ?? "")) ||
                        (fieldName !== "phoneNumber" &&
                            inputValue !=
                                // @ts-expect-error string indexing
                                (schoolData.school?.otherContacts.edges?.[
                                    parseInt(contactIndex) - 1
                                ]?.node?.user?.[fieldName] ?? "")))
                ) {
                    editSchool(
                        {
                            schoolId: schoolId,
                            [inputIdentifier.replace(/[0-9]/g, "")]:
                                inputValue?.trim(),
                            otherContactIndex: parseInt(contactIndex),
                        },
                        true,
                    );
                }
            }
        },
        [editSchool, schoolData.school, schoolId],
    );

    const addContactHandler = useCallback(() => {
        const nextIdentifier = otherContacts
            .slice(-1)[0]
            ?.contactIdentifier?.slice(-1);

        setOtherContacts([
            ...otherContacts,
            {
                contactIdentifier: `otherContact${
                    nextIdentifier ? parseInt(nextIdentifier) + 1 : 1
                }`,
                contactLabel: "",
                initialFirstName: "",
                initialLastName: "",
                initialEmail: "",
                initialPhoneNumber: "",
            },
        ]);
    }, [otherContacts]);

    const removeContactHandler = useCallback(
        (contactIdentifier: string) => {
            setOtherContacts(
                otherContacts.filter(
                    (item) => item?.contactIdentifier !== contactIdentifier,
                ),
            );
        },
        [otherContacts],
    );

    const renderContactInputs = useCallback(
        (input: {
            contactIdentifier: string;
            contactLabel: string | null;
            finishEditingHandler: (
                id?: string | number,
                inputValue?: string,
                isValid?: boolean,
            ) => void;
            initialEmail?: string;
            initialFirstName?: string;
            initialLastName?: string;
            initialPhoneNumber?: string | null;
            labelIsEditable?: boolean;
        }) => {
            return (
                <>
                    <VStack alignItems="flex-start" width="100%">
                        {input.labelIsEditable ? (
                            <HStack pr="8" width="100%">
                                <TextInput
                                    _focus={{
                                        bg: "transparent",
                                        borderWidth: 0,
                                    }}
                                    _hover={{
                                        bg: "transparent",
                                        borderWidth: 0,
                                    }}
                                    autoCapitalize="words"
                                    bg="transparent"
                                    borderRadius="0"
                                    borderWidth={0}
                                    color="surface.700"
                                    flex={1}
                                    focusOutlineColor="transparent"
                                    id={`${input.contactIdentifier}Label`}
                                    initialValue={input.contactLabel ?? ""}
                                    mb="1"
                                    ml="1"
                                    mt="2"
                                    onFinishEditing={input.finishEditingHandler}
                                    p="0"
                                    placeholder="Who is this contact?"
                                    size="md"
                                />
                                <Button
                                    _hover={{
                                        bg: "transparent",
                                        opacity: 0.8,
                                    }}
                                    _pressed={{
                                        bg: "transparent",
                                        opacity: 0.7,
                                    }}
                                    leftIcon={
                                        <CloseIcon
                                            color="surface.700"
                                            size="4"
                                        />
                                    }
                                    onPress={() => {
                                        removeContactHandler(
                                            input.contactIdentifier,
                                        );
                                    }}
                                    p="1"
                                    variant="ghost"
                                />
                            </HStack>
                        ) : null}
                        <TextInput
                            autoCapitalize="words"
                            id={`${input.contactIdentifier}FirstName`}
                            initiallyValid
                            initialValue={input.initialFirstName ?? ""}
                            invalidIndicator
                            label={
                                !input.labelIsEditable
                                    ? input.contactLabel
                                    : undefined
                            }
                            labelStyle={{
                                color: "surface.700",
                                fontSize: "sm",
                            }}
                            onFinishEditing={input.finishEditingHandler}
                            placeholder="First name"
                            size="md"
                        />
                    </VStack>
                    <TextInput
                        autoCapitalize="words"
                        id={`${input.contactIdentifier}LastName`}
                        initiallyValid
                        initialValue={input.initialLastName ?? ""}
                        invalidIndicator
                        onFinishEditing={input.finishEditingHandler}
                        placeholder="Last name"
                        size="md"
                    />
                    <TextInput
                        autoCapitalize="none"
                        email
                        id={`${input.contactIdentifier}Email`}
                        initiallyValid
                        initialValue={input.initialEmail ?? ""}
                        invalidIndicator
                        keyboardType="email-address"
                        onFinishEditing={input.finishEditingHandler}
                        placeholder="Email"
                        size="md"
                    />
                    <TextInput
                        autoCapitalize="none"
                        id={`${input.contactIdentifier}PhoneNumber`}
                        initiallyValid
                        initialValue={input.initialPhoneNumber ?? ""}
                        invalidIndicator
                        keyboardType="numeric"
                        onFinishEditing={input.finishEditingHandler}
                        placeholder="Phone number"
                        size="md"
                    />
                </>
            );
        },
        [removeContactHandler],
    );

    const renderSideBar = useMemo(() => {
        return (
            <SideBar
                isCollapsed={state.values.sideBarIsCollapsed}
                navigation={navigation}
                setIsCollapsed={() =>
                    dispatchState({
                        input: "sideBarIsCollapsed",
                        value: !state.values.sideBarIsCollapsed,
                    })
                }
                width={
                    state.values.sideBarIsCollapsed
                        ? SIDE_BAR_COLLAPSED_WIDTH
                        : SIDE_BAR_EXPANDED_WIDTH
                }>
                <VStack
                    alignItems="center"
                    mt="20"
                    pb="6"
                    space="4"
                    width="100%">
                    <VStack alignItems="center">
                        <Text fontSize="5xl" textAlign="center">
                            {emoji.getUnicode("school")}
                        </Text>
                        <Text fontSize="xl" mt="2" textAlign="center">
                            {titleCaseConverter(schoolData.school?.name)}
                        </Text>
                        <Text fontSize="lg">School</Text>
                    </VStack>
                    <Pressable
                        alignItems="center"
                        onPress={() => changeStatusActionsheetOnOpen()}>
                        <AccountStatus
                            isLoading={
                                editSchoolInFlight &&
                                state.values.statusChangeInFlight
                            }
                            mt="2"
                            showEditArrow
                            status={
                                schoolData.school?.archived
                                    ? "ARCHIVED"
                                    : "ACTIVE"
                            }
                        />
                    </Pressable>
                    <VStack alignItems="center" space="2" width="100%">
                        <TextInput
                            autoCapitalize="words"
                            id="name"
                            initiallyValid={Boolean(schoolData.school?.name)}
                            initialValue={titleCaseConverter(
                                schoolData.school?.name ?? "",
                            )}
                            invalidIndicator
                            label="School name"
                            labelStyle={{
                                color: "surface.700",
                                fontSize: "sm",
                            }}
                            onFinishEditing={schoolInputFinishEditingHandler}
                            size="md"
                        />
                        <>
                            <TextInput
                                autoCapitalize="words"
                                id="addressFirstLine"
                                initiallyValid
                                initialValue={
                                    schoolData.school?.mainOfficeContact
                                        ?.addressFirstLine ?? ""
                                }
                                invalidIndicator
                                label="Address"
                                labelStyle={{
                                    color: "surface.700",
                                    fontSize: "sm",
                                }}
                                maxLength={200}
                                onFinishEditing={
                                    mainOfficeContactInputFinishEditingHandler
                                }
                                onSubmit={() =>
                                    addressSecondLineInputRef.current?.focus()
                                }
                                placeholder="1st line address"
                                size="md"
                            />
                            <TextInput
                                ref={addressSecondLineInputRef}
                                autoCapitalize="words"
                                id="addressSecondLine"
                                initiallyValid
                                initialValue={
                                    schoolData.school?.mainOfficeContact
                                        ?.addressSecondLine ?? ""
                                }
                                invalidIndicator
                                maxLength={200}
                                onFinishEditing={
                                    mainOfficeContactInputFinishEditingHandler
                                }
                                onSubmit={() => cityInputRef.current?.focus()}
                                placeholder="2nd line address"
                                size="md"
                            />
                            <TextInput
                                ref={cityInputRef}
                                autoCapitalize="words"
                                id="city"
                                initiallyValid
                                initialValue={
                                    schoolData.school?.mainOfficeContact
                                        ?.city ?? ""
                                }
                                invalidIndicator
                                maxLength={50}
                                onFinishEditing={
                                    mainOfficeContactInputFinishEditingHandler
                                }
                                onSubmit={() =>
                                    postcodeInputRef.current?.focus()
                                }
                                placeholder="City or county"
                                size="md"
                            />
                            <TextInput
                                ref={postcodeInputRef}
                                autoCapitalize="characters"
                                id="postcode"
                                initiallyValid
                                initialValue={
                                    schoolData.school?.mainOfficeContact
                                        ?.postcode ?? ""
                                }
                                invalidIndicator
                                maxLength={10}
                                onFinishEditing={
                                    mainOfficeContactInputFinishEditingHandler
                                }
                                placeholder="Postcode"
                                size="md"
                            />
                        </>
                        {renderContactInputs({
                            finishEditingHandler:
                                headTeacherInputFinishEditingHandler,
                            contactIdentifier: "headTeacher",
                            contactLabel: "Head Teacher",
                            initialFirstName:
                                schoolData.school?.headTeacher?.user?.firstName,
                            initialLastName:
                                schoolData.school?.headTeacher?.user?.lastName,
                            initialEmail:
                                schoolData.school?.headTeacher?.user?.email,
                            initialPhoneNumber:
                                schoolData.school?.headTeacher?.phoneNumber,
                        })}
                        {renderContactInputs({
                            finishEditingHandler:
                                mainOfficeContactInputFinishEditingHandler,
                            contactIdentifier: "mainOfficeContact",
                            contactLabel: "School Office",
                            initialFirstName:
                                schoolData.school?.mainOfficeContact?.user
                                    ?.firstName,
                            initialLastName:
                                schoolData.school?.mainOfficeContact?.user
                                    ?.lastName,
                            initialEmail:
                                schoolData.school?.mainOfficeContact?.user
                                    ?.email,
                            initialPhoneNumber:
                                schoolData.school?.mainOfficeContact
                                    ?.phoneNumber,
                        })}
                        {otherContacts.map((item) => {
                            if (item) {
                                return renderContactInputs({
                                    finishEditingHandler:
                                        otherContactsInputFinishEditingHandler,
                                    contactIdentifier: item.contactIdentifier,
                                    contactLabel: item.contactLabel,
                                    initialFirstName: item.initialFirstName,
                                    initialLastName: item.initialLastName,
                                    initialEmail: item.initialEmail,
                                    initialPhoneNumber: item.initialPhoneNumber,
                                    labelIsEditable: true,
                                });
                            } else {
                                return null;
                            }
                        })}
                        <Button
                            alignSelf="flex-start"
                            leftIcon={<AddCircleIcon size="6" />}
                            mt="2"
                            onPress={addContactHandler}
                            variant="ghost">
                            Add Contact
                        </Button>
                    </VStack>
                </VStack>
            </SideBar>
        );
    }, [
        addContactHandler,
        changeStatusActionsheetOnOpen,
        editSchoolInFlight,
        state.values.statusChangeInFlight,
        dispatchState,
        headTeacherInputFinishEditingHandler,
        schoolInputFinishEditingHandler,
        mainOfficeContactInputFinishEditingHandler,
        navigation,
        otherContacts,
        otherContactsInputFinishEditingHandler,
        renderContactInputs,
        schoolData.school?.archived,
        schoolData.school?.headTeacher?.phoneNumber,
        schoolData.school?.headTeacher?.user?.email,
        schoolData.school?.headTeacher?.user?.firstName,
        schoolData.school?.headTeacher?.user?.lastName,
        schoolData.school?.mainOfficeContact?.addressFirstLine,
        schoolData.school?.mainOfficeContact?.addressSecondLine,
        schoolData.school?.mainOfficeContact?.city,
        schoolData.school?.mainOfficeContact?.phoneNumber,
        schoolData.school?.mainOfficeContact?.postcode,
        schoolData.school?.mainOfficeContact?.user?.email,
        schoolData.school?.mainOfficeContact?.user?.firstName,
        schoolData.school?.mainOfficeContact?.user?.lastName,
        schoolData.school?.name,
        state.values.sideBarIsCollapsed,
    ]);

    const safeDeleteAccountRef = useRef(null);

    const renderModals = useMemo(() => {
        return (
            <>
                <Actionsheet
                    animationType="fade"
                    hideDragIndicator
                    isOpen={changeStatusActionsheetIsOpen}
                    justifyContent="center"
                    onClose={changeStatusActionsheetOnClose}
                    size="lg">
                    <Actionsheet.Content
                        alignItems="center"
                        closeButtonProps={{ top: "5", right: "5" }}
                        justifyContent="center"
                        mx="auto"
                        roundedBottom={12}
                        roundedTop={12}
                        showCloseButton
                        w="40%">
                        <Text
                            alignSelf="center"
                            fontSize="xl"
                            fontWeight="bold"
                            py="4">
                            Update School Status
                        </Text>
                        <Text
                            alignSelf="center"
                            fontFamily="Poppins-Regular"
                            fontSize="md"
                            mx="8"
                            pb="2"
                            textAlign="center">
                            {`${
                                !schoolData.school?.archived
                                    ? "Archive a school if they are no longer with PianoFunClub.\n\n"
                                    : ""
                            }Only delete a school if it has been created by mistake.`}
                        </Text>
                        {!schoolData.school?.archived ? (
                            <Actionsheet.Item
                                _text={{ fontSize: "lg" }}
                                leftIcon={
                                    <Center size="7">
                                        <ArchivedIcon
                                            color="surface.600"
                                            size="6"
                                        />
                                    </Center>
                                }
                                onPress={() => {
                                    dispatchState({
                                        input: "statusChangeInFlight",
                                        value: true,
                                    });
                                    editSchool({
                                        schoolId: schoolId,
                                        archived: true,
                                    });
                                    changeStatusActionsheetOnClose();
                                }}>
                                Archive school
                            </Actionsheet.Item>
                        ) : (
                            <Actionsheet.Item
                                _text={{ fontSize: "lg" }}
                                leftIcon={
                                    <Center size="7">
                                        <TickIcon
                                            color="primary.400"
                                            size="7"
                                        />
                                    </Center>
                                }
                                onPress={() => {
                                    dispatchState({
                                        input: "statusChangeInFlight",
                                        value: true,
                                    });
                                    editSchool({
                                        schoolId: schoolId,
                                        archived: false,
                                    });
                                    changeStatusActionsheetOnClose();
                                }}>
                                Make school active
                            </Actionsheet.Item>
                        )}
                        <Actionsheet.Item
                            _text={{ fontSize: "lg" }}
                            leftIcon={
                                <Center size="7">
                                    <TrashIcon color="surface.800" size="6" />
                                </Center>
                            }
                            onPress={() => {
                                dispatchState({
                                    input: "deleteSchoolAlertIsOpen",
                                    value: true,
                                });
                                changeStatusActionsheetOnClose();
                            }}>
                            Delete school
                        </Actionsheet.Item>
                    </Actionsheet.Content>
                </Actionsheet>
                <AlertPopup
                    alertIsOpen={state.values.deleteSchoolAlertIsOpen}
                    body="This cannot be undone and all its data will be permanently lost."
                    header={"Are you sure you want to delete this school?"}
                    safeButtonRef={safeDeleteAccountRef}
                    setAlertIsOpen={(isOpen: boolean) =>
                        dispatchState({
                            input: "deleteSchoolAlertIsOpen",
                            value: isOpen,
                        })
                    }>
                    <Button
                        ref={safeDeleteAccountRef}
                        _text={{ fontSize: "lg" }}
                        colorScheme="muted"
                        minWidth="100"
                        onPress={() => {
                            dispatchState({
                                input: "deleteSchoolAlertIsOpen",
                                value: false,
                            });
                        }}
                        width="40%">
                        No, go back
                    </Button>
                    <ButtonDebounced
                        _text={{ fontSize: "lg" }}
                        colorScheme="red"
                        minWidth="100"
                        onPress={() => {
                            dispatchState({
                                input: "statusChangeInFlight",
                                value: true,
                            });
                            editSchool({
                                schoolId: schoolId,
                                deleteSchool: true,
                            });
                            dispatchState({
                                input: "deleteSchoolAlertIsOpen",
                                value: false,
                            });
                        }}
                        width="40%">
                        Yes, delete
                    </ButtonDebounced>
                </AlertPopup>
            </>
        );
    }, [
        changeStatusActionsheetIsOpen,
        changeStatusActionsheetOnClose,
        dispatchState,
        editSchool,
        schoolData.school?.archived,
        schoolId,
        state.values.deleteSchoolAlertIsOpen,
    ]);

    const renderTab = useMemo(() => {
        return schoolData.school ? (
            state.values.currentPageIndex == 0 ? (
                <SchoolLessons
                    dispatchState={dispatchState}
                    loadLessonBlocksQueryReference={
                        loadLessonBlocksQueryReference
                    }
                    navigation={navigation}
                    refreshHandler={refreshHandler}
                    schoolId={schoolData.school.id}
                    schoolName={schoolData.school.name}
                    sideBarWidth={
                        state.values.sideBarIsCollapsed
                            ? SIDE_BAR_COLLAPSED_WIDTH
                            : SIDE_BAR_EXPANDED_WIDTH
                    }
                    startingYears={startingYears}
                    state={state}
                />
            ) : state.values.currentPageIndex == 1 ? (
                <SchoolDates
                    dispatchScreenState={dispatchState}
                    loadBlacklistedDatesQueryReference={
                        loadBlacklistedDatesQueryReference
                    }
                    loadTermDatesQueryReference={loadTermDatesQueryReference}
                    // @ts-expect-error navigation props are merged on component
                    navigation={navigation}
                    refreshHandler={termDatesAndBlacklistedDatesRefreshHandler}
                    schoolId={schoolData.school.id}
                    schoolName={schoolData.school.name}
                    screenState={state}
                    sideBarWidth={
                        state.values.sideBarIsCollapsed
                            ? SIDE_BAR_COLLAPSED_WIDTH
                            : SIDE_BAR_EXPANDED_WIDTH
                    }
                    startingYears={startingYears}
                />
            ) : state.values.currentPageIndex == 2 ? (
                <SchoolRates
                    dispatchState={dispatchState}
                    loadLessonBlocksQueryReference={
                        loadLessonBlocksQueryReference
                    }
                    navigation={navigation}
                    refreshHandler={refreshHandler}
                    schoolName={schoolData.school.name}
                    startingYears={startingYears}
                    state={state}
                />
            ) : (
                <ListEmptyBanner
                    explainer={"This hasn't been built yet..."}
                    flexGrow={1}
                    fontSize="3xl"
                    py="4">
                    <Text fontSize="110">
                        {emoji.getUnicode("construction_worker_man")}
                    </Text>
                </ListEmptyBanner>
            )
        ) : (
            <ProblemSplash navigation={navigation} />
        );
    }, [
        dispatchState,
        loadBlacklistedDatesQueryReference,
        loadLessonBlocksQueryReference,
        loadTermDatesQueryReference,
        navigation,
        refreshHandler,
        schoolData.school,
        startingYears,
        state,
        termDatesAndBlacklistedDatesRefreshHandler,
    ]);

    return (
        <Box flex={1}>
            {renderSideBar}
            <Box
                flex={1}
                flexGrow={1}
                ml={
                    state.values.sideBarIsCollapsed
                        ? SIDE_BAR_COLLAPSED_WIDTH
                        : SIDE_BAR_EXPANDED_WIDTH
                }
                mt="70"
                pt="6"
                px="6">
                <TopTabBar
                    bg="primary.100"
                    borderRadius="lg"
                    buttonStyle={{
                        bg: "primary.50",
                        alignItems: "center",
                        pt: 2,
                        pb: 2,
                        _hover: { bg: "primary.200" },
                        _pressed: { bg: "primary.300" },
                    }}
                    containerProps={{ pt: 4 }}
                    currentPageIndex={state.values.currentPageIndex}
                    hideBottomBar
                    mb="6"
                    overflow="hidden"
                    pages={["Lessons", "Term Dates", "Rates"]}
                    selectedButtonStyle={{ bg: "primary.100" }}
                    selectedTextStyle={{ color: "primary.600" }}
                    setCurrentPageIndex={(index) => {
                        dispatchState({
                            input: "currentPageIndex",
                            value: index,
                        });
                        navigation.setParams({ currentPageIndex: index });
                    }}
                    shadow={1}
                    textStyle={{ color: "primary.800" }}
                />
                {renderTab}
            </Box>
            {renderModals}
        </Box>
    );
};

const SchoolScreen: FC<ScreenProps> = (props) => {
    const { navigation, route } = props;

    const [defaultStartingYear, startingYears] = useMemo(
        () => getDefaultStartingYearAndStartingYearOptions(),
        [],
    );

    const { user } = useAuth();

    const initialState = useMemo(() => {
        let initialStartingYear = route.params?.startingYear;
        if (initialStartingYear === undefined) {
            initialStartingYear =
                user?.profile?.profileGroup?.currentStartingYear ??
                defaultStartingYear;
            navigation.setParams({
                startingYear: initialStartingYear,
            });
        }
        let initialBlock = route.params?.block;
        if (initialBlock === undefined) {
            if (
                user?.profile?.profileGroup?.currentStartingYear &&
                user?.profile?.profileGroup?.currentStartingYear >
                    defaultStartingYear
            ) {
                initialBlock = 1;
            } else {
                initialBlock = user?.profile?.currentBlock ?? getCurrentBlock();
            }
            navigation.setParams({
                block: initialBlock,
            });
        }
        return {
            values: {
                currentPageIndex: Number(route.params?.currentPageIndex ?? 0),
                startingYear: initialStartingYear,
                block: initialBlock,
                searchTerm: route.params?.searchTerm ?? "",
                dayIndex: route.params?.dayIndex,
                orderBy: route.params?.orderBy ?? "lessonDay,lessonTime",
                showAddLessonBlockModal: false,
                showCreateLessonStageModal: false,
                updateBlockDetailsModalInfo: undefined,
                lessonBlocksConnectionId: undefined,
                isRefetching: false,
                contentIsRendered: false,
                subscription: undefined,
                sideBarIsCollapsed: false,
                termDatePickerIsOpen: false,
                termDatePickerDate: undefined,
                termDatePickerInputRef: undefined,
                deleteSchoolAlertIsOpen: false,
                statusChangeInFlight: false,
            },
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [state, dispatchState] = useReducer(reducer, initialState);

    const [loadSchoolQueryReference, loadSchoolQuery] =
        useQueryLoader<LoadSchoolQuery>(load_school);

    useEffect(() => {
        loadSchoolQuery(
            {
                schoolId: route.params.schoolId,
            },
            { fetchPolicy: "store-or-network" },
        );
    }, [loadSchoolQuery, route.params.schoolId]);

    const renderLoadingFallback = useCallback(() => {
        return (
            <HStack flex={1}>
                <SideBar
                    isCollapsed={state.values.sideBarIsCollapsed}
                    navigation={navigation}
                    position="relative"
                    setIsCollapsed={() =>
                        dispatchState({
                            input: "sideBarIsCollapsed",
                            value: !state.values.sideBarIsCollapsed,
                        })
                    }
                    top="0"
                    width={
                        state.values.sideBarIsCollapsed
                            ? SIDE_BAR_COLLAPSED_WIDTH
                            : SIDE_BAR_EXPANDED_WIDTH
                    }
                />
                <LoadingBlobs
                    ml={
                        state.values.sideBarIsCollapsed
                            ? SIDE_BAR_COLLAPSED_WIDTH + 1
                            : SIDE_BAR_EXPANDED_WIDTH + 1
                    }
                    pt="70">
                    Loading School...
                </LoadingBlobs>
            </HStack>
        );
    }, [navigation, state.values.sideBarIsCollapsed]);

    return (
        <Box bg="surface.100" flex={1}>
            {loadSchoolQueryReference != null ? (
                <Suspense fallback={renderLoadingFallback()}>
                    <SchoolContent
                        dispatchState={dispatchState}
                        loadSchoolQueryReference={loadSchoolQueryReference}
                        navigation={navigation}
                        route={route}
                        schoolId={route.params.schoolId}
                        startingYears={startingYears}
                        state={state}
                    />
                </Suspense>
            ) : (
                renderLoadingFallback()
            )}
        </Box>
    );
};

export const screenOptions = {
    headerTitle: "School",
};

export default SchoolScreen;
