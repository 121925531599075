import "regenerator-runtime/runtime";

import { Platform } from "react-native";
import {
    RelayNetworkLayer,
    urlMiddleware,
    retryMiddleware,
    authMiddleware,
    loggerMiddleware,
    errorMiddleware,
} from "react-relay-network-modern";
import { Environment, RecordSource, Store } from "relay-runtime";
import type { IEnvironment } from "relay-runtime";

import { getValueFor, webGetValueFor } from "../utils/store";

// would ideally hide these in environment variables in .env (locally), expo (app) and Vercel (CRM)
// but .env isn't working with Yarn Workspace and Vercel Env Vars don't seem to be exposed to the code.
const PROD_FETCH_URL =
    "https://sb6qb56vha.execute-api.eu-west-1.amazonaws.com/prod/graphql";
const STAGING_FETCH_URL =
    "https://y3knf9x87b.execute-api.eu-west-1.amazonaws.com/staging/graphql";

// cannot run on localhost (use actual local ip address if running locally)
let FETCH_URL: string;
// set the fetch url based on the release channel
switch (process.env.REACT_APP_ENV) {
    case "prod":
        FETCH_URL = process.env.PROD_FETCH_URL ?? PROD_FETCH_URL ?? "";
        break;
    case "staging":
        FETCH_URL = process.env.STAGING_FETCH_URL ?? STAGING_FETCH_URL ?? "";
        break;
    default:
        FETCH_URL = `http://${process.env.LOCAL_IP}:8000/graphql`;
}

export const createWebRelayEnvironment = (): IEnvironment => {
    const network = new RelayNetworkLayer(
        [
            urlMiddleware({
                url: () => Promise.resolve(FETCH_URL),
            }),
            retryMiddleware({
                fetchTimeout: 40 * 1000, // 40 seconds
                retryDelays: [800, 1600, 2400],
            }),
            authMiddleware({
                token: () => {
                    if (Platform.OS !== "web") {
                        return getValueFor("token");
                    } else {
                        return webGetValueFor("token");
                    }
                },
                prefix: "JWT ",
            }),
            process.env.REACT_APP_ENV !== "prod" ? loggerMiddleware() : null,
            process.env.REACT_APP_ENV !== "prod" ? errorMiddleware() : null,
        ],
        { noThrow: true },
    );

    const store = new Store(new RecordSource());

    return new Environment({
        network,
        store,
    });
};

export const createAppRelayEnvironment = (): IEnvironment => {
    const network = new RelayNetworkLayer(
        [
            urlMiddleware({
                url: () => Promise.resolve(FETCH_URL),
            }),
            retryMiddleware({
                fetchTimeout: 60 * 1000, // 60 seconds
                retryDelays: [800, 1600, 3200, 6400],
            }),
            authMiddleware({
                token: () => {
                    if (Platform.OS !== "web") {
                        return getValueFor("token");
                    } else {
                        return webGetValueFor("token");
                    }
                },
                prefix: "JWT ",
            }),
        ],
        { noThrow: true },
    );

    const store = new Store(new RecordSource());

    const environment = new Environment({
        network,
        store,
    });

    return environment;
};
