/**
 * @generated SignedSource<<c4f0315551106bbc29fdf385f3e2bd97>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LoadEnrolmentQuery$variables = {
  enrolmentId: string;
};
export type LoadEnrolmentQuery$data = {
  readonly enrolment: {
    readonly addedOn: string | null;
    readonly adminNotes: string | null;
    readonly costPerLesson: number | null;
    readonly discountPercentage: number | null;
    readonly enroled: boolean;
    readonly id: string;
    readonly instrument: string | null;
    readonly lessonBlockNotes: string | null;
    readonly lessonDay: number | null;
    readonly lessonDuration: number | null;
    readonly lessonStage: string | null;
    readonly lessonStartTime: string | null;
    readonly lessonType: string | null;
    readonly numberOfLessons: number | null;
    readonly offered: boolean | null;
    readonly offeredOn: string | null;
    readonly paidBySchool: boolean | null;
    readonly parentEmail: string | null;
    readonly parentName: string | null;
    readonly parentPhoneNumber: string | null;
    readonly positionInWaitingList: number | null;
    readonly premiumPupil: boolean | null;
    readonly pupilFirstName: string | null;
    readonly pupilLastName: string | null;
    readonly pupilNotes: string | null;
    readonly schoolBorough: string | null;
    readonly schoolClass: string | null;
    readonly schoolName: string | null;
    readonly schoolPostcode: string | null;
    readonly schoolYear: number | null;
    readonly sentWaitingListEmail: boolean | null;
    readonly teacherEmail: string | null;
  } | null;
};
export type LoadEnrolmentQuery = {
  response: LoadEnrolmentQuery$data;
  variables: LoadEnrolmentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "enrolmentId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "enrolmentId"
      }
    ],
    "concreteType": "EnrolmentNode",
    "kind": "LinkedField",
    "name": "enrolment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "schoolName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "schoolBorough",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "schoolPostcode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pupilFirstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pupilLastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "schoolYear",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "schoolClass",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "instrument",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "paidBySchool",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "premiumPupil",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "discountPercentage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "teacherEmail",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lessonDay",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lessonStartTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lessonStage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lessonType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lessonDuration",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "costPerLesson",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "numberOfLessons",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parentName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parentEmail",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parentPhoneNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pupilNotes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lessonBlockNotes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "positionInWaitingList",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "addedOn",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enroled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "offered",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "offeredOn",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "adminNotes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sentWaitingListEmail",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoadEnrolmentQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoadEnrolmentQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "212340794496ab4570a72be5725100b8",
    "id": null,
    "metadata": {},
    "name": "LoadEnrolmentQuery",
    "operationKind": "query",
    "text": "query LoadEnrolmentQuery(\n  $enrolmentId: ID!\n) {\n  enrolment(id: $enrolmentId) {\n    id\n    schoolName\n    schoolBorough\n    schoolPostcode\n    pupilFirstName\n    pupilLastName\n    schoolYear\n    schoolClass\n    instrument\n    paidBySchool\n    premiumPupil\n    discountPercentage\n    teacherEmail\n    lessonDay\n    lessonStartTime\n    lessonStage\n    lessonType\n    lessonDuration\n    costPerLesson\n    numberOfLessons\n    parentName\n    parentEmail\n    parentPhoneNumber\n    pupilNotes\n    lessonBlockNotes\n    positionInWaitingList\n    addedOn\n    enroled\n    offered\n    offeredOn\n    adminNotes\n    sentWaitingListEmail\n  }\n}\n"
  }
};
})();

(node as any).hash = "c765306d231ff2016872c3b5e561a5d0";

export default node;
