import { graphql } from "react-relay";

export const update_scheduled_lesson_block_time = graphql`
    mutation UpdateScheduledLessonBlockTimeMutation(
        $input: UpdateScheduledLessonBlockTimeInput!
    ) {
        updateScheduledLessonBlockTime(input: $input) {
            success
            errors
            lessonBlocks {
                id
                lessonDay
                lessonTime
                lessons {
                    edges {
                        node {
                            scheduledTimestamp
                        }
                    }
                }
            }
        }
    }
`;
