/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
    useContext,
    useRef,
    useState,
    forwardRef,
    useImperativeHandle,
    createContext,
    memo,
    Children,
} from "react";
import type { ComponentProps, ReactElement, MutableRefObject } from "react";

import { useFocusRing } from "@react-native-aria/focus";
import { useHover } from "@react-native-aria/interactions";
import {
    Box,
    Text,
    Input,
    FlatList,
    ChevronDownIcon,
    useControllableState,
    usePropsResolution,
} from "native-base";
import type {
    IActionsheetContentProps,
    IBoxProps,
    IButtonProps,
} from "native-base";
import { Platform, Pressable, Keyboard } from "react-native";

import {
    stylingProps,
    extractInObject,
    mergeRefs,
    useFormControl,
} from "../../utils/nativeBase";

import Actionsheet from "./Actionsheet";

type BoxProps = ComponentProps<typeof Box>;

// This a modified version of the NativeBase select component:
// 1. It displays the actionsheet icons in the input display
// 2. You can have things labelled differently in the actionsheet and the input

interface SelectProps extends IBoxProps<SelectProps> {
    _actionSheetContent?: IActionsheetContentProps;
    _disabledBg?: string;
    _disabledBorderWidth?: number;
    _disabledOpacity?: number;
    _item?: IButtonProps;
    _selectedItem?: IButtonProps;
    actionSheetHeader?: string;
    color?: string;
    defaultValue?: string;
    dropdownCloseIcon?: React.JSX.Element;
    dropdownIcon?: React.JSX.Element;
    dropdownOpenIcon?: React.JSX.Element;
    headerStyle?: Record<string, string | number>;
    inputBorderColor?: string;
    isCentered?: boolean;
    isDisabled?: boolean;
    onCloseSelect?: () => void;
    onOpenSelect?: () => void;
    onValueChange?: (itemValue: string) => void;
    placeholder?: string;
    placeholderTextColor?: string;
    selectedValue?: string;
    startIconContainerProps?: BoxProps;
    subHeaderStyle?: Record<string, string | number>;
    variant?: "outline" | "filled" | "underlined" | "unstyled" | "rounded";
    wrapperRef?: MutableRefObject<any>;
}

interface SelectItemProps extends IButtonProps {
    actionSheetLabel: string;
    inputLabel?: string;
    startIcon?: ReactElement;
    value: string;
}
export declare type ISelectComponentType = ((
    props: SelectProps & {
        ref?: MutableRefObject<any>;
    },
) => React.JSX.Element) & {
    Item: React.MemoExoticComponent<
        (
            props: SelectItemProps & {
                ref?: MutableRefObject<any>;
            },
        ) => React.JSX.Element
    >;
};

const unstyledSelectWebStyles = {
    appearance: "none",
    WebkitAppearance: "none",
    MozAppearance: "none",
    position: "absolute",
    width: "100%",
    height: "100%",
    opacity: 0,
    zIndex: 1,
};

const SelectContext = createContext({
    onValueChange: (() => {
        return;
    }) as any,
    selectedValue: null as any,
    _selectedItem: {} as IButtonProps,
    _item: {} as IButtonProps,
});

const SelectItem = (
    { actionSheetLabel, isDisabled, value, ...props }: SelectItemProps,
    ref?: any,
) => {
    const { _item, _selectedItem, onValueChange, selectedValue } =
        useContext(SelectContext);
    if (Platform.OS !== "web") {
        const isSelected = selectedValue === value;

        return (
            <Actionsheet.Item
                ref={ref}
                accessibilityState={{ selected: isSelected }}
                onPress={() => {
                    if (!isDisabled) {
                        onValueChange(value);
                    }
                }}
                {..._item}
                {...(isSelected && _selectedItem)}
                {...props}>
                {actionSheetLabel}
            </Actionsheet.Item>
        );
    } else {
        return (
            <option ref={ref} disabled={isDisabled} value={value}>
                {actionSheetLabel}
            </option>
        );
    }
};

const SelectMain = (props: SelectProps, ref: any): ReactElement => {
    const selectProps = useFormControl({
        isDisabled: props.isDisabled,
        nativeID: props.nativeID,
    });

    const flatListData: SelectItemProps[] = [];

    const isDisabled = selectProps.disabled;
    const tempFix = "__NativebasePlaceholder__";
    const _ref = useRef(null);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const { focusProps, isFocusVisible } = useFocusRing();
    const { hoverProps, isHovered } = useHover({ isDisabled }, _ref);

    const {
        _actionSheetContent,
        _disabledBg,
        _disabledBorderWidth,
        _disabledOpacity,
        _item,
        _selectedItem,
        accessibilityLabel,
        actionSheetHeader,
        children,
        customDropdownIconProps,
        defaultValue,
        dropdownCloseIcon,
        dropdownIcon,
        dropdownOpenIcon,
        headerStyle,
        inputBorderColor,
        isCentered,
        onClose,
        onOpen,
        onOpenSelect,
        onValueChange,
        placeholder,
        selectedValue,
        startIconContainerProps,
        subHeaderStyle,
        ...resolvedProps
    } = usePropsResolution(
        "Select",
        props,
        {
            isDisabled,
            isHovered,
            isFocused,
            isFocusVisible,
        },
        undefined,
    );

    const [value, setValue] = useControllableState({
        value: selectedValue,
        defaultValue,
        onChange: (newValue) => {
            onValueChange && onValueChange(newValue);
            setIsOpen(false);
        },
    });

    useImperativeHandle(ref, () => ({
        open() {
            setIsOpen(true);
        },
    }));

    const itemsList: Array<{
        actionSheetLabel: string;
        inputLabel: string;
        startIcon: ReactElement;
        value: string;
    }> = Children.map(children, (child: any) => {
        if (child?.props) {
            return {
                actionSheetLabel: child.props.actionSheetLabel,
                value: child.props.value,
                inputLabel: child.props.inputLabel,
                startIcon: child.props.startIcon,
            };
        }
    });

    const selectedItemArray = itemsList.filter(
        (item: any) => item.value === value,
    );

    const selectedItem =
        selectedItemArray && selectedItemArray.length
            ? selectedItemArray[0]
            : null;

    const rightIcon =
        isOpen && dropdownOpenIcon ? (
            dropdownOpenIcon
        ) : !isOpen && dropdownCloseIcon ? (
            dropdownCloseIcon
        ) : dropdownIcon ? (
            dropdownIcon
        ) : (
            <ChevronDownIcon
                mr="2"
                {...customDropdownIconProps}
                color={isDisabled ? "transparent" : "trueGray.400"}
            />
        );

    const handleClose = () => {
        setIsOpen(false);
        onClose && onClose();
    };

    Children.map(children, (child: any) => {
        if (child?.props) {
            flatListData.push(child.props);
        }
    });

    const [layoutProps] = extractInObject(resolvedProps, [
        ...stylingProps.margin,
        ...stylingProps.flexbox,
        ...stylingProps.position,
        "shadow",
        "opacity",
    ]);

    const commonInput = (
        <Input
            InputRightElement={rightIcon}
            placeholder={placeholder}
            {...resolvedProps}
            _disabled={{
                bg: _disabledBg,
                opacity: _disabledOpacity,
                borderWidth: _disabledBorderWidth,
                color: "black",
            }}
            aria-hidden={true}
            borderColor={inputBorderColor ?? "surface.200"}
            editable={false}
            focusable={false}
            importantForAccessibility="no"
            InputLeftElement={
                selectedItem?.startIcon ? (
                    <Box mr="2" pl="2" {...startIconContainerProps}>
                        {selectedItem.startIcon}
                    </Box>
                ) : undefined
            }
            isDisabled={isDisabled}
            pointerEvents="none"
            value={selectedItem?.inputLabel ?? selectedItem?.actionSheetLabel}
        />
    );

    return Platform.OS === "web" ? (
        <Box {...layoutProps}>
            <select
                aria-readonly={selectProps.readOnly}
                disabled={isDisabled}
                required={selectProps.required}
                {...focusProps}
                {...hoverProps}
                ref={mergeRefs([ref, _ref])}
                aria-label={placeholder}
                onBlur={() => {
                    setIsFocused(false);
                    onClose && onClose();
                }}
                onChange={(e) => {
                    setValue(e.target.value);
                }}
                onFocus={() => {
                    setIsFocused(true);
                    onOpen && onOpen();
                }}
                // @ts-expect-error - ignored error
                style={unstyledSelectWebStyles}
                //@ts-ignore
                value={selectedItem === null ? tempFix : value}>
                <option disabled value={tempFix}>
                    {placeholder}
                </option>
                {children}
            </select>
            {commonInput}
        </Box>
    ) : (
        <>
            <Pressable
                ref={mergeRefs([ref, _ref])}
                accessibilityLabel={accessibilityLabel}
                accessibilityRole="button"
                disabled={isDisabled}
                onPress={() => {
                    Keyboard.dismiss();
                    setIsOpen(true);
                    onOpen && onOpen();
                    if (onOpenSelect) {
                        onOpenSelect();
                    }
                }}
                {...layoutProps}>
                {commonInput}
            </Pressable>
            <Actionsheet
                animationType={isCentered ? "fade" : "slide"}
                forceChoice={isCentered ? true : false}
                hideDragIndicator={isCentered ? true : false}
                isOpen={isOpen}
                justifyContent={isCentered ? "center" : undefined}
                onClose={handleClose}>
                <Actionsheet.Content
                    {..._actionSheetContent}
                    mx="auto"
                    roundedBottom={isCentered ? 12 : undefined}
                    roundedTop={isCentered ? 12 : 20}
                    w={isCentered ? "90%" : "100%"}>
                    <FlatList
                        data={flatListData}
                        keyExtractor={(_item, index) => index.toString()}
                        ListHeaderComponent={
                            actionSheetHeader ? (
                                <Box bg="muted.50">
                                    <Text
                                        fontSize="xl"
                                        py="2"
                                        textAlign="center"
                                        {...headerStyle}>
                                        {actionSheetHeader}
                                    </Text>
                                </Box>
                            ) : undefined
                        }
                        renderItem={({ item }) => {
                            const isSelected = selectedValue === item.value;
                            if (item.actionSheetLabel && item.value) {
                                return (
                                    <Actionsheet.Item
                                        accessibilityState={{
                                            selected: isSelected,
                                        }}
                                        onPress={() => {
                                            if (!isDisabled) {
                                                setValue(item.value);
                                            }
                                        }}
                                        {...item}
                                        {..._item}
                                        {...(isSelected && _selectedItem)}>
                                        {item.actionSheetLabel}
                                    </Actionsheet.Item>
                                );
                            } else {
                                // this makes it possible to have headers
                                return <Text {...subHeaderStyle} {...item} />;
                            }
                        }}
                        stickyHeaderIndices={
                            actionSheetHeader ? [0] : undefined
                        }
                        w="100%"
                    />
                </Actionsheet.Content>
            </Actionsheet>
        </>
    );
};

const SelectTemp: any = memo(forwardRef(SelectMain));
SelectTemp.Item = memo(forwardRef(SelectItem));

const Select = SelectTemp as ISelectComponentType;

export default Select;
