/**
 * @generated SignedSource<<c6168039e434bd3e04272fd880a89ef6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LessonStageModelLessonType = "GROUP" | "INDIVIDUAL" | "PAIRED" | "%future added value";
export type LoadLessonStagesQuery$variables = {
  block: number;
  school?: string | null;
  skip: boolean;
  startingYear: number;
};
export type LoadLessonStagesQuery$data = {
  readonly lessonStages?: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly costPerLesson: number | null;
        readonly id: string;
        readonly lessonDuration: number | null;
        readonly lessonType: LessonStageModelLessonType;
        readonly stage: number | null;
      } | null;
    } | null>;
  } | null;
};
export type LoadLessonStagesQuery = {
  response: LoadLessonStagesQuery$data;
  variables: LoadLessonStagesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "block"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "school"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startingYear"
},
v4 = {
  "kind": "Variable",
  "name": "block",
  "variableName": "block"
},
v5 = {
  "kind": "Variable",
  "name": "school",
  "variableName": "school"
},
v6 = {
  "kind": "Variable",
  "name": "startingYear",
  "variableName": "startingYear"
},
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "LessonStageNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LessonStageNode",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "stage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lessonDuration",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lessonType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "costPerLesson",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__id",
        "storageKey": null
      }
    ]
  }
],
v8 = [
  (v4/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  (v5/*: any*/),
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoadLessonStagesQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "lessonStages",
            "args": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "concreteType": "LessonStageNodeConnection",
            "kind": "LinkedField",
            "name": "__LoadLessonStages_query_lessonStages_connection",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "LoadLessonStagesQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "LessonStageNodeConnection",
            "kind": "LinkedField",
            "name": "lessonStages",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v8/*: any*/),
            "filters": [
              "school",
              "startingYear",
              "block"
            ],
            "handle": "connection",
            "key": "LoadLessonStages_query_lessonStages",
            "kind": "LinkedHandle",
            "name": "lessonStages"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "0fc12493298853f4f8af3d236f05ac50",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "lessonStages"
          ]
        }
      ]
    },
    "name": "LoadLessonStagesQuery",
    "operationKind": "query",
    "text": "query LoadLessonStagesQuery(\n  $school: String\n  $startingYear: Int!\n  $block: Int!\n  $skip: Boolean!\n) {\n  lessonStages(school: $school, startingYear: $startingYear, block: $block, first: 20) @skip(if: $skip) {\n    edges {\n      node {\n        id\n        stage\n        lessonDuration\n        lessonType\n        costPerLesson\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "474263393d4175a11dedba7da3538588";

export default node;
