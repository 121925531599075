/**
 * @generated SignedSource<<b6507e380746c671584b2109a13d4301>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnrolmentInput = {
  block: number;
  clientMutationId?: string | null;
  enrolmentId: string;
  startingYear: number;
};
export type EnrolmentMutation$variables = {
  input: EnrolmentInput;
};
export type EnrolmentMutation$data = {
  readonly enrolment: {
    readonly enrolledProfileId: string | null;
    readonly enrolment: {
      readonly addedOn: string | null;
      readonly adminNotes: string | null;
      readonly costPerLesson: number | null;
      readonly discountPercentage: number | null;
      readonly enroled: boolean;
      readonly id: string;
      readonly instrument: string | null;
      readonly lessonBlockNotes: string | null;
      readonly lessonDay: number | null;
      readonly lessonDuration: number | null;
      readonly lessonStage: string | null;
      readonly lessonStartTime: string | null;
      readonly lessonType: string | null;
      readonly numberOfLessons: number | null;
      readonly offered: boolean | null;
      readonly offeredOn: string | null;
      readonly paidBySchool: boolean | null;
      readonly parentName: string | null;
      readonly parentPhoneNumber: string | null;
      readonly positionInWaitingList: number | null;
      readonly premiumPupil: boolean | null;
      readonly pupilFirstName: string | null;
      readonly pupilLastName: string | null;
      readonly pupilNotes: string | null;
      readonly schoolBorough: string | null;
      readonly schoolClass: string | null;
      readonly schoolName: string | null;
      readonly schoolPostcode: string | null;
      readonly schoolYear: number | null;
      readonly teacherEmail: string | null;
    } | null;
    readonly errors: any | null;
    readonly instrumentName: string | null;
    readonly stageId: string | null;
    readonly success: boolean | null;
  } | null;
};
export type EnrolmentMutation = {
  response: EnrolmentMutation$data;
  variables: EnrolmentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EnrolmentPayload",
    "kind": "LinkedField",
    "name": "enrolment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enrolledProfileId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "stageId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "instrumentName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EnrolmentNode",
        "kind": "LinkedField",
        "name": "enrolment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "schoolName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "schoolBorough",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "schoolPostcode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pupilFirstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pupilLastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "schoolYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "schoolClass",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "instrument",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paidBySchool",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "premiumPupil",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discountPercentage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "teacherEmail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lessonDay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lessonStartTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lessonStage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lessonType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lessonDuration",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "costPerLesson",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numberOfLessons",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parentName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parentPhoneNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pupilNotes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lessonBlockNotes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "positionInWaitingList",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "addedOn",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "enroled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "offered",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "offeredOn",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "adminNotes",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EnrolmentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EnrolmentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "dbf38084a5675dd481d248f964df952e",
    "id": null,
    "metadata": {},
    "name": "EnrolmentMutation",
    "operationKind": "mutation",
    "text": "mutation EnrolmentMutation(\n  $input: EnrolmentInput!\n) {\n  enrolment(input: $input) {\n    success\n    errors\n    enrolledProfileId\n    stageId\n    instrumentName\n    enrolment {\n      id\n      schoolName\n      schoolBorough\n      schoolPostcode\n      pupilFirstName\n      pupilLastName\n      schoolYear\n      schoolClass\n      instrument\n      paidBySchool\n      premiumPupil\n      discountPercentage\n      teacherEmail\n      lessonDay\n      lessonStartTime\n      lessonStage\n      lessonType\n      lessonDuration\n      costPerLesson\n      numberOfLessons\n      parentName\n      parentPhoneNumber\n      pupilNotes\n      lessonBlockNotes\n      positionInWaitingList\n      addedOn\n      enroled\n      offered\n      offeredOn\n      adminNotes\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "92603522295c480ebb96539e7d747e47";

export default node;
