import { graphql } from "react-relay";

export const create_account = graphql`
    mutation CreateAccountMutation(
        $input: CreateAccountInput!
        $relevantConnections: [ID!]!
        $parentConnections: [ID!]!
        $pupilConnections: [ID!]!
    ) {
        createAccount(input: $input) {
            success
            errors
            profile
                @prependNode(
                    connections: $relevantConnections
                    edgeTypeName: "ProfileNodeEdge"
                ) {
                id
                ...AccountListFragment_ProfileNode
            }
            parent
                @prependNode(
                    connections: $parentConnections
                    edgeTypeName: "ProfileNodeEdge"
                ) {
                id
                ...AccountListFragment_ProfileNode
            }
            children
                @prependNode(
                    connections: $pupilConnections
                    edgeTypeName: "ProfileNodeEdge"
                ) {
                id
                ...AccountListFragment_ProfileNode
            }
        }
    }
`;
