import AsyncStorage from "@react-native-async-storage/async-storage";
import { setItemAsync, getItemAsync, deleteItemAsync } from "expo-secure-store";

export const save = async (key: string, value: string): Promise<void> => {
    await setItemAsync(key, value);
};

export async function remove(key: string): Promise<void> {
    await deleteItemAsync(key);
}

export async function getValueFor(key: string): Promise<string> {
    return (await getItemAsync(key)) ?? "";
}

export const webSave = async (key: string, value: string): Promise<void> => {
    await AsyncStorage.setItem(key, value);
};

export async function webRemove(key: string): Promise<void> {
    await AsyncStorage.removeItem(key);
}

export async function webGetValueFor(key: string): Promise<string> {
    return (await AsyncStorage.getItem(key)) ?? "";
}
