/**
 * @generated SignedSource<<d25250ee7d1ac81674ea4669a1eb5ff3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateAccountInput = {
  clientMutationId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
};
export type UpdateAccountMutation$variables = {
  input: UpdateAccountInput;
};
export type UpdateAccountMutation$data = {
  readonly updateAccount: {
    readonly errors: any | null;
    readonly success: boolean | null;
  } | null;
};
export type UpdateAccountMutation = {
  response: UpdateAccountMutation$data;
  variables: UpdateAccountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateAccountPayload",
    "kind": "LinkedField",
    "name": "updateAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAccountMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAccountMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8f770418d2cb79991e203f1a3676ca46",
    "id": null,
    "metadata": {},
    "name": "UpdateAccountMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAccountMutation(\n  $input: UpdateAccountInput!\n) {\n  updateAccount(input: $input) {\n    success\n    errors\n  }\n}\n"
  }
};
})();

(node as any).hash = "172134649ae297bf702e31a9cf34f24f";

export default node;
