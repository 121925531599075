import React, {
    Suspense,
    useEffect,
    useCallback,
    useMemo,
    useRef,
} from "react";
import type { FC, ReactElement } from "react";

import { NavigationContainer } from "@react-navigation/native";
import type { LinkingOptions } from "@react-navigation/native";
import * as Linking from "expo-linking";
import { Box } from "native-base";
import { useMutation, usePreloadedQuery, useQueryLoader } from "react-relay";
import type { PreloadedQuery } from "react-relay";

import { useData } from "pianofunclub-shared/providers/DataProvider";

import { useAuth } from "../../../packages/shared/providers/AuthProvider";
import LoadingScreen from "pianofunclub-shared/screens/LoadingScreen";
import WelcomeScreen from "pianofunclub-shared/screens/WelcomeScreen";

import type {
    AutoLoginMutation,
    AutoLoginMutation$data,
} from "pianofunclub-shared/relay/graphql/auth/__generated__/AutoLoginMutation.graphql";
import type {
    RefreshTokenMutation,
    RefreshTokenMutation$data,
} from "pianofunclub-shared/relay/graphql/auth/__generated__/RefreshTokenMutation.graphql";
import { auto_login } from "pianofunclub-shared/relay/graphql/auth/AutoLogin";
import { refresh_token } from "pianofunclub-shared/relay/graphql/auth/RefreshToken";
import type { LoadSchoolsForDropdownsQuery } from "pianofunclub-shared/relay/graphql/schools/__generated__/LoadSchoolsForDropdownsQuery.graphql";
import { load_schools_for_dropdowns } from "pianofunclub-shared/relay/graphql/schools/LoadSchoolsForDropdowns";

import {
    webGetValueFor,
    webRemove,
    webSave,
} from "pianofunclub-shared/utils/store";

import AuthNavigator from "./AuthNavigator";
import type { AuthNavigatorParamList } from "./AuthNavigator";
import HubNavigator from "./HubNavigator";
import type { HubNavigatorParamList } from "./HubNavigator";

// flow adpated from: https://reactnavigation.org/docs/auth-flow/

const prefix = Linking.createURL("/");

interface Props {
    loadSchoolsForDropdownsQueryReference: PreloadedQuery<
        LoadSchoolsForDropdownsQuery,
        Record<string, unknown>
    >;
}

// disable in dev
const SPLASH_SCREEN_TIME = !__DEV__ ? 4000 : 0; // in ms

const AppNavigator = (props: Props): ReactElement => {
    const { loadSchoolsForDropdownsQueryReference } = props;

    const { authState, dispatchAuthState } = useAuth();

    const { dispatchDataState } = useData();

    const schoolsData = usePreloadedQuery<LoadSchoolsForDropdownsQuery>(
        load_schools_for_dropdowns,
        loadSchoolsForDropdownsQueryReference,
    );

    useEffect(() => {
        dispatchDataState({ input: "schools", value: schoolsData?.schools });
    }, [dispatchDataState, schoolsData?.schools]);

    const commitAutoLogin = useMutation<AutoLoginMutation>(auto_login)[0];

    const autoLogin = useCallback(
        (username: string, token: string, refreshToken: string | null) => {
            const autoLoginConfig = {
                variables: {
                    input: {
                        username: username,
                    },
                },
                onCompleted: (response: AutoLoginMutation$data) => {
                    if (response?.autoLogin?.success) {
                        webSave("token", token);
                        if (refreshToken)
                            webSave("refresh_token", refreshToken);
                        dispatchAuthState({
                            type: "ADD_TOKEN",
                            accountType:
                                response.autoLogin.user?.profile?.accountType ??
                                null,
                            token: token,
                        });
                    } else {
                        dispatchAuthState({
                            type: "REMOVE_TOKEN",
                        });
                    }
                },
            };

            commitAutoLogin(autoLoginConfig);
        },
        [commitAutoLogin, dispatchAuthState],
    );

    const commitRefreshToken =
        useMutation<RefreshTokenMutation>(refresh_token)[0];

    useEffect(() => {
        // try to fetch a refresh token from storage for auto-login
        const tryLogin = async () => {
            let refreshToken = null;

            try {
                refreshToken = await webGetValueFor("refresh_token");
                // eslint-disable-next-line no-empty
            } catch {}

            if (refreshToken) {
                commitRefreshToken({
                    variables: {
                        input: {
                            refreshToken: refreshToken,
                        },
                    },
                    onCompleted: (response: RefreshTokenMutation$data) => {
                        if (
                            response?.refreshToken?.success &&
                            response.refreshToken.token
                        ) {
                            // now need to actually authenticate the user
                            autoLogin(
                                response.refreshToken.payload.username,
                                response.refreshToken.token,
                                response.refreshToken.refreshToken,
                            );
                        } else {
                            // if the user has a token, but it's invalid
                            // remove it and go to auth screen
                            dispatchAuthState({ type: "REMOVE_TOKEN" });
                            webRemove("refresh_token");
                            webRemove("token");
                        }
                    },
                });
            } else {
                dispatchAuthState({ type: "REMOVE_TOKEN" });
            }
        };

        setTimeout(() => {
            tryLogin();
        }, SPLASH_SCREEN_TIME);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navRef = useRef(null);

    const linking: LinkingOptions<
        HubNavigatorParamList & AuthNavigatorParamList
    > = useMemo(() => {
        return {
            prefixes: [prefix],
            config: {
                screens: {
                    Login: "login",
                    Register: "register",
                    ForgotPassword: "forgot-password",
                    ResetPassword: "reset-password",
                    InvoicingHubTab: {
                        initialRouteName: "InvoicingHub",
                        screens: {
                            InvoicingHub: "invoicing",
                            Invoice: "invoicing/invoice",
                            Account: "invoicing/account",
                        },
                    },
                    AccountsHubTab: {
                        initialRouteName: "AccountsHub",
                        screens: {
                            AccountsHub: "accounts",
                            Account: "accounts/account",
                            School: "accounts/school",
                            Invoice: "accounts/invoice",
                            DefaultTermDates: "accounts/default-term-dates",
                        },
                    },
                    SchoolsHubTab: {
                        initialRouteName: "SchoolsHub",
                        screens: {
                            SchoolsHub: "schools",
                            School: "schools/school",
                            Account: "schools/account",
                            DefaultTermDates: "schools/default-term-dates",
                        },
                    },
                    ProfileHubTab: {
                        initialRouteName: "ProfileHub",
                        screens: {
                            ProfileHub: "settings",
                            ChangePassword: "settings/change-password",
                            UpdateEmail: "settings/update-email",
                            ChangeName: "settings/change-name",
                        },
                    },
                    RegistersHubTab: {
                        initialRouteName: "RegistersHub",
                        screens: {
                            RegistersHub: "registers",
                            Account: "registers/account",
                            School: "registers/school",
                            DefaultTermDates: "registers/default-term-dates",
                        },
                    },
                    TeacherRegistersHubTab: {
                        initialRouteName: "TeacherRegistersHub",
                        screens: {
                            TeacherRegistersHub: "teacher-registers",
                        },
                    },
                    TeacherTimetableHubTab: {
                        initialRouteName: "TeacherTimetableHub",
                        screens: {
                            TeacherTimetableHub: "teacher-timetable",
                        },
                    },
                    WaitingListHubTab: {
                        initialRouteName: "WaitingListHub",
                        screens: {
                            WaitingListHub: "waiting-list",
                            WaitingList: "waiting-list/enrolment",
                        },
                    },
                    PayrollHubTab: {
                        initialRouteName: "PayrollHub",
                        screens: {
                            PayrollHub: "partner-payouts",
                            Payslip: "partner-payouts/payout",
                            Payrates: "partner-payouts/payrates",
                        },
                    },
                },
            },
            subscribe(listener) {
                const onReceiveURL = ({ url }: { url: string }) => {
                    listener(url);
                };

                // Listen to incoming links
                const linkingSubscription = Linking.addEventListener(
                    "url",
                    onReceiveURL,
                );
                return () => {
                    // Clean up the event listeners
                    linkingSubscription.remove();
                };
            },
        };
    }, []);

    if (authState.isLoading) {
        return (
            <Box flex={1}>
                <WelcomeScreen />
            </Box>
        );
    }

    return (
        <Box flex={1}>
            <NavigationContainer
                ref={navRef}
                documentTitle={{
                    formatter: (options) =>
                        `PianoFunClub${
                            options?.headerTitle
                                ? " | " + options.headerTitle
                                : ""
                        }`,
                }}
                linking={linking}
            >
                {authState.userToken ? <HubNavigator /> : <AuthNavigator />}
            </NavigationContainer>
        </Box>
    );
};

const AppNavigatorWrapper: FC = () => {
    const [
        loadSchoolsForDropdownsQueryReference,
        loadSchoolsForDropdownsQuery,
    ] = useQueryLoader<LoadSchoolsForDropdownsQuery>(
        load_schools_for_dropdowns,
    );

    useEffect(() => {
        loadSchoolsForDropdownsQuery(
            { orderBy: "name", isActive: true },
            { fetchPolicy: "network-only" },
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loadSchoolsForDropdownsQueryReference == null) {
        return <LoadingScreen />;
    }

    return (
        <Suspense fallback={<LoadingScreen />}>
            <AppNavigator
                loadSchoolsForDropdownsQueryReference={
                    loadSchoolsForDropdownsQueryReference
                }
            />
        </Suspense>
    );
};

export default AppNavigatorWrapper;
