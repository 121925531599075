/**
 * @generated SignedSource<<b7b84c51891bf603e6f9448305f4a66e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LoadEnrolments_query_enrolments$data = {
  readonly enrolments: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly addedOn: string | null;
        readonly adminNotes: string | null;
        readonly costPerLesson: number | null;
        readonly discountPercentage: number | null;
        readonly enroled: boolean;
        readonly id: string;
        readonly instrument: string | null;
        readonly lessonBlockNotes: string | null;
        readonly lessonDay: number | null;
        readonly lessonDuration: number | null;
        readonly lessonStage: string | null;
        readonly lessonStartTime: string | null;
        readonly lessonType: string | null;
        readonly numberOfLessons: number | null;
        readonly offered: boolean | null;
        readonly offeredOn: string | null;
        readonly paidBySchool: boolean | null;
        readonly parentName: string | null;
        readonly parentPhoneNumber: string | null;
        readonly positionInWaitingList: number | null;
        readonly premiumPupil: boolean | null;
        readonly pupilFirstName: string | null;
        readonly pupilLastName: string | null;
        readonly pupilNotes: string | null;
        readonly schoolBorough: string | null;
        readonly schoolClass: string | null;
        readonly schoolName: string | null;
        readonly schoolPostcode: string | null;
        readonly schoolYear: number | null;
        readonly sentWaitingListEmail: boolean | null;
        readonly teacherEmail: string | null;
      } | null;
    } | null>;
  } | null;
  readonly " $fragmentType": "LoadEnrolments_query_enrolments";
};
export type LoadEnrolments_query_enrolments$key = {
  readonly " $data"?: LoadEnrolments_query_enrolments$data;
  readonly " $fragmentSpreads": FragmentRefs<"LoadEnrolments_query_enrolments">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "enrolments"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "instrument"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lessonStage"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "orderBy"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "parentEmail"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pupil"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "school"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "schoolYear"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchTerm"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./LoadEnrolmentsPaginationQuery.graphql')
    }
  },
  "name": "LoadEnrolments_query_enrolments",
  "selections": [
    {
      "alias": "enrolments",
      "args": [
        {
          "kind": "Variable",
          "name": "instrument",
          "variableName": "instrument"
        },
        {
          "kind": "Variable",
          "name": "lessonStage",
          "variableName": "lessonStage"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        },
        {
          "kind": "Variable",
          "name": "parentEmail",
          "variableName": "parentEmail"
        },
        {
          "kind": "Variable",
          "name": "pupil",
          "variableName": "pupil"
        },
        {
          "kind": "Variable",
          "name": "school",
          "variableName": "school"
        },
        {
          "kind": "Variable",
          "name": "schoolYear",
          "variableName": "schoolYear"
        },
        {
          "kind": "Variable",
          "name": "searchTerm",
          "variableName": "searchTerm"
        }
      ],
      "concreteType": "EnrolmentNodeConnection",
      "kind": "LinkedField",
      "name": "__LoadEnrolments_query_enrolments_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EnrolmentNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EnrolmentNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "schoolName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "schoolBorough",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "schoolPostcode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pupilFirstName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pupilLastName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "schoolYear",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "schoolClass",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "instrument",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "paidBySchool",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "premiumPupil",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "discountPercentage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "teacherEmail",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lessonDay",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lessonStartTime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lessonStage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lessonType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lessonDuration",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "costPerLesson",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "numberOfLessons",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "parentName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "parentPhoneNumber",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pupilNotes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lessonBlockNotes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "positionInWaitingList",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "addedOn",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "enroled",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "offered",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "offeredOn",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "adminNotes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sentWaitingListEmail",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "805324be12177c05499394f0299ceed9";

export default node;
