/**
 * @generated SignedSource<<ef4c8285e6b486f6ea1ac1c829542669>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LoadTeachersForModalQuery$variables = {
  block: number;
  orderBy?: string | null;
  school?: string | null;
  searchTerm?: string | null;
  startingYear: number;
  status?: string | null;
};
export type LoadTeachersForModalQuery$data = {
  readonly profiles: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly accountNumber: string | null;
        readonly id: string;
        readonly school: {
          readonly id: string;
          readonly name: string;
        } | null;
        readonly schoolYear: number | null;
        readonly totalLessons: number | null;
        readonly totalLessonsMarked: number | null;
        readonly user: {
          readonly email: string;
          readonly firstName: string;
          readonly id: string;
          readonly lastName: string;
        };
      } | null;
    } | null>;
  } | null;
};
export type LoadTeachersForModalQuery = {
  response: LoadTeachersForModalQuery$data;
  variables: LoadTeachersForModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "block"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "school"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startingYear"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "block",
    "variableName": "block"
  },
  {
    "kind": "Variable",
    "name": "startingYear",
    "variableName": "startingYear"
  }
],
v8 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "accountType",
        "value": "TEACHER"
      },
      {
        "kind": "Variable",
        "name": "orderBy",
        "variableName": "orderBy"
      },
      {
        "kind": "Variable",
        "name": "school",
        "variableName": "school"
      },
      {
        "kind": "Variable",
        "name": "searchTerm",
        "variableName": "searchTerm"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      }
    ],
    "concreteType": "ProfileNodeConnection",
    "kind": "LinkedField",
    "name": "profiles",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProfileNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DjangoUserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "schoolYear",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SchoolNode",
                "kind": "LinkedField",
                "name": "school",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "kind": "ScalarField",
                "name": "totalLessonsMarked",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "kind": "ScalarField",
                "name": "totalLessons",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoadTeachersForModalQuery",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "LoadTeachersForModalQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "41f25e6340ad3599e1cd6a3d4793c130",
    "id": null,
    "metadata": {},
    "name": "LoadTeachersForModalQuery",
    "operationKind": "query",
    "text": "query LoadTeachersForModalQuery(\n  $orderBy: String\n  $searchTerm: String\n  $status: String\n  $startingYear: Int!\n  $block: Int!\n  $school: String\n) {\n  profiles(orderBy: $orderBy, searchTerm: $searchTerm, status: $status, accountType: \"TEACHER\", school: $school) {\n    edges {\n      node {\n        id\n        accountNumber\n        user {\n          id\n          email\n          firstName\n          lastName\n        }\n        schoolYear\n        school {\n          id\n          name\n        }\n        totalLessonsMarked(startingYear: $startingYear, block: $block)\n        totalLessons(startingYear: $startingYear, block: $block)\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c40f33ce15746cb0bde5ab967276f755";

export default node;
