/**
 * @generated SignedSource<<6d5ea61287524f86ecd1a6505e27b366>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type LessonBlockModelType = "ARCHIVED" | "BREAK" | "LESSON" | "%future added value";
export type LessonModelStatus = "BLANK" | "BREAK" | "LEFT_SCHOOL" | "MISSED" | "NO_LESSON" | "OTHER_TEACHER" | "PRESENT" | "PRESENT_DOUBLE" | "PUPIL_ABSENT" | "STOPPED_LESSONS" | "TEACHER_ABSENT" | "%future added value";
export type LessonStageModelLessonType = "GROUP" | "INDIVIDUAL" | "PAIRED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type LoadLessonBlocks_query_lessonBlocks$data = {
  readonly lessonBlocks: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
      readonly node: {
        readonly block: number | null;
        readonly id: string;
        readonly instrument: {
          readonly name: string | null;
        } | null;
        readonly keepFree: boolean;
        readonly lessonDay: number | null;
        readonly lessonStage: {
          readonly id: string;
          readonly lessonDuration: number | null;
          readonly lessonType: LessonStageModelLessonType;
          readonly stage: number | null;
        } | null;
        readonly lessonTime: string | null;
        readonly lessons: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly blacklistedDate: {
                readonly id: string;
                readonly reason: string | null;
              } | null;
              readonly homework: {
                readonly id: string;
              } | null;
              readonly id: string;
              readonly lessonDuration: number | null;
              readonly rearrangedTimestamp: string | null;
              readonly replacementLessonColumnIndex: number | null;
              readonly scheduledTimestamp: string | null;
              readonly staffRegisterNoteForTeacher: string | null;
              readonly status: LessonModelStatus;
              readonly teacherRegisterNoteForStaff: string | null;
              readonly weekWasRearranged: boolean | null;
            } | null;
          } | null>;
        };
        readonly pupil: {
          readonly id: string;
          readonly schoolYear: number | null;
          readonly staffNoteForTeacher: string | null;
          readonly teacherNoteForStaff: string | null;
          readonly user: {
            readonly firstName: string;
            readonly lastName: string;
          };
        } | null;
        readonly school: {
          readonly id: string;
          readonly name: string;
        } | null;
        readonly staffNoteForTeacher: string | null;
        readonly teacher: {
          readonly id: string;
          readonly user: {
            readonly firstName: string;
            readonly lastName: string;
          };
        } | null;
        readonly teacherNoteForStaff: string | null;
        readonly type: LessonBlockModelType;
      } | null;
    } | null>;
  } | null;
  readonly " $fragmentType": "LoadLessonBlocks_query_lessonBlocks";
};
export type LoadLessonBlocks_query_lessonBlocks$key = {
  readonly " $data"?: LoadLessonBlocks_query_lessonBlocks$data;
  readonly " $fragmentSpreads": FragmentRefs<"LoadLessonBlocks_query_lessonBlocks">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "lessonBlocks"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "staffNoteForTeacher",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teacherNoteForStaff",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "DjangoUserNode",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lessonDuration",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "block"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "blockType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "dayIndex"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "orderBy"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "profileId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "school"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchTerm"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startingYear"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./LoadLessonBlocksPaginationQuery.graphql')
    }
  },
  "name": "LoadLessonBlocks_query_lessonBlocks",
  "selections": [
    {
      "alias": "lessonBlocks",
      "args": [
        {
          "kind": "Variable",
          "name": "block",
          "variableName": "block"
        },
        {
          "kind": "Variable",
          "name": "blockType",
          "variableName": "blockType"
        },
        {
          "kind": "Variable",
          "name": "dayIndex",
          "variableName": "dayIndex"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        },
        {
          "kind": "Variable",
          "name": "profileId",
          "variableName": "profileId"
        },
        {
          "kind": "Variable",
          "name": "school",
          "variableName": "school"
        },
        {
          "kind": "Variable",
          "name": "searchTerm",
          "variableName": "searchTerm"
        },
        {
          "kind": "Variable",
          "name": "startingYear",
          "variableName": "startingYear"
        }
      ],
      "concreteType": "LessonBlockNodeConnection",
      "kind": "LinkedField",
      "name": "__LoadLessonBlocks_query_lessonBlocks_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LessonBlockNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "LessonBlockNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "block",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "keepFree",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lessonDay",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lessonTime",
                  "storageKey": null
                },
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SchoolNode",
                  "kind": "LinkedField",
                  "name": "school",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProfileNode",
                  "kind": "LinkedField",
                  "name": "pupil",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "schoolYear",
                      "storageKey": null
                    },
                    (v5/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProfileNode",
                  "kind": "LinkedField",
                  "name": "teacher",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v5/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "InstrumentNode",
                  "kind": "LinkedField",
                  "name": "instrument",
                  "plural": false,
                  "selections": [
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LessonStageNode",
                  "kind": "LinkedField",
                  "name": "lessonStage",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "stage",
                      "storageKey": null
                    },
                    (v6/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lessonType",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LessonNodeConnection",
                  "kind": "LinkedField",
                  "name": "lessons",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "LessonNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "LessonNode",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "scheduledTimestamp",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "rearrangedTimestamp",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "status",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "teacherRegisterNoteForStaff",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "staffRegisterNoteForTeacher",
                              "storageKey": null
                            },
                            (v6/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "weekWasRearranged",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "replacementLessonColumnIndex",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "BlacklistedDateNode",
                              "kind": "LinkedField",
                              "name": "blacklistedDate",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "reason",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "HomeworkNode",
                              "kind": "LinkedField",
                              "name": "homework",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "6a2c234ce95565b7e9a653d022f0c651";

export default node;
