import { graphql } from "react-relay";

export const load_enrolment = graphql`
    query LoadEnrolmentQuery($enrolmentId: ID!) {
        enrolment(id: $enrolmentId) {
            id
            schoolName
            schoolBorough
            schoolPostcode
            pupilFirstName
            pupilLastName
            schoolYear
            schoolClass
            instrument
            paidBySchool
            premiumPupil
            discountPercentage
            teacherEmail
            lessonDay
            lessonStartTime
            lessonStage
            lessonType
            lessonDuration
            costPerLesson
            numberOfLessons
            parentName
            parentEmail
            parentPhoneNumber
            pupilNotes
            lessonBlockNotes
            positionInWaitingList
            addedOn
            enroled
            offered
            offeredOn
            adminNotes
            sentWaitingListEmail
        }
    }
`;
