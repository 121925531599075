import React from "react";
import type { ReactElement } from "react";

import { Center, Text } from "native-base";

import { WarningIcon } from "pianofunclub-shared/components/Other/Icons";

const DevBanner = (): ReactElement => {
    return (
        <Center bg="orange.500" flexDirection="row" height="50px" width="100%">
            <WarningIcon color="surface.100" mr="2" size="6" />
            <Text color="surface.100">
                {"This is a test version of Christina"}
            </Text>
        </Center>
    );
};

export default React.memo(DevBanner);
