/**
 * @generated SignedSource<<a87cdbaaacadc4e3ae94a2dc50aebca6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MatchBankPaymentsToInvoicesInput = {
  block: number;
  clientMutationId?: string | null;
  googleDriveCsvUrl: string;
  startingYear: number;
};
export type MatchBankPaymentsToInvoicesMutation$variables = {
  block: number;
  input: MatchBankPaymentsToInvoicesInput;
  startingYear: number;
};
export type MatchBankPaymentsToInvoicesMutation$data = {
  readonly matchBankPaymentsToInvoices: {
    readonly errors: any | null;
    readonly profile: {
      readonly id: string;
      readonly profileGroup: {
        readonly id: string;
        readonly matchPaymentsProgress: number | null;
      } | null;
      readonly totalAmountPaid: number | null;
      readonly totalInvoicesUnpaid: number | null;
    } | null;
    readonly success: boolean | null;
  } | null;
};
export type MatchBankPaymentsToInvoicesMutation = {
  response: MatchBankPaymentsToInvoicesMutation$data;
  variables: MatchBankPaymentsToInvoicesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "block"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startingYear"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "block",
    "variableName": "block"
  },
  {
    "kind": "Variable",
    "name": "startingYear",
    "variableName": "startingYear"
  }
],
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MatchBankPaymentsToInvoicesPayload",
    "kind": "LinkedField",
    "name": "matchBankPaymentsToInvoices",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProfileNode",
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "kind": "ScalarField",
            "name": "totalAmountPaid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "kind": "ScalarField",
            "name": "totalInvoicesUnpaid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileGroupNode",
            "kind": "LinkedField",
            "name": "profileGroup",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "matchPaymentsProgress",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MatchBankPaymentsToInvoicesMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MatchBankPaymentsToInvoicesMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "ca00cd54869348e7398551873bea70bf",
    "id": null,
    "metadata": {},
    "name": "MatchBankPaymentsToInvoicesMutation",
    "operationKind": "mutation",
    "text": "mutation MatchBankPaymentsToInvoicesMutation(\n  $input: MatchBankPaymentsToInvoicesInput!\n  $startingYear: Int!\n  $block: Int!\n) {\n  matchBankPaymentsToInvoices(input: $input) {\n    success\n    errors\n    profile {\n      id\n      totalAmountPaid(startingYear: $startingYear, block: $block)\n      totalInvoicesUnpaid(startingYear: $startingYear, block: $block)\n      profileGroup {\n        id\n        matchPaymentsProgress\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "32f47720efe6c98b316fe1cdadba6204";

export default node;
