import React, {
    Suspense,
    useMemo,
    useReducer,
    useEffect,
    useRef,
    useCallback,
    useState,
} from "react";
import type { FC, Dispatch, MutableRefObject, ReactElement } from "react";

import {
    Box,
    useToast,
    Checkbox,
    VStack,
    HStack,
    Heading,
    PresenceTransition,
    Center,
    useTheme,
    Button,
} from "native-base";
import { useWindowDimensions } from "react-native";
import { DatePickerModal } from "react-native-paper-dates";
import {
    useQueryLoader,
    usePreloadedQuery,
    usePaginationFragment,
    useMutation,
} from "react-relay";
import type { PreloadedQuery } from "react-relay";
import {
    RecyclerListView,
    LayoutProvider,
    DataProvider,
} from "recyclerlistview";

import PayratesTableRow from "../../components/ListItems/PayratesTableRow";
import LoadingBlobs from "pianofunclub-shared/components/Animations/LoadingBlobs";
import Row from "pianofunclub-shared/components/Base/Row";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import CustomFlatListSpinner from "pianofunclub-shared/components/Other/CustomFlatListSpinner";
import {
    AddCircleIcon,
    PayrateIcon,
    TrashIcon,
} from "pianofunclub-shared/components/Other/Icons";

import type {
    CreatePayrateMutation,
    CreatePayrateMutation$data,
} from "pianofunclub-shared/relay/graphql/payslips/__generated__/CreatePayrateMutation.graphql";
import type {
    DeletePayratesMutation,
    DeletePayratesMutation$data,
} from "pianofunclub-shared/relay/graphql/payslips/__generated__/DeletePayratesMutation.graphql";
import type {
    EditPayrateMutation,
    EditPayrateMutation$data,
} from "pianofunclub-shared/relay/graphql/payslips/__generated__/EditPayrateMutation.graphql";
import type {
    LoadPayrates_query_payrates$key,
    LoadPayrates_query_payrates$data,
} from "pianofunclub-shared/relay/graphql/payslips/__generated__/LoadPayrates_query_payrates.graphql";
import type { LoadPayratesPaginationQuery } from "pianofunclub-shared/relay/graphql/payslips/__generated__/LoadPayratesPaginationQuery.graphql";
import type { LoadPayratesQuery } from "pianofunclub-shared/relay/graphql/payslips/__generated__/LoadPayratesQuery.graphql";
import { create_payrate } from "pianofunclub-shared/relay/graphql/payslips/CreatePayrate";
import { delete_payrates } from "pianofunclub-shared/relay/graphql/payslips/DeletePayrates";
import { edit_payrate } from "pianofunclub-shared/relay/graphql/payslips/EditPayrate";
import {
    load_payrates,
    load_payrates_pagination,
} from "pianofunclub-shared/relay/graphql/payslips/LoadPayrates";

import type { Mutable } from "pianofunclub-shared/types";
import {
    getScaledWindowDimension,
    isNumeric,
} from "pianofunclub-shared/utils/converters";
import { ensureDateTypeIfDefined } from "pianofunclub-shared/utils/helpers";
import { createReducer } from "pianofunclub-shared/utils/reducers";
import type { Action, State } from "pianofunclub-shared/utils/reducers";

import type {
    PayrollStackNavigatorProps,
    PayrollStackRouteProps,
} from "pianofunclub-crm/navigation/PayrollNavigator";

import SideBar from "pianofunclub-crm/components/Drawers/SideBar";

export type NavigationProps = PayrollStackNavigatorProps<"Payrates">;
export type RouteProps = PayrollStackRouteProps<"Payrates">;

interface ScreenProps {
    navigation: NavigationProps;
    route: RouteProps;
}

interface ContentProps {
    dispatchState: Dispatch<Action<ReducerValues, ReducerTypes>>;
    editPayrate: (variables: {
        hourlyRate?: number;
        payrateId: string;
        startDate?: Date;
    }) => void;
    loadPayratesQueryReference: PreloadedQuery<
        LoadPayratesQuery,
        Record<string, unknown>
    >;
    navigation: NavigationProps;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payrateRefs: MutableRefObject<Map<any, any>>;
    route: RouteProps;
    state: State<ReducerValues>;
}

export type PayrateData = NonNullable<
    LoadPayrates_query_payrates$data["payrates"]
>["edges"][0];

type PayrateModalInfo = {
    datePickerModalIsOpen?: boolean;
    editingPayrateId?: string;
    initialDate?: Date;
};

export type ReducerValues = {
    dataProvider: DataProvider;
    payrateIds: string[];
    payrateModalInfo?: PayrateModalInfo;
    payratesConnectionId?: string | undefined;
    selectedPayrateIds: string[];
};

export type ReducerTypes =
    | string
    | string[]
    | DataProvider
    | PayrateModalInfo
    | undefined;

const SIDE_BAR_WIDTH = 12;

const TABLE_BORDER_COLOR = "surface.400";
const TABLE_BORDER_WIDTH = 1;
const TABLE_BORDER_RADIUS = 10;
const FLEX_ARRAY = [0.2, 2, 2, 1];

export const LOAD_X_PAYRATES = 50;

const PayratesContent: FC<ContentProps> = (props) => {
    const {
        dispatchState,
        editPayrate,
        loadPayratesQueryReference,
        payrateRefs,
        state,
    } = props;

    const data = usePreloadedQuery(load_payrates, loadPayratesQueryReference);

    const {
        data: payratesData,
        hasNext,
        isLoadingNext,
        loadNext,
    } = usePaginationFragment<
        LoadPayratesPaginationQuery,
        LoadPayrates_query_payrates$key
    >(load_payrates_pagination, data);

    useEffect(() => {
        dispatchState({
            input: "payratesConnectionId",
            value: payratesData.payrates?.__id,
        });

        if (payratesData.payrates?.edges?.length) {
            const mutablePayrates = [...payratesData.payrates.edges] as Mutable<
                typeof payratesData.payrates.edges
            >;
            dispatchState({
                input: "dataProvider",
                value: state.values.dataProvider.cloneWithRows(
                    mutablePayrates.sort(
                        (a, b) =>
                            (ensureDateTypeIfDefined(
                                b?.node?.startDate,
                            )?.getTime() ?? 0) -
                            (ensureDateTypeIfDefined(
                                a?.node?.startDate,
                            )?.getTime() ?? 0),
                    ),
                ),
            });

            dispatchState({
                input: "payrateIds",
                value: mutablePayrates?.map((edge) => edge?.node?.id ?? ""),
            });
            // if there is no data, create a fresh DataProvider
        } else {
            dispatchState({
                input: "dataProvider",
                value: new DataProvider((r1, r2) => {
                    return r1 !== r2;
                }),
            });
            dispatchState({
                input: "payrateIds",
                value: [],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payratesData.payrates?.edges, payratesData.payrates?.__id]);

    const { scale, width: windowWidth } = useWindowDimensions();

    const { colors } = useTheme();

    const [layoutProvider, setLayoutProvider] = useState(
        new LayoutProvider(
            () => "VSEL",
            (type, dim) => {
                switch (type) {
                    case "VSEL":
                        dim.width = getScaledWindowDimension(
                            windowWidth,
                            scale,
                        );
                        dim.height = 40;
                        break;
                    default:
                        dim.width = 0;
                        dim.height = 0;
                }
            },
        ),
    );

    const onEndReached = useCallback(() => {
        if (hasNext && !isLoadingNext) {
            loadNext(LOAD_X_PAYRATES);
        }
    }, [hasNext, isLoadingNext, loadNext]);

    const renderCount = useRef(0);

    useEffect(() => {
        if (renderCount.current < 1) {
            renderCount.current += 1;
            return;
        }
        // this allows the RLV to resize when the window resizes
        setLayoutProvider(
            new LayoutProvider(
                () => "VSEL",
                (type, dim) => {
                    switch (type) {
                        case "VSEL":
                            dim.width = windowWidth;
                            dim.height = 40;
                            break;
                        default:
                            dim.width = 0;
                            dim.height = 0;
                    }
                },
            ),
        );
    }, [windowWidth]);

    const onPressDate = useCallback(
        (variables: { date: Date | undefined; payrateId: string }) => {
            dispatchState({
                input: "payrateModalInfo",
                value: {
                    datePickerModalIsOpen: true,
                    editingPayrateId: variables.payrateId,
                    initialDate: variables.date,
                },
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const checkboxChangeHandler = useCallback(
        (payrateId: string, isSelected: boolean) => {
            dispatchState({
                input: (values) => {
                    return {
                        ...values,
                        selectedPayrateIds: isSelected
                            ? [...values.selectedPayrateIds, payrateId]
                            : values.selectedPayrateIds.filter(
                                  (id) => id !== payrateId,
                              ),
                    };
                },
            });
        },
        [dispatchState],
    );

    const hourlyRateFinishEditingHandler = useCallback(
        (id?: string | number, inputValue?: string | undefined) => {
            if (
                typeof id === "string" &&
                typeof inputValue !== "undefined" &&
                isNumeric(inputValue)
            ) {
                editPayrate({
                    hourlyRate: parseFloat(inputValue),
                    payrateId: id,
                });
            }
        },
        [editPayrate],
    );

    const rowRenderer = useCallback(
        (_: unknown, data: PayrateData, index: number): ReactElement | null => {
            if (!data?.node) {
                return null;
            }

            return (
                <Box
                    borderColor={TABLE_BORDER_COLOR}
                    borderLeftWidth={TABLE_BORDER_WIDTH}>
                    <PayratesTableRow
                        ref={(ref) => {
                            if (
                                ref &&
                                data.node?.id &&
                                !payrateRefs.current.get(data.node.id)
                            ) {
                                payrateRefs.current.set(data.node.id, ref);
                            }
                        }}
                        cellProps={{
                            hideTopBorder: index === 0,
                            pressableTextProps: {
                                fontFamily: "Poppins-Regular",
                            },
                            textProps: {
                                fontSize: "md",
                                textAlign: "center",
                                fontFamily: "Poppins-Light",
                            },
                            px: 2,
                        }}
                        checkboxChangeHandler={checkboxChangeHandler}
                        data={data.node}
                        flexArray={FLEX_ARRAY}
                        hourlyRateFinishEditingHandler={
                            hourlyRateFinishEditingHandler
                        }
                        nextDateFrom={ensureDateTypeIfDefined(
                            state.values.dataProvider.getDataForIndex(index - 1)
                                ?.node?.startDate,
                        )}
                        onPressDate={onPressDate}
                        rowHeight={10}
                        tableBorderColor={TABLE_BORDER_COLOR}
                        tableBorderWidth={TABLE_BORDER_WIDTH}
                    />
                </Box>
            );
        },
        [
            checkboxChangeHandler,
            hourlyRateFinishEditingHandler,
            onPressDate,
            payrateRefs,
            state.values.dataProvider,
        ],
    );

    const renderFooter = useCallback(() => {
        return isLoadingNext ? (
            <CustomFlatListSpinner
                borderColor={TABLE_BORDER_COLOR}
                borderTopWidth={1}
                position="relative"
                pt="8"
                top="0"
            />
        ) : null;
    }, [isLoadingNext]);

    return (
        <>
            {state.values.dataProvider.getSize() > 0 ? (
                <RecyclerListView
                    canChangeSize
                    dataProvider={state.values.dataProvider}
                    layoutProvider={layoutProvider}
                    onEndReached={onEndReached}
                    renderFooter={renderFooter}
                    rowRenderer={rowRenderer}
                    scrollViewProps={{
                        contentContainerStyle: {
                            borderColor:
                                // @ts-expect-error can't index with variable
                                colors?.[TABLE_BORDER_COLOR.split(".")[0]][
                                    TABLE_BORDER_COLOR.split(".")[1]
                                ],
                            borderBottomWidth:
                                payratesData.payrates?.edges.length &&
                                !isLoadingNext
                                    ? TABLE_BORDER_WIDTH
                                    : 0,
                            marginBottom: 40,
                            overflow: "hidden",
                            borderBottomRadius: TABLE_BORDER_RADIUS,
                        },
                        showsVerticalScrollIndicator: false,
                    }}
                    style={{
                        flex: 1,
                        minHeight: 1,
                    }}
                    suppressBoundedSizeException
                    useWindowScroll
                />
            ) : null}
        </>
    );
};

const PayratesScreen: FC<ScreenProps> = (props) => {
    const { navigation } = props;

    const initialState = useMemo(() => {
        // select the current day on the teacher register tab
        return {
            values: {
                payrateIds: [] as string[],
                selectedPayrateIds: [] as string[],
                dataProvider: new DataProvider((r1, r2) => {
                    return r1 !== r2;
                }),
            },
        };
    }, []);

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [state, dispatchState] = useReducer(reducer, initialState);

    const [loadPayratesQueryReference, loadPayratesQuery] =
        useQueryLoader<LoadPayratesQuery>(load_payrates);

    useEffect(() => {
        loadPayratesQuery(
            {
                first: LOAD_X_PAYRATES,
            },
            { fetchPolicy: "store-or-network" },
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadPayratesQueryReference]);

    const toast = useToast();

    const payrateRefs = useRef(new Map());

    const [commitCreatePayrate] =
        useMutation<CreatePayrateMutation>(create_payrate);

    const createPayrate = useCallback(
        (variables: { startDate: Date }) => {
            const createPayrateConfig = {
                variables: {
                    connections: state.values.payratesConnectionId
                        ? [state.values.payratesConnectionId]
                        : [],
                    input: {
                        startDate: variables.startDate.toDateString(),
                    },
                },
                onCompleted: (response: CreatePayrateMutation$data) => {
                    if (!response.createPayrate?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't create payrate"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitCreatePayrate(createPayrateConfig);
        },
        [commitCreatePayrate, state.values.payratesConnectionId, toast],
    );

    const [commitEditPayrate] = useMutation<EditPayrateMutation>(edit_payrate);

    const editPayrate = useCallback(
        (variables: {
            hourlyRate?: number;
            payrateId: string;
            startDate?: Date;
        }) => {
            const editPayrateConfig = {
                variables: {
                    input: {
                        payrateId: variables.payrateId,
                        startDate: variables.startDate?.toDateString(),
                        hourlyRate: variables.hourlyRate,
                    },
                },
                onCompleted: (response: EditPayrateMutation$data) => {
                    if (!response?.editPayrate?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't edit payrate"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitEditPayrate(editPayrateConfig);
        },
        [commitEditPayrate, toast],
    );

    const [commitDeletePayrates] =
        useMutation<DeletePayratesMutation>(delete_payrates);

    const deletePayrates = useCallback(
        (variables: { payrateIds: string[] }) => {
            const deletePayratesConfig = {
                variables: {
                    connections: state.values.payratesConnectionId
                        ? [state.values.payratesConnectionId]
                        : [],
                    input: {
                        payrateIds: variables.payrateIds,
                    },
                },
                optimisticResponse: {
                    deletePayrates: {
                        success: true,
                        deletedpayrateIds: variables.payrateIds,
                    },
                },
                onCompleted: (response: DeletePayratesMutation$data) => {
                    dispatchState({
                        input: "selectedPayrateIds",
                        value: [],
                    });
                    payrateRefs.current.forEach((ref) => {
                        ref.setIsChecked(false);
                    });

                    if (!response?.deletePayrates?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't delete payrates"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitDeletePayrates(deletePayratesConfig);
        },
        [commitDeletePayrates, state.values.payratesConnectionId, toast],
    );

    const selectAllCheckboxChangeHandler = useCallback(
        (isSelected: boolean) => {
            payrateRefs.current.forEach((ref) => {
                ref.setIsChecked(isSelected);
            });
            dispatchState({
                input: "selectedPayrateIds",
                value: isSelected ? state.values.payrateIds : [],
            });
        },
        [state.values.payrateIds],
    );

    const selectDateHandler = useCallback(
        (params: { date?: Date }) => {
            if (!params.date) {
                return;
            }

            if (!state.values.payrateModalInfo?.editingPayrateId) {
                createPayrate({
                    startDate: params.date,
                });
                dispatchState({
                    input: "payrateModalInfo",
                    value: undefined,
                });
            } else {
                editPayrate({
                    payrateId: state.values.payrateModalInfo?.editingPayrateId,
                    startDate: params.date,
                });
                dispatchState({
                    input: "payrateModalInfo",
                    value: undefined,
                });
            }
        },
        [
            createPayrate,
            editPayrate,
            state.values.payrateModalInfo?.editingPayrateId,
        ],
    );

    const tableHeaders = useMemo(() => {
        return [
            {
                data: (
                    <Checkbox
                        isChecked={
                            state.values.selectedPayrateIds.length != 0 &&
                            state.values.payrateIds.length <=
                                state.values.selectedPayrateIds.length &&
                            state.values.payrateIds.every((id) =>
                                state.values.selectedPayrateIds.includes(id),
                            )
                        }
                        onChange={selectAllCheckboxChangeHandler}
                        size="md"
                        value="SELECT_ALL"
                    />
                ),
                onPress: () => {
                    return;
                },
            },
            {
                data: "Date From",
            },
            {
                data: "Date To",
            },
            {
                data: "Hourly Rate",
            },
        ];
    }, [
        selectAllCheckboxChangeHandler,
        state.values.payrateIds,
        state.values.selectedPayrateIds,
    ]);

    const renderHeader = useMemo(() => {
        return (
            <VStack mx="-6" px="6" space="4">
                <HStack ml="1" space="3">
                    <PayrateIcon color="primary.600" size="xl" />
                    <Heading color="primary.600" fontSize="xl">
                        {"Payrates"}
                    </Heading>
                </HStack>
                <HStack
                    justifyContent="space-between"
                    mb="4"
                    minWidth={800}
                    width={"50%"}>
                    <Button
                        _hover={{ bg: "primary.500" }}
                        _pressed={{ bg: "primary.600" }}
                        _text={{ fontSize: "17" }}
                        bg="primary.400"
                        leftIcon={<AddCircleIcon size="md" />}
                        onPress={() => {
                            dispatchState({
                                input: "payrateModalInfo",
                                value: {
                                    datePickerModalIsOpen: true,
                                },
                            });
                        }}
                        px="4"
                        shadow={1}>
                        Create New Payrate
                    </Button>
                    <PresenceTransition
                        animate={{
                            opacity: 1,
                            scale: 1,
                            transition: {
                                duration: 250,
                            },
                        }}
                        initial={{
                            opacity: 0,
                            scale: 0,
                        }}
                        visible={state.values.selectedPayrateIds.length > 0}>
                        <Button
                            _text={{ fontSize: "17" }}
                            colorScheme="error"
                            leftIcon={
                                <Center size="5">
                                    <TrashIcon color="surface.100" size="5" />
                                </Center>
                            }
                            onPress={() =>
                                deletePayrates({
                                    payrateIds: state.values.selectedPayrateIds,
                                })
                            }
                            pr="4"
                            shadow={1}>
                            {`Delete ${
                                state.values.selectedPayrateIds.length
                            } Date${
                                state.values.selectedPayrateIds.length !== 1
                                    ? "s"
                                    : ""
                            }`}
                        </Button>
                    </PresenceTransition>
                </HStack>
                <Box bg="surface.100" minWidth={800} width={"50%"}>
                    <Box
                        bg="primary.50"
                        borderColor={TABLE_BORDER_COLOR}
                        borderTopRadius={TABLE_BORDER_RADIUS}
                        borderWidth={TABLE_BORDER_WIDTH}>
                        <Row
                            cellProps={{
                                px: 2,
                                hideTopBorder: true,
                                hideOuterSideBorder: true,
                                textProps: {
                                    fontSize: "md",
                                    textAlign: "center",
                                },
                            }}
                            data={tableHeaders}
                            flexArray={FLEX_ARRAY}
                            rowHeight={10}
                            rowIndex={0}
                            tableBorderColor={TABLE_BORDER_COLOR}
                            tableBorderWidth={TABLE_BORDER_WIDTH}
                        />
                    </Box>
                </Box>
            </VStack>
        );
    }, [deletePayrates, state.values.selectedPayrateIds, tableHeaders]);

    const renderDatePickerModal = useMemo(() => {
        return (
            <DatePickerModal
                animationType="fade"
                date={state.values.payrateModalInfo?.initialDate ?? new Date()}
                endYear={new Date().getFullYear() + 1}
                label="Select Start Date"
                locale="en"
                mode="single"
                onConfirm={selectDateHandler}
                onDismiss={() =>
                    dispatchState({
                        input: "payrateModalInfo",
                        value: undefined,
                    })
                }
                saveLabel={
                    state.values.payrateModalInfo?.editingPayrateId
                        ? "Update"
                        : "Select"
                }
                startYear={2022}
                uppercase={false}
                visible={Boolean(
                    state.values.payrateModalInfo?.datePickerModalIsOpen,
                )}
            />
        );
    }, [
        selectDateHandler,
        state.values.payrateModalInfo?.datePickerModalIsOpen,
        state.values.payrateModalInfo?.editingPayrateId,
        state.values.payrateModalInfo?.initialDate,
    ]);

    return (
        <Box bg="surface.100" flex={1}>
            <SideBar
                isCollapsed
                navigation={navigation}
                width={SIDE_BAR_WIDTH}
            />
            <Box flex={1} ml={SIDE_BAR_WIDTH} mt="70" pt="6" px="6">
                {renderHeader}
                <Box minWidth={800} width={"50%"}>
                    {loadPayratesQueryReference ? (
                        <Suspense
                            fallback={
                                <LoadingBlobs minHeight={200}>
                                    Loading Payrates...
                                </LoadingBlobs>
                            }>
                            <PayratesContent
                                {...props}
                                dispatchState={dispatchState}
                                editPayrate={editPayrate}
                                loadPayratesQueryReference={
                                    loadPayratesQueryReference
                                }
                                payrateRefs={payrateRefs}
                                state={state}
                            />
                        </Suspense>
                    ) : (
                        <LoadingBlobs minHeight={200}>
                            Loading Payrates...
                        </LoadingBlobs>
                    )}
                </Box>
                {renderDatePickerModal}
            </Box>
        </Box>
    );
};

export const screenOptions = {
    headerTitle: "Payrates",
};

export default PayratesScreen;
