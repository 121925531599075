import { graphql } from "react-relay";

export const update_registers = graphql`
    mutation UpdateRegistersMutation($input: UpdateRegistersInput!) {
        updateRegisters(input: $input) {
            success
            errors
            lessons {
                id
                status
                teacherRegisterNoteForStaff
                staffRegisterNoteForTeacher
                lessonDuration
            }
            pupilsToArchive {
                id
                user {
                    firstName
                    lastName
                }
            }
        }
    }
`;
