/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { createContext, useContext } from "react";

import { useId } from "@react-native-aria/utils";
import isNil from "lodash.isnil";
import omit from "lodash.omit";
import omitBy from "lodash.omitby";
import pick from "lodash.pick";
import type { IFormControlProps } from "native-base";

// Native Base utility functions for creating customised components

export const stylingProps = {
    margin: [
        "margin",
        "m",
        "marginTop",
        "mt",
        "marginRight",
        "mr",
        "marginBottom",
        "mb",
        "marginLeft",
        "ml",
        "marginX",
        "mx",
        "marginY",
        "my",
    ],
    padding: [
        "padding",
        "p",
        "paddingTop",
        "pt",
        "paddingRight",
        "pr",
        "paddingBottom",
        "pb",
        "paddingLeft",
        "pl",
        "paddingX",
        "px",
        "paddingY",
        "py",
    ],
    border: [
        "border",
        "borderWidth",
        "borderStyle",
        "borderColor",
        "borderRadius",
        "borderTop",
        "borderTopWidth",
        "borderTopStyle",
        "borderTopColor",
        "borderTopLeftRadius",
        "borderTopRightRadius",
        "borderRight",
        "borderRightWidth",
        "borderRightStyle",
        "borderRightColor",
        "borderBottom",
        "borderBottomWidth",
        "borderBottomStyle",
        "borderBottomColor",
        "borderBottomLeftRadius",
        "borderBottomRightRadius",
        "borderLeft",
        "borderLeftWidth",
        "borderLeftStyle",
        "borderLeftColor",
        "borderX",
        "borderY",
    ],
    layout: [
        "width",
        "w",
        "height",
        "h",
        "display",
        "minWidth",
        "minW",
        "minH",
        "minHeight",
        "maxWidth",
        "maxW",
        "maxHeight",
        "maxH",
        "size",
        "verticalAlign",
        "overflow",
        "overflowX",
        "overflowY",
    ],
    flexbox: [
        "alignItems",
        "alignContent",
        "justifyItems",
        "justifyContent",
        "flexWrap",
        "flexDirection",
        "flex",
        "flexGrow",
        "flexShrink",
        "flexBasis",
        "justifySelf",
        "alignSelf",
        "order",
    ],
    position: ["position", "zIndex", "top", "right", "bottom", "left"],
    background: ["bg", "backgroundColor", "bgColor"],
};

function omitUndefined(obj: any) {
    return omitBy(obj, isNil);
}

export function extractInObject(parent: any, values: Array<string>) {
    return [
        omitUndefined(pick(parent, values)),
        omitUndefined(omit(parent, values)),
    ];
}

export function mergeRefs<T = any>(
    refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>,
): React.RefCallback<T> {
    return (value) => {
        refs.forEach((ref) => {
            if (typeof ref === "function") {
                ref(value);
            } else if (ref != null) {
                (ref as React.MutableRefObject<T | null>).current = value;
            }
        });
    };
}

export function useFormControlProvider(props: IFormControlProps) {
    const {
        isDisabled,
        isInvalid,
        isReadOnly,
        isRequired,
        nativeID: idProp,
        ...htmlProps
    } = props;

    const id = useId();
    // Generate all the required ids
    const nativeID = idProp || `field-${id}`;

    const labelId = `${nativeID}-label`;
    const feedbackId = `${nativeID}-feedback`;
    const helpTextId = `${nativeID}-helptext`;

    /**
     * Track whether the `FormErrorMessage` has been rendered.
     * We use this to append its id the the `aria-describedby` of the `input`.
     */
    const [hasFeedbackText, setHasFeedbackText] = React.useState(false);

    /**
     * Track whether the `FormHelperText` has been rendered.
     * We use this to append its id the the `aria-describedby` of the `input`.
     */
    const [hasHelpText, setHasHelpText] = React.useState(false);

    const context = {
        isRequired: !!isRequired,
        isInvalid: !!isInvalid,
        isReadOnly: !!isReadOnly,
        isDisabled: !!isDisabled,
        hasFeedbackText,
        setHasFeedbackText,
        hasHelpText,
        setHasHelpText,
        nativeID,
        labelId,
        feedbackId,
        helpTextId,
        htmlProps,
    };

    return context;
}

export type IFormControlContext = Omit<
    ReturnType<typeof useFormControlProvider>,
    "htmlProps"
>;

export const FormControlContext = createContext({});

export const useFormControlContext = () => {
    return useContext(FormControlContext) as unknown as IFormControlContext;
};

export function useFormControl(props: IFormControlProps) {
    const field = useFormControlContext();
    const describedBy: any[] = [];

    // Error message must be described first in all scenarios.
    if (field?.hasFeedbackText) describedBy.push(field?.feedbackId);
    if (field?.hasHelpText) describedBy.push(field?.helpTextId);
    const ariaDescribedBy = describedBy.join(" ");

    const cleanProps = omit(props, [
        "isInvalid",
        "isDisabled",
        "isReadOnly",
        "isRequired",
    ]);

    return {
        ...cleanProps,
        nativeID: props.nativeID ?? field?.nativeID,
        disabled: props.isDisabled || field?.isDisabled,
        readOnly: props.isReadOnly || field?.isReadOnly,
        required: props.isRequired || field?.isRequired,
        accessibilityDescribedBy: ariaDescribedBy || undefined,
    };
}

export const useHover = () => {
    const [isHovered, setHovered] = React.useState(false);
    return {
        hoverProps: {
            onHoverIn: () => setHovered(true),
            onHoverOut: () => setHovered(false),
        },
        isHovered,
    };
};

export const useFocus = () => {
    const [isFocused, setFocused] = React.useState(false);
    return {
        focusProps: {
            onFocus: () => setFocused(true),
            onBlur: () => setFocused(false),
        },
        isFocused,
    };
};

export const useIsPressed = () => {
    const [isPressed, setIsPressed] = React.useState(false);
    return {
        pressableProps: {
            onPressIn: () => setIsPressed(true),
            onPressOut: () => setIsPressed(false),
        },
        isPressed,
    };
};
