/**
 * @generated SignedSource<<76245bc9fc0cd61b09c15b0c67ffb2c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EditEnrolmentInput = {
  adminNotes?: string | null;
  clientMutationId?: string | null;
  costPerLesson?: number | null;
  discountPercentage?: number | null;
  enrolmentId: string;
  instrument?: string | null;
  lessonBlockNotes?: string | null;
  lessonDay?: number | null;
  lessonDuration?: number | null;
  lessonStage?: string | null;
  lessonStartTime?: string | null;
  lessonType?: string | null;
  numberOfLessons?: number | null;
  offered?: boolean | null;
  paidBySchool?: boolean | null;
  parentEmail?: string | null;
  parentName?: string | null;
  parentPhoneNumber?: string | null;
  positionInWaitingList?: number | null;
  premiumPupil?: boolean | null;
  pupilFirstName?: string | null;
  pupilLastName?: string | null;
  pupilNotes?: string | null;
  schoolBorough?: string | null;
  schoolClass?: string | null;
  schoolName?: string | null;
  schoolPostcode?: string | null;
  schoolYear?: number | null;
  sentWaitingListEmail?: boolean | null;
  teacherEmail?: string | null;
};
export type EditEnrolmentMutation$variables = {
  input: EditEnrolmentInput;
};
export type EditEnrolmentMutation$data = {
  readonly editEnrolment: {
    readonly enrolment: {
      readonly addedOn: string | null;
      readonly adminNotes: string | null;
      readonly costPerLesson: number | null;
      readonly discountPercentage: number | null;
      readonly enroled: boolean;
      readonly id: string;
      readonly instrument: string | null;
      readonly lessonBlockNotes: string | null;
      readonly lessonDay: number | null;
      readonly lessonDuration: number | null;
      readonly lessonStage: string | null;
      readonly lessonStartTime: string | null;
      readonly lessonType: string | null;
      readonly numberOfLessons: number | null;
      readonly offered: boolean | null;
      readonly paidBySchool: boolean | null;
      readonly parentEmail: string | null;
      readonly parentName: string | null;
      readonly parentPhoneNumber: string | null;
      readonly positionInWaitingList: number | null;
      readonly premiumPupil: boolean | null;
      readonly pupilFirstName: string | null;
      readonly pupilLastName: string | null;
      readonly pupilNotes: string | null;
      readonly schoolBorough: string | null;
      readonly schoolClass: string | null;
      readonly schoolName: string | null;
      readonly schoolPostcode: string | null;
      readonly schoolYear: number | null;
      readonly sentWaitingListEmail: boolean | null;
      readonly teacherEmail: string | null;
    } | null;
    readonly errors: any | null;
    readonly success: boolean | null;
  } | null;
};
export type EditEnrolmentMutation = {
  response: EditEnrolmentMutation$data;
  variables: EditEnrolmentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EditEnrolmentPayload",
    "kind": "LinkedField",
    "name": "editEnrolment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EnrolmentNode",
        "kind": "LinkedField",
        "name": "enrolment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "schoolName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "schoolBorough",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "schoolPostcode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pupilFirstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pupilLastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "schoolYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "schoolClass",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "instrument",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paidBySchool",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "premiumPupil",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discountPercentage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "teacherEmail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lessonDay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lessonStartTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lessonStage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lessonType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lessonDuration",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "costPerLesson",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numberOfLessons",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parentName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parentEmail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parentPhoneNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pupilNotes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lessonBlockNotes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "positionInWaitingList",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "addedOn",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "enroled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "offered",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "adminNotes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sentWaitingListEmail",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditEnrolmentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditEnrolmentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e268263434987476d466e2356b02fd1e",
    "id": null,
    "metadata": {},
    "name": "EditEnrolmentMutation",
    "operationKind": "mutation",
    "text": "mutation EditEnrolmentMutation(\n  $input: EditEnrolmentInput!\n) {\n  editEnrolment(input: $input) {\n    success\n    errors\n    enrolment {\n      id\n      schoolName\n      schoolBorough\n      schoolPostcode\n      pupilFirstName\n      pupilLastName\n      schoolYear\n      schoolClass\n      instrument\n      paidBySchool\n      premiumPupil\n      discountPercentage\n      teacherEmail\n      lessonDay\n      lessonStartTime\n      lessonStage\n      lessonType\n      lessonDuration\n      costPerLesson\n      numberOfLessons\n      parentName\n      parentEmail\n      parentPhoneNumber\n      pupilNotes\n      lessonBlockNotes\n      positionInWaitingList\n      addedOn\n      enroled\n      offered\n      adminNotes\n      sentWaitingListEmail\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "13feb3526a5c24e5013ea798f1974b69";

export default node;
