/**
 * @generated SignedSource<<4c3d5214a5149917e93974ec67ef467c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveLessonBlocksInput = {
  clientMutationId?: string | null;
  isTeacherRegister?: boolean | null;
  lessonBlockIds: ReadonlyArray<string | null>;
};
export type RemoveLessonBlocksMutation$variables = {
  block: number;
  connections: ReadonlyArray<string>;
  input: RemoveLessonBlocksInput;
  startingYear: number;
};
export type RemoveLessonBlocksMutation$data = {
  readonly removeLessonBlocks: {
    readonly deletedLessonBlockIds: ReadonlyArray<string | null> | null;
    readonly errors: any | null;
    readonly success: boolean | null;
    readonly teacher: {
      readonly id: string;
      readonly totalLessons: number | null;
    } | null;
  } | null;
};
export type RemoveLessonBlocksMutation = {
  response: RemoveLessonBlocksMutation$data;
  variables: RemoveLessonBlocksMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "block"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startingYear"
},
v4 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedLessonBlockIds",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "ProfileNode",
  "kind": "LinkedField",
  "name": "teacher",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "block",
          "variableName": "block"
        },
        {
          "kind": "Variable",
          "name": "startingYear",
          "variableName": "startingYear"
        }
      ],
      "kind": "ScalarField",
      "name": "totalLessons",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveLessonBlocksMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "RemoveLessonBlocksPayload",
        "kind": "LinkedField",
        "name": "removeLessonBlocks",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "RemoveLessonBlocksMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "RemoveLessonBlocksPayload",
        "kind": "LinkedField",
        "name": "removeLessonBlocks",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteEdge",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedLessonBlockIds",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fb28d1ba8b8defc643b6f7b1523f06b2",
    "id": null,
    "metadata": {},
    "name": "RemoveLessonBlocksMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveLessonBlocksMutation(\n  $input: RemoveLessonBlocksInput!\n  $startingYear: Int!\n  $block: Int!\n) {\n  removeLessonBlocks(input: $input) {\n    success\n    errors\n    deletedLessonBlockIds\n    teacher {\n      id\n      totalLessons(startingYear: $startingYear, block: $block)\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9ddaae6073ddb5b33fb7e2b631d21302";

export default node;
