/**
 * @generated SignedSource<<53da60935741b477bfa4fcc156971367>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BulkUploadNewRegistersInput = {
  block: number;
  clientMutationId?: string | null;
  googleDriveNewRegistersUrl: string;
  startingYear: number;
};
export type BulkUploadNewRegistersMutation$variables = {
  input: BulkUploadNewRegistersInput;
};
export type BulkUploadNewRegistersMutation$data = {
  readonly bulkUploadNewRegisters: {
    readonly erroredSchool: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly errors: any | null;
    readonly profileGroup: {
      readonly bulkUploadNewRegistersProgress: number | null;
      readonly id: string;
    } | null;
    readonly success: boolean | null;
  } | null;
};
export type BulkUploadNewRegistersMutation = {
  response: BulkUploadNewRegistersMutation$data;
  variables: BulkUploadNewRegistersMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BulkUploadNewRegistersPayload",
    "kind": "LinkedField",
    "name": "bulkUploadNewRegisters",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SchoolNode",
        "kind": "LinkedField",
        "name": "erroredSchool",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProfileGroupNode",
        "kind": "LinkedField",
        "name": "profileGroup",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bulkUploadNewRegistersProgress",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BulkUploadNewRegistersMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BulkUploadNewRegistersMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "cadd3e60cefc54026acd2880b016be4a",
    "id": null,
    "metadata": {},
    "name": "BulkUploadNewRegistersMutation",
    "operationKind": "mutation",
    "text": "mutation BulkUploadNewRegistersMutation(\n  $input: BulkUploadNewRegistersInput!\n) {\n  bulkUploadNewRegisters(input: $input) {\n    success\n    errors\n    erroredSchool {\n      id\n      name\n    }\n    profileGroup {\n      id\n      bulkUploadNewRegistersProgress\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0bcb1eb66a6d9a3a348adc1b7b4ee635";

export default node;
