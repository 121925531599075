import { graphql } from "react-relay";

export const update_lesson_block_details = graphql`
    mutation UpdateLessonBlockDetailsMutation(
        $connections: [ID!]!
        $input: UpdateLessonBlockDetailsInput!
        $startingYear: Int!
        $block: Int!
    ) {
        updateLessonBlockDetails(input: $input) {
            success
            errors
            deletedLessonBlockIds @deleteEdge(connections: $connections)
            lessonBlocks {
                id
                keepFree
                lessonDay
                lessonTime
                staffNoteForTeacher
                teacherNoteForStaff
                pupil {
                    id
                    schoolYear
                    user {
                        firstName
                        lastName
                    }
                }
                teacher {
                    id
                    totalLessons(startingYear: $startingYear, block: $block)
                    user {
                        firstName
                        lastName
                    }
                }
                instrument {
                    name
                }
                lessonStage {
                    id
                    stage
                    lessonDuration
                    lessonType
                }
                lessons {
                    edges {
                        node {
                            id
                            scheduledTimestamp
                            rearrangedTimestamp
                            status
                            teacherRegisterNoteForStaff
                            staffRegisterNoteForTeacher
                            lessonDuration
                            weekWasRearranged
                            replacementLessonColumnIndex
                        }
                    }
                }
            }
            newPairedLessonBlocks {
                id
                keepFree
                lessonDay
                lessonTime
                type
                school {
                    id
                    name
                }
                pupil {
                    id
                    schoolYear
                    user {
                        firstName
                        lastName
                    }
                }
                teacher {
                    id
                    user {
                        firstName
                        lastName
                    }
                }
                instrument {
                    name
                }
                lessonStage {
                    id
                    stage
                    lessonDuration
                    lessonType
                }
                lessons {
                    edges {
                        node {
                            id
                            scheduledTimestamp
                            rearrangedTimestamp
                            status
                            teacherRegisterNoteForStaff
                            staffRegisterNoteForTeacher
                            lessonDuration
                            weekWasRearranged
                            replacementLessonColumnIndex
                        }
                    }
                }
            }
        }
    }
`;
