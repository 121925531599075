import React, {
    useEffect,
    useMemo,
    useCallback,
    useReducer,
    useRef,
    forwardRef,
    useImperativeHandle,
} from "react";
import type { ReactElement, ComponentProps } from "react";

import { Input, Tooltip, FormControl } from "native-base";
import type { Text } from "native-base";

import { formatCurrency } from "../../utils/converters";
import { useDebounceState } from "../../utils/hooks";
import { currencyMask, currencyFinishEditMask } from "../../utils/inputs";
import { createInputReducer } from "../../utils/reducers";

type InputProps = ComponentProps<typeof Input>;
type TextProps = ComponentProps<typeof Text>;

interface Props extends InputProps {
    autoFocus?: boolean;
    centerLabel?: boolean;
    floatingErrorMessage?: string;
    floatingErrorOffset?: number;
    id: string;
    initialValue?: number | null;
    initiallyValid?: boolean;
    invalidIndicator?: boolean;
    label?: string;
    labelStyle?: TextProps;
    onFinishEditing?: (
        inputIdentifier: string | number,
        inputValue?: string,
        isValid?: boolean,
    ) => void | string;
    onInputChange?: (
        inputIdentifier: string | number,
        inputValue?: string,
        isValid?: boolean,
    ) => void;
    onPress?: (
        inputIdentifier: string | number,
        inputValue?: string,
        isValid?: boolean,
    ) => void;
    onSubmit?: (
        id?: string | number,
        inputValue?: string,
        isValid?: boolean,
    ) => void;
    required?: boolean;
    updateValueOnInitialValueChange?: boolean;
}

const CurrencyInput = forwardRef((props: Props, ref): ReactElement => {
    const {
        autoFocus,
        centerLabel,
        floatingErrorMessage,
        floatingErrorOffset,
        id,
        initialValue,
        onFinishEditing,
        onInputChange,
        onPress,
        onSubmit,
        updateValueOnInitialValueChange,
    } = props;

    const initialCurrency = useMemo(() => {
        // for duration inputs (initialValue in seconds)
        return formatCurrency(initialValue);
    }, [initialValue]);

    const initialRender = useRef(true);

    const inputReducer = createInputReducer();
    const [inputState, dispatch] = useReducer(inputReducer, {
        value: initialCurrency,
        isValid: props?.initiallyValid ?? false,
        touched: false,
        focussed: autoFocus ? true : false,
        hasBeenBlurred: false,
    });

    const {
        debounce,
        setDebouncedState: setDebouncedCurrency,
        state: debouncedCurrency,
    } = useDebounceState(inputState.value, 250);

    const inputRef = useRef<{
        blur: () => void;
        clear: () => void;
        focus: () => void;
    }>();
    useImperativeHandle(ref, () => ({
        focus: () => {
            inputRef.current?.focus();
        },
        blur: () => {
            inputRef.current?.blur();
        },
        setValue: (value: number, isValid: boolean) => {
            dispatch({
                type: "INPUT_CHANGE_NO_FOCUS",
                value: formatCurrency(value),
                isValid: isValid,
            });
        },
        clear: () => {
            inputRef.current?.clear();
            dispatch({
                type: "INPUT_CHANGE",
                value: "",
                isValid: !props.required,
            });
        },
    }));

    useEffect(() => {
        if (updateValueOnInitialValueChange) {
            dispatch({
                type: "INPUT_CHANGE_NO_FOCUS",
                value: initialCurrency,
                isValid: initialCurrency ? true : !props.required,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialCurrency]);

    useEffect(() => {
        if (inputState.touched && onInputChange) {
            onInputChange(id, inputState.value, inputState.isValid);
        }
    }, [inputState, onInputChange, id]);

    useEffect((): void => {
        if (initialRender.current) {
            initialRender.current = false;
            return;
        }

        dispatch({
            type: "INPUT_CHANGE_NO_FOCUS",
            value: debouncedCurrency,
            isValid: true,
        });
    }, [debouncedCurrency]);

    useEffect((): (() => void) => {
        setDebouncedCurrency(inputState.value);

        return () => {
            debounce.cancel();
        };
    }, [debounce, inputState.value, setDebouncedCurrency]);

    const textChangeHandler = useCallback((text: string) => {
        dispatch({
            type: "INPUT_CHANGE",
            value: currencyMask(text),
            isValid: true,
        });
    }, []);

    const onFocusHandler = useCallback(() => {
        dispatch({ type: "INPUT_FOCUS" });
        onPress?.(id, inputState.value, inputState.isValid);
    }, [id, inputState.isValid, inputState.value, onPress]);

    const lostFocusHandler = useCallback(() => {
        dispatch({
            type: "INPUT_CHANGE",
            value: currencyFinishEditMask(inputState.value),
            isValid: inputState.isValid,
        });
        const newValue = onFinishEditing?.(
            id,
            inputState.value.replace("£", ""),
            inputState.isValid,
        );
        if (newValue) {
            dispatch({
                type: "INPUT_CHANGE",
                value: newValue,
                isValid: true,
            });
        }
        dispatch({ type: "INPUT_BLUR" });
    }, [id, inputState.isValid, inputState.value, onFinishEditing]);

    const onSubmitHandler = useCallback(() => {
        dispatch({ type: "INPUT_BLUR" });
        onSubmit?.(id, inputState.value, inputState.isValid);
    }, [id, inputState.isValid, inputState.value, onSubmit]);

    return (
        <FormControl
            isInvalid={
                (props?.invalidIndicator ?? false) &&
                !inputState.isValid &&
                inputState.touched
            }
            isRequired={props?.required ?? false}>
            {props?.label ? (
                <FormControl.Label
                    _text={{
                        fontFamily: "Poppins-SemiBold",
                        color: "surface.100",
                        fontSize: "md",
                        pl: centerLabel ? "0" : "1",
                        ...props.labelStyle,
                    }}
                    justifyContent={centerLabel ? "center" : undefined}>
                    {props.label}
                </FormControl.Label>
            ) : null}
            <Tooltip
                _text={{ color: "warmGray.500" }}
                bg="surface.900"
                isOpen={
                    floatingErrorMessage
                        ? props?.invalidIndicator &&
                          inputState.focussed &&
                          !inputState.isValid
                        : false
                }
                label={floatingErrorMessage ?? ""}
                mt={floatingErrorOffset ?? 0}
                opacity={0.7}
                placement="bottom"
                px="1"
                py="0.5">
                <Input
                    ref={inputRef}
                    _disabled={{
                        bg: "transparent",
                        borderWidth: 0,
                        opacity: 1,
                        py: 0,
                    }}
                    _focus={{
                        bg: "surface.100",
                        borderColor: "surface.300",
                    }}
                    _hover={{
                        bg: "surface.100",
                        borderColor: "surface.400",
                    }}
                    autoCapitalize="none"
                    bg="surface.100"
                    borderColor="surface.200"
                    color="surface.900"
                    disableFullscreenUI={true}
                    keyboardType="number-pad"
                    onBlur={lostFocusHandler}
                    onChangeText={textChangeHandler}
                    onFocus={onFocusHandler}
                    onSubmitEditing={onSubmitHandler}
                    placeholderTextColor="muted.400"
                    returnKeyType="done"
                    selectionColor="secondary.300"
                    selectTextOnFocus
                    size="lg"
                    value={inputState.value}
                    {...props}
                />
            </Tooltip>
        </FormControl>
    );
});

CurrencyInput.displayName = "CurrencyInput";

export default React.memo(CurrencyInput);
