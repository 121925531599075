import { graphql } from "react-relay";

export const create_school = graphql`
    mutation CreateSchoolMutation(
        $input: CreateSchoolInput!
        $connections: [ID!]!
    ) {
        createSchool(input: $input) {
            success
            errors
            school
                @prependNode(
                    connections: $connections
                    edgeTypeName: "SchoolNodeEdge"
                ) {
                id
                ...SchoolListFragment_SchoolNode
            }
        }
    }
`;
