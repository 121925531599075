import React, { useCallback } from "react";
import type { ReactElement, ComponentProps } from "react";

import { Box, Button, Text } from "native-base";

import ButtonDebounced from "./ButtonDebounced";

type ButtonProps = ComponentProps<typeof Button>;
type TextProps = ComponentProps<typeof Text>;
type BoxProps = ComponentProps<typeof Box>;
type ButtonGroupProps = Omit<ComponentProps<typeof Button.Group>, "children">;

interface Props extends ButtonGroupProps {
    barStyle?: BoxProps;
    buttonStyle?: ButtonProps;
    containerProps?: BoxProps;
    currentPageIndex: number;
    hideBottomBar?: boolean;
    onChangePage?: (index: number) => void;
    pages: string[];
    selectedButtonStyle?: ButtonProps;
    selectedTextStyle?: TextProps;
    setCurrentPageIndex: (index: number) => void;
    textStyle?: TextProps;
}

const TopTabBar = (props: Props): ReactElement => {
    const {
        barStyle,
        buttonStyle,
        containerProps,
        currentPageIndex,
        hideBottomBar,
        onChangePage,
        pages,
        selectedButtonStyle,
        selectedTextStyle,
        setCurrentPageIndex,
        textStyle,
    } = props;

    const buttonPressHandler = useCallback(
        (index: number) => {
            if (index !== currentPageIndex) {
                setCurrentPageIndex(index);
                onChangePage?.(index);
            }
        },
        [currentPageIndex, onChangePage, setCurrentPageIndex],
    );

    return (
        <Box width="100%" {...containerProps}>
            <Button.Group isAttached mt="-4" {...props}>
                <>
                    {pages.map((page, index) => {
                        return (
                            <ButtonDebounced
                                key={index}
                                _pressed={{ bg: "primary.500" }}
                                alignItems="flex-end"
                                borderRadius={0}
                                flex={1}
                                pb="0"
                                pt="5"
                                {...buttonStyle}
                                {...(currentPageIndex == index
                                    ? selectedButtonStyle
                                    : undefined)}
                                onPress={() => buttonPressHandler(index)}
                            >
                                <Text
                                    color="surface.100"
                                    fontSize="lg"
                                    fontWeight={600}
                                    textAlign="center"
                                    {...textStyle}
                                    {...(currentPageIndex == index
                                        ? selectedTextStyle
                                        : undefined)}
                                >
                                    {page}
                                </Text>
                                {!hideBottomBar ? (
                                    <Box
                                        bg={
                                            currentPageIndex === index
                                                ? "secondary.500"
                                                : "primary.500"
                                        }
                                        height={1}
                                        mt="2"
                                        width={
                                            typeof props.width === "number"
                                                ? props.width / pages.length
                                                : undefined
                                        }
                                        {...barStyle}
                                    />
                                ) : null}
                            </ButtonDebounced>
                        );
                    })}
                </>
            </Button.Group>
        </Box>
    );
};

export default TopTabBar;
