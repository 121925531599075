/**
 * @generated SignedSource<<c652f70975b5759b850a3514230f39a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LoadTeachingDaysQuery$variables = {
  block?: number | null;
  startingYear?: number | null;
};
export type LoadTeachingDaysQuery$data = {
  readonly me: {
    readonly profile: {
      readonly id: string;
      readonly teachingDays: ReadonlyArray<number | null> | null;
    } | null;
  } | null;
};
export type LoadTeachingDaysQuery = {
  response: LoadTeachingDaysQuery$data;
  variables: LoadTeachingDaysQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "block"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startingYear"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ProfileNode",
  "kind": "LinkedField",
  "name": "profile",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "block",
          "variableName": "block"
        },
        {
          "kind": "Variable",
          "name": "startingYear",
          "variableName": "startingYear"
        }
      ],
      "kind": "ScalarField",
      "name": "teachingDays",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoadTeachingDaysQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DjangoUserNode",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "LoadTeachingDaysQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DjangoUserNode",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b6bd4e3f0fcf0e1302aaa51151daddc7",
    "id": null,
    "metadata": {},
    "name": "LoadTeachingDaysQuery",
    "operationKind": "query",
    "text": "query LoadTeachingDaysQuery(\n  $startingYear: Int\n  $block: Int\n) {\n  me {\n    profile {\n      id\n      teachingDays(startingYear: $startingYear, block: $block)\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "44b55c715a24ae3c5ed698dc86450e9a";

export default node;
