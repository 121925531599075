import { graphql } from "react-relay";

export const remove_lesson_column = graphql`
    mutation RemoveLessonColumnMutation($input: RemoveLessonColumnInput!) {
        removeLessonColumn(input: $input) {
            success
            errors
            lessonBlocks {
                id
                lessons {
                    edges {
                        node {
                            id
                            scheduledTimestamp
                            rearrangedTimestamp
                            status
                        }
                    }
                }
            }
        }
    }
`;
