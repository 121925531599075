/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
    useCallback,
    useContext,
    useMemo,
    createContext,
    memo,
    useState,
} from "react";
import type {
    ComponentProps,
    ReactElement,
    MemoExoticComponent,
    Dispatch,
    SetStateAction,
} from "react";

import { Button, VStack, HStack, Text } from "native-base";

type TextProps = ComponentProps<typeof Text>;
type HStackProps = ComponentProps<typeof HStack>;
type ButtonProps = ComponentProps<typeof Button>;
type ButtonGroupProps = ComponentProps<typeof Button.Group>;

interface SelectListProps extends ButtonGroupProps {
    buttonStyle?: ButtonProps;
    columnContainerProps?: HStackProps;
    headingStyle?: TextProps;
    initialSelectedItems: any;
    labelStyle?: TextProps;
    numberOfColumns?: number;
    onSelect?: (selectedItems: any) => void;
    selectedColorScheme?: string;
}

interface SelectListItemProps extends ButtonProps {
    labelBody?: string;
    labelHeading: string;
    value: string | number | boolean | Date;
}

type SelectListComponentType = ((
    props: SelectListProps,
) => React.JSX.Element) & {
    Item: MemoExoticComponent<
        (props: SelectListItemProps) => React.JSX.Element
    >;
};

interface SelectListContextType {
    buttonStyle?: ButtonProps;
    headingStyle?: TextProps;
    labelStyle?: TextProps;
    onSelect?: (selectedItems: any) => void;
    selectedColorScheme?: string;
    selectedItems: any;
    setSelectedItems: Dispatch<SetStateAction<any>>;
}

const SelectListContext = createContext<SelectListContextType | null>(null);

const useSelectListContext = (): SelectListContextType => {
    const context = useContext(SelectListContext);

    if (!context) {
        throw new Error(
            "useSelectListContext must be used within a SelectListContext Provider",
        );
    }

    return context;
};

const SelectListItem = (props: SelectListItemProps): ReactElement => {
    const { labelBody, labelHeading, value } = props;

    const {
        buttonStyle,
        headingStyle,
        labelStyle,
        onSelect,
        selectedColorScheme,
        selectedItems,
        setSelectedItems,
    } = useSelectListContext();

    const onPressHandler = useCallback(() => {
        let updatedSelectedItems = selectedItems;
        if (selectedItems instanceof Array) {
            if (!selectedItems.includes(value)) {
                updatedSelectedItems = [value].concat(selectedItems);
            } else {
                updatedSelectedItems = selectedItems.filter(
                    (item) => item != value,
                );
            }
        } else if (selectedItems == value) {
            updatedSelectedItems = "";
        } else {
            updatedSelectedItems = value;
        }
        setSelectedItems(updatedSelectedItems);
        onSelect?.(updatedSelectedItems);
    }, [onSelect, selectedItems, setSelectedItems, value]);

    const isSelected = useMemo(() => {
        if (selectedItems instanceof Array)
            return selectedItems.includes(value);
        else return selectedItems == value;
    }, [selectedItems, value]);

    return (
        <Button
            borderRadius="full"
            height="64px"
            onPress={onPressHandler}
            p="4"
            width="64px"
            {...buttonStyle}
            {...props}
            _hover={{ bg: !isSelected ? "transparent" : "primary.500" }}
            colorScheme={
                isSelected ? (selectedColorScheme ?? "primary") : "surface"
            }
            variant={isSelected ? "solid" : "ghost"}
        >
            <VStack space={1} width="100%">
                <Text
                    fontSize="lg"
                    fontWeight="600"
                    {...headingStyle}
                    color={isSelected ? "white" : undefined}
                >
                    {labelHeading}
                </Text>
                {labelBody ? (
                    <Text
                        pb="1"
                        {...labelStyle}
                        color={isSelected ? "white" : undefined}
                    >
                        {labelBody}
                    </Text>
                ) : null}
            </VStack>
        </Button>
    );
};

const SelectListMain = (props: SelectListProps): ReactElement => {
    const {
        buttonStyle,
        children,
        columnContainerProps,
        headingStyle,
        initialSelectedItems,
        labelStyle,
        numberOfColumns = 1,
        onSelect,
        selectedColorScheme,
    } = props;

    const [selectedItems, setSelectedItems] = useState(initialSelectedItems);

    let numberOfRows = 1;
    if (Array.isArray(children)) {
        // get number of children in each row
        numberOfRows = Math.floor(children.length / numberOfColumns);
    }

    // create array of [0, 1, ..., numberOfColumns] to iterate over
    const columns = [...Array(numberOfColumns).keys()];

    return (
        <SelectListContext.Provider
            value={{
                selectedItems,
                setSelectedItems,
                onSelect,
                buttonStyle,
                selectedColorScheme,
                headingStyle,
                labelStyle,
            }}
        >
            <VStack alignItems="center">
                <HStack space="2" {...columnContainerProps}>
                    {columns.map((item) => {
                        return (
                            <Button.Group
                                key={item}
                                flexDirection="column"
                                {...props}
                            >
                                {Array.isArray(children)
                                    ? children.slice(
                                          item * numberOfRows,
                                          item * numberOfRows + numberOfRows,
                                      )
                                    : children}
                            </Button.Group>
                        );
                    })}
                </HStack>
                {Array.isArray(children) &&
                (children.length & 1) == 1 &&
                numberOfColumns > 1 ? (
                    <Button.Group {...props}>
                        {children.slice(-1)[0]}
                    </Button.Group>
                ) : null}
            </VStack>
        </SelectListContext.Provider>
    );
};

const SelectListTemp: any = memo(SelectListMain);
SelectListTemp.Item = memo(SelectListItem);

const SelectList = SelectListTemp as SelectListComponentType;

export default SelectList;
