import { graphql } from "react-relay";

export const edit_lesson_stage = graphql`
    mutation EditLessonStageMutation($input: EditLessonStageInput!) {
        editLessonStage(input: $input) {
            success
            errors
            lessonStage {
                id
                stage
                lessonDuration
                lessonType
                costPerLesson
            }
        }
    }
`;
