export const pushOrRemove = <T1>(array: T1[], item: T1): T1[] => {
    const index = array.indexOf(item);
    if (index === -1) {
        array.push(item);
    } else {
        array.splice(index, 1);
    }

    return array;
};

export const addOrRemoveFromSet = <T1>(set: Set<T1>, item: T1): Set<T1> => {
    if (set.has(item)) {
        set.delete(item);
    } else {
        set.add(item);
    }

    return set;
};

export const ensureDateType = (date: string | Date | null | undefined) => {
    if (date) {
        return new Date(date);
    } else {
        return new Date();
    }
};

export const ensureDateTypeIfDefined = (
    date: string | Date | null | undefined,
) => {
    if (date) {
        const parsedDate = new Date(date);
        if (isNaN(parsedDate.getTime())) {
            return undefined;
        }
        return parsedDate;
    } else {
        return undefined;
    }
};

export const pluralize = (
    str: string,
    count: number | null | undefined,
    options?: {
        pluralizedString?: string;
    },
) => {
    if (count === 1) {
        return str;
    }

    if (options?.pluralizedString) {
        return options?.pluralizedString;
    }

    return str + "s";
};
