/**
 * @generated SignedSource<<09ec179e2463db9632d6549b44981a7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveParentRelationshipInput = {
  clientMutationId?: string | null;
  pupilId: string;
};
export type RemoveParentRelationshipMutation$variables = {
  connections: ReadonlyArray<string>;
  input: RemoveParentRelationshipInput;
};
export type RemoveParentRelationshipMutation$data = {
  readonly removeParentRelationship: {
    readonly errors: any | null;
    readonly profile: {
      readonly id: string;
      readonly primaryParent: {
        readonly id: string;
      } | null;
    } | null;
    readonly success: boolean | null;
  } | null;
};
export type RemoveParentRelationshipMutation = {
  response: RemoveParentRelationshipMutation$data;
  variables: RemoveParentRelationshipMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ProfileNode",
  "kind": "LinkedField",
  "name": "primaryParent",
  "plural": false,
  "selections": [
    (v5/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveParentRelationshipMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "RemoveParentRelationshipPayload",
        "kind": "LinkedField",
        "name": "removeParentRelationship",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "RemoveParentRelationshipMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "RemoveParentRelationshipPayload",
        "kind": "LinkedField",
        "name": "removeParentRelationship",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteEdge",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c176ed61ebc23818060de12293c08ff1",
    "id": null,
    "metadata": {},
    "name": "RemoveParentRelationshipMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveParentRelationshipMutation(\n  $input: RemoveParentRelationshipInput!\n) {\n  removeParentRelationship(input: $input) {\n    success\n    errors\n    profile {\n      id\n      primaryParent {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "de692f29813eba7886bc64fb5fe1bf46";

export default node;
