import React, { useMemo } from "react";
import type { ReactElement } from "react";

import AccountStatus from "../Accounts/AccountStatus";
import DbsStatus from "../Accounts/DbsStatus";
import type { CellProps } from "pianofunclub-shared/components/Base/Cell";
import Row from "pianofunclub-shared/components/Base/Row";
import type { TableData } from "pianofunclub-shared/components/Base/Row";

import type { LoadAccounts_query_profiles$data } from "pianofunclub-shared/relay/graphql/accounts/__generated__/LoadAccounts_query_profiles.graphql";

import type { AccountType } from "pianofunclub-shared/types";
import {
    discountTextConverter,
    schoolYearConverter,
    titleCaseConverter,
} from "pianofunclub-shared/utils/converters";
import { getFullName } from "pianofunclub-shared/utils/extractors";

interface Props {
    cellProps?: CellProps;
    currentPageIndex: number;
    data: NonNullable<
        NonNullable<
            NonNullable<
                LoadAccounts_query_profiles$data["profiles"]
            >["edges"][0]
        >["node"]
    >;
    flexArray: number[];
    onPressProfile: (
        id?: string | null,
        accountType?: AccountType | null,
    ) => void;
    onPressSchool: (schoolId?: string | null) => void;
    rowHeight?: number;
    tableBorderColor?: string;
    tableBorderWidth?: number;
}

const AccountTableRow = (props: Props): ReactElement => {
    const {
        cellProps,
        currentPageIndex,
        data,
        flexArray,
        onPressProfile,
        onPressSchool,
        rowHeight,
        tableBorderColor,
        tableBorderWidth,
    } = props;

    const rowData = useMemo(() => {
        const row = [
            {
                data:
                    currentPageIndex !== 1
                        ? (data.accountNumber ?? "")
                        : (data.primaryParent?.accountNumber ?? ""),
                onPress: () => {
                    if (currentPageIndex !== 1) {
                        onPressProfile(
                            data.id,
                            data.accountType as AccountType,
                        );
                    } else {
                        onPressProfile(data.primaryParent?.id, "PARENT");
                    }
                },
            },
            {
                data: getFullName(data.user.firstName, data.user.lastName),
                onPress: () =>
                    onPressProfile(data.id, data.accountType as AccountType),
            },
        ] as TableData;
        if (currentPageIndex !== 1) {
            row.push(
                {
                    data: data.user.email ?? "",
                    onPress: () =>
                        onPressProfile(
                            data.id,
                            data.accountType as AccountType,
                        ),
                },
                {
                    data: data.phoneNumber ?? "",
                },
            );
        }
        switch (currentPageIndex) {
            case 0:
                return row.concat([
                    {
                        data: data.status ? (
                            <AccountStatus
                                isTransparent
                                status={data.status}
                                textProps={{
                                    fontFamily: "Poppins-Light",
                                }}
                            />
                        ) : (
                            ""
                        ),
                        onPress: data.status
                            ? () =>
                                  onPressProfile(
                                      data.id,
                                      data.accountType as AccountType,
                                  )
                            : undefined,
                    },
                ]);
            case 1:
                return row.concat([
                    {
                        data: getFullName(
                            data.primaryParent?.user.firstName,
                            data.primaryParent?.user.lastName,
                        ),
                        onPress: () =>
                            onPressProfile(data.primaryParent?.id, "PARENT"),
                    },
                    {
                        data: titleCaseConverter(data.school?.name) ?? "",
                        onPress: () => onPressSchool(data.school?.id),
                    },
                    {
                        data: schoolYearConverter(data.schoolYear),
                    },
                    {
                        data: discountTextConverter(
                            data.discountCategory,
                            data.discountPercentage,
                        ),
                    },
                    {
                        data: data.status ? (
                            <AccountStatus
                                isTransparent
                                status={data.status}
                                textProps={{
                                    fontFamily: "Poppins-Light",
                                }}
                            />
                        ) : (
                            ""
                        ),
                        onPress: data.status
                            ? () =>
                                  onPressProfile(
                                      data.id,
                                      data.accountType as AccountType,
                                  )
                            : undefined,
                    },
                ]);
            case 2:
                return row.concat([
                    {
                        data: (
                            <DbsStatus
                                dbsExpirationDate={data.dbsExpirationDate}
                                dbsNumber={data.dbsNumber}
                                isTransparent
                                textProps={{
                                    fontFamily: "Poppins-Light",
                                }}
                            />
                        ),
                    },
                    {
                        data: data.status ? (
                            <AccountStatus
                                isTransparent
                                status={data.status}
                                textProps={{
                                    fontFamily: "Poppins-Light",
                                }}
                            />
                        ) : (
                            ""
                        ),
                    },
                ]);
            default:
                return row.concat([
                    {
                        data: data.user.isSuperuser ? "Maximum" : "Normal",
                    },
                ]);
        }
    }, [
        data.accountNumber,
        data.user.firstName,
        data.user.lastName,
        data.user.email,
        data.user.isSuperuser,
        data.id,
        data.accountType,
        data.primaryParent?.accountNumber,
        data.primaryParent?.id,
        data.primaryParent?.user.firstName,
        data.primaryParent?.user.lastName,
        data.phoneNumber,
        data.status,
        data.school?.name,
        data.school?.id,
        data.schoolYear,
        data.discountCategory,
        data.discountPercentage,
        data.dbsNumber,
        data.dbsExpirationDate,
        currentPageIndex,
        onPressProfile,
        onPressSchool,
    ]);

    return (
        <Row
            cellProps={cellProps}
            data={rowData}
            flexArray={flexArray}
            rowHeight={rowHeight}
            rowIndex={0}
            tableBorderColor={tableBorderColor}
            tableBorderWidth={tableBorderWidth}
        />
    );
};

export default React.memo(AccountTableRow);
