import React, {
    useRef,
    useCallback,
    useMemo,
    useReducer,
    useEffect,
    useState,
    Suspense,
} from "react";
import type { ReactElement, MutableRefObject } from "react";

import emoji from "emoji-dictionary";
import {
    Modal,
    VStack,
    Text,
    Box,
    HStack,
    PresenceTransition,
    Button,
    useToast,
    Pressable,
    Center,
} from "native-base";
import {
    useMutation,
    usePaginationFragment,
    usePreloadedQuery,
    useQueryLoader,
    useRelayEnvironment,
} from "react-relay";
import type { PreloadedQuery } from "react-relay";
import {
    RecyclerListView,
    DataProvider,
    LayoutProvider,
} from "recyclerlistview";
import { fetchQuery } from "relay-runtime";
import type { Subscription } from "relay-runtime";

import type { SchoolEdges } from "pianofunclub-shared/providers/DataProvider";

import BackButton from "../Buttons/BackButton";
import ButtonDebounced from "pianofunclub-shared/components/Buttons/ButtonDebounced";
import PercentageInput from "pianofunclub-shared/components/Inputs/PercentageInput";
import TextInput from "pianofunclub-shared/components/Inputs/TextInput";
import ListEmptyBanner from "pianofunclub-shared/components/ListItems/ListEmptyBanner";
import AlertPopup from "pianofunclub-shared/components/NativeBaseExtended/AlertPopup";
import Select from "pianofunclub-shared/components/NativeBaseExtended/Select";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import CustomFlatListSpinner from "pianofunclub-shared/components/Other/CustomFlatListSpinner";
import {
    AddCircleIcon,
    CloseIcon,
    CompleteIcon,
    MailIcon,
    PhoneIcon,
    SecurityIcon,
} from "pianofunclub-shared/components/Other/Icons";
import SearchBar from "pianofunclub-shared/components/Other/SearchBar";

import type {
    CreateAccountMutation,
    CreateAccountMutation$data,
    ParentOrChildrenAccountInput,
} from "pianofunclub-shared/relay/graphql/accounts/__generated__/CreateAccountMutation.graphql";
import type {
    LoadAccountsForModal_query_profiles$key,
    LoadAccountsForModal_query_profiles$data,
} from "pianofunclub-shared/relay/graphql/accounts/__generated__/LoadAccountsForModal_query_profiles.graphql";
import type { LoadAccountsForModalPaginationQuery } from "pianofunclub-shared/relay/graphql/accounts/__generated__/LoadAccountsForModalPaginationQuery.graphql";
import type { LoadAccountsForModalQuery } from "pianofunclub-shared/relay/graphql/accounts/__generated__/LoadAccountsForModalQuery.graphql";
import { create_account } from "pianofunclub-shared/relay/graphql/accounts/CreateAccount";
import {
    load_accounts_for_modal,
    load_accounts_for_modal_pagination,
} from "pianofunclub-shared/relay/graphql/accounts/LoadAccountsForModal";

import type { Mutable } from "pianofunclub-shared/types";
import {
    ACCOUNT_TYPES,
    DISCOUNT_CATEGORIES,
    SCHOOL_YEARS,
} from "pianofunclub-shared/utils/constants";
import {
    schoolYearConverter,
    titleCaseConverter,
} from "pianofunclub-shared/utils/converters";
import {
    getAccountTypeEmoji,
    getFullName,
} from "pianofunclub-shared/utils/extractors";
import { useDebounceFunction } from "pianofunclub-shared/utils/hooks";
import {
    createFormReducer,
    createReducer,
} from "pianofunclub-shared/utils/reducers";
import type { FormAction } from "pianofunclub-shared/utils/reducers";

type ReducerValues = {
    dataProvider: DataProvider;
    emailAlreadyInUseAlertIsOpen: boolean;
    isRefetching: boolean;
    pageIndex: number;
    searchTerm: string;
    subscription?: Subscription;
};

type ReducerTypes =
    | string
    | boolean
    | number
    | Subscription
    | DataProvider
    | undefined;

interface Props {
    initialAccountType: "PARENT" | "PUPIL" | "TEACHER" | "STAFF";
    isAccountPage?: boolean;
    loadAccountsQueryReference: PreloadedQuery<
        LoadAccountsForModalQuery,
        Record<string, unknown>
    >;
    parentConnections?: string[];
    profileId?: string;
    pupilConnections?: string[];
    schools: SchoolEdges;
    setShowModal: (value: boolean) => void;
    showModal: boolean;
    staffConnections?: string[];
    teacherConnections?: string[];
}

type Page1ReducerValues = {
    accountType: "PARENT" | "PUPIL" | "TEACHER" | "STAFF";
    firstName: string;
    lastName?: string;
    profileId?: string;
};

type Page2ReducerValues = {
    discountCategorySelect?: string;
    discountPercentage?: number;
    email?: string;
    paidBySchoolSelect?: boolean;
    password?: string;
    phoneNumber?: string;
    schoolName?: string;
    schoolYear?: number;
};

type Page4ReducerValues = {
    email?: string;
    firstName: string;
    lastName?: string;
    password?: string;
    phoneNumber?: string;
    profileId?: string;
    schoolName?: string;
    schoolYear?: number;
};

type FormReducerTypes = string | number | boolean | undefined;

const LOAD_X_PROFILES = 12;

const CreateAccountModal = (props: Props): ReactElement => {
    const {
        initialAccountType,
        isAccountPage,
        loadAccountsQueryReference,
        parentConnections,
        profileId,
        pupilConnections,
        schools,
        setShowModal,
        showModal,
        staffConnections,
        teacherConnections,
    } = props;

    const initialState = useMemo(() => {
        return {
            values: {
                pageIndex: 0,
                searchTerm: "",
                isRefetching: false,
                subscription: undefined,
                dataProvider: new DataProvider((r1, r2) => {
                    return r1 !== r2;
                }),
                emailAlreadyInUseAlertIsOpen: false,
            },
        };
    }, []);

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [state, dispatchState] = useReducer(reducer, initialState);

    const page1InitialState = useMemo(() => {
        return {
            inputValues: {
                firstName: "",
                lastName: undefined,
                accountType: initialAccountType,
                profileId: undefined,
            },
            inputValidities: {
                firstName: false,
                lastName: true,
                accountType: true,
                profileId: true,
            },
            formIsValid: false,
            formIsEdited: false,
        };
    }, [initialAccountType]);

    const page1Reducer = createFormReducer<
        Page1ReducerValues,
        FormReducerTypes
    >(page1InitialState);
    const [page1State, dispatchPage1State] = useReducer(
        page1Reducer,
        page1InitialState,
    );

    const page2InitialState = useMemo(() => {
        return {
            inputValues: {
                email: undefined,
                phoneNumber: undefined,
                password: undefined,
                schoolName: undefined,
                schoolYear: undefined,
                paidBySchoolSelect: false,
                discountCategorySelect: "FULL_PRICE",
                discountPercentage: undefined,
            },
            inputValidities: {
                email: true,
                phoneNumber: true,
                password: true,
                schoolName: true,
                schoolYear: true,
                paidBySchoolSelect: true,
                discountCategorySelect: true,
                discountPercentage: true,
            },
            formIsValid: true,
            formIsEdited: false,
        };
    }, []);

    const page2Reducer = createFormReducer<
        Page2ReducerValues,
        FormReducerTypes
    >(page2InitialState);
    const [page2State, dispatchPage2State] = useReducer(
        page2Reducer,
        page2InitialState,
    );

    const [page3State, setPage3State] = useState<Page4ReducerValues[]>([]);

    const page4InitialState = useMemo(() => {
        return {
            inputValues: {
                profileId: undefined,
                firstName: "",
                lastName: undefined,
                schoolName: undefined,
                schoolYear: undefined,
                email: undefined,
                phoneNumber: undefined,
                password: undefined,
            },
            inputValidities: {
                profileId: true,
                firstName: false,
                lastName: true,
                schoolName: true,
                schoolYear: true,
                email: true,
                phoneNumber: true,
                password: true,
            },
            formIsValid: false,
            formIsEdited: false,
        };
    }, []);

    const page4Reducer = createFormReducer<
        Page4ReducerValues,
        FormReducerTypes
    >(page4InitialState);
    const [page4State, dispatchPage4State] = useReducer(
        page4Reducer,
        page4InitialState,
    );

    const data = usePreloadedQuery(
        load_accounts_for_modal,
        loadAccountsQueryReference,
    );

    const {
        data: accountsData,
        hasNext,
        isLoadingNext,
        loadNext,
        refetch: refresh,
    } = usePaginationFragment<
        LoadAccountsForModalPaginationQuery,
        LoadAccountsForModal_query_profiles$key
    >(load_accounts_for_modal_pagination, data);

    // stop refetch useEffects firing on first render
    const renderCount = useRef(0);
    const environment = useRelayEnvironment();

    useEffect(() => {
        if (renderCount.current < 2) {
            renderCount.current += 1;
            return;
        }

        if (
            accountsData.profiles?.edges &&
            accountsData.profiles.edges.length > 0
        ) {
            dispatchState({
                input: "dataProvider",
                value: state.values.dataProvider.cloneWithRows(
                    accountsData.profiles.edges as Mutable<
                        typeof accountsData.profiles.edges
                    >,
                ),
            });
            // if there is no data, create a fresh DataProvider
        } else {
            dispatchState({
                input: "dataProvider",
                value: new DataProvider((r1, r2) => {
                    return r1 !== r2;
                }),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountsData.profiles?.edges]);

    const layoutProvider = useMemo(() => {
        return new LayoutProvider(
            () => "VSEL",
            (type, dim) => {
                switch (type) {
                    case "VSEL":
                        dim.width = 450;
                        dim.height = 43;
                        break;
                    default:
                        dim.width = 0;
                        dim.height = 0;
                }
            },
        );
    }, []);

    const onSearchEndReached = useCallback(() => {
        if (hasNext && !isLoadingNext) {
            loadNext(LOAD_X_PROFILES);
        }
    }, [hasNext, isLoadingNext, loadNext]);

    useDebounceFunction(
        () => {
            if (renderCount.current < 2) {
                renderCount.current += 1;
                return;
            }

            state.values.subscription?.unsubscribe();
            dispatchState({ input: "isRefetching", value: true });

            const variables = {
                searchTerm:
                    state.values.searchTerm.trim() !== ""
                        ? state.values.searchTerm.trim()
                        : undefined,
                orderBy:
                    state.values.searchTerm.trim() === ""
                        ? "user__firstName,user__lastName"
                        : undefined,
                accountType: isAccountPage
                    ? page1State.inputValues.accountType === "PARENT"
                        ? "PARENT"
                        : "PUPIL"
                    : page1State.inputValues.accountType === "PARENT"
                      ? "PUPIL"
                      : "PARENT",
                first: LOAD_X_PROFILES,
            };

            dispatchState({
                input: "subscription",
                value: fetchQuery<LoadAccountsForModalQuery>(
                    environment,
                    load_accounts_for_modal,
                    variables,
                    {
                        fetchPolicy: "store-or-network",
                    },
                ).subscribe({
                    complete: () => {
                        dispatchState({ input: "isRefetching", value: false });
                        refresh(variables, { fetchPolicy: "store-only" });
                    },
                    error: () => {
                        dispatchState({ input: "isRefetching", value: false });
                    },
                }),
            });
        }, // no delay if clearing text
        state.values.searchTerm !== "" ? 750 : 0,

        [state.values.searchTerm, page1State.inputValues.accountType],
    );

    const searchBarChangeHandler = useCallback(
        (_: unknown, inputValue?: string) => {
            dispatchState({
                input: "searchTerm",
                value: inputValue,
            });
        },
        [dispatchState],
    );

    const searchBarClearHandler = useCallback(() => {
        if (state.values.searchTerm !== "") {
            dispatchState({
                input: "searchTerm",
                value: "",
            });
        }
    }, [dispatchState, state.values.searchTerm]);

    const toast = useToast();

    const [commitCreateAccount, createAccountInFlight] =
        useMutation<CreateAccountMutation>(create_account);

    const createAccount = useCallback(
        (input: {
            accountType: string;
            discountCategory?: string;
            discountPercentage?: number;
            email?: string;
            firstName: string;
            lastName?: string;
            paidBySchool?: boolean;
            parentOrChildren?: {
                email?: string;
                firstName: string;
                lastName?: string;
                password?: string;
                phoneNumber?: string;
                profileId?: string;
                schoolName?: string;
                schoolYear?: number;
            }[];
            password?: string;
            phoneNumber?: string;
            profileId?: string;
            schoolName?: string;
            schoolYear?: number;
        }) => {
            const createAccountConfig = {
                variables: {
                    ...(input.accountType === "PARENT"
                        ? {
                              relevantConnections: parentConnections ?? [],
                              parentConnections: [],
                              pupilConnections: pupilConnections ?? [],
                          }
                        : input.accountType === "PUPIL"
                          ? {
                                relevantConnections: pupilConnections ?? [],
                                parentConnections: parentConnections ?? [],
                                pupilConnections: [],
                            }
                          : input.accountType === "TEACHER"
                            ? {
                                  relevantConnections: teacherConnections ?? [],
                                  parentConnections: [],
                                  pupilConnections: [],
                              }
                            : {
                                  relevantConnections: staffConnections ?? [],
                                  parentConnections: [],
                                  pupilConnections: [],
                              }),
                    input: {
                        ...input,
                        ...(profileId && isAccountPage
                            ? {
                                  parentOrChildren: [
                                      { profileId: profileId },
                                  ] as (ParentOrChildrenAccountInput | null)[],
                              }
                            : {}),
                    },
                },
                onCompleted: (response: CreateAccountMutation$data) => {
                    if (response?.createAccount?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={
                                        isAccountPage
                                            ? "Child Added"
                                            : "Account created"
                                    }
                                    toast={toast}
                                />
                            ),
                        });
                        setShowModal(false);
                    } else {
                        if (
                            response.createAccount?.errors[0]?.code ===
                            "already_exists"
                        ) {
                            dispatchState({
                                input: "emailAlreadyInUseAlertIsOpen",
                                value: true,
                            });
                        } else {
                            toast.show({
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        description={
                                            "Please get in touch with one of the team"
                                        }
                                        id={id}
                                        status="error"
                                        title={"Couldn't create account"}
                                        toast={toast}
                                    />
                                ),
                            });
                        }
                    }
                },
            };

            commitCreateAccount(createAccountConfig);
        },
        [
            commitCreateAccount,
            isAccountPage,
            parentConnections,
            profileId,
            pupilConnections,
            setShowModal,
            staffConnections,
            teacherConnections,
            toast,
        ],
    );

    const firstNameInputRefPage1 = useRef<{
        focus: () => void;
    }>();
    const lastNameInputRefPage1 = useRef<{
        focus: () => void;
    }>();
    const firstNameInputRefPage4 = useRef<{
        focus: () => void;
    }>();
    const lastNameInputRefPage4 = useRef<{
        focus: () => void;
    }>();

    const pageInputChangeHandler = useCallback(
        (
            dispatchPageState: (value: FormAction<FormReducerTypes>) => void,
            inputIdentifier?: string | number,
            inputValue?: string,
            isValid?: boolean,
        ) => {
            if (
                typeof inputIdentifier === "string" &&
                typeof inputValue === "string"
            ) {
                // allow an empty password field (sets default password)
                if (inputIdentifier.includes("password") && !inputValue) {
                    dispatchPageState({
                        input: inputIdentifier,
                        value: inputValue.trim(),
                        isValid: true,
                    });
                } else {
                    dispatchPageState({
                        input: inputIdentifier,
                        value: inputValue.trim(),
                        isValid: isValid,
                    });
                }
            }
        },
        [],
    );

    const addOrRemoveParentOrChildHandler = useCallback(
        (index?: number, isRemove?: boolean) => {
            if (isRemove) {
                if (isAccountPage) {
                    dispatchPage1State({ type: "RESET" });
                } else {
                    setPage3State(page3State.filter((_, i) => i !== index));
                }
            } else {
                setPage3State(page3State.concat(page4State.inputValues));
                dispatchState({
                    input: "pageIndex",
                    value: state.values.pageIndex - 1,
                });
                dispatchPage4State({ type: "RESET" });
            }
        },
        [
            isAccountPage,
            page3State,
            page4State.inputValues,
            state.values.pageIndex,
        ],
    );

    const createAccountHandler = useCallback(() => {
        if (page1State.inputValues.firstName) {
            createAccount({
                accountType: page1State.inputValues.accountType,
                firstName: page1State.inputValues.firstName,
                lastName: page1State.inputValues.lastName,
                profileId: page1State.inputValues.profileId,
                email: page2State.inputValues.email,
                phoneNumber: page2State.inputValues.phoneNumber,
                password: page2State.inputValues.password,
                schoolName: page2State.inputValues.schoolName,
                schoolYear: page2State.inputValues.schoolYear,
                paidBySchool: page2State.inputValues.paidBySchoolSelect,
                discountCategory: page2State.inputValues.discountCategorySelect,
                discountPercentage: page2State.inputValues.discountPercentage,
                parentOrChildren: !isAccountPage ? page3State : undefined,
            });
        }
    }, [
        createAccount,
        isAccountPage,
        page1State.inputValues.accountType,
        page1State.inputValues.firstName,
        page1State.inputValues.lastName,
        page1State.inputValues.profileId,
        page2State.inputValues.discountCategorySelect,
        page2State.inputValues.discountPercentage,
        page2State.inputValues.email,
        page2State.inputValues.paidBySchoolSelect,
        page2State.inputValues.password,
        page2State.inputValues.phoneNumber,
        page2State.inputValues.schoolName,
        page2State.inputValues.schoolYear,
        page3State,
    ]);

    const selectFromListHandler = useCallback(
        (
            item: NonNullable<
                LoadAccountsForModal_query_profiles$data["profiles"]
            >["edges"][0],
        ) => {
            if (item?.node) {
                if (!isAccountPage) {
                    setPage3State(
                        page3State.concat({
                            profileId: item.node.id,
                            firstName: item.node.user.firstName,
                            lastName: item.node.user.lastName,
                            schoolName: item.node.school?.name,
                            schoolYear: item.node.schoolYear ?? undefined,
                        }),
                    );
                    dispatchState({
                        input: "pageIndex",
                        value: state.values.pageIndex - 1,
                    });
                    dispatchPage4State({ type: "RESET" });
                } else {
                    dispatchPage1State({
                        input: "profileId",
                        value: item.node.id,
                        isValid: true,
                    });
                    dispatchPage1State({
                        input: "firstName",
                        value: item.node.user.firstName,
                        isValid: true,
                    });
                    dispatchPage1State({
                        input: "lastName",
                        value: item.node.user.lastName,
                        isValid: true,
                    });
                }
                dispatchState({
                    input: "searchTerm",
                    value: "",
                });
            }
        },
        [isAccountPage, page3State, state.values.pageIndex],
    );

    const rowRenderer = useCallback(
        (
            _: unknown,
            item: NonNullable<
                LoadAccountsForModal_query_profiles$data["profiles"]
            >["edges"][0],
            index: number,
        ): ReactElement | null => {
            const isPupil =
                (isAccountPage &&
                    page1State.inputValues.accountType === "PUPIL") ||
                (!isAccountPage &&
                    page1State.inputValues.accountType === "PARENT");
            if (item?.node) {
                return (
                    <Pressable
                        key={index}
                        _pressed={{ bg: "surface.200" }}
                        bg="surface.50"
                        borderBottomWidth={1}
                        borderColor="surface.200"
                        borderTopWidth={index === 0 ? 1 : 0}
                        onPress={() => selectFromListHandler(item)}
                        px="4"
                        py="2"
                    >
                        <Text
                            fontFamily="Poppins-Regular"
                            fontSize="md"
                            numberOfLines={1}
                        >
                            {getFullName(
                                item.node.user.firstName,
                                item.node.user.lastName,
                            )}
                            {isPupil && item.node.school?.name
                                ? ` • ${item.node.school.name}`
                                : ""}
                            {isPupil && item.node.schoolYear
                                ? ` • ${schoolYearConverter(
                                      item.node.schoolYear,
                                  )}`
                                : ""}
                            {!isPupil && item.node.accountNumber
                                ? ` • #${item.node.accountNumber}`
                                : ""}
                        </Text>
                    </Pressable>
                );
            } else {
                return null;
            }
        },
        [
            isAccountPage,
            page1State.inputValues.accountType,
            selectFromListHandler,
        ],
    );

    const renderFooter = useCallback(() => {
        return isLoadingNext ? (
            <CustomFlatListSpinner
                borderColor="surface.200"
                borderTopWidth={1}
                position="relative"
                py="4"
                top="0"
            />
        ) : null;
    }, [isLoadingNext]);

    const renderRefetchIndicator = useMemo(() => {
        return state.values.isRefetching ? (
            <CustomFlatListSpinner top="4" />
        ) : null;
    }, [state.values.isRefetching]);

    const renderSearchResults = useMemo(() => {
        return state.values.dataProvider.getSize() > 0 ? (
            <RecyclerListView
                dataProvider={state.values.dataProvider}
                layoutProvider={layoutProvider}
                onEndReached={onSearchEndReached}
                onEndReachedThreshold={100}
                renderFooter={renderFooter}
                rowRenderer={rowRenderer}
                scrollViewProps={{
                    contentContainerStyle: { marginBottom: 16 },
                }}
                style={{
                    flex: 1,
                    minHeight: 1,
                }}
                suppressBoundedSizeException
                useWindowScroll
            />
        ) : !state.values.isRefetching ? (
            <Center flex={1} flexGrow={1}>
                <PresenceTransition
                    animate={{
                        opacity: 1,
                        transition: {
                            delay: 500,
                            duration: 300,
                        },
                    }}
                    initial={{
                        opacity: 0,
                    }}
                    visible={true}
                >
                    <ListEmptyBanner explainer={"No accounts found..."}>
                        <Text fontSize="6xl">
                            {emoji.getUnicode("neutral_face")}
                        </Text>
                    </ListEmptyBanner>
                </PresenceTransition>
            </Center>
        ) : null;
    }, [
        state.values.dataProvider,
        state.values.isRefetching,
        rowRenderer,
        layoutProvider,
        onSearchEndReached,
        renderFooter,
    ]);

    const renderNameInputs = useCallback(
        (
            pageInputChangeHandler: (
                dispatchPageState: (
                    value: FormAction<FormReducerTypes>,
                ) => void,
                inputIdentifier?: string | number,
                inputValue?: string,
                isValid?: boolean,
            ) => void,
            dispatchPageState: (value: FormAction<FormReducerTypes>) => void,
            firstNameInputRef: MutableRefObject<
                | {
                      focus: () => void;
                  }
                | undefined
            >,
            lastNameInputRef: MutableRefObject<
                | {
                      focus: () => void;
                  }
                | undefined
            >,
            firstName?: string,
            lastName?: string,
        ) => {
            return (
                <HStack space="6">
                    <Box flex={1}>
                        <TextInput
                            ref={firstNameInputRef}
                            autoCapitalize="words"
                            blurOnSubmit={false}
                            centerLabel
                            id="firstName"
                            initialValue={firstName}
                            keyboardType="default"
                            label="First Name"
                            minLength={1}
                            onFinishEditing={(
                                inputIdentifier,
                                inputValue,
                                isValid,
                            ) =>
                                pageInputChangeHandler(
                                    dispatchPageState,
                                    inputIdentifier,
                                    inputValue,
                                    isValid,
                                )
                            }
                            onSubmit={() => lastNameInputRef.current?.focus()}
                            p="3"
                            returnKeyType="next"
                            textAlign="center"
                        />
                    </Box>
                    <Box flex={1}>
                        <TextInput
                            ref={lastNameInputRef}
                            autoCapitalize="words"
                            centerLabel
                            id="lastName"
                            initialValue={lastName}
                            keyboardType="default"
                            label="Last Name"
                            onFinishEditing={(
                                inputIdentifier,
                                inputValue,
                                isValid,
                            ) =>
                                pageInputChangeHandler(
                                    dispatchPageState,
                                    inputIdentifier,
                                    inputValue,
                                    isValid,
                                )
                            }
                            p="3"
                            returnKeyType="next"
                            textAlign="center"
                        />
                    </Box>
                </HStack>
            );
        },
        [],
    );

    const renderEmailPhoneAndPasswordInputs = useCallback(
        (
            pageInputChangeHandler: (
                dispatchPageState: (
                    value: FormAction<FormReducerTypes>,
                ) => void,
                inputIdentifier?: string | number,
                inputValue?: string,
                isValid?: boolean,
            ) => void,
            dispatchPageState: (value: FormAction<FormReducerTypes>) => void,
            email: string | undefined,
            emailIsValid: boolean,
            phoneNumber: string | undefined,
            phoneNumberIsValid: boolean,
            password: string | undefined,
            passwordIsValid: boolean,
        ) => {
            return (
                <>
                    <Box>
                        <TextInput
                            autoCapitalize="none"
                            centerLabel
                            email
                            id="email"
                            initiallyValid
                            initialValue={email}
                            InputLeftElement={
                                <MailIcon
                                    color={
                                        !email
                                            ? "muted.400"
                                            : !emailIsValid
                                              ? "red.400"
                                              : "emerald.400"
                                    }
                                    ml="4"
                                    size="6"
                                />
                            }
                            invalidIndicator
                            keyboardType="email-address"
                            label="Email"
                            onFinishEditing={(
                                inputIdentifier,
                                inputValue,
                                isValid,
                            ) =>
                                pageInputChangeHandler(
                                    dispatchPageState,
                                    inputIdentifier,
                                    inputValue,
                                    isValid,
                                )
                            }
                            p="3"
                            returnKeyType="next"
                            textAlign="center"
                        />
                    </Box>
                    <Box>
                        <TextInput
                            autoCapitalize="none"
                            centerLabel
                            id="phoneNumber"
                            initiallyValid
                            initialValue={phoneNumber}
                            InputLeftElement={
                                <PhoneIcon
                                    color={
                                        !phoneNumber
                                            ? "muted.400"
                                            : !phoneNumberIsValid
                                              ? "red.400"
                                              : "emerald.400"
                                    }
                                    ml="4"
                                    size="6"
                                />
                            }
                            invalidIndicator
                            keyboardType="numeric"
                            label="Phone Number"
                            onFinishEditing={(
                                inputIdentifier,
                                inputValue,
                                isValid,
                            ) =>
                                pageInputChangeHandler(
                                    dispatchPageState,
                                    inputIdentifier,
                                    inputValue,
                                    isValid,
                                )
                            }
                            p="3"
                            textAlign="center"
                        />
                    </Box>
                    <Box>
                        <TextInput
                            autoCapitalize="none"
                            centerLabel
                            id="password"
                            initiallyValid
                            initialValue={password}
                            InputLeftElement={
                                <SecurityIcon
                                    color={
                                        !passwordIsValid
                                            ? "red.400"
                                            : "emerald.400"
                                    }
                                    ml="4"
                                    size="6"
                                />
                            }
                            invalidIndicator
                            label="Password (min 8 letters)"
                            minLength={8}
                            onFinishEditing={(
                                inputIdentifier,
                                inputValue,
                                isValid,
                            ) =>
                                pageInputChangeHandler(
                                    dispatchPageState,
                                    inputIdentifier,
                                    inputValue,
                                    isValid,
                                )
                            }
                            p="3"
                            placeholder={`pianofunclub${
                                page1State.inputValues.firstName
                                    ? "_" +
                                      page1State.inputValues.firstName
                                          .slice(0, 2)
                                          .toLowerCase()
                                    : ""
                            }`}
                            textAlign="center"
                        />
                    </Box>
                </>
            );
        },
        [page1State.inputValues.firstName],
    );

    const renderSchoolInputs = useCallback(
        (
            dispatchState: (value: FormAction<FormReducerTypes>) => void,
            schoolName?: string,
            schoolYear?: number,
        ) => {
            return (
                <HStack space="6">
                    <VStack alignItems="center" flex={1}>
                        <Text
                            color="surface.100"
                            fontSize="md"
                            mb="1"
                            textAlign="center"
                        >
                            School
                        </Text>
                        <Box>
                            <Select
                                bg="surface.100"
                                borderColor="surface.200"
                                borderRadius="2xl"
                                fontSize="md"
                                onValueChange={(itemValue) => {
                                    dispatchState({
                                        input: "schoolName",
                                        value: itemValue,
                                        isValid: true,
                                    });
                                }}
                                p="3"
                                placeholder="Select school"
                                selectedValue={schoolName}
                                textAlign="center"
                            >
                                {schools.map((item) => {
                                    return (
                                        <Select.Item
                                            key={item?.node?.name}
                                            actionSheetLabel={
                                                titleCaseConverter(
                                                    item?.node?.name,
                                                ) ?? ""
                                            }
                                            value={item?.node?.name ?? ""}
                                        />
                                    );
                                })}
                            </Select>
                        </Box>
                    </VStack>
                    <VStack alignItems="center" flex={1}>
                        <Text
                            color="surface.100"
                            fontSize="md"
                            mb="1"
                            textAlign="center"
                        >
                            School Year
                        </Text>
                        <Box>
                            <Select
                                bg="surface.100"
                                borderColor="surface.200"
                                borderRadius="2xl"
                                fontSize="md"
                                onValueChange={(itemValue) => {
                                    dispatchState({
                                        input: "schoolYear",
                                        value: itemValue,
                                        isValid: true,
                                    });
                                }}
                                p="3"
                                placeholder="Select school year"
                                selectedValue={String(schoolYear)}
                                textAlign="center"
                            >
                                {SCHOOL_YEARS.map((item) => {
                                    return (
                                        <Select.Item
                                            key={item.value}
                                            actionSheetLabel={item.label}
                                            value={item.value}
                                        />
                                    );
                                })}
                            </Select>
                        </Box>
                    </VStack>
                </HStack>
            );
        },
        [schools],
    );

    const renderProfileCard = useCallback(
        (item: Page4ReducerValues, index: number) => {
            return (
                <HStack
                    key={item.firstName + item.profileId}
                    bg="surface.100"
                    borderRadius="2xl"
                    px="4"
                    py="3"
                    shadow={1}
                >
                    <Text
                        fontFamily="Poppins-Regular"
                        fontSize="md"
                        numberOfLines={1}
                    >
                        {getFullName(item.firstName, item.lastName)}
                        {item.schoolName ? ` • ${item.schoolName}` : ""}
                    </Text>
                    <Button
                        _hover={{
                            bg: "transparent",
                            opacity: 0.8,
                        }}
                        _pressed={{
                            bg: "transparent",
                            opacity: 0.7,
                        }}
                        leftIcon={<CloseIcon color="muted.400" size="6" />}
                        ml="2"
                        onPress={() =>
                            addOrRemoveParentOrChildHandler(index, true)
                        }
                        p="1"
                        variant="ghost"
                    />
                </HStack>
            );
        },
        [addOrRemoveParentOrChildHandler],
    );

    const renderAddParentOrChild = useMemo(() => {
        return (
            <VStack
                alignItems="center"
                justifyContent="center"
                px="8"
                space="6"
            >
                <VStack alignItems="center" space="4">
                    {page3State.map((item, index) => {
                        if (item.firstName) {
                            return renderProfileCard(item, index);
                        }
                    })}
                </VStack>
                {(page1State.inputValues.accountType === "PUPIL" &&
                    page3State.length > 0) ||
                isAccountPage ? null : (
                    <Button
                        _hover={{
                            bg: "transparent",
                            opacity: 0.8,
                        }}
                        _pressed={{
                            bg: "transparent",
                            opacity: 0.7,
                        }}
                        _text={{
                            fontSize: "lg",
                            color: "primary.300",
                        }}
                        colorScheme="primary"
                        height="40px"
                        leftIcon={
                            <AddCircleIcon color="primary.300" size="6" />
                        }
                        onPress={() =>
                            dispatchState({
                                input: "pageIndex",
                                value: state.values.pageIndex + 1,
                            })
                        }
                        variant="ghost"
                    >
                        {page1State.inputValues.accountType === "PUPIL"
                            ? "Add Parent"
                            : `Add ${
                                  page3State.length === 0 ? "a" : "another"
                              } Child`}
                    </Button>
                )}
            </VStack>
        );
    }, [
        isAccountPage,
        page1State.inputValues.accountType,
        page3State,
        renderProfileCard,
        state.values.pageIndex,
    ]);

    const renderPage1Inputs = useMemo(() => {
        return (
            <VStack
                {...(isAccountPage
                    ? {
                          alignItems: "center",
                          justifyContent: "flex-start",
                          height: "505px",
                          px: "8",
                          space: "4",
                      }
                    : {
                          alignItems: "center",
                          justifyContent: "center",
                          px: "8",
                          space: "12",
                          height: "100%",
                      })}
            >
                {getAccountTypeEmoji(page1State.inputValues.accountType)}
                {!page1State.inputValues.profileId ? (
                    isAccountPage ? (
                        <>
                            <SearchBar
                                inputClearHandler={searchBarClearHandler}
                                inputOnChangeHandler={searchBarChangeHandler}
                                inputSearchHandler={searchBarChangeHandler}
                                placeholderText={`Search for an existing ${
                                    (!isAccountPage &&
                                        page1State.inputValues.accountType ===
                                            "PARENT") ||
                                    (isAccountPage &&
                                        page1State.inputValues.accountType ===
                                            "PUPIL")
                                        ? "child"
                                        : "parent"
                                }`}
                                searchText={state.values.searchTerm}
                                showSearchIcon
                                textInputProps={{
                                    textAlign: "center",
                                    p: "3",
                                }}
                            />
                            <VStack
                                alignItems="center"
                                justifyContent="center"
                                space="12"
                                width="100%"
                            >
                                {state.values.searchTerm !== "" ? (
                                    <Box
                                        bg="surface.100"
                                        borderRadius="2xl"
                                        height="295px"
                                        overflow="hidden"
                                        width="100%"
                                    >
                                        {renderSearchResults}
                                        {renderRefetchIndicator}
                                    </Box>
                                ) : (
                                    <>
                                        <Text
                                            color="surface.100"
                                            fontSize="lg"
                                            mt="12"
                                        >
                                            {`Or Create ${
                                                (!isAccountPage &&
                                                    page1State.inputValues
                                                        .accountType ===
                                                        "PARENT") ||
                                                (isAccountPage &&
                                                    page1State.inputValues
                                                        .accountType ===
                                                        "PUPIL")
                                                    ? "Child"
                                                    : "Parent"
                                            }`}
                                        </Text>
                                        {renderNameInputs(
                                            pageInputChangeHandler,
                                            dispatchPage1State,
                                            firstNameInputRefPage1,
                                            lastNameInputRefPage1,
                                            page1State.inputValues.firstName,
                                            page1State.inputValues.lastName,
                                        )}
                                    </>
                                )}
                            </VStack>
                        </>
                    ) : (
                        renderNameInputs(
                            pageInputChangeHandler,
                            dispatchPage1State,
                            firstNameInputRefPage1,
                            lastNameInputRefPage1,
                            page1State.inputValues.firstName,
                            page1State.inputValues.lastName,
                        )
                    )
                ) : (
                    <Center flex={1}>
                        {renderProfileCard(page1State.inputValues, 0)}
                    </Center>
                )}
                {!isAccountPage ? (
                    <VStack alignItems="center">
                        <Text
                            color="surface.100"
                            fontSize="md"
                            mb="1"
                            textAlign="center"
                        >
                            Account Type
                        </Text>
                        <Box width="60%">
                            <Select
                                bg="surface.100"
                                borderColor="surface.200"
                                borderRadius="2xl"
                                fontSize="md"
                                onValueChange={(itemValue) => {
                                    dispatchPage1State({
                                        input: "accountType",
                                        value: itemValue,
                                        isValid: true,
                                    });
                                    dispatchPage2State({ type: "RESET" });
                                    setPage3State([]);
                                }}
                                p="3"
                                placeholder="Select account type"
                                selectedValue={
                                    page1State.inputValues.accountType
                                }
                                textAlign="center"
                            >
                                {ACCOUNT_TYPES.map((item) => {
                                    return (
                                        <Select.Item
                                            key={item.value}
                                            actionSheetLabel={item.label}
                                            value={item.value}
                                        />
                                    );
                                })}
                            </Select>
                        </Box>
                    </VStack>
                ) : null}
            </VStack>
        );
    }, [
        isAccountPage,
        page1State.inputValues,
        pageInputChangeHandler,
        renderNameInputs,
        renderProfileCard,
        renderRefetchIndicator,
        renderSearchResults,
        searchBarChangeHandler,
        searchBarClearHandler,
        state.values.searchTerm,
    ]);

    const renderPage2Inputs = useMemo(() => {
        return (
            <VStack alignItems="center" px="8" space="6">
                {page1State.inputValues.accountType != "PUPIL" ? (
                    renderEmailPhoneAndPasswordInputs(
                        pageInputChangeHandler,
                        dispatchPage2State,
                        page2State.inputValues.email,
                        page2State.inputValidities.email,
                        page2State.inputValues.phoneNumber,
                        page2State.inputValidities.phoneNumber,
                        page2State.inputValues.password,
                        page2State.inputValidities.password,
                    )
                ) : (
                    <>
                        {renderSchoolInputs(
                            dispatchPage2State,
                            page2State.inputValues.schoolName,
                            page2State.inputValues.schoolYear,
                        )}
                        <HStack space="6">
                            <VStack alignItems="center" flex={1}>
                                <Text
                                    color="surface.100"
                                    fontSize="md"
                                    mb="1"
                                    textAlign="center"
                                >
                                    Lessons Paid by School?
                                </Text>
                                <Box>
                                    <Select
                                        bg="surface.100"
                                        borderColor="surface.200"
                                        borderRadius="2xl"
                                        fontSize="md"
                                        onValueChange={(itemValue) => {
                                            dispatchPage2State({
                                                input: "paidBySchoolSelect",
                                                value: itemValue === "YES",
                                                isValid: true,
                                            });
                                        }}
                                        p="3"
                                        placeholder="Lessons Paid by School?"
                                        selectedValue={
                                            page2State.inputValues
                                                .paidBySchoolSelect
                                                ? "YES"
                                                : "NO"
                                        }
                                        textAlign="center"
                                    >
                                        <Select.Item
                                            key={"YES"}
                                            actionSheetLabel={"Yes"}
                                            value={"YES"}
                                        />
                                        <Select.Item
                                            key={"NO"}
                                            actionSheetLabel={"No"}
                                            value={"NO"}
                                        />
                                    </Select>
                                </Box>
                            </VStack>
                            <VStack alignItems="center" flex={1}>
                                <Text
                                    color="surface.100"
                                    fontSize="md"
                                    mb="1"
                                    textAlign="center"
                                >
                                    Fee Type
                                </Text>
                                <Box>
                                    <Select
                                        bg="surface.100"
                                        borderColor="surface.200"
                                        borderRadius="2xl"
                                        fontSize="md"
                                        onValueChange={(itemValue) => {
                                            dispatchPage2State({
                                                input: "discountCategorySelect",
                                                value: itemValue,
                                                isValid: true,
                                            });
                                        }}
                                        p="3"
                                        placeholder="Select fee type"
                                        selectedValue={
                                            page2State.inputValues
                                                .discountCategorySelect
                                        }
                                        textAlign="center"
                                    >
                                        {DISCOUNT_CATEGORIES.map((item) => {
                                            return (
                                                <Select.Item
                                                    key={item.value}
                                                    actionSheetLabel={
                                                        item.label
                                                    }
                                                    value={item.value}
                                                />
                                            );
                                        })}
                                    </Select>
                                </Box>
                            </VStack>
                        </HStack>
                        {page2State.inputValues.discountCategorySelect?.includes(
                            "DISCOUNT",
                        ) ? (
                            <Box width="200px">
                                <PercentageInput
                                    autoCapitalize="none"
                                    centerLabel
                                    fontSize="md"
                                    id="discountPercentage"
                                    initiallyValid={true}
                                    initialValue={
                                        page2State.inputValues
                                            .discountPercentage
                                    }
                                    invalidIndicator
                                    label="Discount percentage"
                                    labelStyle={{
                                        color: "surface.100",
                                        fontSize: "md",
                                    }}
                                    onFinishEditing={(
                                        inputIdentifier,
                                        inputValue,
                                        isValid,
                                    ) =>
                                        pageInputChangeHandler(
                                            dispatchPage2State,
                                            inputIdentifier,
                                            inputValue,
                                            isValid,
                                        )
                                    }
                                    p="3"
                                    textAlign="center"
                                />
                            </Box>
                        ) : null}
                    </>
                )}
            </VStack>
        );
    }, [
        page1State.inputValues.accountType,
        page2State.inputValidities.email,
        page2State.inputValidities.password,
        page2State.inputValidities.phoneNumber,
        page2State.inputValues.discountCategorySelect,
        page2State.inputValues.discountPercentage,
        page2State.inputValues.email,
        page2State.inputValues.paidBySchoolSelect,
        page2State.inputValues.password,
        page2State.inputValues.phoneNumber,
        page2State.inputValues.schoolName,
        page2State.inputValues.schoolYear,
        pageInputChangeHandler,
        renderEmailPhoneAndPasswordInputs,
        renderSchoolInputs,
    ]);

    const renderPage4Inputs = useMemo(() => {
        return (
            <VStack
                alignItems="center"
                height="555px"
                justifyContent="flex-start"
                px="8"
                space="4"
            >
                <SearchBar
                    inputClearHandler={searchBarClearHandler}
                    inputOnChangeHandler={searchBarChangeHandler}
                    inputSearchHandler={searchBarChangeHandler}
                    placeholderText={`Search for an existing ${
                        page1State.inputValues.accountType === "PARENT"
                            ? "child"
                            : "parent"
                    }`}
                    searchText={state.values.searchTerm}
                    showSearchIcon
                    textInputProps={{
                        textAlign: "center",
                        p: "3",
                    }}
                />
                <VStack
                    alignItems="center"
                    flex={1}
                    justifyContent="center"
                    space="4"
                    width="100%"
                >
                    {state.values.searchTerm !== "" ? (
                        <Box
                            bg="surface.100"
                            borderRadius="2xl"
                            height="450px"
                            overflow="hidden"
                            width="100%"
                        >
                            {renderSearchResults}
                            {renderRefetchIndicator}
                        </Box>
                    ) : (
                        <>
                            <Text color="surface.100" fontSize="lg" mt="2">
                                {`Or Create ${
                                    page1State.inputValues.accountType ===
                                    "PARENT"
                                        ? "Child"
                                        : "Parent"
                                }`}
                            </Text>
                            {renderNameInputs(
                                pageInputChangeHandler,
                                dispatchPage4State,
                                firstNameInputRefPage4,
                                lastNameInputRefPage4,
                                page4State.inputValues.firstName,
                                page4State.inputValues.lastName,
                            )}
                            {page1State.inputValues.accountType === "PARENT"
                                ? renderSchoolInputs(
                                      dispatchPage4State,
                                      page4State.inputValues.schoolName,
                                      page4State.inputValues.schoolYear,
                                  )
                                : renderEmailPhoneAndPasswordInputs(
                                      pageInputChangeHandler,
                                      dispatchPage4State,
                                      page4State.inputValues.email,
                                      page4State.inputValidities.email,
                                      page4State.inputValues.phoneNumber,
                                      page4State.inputValidities.phoneNumber,
                                      page4State.inputValues.password,
                                      page4State.inputValidities.password,
                                  )}
                            <ButtonDebounced
                                _text={{ fontSize: "xl" }}
                                height="50px"
                                isDisabled={!page4State.formIsValid}
                                leftIcon={<AddCircleIcon mr="1" size="6" />}
                                mt="4"
                                onPress={() =>
                                    addOrRemoveParentOrChildHandler()
                                }
                                width="30%"
                            >
                                {"Add"}
                            </ButtonDebounced>
                        </>
                    )}
                </VStack>
            </VStack>
        );
    }, [
        state.values.searchTerm,
        page1State.inputValues.accountType,
        searchBarChangeHandler,
        searchBarClearHandler,
        renderSearchResults,
        renderRefetchIndicator,
        renderNameInputs,
        pageInputChangeHandler,
        page4State.inputValues.firstName,
        page4State.inputValues.lastName,
        page4State.inputValues.schoolName,
        page4State.inputValues.schoolYear,
        page4State.inputValues.email,
        page4State.inputValues.phoneNumber,
        page4State.inputValues.password,
        page4State.inputValidities.email,
        page4State.inputValidities.phoneNumber,
        page4State.inputValidities.password,
        page4State.formIsValid,
        renderSchoolInputs,
        renderEmailPhoneAndPasswordInputs,
        addOrRemoveParentOrChildHandler,
    ]);

    const renderAlertPopups = useMemo(() => {
        return (
            <>
                <AlertPopup
                    alertIsOpen={state.values.emailAlreadyInUseAlertIsOpen}
                    body="Are you sure this account hasn't been added already?"
                    header={"A user already exists with that email address"}
                    setAlertIsOpen={(isOpen: boolean) =>
                        dispatchState({
                            input: "emailAlreadyInUseAlertIsOpen",
                            value: isOpen,
                        })
                    }
                >
                    <Button
                        _text={{ fontSize: "lg" }}
                        colorScheme="primary"
                        minWidth="100"
                        onPress={() => {
                            dispatchState({
                                input: "emailAlreadyInUseAlertIsOpen",
                                value: false,
                            });
                        }}
                        width="40%"
                    >
                        Ok
                    </Button>
                </AlertPopup>
            </>
        );
    }, [state.values.emailAlreadyInUseAlertIsOpen]);

    return (
        <>
            <Modal
                closeOnOverlayClick={false}
                isOpen={showModal}
                onClose={() => {
                    setShowModal(false);
                }}
                size="xl"
            >
                <Modal.Content bg="primary.800" height="700px">
                    {state.values.pageIndex > 0 ? (
                        <BackButton
                            _hover={{ bg: "transparent", opacity: 0.8 }}
                            _pressed={{ bg: "transparent", opacity: 0.7 }}
                            iconProps={{ color: "surface.100" }}
                            left="2"
                            onPress={() =>
                                dispatchState({
                                    input: "pageIndex",
                                    value: state.values.pageIndex - 1,
                                })
                            }
                            position="absolute"
                            px="0.5"
                            py="1.5"
                            textProps={{ color: "surface.100" }}
                            top="3.5"
                            width="90"
                        />
                    ) : null}
                    <Modal.CloseButton
                        _hover={{ bg: "transparent", opacity: 0.7 }}
                        _icon={{ color: "surface.100" }}
                        _pressed={{ bg: "transparent", opacity: 0.7 }}
                    />
                    <Modal.Header
                        _text={{ color: "surface.100" }}
                        bg="primary.800"
                    >
                        {isAccountPage
                            ? page1State.inputValues.accountType === "PARENT"
                                ? "Add Parent"
                                : "Add Child"
                            : state.values.pageIndex < 2
                              ? "Create Account"
                              : page1State.inputValues.accountType === "PARENT"
                                ? "Add Children"
                                : "Add Parent"}
                    </Modal.Header>
                    <Modal.Body
                        _scrollview={{
                            contentContainerStyle: {
                                flexGrow: 1,
                                justifyContent: "center",
                            },
                        }}
                    >
                        {state.values.pageIndex === 0 ? (
                            <PresenceTransition
                                animate={{
                                    opacity: 1,
                                    transition: {
                                        duration: 800,
                                    },
                                }}
                                initial={{
                                    opacity: 0,
                                }}
                                visible={state.values.pageIndex === 0}
                            >
                                {renderPage1Inputs}
                            </PresenceTransition>
                        ) : null}
                        {state.values.pageIndex === 1 ? (
                            <PresenceTransition
                                animate={{
                                    opacity: 1,
                                    transition: {
                                        duration: 800,
                                    },
                                }}
                                initial={{
                                    opacity: 0,
                                }}
                                visible={state.values.pageIndex === 1}
                            >
                                {renderPage2Inputs}
                            </PresenceTransition>
                        ) : null}
                        {state.values.pageIndex === 2 ? (
                            <PresenceTransition
                                animate={{
                                    opacity: 1,
                                    transition: {
                                        duration: 800,
                                    },
                                }}
                                initial={{
                                    opacity: 0,
                                }}
                                visible={state.values.pageIndex === 2}
                            >
                                {renderAddParentOrChild}
                            </PresenceTransition>
                        ) : null}
                        {state.values.pageIndex === 3 ? (
                            <PresenceTransition
                                animate={{
                                    opacity: 1,
                                    transition: {
                                        duration: 800,
                                    },
                                }}
                                initial={{
                                    opacity: 0,
                                }}
                                visible={state.values.pageIndex === 3}
                            >
                                {renderPage4Inputs}
                            </PresenceTransition>
                        ) : null}
                    </Modal.Body>
                    <Center pb="6" pt="7">
                        {(state.values.pageIndex == 0 &&
                            isAccountPage &&
                            page1State.inputValues.profileId) ||
                        (state.values.pageIndex == 1 &&
                            (["STAFF", "TEACHER"].includes(
                                page1State.inputValues.accountType,
                            ) ||
                                isAccountPage)) ||
                        (state.values.pageIndex == 2 &&
                            ["PARENT", "PUPIL"].includes(
                                page1State.inputValues.accountType,
                            )) ? (
                            <ButtonDebounced
                                _spinner={{ mr: "1" }}
                                _text={{ fontSize: "xl" }}
                                height="50px"
                                isDisabled={
                                    state.values.pageIndex === 1 &&
                                    !page2State.formIsValid
                                }
                                isLoading={createAccountInFlight}
                                isLoadingText={isAccountPage ? "Add" : "Create"}
                                leftIcon={<CompleteIcon mr="1" size="5" />}
                                onPress={createAccountHandler}
                                width="200px"
                            >
                                {isAccountPage ? "Add" : "Create"}
                            </ButtonDebounced>
                        ) : state.values.pageIndex < 3 ? (
                            <ButtonDebounced
                                _text={{ fontSize: "xl" }}
                                height="50px"
                                isDisabled={
                                    (state.values.pageIndex === 0 &&
                                        !page1State.formIsValid) ||
                                    (state.values.pageIndex === 1 &&
                                        !page2State.formIsValid)
                                }
                                onPress={() => {
                                    dispatchState({
                                        input: "pageIndex",
                                        value: state.values.pageIndex + 1,
                                    });
                                }}
                                width="30%"
                            >
                                {"Next"}
                            </ButtonDebounced>
                        ) : null}
                    </Center>
                </Modal.Content>
            </Modal>
            {renderAlertPopups}
        </>
    );
};

const CreateAccountModalWrapper = (
    props: Omit<Props, "loadAccountsQueryReference">,
): ReactElement => {
    const { initialAccountType, isAccountPage } = props;

    const [loadAccountsQueryReference, loadAccountsQuery] =
        useQueryLoader<LoadAccountsForModalQuery>(load_accounts_for_modal);

    useEffect(() => {
        loadAccountsQuery(
            {
                accountType: isAccountPage
                    ? initialAccountType === "PUPIL"
                        ? "PUPIL"
                        : "PARENT"
                    : initialAccountType === "PUPIL"
                      ? "PARENT"
                      : "PUPIL",
                first: LOAD_X_PROFILES,
                orderBy: "user__firstName,user__lastName",
            },
            { fetchPolicy: "store-or-network" },
        );
    }, [initialAccountType, isAccountPage, loadAccountsQuery]);

    return loadAccountsQueryReference != null ? (
        <Suspense fallback={<></>}>
            <CreateAccountModal
                loadAccountsQueryReference={loadAccountsQueryReference}
                {...props}
            />
        </Suspense>
    ) : (
        <></>
    );
};

export default React.memo(CreateAccountModalWrapper);
