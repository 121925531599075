/**
 * @generated SignedSource<<ba3612da17c12fc26ccd830a2cddcb13>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GenerateOrUpdateDraftInvoicesForBlockInput = {
  block: number;
  clientMutationId?: string | null;
  invoicesInBatch: number;
  numberOfDraftsGenerated?: number | null;
  numberOfLessonBlocksProcessed?: number | null;
  schoolIds: ReadonlyArray<string | null>;
  startingYear: number;
  totalToGenerate?: number | null;
};
export type GenerateOrUpdateDraftInvoicesForBlockMutation$variables = {
  block: number;
  input: GenerateOrUpdateDraftInvoicesForBlockInput;
  startingYear: number;
};
export type GenerateOrUpdateDraftInvoicesForBlockMutation$data = {
  readonly generateOrUpdateDraftInvoicesForBlock: {
    readonly erroredInvoice: {
      readonly assignedTo: {
        readonly accountNumber: string | null;
        readonly id: string;
        readonly user: {
          readonly email: string;
          readonly firstName: string;
          readonly lastName: string;
        };
      } | null;
      readonly id: string;
    } | null;
    readonly errors: any | null;
    readonly numberOfDraftsGenerated: number | null;
    readonly numberOfLessonBlocksProcessed: number | null;
    readonly profile: {
      readonly id: string;
      readonly lessonBlocksNotInvoicedFor: ReadonlyArray<{
        readonly id: string;
        readonly pupil: {
          readonly id: string;
          readonly user: {
            readonly firstName: string;
            readonly id: string;
            readonly lastName: string;
          };
        } | null;
      } | null> | null;
      readonly percentLessonBlocksInvoicedFor: number | null;
      readonly profileGroup: {
        readonly generateDraftsProgress: number | null;
        readonly id: string;
      } | null;
      readonly totalAmountInvoiced: number | null;
      readonly totalAmountPaid: number | null;
      readonly totalInvoices: number | null;
      readonly totalInvoicesSent: number | null;
      readonly totalInvoicesUnpaid: number | null;
    } | null;
    readonly success: boolean | null;
    readonly totalToGenerate: number | null;
  } | null;
};
export type GenerateOrUpdateDraftInvoicesForBlockMutation = {
  response: GenerateOrUpdateDraftInvoicesForBlockMutation$data;
  variables: GenerateOrUpdateDraftInvoicesForBlockMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "block"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startingYear"
},
v3 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfLessonBlocksProcessed",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfDraftsGenerated",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalToGenerate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumber",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v14 = [
  {
    "kind": "Variable",
    "name": "block",
    "variableName": "block"
  },
  {
    "kind": "Variable",
    "name": "startingYear",
    "variableName": "startingYear"
  }
],
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "ProfileNode",
  "kind": "LinkedField",
  "name": "profile",
  "plural": false,
  "selections": [
    (v9/*: any*/),
    {
      "alias": null,
      "args": (v14/*: any*/),
      "kind": "ScalarField",
      "name": "totalInvoices",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v14/*: any*/),
      "kind": "ScalarField",
      "name": "totalAmountInvoiced",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v14/*: any*/),
      "kind": "ScalarField",
      "name": "totalInvoicesSent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v14/*: any*/),
      "kind": "ScalarField",
      "name": "totalAmountPaid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v14/*: any*/),
      "kind": "ScalarField",
      "name": "totalInvoicesUnpaid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v14/*: any*/),
      "kind": "ScalarField",
      "name": "percentLessonBlocksInvoicedFor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v14/*: any*/),
      "concreteType": "LessonBlockNode",
      "kind": "LinkedField",
      "name": "lessonBlocksNotInvoicedFor",
      "plural": true,
      "selections": [
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileNode",
          "kind": "LinkedField",
          "name": "pupil",
          "plural": false,
          "selections": [
            (v9/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "DjangoUserNode",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                (v9/*: any*/),
                (v11/*: any*/),
                (v12/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileGroupNode",
      "kind": "LinkedField",
      "name": "profileGroup",
      "plural": false,
      "selections": [
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "generateDraftsProgress",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GenerateOrUpdateDraftInvoicesForBlockMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "GenerateOrUpdateDraftInvoicesForBlockPayload",
        "kind": "LinkedField",
        "name": "generateOrUpdateDraftInvoicesForBlock",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceNode",
            "kind": "LinkedField",
            "name": "erroredInvoice",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileNode",
                "kind": "LinkedField",
                "name": "assignedTo",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DjangoUserNode",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GenerateOrUpdateDraftInvoicesForBlockMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "GenerateOrUpdateDraftInvoicesForBlockPayload",
        "kind": "LinkedField",
        "name": "generateOrUpdateDraftInvoicesForBlock",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceNode",
            "kind": "LinkedField",
            "name": "erroredInvoice",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileNode",
                "kind": "LinkedField",
                "name": "assignedTo",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DjangoUserNode",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "db25cfcfaa8aaa9727a5f5d3a5bae361",
    "id": null,
    "metadata": {},
    "name": "GenerateOrUpdateDraftInvoicesForBlockMutation",
    "operationKind": "mutation",
    "text": "mutation GenerateOrUpdateDraftInvoicesForBlockMutation(\n  $input: GenerateOrUpdateDraftInvoicesForBlockInput!\n  $startingYear: Int!\n  $block: Int!\n) {\n  generateOrUpdateDraftInvoicesForBlock(input: $input) {\n    success\n    errors\n    numberOfLessonBlocksProcessed\n    numberOfDraftsGenerated\n    totalToGenerate\n    erroredInvoice {\n      id\n      assignedTo {\n        id\n        accountNumber\n        user {\n          firstName\n          lastName\n          email\n          id\n        }\n      }\n    }\n    profile {\n      id\n      totalInvoices(startingYear: $startingYear, block: $block)\n      totalAmountInvoiced(startingYear: $startingYear, block: $block)\n      totalInvoicesSent(startingYear: $startingYear, block: $block)\n      totalAmountPaid(startingYear: $startingYear, block: $block)\n      totalInvoicesUnpaid(startingYear: $startingYear, block: $block)\n      percentLessonBlocksInvoicedFor(startingYear: $startingYear, block: $block)\n      lessonBlocksNotInvoicedFor(startingYear: $startingYear, block: $block) {\n        id\n        pupil {\n          id\n          user {\n            id\n            firstName\n            lastName\n          }\n        }\n      }\n      profileGroup {\n        id\n        generateDraftsProgress\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cf72e9f7ebe27cfaf81cd1c5d3cf0f8e";

export default node;
