import React, {
    useCallback,
    useState,
    useMemo,
    useReducer,
    useRef,
} from "react";
import type { ReactElement } from "react";

import emoji from "emoji-dictionary";
import {
    Modal,
    VStack,
    Box,
    useToast,
    PresenceTransition,
    Center,
    Text,
    Spinner,
} from "native-base";
import { useMutation } from "react-relay";

import BackButton from "../Buttons/BackButton";
import ButtonDebounced from "pianofunclub-shared/components/Buttons/ButtonDebounced";
import TextInput from "pianofunclub-shared/components/Inputs/TextInput";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import { CompleteIcon } from "pianofunclub-shared/components/Other/Icons";

import type {
    CreateSchoolMutation,
    CreateSchoolMutation$data,
} from "pianofunclub-shared/relay/graphql/schools/__generated__/CreateSchoolMutation.graphql";
import { create_school } from "pianofunclub-shared/relay/graphql/schools/CreateSchool";

import { createFormReducer } from "pianofunclub-shared/utils/reducers";

interface Props {
    schoolsConnectionId?: string;
    schoolsForDropdownConnectionId?: string;
    setShowModal: (value: boolean) => void;
    showModal: boolean;
}

type FormReducerValues = {
    addressFirstLine?: string;
    addressSecondLine?: string;
    city?: string;
    name: string;
    postcode?: string;
};

type FormReducerTypes = string | number | boolean | undefined;

const CreateSchoolModal = (props: Props): ReactElement => {
    const {
        schoolsConnectionId,
        schoolsForDropdownConnectionId,
        setShowModal,
        showModal,
    } = props;

    const [pageIndex, setPageIndex] = useState(0);

    const formInitialState = useMemo(() => {
        return {
            inputValues: {
                name: "",
                addressFirstLine: undefined,
                addressSecondLine: undefined,
                city: undefined,
                postcode: undefined,
            },
            inputValidities: {
                name: false,
                addressFirstLine: true,
                addressSecondLine: true,
                city: true,
                postcode: true,
            },
            formIsValid: false,
            formIsEdited: false,
        };
    }, []);

    const reducer = createFormReducer<FormReducerValues, FormReducerTypes>(
        formInitialState,
    );
    const [formState, dispatchFormState] = useReducer(
        reducer,
        formInitialState,
    );

    const toast = useToast();

    const [commitCreateSchool, createSchoolInFlight] =
        useMutation<CreateSchoolMutation>(create_school);

    const createSchool = useCallback(
        (input: {
            addressFirstLine?: string;
            addressSecondLine?: string;
            city?: string;
            name: string;
            postcode?: string;
        }) => {
            const connections = schoolsConnectionId
                ? [schoolsConnectionId]
                : [];
            if (schoolsForDropdownConnectionId) {
                connections.push(schoolsForDropdownConnectionId);
            }
            const createSchoolConfig = {
                variables: {
                    connections: connections,
                    input: input,
                },
                onCompleted: (response: CreateSchoolMutation$data) => {
                    if (response?.createSchool?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={"School created"}
                                    toast={toast}
                                />
                            ),
                        });
                        setShowModal(false);
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't create school"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitCreateSchool(createSchoolConfig);
        },
        [
            commitCreateSchool,
            schoolsConnectionId,
            schoolsForDropdownConnectionId,
            setShowModal,
            toast,
        ],
    );

    const createSchoolHandler = useCallback(() => {
        if (formState.inputValues.name && !createSchoolInFlight) {
            createSchool(formState.inputValues);
        }
    }, [createSchool, createSchoolInFlight, formState.inputValues]);

    const addressSecondLineInputRef = useRef<{ focus: () => void }>(null);
    const cityInputRef = useRef<{ focus: () => void }>(null);
    const postcodeInputRef = useRef<{ focus: () => void }>(null);

    const inputChangeHandler = useCallback(
        (
            inputIdentifier?: string | number,
            inputValue?: string,
            isValid?: boolean,
        ) => {
            if (
                typeof inputIdentifier === "string" &&
                typeof inputValue === "string"
            ) {
                dispatchFormState({
                    input: inputIdentifier,
                    value: inputValue,
                    isValid: isValid,
                });
            }
        },
        [],
    );

    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={showModal}
            onClose={() => {
                setShowModal(false);
            }}
            size="xl"
        >
            <Modal.Content bg="primary.800" height="500px">
                {pageIndex !== 0 ? (
                    <BackButton
                        _hover={{ bg: "transparent", opacity: 0.8 }}
                        _pressed={{ bg: "transparent", opacity: 0.7 }}
                        iconProps={{ color: "surface.100" }}
                        left="2"
                        onPress={() => setPageIndex(pageIndex - 1)}
                        position="absolute"
                        px="0.5"
                        py="1.5"
                        textProps={{ color: "surface.100" }}
                        top="3.5"
                        width="90"
                    />
                ) : null}
                <Modal.CloseButton
                    _hover={{ bg: "transparent", opacity: 0.7 }}
                    _icon={{ color: "surface.100" }}
                    _pressed={{ bg: "transparent", opacity: 0.7 }}
                />
                <Modal.Header _text={{ color: "surface.100" }} bg="primary.800">
                    {"Create School"}
                </Modal.Header>
                <Modal.Body
                    _scrollview={{
                        contentContainerStyle: {
                            flexGrow: 1,
                            justifyContent: "center",
                            alignItems: "center",
                        },
                    }}
                >
                    {pageIndex === 0 ? (
                        <PresenceTransition
                            animate={{
                                opacity: 1,
                                transition: {
                                    duration: 800,
                                },
                            }}
                            initial={{
                                opacity: 0,
                            }}
                            visible={pageIndex === 0}
                        >
                            <VStack
                                alignItems="center"
                                height="100%"
                                justifyContent="center"
                                px="8"
                                space="12"
                            >
                                <Text fontSize="8xl" textAlign="center">
                                    {emoji.getUnicode("school")}
                                </Text>
                                <Box>
                                    <TextInput
                                        autoCapitalize="words"
                                        centerLabel
                                        id="name"
                                        initialValue={
                                            formState.inputValues.name
                                        }
                                        keyboardType="default"
                                        label="School Name"
                                        minLength={1}
                                        onInputChange={inputChangeHandler}
                                        onSubmit={() => setPageIndex(1)}
                                        p="3"
                                        textAlign="center"
                                    />
                                </Box>
                            </VStack>
                        </PresenceTransition>
                    ) : null}
                    {pageIndex === 1 ? (
                        <PresenceTransition
                            animate={{
                                opacity: 1,
                                transition: {
                                    duration: 800,
                                },
                            }}
                            initial={{
                                opacity: 0,
                            }}
                            visible={pageIndex === 1}
                        >
                            <VStack
                                alignItems="center"
                                justifyContent="center"
                                px="8"
                                space="4"
                            >
                                <TextInput
                                    autoCapitalize="words"
                                    centerLabel
                                    id="addressFirstLine"
                                    initiallyValid
                                    initialValue={
                                        formState.inputValues.addressFirstLine
                                    }
                                    invalidIndicator
                                    label="Address"
                                    maxLength={200}
                                    onInputChange={inputChangeHandler}
                                    onSubmit={() =>
                                        addressSecondLineInputRef.current?.focus()
                                    }
                                    p="3"
                                    placeholder="1st line address"
                                    textAlign="center"
                                />
                                <TextInput
                                    ref={addressSecondLineInputRef}
                                    autoCapitalize="words"
                                    id="addressSecondLine"
                                    initiallyValid
                                    initialValue={
                                        formState.inputValues.addressSecondLine
                                    }
                                    invalidIndicator
                                    maxLength={200}
                                    onInputChange={inputChangeHandler}
                                    onSubmit={() =>
                                        cityInputRef.current?.focus()
                                    }
                                    p="3"
                                    placeholder="2nd line address"
                                    textAlign="center"
                                />
                                <TextInput
                                    ref={cityInputRef}
                                    autoCapitalize="words"
                                    id="city"
                                    initiallyValid
                                    initialValue={formState.inputValues.city}
                                    invalidIndicator
                                    maxLength={50}
                                    onInputChange={inputChangeHandler}
                                    onSubmit={() =>
                                        postcodeInputRef.current?.focus()
                                    }
                                    p="3"
                                    placeholder="City or county"
                                    textAlign="center"
                                />
                                <TextInput
                                    ref={postcodeInputRef}
                                    autoCapitalize="characters"
                                    id="postcode"
                                    initiallyValid
                                    initialValue={
                                        formState.inputValues.postcode
                                    }
                                    invalidIndicator
                                    maxLength={10}
                                    onInputChange={inputChangeHandler}
                                    p="3"
                                    placeholder="Postcode"
                                    textAlign="center"
                                />
                            </VStack>
                        </PresenceTransition>
                    ) : null}
                </Modal.Body>
                <Center pb="6" pt="7">
                    {pageIndex == 1 ? (
                        <ButtonDebounced
                            _text={{ fontSize: "xl" }}
                            height="50px"
                            isDisabled={!formState.formIsValid}
                            leftIcon={
                                <Center mr="2" size="6">
                                    {createSchoolInFlight ? (
                                        <Spinner color="white" />
                                    ) : (
                                        <CompleteIcon color="white" size="6" />
                                    )}
                                </Center>
                            }
                            onPress={createSchoolHandler}
                            width="30%"
                        >
                            {"Create"}
                        </ButtonDebounced>
                    ) : (
                        <ButtonDebounced
                            _text={{ fontSize: "xl" }}
                            height="50px"
                            isDisabled={!formState.formIsValid}
                            onPress={() => {
                                setPageIndex(pageIndex + 1);
                            }}
                            width="30%"
                        >
                            {"Next"}
                        </ButtonDebounced>
                    )}
                </Center>
            </Modal.Content>
        </Modal>
    );
};

export default React.memo(CreateSchoolModal);
