/**
 * @generated SignedSource<<a016b4510e8b9e22bd14d1359ffa55be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LessonModelStatus = "BLANK" | "BREAK" | "LEFT_SCHOOL" | "MISSED" | "NO_LESSON" | "OTHER_TEACHER" | "PRESENT" | "PRESENT_DOUBLE" | "PUPIL_ABSENT" | "STOPPED_LESSONS" | "TEACHER_ABSENT" | "%future added value";
export type LessonStageModelLessonType = "GROUP" | "INDIVIDUAL" | "PAIRED" | "%future added value";
export type LoadLessonsQuery$variables = {
  endDate?: string | null;
  orderBy?: string | null;
  profileId: string;
  skip: boolean;
  startDate: string;
};
export type LoadLessonsQuery$data = {
  readonly lessons?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly lessonBlock: {
          readonly block: number | null;
          readonly id: string;
          readonly instrument: {
            readonly name: string | null;
          } | null;
          readonly lessonStage: {
            readonly id: string;
            readonly lessonDuration: number | null;
            readonly lessonType: LessonStageModelLessonType;
            readonly stage: number | null;
          } | null;
          readonly pupil: {
            readonly id: string;
            readonly schoolYear: number | null;
            readonly staffNoteForTeacher: string | null;
            readonly teacherNoteForStaff: string | null;
            readonly user: {
              readonly firstName: string;
              readonly lastName: string;
            };
          } | null;
          readonly school: {
            readonly id: string;
            readonly mainOfficeContact: {
              readonly addressFirstLine: string | null;
              readonly addressSecondLine: string | null;
              readonly city: string | null;
              readonly id: string;
              readonly postcode: string | null;
            } | null;
            readonly name: string;
          } | null;
          readonly startingYear: number | null;
        } | null;
        readonly lessonDuration: number | null;
        readonly rearrangedTimestamp: string | null;
        readonly scheduledTimestamp: string | null;
        readonly staffRegisterNoteForTeacher: string | null;
        readonly status: LessonModelStatus;
        readonly teacherRegisterNoteForStaff: string | null;
      } | null;
    } | null>;
  } | null;
};
export type LoadLessonsQuery = {
  response: LoadLessonsQuery$data;
  variables: LoadLessonsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "profileId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v5 = [
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "endDate"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "profileId",
    "variableName": "profileId"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "startDate"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scheduledTimestamp",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rearrangedTimestamp",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teacherRegisterNoteForStaff",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "staffRegisterNoteForTeacher",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lessonDuration",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startingYear",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "block",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "SchoolNode",
  "kind": "LinkedField",
  "name": "school",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v15/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileNode",
      "kind": "LinkedField",
      "name": "mainOfficeContact",
      "plural": false,
      "selections": [
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressFirstLine",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressSecondLine",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postcode",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "schoolYear",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "staffNoteForTeacher",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teacherNoteForStaff",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "LessonStageNode",
  "kind": "LinkedField",
  "name": "lessonStage",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lessonType",
      "storageKey": null
    },
    (v12/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoadLessonsQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "LessonNodeConnection",
            "kind": "LinkedField",
            "name": "lessons",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LessonNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LessonNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LessonBlockNode",
                        "kind": "LinkedField",
                        "name": "lessonBlock",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v16/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProfileNode",
                            "kind": "LinkedField",
                            "name": "pupil",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v17/*: any*/),
                              (v18/*: any*/),
                              (v19/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "DjangoUserNode",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v20/*: any*/),
                                  (v21/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "InstrumentNode",
                            "kind": "LinkedField",
                            "name": "instrument",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v22/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "LoadLessonsQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "LessonNodeConnection",
            "kind": "LinkedField",
            "name": "lessons",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LessonNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LessonNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LessonBlockNode",
                        "kind": "LinkedField",
                        "name": "lessonBlock",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v16/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProfileNode",
                            "kind": "LinkedField",
                            "name": "pupil",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v17/*: any*/),
                              (v18/*: any*/),
                              (v19/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "DjangoUserNode",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v20/*: any*/),
                                  (v21/*: any*/),
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "InstrumentNode",
                            "kind": "LinkedField",
                            "name": "instrument",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v22/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "909dfbd94d083646d24c045455398a55",
    "id": null,
    "metadata": {},
    "name": "LoadLessonsQuery",
    "operationKind": "query",
    "text": "query LoadLessonsQuery(\n  $profileId: ID!\n  $startDate: String!\n  $endDate: String\n  $orderBy: String\n  $skip: Boolean!\n) {\n  lessons(profileId: $profileId, startDate: $startDate, endDate: $endDate, orderBy: $orderBy) @skip(if: $skip) {\n    edges {\n      node {\n        id\n        scheduledTimestamp\n        rearrangedTimestamp\n        status\n        teacherRegisterNoteForStaff\n        staffRegisterNoteForTeacher\n        lessonDuration\n        lessonBlock {\n          id\n          startingYear\n          block\n          school {\n            id\n            name\n            mainOfficeContact {\n              id\n              addressFirstLine\n              addressSecondLine\n              city\n              postcode\n            }\n          }\n          pupil {\n            id\n            schoolYear\n            staffNoteForTeacher\n            teacherNoteForStaff\n            user {\n              firstName\n              lastName\n              id\n            }\n          }\n          instrument {\n            name\n            id\n          }\n          lessonStage {\n            id\n            stage\n            lessonType\n            lessonDuration\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "067944b5ddf7e0319bdd87dc747e40c6";

export default node;
