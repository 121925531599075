import { graphql } from "react-relay";

export const load_payslip = graphql`
    query LoadPayslipQuery($payslipId: ID!) {
        payslip(id: $payslipId) {
            id
            amountDue
            status
            amountAdjusted
            adjustedReason
            paidFromDate
            paidToDate
            totalMinutesTaught
            teacher {
                id
                user {
                    firstName
                    lastName
                    email
                }
                accountNumber
            }
        }
    }
`;
