import React, {
    Suspense,
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
    useState,
} from "react";
import type { FC, ReactElement, Dispatch } from "react";

import emoji from "emoji-dictionary";
import * as Clipboard from "expo-clipboard";
import {
    Box,
    Heading,
    VStack,
    HStack,
    Button,
    Text,
    Center,
    useTheme,
    useDisclose,
    useToast,
} from "native-base";
import { useWindowDimensions } from "react-native";
import {
    useQueryLoader,
    usePreloadedQuery,
    usePaginationFragment,
    useRelayEnvironment,
} from "react-relay";
import { useRefetchableFragment } from "react-relay";
import type { PreloadedQuery } from "react-relay";
import {
    RecyclerListView,
    DataProvider,
    LayoutProvider,
} from "recyclerlistview";
import { fetchQuery } from "relay-runtime";
import type { Subscription } from "relay-runtime";

import { useAuth } from "pianofunclub-shared/providers/AuthProvider";

import PayslipTableRow from "../../components/ListItems/PayslipTableRow";
import CreatePayslipModal, {
    Mode,
} from "../../components/Modals/CreatePayslipModal";
import type {
    PayrollStackNavigatorProps,
    PayrollStackRouteProps,
} from "../../navigation/PayrollNavigator";
import { payrollTableCsvConverter } from "../../utils/tableCsvConverters";
import LoadingBlobs from "pianofunclub-shared/components/Animations/LoadingBlobs";
import Column from "pianofunclub-shared/components/Base/Column";
import Row from "pianofunclub-shared/components/Base/Row";
import ListEmptyBanner from "pianofunclub-shared/components/ListItems/ListEmptyBanner";
import TeacherMultiSelectModal from "pianofunclub-shared/components/Modals/TeacherMultiSelectModal";
import Actionsheet from "pianofunclub-shared/components/NativeBaseExtended/Actionsheet";
import Select from "pianofunclub-shared/components/NativeBaseExtended/Select";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import CustomFlatListSpinner from "pianofunclub-shared/components/Other/CustomFlatListSpinner";
import {
    PayrollIcon,
    CopyIcon,
    RestartIcon,
    PayrollEstimateIcon,
    PayrateIcon,
} from "pianofunclub-shared/components/Other/Icons";
import SearchBar from "pianofunclub-shared/components/Other/SearchBar";

import type { PayrollHeadlineDataFragment_query$key } from "pianofunclub-shared/relay/graphql/fragments/__generated__/PayrollHeadlineDataFragment_query.graphql";
import type { PayrollHeadlineDataRefreshQuery } from "pianofunclub-shared/relay/graphql/fragments/__generated__/PayrollHeadlineDataRefreshQuery.graphql";
import { payroll_headline_data_fragment } from "pianofunclub-shared/relay/graphql/fragments/PayrollHeadlineDataFragment";
import type {
    LoadPayslips_query_payslips$data,
    LoadPayslips_query_payslips$key,
} from "pianofunclub-shared/relay/graphql/payslips/__generated__/LoadPayslips_query_payslips.graphql";
import type { LoadPayslipsPaginationQuery } from "pianofunclub-shared/relay/graphql/payslips/__generated__/LoadPayslipsPaginationQuery.graphql";
import type { LoadPayslipsQuery } from "pianofunclub-shared/relay/graphql/payslips/__generated__/LoadPayslipsQuery.graphql";
import {
    load_payslips_pagination,
    load_payslips,
} from "pianofunclub-shared/relay/graphql/payslips/LoadPayslips";

import type { Mutable } from "pianofunclub-shared/types";
import { MONTHS } from "pianofunclub-shared/utils/constants";
import {
    formattedDayMonthYear,
    getScaledWindowDimension,
    monthFirstDay,
    monthLastDay,
} from "pianofunclub-shared/utils/converters";
import {
    getOrderBy,
    sortIconExtractor,
} from "pianofunclub-shared/utils/extractors";
import { useDebounceFunction } from "pianofunclub-shared/utils/hooks";
import { createReducer } from "pianofunclub-shared/utils/reducers";
import type { State, Action } from "pianofunclub-shared/utils/reducers";

type NavigationProps = PayrollStackNavigatorProps<"PayrollHub">;

type RouteProps = PayrollStackRouteProps<"PayrollHub">;

interface ScreenProps {
    navigation: NavigationProps;
    route: RouteProps;
}

const LOAD_X_PAYSLIPS = 50;
const TABLE_BORDER_COLOR = "surface.400";
const TABLE_BORDER_WIDTH = 1;
const TABLE_BORDER_RADIUS = 10;
const FLEX_ARRAY = [0.2, 0.5, 0.5, 0.4, 0.4, 0.3];

interface ContentProps {
    dispatchState: Dispatch<Action<ReducerValues, ReducerTypes>>;
    loadPayslipsQueryReference: PreloadedQuery<
        LoadPayslipsQuery,
        Record<string, unknown>
    >;
    navigation: NavigationProps;
    state: State<ReducerValues>;
}

type ReducerValues = {
    bulkUploadEnrolmentsModalIsOpen?: boolean;
    contentIsRendered: boolean;
    copyDataToClipboard?: () => void;
    createPayslipModalIsOpen?: boolean;
    dataProvider: DataProvider;
    generateEstimatedPayrollModalIsOpen?: boolean;
    generateTeacherPayslipModalIsOpen?: boolean;
    headlineData: {
        data: string | number;
        onPress?: () => void;
        tooltipLabel?: string;
    }[][];
    isRefetching: boolean;
    layoutProvider?: LayoutProvider;
    month: number;
    orderBy?: string;
    refreshHandler?: () => void;
    searchTerm: string;
    showCreateSchoolModal?: boolean;
    subscription?: Subscription;
    teacherIds?: string[];
    year: number;
};

type ReducerTypes =
    | string
    | number
    | boolean
    | { data: string | number; onPress?: () => void; tooltipLabel?: string }[][]
    | Subscription
    | DataProvider
    | LayoutProvider
    | (() => void)
    | string[]
    | undefined;

const PayrollContent: FC<ContentProps> = (props) => {
    const { dispatchState, loadPayslipsQueryReference, navigation, state } =
        props;

    const data = usePreloadedQuery(load_payslips, loadPayslipsQueryReference);

    const { user } = useAuth();

    const [payrollHeadlineData, refetchPayrollHeadlineData] =
        useRefetchableFragment<
            PayrollHeadlineDataRefreshQuery,
            PayrollHeadlineDataFragment_query$key
        >(payroll_headline_data_fragment, data);

    const {
        data: payslipsData,
        hasNext,
        isLoadingNext,
        loadNext,
        refetch: refresh,
    } = usePaginationFragment<
        LoadPayslipsPaginationQuery,
        LoadPayslips_query_payslips$key
    >(load_payslips_pagination, data);

    const toast = useToast();

    const {
        isOpen: payrollActionsActionsheetIsOpen,
        onClose: payrollActionsActionsheetOnClose,
        onOpen: payrollActionsActionsheetOnOpen,
    } = useDisclose();

    useEffect(() => {
        if (
            payslipsData.payslips?.edges &&
            payslipsData.payslips.edges.length > 0
        ) {
            dispatchState({
                input: "dataProvider",
                value: state.values.dataProvider.cloneWithRows(
                    payslipsData.payslips.edges as Mutable<
                        typeof payslipsData.payslips.edges
                    >,
                ),
            });

            setCopyDataToClipboard(() => () => {
                Clipboard.setStringAsync(
                    payrollTableCsvConverter(
                        payslipsData.payslips?.edges ?? [],
                    ),
                );
                toast.show({
                    render: ({ id }: { id: string }) => (
                        <ToastAlert
                            id={id}
                            status="success"
                            title={"Copied Data to Clipboard"}
                            toast={toast}
                        />
                    ),
                });
            });
        } else {
            dispatchState({
                input: "dataProvider",
                value: new DataProvider((r1, r2) => {
                    return r1 !== r2;
                }),
            });
        }

        dispatchState({
            input: "refreshHandler",
            value: () => {
                dispatchState({ input: "contentIsRendered", value: false });
                refresh(
                    {
                        first: LOAD_X_PAYSLIPS,
                        searchTerm: state.values.searchTerm.trim(),
                        dateFrom: monthFirstDay(
                            state.values.year,
                            state.values.month,
                        ),
                        dateTo: monthLastDay(
                            state.values.year,
                            state.values.month,
                        ),
                    },
                    {
                        fetchPolicy: "network-only",
                    },
                );
                refetchPayrollHeadlineData(
                    {
                        dateFrom: monthFirstDay(
                            state.values.year,
                            state.values.month,
                        ),
                        dateTo: monthLastDay(
                            state.values.year,
                            state.values.month,
                        ),
                    },
                    {
                        fetchPolicy: "network-only",
                    },
                );
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payslipsData]);

    // update the headline stats
    useEffect(() => {
        if (
            user?.profile?.profileGroup &&
            user.profile.profileGroup.generatedEstimatedPayoutTotalFromDate &&
            user.profile.profileGroup.generatedEstimatedPayoutTotalToDate
        ) {
            const estimatedTotalDateString = `(${formattedDayMonthYear(
                new Date(
                    user.profile.profileGroup.generatedEstimatedPayoutTotalFromDate ?? "",
                ),
            )} ─ ${formattedDayMonthYear(
                new Date(
                    user.profile.profileGroup.generatedEstimatedPayoutTotalToDate ?? "",
                ),
            )})`;

            dispatchState({
                input: "headlineData",
                value: [
                    [
                        { data: "Hourly Rate" },
                        { data: "Estimated" },
                        { data: "Unpaid" },
                        { data: "Paid" },
                    ],
                    [
                        {
                            data: `£${Number(
                                payrollHeadlineData.payrate?.hourlyRate ?? 25,
                            )?.toLocaleString()}`,
                            onPress: () => navigation.navigate("Payrates"),
                        },
                        {
                            data: user?.profile?.profileGroup
                                ?.estimatedTotalAmountUnpaid
                                ? `£${Number(
                                      user?.profile?.profileGroup
                                          ?.estimatedTotalAmountUnpaid,
                                  )?.toLocaleString()}`
                                : "No Data",
                            tooltipLabel: estimatedTotalDateString,
                        },
                        {
                            data: payrollHeadlineData.me?.profile
                                ?.totalPayslipsUnpaidAmount
                                ? `£${Number(
                                      payrollHeadlineData.me?.profile
                                          ?.totalPayslipsUnpaidAmount,
                                  )?.toLocaleString()}`
                                : "£0",
                        },
                        {
                            data: payrollHeadlineData.me?.profile
                                ?.totalPayslipsPaidAmount
                                ? `£${Number(
                                      payrollHeadlineData.me?.profile
                                          ?.totalPayslipsPaidAmount,
                                  )?.toLocaleString()}`
                                : "£0",
                        },
                    ],
                ],
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        user?.profile?.profileGroup,
        user,
        user?.profile,
        user?.profile?.profileGroup?.generatedEstimatedPayoutTotalFromDate,
        user?.profile?.profileGroup?.generatedEstimatedPayoutTotalToDate,
        user?.profile?.profileGroup?.estimatedTotalAmountUnpaid,
        payrollHeadlineData.me?.profile?.totalPayslipsUnpaidAmount,
        payrollHeadlineData.me?.profile?.totalPayslipsPaidAmount,
        payrollHeadlineData.payrate?.hourlyRate
    ]);

    const onPressPayslip = useCallback(
        (payslipId?: string | null) => {
            if (payslipId) {
                navigation.navigate("Payslip", {
                    payslipId: payslipId,
                    payslipsConnectionId: payslipsData.payslips?.__id,
                });
            }
        },
        [navigation, payslipsData.payslips?.__id],
    );

    const rowRenderer = useCallback(
        (
            _: unknown,
            data: NonNullable<
                LoadPayslips_query_payslips$data["payslips"]
            >["edges"][0],
            index: number,
        ): ReactElement | null => {
            if (data?.node) {
                return (
                    <Box
                        borderColor="surface.400"
                        borderLeftWidth={TABLE_BORDER_WIDTH}>
                        <PayslipTableRow
                            cellProps={{
                                hideTopBorder: index === 0,
                                textProps: {
                                    fontSize: "md",
                                    textAlign: "center",
                                    fontFamily: "Poppins-Regular",
                                },
                                px: 2,
                            }}
                            data={data.node}
                            flexArray={FLEX_ARRAY}
                            onPressPayslip={onPressPayslip}
                            rowHeight={10}
                            tableBorderColor={TABLE_BORDER_COLOR}
                            tableBorderWidth={TABLE_BORDER_WIDTH}
                        />
                    </Box>
                );
            } else {
                return null;
            }
        },
        [onPressPayslip],
    );

    const renderFooter = useCallback(() => {
        return isLoadingNext ? (
            <CustomFlatListSpinner
                borderColor={TABLE_BORDER_COLOR}
                borderTopWidth={1}
                position="relative"
                pt="8"
                top="0"
            />
        ) : null;
    }, [isLoadingNext]);

    const [copyDataToClipboard, setCopyDataToClipboard] =
        useState<() => void | undefined>();

    const inputChangeHandler = useCallback(
        (_: unknown, inputValue?: string) => {
            dispatchState({
                input: "searchTerm",
                value: inputValue,
            });
            navigation.setParams({ searchTerm: inputValue });
        },
        [dispatchState, navigation],
    );

    const inputClearHandler = useCallback(() => {
        if (state.values.searchTerm !== "") {
            dispatchState({
                input: "searchTerm",
                value: "",
            });
            navigation.setParams({ searchTerm: undefined });
        }
    }, [dispatchState, navigation, state.values.searchTerm]);

    const sortHandler = useCallback(
        (column: string) => {
            const orderBy = getOrderBy(column, state.values.orderBy);
            dispatchState({
                input: "orderBy",
                value: orderBy,
            });
            navigation.setParams({
                orderBy: orderBy,
            });
        },
        [dispatchState, navigation, state.values.orderBy],
    );

    const tableHeaders = useMemo(() => {
        // these headers are used by all account types
        return [
            {
                data: "Account #",
            },
            {
                data: "Teacher Name",
                onPress: () =>
                    sortHandler(
                        "teacher__user__firstName,teacher__user__lastName",
                    ),
                icon: sortIconExtractor(
                    "teacher__user__firstName,teacher__user__lastName",
                    state.values.orderBy,
                ),
            },
            {
                data: "Total Amount Due",
                onPress: () => sortHandler("amountDue"),
                icon: sortIconExtractor("amountDue", state.values.orderBy),
            },
            {
                data: "Paid From",
            },
            {
                data: "Paid To",
            },
            {
                data: "Status",
            },
        ];
    }, [sortHandler, state.values.orderBy]);

    const renderHeader = useMemo(() => {
        const getYearsForSelect = () => {
            const yearArray = [
                ...Array(new Date().getFullYear() - 2022).keys(),
            ].map((i) => i + 2023);
            return yearArray.map((item) => {
                return { label: String(item), value: String(item) };
            });
        };

        return (
            <VStack bg="surface.100" mx="30px" pt="6" space="6">
                <HStack justifyContent="space-between">
                    <VStack
                        flex={1}
                        height="100%"
                        justifyContent="space-between"
                        mr="6">
                        <VStack flex={1} space="4">
                            <HStack mb="1" ml="1" space="3">
                                <PayrollIcon color="primary.600" size="2xl" />
                                <Heading color="primary.600" fontSize="xl">
                                    Partner Payouts
                                </Heading>
                            </HStack>
                        </VStack>
                        <VStack space="4">
                            <VStack space="2">
                                <HStack space="4">
                                    <Select
                                        borderRadius="2xl"
                                        fontSize="md"
                                        onValueChange={(itemValue) => {
                                            dispatchState({
                                                input: "year",
                                                value: parseInt(itemValue),
                                            });
                                            navigation.setParams({
                                                year: parseInt(itemValue),
                                            });
                                        }}
                                        placeholder="Select year"
                                        py="2.5"
                                        selectedValue={String(
                                            state.values.year,
                                        )}
                                        width="140px">
                                        {getYearsForSelect().map((item) => {
                                            return (
                                                <Select.Item
                                                    key={item.value}
                                                    actionSheetLabel={
                                                        item.label
                                                    }
                                                    value={String(item.value)}
                                                />
                                            );
                                        })}
                                    </Select>
                                    <Select
                                        borderRadius="2xl"
                                        fontSize="md"
                                        onValueChange={(itemValue) => {
                                            dispatchState({
                                                input: "month",
                                                value: parseInt(itemValue),
                                            });
                                            navigation.setParams({
                                                month: parseInt(itemValue),
                                            });
                                        }}
                                        placeholder="Select month"
                                        py="2.5"
                                        selectedValue={String(
                                            state.values.month,
                                        )}
                                        width="40">
                                        {MONTHS.map((item) => {
                                            return (
                                                <Select.Item
                                                    key={item.value}
                                                    actionSheetLabel={
                                                        item.label
                                                    }
                                                    value={item.value}
                                                />
                                            );
                                        })}
                                    </Select>
                                </HStack>
                            </VStack>
                            <HStack justifyContent="space-between">
                                <HStack maxWidth="100%" space="4" width="450px">
                                    <Box flex={1}>
                                        <SearchBar
                                            inputClearHandler={
                                                inputClearHandler
                                            }
                                            inputOnChangeHandler={
                                                inputChangeHandler
                                            }
                                            inputSearchHandler={
                                                inputChangeHandler
                                            }
                                            placeholderText={
                                                "Search by teacher name"
                                            }
                                            searchText={state.values.searchTerm}
                                            showSearchIcon
                                        />
                                    </Box>
                                    <Button
                                        bg="surface.400"
                                        colorScheme="surface"
                                        leftIcon={<RestartIcon size="5" />}
                                        onPress={state.values.refreshHandler}
                                        p="3"
                                    />
                                </HStack>
                                <HStack justifyContent="flex-end" space="4">
                                    <Button
                                        _hover={{ bg: "primary.500" }}
                                        _pressed={{ bg: "primary.600" }}
                                        _text={{ fontSize: "17" }}
                                        bg="primary.400"
                                        leftIcon={<CopyIcon size="md" />}
                                        onPress={copyDataToClipboard}
                                        px="4"
                                        shadow={1}>
                                        Copy Table to Clipboard
                                    </Button>
                                    <Button
                                        _hover={{ bg: "primary.500" }}
                                        _pressed={{ bg: "primary.600" }}
                                        _text={{ fontSize: "17" }}
                                        bg="primary.400"
                                        leftIcon={<PayrollIcon size="md" />}
                                        onPress={
                                            payrollActionsActionsheetOnOpen
                                        }
                                        px="4"
                                        shadow={1}>
                                        View Payout Actions
                                    </Button>
                                </HStack>
                            </HStack>
                        </VStack>
                    </VStack>
                    <HStack
                        borderColor={TABLE_BORDER_COLOR}
                        borderRadius={TABLE_BORDER_RADIUS}
                        borderWidth={TABLE_BORDER_WIDTH}
                        overflow="hidden"
                        width="260px">
                        {state.values.headlineData?.map((item, index) => {
                            return (
                                <Column
                                    key={index}
                                    bg={index === 0 ? "primary.50" : undefined}
                                    cellProps={{
                                        px: 2,
                                        hideTopBorder: true,
                                        hideOuterSideBorder: true,
                                        textProps: {
                                            fontSize: "md",
                                        },
                                    }}
                                    data={item}
                                    flex={1}
                                    isFinalColumn={
                                        (state.values.headlineData?.length ??
                                            0) ==
                                        index + 1
                                    }
                                    rowHeight={10}
                                    tableBorderColor={TABLE_BORDER_COLOR}
                                    tableBorderWidth={TABLE_BORDER_WIDTH}
                                />
                            );
                        })}
                    </HStack>
                </HStack>
                <Box bg="surface.100">
                    <Box
                        bg="primary.50"
                        borderColor={TABLE_BORDER_COLOR}
                        borderTopRadius={TABLE_BORDER_RADIUS}
                        borderWidth={TABLE_BORDER_WIDTH}>
                        <Row
                            cellProps={{
                                px: 2,
                                hideTopBorder: true,
                                hideOuterSideBorder: true,
                                textProps: {
                                    fontSize: "md",
                                    textAlign: "center",
                                },
                            }}
                            data={tableHeaders}
                            flexArray={FLEX_ARRAY}
                            rowHeight={10}
                            rowIndex={0}
                            tableBorderColor={TABLE_BORDER_COLOR}
                            tableBorderWidth={TABLE_BORDER_WIDTH}
                        />
                    </Box>
                </Box>
            </VStack>
        );
    }, [
        state.values.year,
        state.values.month,
        state.values.searchTerm,
        state.values.refreshHandler,
        state.values.headlineData,
        inputClearHandler,
        inputChangeHandler,
        copyDataToClipboard,
        payrollActionsActionsheetOnOpen,
        tableHeaders,
        dispatchState,
        navigation,
    ]);

    useEffect(() => {
        dispatchState({ input: "contentIsRendered", value: true });
        // unsubscribe from refetch on unmount
        return () => state.values.subscription?.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadPayslipsQueryReference]);

    const renderCount = useRef(0);
    const environment = useRelayEnvironment();
    const { colors } = useTheme();

    const refetchPayslips = useCallback(
        (variables: {
            dateFrom: string;
            dateTo: string;
            first: number;
            orderBy?: string;
            searchTerm?: string;
        }): Subscription | undefined => {
            dispatchState({ input: "isRefetching", value: true });

            const subscription = fetchQuery<LoadPayslipsQuery>(
                environment,
                load_payslips,
                variables,
                { fetchPolicy: "store-or-network" },
            ).subscribe({
                complete: () => {
                    dispatchState({ input: "isRefetching", value: false });
                    refetchPayrollHeadlineData(variables, {
                        fetchPolicy: "store-only",
                    });
                    refresh(variables, { fetchPolicy: "store-only" });
                },
                error: () => {
                    dispatchState({ input: "isRefetching", value: false });
                },
            });
            return subscription;
        },
        [dispatchState, environment, refresh, refetchPayrollHeadlineData],
    );

    useEffect(() => {
        if (renderCount.current < 2) {
            renderCount.current += 1;
            return;
        }

        dispatchState({ input: "isRefetching", value: false });
        state.values.subscription?.unsubscribe();

        // fire refetch immediately when orderBy or filters change
        dispatchState({
            input: "subscription",
            value: refetchPayslips({
                first: LOAD_X_PAYSLIPS,
                searchTerm: state.values.searchTerm.trim(),
                orderBy: state.values.orderBy,
                dateFrom: monthFirstDay(state.values.year, state.values.month),
                dateTo: monthLastDay(state.values.year, state.values.month),
            }),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.values.orderBy, state.values.month, state.values.year]);

    useDebounceFunction(
        () => {
            if (renderCount.current < 2) {
                renderCount.current += 1;
                return;
            }

            dispatchState({ input: "isRefetching", value: false });
            state.values.subscription?.unsubscribe();

            // remove any applied sorting when we search (sort by relevance instead)
            // and reapply sorting if search text is blank
            const orderBy =
                state.values.searchTerm.trim() === ""
                    ? "teacher__user__firstName,teacher__user__lastName"
                    : undefined;

            dispatchState({ input: "orderBy", value: orderBy });
            navigation.setParams({ orderBy: orderBy });
            dispatchState({
                input: "subscription",
                value: refetchPayslips({
                    first: LOAD_X_PAYSLIPS,
                    searchTerm: state.values.searchTerm.trim(),
                    dateFrom: monthFirstDay(
                        state.values.year,
                        state.values.month,
                    ),
                    dateTo: monthLastDay(state.values.year, state.values.month),
                }),
            });
        }, // no delay if clearing text
        state.values.searchTerm !== "" ? 750 : 0,

        [state.values.searchTerm],
    );

    const { scale, width: windowWidth } = useWindowDimensions();

    useEffect(() => {
        // this allows the RLV to resize when the window resizes
        dispatchState({
            input: "layoutProvider",
            value: new LayoutProvider(
                () => "VSEL",
                (type, dim) => {
                    switch (type) {
                        case "VSEL":
                            dim.width = getScaledWindowDimension(
                                windowWidth,
                                scale,
                            );
                            dim.height = 40;
                            break;
                        default:
                            dim.width = 0;
                            dim.height = 0;
                    }
                },
            ),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowWidth, scale]);

    const onEndReached = useCallback(() => {
        if (hasNext && !isLoadingNext) {
            loadNext(LOAD_X_PAYSLIPS);
        }
    }, [hasNext, isLoadingNext, loadNext]);

    const renderPayrollActionsActionsheet = useMemo(() => {
        return (
            <Actionsheet
                animationType="fade"
                hideDragIndicator
                isOpen={payrollActionsActionsheetIsOpen}
                justifyContent="center"
                onClose={payrollActionsActionsheetOnClose}
                size="lg">
                <Actionsheet.Content
                    alignItems="center"
                    closeButtonProps={{ top: "5", right: "5" }}
                    justifyContent="center"
                    mx="auto"
                    roundedBottom={12}
                    roundedTop={12}
                    showCloseButton
                    w="40%">
                    <Text
                        alignSelf="center"
                        fontSize="xl"
                        fontWeight="bold"
                        pb="6"
                        pt="4">
                        Partner Payout Actions
                    </Text>
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="7">
                                <PayrollIcon color="primary.500" size="7" />
                            </Center>
                        }
                        onPress={() => {
                            payrollActionsActionsheetOnClose();
                            dispatchState({
                                input: "generateTeacherPayslipModalIsOpen",
                                value: true,
                            });
                        }}>
                        Generate partner payouts
                    </Actionsheet.Item>
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="7">
                                <PayrollEstimateIcon
                                    color="primary.400"
                                    size="7"
                                />
                            </Center>
                        }
                        onPress={() => {
                            payrollActionsActionsheetOnClose();
                            dispatchState({
                                input: "generateEstimatedPayrollModalIsOpen",
                                value: true,
                            });
                        }}>
                        Generate estimated payouts
                    </Actionsheet.Item>
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="7">
                                <PayrateIcon color="secondary.600" size="7" />
                            </Center>
                        }
                        onPress={() => {
                            payrollActionsActionsheetOnClose();
                            navigation.navigate("Payrates");
                        }}>
                        Update payrates
                    </Actionsheet.Item>
                    <Text
                        alignSelf="center"
                        fontFamily="Poppins-Regular"
                        fontSize="md"
                        mx="8"
                        py="4"
                        textAlign="center">
                        {
                            "After clicking on an action above, you will be asked for confirmation before proceeding."
                        }
                    </Text>
                </Actionsheet.Content>
            </Actionsheet>
        );
    }, [
        payrollActionsActionsheetIsOpen,
        payrollActionsActionsheetOnClose,
        dispatchState,
        navigation,
    ]);

    if (!state.values.contentIsRendered) {
        return <LoadingBlobs>Loading Partner Payouts...</LoadingBlobs>;
    }

    return (
        <>
            {renderHeader}
            <Box flex={1} mx="30px">
                {state.values.dataProvider.getSize() > 0 &&
                state.values.layoutProvider ? (
                    <RecyclerListView
                        canChangeSize
                        dataProvider={state.values.dataProvider}
                        layoutProvider={state.values.layoutProvider}
                        onEndReached={onEndReached}
                        onEndReachedThreshold={2000}
                        renderAheadOffset={800}
                        renderFooter={renderFooter}
                        rowRenderer={rowRenderer}
                        scrollViewProps={{
                            contentContainerStyle: {
                                borderColor:
                                    // @ts-expect-error can't index with variable
                                    colors?.[TABLE_BORDER_COLOR.split(".")[0]][
                                        TABLE_BORDER_COLOR.split(".")[1]
                                    ],
                                borderBottomWidth:
                                    (payslipsData.payslips?.edges.length ??
                                        0 > 0) &&
                                    !isLoadingNext
                                        ? TABLE_BORDER_WIDTH
                                        : 0,
                                marginBottom: 40,
                                overflow: "hidden",
                                borderBottomRadius: TABLE_BORDER_RADIUS,
                            },
                            showsVerticalScrollIndicator: false,
                        }}
                        style={{
                            flex: 1,
                            minHeight: 1,
                        }}
                        suppressBoundedSizeException
                        useWindowScroll
                    />
                ) : (
                    <ListEmptyBanner explainer={"No payouts found..."}>
                        <Text fontSize="6xl">
                            {emoji.getUnicode("neutral_face")}
                        </Text>
                    </ListEmptyBanner>
                )}
                {renderPayrollActionsActionsheet}
                <CreatePayslipModal
                    defaultMonth={state.values.month}
                    defaultYear={state.values.year}
                    hideModal={() => {
                        dispatchState({
                            input: "createPayslipModalIsOpen",
                            value: false,
                        });
                        dispatchState({
                            input: "generateEstimatedPayrollModalIsOpen",
                            value: false,
                        });
                    }}
                    mode={
                        state.values.createPayslipModalIsOpen
                            ? Mode.GeneratePayslips
                            : Mode.GenerateEstimatedPayroll
                    }
                    refreshHandler={state.values.refreshHandler}
                    showModal={Boolean(
                        state.values.createPayslipModalIsOpen ||
                            state.values.generateEstimatedPayrollModalIsOpen,
                    )}
                    teacherIds={state.values.teacherIds ?? []}
                />
            </Box>
        </>
    );
};

const PayrollHubScreen: FC<ScreenProps> = (props) => {
    const { navigation, route } = props;

    const { user } = useAuth();

    type ReducerValues = {
        bulkUploadEnrolmentsModalIsOpen?: boolean;
        contentIsRendered: boolean;
        copyDataToClipboard?: () => void;
        createPayslipModalIsOpen?: boolean;
        dataProvider: DataProvider;
        generateTeacherPayslipModalIsOpen?: boolean;
        headlineData: { data: string | number }[][];
        isRefetching: boolean;
        layoutProvider?: LayoutProvider;
        month: number;
        orderBy?: string;
        refreshHandler?: () => void;
        searchTerm: string;
        showCreateSchoolModal?: boolean;
        subscription?: Subscription;
        teacherIds?: string[];
        year: number;
    };

    type ReducerTypes =
        | string
        | number
        | boolean
        | { data: string | number }[][]
        | Subscription
        | DataProvider
        | LayoutProvider
        | (() => void)
        | string[]
        | undefined;

    const initialState = useMemo(() => {
        return {
            values: {
                searchTerm: route.params?.searchTerm ?? "",
                contentIsRendered: false,
                isRefetching: false,
                subscription: undefined,
                dataProvider: new DataProvider((r1, r2) => {
                    return r1 !== r2;
                }),
                layoutProvider: undefined,
                month: route.params?.month ?? new Date().getMonth(),
                year: route.params?.year ?? new Date().getFullYear(),
                generateTeacherPayslipModalIsOpen: false,
                createPayslipModalIsOpen: false,
                orderBy: "teacher__user__firstName,teacher__user__lastName",
                headlineData: [
                    [
                        { data: "Hourly Rate" },
                        { data: "Estimated" },
                        { data: "Unpaid" },
                        { data: "Paid" },
                    ],
                    [{ data: "" }, { data: "" }, { data: "" }, { data: "" }],
                ],
            },
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [state, dispatchState] = useReducer(reducer, initialState);
    const [loadPayslipsQueryReference, loadPayslipsQuery] =
        useQueryLoader<LoadPayslipsQuery>(load_payslips);

    useEffect(() => {
        if (!state.values.contentIsRendered) {
            loadPayslipsQuery(
                {
                    first: LOAD_X_PAYSLIPS,
                    searchTerm: state.values.searchTerm.trim(),
                    orderBy: state.values.orderBy,
                    dateFrom: monthFirstDay(
                        state.values.year,
                        state.values.month,
                    ),
                    dateTo: monthLastDay(state.values.year, state.values.month),
                },
                { fetchPolicy: "store-or-network" },
            );
        }
    }, [
        loadPayslipsQuery,
        state.values.contentIsRendered,
        state.values.orderBy,
        state.values.searchTerm,
        state.values.month,
        state.values.year,
    ]);

    const renderPayslipGenerateTeacherSelectModal = useMemo(() => {
        return (
            <TeacherMultiSelectModal
                buttonText="Generate"
                hideModal={() => {
                    dispatchState({
                        input: "generateTeacherPayslipModalIsOpen",
                        value: false,
                    });
                }}
                includeCheckbox={false}
                initiallySelectAllTeachers
                onSave={({ teacherIds }) => {
                    dispatchState({
                        input: "createPayslipModalIsOpen",
                        value: true,
                    });
                    dispatchState({
                        input: "teacherIds",
                        value: teacherIds,
                    });
                }}
                showModal={
                    state.values.generateTeacherPayslipModalIsOpen ?? false
                }
                title="Generate Partner Payouts"
            />
        );
    }, [state.values.generateTeacherPayslipModalIsOpen]);

    const renderRefetchIndicator = useMemo(() => {
        return state.values.isRefetching ? (
            <CustomFlatListSpinner top="360" />
        ) : null;
    }, [state.values.isRefetching]);

    if (!user?.profile?.id) {
        return null;
    }
    return (
        <Box bg="surface.100" flex={1} pt="70">
            {loadPayslipsQueryReference ? (
                <Suspense
                    fallback={
                        <LoadingBlobs>Loading Partner Payouts...</LoadingBlobs>
                    }>
                    <PayrollContent
                        dispatchState={dispatchState}
                        loadPayslipsQueryReference={loadPayslipsQueryReference}
                        navigation={navigation}
                        state={state}
                    />
                </Suspense>
            ) : (
                <LoadingBlobs>Loading Partner Payouts...</LoadingBlobs>
            )}
            {renderPayslipGenerateTeacherSelectModal}
            {renderRefetchIndicator}
        </Box>
    );
};

export const screenOptions = {
    headerTitle: "Partner Payouts",
};

export default PayrollHubScreen;
