/**
 * @generated SignedSource<<a59b9e1cc8e0504db0cabc9abbc98c08>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LoadSchoolQuery$variables = {
  schoolId: string;
};
export type LoadSchoolQuery$data = {
  readonly school: {
    readonly archived: boolean;
    readonly headTeacher: {
      readonly id: string;
      readonly phoneNumber: string | null;
      readonly user: {
        readonly email: string;
        readonly firstName: string;
        readonly id: string;
        readonly lastName: string;
      };
    } | null;
    readonly id: string;
    readonly mainOfficeContact: {
      readonly addressFirstLine: string | null;
      readonly addressSecondLine: string | null;
      readonly city: string | null;
      readonly id: string;
      readonly phoneNumber: string | null;
      readonly postcode: string | null;
      readonly user: {
        readonly email: string;
        readonly firstName: string;
        readonly id: string;
        readonly lastName: string;
      };
    } | null;
    readonly name: string;
    readonly otherContacts: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly label: string | null;
          readonly phoneNumber: string | null;
          readonly user: {
            readonly email: string;
            readonly firstName: string;
            readonly id: string;
            readonly lastName: string;
          };
        } | null;
      } | null>;
    };
  } | null;
};
export type LoadSchoolQuery = {
  response: LoadSchoolQuery$data;
  variables: LoadSchoolQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "schoolId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "DjangoUserNode",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "schoolId"
      }
    ],
    "concreteType": "SchoolNode",
    "kind": "LinkedField",
    "name": "school",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "archived",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProfileNode",
        "kind": "LinkedField",
        "name": "headTeacher",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProfileNode",
        "kind": "LinkedField",
        "name": "mainOfficeContact",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "addressFirstLine",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "addressSecondLine",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "city",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "postcode",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProfileNodeConnection",
        "kind": "LinkedField",
        "name": "otherContacts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "label",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoadSchoolQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoadSchoolQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "f0292feca623eee4a508fc03df9dea16",
    "id": null,
    "metadata": {},
    "name": "LoadSchoolQuery",
    "operationKind": "query",
    "text": "query LoadSchoolQuery(\n  $schoolId: ID!\n) {\n  school(id: $schoolId) {\n    id\n    name\n    archived\n    headTeacher {\n      id\n      phoneNumber\n      user {\n        id\n        firstName\n        lastName\n        email\n      }\n    }\n    mainOfficeContact {\n      id\n      phoneNumber\n      addressFirstLine\n      addressSecondLine\n      city\n      postcode\n      user {\n        id\n        firstName\n        lastName\n        email\n      }\n    }\n    otherContacts {\n      edges {\n        node {\n          id\n          phoneNumber\n          label\n          user {\n            id\n            firstName\n            lastName\n            email\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d8c3a26fd2035442d830232adaad3c6c";

export default node;
