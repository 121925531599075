import React, { createContext, useContext, useReducer, useMemo } from "react";
import type { ReactElement, Dispatch } from "react";

import type { LoadSchoolsForDropdownsQuery$data } from "pianofunclub-shared/relay/graphql/schools/__generated__/LoadSchoolsForDropdownsQuery.graphql";

import { createReducer } from "../utils/reducers";
import type { State, Action } from "../utils/reducers";

type ReducerValues = {
    schools: LoadSchoolsForDropdownsQuery$data["schools"] | null;
};

export type ReducerTypes = LoadSchoolsForDropdownsQuery$data["schools"] | null;

export type SchoolEdges = NonNullable<
    LoadSchoolsForDropdownsQuery$data["schools"]
>["edges"];

export interface DataContextType {
    dataState: State<ReducerValues>;
    dispatchDataState: Dispatch<Action<ReducerValues, ReducerTypes>>;
}

interface Props {
    children?: ReactElement;
}

const DataContext = createContext<DataContextType | null>(null);

export const DataProvider = ({ children }: Props): ReactElement => {
    // this provider exposes commonly-used data to the rest of the app
    // to reduce time spent loading stuff from the database

    const initialState = useMemo(() => {
        return {
            values: {
                schools: null,
            },
        };
    }, []);

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [dataState, dispatchDataState] = useReducer(reducer, initialState);

    return (
        // this component gives all child components access to the api keys
        // that are loaded from the encrypted back-end
        <DataContext.Provider
            value={{
                dataState,
                dispatchDataState,
            }}
        >
            {children}
        </DataContext.Provider>
    );
};

// abstract the connection logic into a simple hook
export const useData = (): DataContextType => {
    const context = useContext(DataContext);

    if (!context) {
        throw new Error("useData must be used within an DataProvider");
    }

    return context;
};
