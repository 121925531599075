import { graphql } from "react-relay";

export const generate_or_update_google_registers = graphql`
    mutation GenerateOrUpdateGoogleRegistersMutation(
        $input: GenerateOrUpdateGoogleRegistersInput!
    ) {
        generateOrUpdateGoogleRegisters(input: $input) {
            success
            errors
            totalToGenerate
            numberOfRegistersGenerated
            erroredTeacher {
                id
                user {
                    firstName
                    lastName
                    email
                }
            }
            erroredSchool {
                id
                name
            }
            profileGroup {
                id
                generateGoogleRegistersProgress
            }
        }
    }
`;
