import { graphql } from "react-relay";

export const delete_enrolment = graphql`
    mutation DeleteEnrolmentMutation($input: DeleteEnrolmentInput!) {
        deleteEnrolment(input: $input) {
            success
            errors
            deletedEnrolmentId
        }
    }
`;
