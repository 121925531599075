import React, { useState, useEffect } from "react";

import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { SSRProvider } from "@react-native-aria/utils";
import { useAssets } from "expo-asset";
import * as Font from "expo-font";
import { LinearGradient } from "expo-linear-gradient";
import { NativeBaseProvider } from "native-base";
import { LogBox } from "react-native";
import { enableScreens } from "react-native-screens";
import "react-native-gesture-handler";

import { AuthProvider } from "pianofunclub-shared/providers/AuthProvider";
import { DataProvider } from "pianofunclub-shared/providers/DataProvider";
import { RelayProvider } from "pianofunclub-shared/providers/RelayProvider";

import LoadingScreen from "pianofunclub-shared/screens/LoadingScreen";

import { createWebRelayEnvironment } from "pianofunclub-shared/relay/index";

import PianoFunClubTheme from "pianofunclub-shared/theme";

import AppNavigator from "./navigation/AppNavigator";
import appAssets from "./utils/assets";

LogBox.ignoreAllLogs();
console.error = (error) => error.apply;
console.warn = (warn) => warn.apply;

enableScreens();

const fetchFonts = () => {
    return Font.loadAsync({
        // * eventually remove the ones we don't need
        "Poppins-ExtraLight": require("pianofunclub-shared/assets/fonts/Poppins-ExtraLight.ttf"),
        "Poppins-ExtraLightItalic": require("pianofunclub-shared/assets/fonts/Poppins-ExtraLightItalic.ttf"),
        "Poppins-Light": require("pianofunclub-shared/assets/fonts/Poppins-Light.ttf"),
        "Poppins-LightItalic": require("pianofunclub-shared/assets/fonts/Poppins-LightItalic.ttf"),
        "Poppins-Regular": require("pianofunclub-shared/assets/fonts/Poppins-Regular.ttf"),
        "Poppins-Italic": require("pianofunclub-shared/assets/fonts/Poppins-Italic.ttf"),
        "Poppins-SemiBold": require("pianofunclub-shared/assets/fonts/Poppins-SemiBold.ttf"),
        "Poppins-SemiBoldItalic": require("pianofunclub-shared/assets/fonts/Poppins-SemiBoldItalic.ttf"),
        "Poppins-Bold": require("pianofunclub-shared/assets/fonts/Poppins-Bold.ttf"),
        "Poppins-BoldItalic": require("pianofunclub-shared/assets/fonts/Poppins-BoldItalic.ttf"),
        "Poppins-ExtraBold": require("pianofunclub-shared/assets/fonts/Poppins-ExtraBold.ttf"),
        "Poppins-ExtraBoldItalic": require("pianofunclub-shared/assets/fonts/Poppins-ExtraBoldItalic.ttf"),
        "Poppins-Black": require("pianofunclub-shared/assets/fonts/Poppins-Black.ttf"),
        "Poppins-BlackItalic": require("pianofunclub-shared/assets/fonts/Poppins-BlackItalic.ttf"),
        // icons - use others but these are the main two libraries
        ...Ionicons.font,
        ...MaterialCommunityIcons.font,
    });
};

const AppRoot = (): React.ReactElement => {
    const [fontLoaded, setFontLoaded] = useState(false);

    const [assets] = useAssets(appAssets);

    useEffect(() => {
        fetchFonts().then(() => {
            setFontLoaded(true);
        });
    }, []);

    const nativeBaseConfig = {
        dependencies: {
            "linear-gradient": LinearGradient,
        },
    };

    if (!fontLoaded || !assets) {
        return <LoadingScreen />;
    }

    return (
        <SSRProvider>
            <NativeBaseProvider
                config={nativeBaseConfig}
                theme={PianoFunClubTheme}
            >
                <RelayProvider
                    createRelayEnvironment={createWebRelayEnvironment}
                >
                    <DataProvider>
                        <AuthProvider>
                            <AppNavigator />
                        </AuthProvider>
                    </DataProvider>
                </RelayProvider>
            </NativeBaseProvider>
        </SSRProvider>
    );
};

export default AppRoot;
