import React, {
    useMemo,
    useState,
    forwardRef,
    useImperativeHandle,
} from "react";
import type { ReactElement } from "react";

import { format } from "date-fns";
import { Checkbox, Center } from "native-base";

import type { CellProps } from "pianofunclub-shared/components/Base/Cell";
import Row from "pianofunclub-shared/components/Base/Row";
import CurrencyInput from "pianofunclub-shared/components/Inputs/CurrencyInput";

import { ensureDateTypeIfDefined } from "pianofunclub-shared/utils/helpers";

import type { PayrateData } from "pianofunclub-crm/screens/payroll/PayratesScreen";

interface Props {
    cellProps?: CellProps;
    checkboxChangeHandler: (payrateId: string, isSelected: boolean) => void;
    data: NonNullable<PayrateData>["node"];
    flexArray: number[];
    hourlyRateFinishEditingHandler: (
        _: string | number,
        inputValue?: string,
        isValid?: boolean,
    ) => void | string;
    nextDateFrom?: Date | undefined;
    onPressDate: (variables: {
        date: Date | undefined;
        payrateId: string;
    }) => void;
    rowHeight?: number;
    tableBorderColor?: string;
    tableBorderWidth?: number;
}

const PayratesTableRow = forwardRef((props: Props, ref): ReactElement => {
    const {
        cellProps,
        checkboxChangeHandler,
        data,
        flexArray,
        hourlyRateFinishEditingHandler,
        nextDateFrom,
        onPressDate,
        rowHeight,
        tableBorderColor,
        tableBorderWidth,
    } = props;

    const [checkBoxIsChecked, setCheckBoxIsChecked] = useState(false);

    useImperativeHandle(ref, () => ({
        setIsChecked: (isChecked: boolean) => setCheckBoxIsChecked(isChecked),
    }));

    const rowData = useMemo(() => {
        if (!data?.id) {
            return [];
        }

        const dateFrom = ensureDateTypeIfDefined(data?.startDate);
        const dateTo = nextDateFrom ?? "Present";

        return [
            {
                data: (
                    <Checkbox
                        isChecked={checkBoxIsChecked}
                        onChange={(isSelected) => {
                            setCheckBoxIsChecked(isSelected);
                            checkboxChangeHandler(data?.id, isSelected);
                        }}
                        size="md"
                        value={data?.id}
                    />
                ),
                onPress: () => {
                    return;
                },
            },
            {
                data: dateFrom ? format(dateFrom, "EEE do MMM yyyy") : "",
                onPress: () =>
                    onPressDate({
                        payrateId: data?.id,
                        date: dateFrom,
                    }),
            },
            {
                data: dateFrom
                    ? dateTo === "Present"
                        ? dateTo
                        : format(dateTo, "EEE do MMM yyyy")
                    : "",
            },
            {
                data: (
                    <Center flex={1}>
                        <CurrencyInput
                            key={data?.hourlyRate}
                            _focus={{
                                bg: "transparent",
                                opacity: 1,
                                borderWidth: tableBorderWidth,
                            }}
                            _hover={{
                                bg: "transparent",
                                opacity: 1,
                                borderWidth: tableBorderWidth,
                                color: "black",
                            }}
                            bg="transparent"
                            borderColor="transparent"
                            borderRadius={0}
                            borderWidth={tableBorderWidth}
                            fontFamily="Poppins-Regular"
                            height={39}
                            id={data?.id ?? ""}
                            initiallyValid
                            initialValue={
                                data?.hourlyRate && Number(data?.hourlyRate)
                                    ? Number(data?.hourlyRate)
                                    : 0
                            }
                            mx="-2"
                            onFinishEditing={hourlyRateFinishEditingHandler}
                            textAlign={"center"}
                        />
                    </Center>
                ),
                onPress: () => {
                    return;
                },
            },
        ];
    }, [
        checkBoxIsChecked,
        checkboxChangeHandler,
        data?.hourlyRate,
        data?.id,
        data?.startDate,
        hourlyRateFinishEditingHandler,
        nextDateFrom,
        onPressDate,
        tableBorderWidth,
    ]);

    return (
        <Row
            cellProps={cellProps}
            data={rowData}
            flexArray={flexArray}
            rowHeight={rowHeight}
            rowIndex={0}
            tableBorderColor={tableBorderColor}
            tableBorderWidth={tableBorderWidth}
        />
    );
});

export default PayratesTableRow;
