import { graphql } from "react-relay";

export const register_fragment = graphql`
    fragment RegisterInfoFragment_query on Query
    @argumentDefinitions(profileId: { type: "ID!" })
    @refetchable(queryName: "RegisterInfoRefreshQuery") {
        profile(id: $profileId) {
            id
            totalLessonsMarked(startingYear: $startingYear, block: $block)
            totalLessons(startingYear: $startingYear, block: $block)
            firstLessonTimestamp(
                startingYear: $startingYear
                block: $block
                dayIndex: $dayIndex
            )
            lastLessonTimestamp(
                startingYear: $startingYear
                block: $block
                dayIndex: $dayIndex
            )
            teachingDays(startingYear: $startingYear, block: $block)
        }
    }
`;
