import React, {
    useMemo,
    useReducer,
    useEffect,
    useCallback,
    useRef,
} from "react";
import type { FC } from "react";

import type { NavigationAction } from "@react-navigation/native";
import { Box, HStack, ScrollView, Button } from "native-base";
import type { ScrollView as RNScrollView } from "react-native";
import { useQueryLoader } from "react-relay";

import { useAuth } from "pianofunclub-shared/providers/AuthProvider";

import SideBar from "../../components/Drawers/SideBar";
import BlacklistedDates, {
    LOAD_X_BLACKLISTED_DATES,
} from "../../components/Schools/BlacklistedDates";
import YearTermDates from "../../components/Schools/YearTermDates";
import type {
    AccountsStackNavigatorProps,
    AccountsStackRouteProps,
} from "../../navigation/AccountsNavigator";
import type {
    RegistersStackNavigatorProps,
    RegistersStackRouteProps,
} from "../../navigation/RegistersNavigator";
import type {
    SchoolsStackNavigatorProps,
    SchoolsStackRouteProps,
} from "../../navigation/SchoolsNavigator";
import Select from "pianofunclub-shared/components/NativeBaseExtended/Select";
import { RestartIcon } from "pianofunclub-shared/components/Other/Icons";

import type { LoadBlacklistedDatesQuery } from "pianofunclub-shared/relay/graphql/schools/__generated__/LoadBlacklistedDatesQuery.graphql";
import type { LoadTermDatesQuery } from "pianofunclub-shared/relay/graphql/schools/__generated__/LoadTermDatesQuery.graphql";
import { load_blacklisted_dates } from "pianofunclub-shared/relay/graphql/schools/LoadBlacklistedDates";
import { load_term_dates } from "pianofunclub-shared/relay/graphql/schools/LoadTermDates";

import { getDefaultStartingYearAndStartingYearOptions } from "pianofunclub-shared/utils/extractors";
import { createReducer } from "pianofunclub-shared/utils/reducers";

export type NavigationProps = SchoolsStackNavigatorProps<"DefaultTermDates"> &
    AccountsStackNavigatorProps<"DefaultTermDates"> &
    RegistersStackNavigatorProps<"DefaultTermDates">;

type RouteProps = SchoolsStackRouteProps<"DefaultTermDates"> &
    AccountsStackRouteProps<"DefaultTermDates"> &
    RegistersStackRouteProps<"DefaultTermDates">;

interface Props {
    navigation: NavigationProps;
    route: RouteProps;
}

export type ReducerValues = {
    leaveAlertAction?: NavigationAction | (() => void);
    startingYear: number;
    termDatesHaveBeenModified?: boolean;
};

export type ReducerTypes =
    | string
    | number
    | boolean
    | NavigationAction
    | (() => void)
    | undefined;

const SIDE_BAR_WIDTH = 12;

const DeafultTermDatesScreen: FC<Props> = (props) => {
    const { navigation, route } = props;

    const { user } = useAuth();

    const scrollViewRef = useRef<RNScrollView>(null);

    const [defaultStartingYear, startingYears] = useMemo(
        () => getDefaultStartingYearAndStartingYearOptions(),
        [],
    );

    const initialState = useMemo(() => {
        return {
            values: {
                startingYear:
                    route.params?.startingYear ??
                    user?.profile?.profileGroup?.currentStartingYear ??
                    defaultStartingYear,
            },
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [state, dispatchState] = useReducer(reducer, initialState);

    const [loadTermDatesQueryReference, loadTermDatesQuery] =
        useQueryLoader<LoadTermDatesQuery>(load_term_dates);

    const [loadBlacklistedDatesQueryReference, loadBlacklistedDatesQuery] =
        useQueryLoader<LoadBlacklistedDatesQuery>(load_blacklisted_dates);

    useEffect(() => {
        loadTermDatesQuery(
            {
                startingYear: state.values.startingYear,
                skipSchoolTermDates: true,
            },
            { fetchPolicy: "store-or-network" },
        );

        loadBlacklistedDatesQuery(
            {
                startingYear: state.values.startingYear,
                first: LOAD_X_BLACKLISTED_DATES,
            },
            { fetchPolicy: "store-or-network" },
        );
    }, [
        loadBlacklistedDatesQuery,
        loadTermDatesQuery,
        state.values.startingYear,
    ]);

    useEffect(() => {
        if (route.params?.scrollToBottom) {
            setTimeout(() => {
                scrollViewRef.current?.scrollToEnd();
            }, 200);
        }
    }, [route]);

    const termDatesInputKey = useRef(0);

    const refreshHandler = useCallback(() => {
        loadTermDatesQuery(
            {
                startingYear: state.values.startingYear,
                skipSchoolTermDates: true,
            },
            { fetchPolicy: "network-only" },
        );
        termDatesInputKey.current += 1;

        loadBlacklistedDatesQuery(
            {
                startingYear: state.values.startingYear,
                first: LOAD_X_BLACKLISTED_DATES,
            },
            { fetchPolicy: "network-only" },
        );
    }, [
        loadBlacklistedDatesQuery,
        loadTermDatesQuery,
        state.values.startingYear,
    ]);

    const renderHeader = useMemo(() => {
        return (
            <HStack space="4">
                <Select
                    borderRadius="2xl"
                    fontSize="md"
                    onValueChange={(itemValue) => {
                        dispatchState({
                            input: "startingYear",
                            value: parseInt(itemValue),
                        });
                        navigation.setParams({
                            startingYear: parseInt(itemValue),
                        });
                    }}
                    placeholder="Select year"
                    selectedValue={String(state.values.startingYear)}
                    width="40">
                    {startingYears.map((item) => {
                        return (
                            <Select.Item
                                key={item.value}
                                actionSheetLabel={item.label}
                                value={String(item.value)}
                            />
                        );
                    })}
                </Select>
                <Button
                    bg="surface.400"
                    colorScheme="surface"
                    leftIcon={<RestartIcon size="5" />}
                    onPress={() => {
                        if (state.values.termDatesHaveBeenModified) {
                            dispatchState({
                                input: "leaveAlertAction",
                                value: () => refreshHandler(),
                            });
                        } else {
                            refreshHandler();
                        }
                    }}
                    p="3"
                />
            </HStack>
        );
    }, [
        navigation,
        refreshHandler,
        startingYears,
        state.values.startingYear,
        state.values.termDatesHaveBeenModified,
    ]);

    return (
        <Box flex={1}>
            <SideBar
                isCollapsed
                navigation={navigation}
                width={SIDE_BAR_WIDTH}
            />
            <ScrollView
                ref={scrollViewRef}
                bg="surface.100"
                flex={1}
                ml={SIDE_BAR_WIDTH}
                mt="70"
                px="6"
                py="6">
                <Box>
                    {renderHeader}
                    <YearTermDates
                        key={termDatesInputKey.current}
                        dispatchScreenState={dispatchState}
                        loadTermDatesQueryReference={
                            loadTermDatesQueryReference
                        }
                        // @ts-expect-error nav props are merged on component
                        navigation={navigation}
                        refreshHandler={refreshHandler}
                        screenState={state}
                        startingYears={startingYears}
                    />
                </Box>
                <Box mt="70">
                    <BlacklistedDates
                        dispatchScreenState={dispatchState}
                        loadBlacklistedDatesQueryReference={
                            loadBlacklistedDatesQueryReference
                        }
                        // @ts-expect-error nav props are merged on component
                        navigation={navigation}
                        screenState={state}
                        scrollViewRef={scrollViewRef}
                        sideBarWidth={SIDE_BAR_WIDTH}
                    />
                </Box>
            </ScrollView>
        </Box>
    );
};

export const screenOptions = {
    headerTitle: "Default Term Dates",
};

export default DeafultTermDatesScreen;
