import { graphql } from "react-relay";

export const generate_registers_for_new_block = graphql`
    mutation GenerateRegistersForNewBlockMutation(
        $input: GenerateRegistersForNewBlockInput!
    ) {
        generateRegistersForNewBlock(input: $input) {
            success
            errors
            profileGroup {
                id
                generateRegistersForNewBlockProgress
            }
        }
    }
`;
