import React from "react";
import type { ReactElement, ComponentProps } from "react";

import { HStack, Tooltip } from "native-base";

import Cell from "./Cell";
import type { CellProps } from "./Cell";

type HStackProps = ComponentProps<typeof HStack>;

export type TableData = (
    | {
          cellProps?: CellProps;
          data: string | number | ReactElement;
          icon?: React.JSX.Element;
          isPlaceholder?: boolean;
          onPress?: (data?: string) => void;
          tooltipLabel?: string;
      }
    | null
    | undefined
)[];

interface Props extends HStackProps {
    cellProps?: CellProps;
    data: TableData;
    flexArray: number[];
    rowHeight?: number | string;
    rowIndex: number;
    tableBorderColor?: string;
    tableBorderWidth?: number;
    tooltipLabel?: string;
}

const Row = (props: Props): ReactElement => {
    const {
        cellProps,
        data,
        flexArray,
        rowHeight,
        rowIndex,
        tableBorderColor,
        tableBorderWidth,
        tooltipLabel,
    } = props;

    const filteredData = data.filter(Boolean);

    const renderRow = () => {
        return (
            <HStack {...props}>
                {filteredData.map((item, index) => {
                    if (!item) {
                        return null;
                    }

                    return (
                        <Cell
                            key={index}
                            data={item.data}
                            flex={flexArray[index]}
                            height={rowHeight}
                            icon={item?.icon}
                            isFinal={filteredData.length === index + 1}
                            isPlaceholder={item?.isPlaceholder}
                            onPress={item?.onPress}
                            rowIndex={rowIndex}
                            tableBorderColor={tableBorderColor}
                            tableBorderWidth={tableBorderWidth}
                            tooltipLabel={item?.tooltipLabel}
                            {...cellProps}
                            {...item.cellProps}
                        />
                    );
                })}
            </HStack>
        );
    };

    if (tooltipLabel) {
        return <Tooltip label={tooltipLabel ?? ""}>{renderRow()}</Tooltip>;
    }

    return renderRow();
};

export default React.memo(Row);
