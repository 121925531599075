import React, { useRef } from "react";
import type {
    ReactElement,
    ComponentProps,
    SetStateAction,
    MutableRefObject,
    Dispatch,
} from "react";

import { AlertDialog, Button } from "native-base";

type AlertDialogProps = Omit<
    ComponentProps<typeof AlertDialog>,
    "leastDestructiveRef"
>;
type AlertDialogBodyProps = ComponentProps<typeof AlertDialog.Body>;

interface Props extends AlertDialogProps {
    alertIsOpen: boolean;
    body?: string | ReactElement;
    bodyStyle?: AlertDialogBodyProps;
    children?: ReactElement | ReactElement[] | null;
    header?: string | ReactElement;
    hideCloseButton?: boolean;
    leftAlignText?: boolean;
    onCloseAlert?: () => void;
    safeButtonRef?: MutableRefObject<null>;
    setAlertIsOpen:
        | Dispatch<SetStateAction<boolean>>
        | ((isOpen: boolean) => void);
}

const AlertPopup = (props: Props): ReactElement => {
    const {
        alertIsOpen,
        body,
        bodyStyle,
        children,
        header,
        hideCloseButton,
        leftAlignText,
        onCloseAlert,
        safeButtonRef,
        setAlertIsOpen,
    } = props;

    const buttonRef = useRef();

    return (
        <AlertDialog
            isOpen={alertIsOpen}
            leastDestructiveRef={safeButtonRef ?? buttonRef}
            onClose={() => {
                setAlertIsOpen(false);
                if (onCloseAlert) {
                    onCloseAlert();
                }
            }}
            size="lg"
            {...props}
        >
            <AlertDialog.Content>
                {!hideCloseButton ? <AlertDialog.CloseButton m="1" /> : null}
                {header ? (
                    <AlertDialog.Header
                        _text={{
                            fontWeight: "600",
                            textAlign: "center",
                            fontSize: 20,
                            lineHeight: 32,
                        }}
                        px="16"
                    >
                        {header}
                    </AlertDialog.Header>
                ) : null}
                {body ? (
                    <AlertDialog.Body
                        px={leftAlignText ? "8" : "4"}
                        {...bodyStyle}
                        _text={{
                            fontSize: "lg",
                            textAlign: leftAlignText ? "left" : "center",
                            fontWeight: "light",
                            ...bodyStyle?._text,
                        }}
                    >
                        {body}
                    </AlertDialog.Body>
                ) : null}
                <AlertDialog.Footer>
                    {children ? (
                        <Button.Group
                            alignItems="center"
                            flex={1}
                            justifyContent="center"
                            p="0"
                            size="lg"
                            space={4}
                        >
                            {children}
                        </Button.Group>
                    ) : null}
                </AlertDialog.Footer>
            </AlertDialog.Content>
        </AlertDialog>
    );
};

export default AlertPopup;
