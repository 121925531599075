import React, {
    useCallback,
    useReducer,
    useMemo,
    useRef,
    useState,
} from "react";
import type { FC } from "react";

import {
    VStack,
    HStack,
    Box,
    Text,
    Button,
    useToast,
    Center,
    Spinner,
} from "native-base";
import { useMutation } from "react-relay";

import { useAuth } from "pianofunclub-shared/providers/AuthProvider";

import SideBar from "../../components/Drawers/SideBar";
import type {
    ProfileStackNavigatorProps,
    ProfileStackRouteProps,
} from "../../navigation/ProfileNavigator";
import TextInput from "pianofunclub-shared/components/Inputs/TextInput";
import AlertPopup from "pianofunclub-shared/components/NativeBaseExtended/AlertPopup";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import {
    MailIcon,
    SecurityIcon,
} from "pianofunclub-shared/components/Other/Icons";

import type {
    UpdateEmailMutation,
    UpdateEmailMutation$data,
} from "pianofunclub-shared/relay/graphql/auth/__generated__/UpdateEmailMutation.graphql";
import { update_email } from "pianofunclub-shared/relay/graphql/auth/UpdateEmail";

import type { AlertError } from "pianofunclub-shared/types";
import { mapErrorToAlertText } from "pianofunclub-shared/utils/extractors";
import { createFormReducer } from "pianofunclub-shared/utils/reducers";

type NavigationProps = ProfileStackNavigatorProps<"UpdateEmail">;

type RouteProps = ProfileStackRouteProps<"UpdateEmail">;

interface ScreenProps {
    navigation: NavigationProps;
    route: RouteProps;
}

type InputValues = {
    email: string;
    password: string;
};

type InputTypes = string;

const SIDE_BAR_WIDTH = 12;

const UpdateEmailScreen: FC<ScreenProps> = (props) => {
    const { navigation } = props;

    const initialState = useMemo(() => {
        return {
            inputValues: {
                email: "",
                password: "",
            },
            inputValidities: {
                email: false,
                password: false,
            },
            formIsValid: false,
            formIsEdited: false,
        };
    }, []);

    const formReducer = createFormReducer<InputValues, InputTypes>(
        initialState,
    );
    const [formState, dispatchFormState] = useReducer(
        formReducer,
        initialState,
    );

    const { user } = useAuth();
    const updateToast = useToast();
    const updateToastId = "updateToast";
    const passwordInputRef = useRef<{ clear: () => void; focus: () => void }>();
    const [failedAlertIsOpen, setFailedAlertIsOpen] = useState(false);
    const [failedError, setFailedError] = useState<AlertError>({
        code: undefined,
        alertHeader: "",
        alertMessage: "",
    });

    const clearPasswordInput = useCallback(() => {
        dispatchFormState({
            value: "",
            isValid: false,
            input: "password",
        });
        passwordInputRef.current?.clear();
    }, []);

    const [commitUpdateEmail, updateEmailInFlight] =
        useMutation<UpdateEmailMutation>(update_email);

    const updateEmail = useCallback(
        (email: string, password: string) => {
            const updateEmailConfig = {
                variables: {
                    input: {
                        newEmail: email,
                        password: password,
                    },
                },
                onCompleted: (response: UpdateEmailMutation$data) => {
                    if (response?.updateEmail?.success) {
                        if (!updateToast.isActive(updateToastId)) {
                            updateToast.show({
                                id: updateToastId,
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        id={id}
                                        status="success"
                                        title={"Email updated"}
                                        toast={updateToast}
                                    />
                                ),
                            });
                        }
                        navigation.goBack();
                        // * add proper error handling (different toast for invalid password)
                    } else {
                        clearPasswordInput();
                        setFailedError(
                            mapErrorToAlertText(
                                response?.updateEmail?.errors[0]?.code,
                            ),
                        );
                        setFailedAlertIsOpen(true);
                    }
                },
                onError: () => {
                    if (!updateToast.isActive(updateToastId)) {
                        updateToast.show({
                            id: updateToastId,
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="error"
                                    title={"Email could not be updated"}
                                    toast={updateToast}
                                />
                            ),
                        });
                    }
                },
            };

            commitUpdateEmail(updateEmailConfig);
        },
        [clearPasswordInput, commitUpdateEmail, navigation, updateToast],
    );

    const inputChangeHandler = useCallback(
        (
            inputIdentifier?: string | number,
            inputValue?: string,
            isValid?: boolean,
        ) => {
            if (
                typeof inputIdentifier === "string" &&
                typeof inputValue === "string"
            ) {
                dispatchFormState({
                    value:
                        inputIdentifier == "email"
                            ? inputValue.trim()
                            : inputValue,
                    isValid: isValid,
                    input: inputIdentifier,
                });
            }
        },
        [dispatchFormState],
    );

    return (
        <Box flex={1}>
            <SideBar
                isCollapsed
                navigation={navigation}
                width={SIDE_BAR_WIDTH}
            />
            <Center bg="surface.100" flex={1} ml={SIDE_BAR_WIDTH} pt="70">
                <VStack alignItems="center" space="2">
                    <Text fontSize="2xl" fontWeight={700} mb="4">
                        Update Email Address
                    </Text>
                    <Center>
                        <Text fontSize="lg">Current Email Address</Text>
                        <HStack alignItems="center" space={1}>
                            <Text color="warmGray.500" fontSize="md">
                                {user?.email ?? ""}
                            </Text>
                        </HStack>
                    </Center>
                    <TextInput
                        autoCapitalize="none"
                        autoComplete="new-password"
                        blurOnSubmit={false}
                        email
                        errorMessage="That email address doesn't look right..."
                        id="email"
                        initiallyValid={false}
                        initialValue=""
                        InputLeftElement={
                            <MailIcon
                                color={
                                    formState.inputValues.email == "" ||
                                    !formState.inputValidities.email
                                        ? "muted.400"
                                        : "emerald.400"
                                }
                                ml="4"
                                size="6"
                            />
                        }
                        invalidIndicator
                        keyboardType="email-address"
                        label="New Email"
                        labelStyle={{
                            color: "surface.700",
                            fontSize: "sm",
                        }}
                        mb="4"
                        onInputChange={inputChangeHandler}
                        onSubmit={() => passwordInputRef.current?.focus()}
                        p="3"
                        placeholder="Enter your new email address"
                        returnKeyType="next"
                        size="xl"
                    />
                    <TextInput
                        ref={passwordInputRef}
                        autoCapitalize="none"
                        autoComplete="new-password"
                        id="password"
                        initiallyValid={false}
                        initialValue=""
                        InputLeftElement={
                            <SecurityIcon
                                color={
                                    formState.inputValues.password == "" ||
                                    !formState.inputValidities.password
                                        ? "muted.400"
                                        : "emerald.400"
                                }
                                ml="4"
                                size="6"
                            />
                        }
                        invalidIndicator
                        keyboardType="default"
                        label="Password"
                        labelStyle={{
                            color: "surface.700",
                            fontSize: "sm",
                        }}
                        mb="12"
                        minLength={8}
                        onInputChange={inputChangeHandler}
                        onSubmit={() => {
                            if (formState.formIsValid && !updateEmailInFlight) {
                                updateEmail(
                                    formState.inputValues.email,
                                    formState.inputValues.password,
                                );
                            }
                        }}
                        p="3"
                        placeholder="Confirm your password"
                        secureTextEntry
                        size="xl"
                    />
                    <Button
                        colorScheme="primary"
                        height="60px"
                        isDisabled={
                            !formState.formIsValid || updateEmailInFlight
                        }
                        onPress={() =>
                            updateEmail(
                                formState.inputValues.email,
                                formState.inputValues.password,
                            )
                        }
                        width="200px"
                    >
                        {updateEmailInFlight ? (
                            <Spinner color="white" />
                        ) : (
                            <Text color="white" fontSize="xl">
                                Save
                            </Text>
                        )}
                    </Button>
                    <Button
                        _hover={{ bg: "transparent", opacity: 0.8 }}
                        _pressed={{ bg: "transparent", opacity: 0.7 }}
                        colorScheme="surface"
                        mt="4"
                        onPress={() => navigation.goBack()}
                        variant="ghost"
                    >
                        Go Back
                    </Button>
                </VStack>
            </Center>
            <AlertPopup
                alertIsOpen={failedAlertIsOpen}
                body={failedError.alertMessage}
                closeOnOverlayClick
                header={failedError.alertHeader}
                setAlertIsOpen={setFailedAlertIsOpen}
            >
                <Button
                    _text={{ fontSize: "lg" }}
                    colorScheme="primary"
                    onPress={() => {
                        setFailedAlertIsOpen(false);
                    }}
                    width="40%"
                >
                    Ok
                </Button>
            </AlertPopup>
        </Box>
    );
};

export const screenOptions = {
    headerTitle: "Update Email",
};

export default React.memo(UpdateEmailScreen);
