import React, {
    useMemo,
    useEffect,
    useRef,
    useImperativeHandle,
    forwardRef,
} from "react";
import type { ReactElement, ComponentProps } from "react";

import debounce from "lodash.debounce";
import { Box, Button } from "native-base";
import type { Input } from "native-base";

import LoadingBlobs from "../Animations/LoadingBlobs";
import TextInput from "../Inputs/TextInput";

import { SearchIcon } from "./Icons";

type BoxProps = ComponentProps<typeof Box>;
type TextInputProps = ComponentProps<typeof Input>;

interface Props extends BoxProps {
    inputClearHandler?: (
        id?: string | number,
        inputValue?: string,
        isValid?: boolean,
    ) => void;
    inputFinishEditingHandler?: (
        id?: string | number,
        inputValue?: string,
        isValid?: boolean,
    ) => void;
    inputOnChangeHandler?: (
        id?: string | number,
        inputValue?: string,
        isValid?: boolean,
    ) => void;
    inputPressHandler?: (
        inputIdentifier?: string | number,
        inputValue?: string,
        isValid?: boolean,
    ) => void;
    inputSearchHandler?: (
        id?: string | number,
        inputValue?: string,
        isValid?: boolean,
    ) => void;
    isLoading?: boolean;
    loadingText?: string;
    placeholderText?: string;
    searchText?: string;
    showSearchIcon?: boolean;
    textInputProps?: TextInputProps;
}

const SearchBar = forwardRef((props: Props, ref): ReactElement => {
    const {
        inputClearHandler,
        inputFinishEditingHandler,
        inputOnChangeHandler,
        inputPressHandler,
        inputSearchHandler,
        isLoading,
        loadingText,
        placeholderText,
        searchText,
        showSearchIcon,
        textInputProps,
    } = props;

    const inputRef = useRef<{ clear: () => void; focus: () => void }>(null);
    // handle ref on component, but also allow parent to handle ref
    useImperativeHandle(ref, () => inputRef.current);

    const debouncedInputChangeHandler = useMemo(() => {
        if (inputOnChangeHandler) {
            return debounce(inputOnChangeHandler, 500);
        }
    }, [inputOnChangeHandler]);

    // Stop the debounced function after unmounting
    useEffect(() => {
        return () => {
            debouncedInputChangeHandler?.cancel();
        };
    }, [debouncedInputChangeHandler]);

    return (
        <Box flex={isLoading ? 1 : undefined}>
            <Box width="100%" {...props}>
                <TextInput
                    ref={inputRef}
                    _disabled={{ opacity: 1, bg: "transparent" }}
                    blurOnSubmit
                    borderRadius="2xl"
                    borderWidth="1"
                    clearButton
                    id="search"
                    initialValue={searchText}
                    InputLeftElement={
                        showSearchIcon ? (
                            <Button
                                _hover={{ bg: "transparent" }}
                                _pressed={{ bg: "transparent" }}
                                colorScheme="surface"
                                leftIcon={<SearchIcon size="6" />}
                                ml="4"
                                onPress={() => inputRef.current?.focus()}
                                p="0"
                                variant="ghost"
                                zIndex={2}
                            />
                        ) : undefined
                    }
                    isDisabled={isLoading}
                    keyboardType="default"
                    onClear={inputClearHandler}
                    onFinishEditing={inputFinishEditingHandler}
                    onInputChange={debouncedInputChangeHandler}
                    onPress={inputPressHandler}
                    onSubmit={inputSearchHandler}
                    placeholder={placeholderText}
                    placeholderTextColor="muted.400"
                    returnKeyType="search"
                    {...textInputProps}
                />
            </Box>
            {isLoading && <LoadingBlobs>{loadingText}</LoadingBlobs>}
        </Box>
    );
});

SearchBar.displayName = "SearchBar";

export default React.memo(SearchBar);
