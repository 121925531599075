/**
 * @generated SignedSource<<5347a1ca6ec77a19ae7bf8c3a60f383c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProfileModelDiscountCategory = "DISCOUNT_PUPIL_OTHER" | "DISCOUNT_PUPIL_PPKID" | "FULL_PRICE" | "%future added value";
export type EditProfileInput = {
  addressFirstLine?: string | null;
  addressSecondLine?: string | null;
  city?: string | null;
  clientMutationId?: string | null;
  dbsExpirationDate?: string | null;
  dbsNumber?: string | null;
  discountCategory?: string | null;
  discountPercentage?: number | null;
  email?: string | null;
  firstName?: string | null;
  hasBook?: boolean | null;
  hasInstrument?: boolean | null;
  hasReturnedInstrument?: boolean | null;
  instrumentSuppliedByUs?: boolean | null;
  lastName?: string | null;
  paidBySchool?: boolean | null;
  phoneNumber?: string | null;
  postcode?: string | null;
  profileId: string;
  school?: string | null;
  schoolClass?: string | null;
  schoolYear?: number | null;
  staffNoteForTeacher?: string | null;
};
export type EditProfileMutation$variables = {
  input: EditProfileInput;
};
export type EditProfileMutation$data = {
  readonly editProfile: {
    readonly errors: any | null;
    readonly profile: {
      readonly addressFirstLine: string | null;
      readonly addressSecondLine: string | null;
      readonly city: string | null;
      readonly dbsExpirationDate: string | null;
      readonly dbsNumber: string | null;
      readonly discountCategory: ProfileModelDiscountCategory | null;
      readonly discountPercentage: number | null;
      readonly hasBook: boolean | null;
      readonly hasInstrument: boolean | null;
      readonly hasReturnedInstrument: boolean | null;
      readonly id: string;
      readonly instrumentSuppliedByUs: boolean | null;
      readonly paidBySchool: boolean | null;
      readonly phoneNumber: string | null;
      readonly postcode: string | null;
      readonly school: {
        readonly id: string;
        readonly name: string;
      } | null;
      readonly schoolClass: string | null;
      readonly schoolYear: number | null;
      readonly staffNoteForTeacher: string | null;
      readonly user: {
        readonly email: string;
        readonly firstName: string;
        readonly id: string;
        readonly lastName: string;
      };
    } | null;
    readonly success: boolean | null;
  } | null;
};
export type EditProfileMutation = {
  response: EditProfileMutation$data;
  variables: EditProfileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EditProfilePayload",
    "kind": "LinkedField",
    "name": "editProfile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProfileNode",
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SchoolNode",
            "kind": "LinkedField",
            "name": "school",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phoneNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "schoolYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "schoolClass",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "addressFirstLine",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "addressSecondLine",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "city",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "postcode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dbsNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dbsExpirationDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paidBySchool",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discountCategory",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discountPercentage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasBook",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasInstrument",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "instrumentSuppliedByUs",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasReturnedInstrument",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "staffNoteForTeacher",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DjangoUserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditProfileMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditProfileMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "eb659428d4be430e9bd0cb2f39f9f725",
    "id": null,
    "metadata": {},
    "name": "EditProfileMutation",
    "operationKind": "mutation",
    "text": "mutation EditProfileMutation(\n  $input: EditProfileInput!\n) {\n  editProfile(input: $input) {\n    success\n    errors\n    profile {\n      id\n      school {\n        id\n        name\n      }\n      phoneNumber\n      schoolYear\n      schoolClass\n      addressFirstLine\n      addressSecondLine\n      city\n      postcode\n      dbsNumber\n      dbsExpirationDate\n      paidBySchool\n      discountCategory\n      discountPercentage\n      hasBook\n      hasInstrument\n      instrumentSuppliedByUs\n      hasReturnedInstrument\n      staffNoteForTeacher\n      user {\n        id\n        firstName\n        lastName\n        email\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d8231cb76134681fd80b87b586f42059";

export default node;
