import React, { useMemo } from "react";
import type { ReactElement } from "react";

import { Pressable } from "native-base";

import InvoiceStatus from "../Invoicing/InvoiceStatus";
import type { CellProps } from "pianofunclub-shared/components/Base/Cell";
import Row from "pianofunclub-shared/components/Base/Row";

import type { LoadPayslips_query_payslips$data } from "pianofunclub-shared/relay/graphql/payslips/__generated__/LoadPayslips_query_payslips.graphql";

import { formattedDayMonthYear } from "pianofunclub-shared/utils/converters";
import { getFullName } from "pianofunclub-shared/utils/extractors";

interface Props {
    cellProps?: CellProps;
    data: NonNullable<
        NonNullable<
            NonNullable<
                LoadPayslips_query_payslips$data["payslips"]
            >["edges"][0]
        >["node"]
    >;
    flexArray: number[];
    onPressPayslip: (payslipId?: string) => void;
    rowHeight?: number;
    tableBorderColor?: string;
    tableBorderWidth?: number;
}

const PayslipTableRow = (props: Props): ReactElement => {
    const {
        cellProps,
        data,
        flexArray,
        onPressPayslip,
        rowHeight,
        tableBorderColor,
        tableBorderWidth,
    } = props;

    const rowData = useMemo(() => {
        return [
            {
                data: data.teacher?.accountNumber ?? "",
            },
            {
                data: data.teacher?.user.firstName
                    ? getFullName(
                          data.teacher?.user.firstName,
                          data.teacher?.user.lastName,
                      )
                    : (data.teacher?.user.email ?? ""),
            },
            {
                data: data.amountAdjusted
                    ? data.amountAdjusted == 0
                        ? `£${data.amountDue}`
                        : `£${data.amountDue} (+£${data.amountAdjusted})`
                    : "",
            },
            {
                data:
                    formattedDayMonthYear(new Date(data.paidFromDate ?? "")) ??
                    "",
            },
            {
                data:
                    formattedDayMonthYear(new Date(data.paidToDate ?? "")) ??
                    "",
            },
            {
                data: (
                    <>
                        {data.status === "NOT_VIEWED" && (
                            <InvoiceStatus
                                isTransparent
                                status="NOT_VIEWED"
                                textProps={{
                                    fontFamily: "Poppins-Regular",
                                }}
                            />
                        )}
                        {data.status === "READY_TO_PAY" && (
                            <InvoiceStatus
                                isTransparent
                                status="READY_TO_PAY"
                                textProps={{
                                    fontFamily: "Poppins-Regular",
                                }}
                            />
                        )}
                        {data.status === "PAID" && (
                            <InvoiceStatus
                                isTransparent
                                status="PAID"
                                textProps={{
                                    fontFamily: "Poppins-Regular",
                                }}
                            />
                        )}
                    </>
                ),
            },
        ];
    }, [
        data.amountAdjusted,
        data.amountDue,
        data.paidFromDate,
        data.paidToDate,
        data.status,
        data.teacher?.accountNumber,
        data.teacher?.user.email,
        data.teacher?.user.firstName,
        data.teacher?.user.lastName,
    ]);

    return (
        <Pressable onPress={() => onPressPayslip(data.id)}>
            <Row
                cellProps={cellProps}
                data={rowData}
                flexArray={flexArray}
                rowHeight={rowHeight}
                rowIndex={0}
                tableBorderColor={tableBorderColor}
                tableBorderWidth={tableBorderWidth}
            />
        </Pressable>
    );
};

export default PayslipTableRow;
