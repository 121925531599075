import { graphql } from "react-relay";

export const delete_lesson_stage = graphql`
    mutation DeleteLessonStageMutation(
        $connections: [ID!]!
        $input: DeleteLessonStageInput!
    ) {
        deleteLessonStage(input: $input) {
            success
            errors
            deletedLessonStageId @deleteEdge(connections: $connections)
        }
    }
`;
