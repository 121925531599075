import { graphql } from "react-relay";

export const load_schools_for_dropdowns = graphql`
    query LoadSchoolsForDropdownsQuery(
        $searchTerm: String
        $orderBy: String
        $isActive: Boolean
    ) {
        schools(
            orderBy: $orderBy
            searchTerm: $searchTerm
            isActive: $isActive
        ) {
            __id
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;
