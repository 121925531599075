import React, {
    useCallback,
    Suspense,
    useRef,
    useMemo,
    useReducer,
    useEffect,
    useState,
} from "react";
import type { ReactElement, Dispatch, RefObject, SetStateAction } from "react";

import type { NavigationAction } from "@react-navigation/native";
import { format } from "date-fns";
import {
    Box,
    VStack,
    useToast,
    Button,
    PresenceTransition,
    HStack,
    Heading,
    Text,
} from "native-base";
import { useBeforeunload } from "react-beforeunload";
import { DatePickerModal } from "react-native-paper-dates";
import { usePreloadedQuery, useMutation } from "react-relay";
import type { PreloadedQuery } from "react-relay";

import type {
    NavigationProps as DefaultTermDatesScreenNavigationProps,
    ReducerValues as DefaultTermDatesScreenReducerValues,
    ReducerTypes as DefaultTermDatesScreenReducerTypes,
} from "../../screens/schools/DefaultTermDatesScreen";
import type {
    NavigationProps as SchoolScreenNavigationProps,
    ReducerValues as SchoolScreenReducerValues,
    ReducerTypes as SchoolScreenReducerTypes,
} from "../../screens/schools/SchoolScreen";
import { getFormattedDate } from "../../utils/datetime";
import TermDates from "../ListItems/TermDates";
import LoadingBlobs from "pianofunclub-shared/components/Animations/LoadingBlobs";
import ButtonDebounced from "pianofunclub-shared/components/Buttons/ButtonDebounced";
import AlertPopup from "pianofunclub-shared/components/NativeBaseExtended/AlertPopup";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import {
    CalendarIcon,
    CompleteIcon,
} from "pianofunclub-shared/components/Other/Icons";

import type {
    LoadTermDatesQuery,
    LoadTermDatesQuery$data,
} from "pianofunclub-shared/relay/graphql/schools/__generated__/LoadTermDatesQuery.graphql";
import type {
    RemoveTermDatesMutation,
    RemoveTermDatesMutation$data,
} from "pianofunclub-shared/relay/graphql/schools/__generated__/RemoveTermDatesMutation.graphql";
import type {
    UpdateTermDatesMutation,
    UpdateTermDatesMutation$data,
} from "pianofunclub-shared/relay/graphql/schools/__generated__/UpdateTermDatesMutation.graphql";
import { load_term_dates } from "pianofunclub-shared/relay/graphql/schools/LoadTermDates";
import { remove_term_dates } from "pianofunclub-shared/relay/graphql/schools/RemoveTermDates";
import { update_term_dates } from "pianofunclub-shared/relay/graphql/schools/UpdateTermDates";

import { isNavigationAction } from "pianofunclub-shared/types/guards";
import { TERMS } from "pianofunclub-shared/utils/constants";
import { createReducer } from "pianofunclub-shared/utils/reducers";
import type { State, Action } from "pianofunclub-shared/utils/reducers";

export type TermDatesData = NonNullable<
    LoadTermDatesQuery$data["termDates"]
>["edges"][0];

interface Props {
    dispatchScreenState: Dispatch<
        Action<
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            any,
            SchoolScreenReducerTypes & DefaultTermDatesScreenReducerTypes
        >
    >;
    loadTermDatesQueryReference:
        | PreloadedQuery<LoadTermDatesQuery, Record<string, unknown>>
        | undefined
        | null;
    navigation: SchoolScreenNavigationProps &
        DefaultTermDatesScreenNavigationProps;
    schoolName?: string;
    screenState: State<
        SchoolScreenReducerValues | DefaultTermDatesScreenReducerValues
    >;
}

interface ContentProps extends Props {
    dispatchState: Dispatch<Action<ReducerValues, ReducerTypes>>;
    inputState: InputType;
    loadTermDatesQueryReference: PreloadedQuery<
        LoadTermDatesQuery,
        Record<string, unknown>
    >;
    setInputState: Dispatch<SetStateAction<InputType>>;
    state: State<ReducerValues>;
}

export type ReducerValues = {
    termDatePickerDate?: Date;
    termDatePickerInputId?: string;
    termDatePickerInputRef?: RefObject<{
        setValue: (text: string, isValid: boolean) => void;
    }>;
    termDatePickerIsOpen: boolean;
};

export type ReducerTypes =
    | string
    | number
    | boolean
    | Date
    | RefObject<{
          setValue: (text: string, isValid: boolean) => void;
      }>
    | NavigationAction
    | (() => void)
    | undefined;

export type TermInputType = {
    firstDayOfSecondHalfOfTerm?: Date;
    firstDayOfTerm?: Date;
    lastDayOfFirstHalfOfTerm?: Date;
    lastDayOfTerm?: Date;
};

export type InputType = {
    1: TermInputType;
    2: TermInputType;
    3: TermInputType;
};

const INITIAL_INPUT_STATE: InputType = {
    1: {
        firstDayOfTerm: undefined,
        lastDayOfFirstHalfOfTerm: undefined,
        firstDayOfSecondHalfOfTerm: undefined,
        lastDayOfTerm: undefined,
    },
    2: {
        firstDayOfTerm: undefined,
        lastDayOfFirstHalfOfTerm: undefined,
        firstDayOfSecondHalfOfTerm: undefined,
        lastDayOfTerm: undefined,
    },
    3: {
        firstDayOfTerm: undefined,
        lastDayOfFirstHalfOfTerm: undefined,
        firstDayOfSecondHalfOfTerm: undefined,
        lastDayOfTerm: undefined,
    },
};

const YearTermDatesContent = (props: ContentProps): ReactElement => {
    const {
        dispatchScreenState,
        dispatchState,
        inputState,
        loadTermDatesQueryReference,
        navigation,
        schoolName,
        screenState,
        setInputState,
        state,
    } = props;

    const data = usePreloadedQuery(
        load_term_dates,
        loadTermDatesQueryReference,
    );

    // default term dates
    const defaultTermDates = useMemo(() => {
        return (
            data.termDates?.edges?.reduce(
                (output, item) => {
                    if (item?.node?.term) {
                        output[item.node.term as keyof typeof output] =
                            item.node;
                    }
                    return output;
                },
                {
                    1: undefined as
                        | NonNullable<TermDatesData>["node"]
                        | undefined,
                    2: undefined as
                        | NonNullable<TermDatesData>["node"]
                        | undefined,
                    3: undefined as
                        | NonNullable<TermDatesData>["node"]
                        | undefined,
                },
            ) ?? []
        );
    }, [data.termDates?.edges]);

    const initialiseSchoolTermDateState = useCallback(() => {
        const output = data.schoolTermDates?.edges?.reduce(
            (output, item) => {
                if (item?.node?.term) {
                    output[item.node.term as keyof typeof output] = item.node;
                }
                return output;
            },
            {
                1: undefined as NonNullable<TermDatesData>["node"] | undefined,
                2: undefined as NonNullable<TermDatesData>["node"] | undefined,
                3: undefined as NonNullable<TermDatesData>["node"] | undefined,
            },
        ) ?? { 1: undefined, 2: undefined, 3: undefined };
        return output;
    }, [data.schoolTermDates?.edges]);

    const [schoolTermDates, setSchoolTermDates] = useState(() =>
        initialiseSchoolTermDateState(),
    );

    useEffect(() => {
        setSchoolTermDates(() => initialiseSchoolTermDateState());
    }, [initialiseSchoolTermDateState]);

    const schoolTermDatesConnectionId = useMemo(() => {
        return data.schoolTermDates?.__id;
    }, [data.schoolTermDates?.__id]);

    const safeLeaveRef = useRef(null);

    const toast = useToast();

    const [commitUpdateTermDates, updateTermDatesInFlight] =
        useMutation<UpdateTermDatesMutation>(update_term_dates);

    const updateTermDates = useCallback(
        (onComplete?: () => void) => {
            const updateTermDatesConfig = {
                variables: {
                    input: {
                        startingYear: screenState.values.startingYear,
                        school: schoolName,
                        term1Dates: {
                            firstDayOfTerm: getFormattedDate(
                                inputState[1].firstDayOfTerm,
                            ),
                            lastDayOfFirstHalfOfTerm: getFormattedDate(
                                inputState[1].lastDayOfFirstHalfOfTerm,
                            ),
                            firstDayOfSecondHalfOfTerm: getFormattedDate(
                                inputState[1].firstDayOfSecondHalfOfTerm,
                            ),
                            lastDayOfTerm: getFormattedDate(
                                inputState[1].lastDayOfTerm,
                            ),
                        },
                        term2Dates: {
                            firstDayOfTerm: getFormattedDate(
                                inputState[2].firstDayOfTerm,
                            ),
                            lastDayOfFirstHalfOfTerm: getFormattedDate(
                                inputState[2].lastDayOfFirstHalfOfTerm,
                            ),
                            firstDayOfSecondHalfOfTerm: getFormattedDate(
                                inputState[2].firstDayOfSecondHalfOfTerm,
                            ),
                            lastDayOfTerm: getFormattedDate(
                                inputState[2].lastDayOfTerm,
                            ),
                        },
                        term3Dates: {
                            firstDayOfTerm: getFormattedDate(
                                inputState[3].firstDayOfTerm,
                            ),
                            lastDayOfFirstHalfOfTerm: getFormattedDate(
                                inputState[3].lastDayOfFirstHalfOfTerm,
                            ),
                            firstDayOfSecondHalfOfTerm: getFormattedDate(
                                inputState[3].firstDayOfSecondHalfOfTerm,
                            ),
                            lastDayOfTerm: getFormattedDate(
                                inputState[3].lastDayOfTerm,
                            ),
                        },
                    },
                },
                onCompleted: (response: UpdateTermDatesMutation$data) => {
                    if (response?.updateTermDates?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={"Updated term dates"}
                                    toast={toast}
                                />
                            ),
                        });
                        onComplete?.();
                        setInputState(INITIAL_INPUT_STATE);
                        setSchoolTermDates((termDates) => {
                            if (response?.updateTermDates?.term1Dates) {
                                termDates[1] =
                                    response.updateTermDates.term1Dates;
                            }
                            if (response?.updateTermDates?.term2Dates) {
                                termDates["2"] =
                                    response.updateTermDates.term2Dates;
                            }
                            if (response?.updateTermDates?.term3Dates) {
                                termDates["3"] =
                                    response.updateTermDates.term3Dates;
                            }
                            return termDates;
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't update term dates"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitUpdateTermDates(updateTermDatesConfig);
        },
        [
            commitUpdateTermDates,
            inputState,
            schoolName,
            screenState.values.startingYear,
            setInputState,
            toast,
        ],
    );

    const commitRemoveTermDates =
        useMutation<RemoveTermDatesMutation>(remove_term_dates)[0];

    const removeTermDates = useCallback(
        (term: string, schoolTermDatesId: string | undefined) => {
            // reset state for this term
            setInputState((prevState) => {
                return {
                    ...prevState,
                    [term]: {
                        firstDayOfTerm: undefined,
                        lastDayOfFirstHalfOfTerm: undefined,
                        firstDayOfSecondHalfOfTerm: undefined,
                        lastDayOfTerm: undefined,
                    },
                };
            });

            if (!schoolTermDatesId) {
                return;
            }

            const removeTermDatesConfig = {
                variables: {
                    connections: schoolTermDatesConnectionId
                        ? [schoolTermDatesConnectionId]
                        : [],
                    input: {
                        schoolTermDatesId: schoolTermDatesId,
                    },
                },
                optimisticResponse: {
                    removeTermDates: {
                        success: true,
                        errors: null,
                        deletedTermDateId: schoolTermDatesId,
                    },
                },
                onCompleted: (response: RemoveTermDatesMutation$data) => {
                    if (response?.removeTermDates?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={"Reset term dates to default"}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't reset term dates"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitRemoveTermDates(removeTermDatesConfig);
        },
        [
            commitRemoveTermDates,
            schoolTermDatesConnectionId,
            setInputState,
            toast,
        ],
    );

    const selectDateHandler = useCallback(
        (params: { date?: Date }) => {
            if (
                params.date &&
                state.values.termDatePickerInputRef &&
                state.values.termDatePickerInputId
            ) {
                // id is in the format {term}-{key}
                const term = Number(
                    state.values.termDatePickerInputId.split("-")[0],
                ) as 1 | 2 | 3;
                const key = state.values.termDatePickerInputId.split(
                    "-",
                )[1] as keyof TermInputType;
                // check if the value is different from the current value
                if (params.date !== inputState[term][key]) {
                    state.values.termDatePickerInputRef.current?.setValue(
                        format(params.date, "EEE do MMM yyyy"),
                        true,
                    );
                    setInputState((prevState) => {
                        return {
                            ...prevState,
                            [term]: {
                                ...prevState[term],
                                // if it is the same as the value on the server, remove it from the input state
                                [key]:
                                    getFormattedDate(params.date) !==
                                    getFormattedDate(
                                        schoolTermDates[term]?.[key] ??
                                            defaultTermDates[term]?.[key],
                                    )
                                        ? params.date
                                        : undefined,
                            },
                        };
                    });
                }
            }
            dispatchState({
                input: "termDatePickerIsOpen",
                value: false,
            });
        },
        [
            defaultTermDates,
            dispatchState,
            inputState,
            schoolTermDates,
            setInputState,
            state.values.termDatePickerInputId,
            state.values.termDatePickerInputRef,
        ],
    );

    return (
        <>
            <VStack flex={1} mt="6" space="8">
                {TERMS.map((item, index) => {
                    return (
                        <TermDates
                            key={index}
                            defaultTermDates={
                                defaultTermDates?.[(index + 1) as 1 | 2 | 3]
                            }
                            dispatchState={dispatchState}
                            inputState={inputState[(index + 1) as 1 | 2 | 3]}
                            isDefaultTermDateScreen={!schoolName}
                            label={item.label}
                            removeTermDates={removeTermDates}
                            schoolTermDates={
                                schoolTermDates?.[(index + 1) as 1 | 2 | 3]
                            }
                            term={item.value}
                        />
                    );
                })}
            </VStack>
            <PresenceTransition
                animate={{
                    opacity: 1,
                    scale: 1,
                    transition: {
                        duration: 250,
                    },
                }}
                initial={{
                    opacity: 0,
                    scale: 0,
                }}
                visible={screenState.values.termDatesHaveBeenModified}>
                <Button
                    _focus={{ opacity: 1 }}
                    _hover={{ opacity: 1 }}
                    _spinner={{ size: "lg" }}
                    _text={{ fontSize: "xl" }}
                    alignSelf="center"
                    bottom={!schoolName ? "20px" : "-100px"}
                    height="60px"
                    isLoading={updateTermDatesInFlight}
                    leftIcon={<CompleteIcon size="8" />}
                    onPress={() => updateTermDates()}
                    position={"absolute"}
                    shadow={5}
                    width="300px">
                    Save
                </Button>
            </PresenceTransition>
            <DatePickerModal
                animationType="fade"
                date={
                    state.values.termDatePickerDate
                        ? new Date(state.values.termDatePickerDate)
                        : new Date()
                }
                endYear={Number(screenState.values.startingYear) + 1}
                label="Select Date"
                locale="en"
                mode="single"
                onConfirm={selectDateHandler}
                onDismiss={() =>
                    dispatchState({
                        input: "termDatePickerIsOpen",
                        value: false,
                    })
                }
                saveLabel="Select"
                startYear={Number(screenState.values.startingYear)}
                uppercase={false}
                visible={state.values.termDatePickerIsOpen}
            />
            <AlertPopup
                alertIsOpen={Boolean(screenState.values.leaveAlertAction)}
                body="You have made unsaved changes to your registers."
                header="Save your changes?"
                safeButtonRef={safeLeaveRef}
                setAlertIsOpen={(isOpen: boolean) =>
                    dispatchScreenState({
                        input: "leaveAlertAction",
                        value: isOpen,
                    })
                }>
                <Button
                    _text={{ fontSize: "lg" }}
                    colorScheme="red"
                    minWidth="100"
                    onPress={() => {
                        if (
                            isNavigationAction(
                                screenState.values.leaveAlertAction,
                            )
                        ) {
                            navigation.dispatch(
                                screenState.values.leaveAlertAction,
                            );
                        } else {
                            screenState.values.leaveAlertAction?.();
                        }
                        dispatchScreenState({
                            input: "leaveAlertAction",
                            value: undefined,
                        });
                        setInputState(INITIAL_INPUT_STATE);
                    }}
                    width="40%">
                    Discard
                </Button>
                <ButtonDebounced
                    ref={safeLeaveRef}
                    _text={{ fontSize: "lg" }}
                    colorScheme="primary"
                    minWidth="100"
                    onPress={() => {
                        dispatchScreenState({
                            input: "leaveAlertAction",
                            value: undefined,
                        });
                        updateTermDates(() => {
                            if (
                                isNavigationAction(
                                    screenState.values.leaveAlertAction,
                                )
                            ) {
                                navigation.dispatch(
                                    screenState.values.leaveAlertAction,
                                );
                            } else {
                                screenState.values.leaveAlertAction?.();
                            }
                        });
                    }}
                    width="40%">
                    Save
                </ButtonDebounced>
            </AlertPopup>
        </>
    );
};

const YearTermDates = (props: Props): ReactElement => {
    const {
        dispatchScreenState,
        loadTermDatesQueryReference,
        navigation,
        schoolName,
        screenState,
    } = props;

    const initialState = useMemo(() => {
        return {
            values: {
                termDatePickerIsOpen: false,
                termDatePickerDate: undefined,
                termDatePickerInputId: undefined,
                termDatePickerInputRef: undefined,
                leaveAlertAction: undefined,
            },
        };
    }, []);

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [state, dispatchState] = useReducer(reducer, initialState);

    const [inputState, setInputState] = useState(INITIAL_INPUT_STATE);

    useEffect(() => {
        dispatchScreenState({
            input: "termDatesHaveBeenModified",
            value: Object.values(inputState).some((term) =>
                Object.values(term).some((value) => value !== undefined),
            ),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputState]);

    // if unsaved updates have been made to registers, show an alert
    useEffect(() => {
        const unsubscribe = navigation.addListener("beforeRemove", (e) => {
            if (!screenState.values.termDatesHaveBeenModified) {
                return;
            }

            e.preventDefault();
            dispatchScreenState({
                input: "leaveAlertAction",
                value: e.data.action,
            });
        });

        return () => unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenState.values.termDatesHaveBeenModified]);

    // trigger a popup if the CRM is unloaded
    useBeforeunload((e) => {
        if (!screenState.values.termDatesHaveBeenModified) {
            return;
        }

        e.preventDefault();
    });

    const renderHeader = useCallback(() => {
        return (
            <VStack mx="-6" px="6">
                <HStack
                    justifyContent="space-between"
                    my={!schoolName ? "6" : "0"}>
                    <HStack ml="1" space="3">
                        <CalendarIcon color="primary.600" size="xl" />
                        <Heading color="primary.600" fontSize="xl">
                            {schoolName ? "Term Dates" : "Default Term Dates"}
                        </Heading>
                    </HStack>
                    <Button
                        _hover={{ bg: "primary.500" }}
                        _pressed={{ bg: "primary.600" }}
                        _text={{ fontSize: "17" }}
                        bg="primary.400"
                        leftIcon={<CalendarIcon size="md" />}
                        onPress={() => {
                            navigation.navigate("DefaultTermDates", {
                                startingYear: screenState.values.startingYear,
                            });
                        }}
                        px="4"
                        shadow={1}>
                        Update Default Term Dates
                    </Button>
                </HStack>
                {!schoolName ? (
                    <Text
                        fontFamily="Poppins-Regular"
                        fontSize="md"
                        lineHeight="2xl"
                        mb="4">
                        {
                            "These term dates will be used as defaults for any schools without their own custom term dates.\nChanging the dates here will not overwrite any existing school-specific term dates."
                        }
                    </Text>
                ) : null}
            </VStack>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schoolName, screenState.values.startingYear]);

    return (
        <Box flex={1}>
            {renderHeader()}
            <Box height={420} justifyContent="center">
                {loadTermDatesQueryReference != null ? (
                    <Suspense
                        fallback={
                            <LoadingBlobs>Loading Term Dates...</LoadingBlobs>
                        }>
                        <YearTermDatesContent
                            {...props}
                            dispatchScreenState={dispatchScreenState}
                            dispatchState={dispatchState}
                            inputState={inputState}
                            loadTermDatesQueryReference={
                                loadTermDatesQueryReference
                            }
                            screenState={screenState}
                            setInputState={setInputState}
                            state={state}
                        />
                    </Suspense>
                ) : (
                    <LoadingBlobs>Loading Term Dates...</LoadingBlobs>
                )}
            </Box>
        </Box>
    );
};

export default React.memo(YearTermDates);
