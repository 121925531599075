import React, {
    useCallback,
    useState,
    useEffect,
    useRef,
    forwardRef,
    useImperativeHandle,
} from "react";
import type { ReactElement, Dispatch } from "react";

import {
    Text,
    Pressable,
    VStack,
    Spinner,
    Center,
    Button,
    PresenceTransition,
    Box,
} from "native-base";
import {
    usePreloadedQuery,
    useMutation,
    useRelayEnvironment,
    fetchQuery,
} from "react-relay";
import type {
    PreloadedQuery,
    UseQueryLoaderLoadQueryOptions,
} from "react-relay";

import { useAuth } from "pianofunclub-shared/providers/AuthProvider";

import { CloseIcon } from "pianofunclub-shared/components/Other/Icons";

import type { DismissMutationProgressIndicatorMutation } from "pianofunclub-shared/relay/graphql/general/__generated__/DismissMutationProgressIndicatorMutation.graphql";
import type {
    LoadMutationProgressQuery,
    LoadMutationProgressQuery$variables,
} from "pianofunclub-shared/relay/graphql/general/__generated__/LoadMutationProgressQuery.graphql";
import { dismiss_mutation_progress_indicator } from "pianofunclub-shared/relay/graphql/general/DismissMutationProgressIndicator";
import { load_mutation_progress } from "pianofunclub-shared/relay/graphql/general/LoadMutationProgress";

import type { Action, State } from "pianofunclub-shared/utils/reducers";

import type {
    ReducerValues as InvoicingHubReducerValues,
    ReducerTypes as InvoicingHubReducerTypes,
} from "pianofunclub-crm/screens/invoicing/InvoicingHubScreen";

export interface InvoiceMutationProgressIndicatorsRef {
    refreshMutationProgress: () => void;
}

interface Props {
    dispatchState: Dispatch<
        Action<InvoicingHubReducerValues, InvoicingHubReducerTypes>
    >;
    loadMutationProgressQuery: (
        variables: LoadMutationProgressQuery$variables,
        options?: UseQueryLoaderLoadQueryOptions | undefined,
    ) => void;
    loadMutationProgressQueryReference: PreloadedQuery<
        LoadMutationProgressQuery,
        Record<string, unknown>
    >;
    state: State<InvoicingHubReducerValues>;
}

const InvoiceMutationProgressIndicators = forwardRef<
    InvoiceMutationProgressIndicatorsRef,
    Props
>((props, ref): ReactElement => {
    const {
        dispatchState,
        loadMutationProgressQuery,
        loadMutationProgressQueryReference,
        state,
    } = props;

    const { user } = useAuth();

    const mutationProgressData = usePreloadedQuery(
        load_mutation_progress,
        loadMutationProgressQueryReference,
    );

    const [isRefetching, setIsRefetching] = useState(false);

    const environment = useRelayEnvironment();

    const refreshMutationProgress = useCallback(
        (options?: { doNotShowLoadingIndicator?: boolean }) => {
            if (isRefetching) {
                return;
            }

            if (!options?.doNotShowLoadingIndicator) {
                setIsRefetching(true);
            }

            if (user?.profile?.id) {
                fetchQuery<LoadMutationProgressQuery>(
                    environment,
                    load_mutation_progress,
                    {
                        profileId: user.profile.id,
                        startingYear: state.values.startingYear,
                        block: state.values.block,
                        skipInvoiceTotals: false,
                    },
                    { fetchPolicy: "network-only" },
                ).subscribe({
                    complete: () => {
                        setIsRefetching(false);
                        if (user?.profile?.id) {
                            loadMutationProgressQuery(
                                {
                                    profileId: user.profile.id,
                                    startingYear: state.values.startingYear,
                                    block: state.values.block,
                                    skipInvoiceTotals: false,
                                },
                                {
                                    // @ts-expect-error relay typing error
                                    fetchPolicy: "store-only",
                                },
                            );
                        }
                    },
                    error: () => {
                        setIsRefetching(false);
                    },
                });
            }
        },
        [
            environment,
            isRefetching,
            loadMutationProgressQuery,
            state.values.block,
            state.values.startingYear,
            user?.profile?.id,
        ],
    );

    useImperativeHandle(ref, () => ({
        refreshMutationProgress: () => {
            refreshMutationProgress({ doNotShowLoadingIndicator: true });
        },
    }));

    const commitDismissMutationProgressIndicator =
        useMutation<DismissMutationProgressIndicatorMutation>(
            dismiss_mutation_progress_indicator,
        )[0];

    const dismissMutationProgressIndicator = useCallback(
        (variables: {
            dismissGenerateDraftIndicator?: boolean;
            dismissMatchPaymentsIndicator?: boolean;
            dismissSendDraftIndicator?: boolean;
            dismissSendRemindersIndicator?: boolean;
        }) => {
            const dismissMutationProgressIndicatorConfig = {
                variables: {
                    input: variables,
                },
                optimisticResponse: {
                    dismissMutationProgressIndicator: {
                        success: true,
                        errors: null,
                        profileGroup: {
                            id: user?.profile?.profileGroup?.id,
                            generateDraftsProgress:
                                variables.dismissGenerateDraftIndicator
                                    ? null
                                    : mutationProgressData.profile?.profileGroup
                                          ?.generateDraftsProgress,
                            sendDraftsProgress:
                                variables.dismissSendDraftIndicator
                                    ? null
                                    : mutationProgressData.profile?.profileGroup
                                          ?.sendDraftsProgress,
                            sendRemindersProgress:
                                variables.dismissSendRemindersIndicator
                                    ? null
                                    : mutationProgressData.profile?.profileGroup
                                          ?.sendRemindersProgress,
                            matchPaymentsProgress:
                                variables.dismissMatchPaymentsIndicator
                                    ? null
                                    : mutationProgressData.profile?.profileGroup
                                          ?.matchPaymentsProgress,
                        },
                    },
                },
            };
            commitDismissMutationProgressIndicator(
                dismissMutationProgressIndicatorConfig,
            );
        },
        [
            commitDismissMutationProgressIndicator,
            mutationProgressData.profile?.profileGroup?.generateDraftsProgress,
            mutationProgressData.profile?.profileGroup?.matchPaymentsProgress,
            mutationProgressData.profile?.profileGroup?.sendDraftsProgress,
            mutationProgressData.profile?.profileGroup?.sendRemindersProgress,
            user?.profile?.profileGroup?.id,
        ],
    );

    const renderIndicator = useCallback(
        ({
            errorMessage,
            loadingMessage,
            onDismiss,
            progress,
            showIndicatorState,
            successMessage,
        }: {
            errorMessage: string;
            loadingMessage: string;
            onDismiss: () => void;
            progress: number | null | undefined;
            showIndicatorState: boolean;
            successMessage: string;
        }) => {
            if (typeof progress === "number" || showIndicatorState) {
                return (
                    <PresenceTransition
                        animate={{
                            opacity: 1,
                            scale: 1,
                            transition: {
                                duration: 250,
                            },
                        }}
                        initial={{
                            opacity: 0,
                            scale: 0,
                        }}
                        visible
                    >
                        <Pressable
                            bg={
                                progress === 100
                                    ? "primary.700"
                                    : // progress of -1 signifies error
                                      (progress ?? 0) < 0
                                      ? "red.500"
                                      : "primary.500"
                            }
                            borderRadius="xl"
                            flexDirection="row"
                            onPress={() => refreshMutationProgress()}
                            shadow={1}
                        >
                            <Text color="surface.100" pl="3" py="2">
                                {progress === 100
                                    ? successMessage
                                    : (progress ?? 0) < 0
                                      ? errorMessage
                                      : loadingMessage}
                            </Text>
                            {(progress ?? 0) >= 0 ? (
                                <Center pl="1" pr="3" py="2" width="65px">
                                    {isRefetching ? (
                                        <Spinner color="surface.100" />
                                    ) : (
                                        <Text color="surface.100">
                                            {"(" + (progress ?? 0) + "%)"}
                                        </Text>
                                    )}
                                </Center>
                            ) : (
                                <Box pr="3" />
                            )}
                        </Pressable>
                        <Button
                            bg="surface.900"
                            borderRadius="full"
                            leftIcon={
                                <CloseIcon color="surface.100" size="3" />
                            }
                            onPress={onDismiss}
                            p="1"
                            position="absolute"
                            right="-7"
                            shadow={1}
                            top="-7"
                            variant="dark"
                            zIndex={2}
                        />
                    </PresenceTransition>
                );
            } else {
                return null;
            }
        },
        [isRefetching, refreshMutationProgress],
    );

    // stop effects firing on first render
    const renderCount = useRef(0);

    // this series of effects closes loading indicator when progress finishes
    useEffect(() => {
        if (renderCount.current < 4) {
            renderCount.current += 1;
            return;
        }

        if (
            mutationProgressData.profile?.profileGroup
                ?.generateDraftsProgress === null
        ) {
            dispatchState({
                input: "showMutationProgressIndicators",
                value: {
                    ...state.values.showMutationProgressIndicators,
                    generateDrafts: false,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutationProgressData.profile?.profileGroup?.generateDraftsProgress]);

    useEffect(() => {
        if (renderCount.current < 4) {
            renderCount.current += 1;
            return;
        }

        if (
            mutationProgressData.profile?.profileGroup?.sendDraftsProgress ===
            null
        ) {
            dispatchState({
                input: "showMutationProgressIndicators",
                value: {
                    ...state.values.showMutationProgressIndicators,
                    sendDrafts: false,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutationProgressData.profile?.profileGroup?.sendDraftsProgress]);

    useEffect(() => {
        if (renderCount.current < 4) {
            renderCount.current += 1;
            return;
        }

        if (
            mutationProgressData.profile?.profileGroup
                ?.sendRemindersProgress === null
        ) {
            dispatchState({
                input: "showMutationProgressIndicators",
                value: {
                    ...state.values.showMutationProgressIndicators,
                    sendReminders: false,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutationProgressData.profile?.profileGroup?.sendRemindersProgress]);

    useEffect(() => {
        if (renderCount.current < 4) {
            renderCount.current += 1;
            return;
        }

        if (
            mutationProgressData.profile?.profileGroup
                ?.matchPaymentsProgress === null
        ) {
            dispatchState({
                input: "showMutationProgressIndicators",
                value: {
                    ...state.values.showMutationProgressIndicators,
                    matchPayments: false,
                },
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutationProgressData.profile?.profileGroup?.matchPaymentsProgress]);

    return (
        <VStack bottom="5" position="absolute" right="5" space="4">
            {renderIndicator({
                progress:
                    mutationProgressData.profile?.profileGroup
                        ?.matchPaymentsProgress,
                showIndicatorState:
                    state.values.showMutationProgressIndicators.matchPayments,
                successMessage: "Matched Payments",
                errorMessage: "Error Matching Payments",
                loadingMessage: "Matching Payments",
                onDismiss: () => {
                    dismissMutationProgressIndicator({
                        dismissMatchPaymentsIndicator: true,
                    });
                    dispatchState({
                        input: "showMutationProgressIndicators",
                        value: {
                            ...state.values.showMutationProgressIndicators,
                            matchPayments: false,
                        },
                    });
                },
            })}
            {renderIndicator({
                progress:
                    mutationProgressData.profile?.profileGroup
                        ?.sendRemindersProgress,
                showIndicatorState:
                    state.values.showMutationProgressIndicators.sendReminders,
                successMessage: "Sent Reminders",
                errorMessage: "Error Sending Reminders",
                loadingMessage: "Sending Reminders",
                onDismiss: () => {
                    dismissMutationProgressIndicator({
                        dismissSendRemindersIndicator: true,
                    });
                    dispatchState({
                        input: "showMutationProgressIndicators",
                        value: {
                            ...state.values.showMutationProgressIndicators,
                            sendReminders: false,
                        },
                    });
                },
            })}
            {renderIndicator({
                progress:
                    mutationProgressData.profile?.profileGroup
                        ?.sendDraftsProgress,
                showIndicatorState:
                    state.values.showMutationProgressIndicators.sendDrafts,
                successMessage: "Sent Drafts",
                errorMessage: "Error Sending Drafts",
                loadingMessage: "Sending Drafts",
                onDismiss: () => {
                    dismissMutationProgressIndicator({
                        dismissSendDraftIndicator: true,
                    });
                    dispatchState({
                        input: "showMutationProgressIndicators",
                        value: {
                            ...state.values.showMutationProgressIndicators,
                            sendDrafts: false,
                        },
                    });
                },
            })}
            {renderIndicator({
                progress:
                    mutationProgressData.profile?.profileGroup
                        ?.generateDraftsProgress,
                showIndicatorState:
                    state.values.showMutationProgressIndicators.generateDrafts,
                successMessage: "Generated Drafts",
                errorMessage: "Error Generating Drafts",
                loadingMessage: "Generating Drafts",
                onDismiss: () => {
                    dismissMutationProgressIndicator({
                        dismissGenerateDraftIndicator: true,
                    });
                    dispatchState({
                        input: "showMutationProgressIndicators",
                        value: {
                            ...state.values.showMutationProgressIndicators,
                            generateDrafts: false,
                        },
                    });
                },
            })}
        </VStack>
    );
});

export default React.memo(InvoiceMutationProgressIndicators);
