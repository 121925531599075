import React, { useState, useContext, createContext } from "react";
import type { FC, ReactNode } from "react";

import { RelayEnvironmentProvider } from "react-relay/hooks";
import type { IEnvironment } from "relay-runtime";

// This makes it possible to completely reset the cache of the relay client
// This is used when a user signs out
// Idea taken from: https://gist.github.com/hyochan/7ea10143fbba62c3205bf8d59e6461a8

interface Context {
    // current relay environment
    environment: IEnvironment;
    // discard the current relay environment and create a new one
    resetRelayEnvironment: () => void;
}

interface Props {
    children?: ReactNode;
    // factory function for generating Relay environments
    createRelayEnvironment: () => IEnvironment;

    // for testing
    environment?: IEnvironment;
}

const ResettableRelayContext = createContext<Context | null>(null);

export const RelayProvider: FC<Props> = ({
    children,
    createRelayEnvironment,
}) => {
    const [environment, setEnvironment] = useState(createRelayEnvironment());

    const resetRelayEnvironment = () => {
        setEnvironment(createRelayEnvironment());
    };

    return (
        <ResettableRelayContext.Provider
            value={{ environment, resetRelayEnvironment }}
        >
            <RelayEnvironmentProvider environment={environment}>
                {children}
            </RelayEnvironmentProvider>
        </ResettableRelayContext.Provider>
    );
};

export const useResettableRelay = (): Context => {
    const context = useContext(ResettableRelayContext);

    if (!context) {
        throw new Error(
            "useResettableRelay must be used within an RelayProvider",
        );
    }

    return context;
};
