/**
 * @generated SignedSource<<8be670ad3a95d05a212a065d6ca8cc07>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PayslipModelStatus = "NOT_VIEWED" | "PAID" | "READY_TO_PAY" | "%future added value";
export type EditPayslipInput = {
  adjustedReason?: string | null;
  amountAdjusted?: number | null;
  amountDue?: number | null;
  clientMutationId?: string | null;
  payslipId: string;
  status?: string | null;
};
export type EditPayslipMutation$variables = {
  input: EditPayslipInput;
};
export type EditPayslipMutation$data = {
  readonly editPayslip: {
    readonly errors: any | null;
    readonly payslip: {
      readonly adjustedReason: string | null;
      readonly amountAdjusted: number | null;
      readonly amountDue: number | null;
      readonly id: string;
      readonly status: PayslipModelStatus;
      readonly teacher: {
        readonly user: {
          readonly email: string;
          readonly firstName: string;
          readonly lastName: string;
        };
      } | null;
    } | null;
    readonly success: boolean | null;
  } | null;
};
export type EditPayslipMutation = {
  response: EditPayslipMutation$data;
  variables: EditPayslipMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amountDue",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amountAdjusted",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adjustedReason",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditPayslipMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditPayslipPayload",
        "kind": "LinkedField",
        "name": "editPayslip",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PayslipNode",
            "kind": "LinkedField",
            "name": "payslip",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileNode",
                "kind": "LinkedField",
                "name": "teacher",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DjangoUserNode",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditPayslipMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditPayslipPayload",
        "kind": "LinkedField",
        "name": "editPayslip",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PayslipNode",
            "kind": "LinkedField",
            "name": "payslip",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileNode",
                "kind": "LinkedField",
                "name": "teacher",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DjangoUserNode",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7ed28a1590d46aba5fe81b1d25cb5b81",
    "id": null,
    "metadata": {},
    "name": "EditPayslipMutation",
    "operationKind": "mutation",
    "text": "mutation EditPayslipMutation(\n  $input: EditPayslipInput!\n) {\n  editPayslip(input: $input) {\n    success\n    errors\n    payslip {\n      id\n      amountDue\n      status\n      amountAdjusted\n      adjustedReason\n      teacher {\n        user {\n          firstName\n          lastName\n          email\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "361ab4d317096f56c057f5a4465ffccb";

export default node;
