import React, {
    createContext,
    useContext,
    useReducer,
    useState,
    useCallback,
} from "react";
import type { ReactElement, Dispatch } from "react";

import { Platform } from "react-native";
import { useMutation, useQueryLoader } from "react-relay";
import type {
    PreloadedQuery,
    UseQueryLoaderLoadQueryOptions,
} from "react-relay";

import type {
    LogoutUserMutation,
    LogoutUserMutation$data,
} from "pianofunclub-shared/relay/graphql/auth/__generated__/LogoutUserMutation.graphql";
import type { RevokeTokenMutation } from "pianofunclub-shared/relay/graphql/auth/__generated__/RevokeTokenMutation.graphql";
import type {
    MeQuery,
    MeQuery$data,
    MeQuery$variables,
} from "pianofunclub-shared/relay/graphql/general/__generated__/MeQuery.graphql";

import { logout_user } from "../relay/graphql/auth/LogoutUser";
import { revoke_token } from "../relay/graphql/auth/RevokeToken";
import { me } from "../relay/graphql/general/Me";
import { getValueFor, remove, webGetValueFor, webRemove } from "../utils/store";
import { useResettableRelay } from "pianofunclub-shared/providers/RelayProvider";

// Ideas taken from: https://reactnavigation.org/docs/auth-flow/ and
// https://levelup.gitconnected.com/react-native-authentication-flow-the-simplest-and-most-efficient-way-3aa13e80af61

type AuthState = {
    accountType: string | null;
    isLoading: boolean;
    userToken: string | null;
};

export type AuthAction =
    | {
          accountType?: string | null;
          token: string | null;
          type: "ADD_TOKEN";
      }
    | { type: "REMOVE_TOKEN" };

export interface AuthContextType {
    authState: AuthState;
    dispatchAuthState: Dispatch<AuthAction>;
    loadMeQuery: (
        variables: MeQuery$variables,
        options?: UseQueryLoaderLoadQueryOptions | undefined,
    ) => void;
    logout: (onCompleted?: (() => void) | undefined) => Promise<void>;
    meQueryReference?: PreloadedQuery<MeQuery, Record<string, unknown>> | null;
    setUser: (value: MeQuery$data["me"] | null) => void;
    user: MeQuery$data["me"] | null;
}

interface Props {
    children?: ReactElement;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: Props): ReactElement => {
    // expose the current user (and query to check) to the rest of the app
    const [user, setUser] = useState<MeQuery$data["me"] | null>(null);
    const [meQueryReference, loadMeQuery] = useQueryLoader<MeQuery>(me);

    const { resetRelayEnvironment } = useResettableRelay();

    const [authState, dispatchAuthState] = useReducer(
        (prevState: AuthState, action: AuthAction): AuthState => {
            switch (action.type) {
                case "ADD_TOKEN":
                    return {
                        ...prevState,
                        userToken: action.token,
                        accountType: action.accountType ?? null,
                        isLoading: false,
                    };
                case "REMOVE_TOKEN":
                    return {
                        ...prevState,
                        userToken: null,
                        isLoading: false,
                    };
            }
        },
        {
            isLoading: true,
            userToken: null,
            accountType: null,
        },
    );

    const logoutCleanup = useCallback(async () => {
        const cleanup = async () => {
            // remove the tokens from secure local store
            if (Platform.OS !== "web") {
                remove("refresh_token");
                remove("token");
            } else {
                webRemove("refresh_token");
                webRemove("token");
            }
            resetRelayEnvironment();
        };

        await cleanup();
    }, [resetRelayEnvironment]);

    const commitLogoutUser = useMutation<LogoutUserMutation>(logout_user)[0];
    const commitRevokeToken = useMutation<RevokeTokenMutation>(revoke_token)[0];

    const logout = useCallback(
        async (onCompleted?: () => void) => {
            // instantly log the user out
            dispatchAuthState({ type: "REMOVE_TOKEN" });
            setUser(null);

            let refreshToken = null;
            let loginMethod = null;

            try {
                if (Platform.OS !== "web") {
                    refreshToken = await getValueFor("refresh_token");
                    loginMethod = await getValueFor("login_method");
                } else {
                    refreshToken = await webGetValueFor("refresh_token");
                    loginMethod = await webGetValueFor("login_method");
                }
                // eslint-disable-next-line no-empty
            } catch {}

            // first revoke the refresh token in the store (if it exists)
            // this adds a layer of security - stops someone saving the refresh token
            // and re-using to login
            if (refreshToken && loginMethod === "EMAIL") {
                const revokeTokenConfig = {
                    variables: {
                        input: {
                            refreshToken: refreshToken,
                        },
                    },
                };
                commitRevokeToken(revokeTokenConfig);
            }

            // now call logout on django back-end (this unauthenticates user and ends session)
            commitLogoutUser({
                variables: {
                    input: {},
                },
                onCompleted: (response: LogoutUserMutation$data) => {
                    if (response?.logoutUser?.success) {
                        // call cleanup functions
                        logoutCleanup();
                        resetRelayEnvironment();
                        onCompleted?.();
                    }
                },
            });
        },
        [
            commitLogoutUser,
            commitRevokeToken,
            logoutCleanup,
            resetRelayEnvironment,
        ],
    );

    return (
        // this component gives all child components access to the authentication state
        // and login/logout/register methods
        <AuthContext.Provider
            value={{
                authState,
                dispatchAuthState,
                logout,
                user,
                setUser,
                meQueryReference,
                loadMeQuery,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

// abstract the connection logic into a simple hook
export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }

    return context;
};
