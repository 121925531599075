import React from "react";
import type { ReactElement } from "react";

import type {
    CompositeNavigationProp,
    RouteProp,
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import type { StackNavigationProp } from "@react-navigation/stack";

import TeacherTimetableHubScreen, {
    screenOptions as teacherTimetableHubScreenOptions,
} from "../screens/registers/TeacherTimetableHubScreen";

import type { HubTabNavigatorProps } from "./HubNavigator";
import defaultNavOptions from "./utils/DefaultNavigationOptions";

export type TeacherTimetableStackNavigatorParamList = {
    TeacherTimetableHub?: {
        block?: number;
        school?: string;
        searchTerm?: string;
        startingYear?: number;
        timetableDate?: string;
        timetableView?: "DAY" | "WEEK";
    };
};

type NavigatorProps<T extends keyof TeacherTimetableStackNavigatorParamList> =
    StackNavigationProp<TeacherTimetableStackNavigatorParamList, T>;

export type TeacherTimetableStackNavigatorProps<
    T extends keyof TeacherTimetableStackNavigatorParamList,
> = CompositeNavigationProp<
    NavigatorProps<T>,
    HubTabNavigatorProps<"RegistersHubTab">
>;

export type TeacherTimetableStackRouteProps<
    T extends keyof TeacherTimetableStackNavigatorParamList,
> = RouteProp<TeacherTimetableStackNavigatorParamList, T>;

const TeacherTimetableStackNavigator =
    createStackNavigator<TeacherTimetableStackNavigatorParamList>();

const TeacherTimetableNavigator = (): ReactElement => {
    return (
        <TeacherTimetableStackNavigator.Navigator
            screenOptions={defaultNavOptions}
        >
            <TeacherTimetableStackNavigator.Screen
                component={TeacherTimetableHubScreen}
                name="TeacherTimetableHub"
                options={teacherTimetableHubScreenOptions}
            />
        </TeacherTimetableStackNavigator.Navigator>
    );
};

export default TeacherTimetableNavigator;
