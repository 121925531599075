/**
 * @generated SignedSource<<2c135fb6cd8c25ccd9799eace9d0a877>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EditBlacklistedDateInput = {
  blacklistedDateId: string;
  clientMutationId?: string | null;
  date?: string | null;
  reason?: string | null;
  schoolId?: string | null;
  teacherIds?: ReadonlyArray<string | null> | null;
};
export type EditBlacklistedDateMutation$variables = {
  input: EditBlacklistedDateInput;
};
export type EditBlacklistedDateMutation$data = {
  readonly editBlacklistedDate: {
    readonly blacklistedDate: {
      readonly date: string;
      readonly id: string;
      readonly reason: string | null;
      readonly teachers: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly user: {
              readonly firstName: string;
              readonly id: string;
              readonly lastName: string;
            };
          } | null;
        } | null>;
      };
    } | null;
    readonly errors: any | null;
    readonly numberOfAffectedExistingLessons: number | null;
    readonly success: boolean | null;
  } | null;
};
export type EditBlacklistedDateMutation = {
  response: EditBlacklistedDateMutation$data;
  variables: EditBlacklistedDateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EditBlacklistedDatePayload",
    "kind": "LinkedField",
    "name": "editBlacklistedDate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "numberOfAffectedExistingLessons",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BlacklistedDateNode",
        "kind": "LinkedField",
        "name": "blacklistedDate",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reason",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNodeConnection",
            "kind": "LinkedField",
            "name": "teachers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProfileNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DjangoUserNode",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditBlacklistedDateMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditBlacklistedDateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "0d8ac2acc9cc8a51fe8b3e246d1aa8a7",
    "id": null,
    "metadata": {},
    "name": "EditBlacklistedDateMutation",
    "operationKind": "mutation",
    "text": "mutation EditBlacklistedDateMutation(\n  $input: EditBlacklistedDateInput!\n) {\n  editBlacklistedDate(input: $input) {\n    success\n    errors\n    numberOfAffectedExistingLessons\n    blacklistedDate {\n      id\n      date\n      reason\n      teachers {\n        edges {\n          node {\n            id\n            user {\n              id\n              firstName\n              lastName\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f27a1ad6b2f9267a98e6316468e1a47d";

export default node;
