import { graphql } from "react-relay";

export const generate_estimated_payout_total = graphql`
    mutation GenerateEstimatedPayoutTotalMutation(
        $input: GenerateEstimatedPayoutTotalInput!
    ) {
        generateEstimatedPayoutTotal(input: $input) {
            success
            errors
            profileGroup {
                id
                estimatedTotalAmountUnpaid
                generatedEstimatedPayoutTotalFromDate
                generatedEstimatedPayoutTotalToDate
            }
        }
    }
`;
