/**
 * @generated SignedSource<<6258608726ae68ab61e8e65d2c004178>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LessonModelStatus = "BLANK" | "BREAK" | "LEFT_SCHOOL" | "MISSED" | "NO_LESSON" | "OTHER_TEACHER" | "PRESENT" | "PRESENT_DOUBLE" | "PUPIL_ABSENT" | "STOPPED_LESSONS" | "TEACHER_ABSENT" | "%future added value";
export type CancelAndRescheduleLessonsOnBlacklistedDateInput = {
  blacklistedDateId: string;
  clientMutationId?: string | null;
};
export type CancelAndRescheduleLessonsOnBlacklistedDateMutation$variables = {
  input: CancelAndRescheduleLessonsOnBlacklistedDateInput;
};
export type CancelAndRescheduleLessonsOnBlacklistedDateMutation$data = {
  readonly cancelAndRescheduleLessonsOnBlacklistedDate: {
    readonly cancelledLessons: ReadonlyArray<{
      readonly id: string;
      readonly status: LessonModelStatus;
    } | null> | null;
    readonly errors: any | null;
    readonly success: boolean | null;
  } | null;
};
export type CancelAndRescheduleLessonsOnBlacklistedDateMutation = {
  response: CancelAndRescheduleLessonsOnBlacklistedDateMutation$data;
  variables: CancelAndRescheduleLessonsOnBlacklistedDateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CancelAndRescheduleLessonsOnBlacklistedDatePayload",
    "kind": "LinkedField",
    "name": "cancelAndRescheduleLessonsOnBlacklistedDate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LessonNode",
        "kind": "LinkedField",
        "name": "cancelledLessons",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CancelAndRescheduleLessonsOnBlacklistedDateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CancelAndRescheduleLessonsOnBlacklistedDateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "66f943fb2913f32ab3a5fc6631627512",
    "id": null,
    "metadata": {},
    "name": "CancelAndRescheduleLessonsOnBlacklistedDateMutation",
    "operationKind": "mutation",
    "text": "mutation CancelAndRescheduleLessonsOnBlacklistedDateMutation(\n  $input: CancelAndRescheduleLessonsOnBlacklistedDateInput!\n) {\n  cancelAndRescheduleLessonsOnBlacklistedDate(input: $input) {\n    success\n    errors\n    cancelledLessons {\n      id\n      status\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7535124400dda9c578ad9a3c34e23f34";

export default node;
