import { graphql } from "react-relay";

export const load_teachers_for_modal = graphql`
    query LoadTeachersForModalQuery(
        $orderBy: String
        $searchTerm: String
        $status: String
        $startingYear: Int!
        $block: Int!
        $school: String
    ) {
        profiles(
            orderBy: $orderBy
            searchTerm: $searchTerm
            status: $status
            accountType: "TEACHER"
            school: $school
        ) {
            edges {
                node {
                    id
                    accountNumber
                    user {
                        id
                        email
                        firstName
                        lastName
                    }
                    schoolYear
                    school {
                        id
                        name
                    }
                    totalLessonsMarked(
                        startingYear: $startingYear
                        block: $block
                    )
                    totalLessons(startingYear: $startingYear, block: $block)
                }
            }
        }
    }
`;
