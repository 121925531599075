import { graphql } from "react-relay";

export const load_school = graphql`
    query LoadSchoolQuery($schoolId: ID!) {
        school(id: $schoolId) {
            id
            name
            archived
            headTeacher {
                id
                phoneNumber
                user {
                    id
                    firstName
                    lastName
                    email
                }
            }
            mainOfficeContact {
                id
                phoneNumber
                addressFirstLine
                addressSecondLine
                city
                postcode
                user {
                    id
                    firstName
                    lastName
                    email
                }
            }
            otherContacts {
                edges {
                    node {
                        id
                        phoneNumber
                        label
                        user {
                            id
                            firstName
                            lastName
                            email
                        }
                    }
                }
            }
        }
    }
`;
