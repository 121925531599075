import { graphql } from "react-relay";

export const send_bulk_communication = graphql`
    mutation SendBulkCommunicationMutation(
        $input: SendBulkCommunicationInput!
    ) {
        sendBulkCommunication(input: $input) {
            success
            errors
            numberSent
            totalToSend
            erroredProfile {
                user {
                    firstName
                    lastName
                    email
                }
            }
            profile {
                profileGroup {
                    sendBulkCommunicationProgress
                }
            }
        }
    }
`;
