import React, { useMemo } from "react";
import type { ReactElement, ComponentProps } from "react";

import { HStack, Text, Center, Spinner, ChevronDownIcon } from "native-base";
import type { Icon } from "native-base";

import {
    TickIcon,
    ArchivedIcon,
    WaitingListIcon,
    NewEnrollmentIcon,
} from "pianofunclub-shared/components/Other/Icons";

import { titleCaseConverter } from "pianofunclub-shared/utils/converters";

type HStackProps = ComponentProps<typeof HStack>;
type TextProps = ComponentProps<typeof Text>;
type IconProps = ComponentProps<typeof Icon>;

interface Props extends HStackProps {
    iconProps?: IconProps;
    isLoading?: boolean;
    isTransparent?: boolean;
    showEditArrow?: boolean;
    status?: string;
    textProps?: TextProps;
}

const AccountStatus = (props: Props): ReactElement | null => {
    const {
        iconProps,
        isLoading,
        isTransparent,
        showEditArrow,
        status,
        textProps,
    } = props;

    const { color, endColor, icon, startColor, textColor } = useMemo(() => {
        if (!isTransparent) {
            switch (status) {
                case "ACTIVE":
                    return {
                        startColor: "primary.400",
                        endColor: "primary.600",
                        textColor: "surface.50",
                        icon: (
                            <TickIcon
                                color="surface.50"
                                size="6"
                                {...iconProps}
                            />
                        ),
                    };
                case "WAITING_LIST":
                    return {
                        startColor: "surface.400",
                        endColor: "surface.600",
                        textColor: "surface.50",
                        icon: (
                            <WaitingListIcon
                                color="surface.50"
                                size="6"
                                {...iconProps}
                            />
                        ),
                    };
                case "NEW_ENROLLMENT":
                    return {
                        startColor: "primary.100",
                        endColor: "primary.200",
                        textColor: "surface.50",
                        icon: (
                            <NewEnrollmentIcon
                                color="surface.50"
                                size="6"
                                {...iconProps}
                            />
                        ),
                    };
                default:
                    return {
                        startColor: "surface.600",
                        endColor: "surface.800",
                        textColor: "surface.50",
                        icon: (
                            <ArchivedIcon
                                color="surface.50"
                                size="5"
                                {...iconProps}
                            />
                        ),
                    };
            }
        } else {
            switch (status) {
                case "ACTIVE":
                    return {
                        color: "transparent",
                        textColor: "primary.600",
                        icon: (
                            <TickIcon
                                color="primary.600"
                                size="6"
                                {...iconProps}
                            />
                        ),
                    };
                case "WAITING_LIST":
                    return {
                        color: "transparent",
                        textColor: "surface.800",
                        icon: (
                            <WaitingListIcon
                                color="surface.800"
                                size="6"
                                {...iconProps}
                            />
                        ),
                    };
                case "NEW_ENROLLMENT":
                    return {
                        color: "transparent",
                        textColor: "primary.400",
                        icon: (
                            <NewEnrollmentIcon
                                color="primary.400"
                                size="6"
                                {...iconProps}
                            />
                        ),
                    };
                default:
                    return {
                        color: "transparent",
                        textColor: "surface.800",
                        icon: (
                            <ArchivedIcon
                                color="surface.800"
                                size="6"
                                {...iconProps}
                            />
                        ),
                    };
            }
        }
    }, [iconProps, isTransparent, status]);

    if (status) {
        return (
            <HStack
                bg={
                    startColor && endColor
                        ? {
                              linearGradient: {
                                  colors: [startColor, endColor],
                                  start: [0.25, 0],
                                  end: [0.75, 1],
                              },
                          }
                        : color
                }
                borderRadius="2xl"
                pl="3"
                pr="4"
                py="2"
                shadow={!isTransparent && showEditArrow ? "1" : undefined}
                space="2"
                {...props}
            >
                <Center size="6">
                    {!isLoading ? (
                        icon
                    ) : (
                        <Spinner
                            color={isTransparent ? textColor : "surface.50"}
                        />
                    )}
                </Center>
                <Text color={textColor} fontSize="md" {...textProps}>
                    {titleCaseConverter(status)}
                </Text>
                {showEditArrow ? (
                    <ChevronDownIcon
                        color={isTransparent ? "surface.500" : "surface.100"}
                    />
                ) : null}
            </HStack>
        );
    } else {
        return null;
    }
};

export default AccountStatus;
