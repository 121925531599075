/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import type { ReactElement, ComponentProps } from "react";

import {
    Alert,
    VStack,
    HStack,
    IconButton,
    CloseIcon,
    Text,
    WarningIcon,
} from "native-base";

type AlertProps = ComponentProps<typeof Alert>;

interface Props {
    alertProps?: AlertProps;
    description?: string;
    id: string;
    isClosable?: boolean;
    status?: "info" | "success" | "error";
    title: string;
    toast: {
        close: (id: any) => void;
        closeAll: () => void;
        isActive: (id: any) => boolean;
        show: (props: any) => any;
    };
}

const ToastAlert = (props: Props): ReactElement => {
    const {
        alertProps,
        description,
        id,
        isClosable = true,
        status,
        title,
        toast,
    } = props;

    return (
        <Alert
            alignSelf="center"
            borderRadius="2xl"
            flexDirection="row"
            maxWidth="100%"
            status={status ?? "info"}
            {...alertProps}
        >
            <VStack alignItems="center" flexShrink={1} space={1}>
                <HStack
                    alignItems="center"
                    flexShrink={1}
                    justifyContent="space-between"
                >
                    <HStack alignItems="center" flexShrink={1} space={2}>
                        {status !== "error" ? (
                            <Alert.Icon size="lg" />
                        ) : (
                            <WarningIcon color="red.500" size="lg" />
                        )}
                        <Text
                            color={
                                status === "info"
                                    ? "surface.900"
                                    : status === "success"
                                      ? "emerald.800"
                                      : "red.800"
                            }
                            flexShrink={1}
                            flexWrap="wrap"
                            fontSize="lg"
                            mt="0.5"
                        >
                            {title}
                        </Text>
                    </HStack>
                    {isClosable ? (
                        <IconButton
                            _icon={{
                                color: "lightText",
                            }}
                            icon={
                                <CloseIcon
                                    color={
                                        status === "info"
                                            ? "surface.900"
                                            : status === "success"
                                              ? "emerald.800"
                                              : "red.800"
                                    }
                                    size="3"
                                />
                            }
                            onPress={() => toast.close(id)}
                            variant="unstyled"
                        />
                    ) : null}
                </HStack>
                {description ? (
                    <Text
                        color={
                            status === "info"
                                ? "surface.900"
                                : status === "success"
                                  ? "emerald.800"
                                  : "red.800"
                        }
                        fontWeight="medium"
                        px="6"
                        textAlign="center"
                    >
                        {description}
                    </Text>
                ) : null}
            </VStack>
        </Alert>
    );
};

export default ToastAlert;
