import { graphql } from "react-relay";

export const delete_blacklisted_dates = graphql`
    mutation DeleteBlacklistedDatesMutation(
        $connections: [ID!]!
        $input: DeleteBlacklistedDatesInput!
    ) {
        deleteBlacklistedDates(input: $input) {
            success
            errors
            deletedBlacklistedDateIds @deleteEdge(connections: $connections)
        }
    }
`;
