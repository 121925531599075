import React, { useRef, useMemo, useCallback } from "react";
import type { ReactElement, ComponentProps, Dispatch } from "react";

import { format } from "date-fns";
import { VStack, Text, HStack, Box, Button } from "native-base";

import type {
    ReducerValues as YearTermDatesReducerValues,
    ReducerTypes as YearTermDatesReducerTypes,
    TermInputType,
} from "../Schools/YearTermDates";
import TextInput from "pianofunclub-shared/components/Inputs/TextInput";
import { RestartIcon } from "pianofunclub-shared/components/Other/Icons";

import type { Action } from "pianofunclub-shared/utils/reducers";

type VStackProps = ComponentProps<typeof VStack>;

interface Props extends VStackProps {
    defaultTermDates:
        | {
              readonly firstDayOfSecondHalfOfTerm: string | null;
              readonly firstDayOfTerm: string | null;
              readonly id: string;
              readonly lastDayOfFirstHalfOfTerm: string | null;
              readonly lastDayOfTerm: string | null;
              readonly term: number | null;
          }
        | null
        | undefined;
    dispatchState: Dispatch<
        Action<YearTermDatesReducerValues, YearTermDatesReducerTypes>
    >;
    inputState: TermInputType;
    isDefaultTermDateScreen?: boolean;
    label: string;
    removeTermDates: (
        term: string,
        schoolTermDatesId: string | undefined,
    ) => void;
    schoolTermDates:
        | {
              readonly firstDayOfSecondHalfOfTerm: string | null;
              readonly firstDayOfTerm: string | null;
              readonly id: string;
              readonly lastDayOfFirstHalfOfTerm: string | null;
              readonly lastDayOfTerm: string | null;
              readonly term: number | null;
          }
        | null
        | undefined;
    term: string;
}

const TermDates = (props: Props): ReactElement => {
    const {
        defaultTermDates,
        dispatchState,
        inputState,
        isDefaultTermDateScreen,
        label,
        removeTermDates,
        schoolTermDates,
        term,
    } = props;

    const firstDayOfTermInputRef = useRef<{
        setValue: (text: string, isValid: boolean) => void;
    }>(null);
    const lastDayOfFirstHalfOfTermInputRef = useRef<{
        setValue: (text: string, isValid: boolean) => void;
    }>(null);
    const firstDayOfSecondHalfOfTermInputRef = useRef<{
        setValue: (text: string, isValid: boolean) => void;
    }>(null);
    const lastDayOfTermInputRef = useRef<{
        setValue: (text: string, isValid: boolean) => void;
    }>(null);

    const isDefaultTermDatesForSchool = useMemo(() => {
        return (
            !isDefaultTermDateScreen &&
            inputState.firstDayOfTerm === schoolTermDates?.firstDayOfTerm &&
            inputState.lastDayOfFirstHalfOfTerm ===
                schoolTermDates?.lastDayOfFirstHalfOfTerm &&
            inputState.firstDayOfSecondHalfOfTerm ===
                schoolTermDates?.firstDayOfSecondHalfOfTerm &&
            inputState.lastDayOfTerm === schoolTermDates?.lastDayOfTerm
        );
    }, [
        isDefaultTermDateScreen,
        inputState.firstDayOfTerm,
        inputState.lastDayOfFirstHalfOfTerm,
        inputState.firstDayOfSecondHalfOfTerm,
        inputState.lastDayOfTerm,
        schoolTermDates?.firstDayOfTerm,
        schoolTermDates?.lastDayOfFirstHalfOfTerm,
        schoolTermDates?.firstDayOfSecondHalfOfTerm,
        schoolTermDates?.lastDayOfTerm,
    ]);

    const removeTermDatesHandler = useCallback(() => {
        // reset inputs
        firstDayOfTermInputRef.current?.setValue(
            defaultTermDates?.firstDayOfTerm
                ? format(
                      new Date(defaultTermDates?.firstDayOfTerm),
                      "EEE do MMM yyyy",
                  )
                : "",
            true,
        );
        lastDayOfFirstHalfOfTermInputRef.current?.setValue(
            defaultTermDates?.lastDayOfFirstHalfOfTerm
                ? format(
                      new Date(defaultTermDates?.lastDayOfFirstHalfOfTerm),
                      "EEE do MMM yyyy",
                  )
                : "",
            true,
        );
        firstDayOfSecondHalfOfTermInputRef.current?.setValue(
            defaultTermDates?.firstDayOfSecondHalfOfTerm
                ? format(
                      new Date(defaultTermDates?.firstDayOfSecondHalfOfTerm),
                      "EEE do MMM yyyy",
                  )
                : "",
            true,
        );
        lastDayOfTermInputRef.current?.setValue(
            defaultTermDates?.lastDayOfTerm
                ? format(
                      new Date(defaultTermDates?.lastDayOfTerm),
                      "EEE do MMM yyyy",
                  )
                : "",
            true,
        );

        removeTermDates(term, schoolTermDates?.id);
    }, [
        defaultTermDates?.firstDayOfSecondHalfOfTerm,
        defaultTermDates?.firstDayOfTerm,
        defaultTermDates?.lastDayOfFirstHalfOfTerm,
        defaultTermDates?.lastDayOfTerm,
        removeTermDates,
        schoolTermDates?.id,
        term,
    ]);

    return (
        <VStack space="2" width="100%">
            <HStack space="4">
                <Text fontSize="xl" ml="1">
                    {label}
                </Text>
                {!isDefaultTermDateScreen ? (
                    <Button
                        _disabled={{ opacity: 1 }}
                        borderRadius="2xl"
                        colorScheme={
                            isDefaultTermDatesForSchool ? "surface" : "info"
                        }
                        isDisabled={Boolean(isDefaultTermDatesForSchool)}
                        leftIcon={
                            !isDefaultTermDatesForSchool ? (
                                <RestartIcon />
                            ) : undefined
                        }
                        onPress={removeTermDatesHandler}
                        py="1.5"
                        variant="outline">
                        {isDefaultTermDatesForSchool
                            ? "Default"
                            : "Reset to default"}
                    </Button>
                ) : null}
            </HStack>
            <HStack space="8" width="100%">
                <Box flex={1} maxWidth="250px">
                    <TextInput
                        ref={firstDayOfTermInputRef}
                        centerLabel
                        color={
                            isDefaultTermDatesForSchool
                                ? "muted.400"
                                : undefined
                        }
                        fontFamily="Poppins-Regular"
                        id="firstDayOfTerm"
                        initiallyValid
                        initialValue={
                            schoolTermDates?.firstDayOfTerm
                                ? format(
                                      new Date(schoolTermDates.firstDayOfTerm),
                                      "EEE do MMM yyyy",
                                  )
                                : defaultTermDates?.firstDayOfTerm
                                  ? format(
                                        new Date(
                                            defaultTermDates.firstDayOfTerm,
                                        ),
                                        "EEE do MMM yyyy",
                                    )
                                  : ""
                        }
                        label="First Day of Term"
                        labelStyle={{
                            color: "surface.700",
                            fontSize: "15",
                        }}
                        onPress={() => {
                            dispatchState({
                                input: "termDatePickerInputRef",
                                value: firstDayOfTermInputRef,
                            });
                            dispatchState({
                                input: "termDatePickerDate",
                                value:
                                    inputState.firstDayOfTerm ??
                                    schoolTermDates?.firstDayOfTerm ??
                                    defaultTermDates?.firstDayOfTerm ??
                                    undefined,
                            });
                            dispatchState({
                                input: "termDatePickerInputId",
                                value: `${term}-firstDayOfTerm`,
                            });
                            dispatchState({
                                input: "termDatePickerIsOpen",
                                value: true,
                            });
                        }}
                        size="lg"
                        textAlign="center"
                        updateValueOnInitialValueChange
                    />
                </Box>
                <Box flex={1} maxWidth="250px">
                    <TextInput
                        ref={lastDayOfFirstHalfOfTermInputRef}
                        centerLabel
                        color={
                            isDefaultTermDatesForSchool
                                ? "muted.400"
                                : undefined
                        }
                        fontFamily="Poppins-Regular"
                        id="lastDayOfFirstHalfOfTerm"
                        initiallyValid
                        initialValue={
                            schoolTermDates?.lastDayOfFirstHalfOfTerm
                                ? format(
                                      new Date(
                                          schoolTermDates.lastDayOfFirstHalfOfTerm,
                                      ),
                                      "EEE do MMM yyyy",
                                  )
                                : defaultTermDates?.lastDayOfFirstHalfOfTerm
                                  ? format(
                                        new Date(
                                            defaultTermDates.lastDayOfFirstHalfOfTerm,
                                        ),
                                        "EEE do MMM yyyy",
                                    )
                                  : ""
                        }
                        label="Last Day of 1st Half Term"
                        labelStyle={{
                            color: "surface.700",
                            fontSize: "15",
                        }}
                        onPress={() => {
                            dispatchState({
                                input: "termDatePickerInputRef",
                                value: lastDayOfFirstHalfOfTermInputRef,
                            });
                            dispatchState({
                                input: "termDatePickerDate",
                                value:
                                    inputState.lastDayOfFirstHalfOfTerm ??
                                    schoolTermDates?.lastDayOfFirstHalfOfTerm ??
                                    defaultTermDates?.lastDayOfFirstHalfOfTerm ??
                                    undefined,
                            });
                            dispatchState({
                                input: "termDatePickerInputId",
                                value: `${term}-lastDayOfFirstHalfOfTerm`,
                            });
                            dispatchState({
                                input: "termDatePickerIsOpen",
                                value: true,
                            });
                        }}
                        size="lg"
                        textAlign="center"
                        updateValueOnInitialValueChange
                    />
                </Box>
                <Box flex={1} maxWidth="250px">
                    <TextInput
                        ref={firstDayOfSecondHalfOfTermInputRef}
                        centerLabel
                        color={
                            isDefaultTermDatesForSchool
                                ? "muted.400"
                                : undefined
                        }
                        fontFamily="Poppins-Regular"
                        id="firstDayOfSecondHalfOfTerm"
                        initiallyValid
                        initialValue={
                            schoolTermDates?.firstDayOfSecondHalfOfTerm
                                ? format(
                                      new Date(
                                          schoolTermDates.firstDayOfSecondHalfOfTerm,
                                      ),
                                      "EEE do MMM yyyy",
                                  )
                                : defaultTermDates?.firstDayOfSecondHalfOfTerm
                                  ? format(
                                        new Date(
                                            defaultTermDates.firstDayOfSecondHalfOfTerm,
                                        ),
                                        "EEE do MMM yyyy",
                                    )
                                  : ""
                        }
                        label="First Day of 2nd Half Term"
                        labelStyle={{
                            color: "surface.700",
                            fontSize: "15",
                        }}
                        onPress={() => {
                            dispatchState({
                                input: "termDatePickerInputRef",
                                value: firstDayOfSecondHalfOfTermInputRef,
                            });
                            dispatchState({
                                input: "termDatePickerDate",
                                value:
                                    inputState.firstDayOfSecondHalfOfTerm ??
                                    schoolTermDates?.firstDayOfSecondHalfOfTerm ??
                                    defaultTermDates?.firstDayOfSecondHalfOfTerm ??
                                    undefined,
                            });
                            dispatchState({
                                input: "termDatePickerInputId",
                                value: `${term}-firstDayOfSecondHalfOfTerm`,
                            });
                            dispatchState({
                                input: "termDatePickerIsOpen",
                                value: true,
                            });
                        }}
                        size="lg"
                        textAlign="center"
                        updateValueOnInitialValueChange
                    />
                </Box>
                <Box flex={1} maxWidth="250px">
                    <TextInput
                        ref={lastDayOfTermInputRef}
                        centerLabel
                        color={
                            isDefaultTermDatesForSchool
                                ? "muted.400"
                                : undefined
                        }
                        fontFamily="Poppins-Regular"
                        id="lastDayOfTerm"
                        initiallyValid
                        initialValue={
                            schoolTermDates?.lastDayOfTerm
                                ? format(
                                      new Date(schoolTermDates.lastDayOfTerm),
                                      "EEE do MMM yyyy",
                                  )
                                : defaultTermDates?.lastDayOfTerm
                                  ? format(
                                        new Date(
                                            defaultTermDates.lastDayOfTerm,
                                        ),
                                        "EEE do MMM yyyy",
                                    )
                                  : ""
                        }
                        label="Last Day of Term"
                        labelStyle={{
                            color: "surface.700",
                            fontSize: "15",
                        }}
                        onPress={() => {
                            dispatchState({
                                input: "termDatePickerInputRef",
                                value: lastDayOfTermInputRef,
                            });
                            dispatchState({
                                input: "termDatePickerDate",
                                value:
                                    inputState.lastDayOfTerm ??
                                    schoolTermDates?.lastDayOfTerm ??
                                    defaultTermDates?.lastDayOfTerm ??
                                    undefined,
                            });
                            dispatchState({
                                input: "termDatePickerInputId",
                                value: `${term}-lastDayOfTerm`,
                            });
                            dispatchState({
                                input: "termDatePickerIsOpen",
                                value: true,
                            });
                        }}
                        size="lg"
                        textAlign="center"
                        updateValueOnInitialValueChange
                    />
                </Box>
            </HStack>
        </VStack>
    );
};

TermDates.displayName = "TermDates";

export default TermDates;
