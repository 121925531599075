import React from "react";
import type { ReactElement, ReactNode, ComponentProps } from "react";

import LottieView from "lottie-react-native";
import { Center, Text } from "native-base";
import { Platform } from "react-native";

type CenterProps = ComponentProps<typeof Center>;
type TextProps = ComponentProps<typeof Text>;

interface Props extends CenterProps {
    children?: ReactNode;
    textProps?: TextProps;
}

const LoadingBlobs = (props: Props): ReactElement => {
    return (
        <Center
            bg="surface.100"
            borderBottomLeftRadius={Platform.OS !== "web" ? "32" : undefined}
            flex={1}
            flexGrow={1}
            overflow="hidden"
            {...props}
        >
            <Center height="39" width="100">
                <LottieView
                    autoPlay
                    cacheStrategy="strong"
                    loop
                    resizeMode="contain"
                    source={require("../../assets/animations/LoadingBlobs.json")}
                />
            </Center>
            {props.children ? (
                <Text
                    fontSize="lg"
                    fontWeight="400"
                    mt="3"
                    textAlign="center"
                    {...props.textProps}
                >
                    {props.children}
                </Text>
            ) : null}
        </Center>
    );
};

export default LoadingBlobs;
