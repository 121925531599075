import { graphql } from "react-relay";

export const remove_parent_relationship = graphql`
    mutation RemoveParentRelationshipMutation(
        $input: RemoveParentRelationshipInput!
        $connections: [ID!]!
    ) {
        removeParentRelationship(input: $input) {
            success
            errors
            profile {
                id @deleteEdge(connections: $connections)
                primaryParent {
                    id
                }
            }
        }
    }
`;
