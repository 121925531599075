import { graphql } from "react-relay";

export const reschedule_lesson = graphql`
    mutation RescheduleLessonMutation($input: RescheduleLessonInput!) {
        rescheduleLesson(input: $input) {
            success
            errors
            lesson {
                id
                rearrangedTimestamp
                lessonDuration
            }
        }
    }
`;
