import React from "react";
import type { ReactElement } from "react";

import type {
    CompositeNavigationProp,
    RouteProp,
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import type { StackNavigationProp } from "@react-navigation/stack";

import TeacherRegistersHubScreen, {
    screenOptions as teacherRegistersHubScreenOptions,
} from "../screens/registers/TeacherRegistersHubScreen";

import type { HubTabNavigatorProps } from "./HubNavigator";
import defaultNavOptions from "./utils/DefaultNavigationOptions";

export type TeacherRegistersStackNavigatorParamList = {
    TeacherRegistersHub: {
        block?: number;
        dayIndex?: number;
        startingYear?: number;
    };
};

type NavigatorProps<T extends keyof TeacherRegistersStackNavigatorParamList> =
    StackNavigationProp<TeacherRegistersStackNavigatorParamList, T>;

export type TeacherRegistersStackNavigatorProps<
    T extends keyof TeacherRegistersStackNavigatorParamList,
> = CompositeNavigationProp<
    NavigatorProps<T>,
    HubTabNavigatorProps<"RegistersHubTab">
>;

export type TeacherRegistersStackRouteProps<
    T extends keyof TeacherRegistersStackNavigatorParamList,
> = RouteProp<TeacherRegistersStackNavigatorParamList, T>;

const TeacherRegistersStackNavigator =
    createStackNavigator<TeacherRegistersStackNavigatorParamList>();

const TeacherRegistersNavigator = (): ReactElement => {
    return (
        <TeacherRegistersStackNavigator.Navigator
            screenOptions={defaultNavOptions}
        >
            <TeacherRegistersStackNavigator.Screen
                component={TeacherRegistersHubScreen}
                name="TeacherRegistersHub"
                options={teacherRegistersHubScreenOptions}
            />
        </TeacherRegistersStackNavigator.Navigator>
    );
};

export default TeacherRegistersNavigator;
