import React, { useMemo, useRef } from "react";
import type { ReactElement, Dispatch } from "react";

import { Box, ScrollView, HStack, Button } from "native-base";
import type { ScrollView as RNScrollView } from "react-native";
import type { PreloadedQuery } from "react-relay";

import type {
    NavigationProps as DefaultTermDatesScreenNavigationProps,
    ReducerValues as DefaultTermDatesScreenReducerValues,
    ReducerTypes as DefaultTermDatesScreenReducerTypes,
} from "../../screens/schools/DefaultTermDatesScreen";
import type {
    NavigationProps as SchoolScreenNavigationProps,
    ReducerValues as SchoolScreenReducerValues,
    ReducerTypes as SchoolScreenReducerTypes,
} from "../../screens/schools/SchoolScreen";
import BlacklistedDates from "../Schools/BlacklistedDates";
import YearTermDates from "../Schools/YearTermDates";
import Select from "pianofunclub-shared/components/NativeBaseExtended/Select";
import { RestartIcon } from "pianofunclub-shared/components/Other/Icons";

import type { LoadBlacklistedDatesQuery } from "pianofunclub-shared/relay/graphql/schools/__generated__/LoadBlacklistedDatesQuery.graphql";
import type { LoadTermDatesQuery } from "pianofunclub-shared/relay/graphql/schools/__generated__/LoadTermDatesQuery.graphql";

import type { State, Action } from "pianofunclub-shared/utils/reducers";

interface Props {
    dispatchScreenState: Dispatch<
        Action<
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            any,
            SchoolScreenReducerTypes & DefaultTermDatesScreenReducerTypes
        >
    >;
    loadBlacklistedDatesQueryReference:
        | PreloadedQuery<LoadBlacklistedDatesQuery, Record<string, unknown>>
        | undefined
        | null;
    loadTermDatesQueryReference:
        | PreloadedQuery<LoadTermDatesQuery, Record<string, unknown>>
        | undefined
        | null;
    navigation: SchoolScreenNavigationProps &
        DefaultTermDatesScreenNavigationProps;
    refreshHandler: () => void;
    schoolId?: string;
    schoolName?: string;
    screenState: State<
        SchoolScreenReducerValues | DefaultTermDatesScreenReducerValues
    >;
    sideBarWidth: number;
    startingYears: {
        label: string;
        value: number;
    }[];
}

const SchoolDates = (props: Props): ReactElement => {
    const {
        dispatchScreenState,
        loadBlacklistedDatesQueryReference,
        loadTermDatesQueryReference,
        navigation,
        refreshHandler,
        schoolId,
        schoolName,
        screenState,
        sideBarWidth,
        startingYears,
    } = props;

    const scrollViewRef = useRef<RNScrollView>(null);

    const termDatesInputKey = useRef(0);

    const renderHeader = useMemo(() => {
        return (
            <HStack mb="8" space="4">
                <Select
                    borderRadius="2xl"
                    fontSize="md"
                    onValueChange={(itemValue) => {
                        dispatchScreenState({
                            input: "startingYear",
                            value: parseInt(itemValue),
                        });
                        navigation.setParams({
                            startingYear: parseInt(itemValue),
                        });
                    }}
                    placeholder="Select year"
                    selectedValue={String(screenState.values.startingYear)}
                    width="40">
                    {startingYears.map((item) => {
                        return (
                            <Select.Item
                                key={item.value}
                                actionSheetLabel={item.label}
                                value={String(item.value)}
                            />
                        );
                    })}
                </Select>
                <Button
                    bg="surface.400"
                    colorScheme="surface"
                    leftIcon={<RestartIcon size="5" />}
                    onPress={() => {
                        if (screenState.values.termDatesHaveBeenModified) {
                            dispatchScreenState({
                                input: "leaveAlertAction",
                                value: () => refreshHandler(),
                            });
                        } else {
                            refreshHandler();
                        }
                    }}
                    p="3"
                />
            </HStack>
        );
    }, [
        screenState.values.startingYear,
        screenState.values.termDatesHaveBeenModified,
        startingYears,
        dispatchScreenState,
        navigation,
        refreshHandler,
    ]);

    return (
        <ScrollView
            ref={scrollViewRef}
            contentContainerStyle={{
                paddingVertical: 24,
            }}
            flex={1}
            mt="-6"
            showsVerticalScrollIndicator={false}>
            {renderHeader}
            <YearTermDates
                key={termDatesInputKey.current}
                dispatchScreenState={dispatchScreenState}
                loadTermDatesQueryReference={loadTermDatesQueryReference}
                navigation={navigation}
                schoolName={schoolName}
                screenState={screenState}
            />
            <Box mt="90px">
                <BlacklistedDates
                    dispatchScreenState={dispatchScreenState}
                    loadBlacklistedDatesQueryReference={
                        loadBlacklistedDatesQueryReference
                    }
                    navigation={navigation}
                    schoolId={schoolId}
                    schoolName={schoolName}
                    screenState={screenState}
                    scrollViewRef={scrollViewRef}
                    sideBarWidth={sideBarWidth}
                />
            </Box>
        </ScrollView>
    );
};

export default SchoolDates;
