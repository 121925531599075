/**
 * @generated SignedSource<<91e9b95e61f081d8ad73cbba994f92ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LoadSchoolsForDropdownsQuery$variables = {
  isActive?: boolean | null;
  orderBy?: string | null;
  searchTerm?: string | null;
};
export type LoadSchoolsForDropdownsQuery$data = {
  readonly schools: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
      } | null;
    } | null>;
  } | null;
};
export type LoadSchoolsForDropdownsQuery = {
  response: LoadSchoolsForDropdownsQuery$data;
  variables: LoadSchoolsForDropdownsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isActive"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "isActive",
        "variableName": "isActive"
      },
      {
        "kind": "Variable",
        "name": "orderBy",
        "variableName": "orderBy"
      },
      {
        "kind": "Variable",
        "name": "searchTerm",
        "variableName": "searchTerm"
      }
    ],
    "concreteType": "SchoolNodeConnection",
    "kind": "LinkedField",
    "name": "schools",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SchoolNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SchoolNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__id",
            "storageKey": null
          }
        ]
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoadSchoolsForDropdownsQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "LoadSchoolsForDropdownsQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "189c38d4f44bdab849c5981bb4ead9a6",
    "id": null,
    "metadata": {},
    "name": "LoadSchoolsForDropdownsQuery",
    "operationKind": "query",
    "text": "query LoadSchoolsForDropdownsQuery(\n  $searchTerm: String\n  $orderBy: String\n  $isActive: Boolean\n) {\n  schools(orderBy: $orderBy, searchTerm: $searchTerm, isActive: $isActive) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e585990091874cabe723efb5a3f51c65";

export default node;
