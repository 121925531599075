import { graphql } from "react-relay";

export const load_lesson_blocks = graphql`
    query LoadLessonBlocksQuery(
        $searchTerm: String
        $startingYear: Int!
        $block: Int
        $school: String
        $profileId: ID!
        $dayIndex: Int
        $skipLessonBlocks: Boolean!
        $skipRegisterProgress: Boolean!
        $skipLessonStages: Boolean!
        $orderBy: String
        $first: Int!
        $after: String
        $blockType: String
    ) {
        ...LoadLessonBlocks_query_lessonBlocks
            @skip(if: $skipLessonBlocks)
            @arguments(
                searchTerm: $searchTerm
                startingYear: $startingYear
                block: $block
                school: $school
                profileId: $profileId
                dayIndex: $dayIndex
                orderBy: $orderBy
                first: $first
                after: $after
                blockType: $blockType
            )
        ...RegisterInfoFragment_query
            @skip(if: $skipRegisterProgress)
            @arguments(profileId: $profileId)
        lessonStages(
            school: $school
            startingYear: $startingYear
            block: $block
        ) @skip(if: $skipLessonStages) {
            __id
            edges {
                node {
                    id
                    stage
                    lessonDuration
                    lessonType
                    costPerLesson
                }
            }
        }
    }
`;

export const load_lesson_blocks_pagination = graphql`
    fragment LoadLessonBlocks_query_lessonBlocks on Query
    @argumentDefinitions(
        searchTerm: { type: "String" }
        startingYear: { type: "Int!" }
        block: { type: "Int" }
        school: { type: "String" }
        profileId: { type: "ID" }
        dayIndex: { type: "Int" }
        orderBy: { type: "String" }
        first: { type: "Int!" }
        after: { type: "String" }
        blockType: { type: "String" }
    )
    @refetchable(queryName: "LoadLessonBlocksPaginationQuery") {
        lessonBlocks(
            searchTerm: $searchTerm
            startingYear: $startingYear
            block: $block
            school: $school
            profileId: $profileId
            dayIndex: $dayIndex
            orderBy: $orderBy
            first: $first
            after: $after
            blockType: $blockType
        ) @connection(key: "LoadLessonBlocks_query_lessonBlocks") {
            __id
            edges {
                cursor
                node {
                    id
                    block
                    keepFree
                    lessonDay
                    lessonTime
                    staffNoteForTeacher
                    teacherNoteForStaff
                    type
                    school {
                        id
                        name
                    }
                    pupil {
                        id
                        staffNoteForTeacher
                        teacherNoteForStaff
                        schoolYear
                        user {
                            firstName
                            lastName
                        }
                    }
                    teacher {
                        id
                        user {
                            firstName
                            lastName
                        }
                    }
                    instrument {
                        name
                    }
                    lessonStage {
                        id
                        stage
                        lessonDuration
                        lessonType
                    }
                    lessons {
                        edges {
                            node {
                                id
                                scheduledTimestamp
                                rearrangedTimestamp
                                status
                                teacherRegisterNoteForStaff
                                staffRegisterNoteForTeacher
                                lessonDuration
                                weekWasRearranged
                                replacementLessonColumnIndex
                                blacklistedDate {
                                    id
                                    reason
                                }
                                homework {
                                    id
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
