import React, { useState, useCallback, useRef, useEffect } from "react";
import type { FC } from "react";

import * as Linking from "expo-linking";
import { Button, Spinner, Center, VStack, useToast, Text } from "native-base";
import { Keyboard, Platform } from "react-native";
import { useMutation } from "react-relay";

import type {
    AuthStackNavigatorProps,
    AuthStackRouteProps,
} from "../../navigation/AuthNavigator";
import TextInput from "pianofunclub-shared/components/Inputs/TextInput";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import { MailIcon } from "pianofunclub-shared/components/Other/Icons";

import type {
    SendPasswordResetEmailMutation,
    SendPasswordResetEmailMutation$data,
} from "pianofunclub-shared/relay/graphql/auth/__generated__/SendPasswordResetEmailMutation.graphql";
import { send_password_reset_email } from "pianofunclub-shared/relay/graphql/auth/SendPasswordResetEmail";

type NavigationProps = AuthStackNavigatorProps<"ForgotPassword">;

type RouteProps = AuthStackRouteProps<"ForgotPassword">;

interface Props {
    navigation: NavigationProps;
    route: RouteProps;
}

const ForgotPasswordScreen: FC<Props> = (props) => {
    const { navigation, route } = props;

    const [email, setEmail] = useState(route.params?.email ?? "");
    const [emailIsValid, setEmailIsValid] = useState(
        route.params?.email ? true : false,
    );

    const emailInputRef = useRef<{ focus: () => void }>();
    const sendPasswordResetToast = useToast();
    const sendPasswordResetSuccessToastId = "sendPasswordResetSuccessToastId";
    const sendPasswordResetNoAccountToastId =
        "sendPasswordResetNoAccountToastId";
    const sendPasswordResetFailToastId = "sendPasswordResetFailToastId";

    const [commitSendPasswordResetEmail, sendPasswordResetEmailInFlight] =
        useMutation<SendPasswordResetEmailMutation>(send_password_reset_email);

    const sendPasswordResetEmail = useCallback(() => {
        Keyboard.dismiss();
        const sendPasswordResetEmailConfig = {
            variables: {
                input: {
                    email: email,
                    redirectUrl: Linking.createURL("/reset-password"),
                },
            },
            onCompleted: (response: SendPasswordResetEmailMutation$data) => {
                if (response?.sendPasswordResetEmail?.success) {
                    navigation.navigate("Login");
                    if (
                        !sendPasswordResetToast.isActive(
                            sendPasswordResetSuccessToastId,
                        )
                    ) {
                        sendPasswordResetToast.show({
                            id: sendPasswordResetSuccessToastId,
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    alertProps={{ ml: 0 }}
                                    id={id}
                                    status="success"
                                    title={"Password reset email sent"}
                                    toast={sendPasswordResetToast}
                                />
                            ),
                        });
                    }
                } else if (
                    response?.sendPasswordResetEmail?.errors?.code ==
                    "user_does_not_exist"
                ) {
                    if (
                        !sendPasswordResetToast.isActive(
                            sendPasswordResetNoAccountToastId,
                        )
                    ) {
                        sendPasswordResetToast.show({
                            id: sendPasswordResetNoAccountToastId,
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    alertProps={{ ml: 0 }}
                                    id={id}
                                    status="error"
                                    title={
                                        "There is no account with that email"
                                    }
                                    toast={sendPasswordResetToast}
                                />
                            ),
                        });
                    }
                } else {
                    if (
                        !sendPasswordResetToast.isActive(
                            sendPasswordResetFailToastId,
                        )
                    ) {
                        sendPasswordResetToast.show({
                            id: sendPasswordResetFailToastId,
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    alertProps={{ ml: 0 }}
                                    id={id}
                                    status="error"
                                    title={
                                        "Password reset email failed to send"
                                    }
                                    toast={sendPasswordResetToast}
                                />
                            ),
                        });
                    }
                }
            },
            onError: () => {
                if (
                    !sendPasswordResetToast.isActive(
                        sendPasswordResetFailToastId,
                    )
                ) {
                    sendPasswordResetToast.show({
                        id: sendPasswordResetFailToastId,
                        render: ({ id }: { id: string }) => (
                            <ToastAlert
                                alertProps={{ ml: 0 }}
                                id={id}
                                status="error"
                                title={"Password reset email failed to send"}
                                toast={sendPasswordResetToast}
                            />
                        ),
                    });
                }
            },
        };

        commitSendPasswordResetEmail(sendPasswordResetEmailConfig);
    }, [
        commitSendPasswordResetEmail,
        email,
        navigation,
        sendPasswordResetToast,
    ]);

    const inputChangeHandler = useCallback(
        (_?: string | number, inputValue?: string, isValid?: boolean) => {
            if (
                typeof inputValue === "string" &&
                typeof isValid === "boolean"
            ) {
                setEmail(inputValue.trim());
                setEmailIsValid(isValid);
            }
        },
        [],
    );

    useEffect(() => {
        setTimeout(() => emailInputRef.current?.focus(), 100);
    }, []);

    return (
        <Center flex={1}>
            <VStack alignItems="center" space="6">
                <Text
                    color="surface.100"
                    fontSize="2xl"
                    fontWeight={700}
                    mb="4"
                >
                    Forgotten your Password?
                </Text>
                <VStack
                    alignItems="center"
                    justifyContent="center"
                    space="12"
                    width="100%"
                >
                    <TextInput
                        ref={emailInputRef}
                        autoCapitalize="none"
                        email
                        id="email"
                        initiallyValid={route.params?.email ? true : false}
                        initialValue={route.params?.email ?? ""}
                        InputLeftElement={
                            <MailIcon
                                color={
                                    email == "" || !emailIsValid
                                        ? "muted.400"
                                        : "emerald.400"
                                }
                                ml="4"
                                size="6"
                            />
                        }
                        invalidIndicator
                        keyboardType="email-address"
                        label="Email"
                        onInputChange={inputChangeHandler}
                        onSubmit={() => {
                            if (emailIsValid && !sendPasswordResetEmailInFlight)
                                sendPasswordResetEmail();
                        }}
                        p="3"
                        placeholder="newme@gmail.com"
                        size="xl"
                    />
                    <Button
                        _disabled={{ opacity: 0.6 }}
                        _hover={{ bg: "primary.600" }}
                        _pressed={{ bg: "primary.700" }}
                        _text={{ fontSize: "xl", fontWeight: "600" }}
                        colorScheme="primary"
                        height="64px"
                        isDisabled={
                            sendPasswordResetEmailInFlight || !emailIsValid
                        }
                        onPress={sendPasswordResetEmail}
                        p="4"
                        shadow={5}
                        width="75%"
                    >
                        {!sendPasswordResetEmailInFlight ? (
                            "Send Reset Email"
                        ) : (
                            <Spinner color="white" />
                        )}
                    </Button>
                </VStack>
                <Button
                    _hover={{ bg: "transparent", opacity: 0.8 }}
                    _pressed={{ bg: "transparent", opacity: 0.7 }}
                    colorScheme="surface"
                    mt="4"
                    onPress={() => navigation.navigate("Login")}
                    variant="ghost"
                >
                    Go Back
                </Button>
            </VStack>
        </Center>
    );
};

export const screenOptions = {
    headerTitle: "",
    headerTransparent: true,
    headerStyle: {
        height: Platform.OS == "android" ? 120 : undefined,
    },
    headerTintColor: "#5B0854",
};

export default React.memo(ForgotPasswordScreen);
