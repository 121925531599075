/**
 * @generated SignedSource<<c3a2c0a72118aa4a5dfa02261ba208f0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LoadTermDatesQuery$variables = {
  school?: string | null;
  skipSchoolTermDates: boolean;
  startingYear: number;
};
export type LoadTermDatesQuery$data = {
  readonly schoolTermDates?: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly firstDayOfSecondHalfOfTerm: string | null;
        readonly firstDayOfTerm: string | null;
        readonly id: string;
        readonly lastDayOfFirstHalfOfTerm: string | null;
        readonly lastDayOfTerm: string | null;
        readonly term: number | null;
      } | null;
    } | null>;
  } | null;
  readonly termDates: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly firstDayOfSecondHalfOfTerm: string | null;
        readonly firstDayOfTerm: string | null;
        readonly id: string;
        readonly lastDayOfFirstHalfOfTerm: string | null;
        readonly lastDayOfTerm: string | null;
        readonly term: number | null;
      } | null;
    } | null>;
  } | null;
};
export type LoadTermDatesQuery = {
  response: LoadTermDatesQuery$data;
  variables: LoadTermDatesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "school"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipSchoolTermDates"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startingYear"
},
v3 = {
  "kind": "Variable",
  "name": "startingYear",
  "variableName": "startingYear"
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "TermDatesNodeEdge",
  "kind": "LinkedField",
  "name": "edges",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TermDatesNode",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "term",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstDayOfTerm",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastDayOfFirstHalfOfTerm",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstDayOfSecondHalfOfTerm",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastDayOfTerm",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      (v3/*: any*/)
    ],
    "concreteType": "TermDatesNodeConnection",
    "kind": "LinkedField",
    "name": "termDates",
    "plural": false,
    "selections": [
      (v4/*: any*/)
    ],
    "storageKey": null
  },
  {
    "condition": "skipSchoolTermDates",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": "schoolTermDates",
        "args": [
          {
            "kind": "Variable",
            "name": "school",
            "variableName": "school"
          },
          (v3/*: any*/)
        ],
        "concreteType": "TermDatesNodeConnection",
        "kind": "LinkedField",
        "name": "termDates",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoadTermDatesQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "LoadTermDatesQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "e085eb567f43beb6f4d31a803ec6e77e",
    "id": null,
    "metadata": {},
    "name": "LoadTermDatesQuery",
    "operationKind": "query",
    "text": "query LoadTermDatesQuery(\n  $school: String\n  $startingYear: Int!\n  $skipSchoolTermDates: Boolean!\n) {\n  termDates(startingYear: $startingYear) {\n    edges {\n      node {\n        id\n        term\n        firstDayOfTerm\n        lastDayOfFirstHalfOfTerm\n        firstDayOfSecondHalfOfTerm\n        lastDayOfTerm\n      }\n    }\n  }\n  schoolTermDates: termDates(school: $school, startingYear: $startingYear) @skip(if: $skipSchoolTermDates) {\n    edges {\n      node {\n        id\n        term\n        firstDayOfTerm\n        lastDayOfFirstHalfOfTerm\n        firstDayOfSecondHalfOfTerm\n        lastDayOfTerm\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9d47a6d6359b1a19954db61e3397d47b";

export default node;
