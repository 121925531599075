/**
 * @generated SignedSource<<a6ba5f5d902df3017b51345faa39e79f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LoadRegistersQuery$variables = {
  after?: string | null;
  block: number;
  first: number;
  searchTerm?: string | null;
  startingYear: number;
};
export type LoadRegistersQuery$data = {
  readonly me: {
    readonly profile: {
      readonly profileGroup: {
        readonly bulkUploadNewRegistersProgress: number | null;
        readonly generateGoogleRegistersProgress: number | null;
        readonly generateRegistersForNewBlockProgress: number | null;
        readonly id: string;
        readonly readInRegistersProgress: number | null;
      } | null;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"LoadRegisters_query_profiles">;
};
export type LoadRegistersQuery = {
  response: LoadRegistersQuery$data;
  variables: LoadRegistersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "block"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startingYear"
},
v5 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v6 = {
  "kind": "Variable",
  "name": "block",
  "variableName": "block"
},
v7 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v8 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v9 = {
  "kind": "Variable",
  "name": "startingYear",
  "variableName": "startingYear"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "ProfileGroupNode",
  "kind": "LinkedField",
  "name": "profileGroup",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "generateGoogleRegistersProgress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readInRegistersProgress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bulkUploadNewRegistersProgress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "generateRegistersForNewBlockProgress",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = [
  {
    "kind": "Literal",
    "name": "accountType",
    "value": "TEACHER"
  },
  (v5/*: any*/),
  (v7/*: any*/),
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "user__firstName,user__lastName"
  },
  (v8/*: any*/)
],
v13 = [
  (v6/*: any*/),
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoadRegistersQuery",
    "selections": [
      {
        "args": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "LoadRegisters_query_profiles"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DjangoUserNode",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "LoadRegistersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v12/*: any*/),
        "concreteType": "ProfileNodeConnection",
        "kind": "LinkedField",
        "name": "profiles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": (v13/*: any*/),
                    "kind": "ScalarField",
                    "name": "totalLessonsMarked",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v13/*: any*/),
                    "kind": "ScalarField",
                    "name": "totalLessons",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DjangoUserNode",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v12/*: any*/),
        "filters": [
          "accountType",
          "searchTerm",
          "orderBy"
        ],
        "handle": "connection",
        "key": "LoadRegisters_query_profiles",
        "kind": "LinkedHandle",
        "name": "profiles"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DjangoUserNode",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8ef1ee9ba28a894ad72c08a909580c3c",
    "id": null,
    "metadata": {},
    "name": "LoadRegistersQuery",
    "operationKind": "query",
    "text": "query LoadRegistersQuery(\n  $searchTerm: String\n  $startingYear: Int!\n  $block: Int!\n  $first: Int!\n  $after: String\n) {\n  ...LoadRegisters_query_profiles_2O59Mw\n  me {\n    profile {\n      profileGroup {\n        id\n        generateGoogleRegistersProgress\n        readInRegistersProgress\n        bulkUploadNewRegistersProgress\n        generateRegistersForNewBlockProgress\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment LoadRegisters_query_profiles_2O59Mw on Query {\n  profiles(accountType: \"TEACHER\", searchTerm: $searchTerm, orderBy: \"user__firstName,user__lastName\", first: $first, after: $after) {\n    edges {\n      node {\n        id\n        totalLessonsMarked(startingYear: $startingYear, block: $block)\n        totalLessons(startingYear: $startingYear, block: $block)\n        user {\n          firstName\n          lastName\n          email\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "59fa8da30f0e7c1cfeef2d594a693a56";

export default node;
