/**
 * @generated SignedSource<<0ed5fb93cf05b333e7e9b843726f215d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LoadPayslipsQuery$variables = {
  after?: string | null;
  dateFrom: string;
  dateTo: string;
  first: number;
  orderBy?: string | null;
  searchTerm?: string | null;
};
export type LoadPayslipsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LoadPayslips_query_payslips" | "PayrollHeadlineDataFragment_query">;
};
export type LoadPayslipsQuery = {
  response: LoadPayslipsQuery$data;
  variables: LoadPayslipsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dateFrom"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dateTo"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v6 = {
  "kind": "Variable",
  "name": "dateFrom",
  "variableName": "dateFrom"
},
v7 = {
  "kind": "Variable",
  "name": "dateTo",
  "variableName": "dateTo"
},
v8 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  (v6/*: any*/),
  (v7/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "searchTerm",
    "variableName": "searchTerm"
  }
],
v9 = [
  (v6/*: any*/),
  (v7/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoadPayslipsQuery",
    "selections": [
      {
        "args": (v8/*: any*/),
        "kind": "FragmentSpread",
        "name": "LoadPayslips_query_payslips"
      },
      {
        "args": (v9/*: any*/),
        "kind": "FragmentSpread",
        "name": "PayrollHeadlineDataFragment_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "LoadPayslipsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "PayslipNodeConnection",
        "kind": "LinkedField",
        "name": "payslips",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PayslipNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PayslipNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amountDue",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amountAdjusted",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adjustedReason",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "paidFromDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "paidToDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalMinutesTaught",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProfileNode",
                    "kind": "LinkedField",
                    "name": "teacher",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProfileGroupNode",
                        "kind": "LinkedField",
                        "name": "profileGroup",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "estimatedTotalAmountUnpaid",
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DjangoUserNode",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "accountNumber",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "filters": [
          "searchTerm",
          "orderBy",
          "dateFrom",
          "dateTo"
        ],
        "handle": "connection",
        "key": "LoadPayslips_query_payslips",
        "kind": "LinkedHandle",
        "name": "payslips"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DjangoUserNode",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              {
                "alias": null,
                "args": (v9/*: any*/),
                "kind": "ScalarField",
                "name": "totalPayslipsUnpaidAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v9/*: any*/),
                "kind": "ScalarField",
                "name": "totalPayslipsPaidAmount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "date",
            "variableName": "dateFrom"
          }
        ],
        "concreteType": "PayrateNode",
        "kind": "LinkedField",
        "name": "payrate",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hourlyRate",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "71211381f8008f067d3da4fb9311fccc",
    "id": null,
    "metadata": {},
    "name": "LoadPayslipsQuery",
    "operationKind": "query",
    "text": "query LoadPayslipsQuery(\n  $searchTerm: String\n  $orderBy: String\n  $first: Int!\n  $after: String\n  $dateFrom: String!\n  $dateTo: String!\n) {\n  ...LoadPayslips_query_payslips_2BaxH\n  ...PayrollHeadlineDataFragment_query_3f8Bpf\n}\n\nfragment LoadPayslips_query_payslips_2BaxH on Query {\n  payslips(searchTerm: $searchTerm, orderBy: $orderBy, first: $first, after: $after, dateFrom: $dateFrom, dateTo: $dateTo) {\n    edges {\n      node {\n        id\n        amountDue\n        status\n        amountAdjusted\n        adjustedReason\n        paidFromDate\n        paidToDate\n        totalMinutesTaught\n        teacher {\n          id\n          profileGroup {\n            estimatedTotalAmountUnpaid\n            id\n          }\n          user {\n            firstName\n            lastName\n            email\n            id\n          }\n          accountNumber\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment PayrollHeadlineDataFragment_query_3f8Bpf on Query {\n  me {\n    profile {\n      id\n      totalPayslipsUnpaidAmount(dateFrom: $dateFrom, dateTo: $dateTo)\n      totalPayslipsPaidAmount(dateFrom: $dateFrom, dateTo: $dateTo)\n    }\n    id\n  }\n  payrate(date: $dateFrom) {\n    id\n    hourlyRate\n  }\n}\n"
  }
};
})();

(node as any).hash = "880cc1a7631806dfe2cdc895f8e3698d";

export default node;
