import { graphql } from "react-relay";

export const send_reminder_for_unpaid_invoice = graphql`
    mutation SendReminderForUnpaidInvoiceMutation(
        $input: SendReminderForUnpaidInvoiceInput!
    ) {
        sendReminderForUnpaidInvoice(input: $input) {
            success
            errors
            invoice {
                id
                reminder1SentTimestamp
                reminder2SentTimestamp
                reminder3SentTimestamp
            }
        }
    }
`;
