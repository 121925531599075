import { graphql } from "react-relay";

export const generate_or_update_draft_invoices_for_block = graphql`
    mutation GenerateOrUpdateDraftInvoicesForBlockMutation(
        $input: GenerateOrUpdateDraftInvoicesForBlockInput!
        $startingYear: Int!
        $block: Int!
    ) {
        generateOrUpdateDraftInvoicesForBlock(input: $input) {
            success
            errors
            numberOfLessonBlocksProcessed
            numberOfDraftsGenerated
            totalToGenerate
            erroredInvoice {
                id
                assignedTo {
                    id
                    accountNumber
                    user {
                        firstName
                        lastName
                        email
                    }
                }
            }
            profile {
                id
                totalInvoices(startingYear: $startingYear, block: $block)
                totalAmountInvoiced(startingYear: $startingYear, block: $block)
                totalInvoicesSent(startingYear: $startingYear, block: $block)
                totalAmountPaid(startingYear: $startingYear, block: $block)
                totalInvoicesUnpaid(startingYear: $startingYear, block: $block)
                percentLessonBlocksInvoicedFor(
                    startingYear: $startingYear
                    block: $block
                )
                lessonBlocksNotInvoicedFor(
                    startingYear: $startingYear
                    block: $block
                ) {
                    id
                    pupil {
                        id
                        user {
                            id
                            firstName
                            lastName
                        }
                    }
                }
                profileGroup {
                    id
                    generateDraftsProgress
                }
            }
        }
    }
`;
