import { graphql } from "react-relay";

export const auto_login = graphql`
    mutation AutoLoginMutation($input: AutoLoginInput!) {
        autoLogin(input: $input) {
            success
            errors
            user {
                id
                profile {
                    id
                    accountType
                }
            }
        }
    }
`;
