/**
 * @generated SignedSource<<bc27e8a5d2f7674822e3112522fab813>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InvoiceModelStatus = "CANCELLED" | "DRAFT" | "OVERPAID" | "PAID" | "PENDING" | "REFUND" | "SENT" | "%future added value";
export type PaymentModelPaymentType = "BANK_TRANSFER" | "CASH" | "CREDIT_DEBIT_CARD" | "PAYPAL" | "%future added value";
export type UpdateOrCreatePaymentInput = {
  amount: number;
  clientMutationId?: string | null;
  invoiceId: string;
  paymentDate?: string | null;
  paymentId?: string | null;
  paymentNote?: string | null;
  paymentReference?: string | null;
  paymentType?: string | null;
};
export type UpdateOrCreatePaymentMutation$variables = {
  connections: ReadonlyArray<string>;
  input: UpdateOrCreatePaymentInput;
};
export type UpdateOrCreatePaymentMutation$data = {
  readonly updateOrCreatePayment: {
    readonly errors: any | null;
    readonly invoice: {
      readonly amountPaid: number | null;
      readonly id: string;
      readonly status: InvoiceModelStatus;
    } | null;
    readonly payment: {
      readonly amount: number | null;
      readonly id: string;
      readonly paymentDate: string | null;
      readonly paymentNote: string | null;
      readonly paymentReference: string | null;
      readonly paymentType: PaymentModelPaymentType;
    } | null;
    readonly success: boolean | null;
  } | null;
};
export type UpdateOrCreatePaymentMutation = {
  response: UpdateOrCreatePaymentMutation$data;
  variables: UpdateOrCreatePaymentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "PaymentNode",
  "kind": "LinkedField",
  "name": "payment",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentReference",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentNote",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "InvoiceNode",
  "kind": "LinkedField",
  "name": "invoice",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amountPaid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateOrCreatePaymentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrCreatePaymentPayload",
        "kind": "LinkedField",
        "name": "updateOrCreatePayment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateOrCreatePaymentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrCreatePaymentPayload",
        "kind": "LinkedField",
        "name": "updateOrCreatePayment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "prependNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "payment",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "PaymentNodeEdge"
              }
            ]
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dd6d65309b71673b4754b6f12bd92a51",
    "id": null,
    "metadata": {},
    "name": "UpdateOrCreatePaymentMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateOrCreatePaymentMutation(\n  $input: UpdateOrCreatePaymentInput!\n) {\n  updateOrCreatePayment(input: $input) {\n    success\n    errors\n    payment {\n      id\n      amount\n      paymentType\n      paymentReference\n      paymentDate\n      paymentNote\n    }\n    invoice {\n      id\n      amountPaid\n      status\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "348ebca5928a581f87105fb0c62a1934";

export default node;
