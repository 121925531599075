import { graphql } from "react-relay";

export const update_invoice_status = graphql`
    mutation UpdateInvoiceStatusMutation($input: UpdateInvoiceStatusInput!) {
        updateInvoiceStatus(input: $input) {
            success
            errors
            invoice {
                id
                status
                initialSentTimestamp
                dueDate
                amountPaid
                payments {
                    edges {
                        node {
                            id
                        }
                    }
                }
            }
            relatedInvoice {
                id
                otherInvoicesForBlock {
                    edges {
                        node {
                            id
                            invoiceNumber
                            status
                            totalAmount
                            createdOn
                        }
                    }
                }
            }
        }
    }
`;
