import { graphql } from "react-relay";

export const update_registers_for_week = graphql`
    mutation UpdateRegistersForWeekMutation(
        $input: UpdateRegistersForWeekInput!
    ) {
        updateRegistersForWeek(input: $input) {
            success
            errors
            lessons {
                id
                status
                lessonDuration
                staffRegisterNoteForTeacher
                teacherRegisterNoteForStaff
            }
        }
    }
`;
