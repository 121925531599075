import { graphql } from "react-relay";

export const update_account_status = graphql`
    mutation UpdateAccountStatusMutation(
        $input: UpdateAccountStatusInput!
        $connections: [ID!]!
    ) {
        updateAccountStatus(input: $input) {
            success
            errors
            deletedProfileId @deleteEdge(connections: $connections)
            profiles {
                id
                status
            }
        }
    }
`;
