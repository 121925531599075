/**
 * @generated SignedSource<<6bd5b3c96fd04fce8e8c682cfbc6240c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LessonModelStatus = "BLANK" | "BREAK" | "LEFT_SCHOOL" | "MISSED" | "NO_LESSON" | "OTHER_TEACHER" | "PRESENT" | "PRESENT_DOUBLE" | "PUPIL_ABSENT" | "STOPPED_LESSONS" | "TEACHER_ABSENT" | "%future added value";
export type UpdateRegistersInput = {
  clientMutationId?: string | null;
  updates: ReadonlyArray<UpdateRegisterInput | null>;
};
export type UpdateRegisterInput = {
  lessonId: string;
  staffRegisterNoteForTeacher?: string | null;
  status: string;
  teacherRegisterNoteForStaff?: string | null;
};
export type UpdateRegistersMutation$variables = {
  input: UpdateRegistersInput;
};
export type UpdateRegistersMutation$data = {
  readonly updateRegisters: {
    readonly errors: any | null;
    readonly lessons: ReadonlyArray<{
      readonly id: string;
      readonly lessonDuration: number | null;
      readonly staffRegisterNoteForTeacher: string | null;
      readonly status: LessonModelStatus;
      readonly teacherRegisterNoteForStaff: string | null;
    } | null> | null;
    readonly pupilsToArchive: ReadonlyArray<{
      readonly id: string;
      readonly user: {
        readonly firstName: string;
        readonly lastName: string;
      };
    } | null> | null;
    readonly success: boolean | null;
  } | null;
};
export type UpdateRegistersMutation = {
  response: UpdateRegistersMutation$data;
  variables: UpdateRegistersMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "LessonNode",
  "kind": "LinkedField",
  "name": "lessons",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "teacherRegisterNoteForStaff",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "staffRegisterNoteForTeacher",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lessonDuration",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateRegistersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRegistersPayload",
        "kind": "LinkedField",
        "name": "updateRegisters",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "pupilsToArchive",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DjangoUserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateRegistersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRegistersPayload",
        "kind": "LinkedField",
        "name": "updateRegisters",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "pupilsToArchive",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DjangoUserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a5ac3aa94b1157ed0651908e15577dc4",
    "id": null,
    "metadata": {},
    "name": "UpdateRegistersMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateRegistersMutation(\n  $input: UpdateRegistersInput!\n) {\n  updateRegisters(input: $input) {\n    success\n    errors\n    lessons {\n      id\n      status\n      teacherRegisterNoteForStaff\n      staffRegisterNoteForTeacher\n      lessonDuration\n    }\n    pupilsToArchive {\n      id\n      user {\n        firstName\n        lastName\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "217f1d5d2fefaaa24a238a762d4d3044";

export default node;
