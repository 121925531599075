/**
 * @generated SignedSource<<ad02a438fdb71d8a6b0699d488a8cd03>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PasswordChangeInput = {
  clientMutationId?: string | null;
  newPassword1: string;
  newPassword2: string;
  oldPassword: string;
};
export type ChangePasswordMutation$variables = {
  input: PasswordChangeInput;
};
export type ChangePasswordMutation$data = {
  readonly passwordChange: {
    readonly errors: any | null;
    readonly refreshToken: string | null;
    readonly success: boolean | null;
    readonly token: string | null;
  } | null;
};
export type ChangePasswordMutation = {
  response: ChangePasswordMutation$data;
  variables: ChangePasswordMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PasswordChangePayload",
    "kind": "LinkedField",
    "name": "passwordChange",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "refreshToken",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangePasswordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangePasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0390b0ba8afc32f5e804df81bd7df67e",
    "id": null,
    "metadata": {},
    "name": "ChangePasswordMutation",
    "operationKind": "mutation",
    "text": "mutation ChangePasswordMutation(\n  $input: PasswordChangeInput!\n) {\n  passwordChange(input: $input) {\n    success\n    errors\n    token\n    refreshToken\n  }\n}\n"
  }
};
})();

(node as any).hash = "910f6d3aac1edecba93ad5887ba55d06";

export default node;
