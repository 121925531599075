import { graphql } from "react-relay";

export const update_invoice_message = graphql`
    mutation UpdateInvoiceMessageMutation($input: UpdateInvoiceMessageInput!) {
        updateInvoiceMessage(input: $input) {
            success
            errors
            invoice {
                id
                invoiceEmailSubject
                invoiceMessageString
            }
        }
    }
`;
