import React, { useMemo } from "react";
import type { ReactElement } from "react";

import { Box, Progress, Text } from "native-base";

import type { TableData as RegisterTableData } from "../../screens/registers/RegistersHubScreen";
import type { CellProps } from "pianofunclub-shared/components/Base/Cell";
import Row from "pianofunclub-shared/components/Base/Row";

import { getFullName } from "pianofunclub-shared/utils/extractors";

interface Props {
    cellProps?: CellProps;
    data: NonNullable<NonNullable<RegisterTableData>["node"]>;
    flexArray: number[];
    onPressProfile: (profileId?: string | null) => void;
    rowHeight?: number;
    tableBorderColor?: string;
    tableBorderWidth?: number;
}

const RegisterHubTableRow = (props: Props): ReactElement => {
    const {
        cellProps,
        data,
        flexArray,
        onPressProfile,
        rowHeight,
        tableBorderColor,
        tableBorderWidth,
    } = props;

    const rowData = useMemo(() => {
        const registerPercentageProgress =
            data.totalLessons &&
            data.totalLessonsMarked &&
            (data.totalLessons ?? 0) > 0
                ? 100 * (data.totalLessonsMarked / data.totalLessons)
                : 0;
        let registerTrackColor = "emerald.400";
        if (registerPercentageProgress < 50) {
            registerTrackColor = "red.300";
        } else if (registerPercentageProgress < 90) {
            registerTrackColor = "warning.300";
        } else if (registerPercentageProgress < 100) {
            registerTrackColor = "primary.300";
        }

        return [
            {
                data: data.user.firstName
                    ? getFullName(data.user.firstName, data.user.lastName)
                    : data.user.email,
                onPress: () => onPressProfile(data.id),
            },
            {
                data: (
                    <Box
                        alignItems="center"
                        flex={1}
                        height="container"
                        justifyContent="center"
                        mx="-2"
                        width="container"
                    >
                        <Progress
                            _filledTrack={{
                                bg: registerTrackColor,
                                borderLeftRadius: 0,
                                borderRightRadius:
                                    registerPercentageProgress === 100
                                        ? 0
                                        : undefined,
                            }}
                            borderRadius="0"
                            height="10"
                            value={registerPercentageProgress}
                            width="100%"
                        />
                        <Box
                            alignItems="center"
                            alignSelf="center"
                            position="absolute"
                            top="2.5"
                        >
                            <Text fontFamily="Poppins-Light">
                                {registerPercentageProgress.toFixed(1)}% (
                                {data.totalLessonsMarked ?? 0}/
                                {data.totalLessons ?? 0})
                            </Text>
                        </Box>
                    </Box>
                ),
                onPress: () => onPressProfile(data.id),
            },
        ];
    }, [
        data.id,
        data.totalLessons,
        data.totalLessonsMarked,
        data.user.email,
        data.user.firstName,
        data.user.lastName,
        onPressProfile,
    ]);

    return (
        <Row
            cellProps={cellProps}
            data={rowData}
            flexArray={flexArray}
            rowHeight={rowHeight}
            rowIndex={0}
            tableBorderColor={tableBorderColor}
            tableBorderWidth={tableBorderWidth}
        />
    );
};

export default RegisterHubTableRow;
