import React, { useMemo } from "react";
import type { ReactElement, ComponentProps } from "react";

import { HStack, Text, ChevronDownIcon, Center, Spinner } from "native-base";
import type { Icon } from "native-base";

import {
    DraftIcon,
    SendIcon,
    TickIcon,
    BlankIcon,
    BlockIcon,
    DraftFilledIcon,
    DoubleTickIcon,
    DraftUpdatedIcon,
    DraftFilledUpdatedIcon,
    CashIcon,
    ClockCheckIcon,
} from "pianofunclub-shared/components/Other/Icons";

import { titleCaseConverter } from "pianofunclub-shared/utils/converters";

type HStackProps = ComponentProps<typeof HStack>;
type TextProps = ComponentProps<typeof Text>;
type IconProps = ComponentProps<typeof Icon>;

interface Props extends HStackProps {
    iconProps?: IconProps;
    isLoading?: boolean;
    isTransparent?: boolean;
    isUpdatedDraft?: boolean | null;
    showEditArrow?: boolean;
    status?: string;
    textProps?: TextProps;
}

const InvoiceStatus = (props: Props): ReactElement | null => {
    const {
        iconProps,
        isLoading,
        isTransparent,
        isUpdatedDraft,
        showEditArrow,
        status,
        textProps,
    } = props;

    const { color, endColor, icon, startColor, textColor } = useMemo(() => {
        if (!isTransparent) {
            switch (status) {
                case "DRAFT":
                    return {
                        startColor: "surface.400",
                        endColor: "surface.600",
                        textColor: "surface.50",
                        icon: !isUpdatedDraft ? (
                            <DraftIcon
                                color="surface.50"
                                size="6"
                                {...iconProps}
                            />
                        ) : (
                            <DraftUpdatedIcon
                                color="surface.50"
                                size="6"
                                {...iconProps}
                            />
                        ),
                    };
                case "SENT":
                    return {
                        startColor: "primary.300",
                        endColor: "primary.500",
                        textColor: "surface.50",
                        icon: (
                            <SendIcon
                                color="surface.50"
                                size="4"
                                {...iconProps}
                            />
                        ),
                    };
                case "PAID":
                    return {
                        startColor: "primary.400",
                        endColor: "primary.600",
                        textColor: "surface.50",
                        icon: (
                            <TickIcon
                                color="surface.50"
                                size="6"
                                {...iconProps}
                            />
                        ),
                    };
                case "PENDING":
                    return {
                        startColor: "orange.300",
                        endColor: "orange.500",
                        textColor: "surface.50",
                        icon: (
                            <BlankIcon
                                color="surface.50"
                                size="6"
                                {...iconProps}
                            />
                        ),
                    };
                case "OVERPAID":
                    return {
                        startColor: "primary.600",
                        endColor: "primary.800",
                        textColor: "surface.50",
                        icon: (
                            <DoubleTickIcon
                                color="surface.50"
                                size="6"
                                {...iconProps}
                            />
                        ),
                    };
                case "REFUND":
                    return {
                        startColor: "secondary.500",
                        endColor: "secondary.700",
                        textColor: "surface.50",
                        icon: (
                            <CashIcon
                                color="surface.50"
                                size="6"
                                {...iconProps}
                            />
                        ),
                    };
                case "NOT_VIEWED":
                    return {
                        color: "transparent",
                        textColor: "orange.500",
                        icon: (
                            <BlankIcon
                                color="orange.500"
                                size="6"
                                {...iconProps}
                            />
                        ),
                    };
                case "READY_TO_PAY":
                    return {
                        color: "transparent",
                        textColor: "yellow.600",
                        icon: (
                            <ClockCheckIcon
                                color="yellow.600"
                                size="6"
                                {...iconProps}
                            />
                        ),
                    };
                default:
                    return {
                        startColor: "surface.600",
                        endColor: "surface.800",
                        textColor: "surface.50",
                        icon: (
                            <BlockIcon
                                color="surface.50"
                                size="6"
                                {...iconProps}
                            />
                        ),
                    };
            }
        } else {
            switch (status) {
                case "DRAFT":
                    return {
                        color: "transparent",
                        textColor: "surface.700",
                        icon: !isUpdatedDraft ? (
                            <DraftFilledIcon
                                color="surface.700"
                                size="6"
                                {...iconProps}
                            />
                        ) : (
                            <DraftFilledUpdatedIcon
                                color="surface.700"
                                size="6"
                                {...iconProps}
                            />
                        ),
                    };
                case "SENT":
                    return {
                        color: "transparent",
                        textColor: "primary.500",
                        icon: (
                            <SendIcon
                                color="primary.500"
                                size="4"
                                {...iconProps}
                            />
                        ),
                    };
                case "PAID":
                    return {
                        color: "transparent",
                        textColor: "primary.600",
                        icon: (
                            <TickIcon
                                color="primary.600"
                                size="6"
                                {...iconProps}
                            />
                        ),
                    };
                case "PENDING":
                    return {
                        color: "transparent",
                        textColor: "orange.500",
                        icon: (
                            <BlankIcon
                                color="orange.500"
                                size="6"
                                {...iconProps}
                            />
                        ),
                    };
                case "OVERPAID":
                    return {
                        color: "transparent",
                        textColor: "primary.800",
                        icon: (
                            <DoubleTickIcon
                                color="primary.800"
                                size="6"
                                {...iconProps}
                            />
                        ),
                    };
                case "REFUND":
                    return {
                        color: "transparent",
                        textColor: "secondary.600",
                        icon: (
                            <CashIcon
                                color="secondary.600"
                                size="6"
                                {...iconProps}
                            />
                        ),
                    };
                case "NOT_VIEWED":
                    return {
                        color: "transparent",
                        textColor: "orange.500",
                        icon: (
                            <BlankIcon
                                color="orange.500"
                                size="6"
                                {...iconProps}
                            />
                        ),
                    };
                case "READY_TO_PAY":
                    return {
                        color: "transparent",
                        textColor: "yellow.600",
                        icon: (
                            <ClockCheckIcon
                                color="yellow.600"
                                size="6"
                                {...iconProps}
                            />
                        ),
                    };
                default:
                    return {
                        color: "transparent",
                        textColor: "surface.800",
                        icon: (
                            <BlockIcon
                                color="surface.800"
                                size="6"
                                {...iconProps}
                            />
                        ),
                    };
            }
        }
    }, [iconProps, isTransparent, isUpdatedDraft, status]);

    if (status) {
        return (
            <HStack
                bg={
                    startColor && endColor
                        ? {
                              linearGradient: {
                                  colors: [startColor, endColor],
                                  start: [0.25, 0],
                                  end: [0.75, 1],
                              },
                          }
                        : color
                }
                borderRadius="2xl"
                pl="3"
                pr="4"
                py="2"
                shadow={!isTransparent && showEditArrow ? "1" : undefined}
                space="2"
                {...props}
            >
                <Center size="6">
                    {!isLoading ? (
                        icon
                    ) : (
                        <Spinner
                            color={isTransparent ? textColor : "surface.50"}
                        />
                    )}
                </Center>
                <Text color={textColor} fontSize="md" {...textProps}>
                    {titleCaseConverter(status)}
                </Text>
                {showEditArrow ? (
                    <ChevronDownIcon
                        color={isTransparent ? "surface.500" : "surface.100"}
                    />
                ) : null}
            </HStack>
        );
    } else {
        return null;
    }
};

export default InvoiceStatus;
