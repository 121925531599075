/**
 * @generated SignedSource<<02d392464d96622b7e271f38a3e13b6d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RefreshTokenInput = {
  clientMutationId?: string | null;
  refreshToken: string;
};
export type RefreshTokenMutation$variables = {
  input: RefreshTokenInput;
};
export type RefreshTokenMutation$data = {
  readonly refreshToken: {
    readonly errors: any | null;
    readonly payload: any | null;
    readonly refreshToken: string | null;
    readonly success: boolean | null;
    readonly token: string | null;
  } | null;
};
export type RefreshTokenMutation = {
  response: RefreshTokenMutation$data;
  variables: RefreshTokenMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RefreshTokenPayload",
    "kind": "LinkedField",
    "name": "refreshToken",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "payload",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "refreshToken",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RefreshTokenMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RefreshTokenMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bce2495e067357aaa2897648b48b9668",
    "id": null,
    "metadata": {},
    "name": "RefreshTokenMutation",
    "operationKind": "mutation",
    "text": "mutation RefreshTokenMutation(\n  $input: RefreshTokenInput!\n) {\n  refreshToken(input: $input) {\n    success\n    errors\n    payload\n    token\n    refreshToken\n  }\n}\n"
  }
};
})();

(node as any).hash = "f00e2127dbca4ec85a098c1b6ba537ac";

export default node;
