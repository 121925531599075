import { graphql } from "react-relay";

export const add_new_lesson_block = graphql`
    mutation AddNewLessonBlockMutation(
        $connections: [ID!]!
        $input: AddNewLessonBlockInput!
    ) {
        addNewLessonBlock(input: $input) {
            success
            errors
            lessonBlock
                @prependNode(
                    connections: $connections
                    edgeTypeName: "LessonBlockNodeEdge"
                ) {
                id
                lessonDay
                lessonTime
                teacher {
                    id
                }
                school {
                    id
                    name
                }
                lessons {
                    edges {
                        node {
                            status
                            scheduledTimestamp
                            rearrangedTimestamp
                            weekWasRearranged
                            replacementLessonColumnIndex
                        }
                    }
                }
                type
            }
        }
    }
`;
