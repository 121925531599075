import React, { useCallback, useEffect, useRef, useMemo } from "react";
import type {
    ReactElement,
    ComponentProps,
    Dispatch,
    MutableRefObject,
} from "react";

import emoji from "emoji-dictionary";
import { Box, VStack, HStack, Button, Text, SectionList } from "native-base";
import { Platform } from "react-native";
import {
    usePreloadedQuery,
    usePaginationFragment,
    useRelayEnvironment,
} from "react-relay";
import type {
    PreloadedQuery,
    UseQueryLoaderLoadQueryOptions,
} from "react-relay";
import { fetchQuery } from "relay-runtime";
import type { Subscription } from "relay-runtime";

import type {
    ReducerValues as AccountScreenReducerValues,
    ReducerTypes as AccountScreenReducerTypes,
} from "../../screens/accounts/AccountScreen";
import type { NavigationProps as AccountScreenNavigationProps } from "../../screens/accounts/AccountScreen";
import PupilLessonBlockRegister from "../ListItems/PupilLessonBlockRegister";
import LoadingBlobs from "pianofunclub-shared/components/Animations/LoadingBlobs";
import ListEmptyBanner from "pianofunclub-shared/components/ListItems/ListEmptyBanner";
import type { RegisterUpdate } from "pianofunclub-shared/components/Modals/ManageRegisterLessonModal";
import Select from "pianofunclub-shared/components/NativeBaseExtended/Select";
import CustomFlatListSpinner from "pianofunclub-shared/components/Other/CustomFlatListSpinner";
import { RestartIcon } from "pianofunclub-shared/components/Other/Icons";

import type {
    LoadLessonBlocks_query_lessonBlocks$key,
    LoadLessonBlocks_query_lessonBlocks$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/LoadLessonBlocks_query_lessonBlocks.graphql";
import type { LoadLessonBlocksPaginationQuery } from "pianofunclub-shared/relay/graphql/registers/__generated__/LoadLessonBlocksPaginationQuery.graphql";
import type {
    LoadLessonBlocksQuery,
    LoadLessonBlocksQuery$variables,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/LoadLessonBlocksQuery.graphql";
import {
    load_lesson_blocks,
    load_lesson_blocks_pagination,
} from "pianofunclub-shared/relay/graphql/registers/LoadLessonBlocks";

import { BLOCKS } from "pianofunclub-shared/utils/constants";
import type { State, Action } from "pianofunclub-shared/utils/reducers";

type VStackProps = ComponentProps<typeof VStack>;

interface Props extends VStackProps {
    dispatchState: Dispatch<
        Action<AccountScreenReducerValues, AccountScreenReducerTypes>
    >;
    loadLessonBlocksQuery: (
        variables: LoadLessonBlocksQuery$variables,
        options?: UseQueryLoaderLoadQueryOptions,
    ) => void;
    loadLessonBlocksQueryReference: PreloadedQuery<
        LoadLessonBlocksQuery,
        Record<string, unknown>
    >;
    navigation: AccountScreenNavigationProps;
    openLessonBlockOptionsHandler: (lessonBlockId: string) => void;
    profileId: string;
    registerUpdates: MutableRefObject<RegisterUpdate[]>;
    showUpdateBlockDetailsModalHandler: (variables: {
        keepFree?: boolean;
        lessonBlockIds: string[];
        onUpdate?: () => void;
        school?: string;
        selectedProfileFullName?: string;
        selectedProfileId?: string;
        type: string;
    }) => void;
    sideBarWidth: number;
    startingYears: {
        label: string;
        value: number;
    }[];
    state: State<AccountScreenReducerValues>;
    updateRegisterState: (
        variables: {
            lessonId: string;
            registerNote?: string;
            status: string;
        },
        progressChange?: number,
    ) => void;
}

const LOAD_X_LESSON_BLOCKS = Platform.OS === "web" ? 20 : 10;

const PupilRegisters = (props: Props): ReactElement => {
    const {
        dispatchState,
        loadLessonBlocksQuery,
        loadLessonBlocksQueryReference,
        navigation,
        openLessonBlockOptionsHandler,
        profileId,
        registerUpdates,
        showUpdateBlockDetailsModalHandler,
        sideBarWidth,
        startingYears,
        state,
        updateRegisterState,
    } = props;

    const data = usePreloadedQuery(
        load_lesson_blocks,
        loadLessonBlocksQueryReference,
    );

    const {
        data: lessonBlocksData,
        hasNext,
        isLoadingNext,
        loadNext,
        refetch: refresh,
    } = usePaginationFragment<
        LoadLessonBlocksPaginationQuery,
        LoadLessonBlocks_query_lessonBlocks$key
    >(load_lesson_blocks_pagination, data);

    const lessonBlocksSectioned = useMemo(() => {
        dispatchState({
            input: "lessonBlocksConnectionId",
            value: lessonBlocksData.lessonBlocks?.__id,
        });
        // group lesson blocks by block
        const map = new Map();
        lessonBlocksData.lessonBlocks?.edges.forEach((item) => {
            if (
                item?.node &&
                // this check is included so that when a pupil is removed from a block in the store
                // the block is not displayed in their register
                item.node.pupil?.id === profileId
            ) {
                if (map.get(item.node.block)) {
                    return map.set(item.node.block, {
                        ...map.get(item.node.block),
                        data: [...map.get(item.node.block).data, item.node],
                    });
                } else {
                    return map.set(item.node.block, {
                        title: item.node.block,
                        data: [item.node],
                    });
                }
            }
        });

        // add an index to the section array
        return Array.from(map.values()).map((item, index) => {
            item["key"] = index;
            return item;
        });
    }, [
        dispatchState,
        lessonBlocksData.lessonBlocks?.__id,
        lessonBlocksData.lessonBlocks?.edges,
        profileId,
    ]);

    const onEndReached = useCallback(() => {
        if (hasNext && !isLoadingNext) {
            loadNext(LOAD_X_LESSON_BLOCKS);
        }
    }, [hasNext, isLoadingNext, loadNext]);

    const refreshHandler = useCallback(() => {
        const refreshData = () => {
            loadLessonBlocksQuery(
                {
                    first: LOAD_X_LESSON_BLOCKS,
                    startingYear: state.values.startingYear,
                    profileId: profileId,
                    orderBy: "-block,lessonDay,lessonTime",
                    skipRegisterProgress: true,
                    skipLessonBlocks: false,
                    skipLessonStages: true,
                },
                { fetchPolicy: "network-only" },
            );
        };
        if (registerUpdates.current.length > 0) {
            dispatchState({
                input: "leaveAlertAction",
                value: refreshData,
            });
        } else {
            refreshData();
        }
    }, [
        dispatchState,
        loadLessonBlocksQuery,
        profileId,
        registerUpdates,
        state.values.startingYear,
    ]);

    // stop refetch useEffects firing on first render
    const renderCount = useRef(0);
    const environment = useRelayEnvironment();

    const refetchLessonBlocks = useCallback(
        (variables: { startingYear: number }): Subscription | undefined => {
            dispatchState({ input: "isRefetching", value: true });

            const fullVariables = {
                ...variables,
                first: LOAD_X_LESSON_BLOCKS,
                profileId: profileId,
                searchTerm: "",
                dayIndex: 0,
                orderBy: "-block,lessonDay,lessonTime",
                skipRegisterProgress: true,
                skipLessonStages: true,
                skipLessonBlocks: false,
            };

            const subscription = fetchQuery<LoadLessonBlocksQuery>(
                environment,
                load_lesson_blocks,
                fullVariables,
                { fetchPolicy: "store-or-network" },
            ).subscribe({
                complete: () => {
                    dispatchState({ input: "isRefetching", value: false });
                    refresh(fullVariables, { fetchPolicy: "store-only" });
                },
                error: () => {
                    dispatchState({ input: "isRefetching", value: false });
                },
            });
            return subscription;
        },
        [dispatchState, environment, profileId, refresh],
    );

    useEffect(() => {
        if (renderCount.current < 2) {
            renderCount.current += 1;
            return;
        }

        dispatchState({ input: "isRefetching", value: false });
        state.values.subscription?.unsubscribe();

        // fire refetch immediately when filters change
        dispatchState({
            input: "subscription",
            value: refetchLessonBlocks({
                startingYear: state.values.startingYear,
            }),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.values.startingYear]);

    useEffect(() => {
        if (renderCount.current < 2) {
            renderCount.current += 1;
            return;
        }

        dispatchState({ input: "isRefetching", value: false });
        state.values.subscription?.unsubscribe();

        // fire refresh rather than refetch (to hide current content) when changing tab
        refreshHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.values.currentPageIndex]);

    const renderItem = useCallback(
        ({
            item,
        }: {
            item: NonNullable<
                NonNullable<
                    NonNullable<
                        LoadLessonBlocks_query_lessonBlocks$data["lessonBlocks"]
                    >["edges"][0]
                >["node"]
            >;
        }): ReactElement | null => {
            return (
                <PupilLessonBlockRegister
                    data={item}
                    dispatchState={dispatchState}
                    navigation={navigation}
                    openLessonBlockOptionsHandler={
                        openLessonBlockOptionsHandler
                    }
                    registerUpdates={registerUpdates}
                    showUpdateBlockDetailsModalHandler={
                        showUpdateBlockDetailsModalHandler
                    }
                    sideBarWidth={sideBarWidth}
                    startingYear={state.values.startingYear}
                    updateRegisterState={updateRegisterState}
                />
            );
        },
        [
            dispatchState,
            updateRegisterState,
            showUpdateBlockDetailsModalHandler,
            openLessonBlockOptionsHandler,
            registerUpdates,
            navigation,
            sideBarWidth,
            state.values.startingYear,
        ],
    );

    const renderSectionHeader = useCallback(
        (info: {
            section: { key: number; title: string };
        }): ReactElement | null => {
            if (info.section.title !== "") {
                return (
                    <Text
                        fontSize="2xl"
                        pt={info.section.key !== 0 ? "6" : "4"}>
                        {BLOCKS[parseInt(info.section.title) - 1].label}
                    </Text>
                );
            } else {
                return null;
            }
        },
        [],
    );

    const renderHeader = useCallback(() => {
        return (
            <VStack
                borderBottomWidth={1}
                borderColor="surface.300"
                mx="-6"
                pb="4"
                px="6"
                space="4">
                <HStack space="4">
                    <Select
                        borderRadius="2xl"
                        fontSize="md"
                        onValueChange={(itemValue) => {
                            dispatchState({
                                input: "startingYear",
                                value: itemValue,
                            });
                            navigation.setParams({
                                startingYear: parseInt(itemValue),
                            });
                        }}
                        placeholder="Select year"
                        selectedValue={String(state.values.startingYear)}
                        width="40">
                        {startingYears.map((item) => {
                            return (
                                <Select.Item
                                    key={item.value}
                                    actionSheetLabel={item.label}
                                    value={String(item.value)}
                                />
                            );
                        })}
                    </Select>
                    <Button
                        bg="surface.400"
                        colorScheme="surface"
                        height="10"
                        leftIcon={<RestartIcon size="5" />}
                        onPress={refreshHandler}
                        width="10"
                    />
                </HStack>
            </VStack>
        );
    }, [
        dispatchState,
        navigation,
        refreshHandler,
        startingYears,
        state.values.startingYear,
    ]);

    const renderFooter = useCallback(() => {
        return isLoadingNext ? (
            <CustomFlatListSpinner position="relative" py="4" top="0" />
        ) : (
            <Box height="6" />
        );
    }, [isLoadingNext]);

    const renderListEmptyBanner = useCallback(() => {
        return (
            <ListEmptyBanner explainer={"No lessons found..."}>
                <Text fontSize="6xl">{emoji.getUnicode("neutral_face")}</Text>
            </ListEmptyBanner>
        );
    }, []);

    const renderRefetchIndicator = useCallback(() => {
        return state.values.isRefetching ? (
            <CustomFlatListSpinner top="150" />
        ) : null;
    }, [state.values.isRefetching]);

    useEffect(() => {
        dispatchState({ input: "contentIsRendered", value: true });
        // unsubscribe from refetch on unmount
        return () => state.values.subscription?.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadLessonBlocksQueryReference]);

    if (!state.values.contentIsRendered) {
        return <LoadingBlobs>Loading Registers...</LoadingBlobs>;
    }

    return (
        <Box flex={1}>
            {renderHeader()}
            <SectionList
                contentContainerStyle={{
                    flexGrow: 1,
                    paddingBottom: registerUpdates.current.length > 0 ? 58 : 0,
                }}
                flex={1}
                keyExtractor={(_, index) => index.toString()}
                ListEmptyComponent={renderListEmptyBanner}
                ListFooterComponent={renderFooter}
                onEndReached={onEndReached}
                onEndReachedThreshold={0.4}
                refreshing={false}
                renderItem={renderItem}
                renderSectionHeader={renderSectionHeader}
                sections={lessonBlocksSectioned}
                showsVerticalScrollIndicator={false}
            />
            {renderRefetchIndicator()}
        </Box>
    );
};

export default React.memo(PupilRegisters);
