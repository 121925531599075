import { graphql } from "react-relay";

export const generate_or_update_draft_invoice = graphql`
    mutation GenerateOrUpdateDraftInvoiceMutation(
        $connections: [ID!]!
        $input: GenerateOrUpdateDraftInvoiceInput!
    ) {
        generateOrUpdateDraftInvoice(input: $input) {
            success
            errors
            # delete invoice if updating an invoice which no longer has any lesson blocks
            deletedInvoiceId @deleteEdge(connections: $connections)
            invoice
                @prependNode(
                    connections: $connections
                    edgeTypeName: "InvoiceNodeEdge"
                ) {
                id
                invoiceNumber
                createdOn
                lastModified
                totalAmount
                amountPaid
                status
                notes
                staffNotes
                startingYear
                block
                assignedToFullName
                assignedToEmail
                assignedToPhoneNumber
                assignedToAccountNumber
                invoiceEmailSubject
                invoiceMessageString
                invoicePdfSignedUrl
                initialSentTimestamp
                reminder1SentTimestamp
                reminder2SentTimestamp
                reminder3SentTimestamp
                assignedTo {
                    id
                    accountNumber
                    user {
                        id
                        email
                        firstName
                        lastName
                    }
                }
                createdBy {
                    user {
                        id
                        email
                        firstName
                        lastName
                    }
                }
                lineItems {
                    edges {
                        node {
                            id
                            itemType
                            quantity
                            unitAmount
                            timePeriodDescription
                            pupilDescription
                            instrumentDescription
                            typeDescription
                            pupil {
                                id
                            }
                        }
                    }
                }
                payments {
                    __id
                    edges {
                        node {
                            id
                            amount
                            paymentType
                            paymentReference
                            paymentDate
                            paymentNote
                        }
                    }
                }
                otherInvoicesForBlock {
                    __id
                    edges {
                        node {
                            id
                            invoiceNumber
                            status
                            totalAmount
                            createdOn
                        }
                    }
                }
                lessonBlocks {
                    edges {
                        node {
                            id
                            instrument {
                                name
                            }
                            pupil {
                                id
                                user {
                                    firstName
                                    lastName
                                }
                            }
                            lessons {
                                totalCount
                            }
                            examFee
                            otherDiscountOrFee
                            lessonStage {
                                lessonType
                                lessonDuration
                                costPerLesson
                            }
                        }
                    }
                }
            }
        }
    }
`;
