/**
 * @generated SignedSource<<8f2c02cc4b1f369135765b05014b3ca3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PayslipModelStatus = "NOT_VIEWED" | "PAID" | "READY_TO_PAY" | "%future added value";
export type GeneratePayslipsInput = {
  clientMutationId?: string | null;
  dateFrom: string;
  dateTo: string;
  teacherIds: ReadonlyArray<string | null>;
};
export type GeneratePayslipsMutation$variables = {
  input: GeneratePayslipsInput;
};
export type GeneratePayslipsMutation$data = {
  readonly generatePayslips: {
    readonly errors: any | null;
    readonly payslips: ReadonlyArray<{
      readonly adjustedReason: string | null;
      readonly amountAdjusted: number | null;
      readonly amountDue: number | null;
      readonly id: string;
      readonly status: PayslipModelStatus;
    } | null> | null;
    readonly success: boolean | null;
  } | null;
};
export type GeneratePayslipsMutation = {
  response: GeneratePayslipsMutation$data;
  variables: GeneratePayslipsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GeneratePayslipsPayload",
    "kind": "LinkedField",
    "name": "generatePayslips",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PayslipNode",
        "kind": "LinkedField",
        "name": "payslips",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amountDue",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amountAdjusted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "adjustedReason",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GeneratePayslipsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GeneratePayslipsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "987faaa7641457e4e624c38b6f84fda2",
    "id": null,
    "metadata": {},
    "name": "GeneratePayslipsMutation",
    "operationKind": "mutation",
    "text": "mutation GeneratePayslipsMutation(\n  $input: GeneratePayslipsInput!\n) {\n  generatePayslips(input: $input) {\n    success\n    errors\n    payslips {\n      id\n      amountDue\n      status\n      amountAdjusted\n      adjustedReason\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "23dbecc69b5b1fe24fa81dff546061b1";

export default node;
