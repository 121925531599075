import React, { useMemo } from "react";
import type { ReactElement, ComponentProps } from "react";

import { differenceInMonths } from "date-fns";
import { HStack, Text } from "native-base";
import type { Icon } from "native-base";

import {
    TickIcon,
    CloseIcon,
    WarningIcon,
} from "pianofunclub-shared/components/Other/Icons";

type HStackProps = ComponentProps<typeof HStack>;
type TextProps = ComponentProps<typeof Text>;
type IconProps = ComponentProps<typeof Icon>;

interface Props extends HStackProps {
    dbsExpirationDate?: string | null;
    dbsNumber?: string | null;
    iconProps?: IconProps;
    isTransparent?: boolean;
    showLongText?: boolean;
    textProps?: TextProps;
}

const DbsStatus = (props: Props): ReactElement | null => {
    const {
        dbsExpirationDate,
        dbsNumber,
        iconProps,
        isTransparent,
        showLongText,
        textProps,
    } = props;

    const { color, endColor, icon, startColor, text, textColor } =
        useMemo(() => {
            const currentDate = new Date();
            if (dbsNumber && dbsExpirationDate) {
                const isExpired =
                    currentDate.getTime() >
                    new Date(dbsExpirationDate).getTime();
                const monthsTillExpiration = differenceInMonths(
                    new Date(dbsExpirationDate),
                    currentDate,
                );
                if (monthsTillExpiration > 6) {
                    return !isTransparent
                        ? {
                              text: `${
                                  showLongText ? "DBS " : ""
                              }Valid (${monthsTillExpiration} months)`,
                              startColor: "primary.400",
                              endColor: "primary.600",
                              textColor: "surface.50",
                              icon: (
                                  <TickIcon
                                      color="surface.50"
                                      size="6"
                                      {...iconProps}
                                  />
                              ),
                          }
                        : {
                              text: `${
                                  showLongText ? "DBS " : ""
                              }Valid (${monthsTillExpiration} months)`,
                              color: "transparent",
                              textColor: "primary.600",
                              icon: (
                                  <TickIcon
                                      color="primary.600"
                                      size="6"
                                      {...iconProps}
                                  />
                              ),
                          };
                } else if (!isExpired) {
                    return !isTransparent
                        ? {
                              text: `${
                                  showLongText ? "DBS " : ""
                              }Valid (${monthsTillExpiration} months)`,
                              startColor: "orange.300",
                              endColor: "orange.500",
                              textColor: "surface.50",
                              icon: (
                                  <TickIcon
                                      color="surface.50"
                                      size="6"
                                      {...iconProps}
                                  />
                              ),
                          }
                        : {
                              text: `${
                                  showLongText ? "DBS " : ""
                              }Valid (${monthsTillExpiration} months)`,
                              color: "transparent",
                              textColor: "orange.500",
                              icon: (
                                  <TickIcon
                                      color="orange.500"
                                      size="6"
                                      {...iconProps}
                                  />
                              ),
                          };
                } else {
                    return !isTransparent
                        ? {
                              text: `${showLongText ? "DBS " : ""}Expired`,
                              startColor: "red.300",
                              endColor: "red.500",
                              textColor: "surface.50",
                              icon: (
                                  <CloseIcon
                                      color="surface.50"
                                      size="6"
                                      {...iconProps}
                                  />
                              ),
                          }
                        : {
                              text: `${showLongText ? "DBS " : ""}Expired`,
                              color: "transparent",
                              textColor: "red.500",
                              icon: (
                                  <CloseIcon
                                      color="red.500"
                                      size="6"
                                      {...iconProps}
                                  />
                              ),
                          };
                }
            } else {
                return !isTransparent
                    ? {
                          text: "No DBS",
                          startColor: "surface.600",
                          endColor: "surface.800",
                          textColor: "surface.50",
                          icon: (
                              <WarningIcon
                                  color="surface.50"
                                  size="6"
                                  {...iconProps}
                              />
                          ),
                      }
                    : {
                          text: "No DBS",
                          color: "transparent",
                          textColor: "surface.800",
                          icon: (
                              <WarningIcon
                                  color="surface.800"
                                  size="6"
                                  {...iconProps}
                              />
                          ),
                      };
            }
        }, [
            dbsExpirationDate,
            dbsNumber,
            iconProps,
            isTransparent,
            showLongText,
        ]);

    return (
        <HStack
            bg={
                startColor && endColor
                    ? {
                          linearGradient: {
                              colors: [startColor, endColor],
                              start: [0.25, 0],
                              end: [0.75, 1],
                          },
                      }
                    : color
            }
            borderRadius="2xl"
            pl="3"
            pr="4"
            py="2"
            space="2"
            {...props}
        >
            {icon}
            <Text color={textColor} fontSize="md" {...textProps}>
                {text}
            </Text>
        </HStack>
    );
};

export default DbsStatus;
