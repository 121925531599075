/**
 * @generated SignedSource<<bd4d7d8475d8fb9ea8e20c6569648699>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InvoicingHeadlineDataRefreshQuery$variables = {
  block: number;
  startingYear: number;
};
export type InvoicingHeadlineDataRefreshQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"InvoicingHeadlineDataFragment_query">;
};
export type InvoicingHeadlineDataRefreshQuery = {
  response: InvoicingHeadlineDataRefreshQuery$data;
  variables: InvoicingHeadlineDataRefreshQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "block"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startingYear"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "block",
    "variableName": "block"
  },
  {
    "kind": "Variable",
    "name": "startingYear",
    "variableName": "startingYear"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InvoicingHeadlineDataRefreshQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "InvoicingHeadlineDataFragment_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InvoicingHeadlineDataRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DjangoUserNode",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": (v1/*: any*/),
                "kind": "ScalarField",
                "name": "totalInvoices",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "kind": "ScalarField",
                "name": "totalAmountInvoiced",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "kind": "ScalarField",
                "name": "totalInvoicesSent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "kind": "ScalarField",
                "name": "totalAmountPaid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "kind": "ScalarField",
                "name": "totalInvoicesUnpaid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "kind": "ScalarField",
                "name": "percentLessonBlocksInvoicedFor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "LessonBlockNode",
                "kind": "LinkedField",
                "name": "lessonBlocksNotInvoicedFor",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SchoolNode",
                    "kind": "LinkedField",
                    "name": "school",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProfileNode",
                    "kind": "LinkedField",
                    "name": "pupil",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "schoolYear",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DjangoUserNode",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "03fd7ed537cbf4c63b493457f6c64ca9",
    "id": null,
    "metadata": {},
    "name": "InvoicingHeadlineDataRefreshQuery",
    "operationKind": "query",
    "text": "query InvoicingHeadlineDataRefreshQuery(\n  $block: Int!\n  $startingYear: Int!\n) {\n  ...InvoicingHeadlineDataFragment_query_UP0N6\n}\n\nfragment InvoicingHeadlineDataFragment_query_UP0N6 on Query {\n  me {\n    profile {\n      id\n      totalInvoices(startingYear: $startingYear, block: $block)\n      totalAmountInvoiced(startingYear: $startingYear, block: $block)\n      totalInvoicesSent(startingYear: $startingYear, block: $block)\n      totalAmountPaid(startingYear: $startingYear, block: $block)\n      totalInvoicesUnpaid(startingYear: $startingYear, block: $block)\n      percentLessonBlocksInvoicedFor(startingYear: $startingYear, block: $block)\n      lessonBlocksNotInvoicedFor(startingYear: $startingYear, block: $block) {\n        id\n        school {\n          name\n          id\n        }\n        pupil {\n          id\n          schoolYear\n          user {\n            id\n            firstName\n            lastName\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c5b61569f91243b6d16c91b92e506c7c";

export default node;
