import { graphql } from "react-relay";

export const delete_payslip = graphql`
    mutation DeletePayslipMutation(
        $connections: [ID!]!
        $input: DeletePayslipInput!
    ) {
        deletePayslip(input: $input) {
            success
            errors
            deletedPayslipId @deleteEdge(connections: $connections)
        }
    }
`;
