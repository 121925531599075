import { graphql } from "react-relay";

export const load_enrolments = graphql`
    query LoadEnrolmentsQuery(
        $searchTerm: String
        $orderBy: String
        $school: String
        $pupil: String
        $lessonStage: String
        $schoolYear: String
        $parentEmail: String
        $instrument: String
        $first: Int!
        $after: String
    ) {
        ...LoadEnrolments_query_enrolments
            @arguments(
                orderBy: $orderBy
                searchTerm: $searchTerm
                pupil: $pupil
                school: $school
                lessonStage: $lessonStage
                schoolYear: $schoolYear
                parentEmail: $parentEmail
                instrument: $instrument
                first: $first
                after: $after
            )
    }
`;

export const load_enrolments_pagination = graphql`
    fragment LoadEnrolments_query_enrolments on Query
    @argumentDefinitions(
        searchTerm: { type: "String" }
        orderBy: { type: "String" }
        school: { type: "String" }
        pupil: { type: "String" }
        lessonStage: { type: "String" }
        schoolYear: { type: "String" }
        parentEmail: { type: "String" }
        instrument: { type: "String" }
        first: { type: "Int!" }
        after: { type: "String" }
    )
    @refetchable(queryName: "LoadEnrolmentsPaginationQuery") {
        enrolments(
            orderBy: $orderBy
            searchTerm: $searchTerm
            pupil: $pupil
            school: $school
            lessonStage: $lessonStage
            schoolYear: $schoolYear
            parentEmail: $parentEmail
            instrument: $instrument
            first: $first
            after: $after
        ) @connection(key: "LoadEnrolments_query_enrolments") {
            __id
            edges {
                node {
                    id
                    schoolName
                    schoolBorough
                    schoolPostcode
                    pupilFirstName
                    pupilLastName
                    schoolYear
                    schoolClass
                    instrument
                    paidBySchool
                    premiumPupil
                    discountPercentage
                    teacherEmail
                    lessonDay
                    lessonStartTime
                    lessonStage
                    lessonType
                    lessonDuration
                    costPerLesson
                    numberOfLessons
                    parentName
                    parentPhoneNumber
                    pupilNotes
                    lessonBlockNotes
                    positionInWaitingList
                    addedOn
                    enroled
                    offered
                    offeredOn
                    adminNotes
                    sentWaitingListEmail
                }
            }
        }
    }
`;
