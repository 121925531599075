import React, { useMemo } from "react";
import type { ReactElement, ComponentProps } from "react";

import { Box, HStack, Text } from "native-base";

import { getLessonStatuses } from "../../utils/extractors";

type HStackProps = ComponentProps<typeof HStack>;

interface Props extends HStackProps {
    hasRegisterNote?: boolean;
    hideBlankIcon?: boolean;
    hideDropdownIcon?: boolean;
    hideLabel?: boolean;
    isRearranged?: boolean;
    status?: string;
}

const LessonStatus = (props: Props): ReactElement | null => {
    const {
        hasRegisterNote,
        hideBlankIcon,
        hideDropdownIcon,
        hideLabel,
        isRearranged,
        status = "NO_LESSON",
    } = props;

    const {
        color,
        icon,
        label: text,
        textColor,
    } = useMemo(() => {
        return getLessonStatuses({
            iconProps: {
                mr: "2",
            },
            blankText: "",
            useShortLabels: true,
            showBlankIcon: !hideBlankIcon,
        }).filter((item) => item.value === status)[0];
    }, [status, hideBlankIcon]);

    if (status) {
        return (
            <HStack
                alignItems="center"
                bg={color}
                flex={1}
                height="100%"
                justifyContent="center"
                px="1"
                width="100%"
                {...props}
            >
                {icon ?? null}
                {!hideLabel ? (
                    <Text
                        color={textColor}
                        fontFamily="Poppins-Regular"
                        fontSize="md"
                    >
                        {text}
                    </Text>
                ) : null}
                {!hideDropdownIcon && status !== "BLANK" ? (
                    <Box
                        bg="transparent"
                        borderBottomColor="surface.500"
                        borderBottomWidth="9"
                        borderLeftColor="transparent"
                        borderLeftWidth="6"
                        borderRightColor="transparent"
                        borderRightWidth="6"
                        borderStyle="solid"
                        position="absolute"
                        right="2"
                        style={{ transform: [{ rotate: "180deg" }] }}
                    />
                ) : null}
                {hasRegisterNote ? (
                    <Box
                        bg="transparent"
                        borderRightColor="transparent"
                        borderRightWidth="10"
                        borderStyle="solid"
                        borderTopColor="primary.500"
                        borderTopWidth="10"
                        height="0"
                        position="absolute"
                        right="0"
                        style={{ transform: [{ rotate: "90deg" }] }}
                        top="0"
                        width="0"
                    />
                ) : null}
                {isRearranged ? (
                    <Box
                        bg="transparent"
                        borderLeftColor="secondary.600"
                        borderLeftWidth="10"
                        borderStyle="solid"
                        borderTopColor="transparent"
                        borderTopWidth="10"
                        height="0"
                        left="0"
                        position="absolute"
                        style={{ transform: [{ rotate: "90deg" }] }}
                        top="0"
                        width="0"
                    />
                ) : null}
            </HStack>
        );
    } else {
        return null;
    }
};

export default React.memo(LessonStatus);
