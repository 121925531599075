import React, {
    Suspense,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import type { ReactElement } from "react";

import { Box, Button, Center, HStack, Modal, Text, VStack } from "native-base";
import { Platform } from "react-native";
import { usePreloadedQuery, useQueryLoader } from "react-relay";
import type { PreloadedQuery } from "react-relay";

import type { LoadLessonStagesQuery } from "pianofunclub-shared/relay/graphql/schools/__generated__/LoadLessonStagesQuery.graphql";
import { load_lesson_stages } from "pianofunclub-shared/relay/graphql/schools/LoadLessonStages";

import { NO_STAGE } from "../../utils/constants";
import ButtonDebounced from "../Buttons/ButtonDebounced";
import TextInput from "../Inputs/TextInput";

import type { LessonStageData } from "pianofunclub-crm/components/Tabs/SchoolRates";

import LoadingBlobs from "pianofunclub-shared/components/Animations/LoadingBlobs";
import {
    DrumsIcon,
    GuitarIcon,
    MusicTheoryIcon,
    PianoIcon,
    RecorderIcon,
    UkeleleIcon,
    ViolinIcon,
} from "pianofunclub-shared/components/Other/Icons";

import { titleCaseConverter } from "pianofunclub-shared/utils/converters";

interface Props {
    detailType?: "STAGE" | "INSTRUMENT" | "NOTES";
    hideModal: () => void;
    lessonBlockCursor?: string;
    lessonBlockIds?: string[];
    lessonStagesData?: LessonStageData;
    loadLessonStagesQueryReference: PreloadedQuery<
        LoadLessonStagesQuery,
        Record<string, unknown>
    >;
    onUpdate?: () => void;
    showModal: boolean;
    updateLessonBlockDetails: (
        variables: {
            instrument?: string;
            keepFree?: boolean;
            lessonBlockIds: string[];
            pupilId?: string | null;
            staffNoteForTeacher?: string;
            stageId?: string;
            teacherId?: string | null;
            teacherNoteForStaff?: string;
        },
        lessonBlockCursor?: string,
        onComplete?: () => void,
    ) => void;
    updateLessonBlockDetailsInFlight: boolean;
}

interface WrapperProps extends Omit<Props, "loadLessonStagesQueryReference"> {
    block?: number;
    school?: string;
    showModal: boolean;
    startingYear?: number;
}

const UpdateLessonBlockDetailsModal = (props: Props): ReactElement | null => {
    const {
        detailType,
        hideModal,
        lessonBlockCursor,
        lessonBlockIds,
        lessonStagesData,
        loadLessonStagesQueryReference,
        onUpdate,
        showModal,
        updateLessonBlockDetails,
        updateLessonBlockDetailsInFlight,
    } = props;

    const lessonStagesQueryData = usePreloadedQuery(
        load_lesson_stages,
        loadLessonStagesQueryReference,
    );

    const lessonStages = useMemo(() => {
        return (
            lessonStagesData ?? lessonStagesQueryData.lessonStages?.edges ?? []
        );
    }, [lessonStagesData, lessonStagesQueryData.lessonStages?.edges]);

    const [teacherNoteForStaff, setTeacherNoteForStaff] = useState("");
    const [staffNoteForTeacher, setStaffNoteForTeacher] = useState("");
    const teacherNoteForStaffInputRef = useRef<{
        focus: () => void;
        setValue: (text: string, isValid: boolean) => void;
    }>();
    const staffNoteForTeacherInputRef = useRef<{
        focus: () => void;
        setValue: (text: string, isValid: boolean) => void;
    }>();

    const updateLessonBlockDetailsHandler = useCallback(
        (variables: {
            instrument?: string;
            staffNoteForTeacher?: string;
            stageId?: string;
            startingLesson?: number;
            teacherNoteForStaff?: string;
        }) => {
            if (lessonBlockIds && lessonBlockIds.length > 0) {
                updateLessonBlockDetails(
                    {
                        ...variables,
                        lessonBlockIds: lessonBlockIds,
                    },
                    lessonBlockCursor,
                    () => {
                        onUpdate?.();
                        detailType === "NOTES" ? hideModal() : undefined;
                    },
                );
            }
            if (detailType !== "NOTES") {
                hideModal();
            }
        },
        [
            lessonBlockIds,
            detailType,
            updateLessonBlockDetails,
            lessonBlockCursor,
            onUpdate,
            hideModal,
        ],
    );

    const renderInstrumentPicker = useMemo(() => {
        return (
            <VStack alignItems="center" space="8">
                <HStack space="8">
                    <Button
                        _stack={{ flexDirection: "column", space: 2 }}
                        borderRadius="full"
                        leftIcon={<PianoIcon size={16} />}
                        onPress={() => {
                            updateLessonBlockDetailsHandler({
                                instrument: "piano",
                            });
                        }}
                        size={32}
                    >
                        Piano
                    </Button>
                    <Button
                        _stack={{ flexDirection: "column", space: 2 }}
                        borderRadius="full"
                        leftIcon={<GuitarIcon size={16} />}
                        onPress={() => {
                            updateLessonBlockDetailsHandler({
                                instrument: "guitar",
                            });
                        }}
                        size={32}
                    >
                        Guitar
                    </Button>
                </HStack>
                <HStack space="8">
                    <Button
                        _stack={{ flexDirection: "column", space: 2 }}
                        borderRadius="full"
                        leftIcon={<ViolinIcon size={16} />}
                        onPress={() => {
                            updateLessonBlockDetailsHandler({
                                instrument: "violin",
                            });
                        }}
                        size={32}
                    >
                        Violin
                    </Button>
                    <Button
                        _stack={{ flexDirection: "column", space: 2 }}
                        borderRadius="full"
                        leftIcon={<DrumsIcon size={16} />}
                        onPress={() => {
                            updateLessonBlockDetailsHandler({
                                instrument: "drums",
                            });
                        }}
                        size={32}
                    >
                        Drums
                    </Button>
                </HStack>
                <HStack space="8">
                    <Button
                        _stack={{ flexDirection: "column", space: 2 }}
                        borderRadius="full"
                        leftIcon={<RecorderIcon size={16} />}
                        onPress={() => {
                            updateLessonBlockDetailsHandler({
                                instrument: "recorder",
                            });
                        }}
                        size={32}
                    >
                        Recorder
                    </Button>
                    <Button
                        _stack={{ flexDirection: "column", space: 2 }}
                        borderRadius="full"
                        leftIcon={<UkeleleIcon size={16} />}
                        onPress={() => {
                            updateLessonBlockDetailsHandler({
                                instrument: "ukelele",
                            });
                        }}
                        size={32}
                    >
                        Ukelele
                    </Button>
                </HStack>
                <HStack space="8">
                    <Button
                        _stack={{ flexDirection: "column", space: 2 }}
                        borderRadius="full"
                        leftIcon={
                            <Center size={16}>
                                <MusicTheoryIcon color="white" size={12} />
                            </Center>
                        }
                        onPress={() => {
                            updateLessonBlockDetailsHandler({
                                instrument: "theory",
                            });
                        }}
                        size={32}
                    >
                        Theory
                    </Button>
                </HStack>
            </VStack>
        );
    }, [updateLessonBlockDetailsHandler]);

    const renderStagePicker = useMemo(() => {
        return (
            <HStack
                flexWrap="wrap"
                justifyContent="center"
                m="-4"
                minHeight="420"
                p="4"
            >
                {lessonStages.length > 0 ? (
                    lessonStages.map((item, index) => {
                        if (item?.node) {
                            return (
                                <Button
                                    key={index}
                                    borderRadius="full"
                                    m="4"
                                    onPress={() => {
                                        if (item?.node) {
                                            updateLessonBlockDetailsHandler({
                                                stageId: item.node.id,
                                            });
                                        }
                                    }}
                                    size={40}
                                >
                                    <VStack
                                        alignItems="center"
                                        mb="2"
                                        space="1"
                                    >
                                        <Text color="white" fontSize="xl">
                                            {item.node.stage !== NO_STAGE
                                                ? `Stage ${item.node.stage}`
                                                : "Any Stage"}
                                        </Text>
                                        <Text color="white">
                                            {`${
                                                item.node.lessonType ===
                                                "INDIVIDUAL"
                                                    ? "1-on-1"
                                                    : item.node.lessonType ===
                                                        "PAIRED"
                                                      ? "Paired"
                                                      : "Group"
                                            } • ${
                                                item.node.lessonDuration !==
                                                null
                                                    ? `${item.node.lessonDuration} mins`
                                                    : "No duration"
                                            }`}
                                        </Text>
                                        <Text color="white">
                                            {item.node.costPerLesson !== null
                                                ? `£${item.node.costPerLesson} per lesson`
                                                : "Cost not set"}
                                        </Text>
                                    </VStack>
                                </Button>
                            );
                        } else {
                            return <></>;
                        }
                    })
                ) : (
                    <LoadingBlobs
                        bg="transparent"
                        borderBottomLeftRadius="0"
                        textProps={{ color: "white" }}
                    >
                        Loading Stages
                    </LoadingBlobs>
                )}
            </HStack>
        );
    }, [lessonStages, updateLessonBlockDetailsHandler]);

    const renderNotesInput = useMemo(() => {
        return (
            <VStack alignItems="center" space="8">
                <Box>
                    <TextInput
                        ref={teacherNoteForStaffInputRef}
                        blurOnSubmit
                        centerLabel
                        fontFamily="Poppins-Regular"
                        id="teacherNoteForStaff"
                        initialValue={teacherNoteForStaff}
                        keyboardType="default"
                        label="Teacher Note"
                        maxLength={750}
                        multiline
                        numberOfLines={4}
                        onInputChange={(_, inputValue) =>
                            setTeacherNoteForStaff(inputValue ?? "")
                        }
                        placeholder={"The teacher has not left a note."}
                        textAlign="center"
                        textAlignVertical="top"
                        width={Platform.select({
                            web: undefined,
                            default: "50%",
                        })}
                    />
                </Box>
                <Box>
                    <TextInput
                        ref={staffNoteForTeacherInputRef}
                        blurOnSubmit
                        centerLabel
                        fontFamily="Poppins-Regular"
                        id="staffNoteForTeacher"
                        initialValue={staffNoteForTeacher}
                        keyboardType="default"
                        label="Staff Note"
                        maxLength={750}
                        multiline
                        numberOfLines={4}
                        onInputChange={(_, inputValue) =>
                            setStaffNoteForTeacher(inputValue ?? "")
                        }
                        onSubmit={() =>
                            updateLessonBlockDetailsHandler({
                                teacherNoteForStaff: teacherNoteForStaff,
                                staffNoteForTeacher: staffNoteForTeacher,
                            })
                        }
                        placeholder={
                            "Leave a note for the teacher. It will appear in the Google Sheet register under 'Staff Notes'"
                        }
                        textAlign="center"
                        textAlignVertical="top"
                        width={Platform.OS !== "web" ? "50%" : undefined}
                    />
                </Box>
                <ButtonDebounced
                    _text={{ fontSize: "xl" }}
                    height="56px"
                    isLoading={updateLessonBlockDetailsInFlight}
                    mb="4"
                    mt="4"
                    onPress={() =>
                        updateLessonBlockDetailsHandler({
                            teacherNoteForStaff: teacherNoteForStaff,
                            staffNoteForTeacher: staffNoteForTeacher,
                        })
                    }
                    width="160px"
                >
                    {"Update"}
                </ButtonDebounced>
            </VStack>
        );
    }, [
        staffNoteForTeacher,
        teacherNoteForStaff,
        updateLessonBlockDetailsHandler,
        updateLessonBlockDetailsInFlight,
    ]);

    return (
        <Modal
            isOpen={showModal}
            onClose={() => {
                hideModal();
            }}
            size="xl"
        >
            <Modal.Content bg="primary.800">
                <Modal.CloseButton
                    _hover={{ bg: "transparent", opacity: 0.7 }}
                    _icon={{ color: "surface.100" }}
                    _pressed={{ bg: "transparent", opacity: 0.7 }}
                />
                <Modal.Header _text={{ color: "surface.100" }} bg="primary.800">
                    {detailType === "NOTES"
                        ? "Add Notes"
                        : `Select ${titleCaseConverter(detailType)}`}
                </Modal.Header>
                <Modal.Body alignItems="center" mb="8" px="6">
                    {detailType === "INSTRUMENT"
                        ? renderInstrumentPicker
                        : detailType === "STAGE"
                          ? renderStagePicker
                          : detailType === "NOTES"
                            ? renderNotesInput
                            : null}
                </Modal.Body>
            </Modal.Content>
        </Modal>
    );
};

const UpdateLessonBlockDetailsModalWrapper = (
    props: WrapperProps,
): ReactElement => {
    const { block, detailType, lessonStagesData, school, startingYear } = props;

    const [loadLessonStagesQueryReference, loadLessonStagesQuery] =
        useQueryLoader<LoadLessonStagesQuery>(load_lesson_stages);

    useEffect(() => {
        loadLessonStagesQuery(
            {
                school: school,
                startingYear: startingYear ?? 0,
                block: block ?? 0,
                skip:
                    Boolean(lessonStagesData) ||
                    !startingYear ||
                    !block ||
                    !school,
            },
            { fetchPolicy: "store-or-network" },
        );
    }, [loadLessonStagesQuery, block, lessonStagesData, startingYear, school]);

    return loadLessonStagesQueryReference != null && detailType != null ? (
        <Suspense fallback={<></>}>
            <UpdateLessonBlockDetailsModal
                loadLessonStagesQueryReference={loadLessonStagesQueryReference}
                {...props}
            />
        </Suspense>
    ) : (
        <></>
    );
};

export default React.memo(UpdateLessonBlockDetailsModalWrapper);
