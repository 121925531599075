import { graphql } from "react-relay";

export const archive_pupils = graphql`
    mutation ArchivePupilsMutation($input: ArchivePupilsInput!) {
        archivePupils(input: $input) {
            success
            errors
            archivedPupils {
                id
                status
            }
            archivedParents {
                id
                status
            }
            vacatedLessonBlocks {
                id
                pupil {
                    id
                }
            }
        }
    }
`;
