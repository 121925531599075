import React, {
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
    Suspense,
} from "react";
import type { FC, Dispatch } from "react";

import { useIsFocused } from "@react-navigation/native";
import type { NavigationAction } from "@react-navigation/native";
import {
    Box,
    Button,
    Heading,
    HStack,
    PresenceTransition,
    ScrollView,
    useToast,
} from "native-base";
import { useBeforeunload } from "react-beforeunload";
import { useWindowDimensions } from "react-native";
import { DateTimePickerModal } from "react-native-paper-dates";
import { useQueryLoader, useMutation, usePreloadedQuery } from "react-relay";
import type { PreloadedQuery } from "react-relay";
import type { Subscription } from "relay-runtime";

import { useAuth } from "pianofunclub-shared/providers/AuthProvider";
import { useData } from "pianofunclub-shared/providers/DataProvider";

import LoadingBlobs from "pianofunclub-shared/components/Animations/LoadingBlobs";
import ButtonDebounced from "pianofunclub-shared/components/Buttons/ButtonDebounced";
import type { TimetableLessonInfo } from "pianofunclub-shared/components/Modals/LessonDetailsModal";
import type { RegisterUpdate } from "pianofunclub-shared/components/Modals/ManageRegisterLessonModal";
import type { LessonInfo as RegisterLessonInfo } from "pianofunclub-shared/components/Modals/ManageRegisterLessonModal";
import UpdateLessonBlockDetailsModal from "pianofunclub-shared/components/Modals/UpdateLessonBlockDetailsModal";
import AlertPopup from "pianofunclub-shared/components/NativeBaseExtended/AlertPopup";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import {
    CompleteIcon,
    RegisterIcon,
} from "pianofunclub-shared/components/Other/Icons";
import TeacherRegisters from "pianofunclub-shared/components/Registers/TeacherRegisters";
import type { RegisterProgress } from "pianofunclub-shared/components/Registers/TeacherRegisters";

import type { LoadLessonBlocksQuery } from "pianofunclub-shared/relay/graphql/registers/__generated__/LoadLessonBlocksQuery.graphql";
import type { LoadTeachingDaysQuery } from "pianofunclub-shared/relay/graphql/registers/__generated__/LoadTeachingDaysQuery.graphql";
import type {
    RescheduleLessonMutation,
    RescheduleLessonMutation$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/RescheduleLessonMutation.graphql";
import type {
    UpdateLessonBlockDetailsMutation,
    UpdateLessonBlockDetailsMutation$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/UpdateLessonBlockDetailsMutation.graphql";
import type {
    UpdateRegistersMutation,
    UpdateRegistersMutation$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/UpdateRegistersMutation.graphql";
import { load_lesson_blocks } from "pianofunclub-shared/relay/graphql/registers/LoadLessonBlocks";
import { load_teaching_days } from "pianofunclub-shared/relay/graphql/registers/LoadTeachingDays";
import { reschedule_lesson } from "pianofunclub-shared/relay/graphql/registers/RescheduleLesson";
import { update_lesson_block_details } from "pianofunclub-shared/relay/graphql/registers/UpdateLessonBlockDetails";
import { update_registers } from "pianofunclub-shared/relay/graphql/registers/UpdateRegisters";

import type { UpdateBlockDetailsModalInfo } from "pianofunclub-shared/types";
import { isNavigationAction } from "pianofunclub-shared/types/guards";
import { getScaledWindowDimension } from "pianofunclub-shared/utils/converters";
import {
    getCurrentBlock,
    getDefaultStartingYearAndStartingYearOptions,
} from "pianofunclub-shared/utils/extractors";
import { createReducer } from "pianofunclub-shared/utils/reducers";
import type { Action, State } from "pianofunclub-shared/utils/reducers";

import type {
    TeacherRegistersStackNavigatorProps,
    TeacherRegistersStackRouteProps,
} from "pianofunclub-crm/navigation/TeacherRegistersNavigator";

export type NavigationProps =
    TeacherRegistersStackNavigatorProps<"TeacherRegistersHub">;

type RouteProps = TeacherRegistersStackRouteProps<"TeacherRegistersHub">;

interface ScreenProps {
    navigation: NavigationProps;
    route: RouteProps;
}

interface ContentProps {
    dispatchState: Dispatch<Action<ReducerValues, ReducerTypes>>;
    loadTeachingDaysQueryReference: PreloadedQuery<
        LoadTeachingDaysQuery,
        Record<string, unknown>
    >;
    navigation: NavigationProps;
    startingYears: {
        label: string;
        value: number;
    }[];
    state: State<ReducerValues>;
}

export type ReducerValues = {
    block: number;
    contentIsRendered: boolean;
    dateTimePickerModalDate?: Date;
    dateTimePickerModalLessonDuration?: number;
    dateTimePickerModalLessonId?: string;
    dayIndex: number;
    isRefetching: boolean;
    leaveAlertAction?: NavigationAction | (() => void);
    registerUpdater: number;
    school?: string;
    searchTerm: string;
    startingYear: number;
    subscription?: Subscription;
    teachingDays?: readonly (number | null)[] | undefined;
    updateBlockDetailsModalInfo?: UpdateBlockDetailsModalInfo;
};

export type ReducerTypes =
    | string
    | number
    | boolean
    | Subscription
    | Date
    | UpdateBlockDetailsModalInfo
    | TimetableLessonInfo
    | RegisterLessonInfo
    | NavigationAction
    | (() => void)
    | readonly (number | null)[]
    | undefined;

export const LOAD_X_LESSON_BLOCKS = 20;

const TeacherRegistersHubContent: FC<ContentProps> = (props) => {
    const {
        dispatchState,
        loadTeachingDaysQueryReference,
        navigation,
        startingYears,
        state,
    } = props;

    const teachingDaysData = usePreloadedQuery(
        load_teaching_days,
        loadTeachingDaysQueryReference,
    );

    useEffect(() => {
        if (teachingDaysData.me?.profile?.teachingDays) {
            // initialise local state
            // day index defaults to current day, but adjust if teacher
            // doesn't teach on current day
            dispatchState({
                input: "dayIndex",
                value:
                    teachingDaysData.me.profile.teachingDays.length > 0 &&
                    !teachingDaysData.me?.profile?.teachingDays.includes(
                        state.values.dayIndex,
                    )
                        ? (teachingDaysData.me?.profile?.teachingDays[0] ??
                          state.values.dayIndex)
                        : state.values.dayIndex,
            });
            dispatchState({
                input: "teachingDays",
                value: teachingDaysData.me.profile.teachingDays ?? undefined,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchState, teachingDaysData.me?.profile?.teachingDays]);

    const { user } = useAuth();

    const [loadLessonBlocksQueryReference, loadLessonBlocksQuery] =
        useQueryLoader<LoadLessonBlocksQuery>(load_lesson_blocks);

    useEffect(() => {
        if (user?.profile?.id && !state.values.contentIsRendered) {
            loadLessonBlocksQuery(
                {
                    first: LOAD_X_LESSON_BLOCKS,
                    profileId: user.profile.id,
                    searchTerm: state.values.searchTerm,
                    startingYear: state.values.startingYear,
                    block: state.values.block,
                    school: state.values.school,
                    dayIndex: state.values.dayIndex,
                    skipLessonBlocks: false,
                    skipRegisterProgress: false,
                    skipLessonStages: true,
                },
                { fetchPolicy: "store-or-network" },
            );
        }
    }, [
        user?.profile?.id,
        loadLessonBlocksQuery,
        state.values.contentIsRendered,
        state.values.searchTerm,
        state.values.startingYear,
        state.values.block,
        state.values.school,
        state.values.dayIndex,
    ]);

    const { dataState } = useData();

    const allSchools = useMemo(() => {
        return dataState.values.schools?.edges ?? [];
    }, [dataState.values.schools?.edges]);

    const teacherSchools = useMemo(() => {
        return user?.profile?.schools;
    }, [user?.profile?.schools]);

    const safeLeaveRef = useRef(null);
    // use refs rather than state to track these, so that updates work properly
    // when run from nested children
    const registerUpdates = useRef<RegisterUpdate[]>([]);
    const registerProgressCounter = useRef<RegisterProgress>({
        marked: undefined,
        total: undefined,
    });

    // if unsaved updates have been made to registers, show an alert
    useEffect(() => {
        const unsubscribe = navigation.addListener("beforeRemove", (e) => {
            if (registerUpdates.current.length === 0) {
                return;
            }

            e.preventDefault();
            dispatchState({
                input: "leaveAlertAction",
                value: e.data.action,
            });
        });

        return () => unsubscribe();
    }, [dispatchState, navigation, registerUpdates.current.length]);

    // trigger a popup if the CRM is unloaded
    useBeforeunload((e) => {
        if (registerUpdates.current.length === 0) {
            return;
        }

        e.preventDefault();
    });

    const toast = useToast();

    const [commitUpdateRegisters, updateRegistersInFlight] =
        useMutation<UpdateRegistersMutation>(update_registers);

    const updateRegisters = useCallback(
        (
            variables: {
                lessonId: string;
                registerNote?: string;
                status: string;
            }[],
            onComplete?: () => void,
        ) => {
            const updateRegistersConfig = {
                variables: {
                    input: { updates: variables },
                },
                onCompleted: (response: UpdateRegistersMutation$data) => {
                    if (response?.updateRegisters?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={"Register updated"}
                                    toast={toast}
                                />
                            ),
                        });
                        onComplete?.();
                        registerUpdates.current = [];
                        dispatchState({
                            input: "registerUpdater",
                            value: Math.random(),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't update register"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitUpdateRegisters(updateRegistersConfig);
        },
        [commitUpdateRegisters, dispatchState, toast],
    );

    const commitRescheduleLesson =
        useMutation<RescheduleLessonMutation>(reschedule_lesson)[0];

    const rescheduleLesson = useCallback(
        (
            lessonId: string,
            rearrangedDatetime: Date,
            lessonDuration?: number,
        ) => {
            const rescheduleLessonConfig = {
                variables: {
                    input: {
                        lessonId: lessonId,
                        rearrangedDatetime: String(rearrangedDatetime),
                        lessonDuration: lessonDuration,
                    },
                },
                optimsticResponse: {
                    rescheduleLesson: {
                        success: true,
                        errors: null,
                        lesson: {
                            id: lessonId,
                            rearrangedTimestamp: rearrangedDatetime,
                        },
                    },
                },
                onCompleted: (response: RescheduleLessonMutation$data) => {
                    if (response?.rescheduleLesson?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={"Rescheduled lesson"}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't reschedule lesson"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitRescheduleLesson(rescheduleLessonConfig);
        },
        [commitRescheduleLesson, toast],
    );

    const [commitUpdateLessonBlockDetails, updateLessonBlockDetailsInFlight] =
        useMutation<UpdateLessonBlockDetailsMutation>(
            update_lesson_block_details,
        );

    const updateLessonBlockDetails = useCallback(
        (
            variables: {
                instrument?: string;
                keepFree?: boolean;
                lessonBlockIds: string[];
                pupilId?: string | null;
                staffNoteForTeacher?: string;
                stageId?: string;
                teacherId?: string | null;
                teacherNoteForStaff?: string;
            },
            _?: string,
            onComplete?: () => void,
        ) => {
            const updateLessonBlockDetailsConfig = {
                variables: {
                    connections: [],
                    input: variables,
                    startingYear: state.values.startingYear,
                    block: state.values.block,
                },
                onCompleted: (
                    response: UpdateLessonBlockDetailsMutation$data,
                ) => {
                    if (response?.updateLessonBlockDetails?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={`Updated lesson block${
                                        variables.lessonBlockIds.length > 1
                                            ? "s"
                                            : ""
                                    }`}
                                    toast={toast}
                                />
                            ),
                        });
                        onComplete?.();
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={`Couldn't update lesson block${
                                        variables.lessonBlockIds.length > 1
                                            ? "s"
                                            : ""
                                    }`}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitUpdateLessonBlockDetails(updateLessonBlockDetailsConfig);
        },
        [
            commitUpdateLessonBlockDetails,
            state.values.block,
            state.values.startingYear,
            toast,
        ],
    );

    const showUpdateBlockDetailsModalHandler = useCallback(
        (variables: {
            lessonBlockIds: string[];
            school?: string;
            type: string;
        }) => {
            dispatchState({
                input: "updateBlockDetailsModalInfo",
                value: {
                    ids: variables.lessonBlockIds,
                    type: variables.type as
                        | "INSTRUMENT"
                        | "STAGE"
                        | "PUPIL"
                        | "TEACHER"
                        | "NOTES"
                        | "TEACHER_AND_DAY",
                    school: variables.school,
                },
            });
        },
        [dispatchState],
    );

    const updateRegisterStateHandler = useCallback(
        (
            variables: {
                lessonId: string;
                registerNote?: string;
                status: string;
            },
            progressChange?: number,
        ) => {
            const existingUpdateIndex = registerUpdates.current.findIndex(
                (item) => item.lessonId === variables.lessonId,
            );
            if (existingUpdateIndex === -1) {
                registerUpdates.current.push(variables);
            } else {
                registerUpdates.current.splice(
                    existingUpdateIndex,
                    1,
                    variables,
                );
            }
            // update the progress bar
            if (
                progressChange &&
                typeof registerProgressCounter.current.marked !== "undefined"
            ) {
                registerProgressCounter.current.marked += progressChange;
            }
            // this is a key used to force a rerender when these refs change (refs do not cause re-render)
            dispatchState({ input: "registerUpdater", value: Math.random() });
        },
        [dispatchState],
    );

    const renderModals = useMemo(() => {
        return (
            <>
                <DateTimePickerModal
                    animationType="fade"
                    canChooseEndTime
                    date={state.values.dateTimePickerModalDate}
                    duration={state.values.dateTimePickerModalLessonDuration}
                    hours={state.values.dateTimePickerModalDate?.getHours()}
                    label="Reschedule Lesson"
                    locale="en"
                    minutes={state.values.dateTimePickerModalDate?.getMinutes()}
                    onConfirm={(params) => {
                        if (state.values.dateTimePickerModalLessonId) {
                            rescheduleLesson(
                                state.values.dateTimePickerModalLessonId,
                                params.date as Date,
                                params.duration,
                            );
                        }
                        dispatchState({
                            input: "dateTimePickerModalDate",
                            value: undefined,
                        });
                        dispatchState({
                            input: "dateTimePickerModalLessonId",
                            value: undefined,
                        });
                    }}
                    onDismiss={() =>
                        dispatchState({
                            input: "dateTimePickerModalDate",
                            value: undefined,
                        })
                    }
                    saveLabel="Update"
                    uppercase={false}
                    visible={
                        typeof state.values.dateTimePickerModalDate !==
                        "undefined"
                    }
                />
                <UpdateLessonBlockDetailsModal
                    block={state.values.block}
                    detailType={
                        state.values.updateBlockDetailsModalInfo?.type as
                            | "INSTRUMENT"
                            | "STAGE"
                            | undefined
                    }
                    hideModal={() => {
                        dispatchState({
                            input: "updateBlockDetailsModalInfo",
                            value: undefined,
                        });
                    }}
                    lessonBlockCursor={
                        state.values.updateBlockDetailsModalInfo?.cursor
                    }
                    lessonBlockIds={
                        state.values.updateBlockDetailsModalInfo?.ids
                    }
                    school={state.values.updateBlockDetailsModalInfo?.school}
                    showModal={
                        Boolean(
                            state.values.updateBlockDetailsModalInfo?.ids,
                        ) &&
                        (state.values.updateBlockDetailsModalInfo?.type ===
                            "INSTRUMENT" ||
                            state.values.updateBlockDetailsModalInfo?.type ===
                                "STAGE" ||
                            state.values.updateBlockDetailsModalInfo?.type ===
                                "NOTES")
                    }
                    startingYear={state.values.startingYear}
                    updateLessonBlockDetails={updateLessonBlockDetails}
                    updateLessonBlockDetailsInFlight={
                        updateLessonBlockDetailsInFlight
                    }
                />
                <AlertPopup
                    alertIsOpen={Boolean(state.values.leaveAlertAction)}
                    body="You have made unsaved changes to your registers."
                    header="Save your changes?"
                    safeButtonRef={safeLeaveRef}
                    setAlertIsOpen={(isOpen: boolean) =>
                        dispatchState({
                            input: "leaveAlertAction",
                            value: isOpen,
                        })
                    }
                >
                    <Button
                        _text={{ fontSize: "lg" }}
                        colorScheme="red"
                        minWidth="100"
                        onPress={() => {
                            if (
                                isNavigationAction(
                                    state.values.leaveAlertAction,
                                )
                            ) {
                                navigation.dispatch(
                                    state.values.leaveAlertAction,
                                );
                            } else {
                                state.values.leaveAlertAction?.();
                            }
                            dispatchState({
                                input: "leaveAlertAction",
                                value: undefined,
                            });
                            registerUpdates.current = [];
                            dispatchState({
                                input: "registerUpdater",
                                value: Math.random(),
                            });
                        }}
                        width="40%"
                    >
                        Discard
                    </Button>
                    <ButtonDebounced
                        ref={safeLeaveRef}
                        _text={{ fontSize: "lg" }}
                        colorScheme="primary"
                        minWidth="100"
                        onPress={() => {
                            dispatchState({
                                input: "leaveAlertAction",
                                value: undefined,
                            });
                            updateRegisters(registerUpdates.current, () => {
                                if (
                                    isNavigationAction(
                                        state.values.leaveAlertAction,
                                    )
                                ) {
                                    navigation.dispatch(
                                        state.values.leaveAlertAction,
                                    );
                                } else {
                                    state.values.leaveAlertAction?.();
                                }
                            });
                        }}
                        width="40%"
                    >
                        Save
                    </ButtonDebounced>
                </AlertPopup>
            </>
        );
    }, [
        dispatchState,
        navigation,
        rescheduleLesson,
        state.values,
        updateLessonBlockDetails,
        updateLessonBlockDetailsInFlight,
        updateRegisters,
    ]);

    const { scale, width: windowWidth } = useWindowDimensions();

    if (!user?.profile?.id) {
        return null;
    }

    return (
        <>
            <TeacherRegisters
                allSchools={allSchools}
                // @ts-expect-error state is merged on component
                dispatchState={dispatchState}
                loadLessonBlocksQuery={loadLessonBlocksQuery}
                loadLessonBlocksQueryReference={loadLessonBlocksQueryReference}
                // @ts-expect-error nav props are merged on component
                navigation={navigation}
                profileId={user.profile.id}
                registerProgressCounter={registerProgressCounter}
                registerUpdates={registerUpdates}
                showUpdateBlockDetailsModalHandler={
                    showUpdateBlockDetailsModalHandler
                }
                sideBarWidth={
                    getScaledWindowDimension(windowWidth, scale) > 1000
                        ? -23.5
                        : -64
                }
                startingYears={startingYears}
                state={state}
                teacherSchools={teacherSchools}
                updateLessonBlockDetails={updateLessonBlockDetails}
                updateRegisterState={updateRegisterStateHandler}
                userIsTeacher
            />
            <PresenceTransition
                animate={{
                    opacity: 1,
                    scale: 1,
                    transition: {
                        duration: 250,
                    },
                }}
                initial={{
                    opacity: 0,
                    scale: 0,
                }}
                visible={registerUpdates.current.length > 0}
            >
                <Button
                    _focus={{ opacity: 1 }}
                    _hover={{ opacity: 1 }}
                    _spinner={{ size: "lg" }}
                    _text={{ fontSize: "xl" }}
                    alignSelf="center"
                    bottom="5"
                    height="60px"
                    isLoading={updateRegistersInFlight}
                    leftIcon={<CompleteIcon size="8" />}
                    onPress={() => updateRegisters(registerUpdates.current)}
                    position="absolute"
                    shadow={5}
                    width="300px"
                >
                    Save
                </Button>
            </PresenceTransition>
            {renderModals}
        </>
    );
};

const TeacherRegistersHubScreen: FC<ScreenProps> = (props) => {
    const { navigation, route } = props;

    const { user } = useAuth();

    const [defaultStartingYear, startingYears] = useMemo(
        () => getDefaultStartingYearAndStartingYearOptions(),
        [],
    );

    const initialState = useMemo(() => {
        // select the current day on the teacher register tab
        let dayIndex = route.params?.dayIndex ?? new Date().getDay() - 1;
        // only allowed to select mon-fri
        if (dayIndex < 0 || dayIndex > 4) {
            dayIndex = 0;
        }
        let initialStartingYear = route.params?.startingYear;
        if (initialStartingYear === undefined) {
            initialStartingYear =
                user?.profile?.profileGroup?.currentStartingYear ??
                defaultStartingYear;
            navigation.setParams({
                startingYear: initialStartingYear,
            });
        }
        let initialBlock = route.params?.block;
        if (initialBlock === undefined) {
            if (
                user?.profile?.profileGroup?.currentStartingYear &&
                user?.profile?.profileGroup?.currentStartingYear >
                    defaultStartingYear
            ) {
                initialBlock = 1;
            } else {
                initialBlock = user?.profile?.currentBlock ?? getCurrentBlock();
            }
            navigation.setParams({
                block: initialBlock,
            });
        }
        return {
            values: {
                startingYear: initialStartingYear,
                block: initialBlock,
                school: undefined,
                searchTerm: "",
                isRefetching: false,
                contentIsRendered: false,
                subscription: undefined,
                dateTimePickerModalDate: undefined,
                dateTimePickerModalLessonId: undefined,
                dateTimePickerModalLessonDuration: undefined,
                updateBlockDetailsModalInfo: undefined,
                registerUpdater: 0,
                dayIndex: dayIndex,
                leaveAlertAction: undefined,
                teachingDays: undefined,
            },
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // stop useEffects firing on first render
    const renderCount = useRef(0);
    const isFocused = useIsFocused();

    useEffect(() => {
        if (renderCount.current < 1) {
            renderCount.current += 1;
            return;
        }

        // if navigating from timetable screen, match selected day index
        if (route.params?.dayIndex) {
            dispatchState({ input: "dayIndex", value: route.params?.dayIndex });
        }
        if (route.params?.startingYear) {
            dispatchState({
                input: "startingYear",
                value: route.params?.startingYear,
            });
        }
        if (route.params?.block) {
            dispatchState({ input: "block", value: route.params?.block });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFocused]);

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [state, dispatchState] = useReducer(reducer, initialState);

    const [loadTeachingDaysQueryReference, loadTeachingDaysQuery] =
        useQueryLoader<LoadTeachingDaysQuery>(load_teaching_days);

    useEffect(() => {
        loadTeachingDaysQuery(
            {
                startingYear: state.values.startingYear,
                block: state.values.block,
            },
            { fetchPolicy: "store-or-network" },
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadTeachingDaysQuery]);

    const { scale, width: windowWidth } = useWindowDimensions();

    const renderHeader = useMemo(() => {
        return (
            <HStack mb="4" ml="1" pt="6" space="3">
                <RegisterIcon color="primary.600" size="2xl" />
                <Heading color="primary.600" fontSize="xl">
                    Registers
                </Heading>
            </HStack>
        );
    }, []);

    return (
        <Box bg="surface.100" flex={1} pt="70">
            <PresenceTransition
                animate={{
                    opacity: 1,
                    transition: {
                        delay: 0,
                        duration: 800,
                    },
                }}
                initial={{
                    opacity: 0,
                }}
                style={{ flex: 1 }}
                visible={Boolean(user?.profile?.id)}
            >
                <ScrollView
                    // makes the whole screen scrollable (no fixed header) - good for small screen
                    contentContainerStyle={
                        getScaledWindowDimension(windowWidth, scale) > 1000
                            ? { flex: 1 }
                            : undefined
                    }
                    flex={1}
                    showsVerticalScrollIndicator={false}
                >
                    <Box
                        flex={1}
                        mx={
                            getScaledWindowDimension(windowWidth, scale) > 1000
                                ? "30px"
                                : "10px"
                        }
                    >
                        {renderHeader}
                        {loadTeachingDaysQueryReference != null ? (
                            <Suspense
                                fallback={
                                    <LoadingBlobs pt="70">
                                        Loading Registers...
                                    </LoadingBlobs>
                                }
                            >
                                <TeacherRegistersHubContent
                                    dispatchState={dispatchState}
                                    loadTeachingDaysQueryReference={
                                        loadTeachingDaysQueryReference
                                    }
                                    navigation={navigation}
                                    startingYears={startingYears}
                                    state={state}
                                />
                            </Suspense>
                        ) : (
                            <LoadingBlobs pt="70">
                                Loading Registers...
                            </LoadingBlobs>
                        )}
                    </Box>
                </ScrollView>
            </PresenceTransition>
        </Box>
    );
};

export const screenOptions = {
    headerTitle: "Registers",
};

export default TeacherRegistersHubScreen;
