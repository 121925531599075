/**
 * @generated SignedSource<<3c7d4dd1ea60e7ba739592e6c06a596f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PayrollHeadlineDataFragment_query$data = {
  readonly me: {
    readonly profile: {
      readonly id: string;
      readonly totalPayslipsPaidAmount: number | null;
      readonly totalPayslipsUnpaidAmount: number | null;
    } | null;
  } | null;
  readonly payrate: {
    readonly hourlyRate: number | null;
    readonly id: string;
  } | null;
  readonly " $fragmentType": "PayrollHeadlineDataFragment_query";
};
export type PayrollHeadlineDataFragment_query$key = {
  readonly " $data"?: PayrollHeadlineDataFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"PayrollHeadlineDataFragment_query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Variable",
    "name": "dateFrom",
    "variableName": "dateFrom"
  },
  {
    "kind": "Variable",
    "name": "dateTo",
    "variableName": "dateTo"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "dateFrom"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "dateTo"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./PayrollHeadlineDataRefreshQuery.graphql')
    }
  },
  "name": "PayrollHeadlineDataFragment_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DjangoUserNode",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileNode",
          "kind": "LinkedField",
          "name": "profile",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": (v1/*: any*/),
              "kind": "ScalarField",
              "name": "totalPayslipsUnpaidAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": (v1/*: any*/),
              "kind": "ScalarField",
              "name": "totalPayslipsPaidAmount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "date",
          "variableName": "dateFrom"
        }
      ],
      "concreteType": "PayrateNode",
      "kind": "LinkedField",
      "name": "payrate",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hourlyRate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "77c0b44ccbeddbe844f9d076a65b9a39";

export default node;
