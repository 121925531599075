import React, {
    Suspense,
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
} from "react";
import type { FC, ReactElement, Dispatch } from "react";

import emoji from "emoji-dictionary";
import * as Clipboard from "expo-clipboard";
import {
    Box,
    Heading,
    VStack,
    HStack,
    Button,
    Text,
    useTheme,
    useToast,
} from "native-base";
import { useWindowDimensions } from "react-native";
import {
    useQueryLoader,
    usePreloadedQuery,
    usePaginationFragment,
    useRelayEnvironment,
} from "react-relay";
import type { PreloadedQuery } from "react-relay";
import {
    RecyclerListView,
    DataProvider,
    LayoutProvider,
} from "recyclerlistview";
import { fetchQuery } from "relay-runtime";
import type { Subscription } from "relay-runtime";

import { useAuth } from "pianofunclub-shared/providers/AuthProvider";
import { useData } from "pianofunclub-shared/providers/DataProvider";

import SchoolTableRow from "../../components/ListItems/SchoolTableRow";
import CreateSchoolModal from "../../components/Modals/CreateSchoolModal";
import type {
    SchoolsStackNavigatorProps,
    SchoolsStackRouteProps,
} from "../../navigation/SchoolsNavigator";
import { schoolsHubTableCsvConverter } from "../../utils/tableCsvConverters";
import LoadingBlobs from "pianofunclub-shared/components/Animations/LoadingBlobs";
import Row from "pianofunclub-shared/components/Base/Row";
import ListEmptyBanner from "pianofunclub-shared/components/ListItems/ListEmptyBanner";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import CustomFlatListSpinner from "pianofunclub-shared/components/Other/CustomFlatListSpinner";
import {
    AddCircleIcon,
    CalendarIcon,
    CopyIcon,
    RestartIcon,
    SchoolIcon,
} from "pianofunclub-shared/components/Other/Icons";
import SearchBar from "pianofunclub-shared/components/Other/SearchBar";

import type {
    LoadSchools_query_schools$key,
    LoadSchools_query_schools$data,
} from "pianofunclub-shared/relay/graphql/schools/__generated__/LoadSchools_query_schools.graphql";
import type { LoadSchoolsPaginationQuery } from "pianofunclub-shared/relay/graphql/schools/__generated__/LoadSchoolsPaginationQuery.graphql";
import type { LoadSchoolsQuery } from "pianofunclub-shared/relay/graphql/schools/__generated__/LoadSchoolsQuery.graphql";
import {
    load_schools,
    load_schools_pagination,
} from "pianofunclub-shared/relay/graphql/schools/LoadSchools";

import type { Mutable } from "pianofunclub-shared/types";
import { getScaledWindowDimension } from "pianofunclub-shared/utils/converters";
import {
    getOrderBy,
    sortIconExtractor,
} from "pianofunclub-shared/utils/extractors";
import { useDebounceFunction } from "pianofunclub-shared/utils/hooks";
import { createReducer } from "pianofunclub-shared/utils/reducers";
import type { State, Action } from "pianofunclub-shared/utils/reducers";

type NavigationProps = SchoolsStackNavigatorProps<"SchoolsHub">;

type RouteProps = SchoolsStackRouteProps<"SchoolsHub">;

interface ScreenProps {
    navigation: NavigationProps;
    route: RouteProps;
}

interface ContentProps {
    dispatchState: Dispatch<Action<ReducerValues, ReducerTypes>>;
    navigation: NavigationProps;
    queryReference: PreloadedQuery<LoadSchoolsQuery, Record<string, unknown>>;
    schoolsForDropdownConnectionId: string | undefined;
    state: State<ReducerValues>;
}

type ReducerValues = {
    contentIsRendered: boolean;
    copyDataToClipboard?: () => void;
    dataProvider: DataProvider;
    isActiveSchools: boolean;
    isRefetching: boolean;
    layoutProvider?: LayoutProvider;
    orderBy: string;
    refreshHandler?: () => void;
    schoolsConnectionId?: string;
    searchTerm: string;
    showCreateSchoolModal: boolean;
    subscription?: Subscription;
};

type ReducerTypes =
    | string
    | number
    | boolean
    | Subscription
    | DataProvider
    | LayoutProvider
    | (() => void)
    | undefined;

const LOAD_X_SCHOOLS = 50;
const TABLE_BORDER_COLOR = "surface.400";
const TABLE_BORDER_WIDTH = 1;
const TABLE_BORDER_RADIUS = 10;
const FLEX_ARRAY = [1, 1, 1, 0.6, 0.6, 0.6, 0.6, 0.5];

const SchoolsHubContent: FC<ContentProps> = (props) => {
    const {
        dispatchState,
        navigation,
        queryReference,
        schoolsForDropdownConnectionId,
        state,
    } = props;

    const data = usePreloadedQuery(load_schools, queryReference);

    const {
        data: schoolsData,
        hasNext,
        isLoadingNext,
        loadNext,
        refetch: refresh,
    } = usePaginationFragment<
        LoadSchoolsPaginationQuery,
        LoadSchools_query_schools$key
    >(load_schools_pagination, data);

    useEffect(() => {
        dispatchState({
            input: "schoolsConnectionId",
            value: schoolsData.schools?.__id,
        });
    }, [dispatchState, schoolsData.schools?.__id]);

    const toast = useToast();

    useEffect(() => {
        if (
            schoolsData.schools?.edges &&
            schoolsData.schools.edges.length > 0
        ) {
            dispatchState({
                input: "dataProvider",
                value: state.values.dataProvider.cloneWithRows(
                    schoolsData.schools.edges as Mutable<
                        typeof schoolsData.schools.edges
                    >,
                ),
            });
            // if there is no data, create a fresh DataProvider
        } else {
            dispatchState({
                input: "dataProvider",
                value: new DataProvider((r1, r2) => {
                    return r1 !== r2;
                }),
            });
        }

        dispatchState({
            input: "refreshHandler",
            value: () => {
                dispatchState({ input: "contentIsRendered", value: false });
                refresh(
                    {
                        first: LOAD_X_SCHOOLS,
                        searchTerm: state.values.searchTerm.trim(),
                        orderBy: state.values.orderBy,
                        isActive: state.values.isActiveSchools,
                    },
                    {
                        fetchPolicy: "network-only",
                    },
                );
            },
        });

        dispatchState({
            input: "copyDataToClipboard",
            value: () => {
                Clipboard.setStringAsync(
                    schoolsHubTableCsvConverter(
                        schoolsData.schools?.edges ?? [],
                    ),
                );
                toast.show({
                    render: ({ id }: { id: string }) => (
                        <ToastAlert
                            id={id}
                            status="success"
                            title={"Copied Data to Clipboard"}
                            toast={toast}
                        />
                    ),
                });
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schoolsData]);

    const onEndReached = useCallback(() => {
        if (hasNext && !isLoadingNext) {
            loadNext(LOAD_X_SCHOOLS);
        }
    }, [hasNext, isLoadingNext, loadNext]);

    // stop refetch useEffects firing on first render
    const renderCount = useRef(0);
    const environment = useRelayEnvironment();
    const { colors } = useTheme();

    const refetchSchools = useCallback(
        (variables: {
            first: number;
            isActive: boolean;
            orderBy?: string;
            searchTerm?: string;
        }): Subscription | undefined => {
            dispatchState({ input: "isRefetching", value: true });

            const subscription = fetchQuery<LoadSchoolsQuery>(
                environment,
                load_schools,
                variables,
                { fetchPolicy: "store-or-network" },
            ).subscribe({
                complete: () => {
                    dispatchState({ input: "isRefetching", value: false });
                    refresh(variables, { fetchPolicy: "store-only" });
                },
                error: () => {
                    dispatchState({ input: "isRefetching", value: false });
                },
            });
            return subscription;
        },
        [dispatchState, environment, refresh],
    );

    useEffect(() => {
        if (renderCount.current < 2) {
            renderCount.current += 1;
            return;
        }

        dispatchState({ input: "isRefetching", value: false });
        state.values.subscription?.unsubscribe();

        // fire refetch immediately when orderBy or filters change
        dispatchState({
            input: "subscription",
            value: refetchSchools({
                first: LOAD_X_SCHOOLS,
                searchTerm: state.values.searchTerm.trim(),
                orderBy: state.values.orderBy,
                isActive: state.values.isActiveSchools,
            }),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.values.orderBy, state.values.isActiveSchools]);

    useDebounceFunction(
        () => {
            if (renderCount.current < 2) {
                renderCount.current += 1;
                return;
            }

            dispatchState({ input: "isRefetching", value: false });
            state.values.subscription?.unsubscribe();

            // remove any applied sorting when we search (sort by relevance instead)
            // and reapply sorting if search text is blank
            const orderBy =
                state.values.searchTerm.trim() === "" ? "name" : undefined;

            dispatchState({ input: "orderBy", value: orderBy });
            navigation.setParams({ orderBy: orderBy });
            dispatchState({
                input: "subscription",
                value: refetchSchools({
                    first: LOAD_X_SCHOOLS,
                    searchTerm: state.values.searchTerm.trim(),
                    orderBy: orderBy,
                    isActive: state.values.isActiveSchools,
                }),
            });
        }, // no delay if clearing text
        state.values.searchTerm !== "" ? 750 : 0,

        [state.values.searchTerm],
    );

    const { scale, width: windowWidth } = useWindowDimensions();

    useEffect(() => {
        // this allows the RLV to resize when the window resizes
        dispatchState({
            input: "layoutProvider",
            value: new LayoutProvider(
                () => "VSEL",
                (type, dim) => {
                    switch (type) {
                        case "VSEL":
                            dim.width = getScaledWindowDimension(
                                windowWidth,
                                scale,
                            );
                            dim.height = 40;
                            break;
                        default:
                            dim.width = 0;
                            dim.height = 0;
                    }
                },
            ),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowWidth, scale]);

    const onPressSchool = useCallback(
        (schoolId?: string | null) => {
            const schoolsConnectionIds = state.values.schoolsConnectionId
                ? [state.values.schoolsConnectionId]
                : [];
            if (schoolsForDropdownConnectionId) {
                schoolsConnectionIds.push(schoolsForDropdownConnectionId);
            }
            if (schoolId) {
                navigation.navigate("School", {
                    schoolId: schoolId,
                    schoolsConnectionIds: schoolsConnectionIds,
                });
            }
        },
        [
            navigation,
            schoolsForDropdownConnectionId,
            state.values.schoolsConnectionId,
        ],
    );

    const rowRenderer = useCallback(
        (
            _: unknown,
            data: NonNullable<
                LoadSchools_query_schools$data["schools"]
            >["edges"][0],
            index: number,
        ): ReactElement | null => {
            if (data?.node) {
                return (
                    <Box
                        borderColor="surface.400"
                        borderLeftWidth={TABLE_BORDER_WIDTH}
                    >
                        <SchoolTableRow
                            cellProps={{
                                hideTopBorder: index === 0,
                                pressableTextProps: {
                                    fontFamily: "Poppins-Regular",
                                },
                                textProps: {
                                    fontSize: "md",
                                    textAlign: "center",
                                    fontFamily: "Poppins-Light",
                                },
                                px: 2,
                            }}
                            data={data.node}
                            flexArray={FLEX_ARRAY}
                            onPressSchool={onPressSchool}
                            rowHeight={10}
                            tableBorderColor={TABLE_BORDER_COLOR}
                            tableBorderWidth={TABLE_BORDER_WIDTH}
                        />
                    </Box>
                );
            } else {
                return null;
            }
        },
        [onPressSchool],
    );

    const renderFooter = useCallback(() => {
        return isLoadingNext ? (
            <CustomFlatListSpinner
                borderColor={TABLE_BORDER_COLOR}
                borderTopWidth={1}
                position="relative"
                pt="8"
                top="0"
            />
        ) : null;
    }, [isLoadingNext]);

    useEffect(() => {
        dispatchState({ input: "contentIsRendered", value: true });
        // unsubscribe from refetch on unmount
        return () => state.values.subscription?.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryReference]);

    if (!state.values.contentIsRendered) {
        return <LoadingBlobs>Loading Schools...</LoadingBlobs>;
    }

    return (
        <Box flex={1} mx="30px">
            {state.values.dataProvider.getSize() > 0 &&
            state.values.layoutProvider ? (
                <RecyclerListView
                    canChangeSize
                    dataProvider={state.values.dataProvider}
                    layoutProvider={state.values.layoutProvider}
                    onEndReached={onEndReached}
                    onEndReachedThreshold={2000}
                    renderAheadOffset={800}
                    renderFooter={renderFooter}
                    rowRenderer={rowRenderer}
                    scrollViewProps={{
                        contentContainerStyle: {
                            borderColor:
                                // @ts-expect-error can't index with variable
                                colors?.[TABLE_BORDER_COLOR.split(".")[0]][
                                    TABLE_BORDER_COLOR.split(".")[1]
                                ],
                            borderBottomWidth:
                                (schoolsData.schools?.edges.length ?? 0 > 0) &&
                                !isLoadingNext
                                    ? TABLE_BORDER_WIDTH
                                    : 0,
                            marginBottom: 40,
                            overflow: "hidden",
                            borderBottomRadius: TABLE_BORDER_RADIUS,
                        },
                        showsVerticalScrollIndicator: false,
                    }}
                    style={{
                        flex: 1,
                        minHeight: 1,
                    }}
                    suppressBoundedSizeException
                    useWindowScroll
                />
            ) : (
                <ListEmptyBanner explainer={"No schools found..."}>
                    <Text fontSize="6xl">
                        {emoji.getUnicode("neutral_face")}
                    </Text>
                </ListEmptyBanner>
            )}
        </Box>
    );
};

const SchoolsHubScreen: FC<ScreenProps> = (props) => {
    const { user } = useAuth();

    const initialState = useMemo(() => {
        return {
            values: {
                searchTerm: props.route.params?.searchTerm ?? "",
                orderBy: props.route.params?.orderBy ?? "name",
                isActiveSchools: true,
                contentIsRendered: false,
                isRefetching: false,
                showCreateSchoolModal: false,
                subscription: undefined,
                dataProvider: new DataProvider((r1, r2) => {
                    return r1 !== r2;
                }),
                layoutProvider: undefined,
                refreshHandler: undefined,
            },
        };
    }, [props.route.params?.orderBy, props.route.params?.searchTerm]);

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [state, dispatchState] = useReducer(reducer, initialState);

    const [loadSchoolsQueryReference, loadSchoolsQuery] =
        useQueryLoader<LoadSchoolsQuery>(load_schools);

    useEffect(() => {
        if (!state.values.contentIsRendered) {
            loadSchoolsQuery(
                {
                    first: LOAD_X_SCHOOLS,
                    searchTerm: state.values.searchTerm.trim(),
                    orderBy: state.values.orderBy,
                    isActive: state.values.isActiveSchools,
                },
                { fetchPolicy: "store-or-network" },
            );
        }
    }, [
        loadSchoolsQuery,
        state.values.contentIsRendered,
        state.values.isActiveSchools,
        state.values.orderBy,
        state.values.searchTerm,
    ]);

    const { dataState } = useData();

    const schoolsForDropdownConnectionId = useMemo(() => {
        return dataState.values.schools?.__id;
    }, [dataState.values.schools?.__id]);

    const inputChangeHandler = useCallback(
        (_: unknown, inputValue?: string) => {
            dispatchState({
                input: "searchTerm",
                value: inputValue,
            });
            props.navigation.setParams({ searchTerm: inputValue });
        },
        [props.navigation],
    );

    const inputClearHandler = useCallback(() => {
        if (state.values.searchTerm !== "") {
            dispatchState({
                input: "searchTerm",
                value: "",
            });
            props.navigation.setParams({ searchTerm: undefined });
        }
    }, [props.navigation, state.values.searchTerm]);

    const sortHandler = useCallback(
        (column: string) => {
            const orderBy = getOrderBy(column, state.values.orderBy);
            dispatchState({
                input: "orderBy",
                value: orderBy,
            });
            props.navigation.setParams({
                orderBy: orderBy,
            });
        },
        [props.navigation, state.values.orderBy],
    );

    const tableHeaders = useMemo(() => {
        // these headers are used by all account types
        return [
            {
                data: "Name",
                onPress: () => sortHandler("name"),
                icon: sortIconExtractor("name", state.values.orderBy),
            },
            {
                data: "Head Teacher",
                onPress: () =>
                    sortHandler(
                        "headTeacher__user__firstName,headTeacher__user__lastName",
                    ),
                icon: sortIconExtractor(
                    "headTeacher__user__firstName,headTeacher__user__lastName",
                    state.values.orderBy,
                ),
            },
            {
                data: "Office Contact",
                onPress: () =>
                    sortHandler(
                        "mainOfficeContact__user__firstName,mainOfficeContact__user__lastName",
                    ),
                icon: sortIconExtractor(
                    "mainOfficeContact__user__firstName,mainOfficeContact__user__lastName",
                    state.values.orderBy,
                ),
            },
            {
                data: "Active Teachers",
            },
            {
                data: "Filled Slots",
            },
            {
                data: "Available Slots",
            },
            {
                data: "Pupils on Waiting List",
            },
            {
                data: "Status",
            },
        ];
    }, [sortHandler, state.values.orderBy]);

    const renderHeader = useMemo(() => {
        return (
            <VStack mx="30px" pt="6" space="6">
                <HStack ml="1" space="5">
                    <SchoolIcon color="primary.600" size="xl" />
                    <Heading color="primary.600" fontSize="xl">
                        Schools
                    </Heading>
                </HStack>
                <HStack justifyContent="space-between">
                    <HStack alignItems="center" space="4">
                        <Box minWidth="400" width="25%">
                            <SearchBar
                                inputClearHandler={inputClearHandler}
                                inputOnChangeHandler={inputChangeHandler}
                                inputSearchHandler={inputChangeHandler}
                                placeholderText={"Search for a school"}
                                searchText={state.values.searchTerm}
                                showSearchIcon
                            />
                        </Box>
                        <Button
                            bg="surface.400"
                            colorScheme="surface"
                            leftIcon={<RestartIcon size="5" />}
                            onPress={state.values.refreshHandler}
                            p="3"
                        />
                    </HStack>
                    <HStack space="4">
                        <Button
                            _hover={{ bg: "primary.500" }}
                            _pressed={{ bg: "primary.600" }}
                            _text={{ fontSize: "17" }}
                            bg="primary.400"
                            leftIcon={<CopyIcon size="md" />}
                            onPress={state.values.copyDataToClipboard}
                            px="4"
                            shadow={1}
                        >
                            Copy Table to Clipboard
                        </Button>
                        <Button
                            _hover={{ bg: "primary.500" }}
                            _pressed={{ bg: "primary.600" }}
                            _text={{ fontSize: "17" }}
                            bg="primary.400"
                            leftIcon={<CalendarIcon size="md" />}
                            onPress={() => {
                                props.navigation.navigate(
                                    "DefaultTermDates",
                                    {},
                                );
                            }}
                            px="4"
                            shadow={1}
                        >
                            Term Dates & Blacklisted Dates
                        </Button>
                        <Button
                            _hover={{ bg: "primary.500" }}
                            _pressed={{ bg: "primary.600" }}
                            _text={{ fontSize: "17" }}
                            bg="primary.400"
                            leftIcon={<AddCircleIcon size="md" />}
                            onPress={() =>
                                dispatchState({
                                    input: "showCreateSchoolModal",
                                    value: true,
                                })
                            }
                            px="4"
                            shadow={1}
                        >
                            Create School
                        </Button>
                    </HStack>
                </HStack>
                <Box bg="surface.100">
                    <Box
                        bg="primary.50"
                        borderColor={TABLE_BORDER_COLOR}
                        borderTopRadius={TABLE_BORDER_RADIUS}
                        borderWidth={TABLE_BORDER_WIDTH}
                    >
                        <Row
                            cellProps={{
                                px: 2,
                                hideTopBorder: true,
                                hideOuterSideBorder: true,
                                textProps: {
                                    fontSize: "md",
                                    textAlign: "center",
                                    isTruncated: true,
                                },
                            }}
                            data={tableHeaders}
                            flexArray={FLEX_ARRAY}
                            rowHeight={10}
                            rowIndex={0}
                            tableBorderColor={TABLE_BORDER_COLOR}
                            tableBorderWidth={TABLE_BORDER_WIDTH}
                        />
                    </Box>
                </Box>
            </VStack>
        );
    }, [
        inputChangeHandler,
        inputClearHandler,
        props.navigation,
        state.values.copyDataToClipboard,
        state.values.refreshHandler,
        state.values.searchTerm,
        tableHeaders,
    ]);

    const renderRefetchIndicator = useMemo(() => {
        return state.values.isRefetching ? (
            <CustomFlatListSpinner top="270" />
        ) : null;
    }, [state.values.isRefetching]);

    if (!user?.profile?.id) {
        return null;
    }

    return (
        <Box bg="surface.100" flex={1} pt="70">
            {renderHeader}
            {loadSchoolsQueryReference != null ? (
                <Suspense
                    fallback={<LoadingBlobs>Loading Schools...</LoadingBlobs>}
                >
                    <SchoolsHubContent
                        dispatchState={dispatchState}
                        navigation={props.navigation}
                        queryReference={loadSchoolsQueryReference}
                        schoolsForDropdownConnectionId={
                            schoolsForDropdownConnectionId
                        }
                        state={state}
                    />
                </Suspense>
            ) : (
                <LoadingBlobs>Loading Schools...</LoadingBlobs>
            )}
            {renderRefetchIndicator}
            {state.values.showCreateSchoolModal ? (
                <CreateSchoolModal
                    schoolsConnectionId={state.values.schoolsConnectionId}
                    schoolsForDropdownConnectionId={
                        schoolsForDropdownConnectionId
                    }
                    setShowModal={(isOpen: boolean) => {
                        dispatchState({
                            input: "showCreateSchoolModal",
                            value: isOpen,
                        });
                    }}
                    showModal={state.values.showCreateSchoolModal}
                />
            ) : null}
        </Box>
    );
};

export const screenOptions = {
    headerTitle: "Schools",
};

export default SchoolsHubScreen;
