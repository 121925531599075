import { graphql } from "react-relay";

export const add_replacement_lesson_column_to_register = graphql`
    mutation AddReplacementLessonColumnToRegisterMutation(
        $input: AddReplacementLessonColumnToRegisterInput!
    ) {
        addReplacementLessonColumnToRegister(input: $input) {
            success
            errors
            lessonBlocks {
                id
                lessons {
                    edges {
                        node {
                            id
                            scheduledTimestamp
                            rearrangedTimestamp
                            status
                            replacementLessonColumnIndex
                        }
                    }
                }
            }
        }
    }
`;
