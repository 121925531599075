import React, {
    useReducer,
    useCallback,
    useState,
    useRef,
    useMemo,
    useEffect,
} from "react";
import type { FC } from "react";

import {
    Center,
    Box,
    VStack,
    Button,
    Image,
    PresenceTransition,
} from "native-base";
import { Keyboard } from "react-native";
import { useMutation } from "react-relay";

import { useAuth } from "pianofunclub-shared/providers/AuthProvider";

import type {
    AuthStackNavigatorProps,
    AuthStackRouteProps,
} from "../../navigation/AuthNavigator";
import { LOGO_CROPPED_IMG } from "../../utils/assets";
import TextInput from "pianofunclub-shared/components/Inputs/TextInput";
import AlertPopup from "pianofunclub-shared/components/NativeBaseExtended/AlertPopup";
import {
    MailIcon,
    SecurityIcon,
} from "pianofunclub-shared/components/Other/Icons";

import type {
    AutoLoginMutation,
    AutoLoginMutation$data,
} from "pianofunclub-shared/relay/graphql/auth/__generated__/AutoLoginMutation.graphql";
import type {
    TokenAuthMutation,
    TokenAuthMutation$data,
} from "pianofunclub-shared/relay/graphql/auth/__generated__/TokenAuthMutation.graphql";
import { auto_login } from "pianofunclub-shared/relay/graphql/auth/AutoLogin";
import { token_auth } from "pianofunclub-shared/relay/graphql/auth/TokenAuth";

import type { AlertError } from "pianofunclub-shared/types";
import { mapErrorToAlertText } from "pianofunclub-shared/utils/extractors";
import { createFormReducer } from "pianofunclub-shared/utils/reducers";
import { webSave } from "pianofunclub-shared/utils/store";

export type NavigationProps = AuthStackNavigatorProps<"Login">;

type RouteProps = AuthStackRouteProps<"Login">;

interface ScreenProps {
    navigation: NavigationProps;
    route: RouteProps;
}

type TeacherInputValues = {
    email: string;
    password: string;
};

type InputTypes = string;

const LoginScreen: FC<ScreenProps> = (props) => {
    const { navigation } = props;

    const { dispatchAuthState } = useAuth();

    const emailInputRef = useRef<{ clear: () => void }>();
    const passwordInputRef = useRef<{ clear: () => void; focus: () => void }>();

    const [contentIsRendered, setContentIsRendered] = useState(false);
    const [failedLoginAlertIsOpen, setFailedLoginAlertIsOpen] = useState(false);
    const [failedError, setFailedError] = useState<AlertError>({
        code: undefined,
        alertHeader: "",
        alertMessage: "",
    });

    const initialState = useMemo(() => {
        return {
            inputValues: {
                email: "",
                password: "",
            },
            inputValidities: {
                email: false,
                password: false,
            },
            formIsValid: false,
            formIsEdited: false,
        };
    }, []);

    const formReducer = createFormReducer<TeacherInputValues, InputTypes>(
        initialState,
    );
    const [formState, dispatchFormState] = useReducer(
        formReducer,
        initialState,
    );

    const clearInputs = useCallback(() => {
        dispatchFormState({
            value: "",
            isValid: false,
            input: "email",
        });
        dispatchFormState({
            value: "",
            isValid: false,
            input: "password",
        });
        emailInputRef.current?.clear();
        passwordInputRef.current?.clear();
    }, []);

    // this is required to properly authenticate on the back-end
    const [commitAutoLogin, autoLoginInFlight] =
        useMutation<AutoLoginMutation>(auto_login);

    const autoLogin = useCallback(
        (username: string, token: string, refreshToken: string | null) => {
            const autoLoginConfig = {
                variables: {
                    input: {
                        username: username,
                    },
                },
                onCompleted: (response: AutoLoginMutation$data) => {
                    if (response?.autoLogin?.success) {
                        // save the tokens to the secure local store
                        webSave("token", token);
                        if (refreshToken)
                            webSave("refresh_token", refreshToken);
                        webSave("login_method", "EMAIL");
                        if (response.autoLogin.user?.profile?.accountType) {
                            webSave(
                                "account_type",
                                response.autoLogin.user?.profile?.accountType,
                            );
                        }
                        dispatchAuthState({
                            type: "ADD_TOKEN",
                            accountType:
                                response.autoLogin.user?.profile?.accountType ??
                                null,
                            token: token,
                        });
                    } else {
                        const alertError = mapErrorToAlertText(
                            response.autoLogin?.errors,
                        );
                        setFailedError(alertError);

                        if (alertError?.code === "invalid_credentials") {
                            clearInputs();
                        }
                        setFailedLoginAlertIsOpen(true);
                    }
                },
            };

            commitAutoLogin(autoLoginConfig);
        },
        [clearInputs, commitAutoLogin, dispatchAuthState],
    );

    const [commitLogin, loginInFlight] =
        useMutation<TokenAuthMutation>(token_auth);

    const login = useCallback(
        (email: string, password: string) => {
            Keyboard.dismiss();
            const loginConfig = {
                variables: {
                    input: {
                        email: email,
                        password: password,
                    },
                },
                onCompleted: (response: TokenAuthMutation$data) => {
                    if (
                        response.tokenAuth?.success &&
                        response.tokenAuth?.token
                    ) {
                        autoLogin(
                            email,
                            response.tokenAuth.token,
                            response.tokenAuth?.refreshToken,
                        );
                    } else {
                        const alertError = mapErrorToAlertText(
                            response.tokenAuth?.errors,
                        );
                        setFailedError(alertError);

                        if (alertError?.code === "invalid_credentials") {
                            clearInputs();
                        }
                        setFailedLoginAlertIsOpen(true);
                    }
                },
            };

            commitLogin(loginConfig);
        },
        [autoLogin, clearInputs, commitLogin],
    );

    const inputChangeHandler = useCallback(
        (
            inputIdentifier?: string | number,
            inputValue?: string,
            isValid?: boolean,
        ) => {
            if (
                typeof inputValue === "string" &&
                typeof inputIdentifier === "string"
            ) {
                dispatchFormState({
                    value: inputValue.trim(),
                    isValid: isValid,
                    input: inputIdentifier,
                });
            }
        },
        [dispatchFormState],
    );

    useEffect(() => {
        setContentIsRendered(true);
    }, []);

    return (
        <>
            <PresenceTransition
                animate={{
                    opacity: 1,
                    transition: {
                        duration: 1000,
                        delay: 250,
                    },
                }}
                initial={{
                    opacity: 0,
                }}
                style={{ flex: 1 }}
                visible={contentIsRendered}
            >
                <Center flex={1} flexGrow={1} py="6" width="100%">
                    <VStack
                        alignItems="center"
                        justifyContent="center"
                        space="4"
                        width="400px"
                    >
                        <Image
                            resizeMode="contain"
                            size="xl"
                            src={LOGO_CROPPED_IMG}
                        />
                        <Box key="emailInput" width="100%">
                            <TextInput
                                ref={emailInputRef}
                                autoCapitalize="none"
                                blurOnSubmit={false}
                                email
                                id="email"
                                initiallyValid={false}
                                initialValue={""}
                                InputLeftElement={
                                    <MailIcon
                                        color={
                                            formState.inputValues.email ===
                                                "" ||
                                            !formState.inputValidities.email
                                                ? "muted.400"
                                                : "emerald.400"
                                        }
                                        ml="4"
                                        size="6"
                                    />
                                }
                                invalidIndicator
                                keyboardType="email-address"
                                label="Email"
                                onInputChange={inputChangeHandler}
                                onSubmit={() =>
                                    passwordInputRef.current?.focus()
                                }
                                p="3"
                                returnKeyType="next"
                            />
                        </Box>
                        <Box key="passwordInput" width="100%">
                            <TextInput
                                ref={passwordInputRef}
                                autoCapitalize="none"
                                id="password"
                                initiallyValid={false}
                                initialValue=""
                                InputLeftElement={
                                    <SecurityIcon
                                        color={
                                            formState.inputValues.password ==
                                                "" ||
                                            !formState.inputValidities.password
                                                ? "muted.400"
                                                : "emerald.400"
                                        }
                                        ml="4"
                                        size="6"
                                    />
                                }
                                invalidIndicator
                                keyboardType="default"
                                label="Password"
                                minLength={5}
                                onInputChange={inputChangeHandler}
                                onSubmit={() => {
                                    if (formState.formIsValid) {
                                        login(
                                            formState.inputValues.email.toLowerCase(),
                                            formState.inputValues.password,
                                        );
                                    }
                                }}
                                p="3"
                                secureTextEntry
                            />
                        </Box>
                        <Button
                            _pressed={{ bg: "transparent", opacity: 0.6 }}
                            _text={{ color: "warmGray.400" }}
                            colorScheme="warmGray"
                            mt="2"
                            onPress={() =>
                                navigation.navigate("ForgotPassword", {
                                    email: formState.inputValues.email,
                                })
                            }
                            p="0.5"
                            variant="ghost"
                        >
                            Forgotten your password?
                        </Button>
                        <Button
                            _disabled={{ opacity: 0.6 }}
                            _hover={{ bg: "primary.600" }}
                            _pressed={{ bg: "primary.700" }}
                            _spinner={{ size: "lg" }}
                            _text={{ fontSize: "2xl" }}
                            colorScheme="primary"
                            isDisabled={!formState.formIsValid}
                            isLoading={autoLoginInFlight || loginInFlight}
                            mt="8"
                            onPress={() =>
                                login(
                                    formState.inputValues.email.toLowerCase(),
                                    formState.inputValues.password,
                                )
                            }
                            p="4"
                            shadow={5}
                            width="70%"
                        >
                            Login
                        </Button>
                    </VStack>
                </Center>
            </PresenceTransition>
            <AlertPopup
                alertIsOpen={failedLoginAlertIsOpen}
                body={"Check your email and password and try again."}
                closeOnOverlayClick
                header={failedError.alertHeader}
                setAlertIsOpen={setFailedLoginAlertIsOpen}
            >
                <Button
                    _text={{ fontSize: "lg" }}
                    colorScheme="primary"
                    onPress={() => {
                        setFailedLoginAlertIsOpen(false);
                    }}
                    width="40%"
                >
                    Ok
                </Button>
            </AlertPopup>
        </>
    );
};

export const screenOptions = {
    headerShown: false,
};

export default LoginScreen;
