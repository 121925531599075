/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
    createContext,
    forwardRef,
    memo,
    useContext,
    useRef,
} from "react";
import type {
    MemoExoticComponent,
    MutableRefObject,
    RefObject,
    ComponentProps,
} from "react";

import { Box, Button, Modal, usePropsResolution } from "native-base";
import type { IBoxProps, IActionsheetItemProps } from "native-base";
import { Animated, PanResponder } from "react-native";

interface IActionsheetProps extends IBoxProps<IActionsheetProps> {
    _backdrop?: any;
    animationType?: "fade" | "slide";
    disableOverlay?: boolean;
    forceChoice?: boolean;
    hideDragIndicator?: boolean;
    isOpen?: boolean;
    onClose?: () => any;
}

interface IActionsheetContentProps extends IBoxProps<IActionsheetContentProps> {
    closeButtonProps?: ComponentProps<typeof Modal.CloseButton>;
    showCloseButton?: boolean;
}

type IActionsheetComponentType = ((
    props: IActionsheetProps & { ref?: MutableRefObject<any> },
) => React.JSX.Element) & {
    Content: MemoExoticComponent<
        (
            props: IActionsheetContentProps & { ref?: MutableRefObject<any> },
        ) => React.JSX.Element
    >;
    Item: MemoExoticComponent<
        (
            props: IActionsheetItemProps & { ref?: MutableRefObject<any> },
        ) => React.JSX.Element
    >;
};

const ActionSheetContext = createContext({
    hideDragIndicator: false,
});

const ModalContext = createContext({
    handleClose: (() => {
        return;
    }) as any,
    contentSize: {} as any,
    initialFocusRef: { current: null } as RefObject<any> | undefined,
    finalFocusRef: { current: null } as RefObject<any> | undefined,
});

const ActionsheetMain = (
    { children, hideDragIndicator = false, ...props }: IActionsheetProps,
    ref: any,
) => {
    const {
        animationType,
        disableOverlay,
        forceChoice,
        isOpen,
        onClose,
        ...resolvedProps
    } = usePropsResolution("Actionsheet", props);

    return (
        <Modal
            isOpen={isOpen}
            justifyContent="flex-end"
            onClose={onClose}
            {...resolvedProps}
            ref={ref}
            animationPreset={animationType ?? "slide"}
            closeOnOverlayClick={forceChoice ? false : true}
            overlayVisible={disableOverlay ? false : true}
        >
            <ActionSheetContext.Provider value={{ hideDragIndicator }}>
                {children}
            </ActionSheetContext.Provider>
        </Modal>
    );
};

const ActionsheetContent = (
    { children, ...props }: IActionsheetContentProps,
    ref?: any,
) => {
    const {
        _dragIndicator,
        closeButtonProps,
        showCloseButton,
        ...resolvedProps
    } = usePropsResolution("ActionsheetContent", props);
    const { handleClose } = useContext(ModalContext);
    const { hideDragIndicator } = useContext(ActionSheetContext);
    const pan = useRef(new Animated.ValueXY()).current;
    const sheetHeight = useRef(0);

    const panResponder = useRef(
        PanResponder.create({
            onStartShouldSetPanResponder: () => true,
            onMoveShouldSetPanResponder: (_evt, gestureState) => {
                return gestureState.dy > 15;
            },
            onPanResponderMove: (e, gestureState) => {
                if (gestureState.dy > 0) {
                    Animated.event([null, { dy: pan.y }], {
                        useNativeDriver: false,
                    })(e, gestureState);
                }
            },
            onPanResponderRelease: (_e, gestureState) => {
                // If sheet is dragged 1/4th of it's height, close it
                if (sheetHeight.current / 4 - gestureState.dy < 0) {
                    Animated.timing(pan, {
                        toValue: { x: 0, y: sheetHeight.current },
                        duration: 150,
                        useNativeDriver: true,
                    }).start(handleClose);
                } else {
                    Animated.spring(pan, {
                        toValue: { x: 0, y: 0 },
                        overshootClamping: true,
                        useNativeDriver: true,
                    }).start();
                }
            },
        }),
    ).current;
    return (
        <Animated.View
            onLayout={(event) => {
                const { height } = event.nativeEvent.layout;
                sheetHeight.current = height;
            }}
            pointerEvents="box-none"
            style={{
                transform: [{ translateY: pan.y }],
                width: "100%",
            }}
        >
            {!hideDragIndicator ? (
                <>
                    {/* To increase the draggable area */}
                    <Box
                        py={5}
                        {...panResponder.panHandlers}
                        collapsable={false}
                    />
                </>
            ) : null}
            <Modal.Content {...resolvedProps} ref={ref} safeAreaBottom>
                {showCloseButton ? (
                    <Modal.CloseButton
                        right={4}
                        top={4}
                        {...closeButtonProps}
                    />
                ) : null}
                {!hideDragIndicator ? (
                    <>
                        <Box
                            mt={-2}
                            pb={3}
                            pt={3}
                            {...panResponder.panHandlers}
                            alignItems="center"
                            collapsable={false}
                            width="100%"
                        >
                            <Box {..._dragIndicator} />
                        </Box>
                    </>
                ) : null}
                {children}
            </Modal.Content>
        </Animated.View>
    );
};

const ActionsheetItem = (props: IActionsheetItemProps, ref?: any) => {
    const resolvedProps = usePropsResolution("ActionsheetItem", props);
    return (
        <Button
            _hover={{
                bg: "transparent",
            }}
            _pressed={{
                bg: "transparent",
            }}
            {...resolvedProps}
            ref={ref}
            bg="transparent"
        />
    );
};

const ActionsheetTemp: any = memo(forwardRef(ActionsheetMain));
ActionsheetTemp.Content = memo(forwardRef(ActionsheetContent));
ActionsheetTemp.Item = memo(forwardRef(ActionsheetItem));

const Actionsheet = ActionsheetTemp as IActionsheetComponentType;

export default Actionsheet;
