import { graphql } from "react-relay";

export const me = graphql`
    query MeQuery {
        me {
            id
            firstName
            lastName
            email
            verified
            hasPassword
            dateJoined
            profile {
                id
                accountType
                schoolYear
                currentBlock
                schools {
                    id
                    name
                }
                school {
                    id
                    name
                }
                profileGroup {
                    id
                    currentStartingYear
                    generateDraftsProgress
                    sendDraftsProgress
                    sendRemindersProgress
                    matchPaymentsProgress
                    generateGoogleRegistersProgress
                    readInRegistersProgress
                    bulkUploadNewRegistersProgress
                    generateRegistersForNewBlockProgress
                    sendBulkCommunicationProgress
                    estimatedTotalAmountUnpaid
                    generatedEstimatedPayoutTotalFromDate
                    generatedEstimatedPayoutTotalToDate
                }
            }
        }
    }
`;
