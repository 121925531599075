import React, { useCallback } from "react";
import type {
    ReactElement,
    Dispatch,
    MutableRefObject,
    RefObject,
} from "react";

import { format } from "date-fns";
import {
    Modal,
    VStack,
    Text,
    Box,
    useToast,
    Pressable,
    Center,
} from "native-base";
import { useMutation } from "react-relay";

import type {
    ReducerValues as InvoiceAndEmailReducerValues,
    ReducerTypes as InvoiceAndEmailReducerTypes,
} from "../../components/Invoicing/InvoiceAndEmail";
import ButtonDebounced from "pianofunclub-shared/components/Buttons/ButtonDebounced";
import CurrencyInput from "pianofunclub-shared/components/Inputs/CurrencyInput";
import TextInput from "pianofunclub-shared/components/Inputs/TextInput";
import Select from "pianofunclub-shared/components/NativeBaseExtended/Select";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import {
    BankTransferIcon,
    CardIcon,
    CashIcon,
    PayPalIcon,
} from "pianofunclub-shared/components/Other/Icons";

import type { LoadInvoiceQuery$data } from "pianofunclub-shared/relay/graphql/invoicing/__generated__/LoadInvoiceQuery.graphql";
import type {
    UpdateOrCreatePaymentMutation,
    UpdateOrCreatePaymentMutation$data,
} from "pianofunclub-shared/relay/graphql/invoicing/__generated__/UpdateOrCreatePaymentMutation.graphql";
import { update_or_create_payment } from "pianofunclub-shared/relay/graphql/invoicing/UpdateOrCreatePayment";

import { isNumeric } from "pianofunclub-shared/utils/converters";
import type { State, Action } from "pianofunclub-shared/utils/reducers";

interface Props {
    dispatchState: Dispatch<
        Action<InvoiceAndEmailReducerValues, InvoiceAndEmailReducerTypes>
    >;
    invoiceData: LoadInvoiceQuery$data["invoice"];
    paymentDateInputRef: RefObject<{
        blur: () => void;
        setValue: (text: string, isValid: boolean) => void;
    }>;
    paymentModalKey: MutableRefObject<number>;
    paymentsConnectionId: string | undefined;
    state: State<InvoiceAndEmailReducerValues>;
}

export type PaymentModalDetails = {
    amount?: number;
    isNewPayment?: boolean;
    paymentDate?: string;
    paymentId?: string;
    paymentNote?: string;
    paymentReference?: string;
    paymentType?: string;
};

const UpdateOrCreatePaymentModal = (props: Props): ReactElement => {
    const {
        dispatchState,
        invoiceData,
        paymentDateInputRef,
        paymentModalKey,
        paymentsConnectionId,
        state,
    } = props;

    const toast = useToast();

    const [commitUpdateOrCreatePayment, updateOrCreatePaymentInFlight] =
        useMutation<UpdateOrCreatePaymentMutation>(update_or_create_payment);

    const updateOrCreatePayment = useCallback(
        (variables: PaymentModalDetails) => {
            if (typeof variables.amount !== "undefined" && invoiceData?.id) {
                const updateOrCreatePaymentConfig = {
                    variables: {
                        connections: paymentsConnectionId
                            ? [paymentsConnectionId]
                            : [],
                        input: {
                            invoiceId: invoiceData.id,
                            paymentId: variables.paymentId,
                            paymentType: variables.paymentType,
                            paymentReference: variables.paymentReference,
                            paymentDate: variables.paymentDate,
                            amount: variables.amount as number,
                            paymentNote: variables.paymentNote,
                        },
                    },
                    onCompleted: (
                        response: UpdateOrCreatePaymentMutation$data,
                    ) => {
                        if (response?.updateOrCreatePayment?.success) {
                            toast.show({
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        id={id}
                                        status="success"
                                        title={`Payment ${
                                            variables.isNewPayment
                                                ? "created"
                                                : "updated"
                                        }`}
                                        toast={toast}
                                    />
                                ),
                            });
                            dispatchState({
                                input: "showPaymentModal",
                                value: false,
                            });
                        } else {
                            toast.show({
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        description={
                                            "Please get in touch with one of the team"
                                        }
                                        id={id}
                                        status="error"
                                        title={`Couldn't ${
                                            variables.isNewPayment
                                                ? "create"
                                                : "update"
                                        } payment`}
                                        toast={toast}
                                    />
                                ),
                            });
                        }
                    },
                };
                commitUpdateOrCreatePayment(updateOrCreatePaymentConfig);
            }
        },
        [
            commitUpdateOrCreatePayment,
            dispatchState,
            invoiceData?.id,
            paymentsConnectionId,
            toast,
        ],
    );

    const paymentInputFinishEditingHandler = useCallback(
        (inputIdentifier?: string | number, inputValue?: string) => {
            if (inputIdentifier) {
                dispatchState({
                    input: "paymentModalDetails",
                    value: {
                        ...state.values.paymentModalDetails,
                        [inputIdentifier]: inputValue,
                    },
                });
            }
        },
        [dispatchState, state.values.paymentModalDetails],
    );

    const paymentAmountFinishEditingHandler = useCallback(
        (
            inputIdentifier?: string | number,
            inputValue?: string,
            isValid?: boolean,
        ) => {
            if (
                inputIdentifier &&
                isValid &&
                typeof inputValue !== "undefined" &&
                isNumeric(inputValue)
            ) {
                dispatchState({
                    input: "paymentModalDetails",
                    value: {
                        ...state.values.paymentModalDetails,
                        amount: parseFloat(inputValue),
                    },
                });
            }
        },
        [dispatchState, state.values.paymentModalDetails],
    );

    const openPaymentDateHandler = useCallback(() => {
        dispatchState({
            input: "datePickerType",
            value: "PAYMENT_DATE",
        });
        dispatchState({
            input: "showPaymentModal",
            value: false,
        });
        dispatchState({
            input: "datePickerIsOpen",
            value: true,
        });
    }, [dispatchState]);

    const updateOrCreatePaymentHandler = useCallback(() => {
        if (state.values.paymentModalDetails) {
            updateOrCreatePayment(state.values.paymentModalDetails);
        }
    }, [state.values.paymentModalDetails, updateOrCreatePayment]);

    return (
        <Modal
            key={paymentModalKey.current}
            animationPreset={undefined}
            isOpen={state.values.showPaymentModal}
            onClose={() => {
                dispatchState({
                    input: "showPaymentModal",
                    value: false,
                });
            }}
            size="lg"
        >
            <Modal.Content bg="primary.800">
                <Modal.CloseButton
                    _hover={{ bg: "transparent", opacity: 0.7 }}
                    _icon={{ color: "surface.100" }}
                    _pressed={{ bg: "transparent", opacity: 0.7 }}
                />
                <Modal.Header _text={{ color: "surface.100" }} bg="primary.800">
                    {state.values.paymentModalDetails?.isNewPayment
                        ? "Add Payment"
                        : "Update Payment"}
                </Modal.Header>
                <Modal.Body alignItems="center" mb="4" px="6">
                    <VStack
                        alignItems="center"
                        justifyContent="center"
                        mt="-19"
                        pb="4"
                        space={4}
                    >
                        {invoiceData ? (
                            <Text
                                color="surface.100"
                                fontSize="md"
                                pb="3"
                            >{`${invoiceData.invoiceNumber}  •  ${invoiceData.assignedToFullName}  •  #${invoiceData.assignedToAccountNumber}`}</Text>
                        ) : null}
                        <Box width="90%">
                            <CurrencyInput
                                centerLabel
                                fontFamily="Poppins-Regular"
                                id="paymentAmount"
                                initiallyValid
                                initialValue={
                                    state.values.paymentModalDetails?.amount
                                }
                                label="Payment Amount"
                                onFinishEditing={
                                    paymentAmountFinishEditingHandler
                                }
                                placeholder="Enter the amount paid"
                                required
                                textAlign="center"
                            />
                        </Box>
                        <Pressable
                            _hover={{ opacity: 0.9 }}
                            _pressed={{ opacity: 0.9 }}
                            onPress={openPaymentDateHandler}
                            width="90%"
                        >
                            <TextInput
                                ref={paymentDateInputRef}
                                centerLabel
                                fontFamily="Poppins-Regular"
                                id="paymentDate"
                                initiallyValid
                                initialValue={
                                    state.values.paymentModalDetails
                                        ?.paymentDate
                                        ? format(
                                              new Date(
                                                  state.values.paymentModalDetails.paymentDate,
                                              ),
                                              "dd/MM/yyyy",
                                          )
                                        : ""
                                }
                                label="Payment Date"
                                placeholder="Select a date"
                                pointerEvents="none"
                                textAlign="center"
                            />
                        </Pressable>
                        <Box width="90%">
                            <TextInput
                                blurOnSubmit
                                centerLabel
                                fontFamily="Poppins-Regular"
                                id="paymentReference"
                                initialValue={
                                    state.values.paymentModalDetails
                                        ?.paymentReference
                                }
                                keyboardType="default"
                                label="Payment Reference"
                                maxLength={500}
                                multiline
                                numberOfLines={2}
                                onFinishEditing={
                                    paymentInputFinishEditingHandler
                                }
                                placeholder={"Add the payment reference here"}
                                textAlign="center"
                                textAlignVertical="top"
                            />
                        </Box>
                        <VStack alignItems="center" space="1" width="90%">
                            <Text color="surface.100" fontSize="md">
                                Payment Type
                            </Text>
                            <Center width="100%">
                                <Select
                                    bg="surface.100"
                                    color="surface.900"
                                    fontFamily="Poppins-Regular"
                                    fontSize="md"
                                    onValueChange={(itemValue) =>
                                        dispatchState({
                                            input: "paymentModalDetails",
                                            value: {
                                                ...state.values
                                                    .paymentModalDetails,
                                                paymentType: itemValue,
                                            },
                                        })
                                    }
                                    placeholder="Select payment type"
                                    selectedValue={
                                        state.values.paymentModalDetails
                                            ?.paymentType
                                    }
                                    textAlign="center"
                                    width="250px"
                                >
                                    <Select.Item
                                        key={"BANK_TRANSFER"}
                                        actionSheetLabel={"Bank Transfer"}
                                        startIcon={
                                            <BankTransferIcon
                                                color="surface.900"
                                                ml="4"
                                            />
                                        }
                                        value={"BANK_TRANSFER"}
                                    />
                                    <Select.Item
                                        key={"CREDIT/DEBIT_CARD"}
                                        actionSheetLabel={"Credit/Debit Card"}
                                        startIcon={
                                            <CardIcon
                                                color="surface.900"
                                                ml="4"
                                            />
                                        }
                                        value={"CREDIT/DEBIT_CARD"}
                                    />
                                    <Select.Item
                                        key={"PAYPAL"}
                                        actionSheetLabel={"Paypal"}
                                        startIcon={
                                            <PayPalIcon
                                                color="surface.900"
                                                ml="4"
                                            />
                                        }
                                        value={"PAYPAL"}
                                    />
                                    <Select.Item
                                        key={"CASH"}
                                        actionSheetLabel={"Cash"}
                                        startIcon={
                                            <CashIcon
                                                color="surface.900"
                                                ml="4"
                                            />
                                        }
                                        value={"CASH"}
                                    />
                                </Select>
                            </Center>
                        </VStack>
                        <Box width="90%">
                            <TextInput
                                blurOnSubmit
                                centerLabel
                                fontFamily="Poppins-Regular"
                                id="paymentNote"
                                initialValue={
                                    state.values.paymentModalDetails
                                        ?.paymentNote
                                }
                                keyboardType="default"
                                label="Payment Note"
                                maxLength={500}
                                multiline
                                numberOfLines={4}
                                onFinishEditing={
                                    paymentInputFinishEditingHandler
                                }
                                onSubmit={updateOrCreatePaymentHandler}
                                placeholder={"Add a note about this payment"}
                                textAlign="center"
                                textAlignVertical="top"
                            />
                        </Box>
                        <ButtonDebounced
                            _disabled={{ opacity: 0.8 }}
                            _text={{ fontSize: "xl" }}
                            height="12"
                            isDisabled={
                                state.values.paymentModalDetails?.amount ===
                                undefined
                            }
                            isLoading={updateOrCreatePaymentInFlight}
                            mb="4"
                            mt="4"
                            onPress={updateOrCreatePaymentHandler}
                            width="40"
                        >
                            {state.values.paymentModalDetails?.isNewPayment
                                ? "Save"
                                : "Update"}
                        </ButtonDebounced>
                    </VStack>
                </Modal.Body>
            </Modal.Content>
        </Modal>
    );
};

export default React.memo(UpdateOrCreatePaymentModal);
