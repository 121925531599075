import { graphql } from "react-relay";

export const read_in_google_registers = graphql`
    mutation ReadInGoogleRegistersMutation(
        $input: ReadInGoogleRegistersInput!
    ) {
        readInGoogleRegisters(input: $input) {
            success
            errors
            totalToReadIn
            numberOfRegistersReadIn
            erroredTeacher {
                id
                user {
                    firstName
                    lastName
                    email
                }
            }
            profileGroup {
                id
                readInRegistersProgress
            }
        }
    }
`;
