import React, {
    useCallback,
    useReducer,
    useMemo,
    useRef,
    useState,
} from "react";
import type { FC } from "react";

import {
    Box,
    VStack,
    Button,
    useToast,
    Center,
    Text,
    Spinner,
} from "native-base";
import { useMutation } from "react-relay";

import { useAuth } from "pianofunclub-shared/providers/AuthProvider";

import SideBar from "../../components/Drawers/SideBar";
import type {
    ProfileStackNavigatorProps,
    ProfileStackRouteProps,
} from "../../navigation/ProfileNavigator";
import TextInput from "pianofunclub-shared/components/Inputs/TextInput";
import AlertPopup from "pianofunclub-shared/components/NativeBaseExtended/AlertPopup";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import {
    LockIcon,
    SecurityIcon,
} from "pianofunclub-shared/components/Other/Icons";

import type {
    ChangePasswordMutation,
    ChangePasswordMutation$data,
} from "pianofunclub-shared/relay/graphql/auth/__generated__/ChangePasswordMutation.graphql";
import { change_password } from "pianofunclub-shared/relay/graphql/auth/ChangePassword";

import type { AlertError } from "pianofunclub-shared/types";
import { mapErrorToAlertText } from "pianofunclub-shared/utils/extractors";
import { createFormReducer } from "pianofunclub-shared/utils/reducers";

type NavigationProps = ProfileStackNavigatorProps<"ChangePassword">;

type RouteProps = ProfileStackRouteProps<"ChangePassword">;

interface ScreenProps {
    navigation: NavigationProps;
    route: RouteProps;
}

type InputValues = {
    newPassword1: string;
    newPassword2: string;
    oldPassword: string;
};

type InputTypes = string;

const SIDE_BAR_WIDTH = 12;

const ChangePasswordScreen: FC<ScreenProps> = (props) => {
    const { navigation } = props;

    const { logout } = useAuth();
    const oldPasswordInputRef = useRef<{ clear: () => void }>();
    const newPassword1InputRef = useRef<{
        clear: () => void;
        focus: () => void;
    }>();
    const newPassword2InputRef = useRef<{
        clear: () => void;
        focus: () => void;
    }>();

    const initialState = useMemo(() => {
        return {
            inputValues: {
                oldPassword: "",
                newPassword1: "",
                newPassword2: "",
            },
            inputValidities: {
                oldPassword: false,
                newPassword1: false,
                newPassword2: false,
            },
            formIsValid: false,
            formIsEdited: false,
        };
    }, []);

    const formReducer = createFormReducer<InputValues, InputTypes>(
        initialState,
    );
    const [formState, dispatchFormState] = useReducer(
        formReducer,
        initialState,
    );

    const updateToast = useToast();
    const updateToastId = "updateToast";
    const [failedAlertIsOpen, setFailedAlertIsOpen] = useState(false);
    const [failedError, setFailedError] = useState<AlertError>({
        code: undefined,
        alertHeader: "",
        alertMessage: "",
    });

    const clearNewPasswordInputs = useCallback(() => {
        newPassword1InputRef.current?.clear();
        dispatchFormState({ value: "", isValid: false, input: "newPassword1" });
        newPassword2InputRef.current?.clear();
        dispatchFormState({ value: "", isValid: false, input: "newPassword2" });
    }, []);

    const [commitChangePassword, changePasswordInFlight] =
        useMutation<ChangePasswordMutation>(change_password);

    const changePassword = useCallback(
        (oldPassword: string, newPassword1: string, newPassword2: string) => {
            const changePasswordConfig = {
                variables: {
                    input: {
                        oldPassword: oldPassword,
                        newPassword1: newPassword1,
                        newPassword2: newPassword2,
                    },
                },
                onCompleted: (response: ChangePasswordMutation$data) => {
                    if (response?.passwordChange?.success) {
                        if (!updateToast.isActive(updateToastId)) {
                            updateToast.show({
                                id: updateToastId,
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        id={id}
                                        status="success"
                                        title={"Password updated"}
                                        toast={updateToast}
                                    />
                                ),
                            });
                        }
                        logout();
                    } else {
                        setFailedError(
                            mapErrorToAlertText(
                                response.passwordChange?.errors,
                            ),
                        );
                        setFailedAlertIsOpen(true);
                        clearNewPasswordInputs();
                    }
                },
                onError: () => {
                    if (!updateToast.isActive(updateToastId)) {
                        updateToast.show({
                            id: updateToastId,
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="error"
                                    title={"Password could not be updated"}
                                    toast={updateToast}
                                />
                            ),
                        });
                    }
                },
            };

            commitChangePassword(changePasswordConfig);
        },
        [clearNewPasswordInputs, commitChangePassword, logout, updateToast],
    );

    const inputChangeHandler = useCallback(
        (
            inputIdentifier?: string | number,
            inputValue?: string,
            isValid?: boolean,
        ) => {
            if (
                typeof inputIdentifier === "string" &&
                typeof inputValue === "string"
            ) {
                dispatchFormState({
                    value: inputValue,
                    isValid:
                        inputIdentifier == "newPassword2"
                            ? inputValue == formState.inputValues.newPassword1
                            : isValid,
                    input: inputIdentifier,
                });
            }
        },
        [formState.inputValues.newPassword1],
    );

    const inputSubmitHandler = useCallback(
        (inputIdentifier?: string | number) => {
            switch (inputIdentifier) {
                case "oldPassword":
                    newPassword1InputRef.current?.focus();
                    break;
                case "newPassword1":
                    newPassword2InputRef.current?.focus();
                    break;
                case "newPassword2":
                    if (formState.formIsValid && !changePasswordInFlight) {
                        changePassword(
                            formState.inputValues.oldPassword,
                            formState.inputValues.newPassword1,
                            formState.inputValues.newPassword2,
                        );
                    }
            }
        },
        [
            changePassword,
            changePasswordInFlight,
            formState.formIsValid,
            formState.inputValues.newPassword1,
            formState.inputValues.newPassword2,
            formState.inputValues.oldPassword,
        ],
    );

    return (
        <>
            <Box flex={1}>
                <SideBar
                    isCollapsed
                    navigation={navigation}
                    width={SIDE_BAR_WIDTH}
                />
                <Center bg="surface.100" flex={1} ml={SIDE_BAR_WIDTH} pt="70">
                    <VStack alignItems="center">
                        <Text fontSize="2xl" fontWeight={700} mb="4">
                            Change Password
                        </Text>
                        <TextInput
                            ref={oldPasswordInputRef}
                            autoCapitalize="none"
                            autoComplete="new-password"
                            blurOnSubmit={false}
                            id="oldPassword"
                            initiallyValid={false}
                            initialValue=""
                            InputLeftElement={
                                <SecurityIcon
                                    color={
                                        formState.inputValues.oldPassword ==
                                            "" ||
                                        !formState.inputValidities.oldPassword
                                            ? "muted.400"
                                            : "emerald.400"
                                    }
                                    ml="3"
                                    size="6"
                                />
                            }
                            invalidIndicator
                            keyboardType="default"
                            label="Current Password"
                            labelStyle={{
                                color: "surface.700",
                                fontSize: "sm",
                            }}
                            mb="10"
                            minLength={8}
                            onInputChange={inputChangeHandler}
                            onSubmit={inputSubmitHandler}
                            p="3"
                            placeholder="Enter your current password"
                            returnKeyType="next"
                            secureTextEntry
                            size="xl"
                        />
                        <TextInput
                            ref={newPassword1InputRef}
                            autoCapitalize="none"
                            autoComplete="new-password"
                            blurOnSubmit={false}
                            errorMessage="8+ characters, includes both A-Z and 0-9"
                            id="newPassword1"
                            initiallyValid={false}
                            initialValue=""
                            InputLeftElement={
                                <SecurityIcon
                                    color={
                                        formState.inputValues.newPassword1 ==
                                            "" ||
                                        !formState.inputValidities.newPassword1
                                            ? "muted.400"
                                            : "emerald.400"
                                    }
                                    ml="3"
                                    size="6"
                                />
                            }
                            invalidIndicator
                            keyboardType="default"
                            label="New Password"
                            labelStyle={{
                                color: "surface.700",
                                fontSize: "sm",
                            }}
                            mb="4"
                            minLength={8}
                            mustContainNumbers
                            onInputChange={inputChangeHandler}
                            onSubmit={inputSubmitHandler}
                            p="3"
                            placeholder="Enter your new password"
                            returnKeyType="next"
                            secureTextEntry
                            size="xl"
                        />
                        <TextInput
                            ref={newPassword2InputRef}
                            autoCapitalize="none"
                            autoComplete="new-password"
                            errorMessage="Passwords do not match"
                            errorMessageOffset={-16}
                            id="newPassword2"
                            initiallyValid={false}
                            initialValue=""
                            InputLeftElement={
                                <LockIcon
                                    color={
                                        formState.inputValues.newPassword2 ==
                                            "" ||
                                        !formState.inputValidities
                                            .newPassword2 ||
                                        formState.inputValues.newPassword1 !=
                                            formState.inputValues.newPassword2
                                            ? "muted.400"
                                            : "emerald.400"
                                    }
                                    ml="3"
                                    size="6"
                                />
                            }
                            invalidIndicator
                            isInvalid={
                                formState.inputValues.newPassword1 !=
                                    formState.inputValues.newPassword2 &&
                                formState.inputValues.newPassword2 != ""
                            }
                            keyboardType="default"
                            label="Confirm New Password"
                            labelStyle={{
                                color: "surface.700",
                                fontSize: "sm",
                            }}
                            minLength={8}
                            onInputChange={inputChangeHandler}
                            onSubmit={inputSubmitHandler}
                            p="3"
                            placeholder="Re-enter your new password"
                            secureTextEntry
                            size="xl"
                        />
                        <Text
                            color="muted.500"
                            fontSize="md"
                            my="6"
                            textAlign="center"
                        >
                            {
                                "Changing your password will log you out.\nYou can then login with your new password."
                            }
                        </Text>
                        <Button
                            colorScheme="primary"
                            height="60px"
                            isDisabled={
                                !formState.formIsValid || changePasswordInFlight
                            }
                            onPress={() =>
                                changePassword(
                                    formState.inputValues.oldPassword,
                                    formState.inputValues.newPassword1,
                                    formState.inputValues.newPassword2,
                                )
                            }
                            width="200px"
                        >
                            {changePasswordInFlight ? (
                                <Spinner color="white" />
                            ) : (
                                <Text color="white" fontSize="xl">
                                    Save
                                </Text>
                            )}
                        </Button>
                        <Button
                            _hover={{ bg: "transparent", opacity: 0.8 }}
                            _pressed={{ bg: "transparent", opacity: 0.7 }}
                            colorScheme="surface"
                            mt="4"
                            onPress={() => navigation.goBack()}
                            variant="ghost"
                        >
                            Go Back
                        </Button>
                    </VStack>
                </Center>
            </Box>
            <AlertPopup
                alertIsOpen={failedAlertIsOpen}
                body={failedError.alertMessage}
                closeOnOverlayClick
                header={failedError.alertHeader}
                setAlertIsOpen={setFailedAlertIsOpen}
            >
                <Button
                    _text={{ fontSize: "lg" }}
                    colorScheme="primary"
                    onPress={() => {
                        setFailedAlertIsOpen(false);
                    }}
                    width="40%"
                >
                    Ok
                </Button>
            </AlertPopup>
        </>
    );
};

export const screenOptions = {
    headerTitle: "Change Password",
};

export default React.memo(ChangePasswordScreen);
