import { graphql } from "react-relay";

export const update_account_fee_or_credit = graphql`
    mutation UpdateAccountFeeOrCreditMutation(
        $input: UpdateAccountFeeOrCreditInput!
    ) {
        updateAccountFeeOrCredit(input: $input) {
            success
            errors
            accountFeeOrCredit {
                id
                amount
            }
        }
    }
`;
