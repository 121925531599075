/* eslint-disable no-case-declarations */

export type State<T1> = {
    values: T1;
};

export type Action<T1, T2> = {
    index?: number;
    input?:
        | string
        | ((currentValues: State<T1>["values"]) => State<T1>["values"]);
    type?: "RESET";
    value?: T2;
};

// basic reducer for keeping track of multiple states without validity checking
export const createReducer =
    <T1, T2>(initialState: State<T1>) =>
    (state: State<T1>, action: Action<T1, T2>): State<T1> => {
        switch (action.type) {
            case "RESET":
                return {
                    ...initialState,
                };
            default:
                if (typeof action.input === "string") {
                    if (action.index === undefined) {
                        const updatedValues = {
                            ...state.values,
                            [action.input]: action.value,
                        };
                        return { values: updatedValues };
                    }

                    const updatedValues = {
                        ...state.values,
                        [action.input]: Object.assign(
                            [],
                            // @ts-expect-error couldn't type the state accurately enough
                            state.values[action.input],
                            { [action.index]: action.value },
                        ),
                    };
                    return { values: updatedValues };
                } else if (action.input) {
                    const updatedValues = action.input(state.values);
                    return { values: updatedValues };
                } else {
                    return state;
                }
        }
    };

export type FormState<T1> = {
    formIsEdited: boolean;
    formIsValid: boolean;
    inputValidities: Record<string, boolean>;
    inputValues: T1;
    lastInput?: string;
};

export type FormAction<T2> = {
    input?: string;
    isValid?: boolean;
    type?: "TOTAL_RESET" | "RESET" | "SAVE";
    value?: T2;
};

export const createFormReducer =
    <T1, T2>(initialState: FormState<T1>) =>
    (state: FormState<T1>, action: FormAction<T2>): FormState<T1> => {
        switch (action.type) {
            case "RESET":
                return {
                    ...initialState,
                    formIsEdited: state.formIsEdited,
                    lastInput: state.lastInput,
                };
            case "TOTAL_RESET":
                return {
                    ...initialState,
                    formIsEdited: false,
                    lastInput: undefined,
                };
            case "SAVE":
                return {
                    ...initialState,
                    formIsEdited: false,
                    lastInput: undefined,
                };
            default:
                if (action.input) {
                    const updatedValues = {
                        ...state.inputValues,
                        [action.input]: action.value,
                    };
                    const updatedValidities = {
                        ...state.inputValidities,
                        [action.input]: Boolean(action.isValid),
                    };
                    let updatedFormIsValid = true;
                    for (const key in updatedValidities) {
                        updatedFormIsValid =
                            updatedFormIsValid && updatedValidities[key];
                        if (!updatedFormIsValid) break;
                    }
                    return {
                        formIsValid: updatedFormIsValid,
                        formIsEdited: true,
                        inputValidities: updatedValidities,
                        inputValues: updatedValues,
                        lastInput: action.input,
                    };
                } else {
                    return state;
                }
        }
    };

// this is identical to formReducer but without tracking values
type ItemState = {
    itemIsEdited: boolean;
    itemIsValid: boolean;
    validities?: Record<string, boolean>;
};

export type ItemAction =
    | {
          input: string;
          isValid: boolean;
          type: "INPUT_OR_ADD";
      }
    | { input: string; type: "REMOVE" }
    | { type: "ONLY_EDIT" }
    | { type: "SAVE" }
    | { type: "CLEAR_STATE" };

export const createItemReducer =
    () =>
    (state: ItemState, action: ItemAction): ItemState => {
        switch (action.type) {
            case "INPUT_OR_ADD":
                const updatedValidities = {
                    ...state.validities,
                    [action.input]: action.isValid,
                };
                let updatedItemIsValid = true;
                for (const key in updatedValidities) {
                    updatedItemIsValid =
                        updatedItemIsValid && updatedValidities[key];
                    if (!updatedItemIsValid) {
                        break;
                    }
                }
                return {
                    itemIsValid: updatedItemIsValid,
                    itemIsEdited: true,
                    validities: updatedValidities,
                };
            case "REMOVE":
                const validitiesAfterRemove = {
                    ...state.validities,
                };
                delete validitiesAfterRemove[action.input];
                let itemAfterRemoveIsValid = true;
                for (const key in validitiesAfterRemove) {
                    itemAfterRemoveIsValid =
                        itemAfterRemoveIsValid && validitiesAfterRemove[key];
                    if (!itemAfterRemoveIsValid) {
                        break;
                    }
                }
                return {
                    itemIsValid: itemAfterRemoveIsValid,
                    itemIsEdited: true,
                    validities: validitiesAfterRemove,
                };
            case "ONLY_EDIT":
                return {
                    ...state,
                    itemIsEdited: true,
                };
            case "SAVE":
                return {
                    ...state,
                    itemIsEdited: false,
                };
            case "CLEAR_STATE":
                return {
                    itemIsValid: true,
                    itemIsEdited: false,
                    validities: undefined,
                };
        }
    };

type InputState = {
    focussed: boolean;
    hasBeenBlurred: boolean;
    isValid: boolean;
    touched: boolean;
    value: string;
};

type InputAction =
    | { isValid: boolean; type: "INPUT_CHANGE"; value: string }
    | { isValid: boolean; type: "INPUT_CHANGE_NO_FOCUS"; value: string }
    | { type: "INPUT_FOCUS" }
    | { type: "INPUT_BLUR" };

export const createInputReducer =
    () =>
    (state: InputState, action: InputAction): InputState => {
        switch (action.type) {
            case "INPUT_CHANGE":
                return {
                    ...state,
                    value: action.value,
                    isValid: action.isValid,
                    touched: true,
                    focussed: true,
                };
            case "INPUT_CHANGE_NO_FOCUS":
                return {
                    ...state,
                    value: action.value,
                    isValid: action.isValid,
                };
            case "INPUT_FOCUS":
                return {
                    ...state,
                    touched: true,
                    focussed: true,
                };
            case "INPUT_BLUR":
                return {
                    ...state,
                    touched: true,
                    focussed: false,
                    hasBeenBlurred: true,
                };
            default:
                return state;
        }
    };

export type FilterState = Record<string, Record<string, boolean>>;

export type FilterAction =
    | {
          group: keyof FilterState;
          item: string | number;
          type: "PRESS_CHIP";
      }
    | {
          group: keyof FilterState;
          item: string | number;
          type: "PRESS_CHIP_AND_DESELECT_OTHERS";
      }
    | { group: keyof FilterState; type: "DESELECT_GROUP" }
    | { type: "DESELECT_ALL" };

export const createFilterReducer =
    () =>
    (state: FilterState, action: FilterAction): FilterState => {
        switch (action.type) {
            case "PRESS_CHIP":
                const stateAfterPress = {
                    ...state[action.group],
                    [action.item]: !state[action.group][action.item],
                };
                return { ...state, [action.group]: stateAfterPress };
            case "PRESS_CHIP_AND_DESELECT_OTHERS":
                const stateAfterPressAndDeselect = {
                    ...state[action.group],
                    [action.item]: !state[action.group][action.item],
                };
                Object.keys(stateAfterPressAndDeselect).forEach((item) => {
                    if (item !== action.item) {
                        stateAfterPressAndDeselect[item] = false;
                    }
                });
                return {
                    ...state,
                    [action.group]: stateAfterPressAndDeselect,
                };
            case "DESELECT_GROUP":
                const stateAfterDeselectGroup = state[action.group];
                Object.keys(stateAfterDeselectGroup).forEach(
                    (item) => (stateAfterDeselectGroup[item] = false),
                );
                return {
                    ...state,
                    [action.group]: stateAfterDeselectGroup,
                };
            case "DESELECT_ALL":
                let stateAfterDeselectAll = state;
                Object.keys(state).forEach((group) => {
                    const groupStateAfterDeselectAll = { ...state[group] };
                    Object.keys(groupStateAfterDeselectAll).forEach(
                        (item) => (groupStateAfterDeselectAll[item] = false),
                    );
                    stateAfterDeselectAll = {
                        ...stateAfterDeselectAll,
                        [group]: groupStateAfterDeselectAll,
                    };
                });
                return { ...stateAfterDeselectAll };
            default:
                return state;
        }
    };
