import { graphql } from "react-relay";

export const load_blacklisted_dates = graphql`
    query LoadBlacklistedDatesQuery(
        $startingYear: Int!
        $school: String
        $first: Int!
        $after: String
    ) {
        ...LoadBlacklistedDates_query_blacklistedDates
            @arguments(
                startingYear: $startingYear
                school: $school
                first: $first
                after: $after
            )
    }
`;

export const load_blacklisted_dates_pagination = graphql`
    fragment LoadBlacklistedDates_query_blacklistedDates on Query
    @argumentDefinitions(
        startingYear: { type: "Int!" }
        school: { type: "String" }
        first: { type: "Int!" }
        after: { type: "String" }
    )
    @refetchable(queryName: "LoadBlacklistedDatesPaginationQuery") {
        blacklistedDates(
            school: $school
            startingYear: $startingYear
            first: $first
            after: $after
        ) @connection(key: "LoadBlacklistedDates_query_blacklistedDates") {
            __id
            edges {
                node {
                    id
                    date
                    reason
                    school {
                        id
                    }
                    teachers {
                        edges {
                            node {
                                id
                                user {
                                    id
                                    firstName
                                    lastName
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
