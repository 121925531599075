import React, { useCallback, useMemo } from "react";
import type { ReactElement } from "react";

import {
    Modal,
    VStack,
    Text,
    HStack,
    Avatar,
    Pressable,
    Center,
} from "native-base";
import type { LessonData as TimetableLessonData } from "pianofunclub-shared/types";
import { Platform } from "react-native";

import { useAuth } from "../../providers/AuthProvider";

import type { NavigationProps as AccountScreenNavigationProps } from "pianofunclub-crm/screens/accounts/AccountScreen";

import {
    LessonTimeIcon,
    RegisterIcon,
    SchoolIcon,
} from "pianofunclub-shared/components/Other/Icons";

import {
    getInitials,
    schoolYearConverter,
    titleCaseConverter,
} from "pianofunclub-shared/utils/converters";
import {
    getFullName,
    getInstrumentIcon,
    getLessonTypeIcon,
} from "pianofunclub-shared/utils/extractors";

export type TimetableLessonInfo = {
    dayIndex: number;
    lessonData: TimetableLessonData;
    lessonTimestampDate: Date;
    lessonTimestampString: string;
    lessonType: string;
    schoolAddress: string;
};

interface Props {
    hideModal: () => void;
    lessonInfo?: TimetableLessonInfo;
    navigateToRegister: (
        startingYear?: number | null,
        block?: number | null,
    ) => void;
    navigation: AccountScreenNavigationProps;
    rescheduleLesson: (
        timestamp: Date,
        lessonId: string | undefined,
        lessonDuration: number | undefined,
    ) => void;
    showModal: boolean;
}

const LessonDetailsModal = (props: Props): ReactElement | null => {
    const {
        hideModal,
        lessonInfo,
        navigateToRegister,
        navigation,
        rescheduleLesson,
        showModal,
    } = props;

    const { user } = useAuth();

    const pupilInfo = useMemo(() => {
        if (lessonInfo) {
            return {
                firstName:
                    lessonInfo.lessonData.lessonBlock?.pupil?.user.firstName,
                lastName:
                    lessonInfo.lessonData.lessonBlock?.pupil?.user.lastName,
                schoolYear:
                    lessonInfo.lessonData.lessonBlock?.pupil?.schoolYear,
                notesForTeacher:
                    lessonInfo.lessonData.lessonBlock?.pupil
                        ?.staffNoteForTeacher,
            };
        }
    }, [lessonInfo]);

    const renderModalBody = useCallback(() => {
        const fontColor = Platform.OS === "web" ? "surface.100" : "surface.900";
        if (lessonInfo && pupilInfo) {
            return (
                <VStack
                    alignItems="center"
                    justifyContent="center"
                    pb="6"
                    space={4}
                >
                    <HStack space="2">
                        <LessonTimeIcon color={fontColor} size="6" />
                        <Text color={fontColor} fontSize="md">
                            {lessonInfo.lessonTimestampString}
                        </Text>
                    </HStack>
                    <HStack>
                        {getLessonTypeIcon(lessonInfo.lessonType, {
                            size: "5",
                            color: fontColor,
                            minWidth: "7",
                            mr: "2",
                        })}
                        {getInstrumentIcon(
                            lessonInfo.lessonData.lessonBlock?.instrument?.name,
                            { color: fontColor, size: "6", mr: "2" },
                        )}
                        <Text color={fontColor} fontSize="md">
                            {`${titleCaseConverter(lessonInfo.lessonType)} ${
                                lessonInfo.lessonData.lessonDuration ??
                                lessonInfo.lessonData?.lessonBlock?.lessonStage
                                    ?.lessonDuration
                            } minute ${
                                lessonInfo.lessonData.lessonBlock?.instrument?.name?.toLowerCase() ??
                                "music"
                            } lesson`}
                        </Text>
                    </HStack>
                    <VStack mb="4">
                        <HStack space="2">
                            <SchoolIcon
                                color={fontColor}
                                minWidth="7"
                                size="5"
                            />
                            <Text color={fontColor} fontSize="md">
                                {
                                    lessonInfo.lessonData.lessonBlock?.school
                                        ?.name
                                }
                            </Text>
                        </HStack>
                        <Text
                            color={fontColor}
                            fontFamily="Poppins-Regular"
                            fontSize="md"
                            textAlign="center"
                        >
                            {lessonInfo.schoolAddress}
                        </Text>
                    </VStack>
                    <Pressable
                        alignItems="center"
                        isDisabled={
                            Platform.OS !== "web" ||
                            user?.profile?.accountType === "TEACHER"
                        }
                        mb="4"
                        onPress={() => {
                            if (lessonInfo.lessonData.lessonBlock?.pupil?.id) {
                                hideModal();
                                navigation.push("Account", {
                                    profileId:
                                        lessonInfo.lessonData.lessonBlock.pupil
                                            .id,
                                    accountType: "PUPIL",
                                    startingYear:
                                        lessonInfo.lessonData.lessonBlock
                                            ?.startingYear ?? undefined,
                                    block:
                                        lessonInfo.lessonData.lessonBlock
                                            ?.block ?? undefined,
                                });
                            }
                        }}
                    >
                        {pupilInfo.firstName ? (
                            <Avatar
                                _text={{ color: "surface.200" }}
                                bg="primary.600"
                                mb="3"
                                size="md"
                                source={{
                                    uri: undefined,
                                }}
                            >
                                {getInitials(
                                    pupilInfo.firstName,
                                    pupilInfo.lastName,
                                )}
                            </Avatar>
                        ) : null}
                        <Text
                            color={fontColor}
                            fontSize="md"
                            textAlign="center"
                        >
                            {pupilInfo.firstName
                                ? getFullName(
                                      pupilInfo.firstName,
                                      pupilInfo.lastName,
                                  )
                                : "Free Slot"}
                        </Text>
                        {pupilInfo.schoolYear ? (
                            <Text
                                color={fontColor}
                                fontFamily="Poppins-Regular"
                                fontSize="md"
                                textAlign="center"
                            >
                                {schoolYearConverter(pupilInfo.schoolYear)}
                            </Text>
                        ) : null}
                        {pupilInfo.notesForTeacher ? (
                            <Text
                                color={fontColor}
                                fontFamily="Poppins-Regular"
                                fontSize="md"
                                fontStyle="italic"
                                mt="1"
                                textAlign="center"
                            >
                                {pupilInfo.notesForTeacher}
                            </Text>
                        ) : null}
                    </Pressable>
                    <HStack space="16">
                        <Pressable
                            onPress={() => {
                                hideModal();
                                rescheduleLesson(
                                    lessonInfo.lessonTimestampDate,
                                    lessonInfo.lessonData?.id,
                                    lessonInfo.lessonData?.lessonDuration ??
                                        lessonInfo.lessonData?.lessonBlock
                                            ?.lessonStage?.lessonDuration ??
                                        undefined,
                                );
                            }}
                        >
                            <VStack alignItems="center" space="4">
                                <Center
                                    bg="primary.600"
                                    borderRadius="full"
                                    size={20}
                                >
                                    <LessonTimeIcon
                                        color="surface.100"
                                        size={10}
                                    />
                                </Center>
                                <Text
                                    color={fontColor}
                                    fontSize="lg"
                                    textAlign="center"
                                >
                                    {"Reschedule\nLesson"}
                                </Text>
                            </VStack>
                        </Pressable>
                        <Pressable
                            onPress={() => {
                                hideModal();
                                navigateToRegister(
                                    lessonInfo.lessonData.lessonBlock
                                        ?.startingYear,
                                    lessonInfo.lessonData.lessonBlock?.block,
                                );
                            }}
                        >
                            <VStack alignItems="center" space="4">
                                <Center
                                    bg="primary.600"
                                    borderRadius="full"
                                    size={20}
                                >
                                    <RegisterIcon
                                        color="surface.100"
                                        size={10}
                                    />
                                </Center>
                                <Text
                                    color={fontColor}
                                    fontSize="lg"
                                    textAlign="center"
                                >
                                    {"Update\nRegister"}
                                </Text>
                            </VStack>
                        </Pressable>
                    </HStack>
                </VStack>
            );
        } else {
            return null;
        }
    }, [
        hideModal,
        lessonInfo,
        navigateToRegister,
        navigation,
        pupilInfo,
        rescheduleLesson,
        user?.profile?.accountType,
    ]);

    if (lessonInfo && pupilInfo) {
        return (
            <Modal
                isOpen={showModal}
                onClose={() => {
                    hideModal();
                }}
                size="lg"
            >
                <Modal.Content
                    bg={Platform.OS === "web" ? "primary.800" : "surface.100"}
                >
                    <Modal.CloseButton
                        _hover={{ bg: "transparent", opacity: 0.7 }}
                        _icon={{ color: "surface.100" }}
                        _pressed={{ bg: "transparent", opacity: 0.7 }}
                    />
                    <Modal.Header
                        _text={{ color: "surface.100" }}
                        bg="primary.800"
                    >
                        {`${
                            titleCaseConverter(
                                lessonInfo.lessonData.lessonBlock?.instrument
                                    ?.name,
                            ) ?? "Music"
                        } Lesson`}
                    </Modal.Header>
                    <Modal.Body alignItems="center" mb="4" px="6">
                        {renderModalBody()}
                    </Modal.Body>
                </Modal.Content>
            </Modal>
        );
    } else {
        return null;
    }
};

export default React.memo(LessonDetailsModal);
