import { format } from "date-fns";

export const dateIsEqual = (date1: Date, date2: Date) =>
    date1.toISOString().split("T")[0] === date2.toISOString().split("T")[0];

export const getFormattedDate = (
    date: Date | string | undefined | null,
    options?: {
        dateFormat?: string;
    },
) => {
    const { dateFormat = "yyyy-MM-dd" } = options ?? {};

    if (!date) {
        return undefined;
    }

    if (typeof date === "string") {
        date = new Date(date);
    }

    return format(date, dateFormat);
};
