/**
 * @generated SignedSource<<e885f8cf183b4ee10ec3b5fe8287fa89>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateInvoiceMessageInput = {
  clientMutationId?: string | null;
  invoiceEmailSubject?: string | null;
  invoiceId: string;
  invoiceMessageString?: string | null;
};
export type UpdateInvoiceMessageMutation$variables = {
  input: UpdateInvoiceMessageInput;
};
export type UpdateInvoiceMessageMutation$data = {
  readonly updateInvoiceMessage: {
    readonly errors: any | null;
    readonly invoice: {
      readonly id: string;
      readonly invoiceEmailSubject: string | null;
      readonly invoiceMessageString: string | null;
    } | null;
    readonly success: boolean | null;
  } | null;
};
export type UpdateInvoiceMessageMutation = {
  response: UpdateInvoiceMessageMutation$data;
  variables: UpdateInvoiceMessageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateInvoiceMessagePayload",
    "kind": "LinkedField",
    "name": "updateInvoiceMessage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceNode",
        "kind": "LinkedField",
        "name": "invoice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "invoiceEmailSubject",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "invoiceMessageString",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateInvoiceMessageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateInvoiceMessageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d0554d8ede1937b02701af74b2e3d56c",
    "id": null,
    "metadata": {},
    "name": "UpdateInvoiceMessageMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateInvoiceMessageMutation(\n  $input: UpdateInvoiceMessageInput!\n) {\n  updateInvoiceMessage(input: $input) {\n    success\n    errors\n    invoice {\n      id\n      invoiceEmailSubject\n      invoiceMessageString\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4679fbc78818e8294ac8c0ce6ecf9caa";

export default node;
