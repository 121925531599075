import { extendTheme } from "native-base";

export const customColors = {
    primary: {
        // based on #0B6327
        50: "#E1EDE7",
        100: "#CCE4D9",
        200: "#A2D2BD",
        300: "#7DB798",
        400: "#579B72",
        500: "#317F4D",
        600: "#0B6327",
        700: "#0B5028",
        800: "#0B3C29",
        900: "#061E15",
    },
    primaryVariant: {
        // based on #0B6327 (but shifted towards white)
        // required because native base defaults to 800
        600: "#E1EDE7",
        700: "#CCE4D9",
        800: "#A2D2BD",
        900: "#7DB798",
    },
    // rarely used - only when another colour is needed to differentiate
    secondary: {
        // based on #0599DC
        50: "#F0F9FD",
        100: "#E5EDF0",
        200: "#C1E6F7",
        300: "#B2E0F5",
        400: "#A2D9F3",
        500: "#82CCEE",
        600: "#0599DC",
        700: "#0473A5",
        800: "#034D6E",
        900: "#022737",
    },
    surface: {
        // based on #A3A3A3
        50: "#F5F5F5",
        100: "#F1F1F1",
        200: "#DBDBDB",
        300: "#D0D0D0",
        400: "#C4C4C4",
        500: "#B4B4B4",
        600: "#A3A3A3",
        700: "#7B7B7B",
        800: "#525252",
        900: "#3D3C3C",
    },
};

// this theme is used by Native Base (main component library)
const PianoFunClubNativeBaseTheme = extendTheme({
    // colour shades created using Coolors
    colors: customColors,
    fontConfig: {
        Poppins: {
            // https://fonts.google.com/specimen/Poppins
            200: {
                normal: "Poppins-Light",
                italic: "Poppins-LightItalic",
            },
            300: {
                normal: "Poppins-Regular",
                italic: "Poppins-Italic",
            },
            400: {
                normal: "Poppins-SemiBold",
                italic: "Poppins-SemiBoldItalic",
            },
            600: {
                normal: "Poppins-SemiBold",
                italic: "Poppins-SemiBoldItalic",
            },
            700: {
                normal: "Poppins-Bold",
                italic: "Poppins-BoldItalic",
            },
            800: {
                normal: "Poppins-ExtraBold",
                italic: "Poppins-ExtraBoldItalic",
            },
            900: {
                normal: "Poppins-Black",
                italic: "Poppins-BlackItalic",
            },
        },
    },
    fonts: {
        heading: "Poppins",
        body: "Poppins",
        mono: "Poppins",
    },
    components: {
        Button: {
            defaultProps: {
                _text: {
                    fontFamily: "Poppins-SemiBold",
                    textAlign: "center",
                    allowFontScaling: false,
                    fontSize: "md",
                },
                _loading: { opacity: 1 },
                borderRadius: "2xl",
            },
        },
        Text: {
            defaultProps: {
                color: "surface.900",
                allowFontScaling: false,
                includeFontPadding: false,
            },
        },
        Input: {
            defaultProps: {
                borderRadius: "20",
                allowFontScaling: false,
                selectionColor: "primary.300",
            },
        },
        Checkbox: {
            defaultProps: {
                bg: "surface.100",
                borderColor: "surface.200",
            },
        },
        Pressable: {
            defaultProps: {
                _pressed: { opacity: 0.6 },
                _hover: { opacity: 0.7 },
            },
        },
        Tooltip: {
            defaultProps: {
                borderRadius: "xl",
            },
        },
        Modal: {
            defaultProps: {
                _backdrop: {
                    _pressed: { opacity: 0.3 },
                    _hover: { opacity: 0.3 },
                },
            },
        },
        ModalContent: {
            defaultProps: {
                borderRadius: "2xl",
                bg: "surface.50",
            },
        },
        ModalHeader: {
            defaultProps: {
                _text: { fontSize: "xl", mx: "8", textAlign: "center" },
                borderBottomWidth: 0,
                bg: "surface.50",
            },
        },
        ModalFooter: {
            defaultProps: {
                borderTopWidth: 0,
            },
        },
        ModalCloseButton: {
            defaultProps: {
                p: "2.5",
                mr: "-1",
                borderRadius: "2xl",
            },
        },
        AlertDialog: {
            defaultProps: {
                _backdrop: {
                    _pressed: { opacity: 0.3 },
                    _hover: { opacity: 0.3 },
                },
            },
        },
        AlertDialogContent: {
            defaultProps: {
                bg: "surface.100",
                borderRadius: "2xl",
            },
        },
        AlertDialogHeader: {
            defaultProps: {
                _text: { fontSize: "xl", mx: 2 },
                borderBottomWidth: 0,
            },
        },
        AlertDialogFooter: {
            defaultProps: {
                borderTopWidth: 0,
            },
        },
        AlertDialogCloseButton: {
            defaultProps: {
                borderRadius: "xl",
            },
        },
        Divider: {
            defaultProps: {
                bg: "surface.700",
                height: "50%",
                orientation: "vertical",
                borderRadius: "full",
            },
        },
        Heading: {
            defaultProps: {
                color: "surface.900",
                allowFontScaling: false,
                fontSize: "lg",
            },
        },
        HStack: {
            defaultProps: {
                alignItems: "center",
            },
        },
    },
    config: {
        initialColorMode: "light",
    },
});

export type PianoFunClubThemeType = typeof PianoFunClubNativeBaseTheme;

declare module "native-base" {
    interface ICustomTheme extends PianoFunClubThemeType {}
}

export default PianoFunClubNativeBaseTheme;
