import { graphql } from "react-relay";

export const refresh_token = graphql`
    mutation RefreshTokenMutation($input: RefreshTokenInput!) {
        refreshToken(input: $input) {
            success
            errors
            payload
            token
            refreshToken
        }
    }
`;
