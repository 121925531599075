import React, { useState, useRef, useMemo } from "react";
import type { FC } from "react";

import { format } from "date-fns";
import { Text, Button, VStack, Center, Pressable } from "native-base";

import { useAuth } from "../../../../packages/shared/providers/AuthProvider";
import type {
    ProfileStackNavigatorProps,
    ProfileStackRouteProps,
} from "../../navigation/ProfileNavigator";
import { FULL_LOGO } from "../../utils/assets";
import ButtonDebounced from "pianofunclub-shared/components/Buttons/ButtonDebounced";
import AlertPopup from "pianofunclub-shared/components/NativeBaseExtended/AlertPopup";
import {
    EditIcon,
    EmailIcon,
    LogoutIcon,
    ProfileIcon,
    SecurityIcon,
} from "pianofunclub-shared/components/Other/Icons";

type NavigationProps = ProfileStackNavigatorProps<"ProfileHub">;

type RouteProps = ProfileStackRouteProps<"ProfileHub">;

interface ScreenProps {
    navigation: NavigationProps;
    route: RouteProps;
}

const ProfileHubScreen: FC<ScreenProps> = (props) => {
    const { navigation } = props;

    const { logout, user } = useAuth();

    const [logoutAlertIsOpen, setLogoutAlertIsOpen] = useState(false);
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const safeLogoutRef = useRef(null); // used to highlight the 'back' button in the logout button alert dialogue

    const renderAccountOptions = useMemo(() => {
        return (
            <VStack
                alignItems="center"
                bg="primary.50"
                borderColor="primary.100"
                borderRadius="3xl"
                borderWidth={1}
                my="16"
                pb="4"
                pt="6"
                shadow={1}
                space="8"
                width="500px"
            >
                <VStack alignItems="center" space="2">
                    <ProfileIcon color="primary.500" size="24" />
                    <Text fontSize="lg">
                        {user?.firstName} {user?.lastName ?? ""}
                    </Text>
                    {user?.dateJoined ? (
                        <Text color="surface.700">
                            Joined{" "}
                            {format(new Date(user?.dateJoined), "d MMM yyyy")}
                        </Text>
                    ) : null}
                </VStack>
                <VStack alignItems="center" space="1">
                    <Button
                        _hover={{
                            bg: "transparent",
                            opacity: 0.8,
                        }}
                        _pressed={{
                            bg: "transparent",
                            opacity: 0.7,
                        }}
                        _spinner={{ size: "lg" }}
                        _text={{ fontSize: "lg", color: "surface.900" }}
                        colorScheme="surface"
                        leftIcon={
                            <EditIcon color="surface.800" mr="1" size="lg" />
                        }
                        onPress={() => navigation.navigate("ChangeName")}
                        variant="ghost"
                    >
                        Change Name
                    </Button>
                    <Button
                        _hover={{
                            bg: "transparent",
                            opacity: 0.8,
                        }}
                        _pressed={{
                            bg: "transparent",
                            opacity: 0.7,
                        }}
                        _spinner={{ size: "lg" }}
                        _text={{ fontSize: "lg", color: "surface.900" }}
                        colorScheme="surface"
                        leftIcon={
                            <EmailIcon color="surface.800" mr="1" size="lg" />
                        }
                        onPress={() => navigation.navigate("UpdateEmail")}
                        variant="ghost"
                    >
                        Update Email Address
                    </Button>
                    <Button
                        _hover={{
                            bg: "transparent",
                            opacity: 0.8,
                        }}
                        _pressed={{
                            bg: "transparent",
                            opacity: 0.7,
                        }}
                        _spinner={{ size: "lg" }}
                        _text={{ fontSize: "lg", color: "surface.900" }}
                        colorScheme="surface"
                        leftIcon={
                            <SecurityIcon
                                color="surface.800"
                                mr="1"
                                size="lg"
                            />
                        }
                        onPress={() => navigation.navigate("ChangePassword")}
                        variant="ghost"
                    >
                        Change Password
                    </Button>
                    <Button
                        _hover={{
                            bg: "transparent",
                            opacity: 0.8,
                        }}
                        _pressed={{
                            bg: "transparent",
                            opacity: 0.7,
                        }}
                        _spinner={{ size: "lg" }}
                        _text={{ fontSize: "lg", color: "surface.900" }}
                        colorScheme="surface"
                        leftIcon={
                            <LogoutIcon color="surface.800" mr="1" size="lg" />
                        }
                        onPress={() => setLogoutAlertIsOpen(true)}
                        variant="ghost"
                    >
                        Logout
                    </Button>
                </VStack>
            </VStack>
        );
    }, [navigation, user?.dateJoined, user?.firstName, user?.lastName]);

    const renderSoftwareVersion = useMemo(() => {
        return (
            <VStack alignItems="center" flexGrow={1} my="2%" space="4">
                <Pressable
                    onPress={() =>
                        window.open("https://www.pianofunclub.co.uk/", "_blank")
                    }
                >
                    <FULL_LOGO
                        height="6em"
                        viewBox="0 0 4745 1924"
                        width="15em"
                    />
                </Pressable>
                <Text
                    color="surface.700"
                    fontFamily="Poppins-Regular"
                    textAlign="center"
                >
                    {
                        "PianoFunClub CRM v2.16.0\nLast updated: 03 Sep 2024\nDesigned and built by "
                    }
                    <Pressable
                        onPress={() =>
                            window.open(
                                "https://www.squidinksoftware.co.uk/",
                                "_blank",
                            )
                        }
                    >
                        <Text
                            fontFamily="Poppins-SemiBold"
                            textDecorationLine="underline"
                        >
                            {"Squid Ink Software"}
                        </Text>
                    </Pressable>
                </Text>
            </VStack>
        );
    }, []);

    return (
        <Center bg="surface.100" flex={1} pt="70">
            {renderAccountOptions}
            {renderSoftwareVersion}
            <AlertPopup
                alertIsOpen={logoutAlertIsOpen}
                closeOnOverlayClick
                header="Are you sure you want to logout?"
                safeButtonRef={safeLogoutRef}
                setAlertIsOpen={setLogoutAlertIsOpen}
            >
                <Button
                    ref={safeLogoutRef}
                    _text={{ fontSize: "xl" }}
                    colorScheme="primary"
                    height="60px"
                    mb="2"
                    minWidth="60"
                    onPress={() => {
                        setLogoutAlertIsOpen(false);
                    }}
                    width="25%"
                >
                    No
                </Button>
                <ButtonDebounced
                    _loading={{ opacity: 1 }}
                    _text={{ fontSize: "xl" }}
                    colorScheme="red"
                    height="60px"
                    isLoading={isLoggingOut}
                    mb="2"
                    minWidth="60"
                    onPress={() => {
                        setIsLoggingOut(true);
                        logout(() => {
                            props.navigation
                                .getParent()
                                ?.navigate("AccountsHubTab");
                        });
                    }}
                    width="25%"
                >
                    Yes
                </ButtonDebounced>
            </AlertPopup>
        </Center>
    );
};

export const screenOptions = {
    headerTitle: "Profile",
};

export default ProfileHubScreen;
