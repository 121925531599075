import React from "react";
import type { FC } from "react";

import { View, ActivityIndicator } from "react-native";

// doesn't use NativeBase because it's rendered outside of NativeBaseProvider

const LoadingScreen: FC = () => {
    return (
        <View
            style={{
                flex: 1,
                backgroundColor: "#0B3C29",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <ActivityIndicator color="#7DB798" size="large" />
        </View>
    );
};

export default LoadingScreen;
