/**
 * @generated SignedSource<<7a42c235845fe6e3ac4f29f1ac88a2cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateTermDatesInput = {
  clientMutationId?: string | null;
  school?: string | null;
  startingYear: number;
  term1Dates?: TermDatesInputForTerm | null;
  term2Dates?: TermDatesInputForTerm | null;
  term3Dates?: TermDatesInputForTerm | null;
};
export type TermDatesInputForTerm = {
  firstDayOfSecondHalfOfTerm?: string | null;
  firstDayOfTerm?: string | null;
  lastDayOfFirstHalfOfTerm?: string | null;
  lastDayOfTerm?: string | null;
};
export type UpdateTermDatesMutation$variables = {
  input: UpdateTermDatesInput;
};
export type UpdateTermDatesMutation$data = {
  readonly updateTermDates: {
    readonly errors: any | null;
    readonly success: boolean | null;
    readonly term1Dates: {
      readonly firstDayOfSecondHalfOfTerm: string | null;
      readonly firstDayOfTerm: string | null;
      readonly id: string;
      readonly lastDayOfFirstHalfOfTerm: string | null;
      readonly lastDayOfTerm: string | null;
      readonly school: {
        readonly id: string;
      } | null;
      readonly startingYear: number | null;
      readonly term: number | null;
    } | null;
    readonly term2Dates: {
      readonly firstDayOfSecondHalfOfTerm: string | null;
      readonly firstDayOfTerm: string | null;
      readonly id: string;
      readonly lastDayOfFirstHalfOfTerm: string | null;
      readonly lastDayOfTerm: string | null;
      readonly school: {
        readonly id: string;
      } | null;
      readonly startingYear: number | null;
      readonly term: number | null;
    } | null;
    readonly term3Dates: {
      readonly firstDayOfSecondHalfOfTerm: string | null;
      readonly firstDayOfTerm: string | null;
      readonly id: string;
      readonly lastDayOfFirstHalfOfTerm: string | null;
      readonly lastDayOfTerm: string | null;
      readonly school: {
        readonly id: string;
      } | null;
      readonly startingYear: number | null;
      readonly term: number | null;
    } | null;
  } | null;
};
export type UpdateTermDatesMutation = {
  response: UpdateTermDatesMutation$data;
  variables: UpdateTermDatesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "startingYear",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "term",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "SchoolNode",
    "kind": "LinkedField",
    "name": "school",
    "plural": false,
    "selections": [
      (v1/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstDayOfTerm",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastDayOfFirstHalfOfTerm",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstDayOfSecondHalfOfTerm",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastDayOfTerm",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateTermDatesPayload",
    "kind": "LinkedField",
    "name": "updateTermDates",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TermDatesNode",
        "kind": "LinkedField",
        "name": "term1Dates",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TermDatesNode",
        "kind": "LinkedField",
        "name": "term2Dates",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TermDatesNode",
        "kind": "LinkedField",
        "name": "term3Dates",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateTermDatesMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateTermDatesMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "510445592de4860d22ee2034d4592e23",
    "id": null,
    "metadata": {},
    "name": "UpdateTermDatesMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateTermDatesMutation(\n  $input: UpdateTermDatesInput!\n) {\n  updateTermDates(input: $input) {\n    success\n    errors\n    term1Dates {\n      id\n      startingYear\n      term\n      school {\n        id\n      }\n      firstDayOfTerm\n      lastDayOfFirstHalfOfTerm\n      firstDayOfSecondHalfOfTerm\n      lastDayOfTerm\n    }\n    term2Dates {\n      id\n      startingYear\n      term\n      school {\n        id\n      }\n      firstDayOfTerm\n      lastDayOfFirstHalfOfTerm\n      firstDayOfSecondHalfOfTerm\n      lastDayOfTerm\n    }\n    term3Dates {\n      id\n      startingYear\n      term\n      school {\n        id\n      }\n      firstDayOfTerm\n      lastDayOfFirstHalfOfTerm\n      firstDayOfSecondHalfOfTerm\n      lastDayOfTerm\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "78e1c7a847fe6e3bda3371364e79fb07";

export default node;
