import { graphql } from "react-relay";

export const edit_blacklisted_date = graphql`
    mutation EditBlacklistedDateMutation($input: EditBlacklistedDateInput!) {
        editBlacklistedDate(input: $input) {
            success
            errors
            numberOfAffectedExistingLessons
            blacklistedDate {
                id
                date
                reason
                teachers {
                    edges {
                        node {
                            id
                            user {
                                id
                                firstName
                                lastName
                            }
                        }
                    }
                }
            }
        }
    }
`;
