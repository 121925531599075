import { graphql } from "react-relay";

export const bulk_upload_new_enrolments = graphql`
    mutation BulkUploadNewEnrolmentsMutation(
        $input: BulkUploadNewEnrolmentsInput!
    ) {
        bulkUploadNewEnrolments(input: $input) {
            success
            errors
        }
    }
`;
