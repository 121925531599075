import React from "react";
import type { FC, ComponentProps } from "react";

import LottieView from "lottie-react-native";
import { Center } from "native-base";

type LottieViewProps = Omit<ComponentProps<typeof LottieView>, "source">;
type CenterProps = ComponentProps<typeof Center>;

interface Props extends LottieViewProps {
    containerStyle?: CenterProps;
    outerContainerStyle?: CenterProps;
}

const WelcomeScreen: FC<Props> = (props) => {
    return (
        <Center bg="primary.800" flex={1} {...props.outerContainerStyle}>
            <Center height="500" width="500" {...props.containerStyle}>
                <LottieView
                    autoPlay
                    loop={false}
                    resizeMode="contain"
                    {...props}
                    source={require("../assets/animations/WelcomeLogo.json")}
                />
            </Center>
        </Center>
    );
};

export default WelcomeScreen;
