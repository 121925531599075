import React, { useCallback, useReducer, useMemo, useRef } from "react";
import type { FC } from "react";

import {
    Box,
    Text,
    VStack,
    useToast,
    Center,
    Spinner,
    Button,
} from "native-base";
import { useMutation } from "react-relay";
import type { RecordSourceSelectorProxy } from "relay-runtime";

import { useAuth } from "pianofunclub-shared/providers/AuthProvider";

import SideBar from "../../components/Drawers/SideBar";
import type {
    ProfileStackNavigatorProps,
    ProfileStackRouteProps,
} from "../../navigation/ProfileNavigator";
import TextInput from "pianofunclub-shared/components/Inputs/TextInput";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";

import type {
    UpdateAccountMutation,
    UpdateAccountMutation$data,
} from "pianofunclub-shared/relay/graphql/auth/__generated__/UpdateAccountMutation.graphql";
import { update_account } from "pianofunclub-shared/relay/graphql/auth/UpdateAccount";

import { createFormReducer } from "pianofunclub-shared/utils/reducers";

type NavigationProps = ProfileStackNavigatorProps<"ChangeName">;

type RouteProps = ProfileStackRouteProps<"ChangeName">;

interface ScreenProps {
    navigation: NavigationProps;
    route: RouteProps;
}

type InputValues = {
    firstName: string;
    lastName: string;
};

type InputTypes = string;

const SIDE_BAR_WIDTH = 12;

const ChangeNameScreen: FC<ScreenProps> = (props) => {
    const { navigation } = props;

    const { user } = useAuth();

    const initialState = useMemo(() => {
        return {
            inputValues: {
                firstName: user?.firstName ?? "",
                lastName: user?.lastName ?? "",
            },
            inputValidities: {
                firstName: true,
                lastName: true,
            },
            formIsValid: true,
            formIsEdited: false,
        };
    }, [user?.firstName, user?.lastName]);

    const formReducer = createFormReducer<InputValues, InputTypes>(
        initialState,
    );
    const [formState, dispatchFormState] = useReducer(
        formReducer,
        initialState,
    );

    const updateToast = useToast();
    const updateToastId = "updateToast";
    const lastNameInputRef = useRef<{ focus: () => void }>(null);

    const [commitUpdateAccount, updateAccountInFlight] =
        useMutation<UpdateAccountMutation>(update_account);

    const updateAccount = useCallback(
        (firstName: string, lastName: string) => {
            const updateAccountConfig = {
                variables: {
                    input: {
                        firstName: firstName,
                        lastName: lastName,
                    },
                },
                updater: (store: RecordSourceSelectorProxy) => {
                    if (user?.id) {
                        const userRecord = store.get(user.id);
                        if (userRecord) {
                            userRecord.setValue(firstName, "firstName");
                            userRecord.setValue(lastName, "lastName");
                        }
                    }
                },
                onCompleted: (response: UpdateAccountMutation$data) => {
                    if (response?.updateAccount?.success) {
                        if (!updateToast.isActive(updateToastId)) {
                            updateToast.show({
                                id: updateToastId,
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        id={id}
                                        status="success"
                                        title={"Name updated"}
                                        toast={updateToast}
                                    />
                                ),
                            });
                        }
                        navigation.goBack();
                        // * add proper error handling (different toast for invalid password)
                    } else {
                        if (!updateToast.isActive(updateToastId)) {
                            updateToast.show({
                                id: updateToastId,
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        id={id}
                                        status="error"
                                        title={"Name could not be updated"}
                                        toast={updateToast}
                                    />
                                ),
                            });
                        }
                    }
                },
                onError: () => {
                    if (!updateToast.isActive(updateToastId)) {
                        updateToast.show({
                            id: updateToastId,
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="error"
                                    title={"Name could not be updated"}
                                    toast={updateToast}
                                />
                            ),
                        });
                    }
                },
            };

            commitUpdateAccount(updateAccountConfig);
        },
        [commitUpdateAccount, navigation, updateToast, user?.id],
    );

    const inputChangeHandler = useCallback(
        (
            inputIdentifier?: string | number,
            inputValue?: string,
            isValid?: boolean,
        ) => {
            if (
                typeof inputIdentifier === "string" &&
                typeof inputValue === "string"
            ) {
                dispatchFormState({
                    value: inputValue,
                    isValid: isValid,
                    input: inputIdentifier,
                });
            }
        },
        [dispatchFormState],
    );

    const inputSubmitHandler = useCallback(
        (inputIdentifier?: string | number) => {
            switch (inputIdentifier) {
                case "firstName":
                    lastNameInputRef.current?.focus();
                    break;
                case "lastName":
                    if (formState.formIsValid && !updateAccountInFlight) {
                        updateAccount(
                            formState.inputValues.firstName,
                            formState.inputValues.lastName,
                        );
                    }
            }
        },
        [
            formState.formIsValid,
            formState.inputValues.firstName,
            formState.inputValues.lastName,
            updateAccount,
            updateAccountInFlight,
        ],
    );

    return (
        <Box flex={1}>
            <SideBar
                isCollapsed
                navigation={navigation}
                width={SIDE_BAR_WIDTH}
            />
            <Center bg="surface.100" flex={1} ml={SIDE_BAR_WIDTH} pt="70">
                <VStack alignItems="center" space="4">
                    <Text fontSize="2xl" fontWeight={700} mb="4">
                        Change Name
                    </Text>
                    <TextInput
                        autoCapitalize="words"
                        centerLabel
                        errorMessage="Please enter a first name."
                        id="firstName"
                        initiallyValid={true}
                        initialValue={user?.firstName}
                        invalidIndicator
                        keyboardType="default"
                        label="First Name"
                        labelStyle={{ color: "surface.700" }}
                        maxLength={30}
                        onInputChange={inputChangeHandler}
                        onSubmit={inputSubmitHandler}
                        placeholder="Enter your first name"
                        size="xl"
                        textAlign="center"
                    />
                    <TextInput
                        ref={lastNameInputRef}
                        autoCapitalize="words"
                        centerLabel
                        errorMessage="Please enter a last name."
                        id="lastName"
                        initiallyValid={true}
                        initialValue={user?.lastName}
                        invalidIndicator
                        keyboardType="default"
                        label="Last Name"
                        labelStyle={{ color: "surface.700" }}
                        maxLength={30}
                        mb="4"
                        onInputChange={inputChangeHandler}
                        onSubmit={inputSubmitHandler}
                        placeholder="Enter your last name"
                        size="xl"
                        textAlign="center"
                    />
                    <Button
                        colorScheme="primary"
                        height="60px"
                        isDisabled={
                            !formState.formIsValid || updateAccountInFlight
                        }
                        onPress={() => {
                            updateAccount(
                                formState.inputValues.firstName,
                                formState.inputValues.lastName,
                            );
                        }}
                        width="200px"
                    >
                        {updateAccountInFlight ? (
                            <Spinner color="white" />
                        ) : (
                            <Text color="white" fontSize="xl">
                                Save
                            </Text>
                        )}
                    </Button>
                    <Button
                        _hover={{ bg: "transparent", opacity: 0.8 }}
                        _pressed={{ bg: "transparent", opacity: 0.7 }}
                        colorScheme="surface"
                        mt="4"
                        onPress={() => navigation.goBack()}
                        variant="ghost"
                    >
                        Go Back
                    </Button>
                </VStack>
            </Center>
        </Box>
    );
};

export const screenOptions = {
    headerTitle: "Change Name",
};

export default React.memo(ChangeNameScreen);
