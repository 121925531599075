import { graphql } from "react-relay";

export const edit_profile = graphql`
    mutation EditProfileMutation($input: EditProfileInput!) {
        editProfile(input: $input) {
            success
            errors
            profile {
                id
                school {
                    id
                    name
                }
                phoneNumber
                schoolYear
                schoolClass
                addressFirstLine
                addressSecondLine
                city
                postcode
                dbsNumber
                dbsExpirationDate
                paidBySchool
                discountCategory
                discountPercentage
                hasBook
                hasInstrument
                instrumentSuppliedByUs
                hasReturnedInstrument
                staffNoteForTeacher
                user {
                    id
                    firstName
                    lastName
                    email
                }
            }
        }
    }
`;
