/**
 * @generated SignedSource<<961e111e349a476a9a42b71c1ed48dda>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProfileModelAccountType = "HEAD_TEACHER" | "PARENT" | "PUPIL" | "SCHOOL_CONTACT" | "SCHOOL_TEACHER" | "STAFF" | "TEACHER" | "%future added value";
export type AutoLoginInput = {
  clientMutationId?: string | null;
  username: string;
};
export type AutoLoginMutation$variables = {
  input: AutoLoginInput;
};
export type AutoLoginMutation$data = {
  readonly autoLogin: {
    readonly errors: any | null;
    readonly success: boolean | null;
    readonly user: {
      readonly id: string;
      readonly profile: {
        readonly accountType: ProfileModelAccountType;
        readonly id: string;
      } | null;
    } | null;
  } | null;
};
export type AutoLoginMutation = {
  response: AutoLoginMutation$data;
  variables: AutoLoginMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AutoLoginPayload",
    "kind": "LinkedField",
    "name": "autoLogin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DjangoUserNode",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountType",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AutoLoginMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AutoLoginMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "cd97fd2cd6f78797e2aaa4386180e325",
    "id": null,
    "metadata": {},
    "name": "AutoLoginMutation",
    "operationKind": "mutation",
    "text": "mutation AutoLoginMutation(\n  $input: AutoLoginInput!\n) {\n  autoLogin(input: $input) {\n    success\n    errors\n    user {\n      id\n      profile {\n        id\n        accountType\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1b68280754368e560020eb918c58f314";

export default node;
