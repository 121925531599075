import { graphql } from "react-relay";

export const load_accounts = graphql`
    query LoadAccountsQuery(
        $orderBy: String
        $searchTerm: String
        $accountType: String!
        $status: String
        $isDiscounted: Boolean
        $school: String
        $first: Int!
        $after: String
    ) {
        ...LoadAccounts_query_profiles
            @arguments(
                orderBy: $orderBy
                searchTerm: $searchTerm
                accountType: $accountType
                status: $status
                isDiscounted: $isDiscounted
                school: $school
                first: $first
                after: $after
            )
    }
`;

export const load_accounts_pagination = graphql`
    fragment LoadAccounts_query_profiles on Query
    @argumentDefinitions(
        orderBy: { type: "String" }
        searchTerm: { type: "String" }
        accountType: { type: "String!" }
        status: { type: "String" }
        isDiscounted: { type: "Boolean" }
        school: { type: "String" }
        first: { type: "Int!" }
        after: { type: "String" }
    )
    @refetchable(queryName: "LoadAccountsPaginationQuery") {
        profiles(
            orderBy: $orderBy
            searchTerm: $searchTerm
            accountType: $accountType
            status: $status
            isDiscounted: $isDiscounted
            school: $school
            first: $first
            after: $after
        ) @connection(key: "LoadAccounts_query_profiles") {
            __id
            edges {
                node {
                    id
                    accountNumber
                    accountType
                    phoneNumber
                    schoolYear
                    status
                    discountCategory
                    discountPercentage
                    dbsNumber
                    dbsExpirationDate
                    school {
                        id
                        name
                    }
                    user {
                        firstName
                        lastName
                        email
                        isSuperuser
                    }
                    primaryParent {
                        id
                        accountNumber
                        user {
                            firstName
                            lastName
                        }
                    }
                }
            }
        }
    }
`;
