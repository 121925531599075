import React from "react";
import type { ReactElement } from "react";

import type {
    CompositeNavigationProp,
    RouteProp,
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import type { StackNavigationProp } from "@react-navigation/stack";

import WaitingListHubScreen from "../screens/waitingList/WaitingListHubScreen";
import WaitingListScreen from "../screens/waitingList/WaitingListScreen";

import type { HubTabNavigatorProps } from "./HubNavigator";
import defaultNavOptions from "./utils/DefaultNavigationOptions";
import type { WaitingListParams } from "./utils/ScreenParams";

export type WaitingListNavigatorParamList = {
    WaitingList: WaitingListParams;
    WaitingListHub?: {
        instrument?: string;
        lessonStageIndex?: string;
        orderBy?: string;
        parentEmail?: string;
        school?: string;
        schoolYear?: string;
        searchTerm?: string;
    };
};

type NavigatorProps<T extends keyof WaitingListNavigatorParamList> =
    StackNavigationProp<WaitingListNavigatorParamList, T>;

export type WaitingListStackNavigatorProps<
    T extends keyof WaitingListNavigatorParamList,
> = CompositeNavigationProp<
    NavigatorProps<T>,
    HubTabNavigatorProps<"WaitingListHubTab">
>;

export type WaitingListStackRouteProps<
    T extends keyof WaitingListNavigatorParamList,
> = RouteProp<WaitingListNavigatorParamList, T>;

const WaitingListStackNavigator =
    createStackNavigator<WaitingListNavigatorParamList>();

const WaitingListNavigator = (): ReactElement => {
    return (
        <WaitingListStackNavigator.Navigator screenOptions={defaultNavOptions}>
            <WaitingListStackNavigator.Screen
                component={WaitingListHubScreen}
                name="WaitingListHub"
                options={screenOptions}
            ></WaitingListStackNavigator.Screen>
            <WaitingListStackNavigator.Screen
                component={WaitingListScreen}
                name="WaitingList"
                options={screenOptions}
            ></WaitingListStackNavigator.Screen>
        </WaitingListStackNavigator.Navigator>
    );
};

export default WaitingListNavigator;

const screenOptions = {
    headerTitle: "Waiting List",
};
