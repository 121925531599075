import { graphql } from "react-relay";

export const delete_draft_invoice = graphql`
    mutation DeleteDraftInvoiceMutation(
        $connections: [ID!]!
        $input: DeleteDraftInvoiceInput!
    ) {
        deleteDraftInvoice(input: $input) {
            success
            errors
            deletedInvoiceId @deleteEdge(connections: $connections)
        }
    }
`;
