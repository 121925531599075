import { graphql } from "react-relay";

export const load_account = graphql`
    query LoadAccountQuery($profileId: ID!, $startingYear: Int, $block: Int) {
        profile(id: $profileId) {
            id
            accountNumber
            status
            phoneNumber
            schoolYear
            schoolClass
            instruments
            addressFirstLine
            addressSecondLine
            city
            postcode
            dbsNumber
            dbsExpirationDate
            discountCategory
            paidBySchool
            discountPercentage
            hasBook
            hasInstrument
            instrumentSuppliedByUs
            hasReturnedInstrument
            staffNoteForTeacher
            teacherNoteForStaff
            teachingDays(startingYear: $startingYear, block: $block)
            school {
                id
                name
            }
            schools {
                id
                name
            }
            user {
                id
                email
                firstName
                lastName
            }
            primaryParent {
                id
                phoneNumber
                user {
                    id
                    email
                    firstName
                    lastName
                }
            }
            children {
                __id
                edges {
                    node {
                        id
                        user {
                            id
                            firstName
                            lastName
                        }
                    }
                }
            }
            teachers {
                profileId
                profileFirstName
                profileLastName
            }
        }
    }
`;
