/**
 * @generated SignedSource<<87779f8ccad53591b23f0d332e1e5a3a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RegisterInfoRefreshQuery$variables = {
  block?: number | null;
  dayIndex?: number | null;
  profileId: string;
  startingYear: number;
};
export type RegisterInfoRefreshQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"RegisterInfoFragment_query">;
};
export type RegisterInfoRefreshQuery = {
  response: RegisterInfoRefreshQuery$data;
  variables: RegisterInfoRefreshQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "block"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dayIndex"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "profileId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startingYear"
  }
],
v1 = {
  "kind": "Variable",
  "name": "block",
  "variableName": "block"
},
v2 = {
  "kind": "Variable",
  "name": "startingYear",
  "variableName": "startingYear"
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = [
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "dayIndex",
    "variableName": "dayIndex"
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RegisterInfoRefreshQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "profileId",
            "variableName": "profileId"
          }
        ],
        "kind": "FragmentSpread",
        "name": "RegisterInfoFragment_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RegisterInfoRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "profileId"
          }
        ],
        "concreteType": "ProfileNode",
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "kind": "ScalarField",
            "name": "totalLessonsMarked",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "kind": "ScalarField",
            "name": "totalLessons",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "kind": "ScalarField",
            "name": "firstLessonTimestamp",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "kind": "ScalarField",
            "name": "lastLessonTimestamp",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "kind": "ScalarField",
            "name": "teachingDays",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "71aaab0b9ec347e5228bd30a840a4321",
    "id": null,
    "metadata": {},
    "name": "RegisterInfoRefreshQuery",
    "operationKind": "query",
    "text": "query RegisterInfoRefreshQuery(\n  $block: Int\n  $dayIndex: Int\n  $profileId: ID!\n  $startingYear: Int!\n) {\n  ...RegisterInfoFragment_query_3Sd9eM\n}\n\nfragment RegisterInfoFragment_query_3Sd9eM on Query {\n  profile(id: $profileId) {\n    id\n    totalLessonsMarked(startingYear: $startingYear, block: $block)\n    totalLessons(startingYear: $startingYear, block: $block)\n    firstLessonTimestamp(startingYear: $startingYear, block: $block, dayIndex: $dayIndex)\n    lastLessonTimestamp(startingYear: $startingYear, block: $block, dayIndex: $dayIndex)\n    teachingDays(startingYear: $startingYear, block: $block)\n  }\n}\n"
  }
};
})();

(node as any).hash = "ab73351c428f989bfa720546ad4cc99c";

export default node;
