import React, {
    useCallback,
    useEffect,
    useRef,
    useMemo,
    Suspense,
} from "react";
import type { ReactElement, ComponentProps, Dispatch } from "react";

import { format, startOfWeek, endOfWeek } from "date-fns";
import {
    Box,
    VStack,
    HStack,
    Button,
    Text,
    ScrollView,
    ChevronLeftIcon,
    ChevronRightIcon,
    Center,
} from "native-base";
import { Platform } from "react-native";
import { DatePickerModal } from "react-native-paper-dates";
import { usePreloadedQuery } from "react-relay";
import type {
    PreloadedQuery,
    UseQueryLoaderLoadQueryOptions,
} from "react-relay";

import type { LoadLessonBlocks_query_lessonBlocks$data } from "pianofunclub-shared/relay/graphql/registers/__generated__/LoadLessonBlocks_query_lessonBlocks.graphql";
import type {
    LoadLessonsQuery,
    LoadLessonsQuery$variables,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/LoadLessonsQuery.graphql";
import { load_lessons } from "pianofunclub-shared/relay/graphql/registers/LoadLessons";

import type { LessonData } from "../../types";

import type {
    ReducerValues as AccountScreenReducerValues,
    ReducerTypes as AccountScreenReducerTypes,
    NavigationProps as AccountScreenNavigationProps,
} from "pianofunclub-crm/screens/accounts/AccountScreen";
import type {
    ReducerValues as TeacherTimtableHubScreenReducerValues,
    ReducerTypes as TeacherTimtableHubScreenReducerTypes,
    NavigationProps as TeacherTimtableHubScreenNavigationProps,
} from "pianofunclub-crm/screens/registers/TeacherTimetableHubScreen";

import type {
    ReducerValues as TimetableScreenReducerValues,
    ReducerTypes as TimetableScreenReducerTypes,
    NavigationProps as TimetableScreenNavigationProps,
} from "pianofunclub-teaching-app/screens/TimetableScreen";

import LoadingBlobs from "pianofunclub-shared/components/Animations/LoadingBlobs";
import Row from "pianofunclub-shared/components/Base/Row";
import LessonDetailsModal from "pianofunclub-shared/components/Modals/LessonDetailsModal";
import TimetableLessonGroup from "pianofunclub-shared/components/Registers/TimetableLessonGroup";

import {
    TEACHING_DAYS,
    HOUR_TIMETABLE_HEIGHT,
} from "pianofunclub-shared/utils/constants";
import type { State, Action } from "pianofunclub-shared/utils/reducers";

type VStackProps = ComponentProps<typeof VStack>;

export type LessonBlockData = NonNullable<
    LoadLessonBlocks_query_lessonBlocks$data["lessonBlocks"]
>["edges"][0];

interface Props extends VStackProps {
    dispatchState: Dispatch<
        Action<
            AccountScreenReducerValues &
                TimetableScreenReducerValues &
                TeacherTimtableHubScreenReducerValues,
            | AccountScreenReducerTypes
            | TimetableScreenReducerTypes
            | TeacherTimtableHubScreenReducerTypes
        >
    >;
    loadLessonsQuery: (
        variables: LoadLessonsQuery$variables,
        options?: UseQueryLoaderLoadQueryOptions,
    ) => void;
    loadLessonsQueryReference:
        | PreloadedQuery<LoadLessonsQuery, Record<string, unknown>>
        | null
        | undefined;
    navigation: AccountScreenNavigationProps &
        TimetableScreenNavigationProps &
        TeacherTimtableHubScreenNavigationProps;
    profileId: string;
    state: State<
        | AccountScreenReducerValues
        | TimetableScreenReducerValues
        | TeacherTimtableHubScreenReducerValues
    >;
    userIsTeacher: boolean;
    viewToggleOnRight: boolean;
}

interface ContentProps extends Props {
    loadLessonsQueryReference: PreloadedQuery<
        LoadLessonsQuery,
        Record<string, unknown>
    >;
    refreshHandler: () => void;
}

const TABLE_BORDER_COLOR = "surface.400";
const TABLE_BORDER_WIDTH = 1;

const TeacherTimetableContent = (props: ContentProps): ReactElement => {
    const {
        dispatchState,
        loadLessonsQueryReference,
        navigation,
        state,
        userIsTeacher,
    } = props;

    const data = usePreloadedQuery(load_lessons, loadLessonsQueryReference);

    const lessons = useMemo(() => {
        return data.lessons?.edges ?? [];
    }, [data.lessons?.edges]);

    const navigateToRegisterHandler = useCallback(
        (startingYear?: number | null, block?: number | null) => {
            if (userIsTeacher) {
                navigation.navigate("TeacherRegistersHubTab", {
                    screen: "TeacherRegistersHub",
                    params: {
                        startingYear: startingYear ?? undefined,
                        block: block ?? undefined,
                        dayIndex:
                            state.values.timetableLessonModalInfo?.dayIndex ??
                            0,
                    },
                });
            } else {
                dispatchState({
                    input: "dayIndex",
                    value: state.values.timetableLessonModalInfo?.dayIndex ?? 0,
                });
                if (startingYear) {
                    dispatchState({
                        input: "startingYear",
                        value: startingYear,
                    });
                }
                if (block) {
                    dispatchState({ input: "block", value: block });
                }
                dispatchState({ input: "currentPageIndex", value: 1 });
                navigation.setParams({
                    dayIndex:
                        state.values.timetableLessonModalInfo?.dayIndex ?? 0,
                    startingYear: startingYear ?? undefined,
                    block: block ?? undefined,
                });
            }
        },
        [
            dispatchState,
            navigation,
            state.values.timetableLessonModalInfo?.dayIndex,
            userIsTeacher,
        ],
    );

    const rescheduleLessonHandler = useCallback(
        (
            timestamp: Date,
            lessonId: string | undefined,
            lessonDuration: number | undefined,
        ) => {
            dispatchState({
                input: "dateTimePickerModalDate",
                value: timestamp,
            });
            dispatchState({
                input: "dateTimePickerModalLessonId",
                value: lessonId,
            });
            dispatchState({
                input: "dateTimePickerModalLessonDuration",
                value: lessonDuration,
            });
        },
        [dispatchState],
    );

    const renderLessons = useCallback(() => {
        const lessonMap = new Map<string, LessonData[]>();
        // this combines lessons with the same timestamp into an array on that timestamp
        lessons.forEach((lesson) => {
            if (lesson?.node) {
                const lessonTimestamp =
                    lesson?.node?.rearrangedTimestamp ??
                    lesson?.node?.scheduledTimestamp;
                if (lessonTimestamp) {
                    if (lessonMap.get(lessonTimestamp)) {
                        return lessonMap.set(lessonTimestamp, [
                            ...(lessonMap.get(lessonTimestamp) ?? []),
                            lesson.node,
                        ]);
                    } else {
                        return lessonMap.set(lessonTimestamp, [lesson.node]);
                    }
                }
            }
        });

        let previousSchool: string | undefined = undefined;
        let previousTimestamp: Date | undefined = undefined;

        return Array.from(lessonMap.keys()).map((lessonTimestamp, index) => {
            const lessons = lessonMap.get(lessonTimestamp);
            if (lessons) {
                const lessonTimestampDate = new Date(lessonTimestamp);
                // show school label if a) school has changed
                // b) there's a gap of an hour or more
                const showSchoolLabel =
                    previousSchool != lessons[0].lessonBlock?.school?.name ||
                    lessonTimestampDate.getTime() -
                        (previousTimestamp?.getTime() ?? 0) >=
                        60 * 60 * 1000;
                previousSchool = lessons[0].lessonBlock?.school?.name;
                previousTimestamp = lessonTimestampDate;
                return (
                    <TimetableLessonGroup
                        key={index}
                        dispatchState={dispatchState}
                        lessonsData={lessons}
                        lessonTimestampDate={lessonTimestampDate}
                        navigation={navigation}
                        showSchoolLabel={showSchoolLabel}
                        state={state}
                    />
                );
            } else {
                return null;
            }
        });
    }, [lessons, state, dispatchState, navigation]);

    const renderTimetable = useCallback(() => {
        return (
            <HStack mx={Platform.OS === "web" ? "6" : "2"} space="2">
                <VStack mt="-4" pt="-1">
                    {Array.from(Array(23)).map((_, index) => {
                        const date = new Date();
                        date.setHours(Math.floor(index / 2 + 7));
                        date.setMinutes(index % 2 === 1 ? 30 : 0);
                        return (
                            <Box
                                key={index}
                                height={
                                    index !== 22
                                        ? `${HOUR_TIMETABLE_HEIGHT / 2}px`
                                        : "0"
                                }
                            >
                                <Text color="surface.600">
                                    {format(
                                        date,
                                        index % 2 === 1 ? "h:mm" : "h aa",
                                    )}
                                </Text>
                            </Box>
                        );
                    })}
                </VStack>
                <VStack flex={1} mb="6" ml="2">
                    <HStack height="6" mb="2">
                        {state.values.timetableView === "WEEK"
                            ? TEACHING_DAYS.map((item, index) => {
                                  return (
                                      <Center key={index} width="20%">
                                          <Text color="surface.600">
                                              {item.label
                                                  .slice(0, 3)
                                                  .toUpperCase()}
                                          </Text>
                                      </Center>
                                  );
                              })
                            : null}
                    </HStack>
                    <VStack
                        borderBottomWidth={TABLE_BORDER_WIDTH}
                        borderColor={TABLE_BORDER_COLOR}
                        borderLeftWidth={TABLE_BORDER_WIDTH}
                        flex={1}
                    >
                        {Array.from(Array(11)).map((_: unknown, index) => {
                            return (
                                <Row
                                    key={index}
                                    cellProps={{
                                        hideOuterSideBorder: true,
                                    }}
                                    data={
                                        state.values.timetableView === "DAY"
                                            ? [{ data: "" }]
                                            : Array.from(Array(5)).fill(
                                                  { data: "" },
                                                  0,
                                                  5,
                                              )
                                    }
                                    flexArray={
                                        state.values.timetableView === "DAY"
                                            ? [1]
                                            : [1, 1, 1, 1, 1]
                                    }
                                    ml="-2"
                                    pl="2"
                                    rowHeight={`${HOUR_TIMETABLE_HEIGHT}px`}
                                    rowIndex={index}
                                    tableBorderColor={TABLE_BORDER_COLOR}
                                    tableBorderWidth={TABLE_BORDER_WIDTH}
                                />
                            );
                        })}
                        {renderLessons()}
                    </VStack>
                </VStack>
            </HStack>
        );
    }, [state.values.timetableView, renderLessons]);

    useEffect(() => {
        dispatchState({ input: "contentIsRendered", value: true });
        // unsubscribe from refetch on unmount
        return () => state.values.subscription?.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!state.values.contentIsRendered) {
        return <LoadingBlobs>Loading Timetable...</LoadingBlobs>;
    }

    return (
        <>
            <ScrollView
                contentContainerStyle={
                    Platform.OS === "web" ? { marginHorizontal: 30 } : undefined
                }
                flex={1}
                mx={Platform.OS === "web" ? "-30px" : undefined}
                pt="6"
            >
                {renderTimetable()}
            </ScrollView>
            <LessonDetailsModal
                hideModal={() =>
                    dispatchState({
                        input: "timetableLessonModalInfo",
                        value: undefined,
                    })
                }
                lessonInfo={state.values.timetableLessonModalInfo}
                navigateToRegister={navigateToRegisterHandler}
                navigation={navigation}
                rescheduleLesson={rescheduleLessonHandler}
                showModal={Boolean(state.values.timetableLessonModalInfo)}
            />
        </>
    );
};

const TeacherTimetable = (props: Props): ReactElement => {
    const {
        dispatchState,
        loadLessonsQuery,
        loadLessonsQueryReference,
        navigation,
        profileId,
        state,
        viewToggleOnRight,
    } = props;

    const dateArrowHandler = useCallback(
        (isRightArrow: boolean) => {
            const newTimetableDay = new Date(state.values.timetableDate);
            if (state.values.timetableView === "DAY") {
                newTimetableDay.setDate(
                    state.values.timetableDate.getDate() +
                        (isRightArrow ? 1 : -1),
                );
            } else {
                newTimetableDay.setTime(
                    state.values.timetableDate.getTime() +
                        (isRightArrow ? 1 : -1) * 7 * 24 * 60 * 60 * 1000, // 1 week in ms
                );
            }
            dispatchState({ input: "timetableDate", value: newTimetableDay });
            navigation.setParams({ timetableDate: newTimetableDay.toString() });
        },
        [
            dispatchState,
            navigation,
            state.values.timetableDate,
            state.values.timetableView,
        ],
    );

    const refreshHandler = useCallback(() => {
        let startDate = state.values.timetableDate;
        let endDate;
        if (state.values.timetableView === "WEEK") {
            startDate = startOfWeek(startDate, { weekStartsOn: 1 });
            endDate = endOfWeek(startDate, { weekStartsOn: 1 });
        }

        loadLessonsQuery(
            {
                profileId: profileId,
                startDate: startDate.toString(),
                endDate: endDate?.toString(),
                orderBy: "rearrangedTimestamp,scheduledTimestamp",
                skip: false,
            },
            { fetchPolicy: "network-only" },
        );
    }, [
        loadLessonsQuery,
        profileId,
        state.values.timetableDate,
        state.values.timetableView,
    ]);

    const renderHeader = useCallback(() => {
        const mondayOfWeek = startOfWeek(state.values.timetableDate, {
            weekStartsOn: 1,
        });
        const fridayOfWeek = endOfWeek(state.values.timetableDate, {
            weekStartsOn: 1,
        });
        fridayOfWeek.setDate(
            endOfWeek(state.values.timetableDate, {
                weekStartsOn: 1,
            }).getDate() - 2,
        );

        return (
            <Center
                borderBottomWidth={1}
                borderColor="surface.300"
                mx="-30px"
                pb={Platform.OS === "web" ? "6" : "4"}
                px="30px"
            >
                <Button.Group
                    isAttached
                    position="absolute"
                    top="-2"
                    {...(viewToggleOnRight ? { right: 6 } : { left: 6 })}
                >
                    <Button
                        _disabled={{
                            bg: "primary.500",
                            _text: { color: "white" },
                            opacity: 1,
                        }}
                        _text={{ color: "surface.600" }}
                        borderLeftRadius="lg"
                        borderRightRadius={0}
                        isDisabled={state.values.timetableView === "WEEK"}
                        onPress={() => {
                            dispatchState({
                                input: "timetableView",
                                value: "WEEK",
                            });
                        }}
                        py={Platform.OS !== "web" ? "1.5" : undefined}
                        shadow={1}
                        variant="subtle"
                    >
                        Week
                    </Button>
                    <Button
                        _disabled={{
                            bg: "primary.500",
                            _text: { color: "white" },
                            opacity: 1,
                        }}
                        _text={{ color: "surface.600" }}
                        borderLeftRadius={0}
                        borderRightRadius="lg"
                        isDisabled={state.values.timetableView === "DAY"}
                        onPress={() => {
                            // switch date to Friday before if a Sat/Sun
                            if (
                                [0, 6].includes(
                                    state.values.timetableDate.getDay(),
                                )
                            ) {
                                const date = new Date(
                                    state.values.timetableDate,
                                );
                                date.setHours(
                                    -24 * (date.getDay() === 0 ? 2 : 1),
                                );
                                dispatchState({
                                    input: "timetableDate",
                                    value: date,
                                });
                                navigation.setParams({
                                    timetableDate: date.toString(),
                                });
                            }
                            dispatchState({
                                input: "timetableView",
                                value: "DAY",
                            });
                            navigation.setParams({ timetableView: "DAY" });
                        }}
                        py={Platform.OS !== "web" ? "1.5" : undefined}
                        shadow={1}
                        variant="subtle"
                    >
                        Day
                    </Button>
                </Button.Group>
                <Button.Group>
                    <Button
                        _hover={{ bg: "transparent", opacity: 0.8 }}
                        _pressed={{ bg: "transparent", opacity: 0.7 }}
                        leftIcon={
                            <ChevronLeftIcon
                                size={Platform.OS === "web" ? "6" : "5"}
                            />
                        }
                        onPress={() => dateArrowHandler(false)}
                        px="2"
                        py="1"
                        variant="ghost"
                    />
                    <Button
                        _disabled={{ opacity: 1 }}
                        _hover={{ bg: "transparent", opacity: 0.8 }}
                        _pressed={{ bg: "transparent", opacity: 0.7 }}
                        _text={{
                            fontSize: Platform.OS === "web" ? "xl" : "lg",
                        }}
                        isDisabled={Platform.OS !== "web"}
                        onPress={() =>
                            dispatchState({
                                input: "timetableDatePickerIsOpen",
                                value: true,
                            })
                        }
                        py="1"
                        variant="ghost"
                        width={
                            state.values.timetableView === "DAY"
                                ? "200px"
                                : "370px"
                        }
                    >
                        {state.values.timetableView === "DAY"
                            ? state.values.timetableDate.toDateString()
                            : `${mondayOfWeek.toDateString()} - ${fridayOfWeek.toDateString()}`}
                    </Button>
                    <Button
                        _hover={{ bg: "transparent", opacity: 0.8 }}
                        _pressed={{ bg: "transparent", opacity: 0.7 }}
                        leftIcon={
                            <ChevronRightIcon
                                size={Platform.OS === "web" ? "6" : "5"}
                            />
                        }
                        onPress={() => dateArrowHandler(true)}
                        px="2"
                        py="1"
                        variant="ghost"
                    />
                </Button.Group>
            </Center>
        );
    }, [
        state.values.timetableDate,
        state.values.timetableView,
        viewToggleOnRight,
        dispatchState,
        navigation,
        dateArrowHandler,
    ]);

    // stop refetch useEffects firing on first render
    const renderCount = useRef(0);

    useEffect(() => {
        if (renderCount.current < 1) {
            renderCount.current += 1;
            return;
        }

        refreshHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.values.timetableDate, state.values.timetableView]);

    return (
        <Box flex={1}>
            {renderHeader()}
            {loadLessonsQueryReference != null ? (
                <Suspense
                    fallback={<LoadingBlobs>Loading Timetable...</LoadingBlobs>}
                >
                    <TeacherTimetableContent
                        {...props}
                        loadLessonsQueryReference={loadLessonsQueryReference}
                        refreshHandler={refreshHandler}
                    />
                </Suspense>
            ) : (
                <LoadingBlobs>Loading Timetable...</LoadingBlobs>
            )}
            <DatePickerModal
                animationType="fade"
                date={state.values.timetableDate}
                label="Select Date"
                locale="en"
                mode="single"
                onConfirm={(params) => {
                    if (params.date) {
                        dispatchState({
                            input: "timetableDate",
                            value: params.date,
                        });
                        navigation.setParams({
                            timetableDate: params.date.toString(),
                        });
                    }
                    dispatchState({
                        input: "timetableDatePickerIsOpen",
                        value: false,
                    });
                }}
                onDismiss={() =>
                    dispatchState({
                        input: "timetableDatePickerIsOpen",
                        value: false,
                    })
                }
                saveLabel="Select"
                uppercase={false}
                visible={state.values.timetableDatePickerIsOpen}
            />
        </Box>
    );
};

export default React.memo(TeacherTimetable);
