import React, { useMemo } from "react";
import type { ReactElement } from "react";

import type { CellProps } from "pianofunclub-shared/components/Base/Cell";
import Row from "pianofunclub-shared/components/Base/Row";

import type { LoadEnrolments_query_enrolments$data } from "pianofunclub-shared/relay/graphql/accounts/__generated__/LoadEnrolments_query_enrolments.graphql";

import { getFullName } from "pianofunclub-shared/utils/extractors";

interface Props {
    cellProps?: CellProps;
    data: NonNullable<
        NonNullable<
            NonNullable<
                LoadEnrolments_query_enrolments$data["enrolments"]
            >["edges"][0]
        >["node"]
    >;
    flexArray: number[];
    onPressEnrolment: (enrolmentId?: string) => void;
    rowHeight?: number;
    tableBorderColor?: string;
    tableBorderWidth?: number;
}

const WaitingListTableRow = (props: Props): ReactElement => {
    const {
        cellProps,
        data,
        flexArray,
        onPressEnrolment,
        rowHeight,
        tableBorderColor,
        tableBorderWidth,
    } = props;

    const rowData = useMemo(() => {
        return [
            {
                data:
                    getFullName(data.pupilFirstName, data.pupilLastName) ?? "",
                onPress: () => onPressEnrolment(data.id ?? undefined),
            },
            {
                data: data.schoolName ?? "",
            },
            {
                data: data.positionInWaitingList ?? "",
            },
            {
                data: data.schoolYear ?? "",
            },
            {
                data: data.schoolClass ?? "",
            },
            {
                data: data.instrument ?? "",
            },
            {
                data:
                    data.lessonStage == "-1"
                        ? "Any Stage"
                        : (data.lessonStage ?? ""),
            },
            {
                data: data.pupilNotes ?? "",
                tooltipLabel: data.pupilNotes ?? "",
            },
        ];
    }, [
        data.id,
        data.instrument,
        data.lessonStage,
        data.positionInWaitingList,
        data.pupilFirstName,
        data.pupilLastName,
        data.pupilNotes,
        data.schoolClass,
        data.schoolName,
        data.schoolYear,
        onPressEnrolment,
    ]);

    return (
        <Row
            cellProps={cellProps}
            data={rowData}
            flexArray={flexArray}
            rowHeight={rowHeight}
            rowIndex={0}
            tableBorderColor={tableBorderColor}
            tableBorderWidth={tableBorderWidth}
        />
    );
};

export default WaitingListTableRow;
