/**
 * @generated SignedSource<<20d6064a0afda6179b56c0bfd18171e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LogoutUserInput = {
  clientMutationId?: string | null;
};
export type LogoutUserMutation$variables = {
  input: LogoutUserInput;
};
export type LogoutUserMutation$data = {
  readonly logoutUser: {
    readonly errors: any | null;
    readonly success: boolean | null;
  } | null;
};
export type LogoutUserMutation = {
  response: LogoutUserMutation$data;
  variables: LogoutUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "LogoutUserPayload",
    "kind": "LinkedField",
    "name": "logoutUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LogoutUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LogoutUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0884079da05a912b6cbebd5f14a6d039",
    "id": null,
    "metadata": {},
    "name": "LogoutUserMutation",
    "operationKind": "mutation",
    "text": "mutation LogoutUserMutation(\n  $input: LogoutUserInput!\n) {\n  logoutUser(input: $input) {\n    success\n    errors\n  }\n}\n"
  }
};
})();

(node as any).hash = "f124af8dfdea3b88c3b6f9bf1e19f4bd";

export default node;
