import { graphql } from "react-relay";

export const update_or_create_payment = graphql`
    mutation UpdateOrCreatePaymentMutation(
        $connections: [ID!]!
        $input: UpdateOrCreatePaymentInput!
    ) {
        updateOrCreatePayment(input: $input) {
            success
            errors
            payment
                @prependNode(
                    connections: $connections
                    edgeTypeName: "PaymentNodeEdge"
                ) {
                id
                amount
                paymentType
                paymentReference
                paymentDate
                paymentNote
            }
            invoice {
                id
                amountPaid
                status
            }
        }
    }
`;
