/**
 * @generated SignedSource<<838f585ca560842d9597bbf890bd7f42>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PayrollHeadlineDataRefreshQuery$variables = {
  dateFrom: string;
  dateTo: string;
};
export type PayrollHeadlineDataRefreshQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PayrollHeadlineDataFragment_query">;
};
export type PayrollHeadlineDataRefreshQuery = {
  response: PayrollHeadlineDataRefreshQuery$data;
  variables: PayrollHeadlineDataRefreshQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dateFrom"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dateTo"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "dateFrom",
    "variableName": "dateFrom"
  },
  {
    "kind": "Variable",
    "name": "dateTo",
    "variableName": "dateTo"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PayrollHeadlineDataRefreshQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "PayrollHeadlineDataFragment_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PayrollHeadlineDataRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DjangoUserNode",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": (v1/*: any*/),
                "kind": "ScalarField",
                "name": "totalPayslipsUnpaidAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "kind": "ScalarField",
                "name": "totalPayslipsPaidAmount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "date",
            "variableName": "dateFrom"
          }
        ],
        "concreteType": "PayrateNode",
        "kind": "LinkedField",
        "name": "payrate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hourlyRate",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0567f83df3042d69e4642a352cd9e9f0",
    "id": null,
    "metadata": {},
    "name": "PayrollHeadlineDataRefreshQuery",
    "operationKind": "query",
    "text": "query PayrollHeadlineDataRefreshQuery(\n  $dateFrom: String!\n  $dateTo: String!\n) {\n  ...PayrollHeadlineDataFragment_query_3f8Bpf\n}\n\nfragment PayrollHeadlineDataFragment_query_3f8Bpf on Query {\n  me {\n    profile {\n      id\n      totalPayslipsUnpaidAmount(dateFrom: $dateFrom, dateTo: $dateTo)\n      totalPayslipsPaidAmount(dateFrom: $dateFrom, dateTo: $dateTo)\n    }\n    id\n  }\n  payrate(date: $dateFrom) {\n    id\n    hourlyRate\n  }\n}\n"
  }
};
})();

(node as any).hash = "77c0b44ccbeddbe844f9d076a65b9a39";

export default node;
