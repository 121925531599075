import { graphql } from "react-relay";

export const delete_payrates = graphql`
    mutation DeletePayratesMutation(
        $connections: [ID!]!
        $input: DeletePayratesInput!
    ) {
        deletePayrates(input: $input) {
            success
            errors
            deletedPayrateIds @deleteEdge(connections: $connections)
        }
    }
`;
