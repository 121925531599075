import { graphql } from "react-relay";

export const load_schools = graphql`
    query LoadSchoolsQuery(
        $searchTerm: String
        $orderBy: String
        $isActive: Boolean
        $first: Int!
        $after: String
    ) {
        ...LoadSchools_query_schools
            @arguments(
                searchTerm: $searchTerm
                orderBy: $orderBy
                isActive: $isActive
                first: $first
                after: $after
            )
    }
`;

export const load_schools_pagination = graphql`
    fragment LoadSchools_query_schools on Query
    @argumentDefinitions(
        searchTerm: { type: "String" }
        orderBy: { type: "String" }
        isActive: { type: "Boolean" }
        first: { type: "Int!" }
        after: { type: "String" }
    )
    @refetchable(queryName: "LoadSchoolsPaginationQuery") {
        schools(
            searchTerm: $searchTerm
            orderBy: $orderBy
            isActive: $isActive
            first: $first
            after: $after
        ) @connection(key: "LoadSchools_query_schools") {
            __id
            edges {
                node {
                    id
                    name
                    archived
                    numberOfActiveTeachers
                    numberOfFilledLessonSlots
                    numberOfFreeLessonSlots
                    numberOfPupilsOnWaitingList
                    headTeacher {
                        id
                        phoneNumber
                        user {
                            id
                            firstName
                            lastName
                            email
                        }
                    }
                    mainOfficeContact {
                        id
                        phoneNumber
                        addressFirstLine
                        addressSecondLine
                        city
                        postcode
                        user {
                            id
                            firstName
                            lastName
                            email
                        }
                    }
                }
            }
        }
    }
`;
