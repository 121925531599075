import React from "react";
import type { ReactElement, ComponentProps } from "react";

import { VStack } from "native-base";

import Cell from "./Cell";
import type { CellProps } from "./Cell";
import type { TableData } from "./Row";

type VStackProps = ComponentProps<typeof VStack>;

interface Props extends VStackProps {
    cellProps?: CellProps;
    data: TableData;
    isFinalColumn?: boolean;
    rowHeight?: number;
    tableBorderColor?: string;
    tableBorderWidth?: number;
}

const Column = (props: Props): ReactElement => {
    const {
        cellProps,
        data,
        isFinalColumn,
        rowHeight,
        tableBorderColor,
        tableBorderWidth,
    } = props;

    const filteredData = data.filter(Boolean);

    return (
        <VStack flex={1} {...props}>
            {filteredData.map((item, index) => {
                if (!item) {
                    return null;
                }

                return (
                    <Cell
                        key={index}
                        data={item.data}
                        height={rowHeight}
                        icon={item?.icon}
                        isFinal={isFinalColumn ?? false}
                        isPlaceholder={item?.isPlaceholder}
                        onPress={item?.onPress}
                        rowIndex={index}
                        tableBorderColor={tableBorderColor}
                        tableBorderWidth={tableBorderWidth}
                        tooltipLabel={item?.tooltipLabel}
                        {...cellProps}
                    />
                );
            })}
        </VStack>
    );
};

export default Column;
