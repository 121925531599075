/**
 * @generated SignedSource<<78fe9bb9a513110aefb4466ac3d658ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LessonBlockModelType = "ARCHIVED" | "BREAK" | "LESSON" | "%future added value";
export type LessonModelStatus = "BLANK" | "BREAK" | "LEFT_SCHOOL" | "MISSED" | "NO_LESSON" | "OTHER_TEACHER" | "PRESENT" | "PRESENT_DOUBLE" | "PUPIL_ABSENT" | "STOPPED_LESSONS" | "TEACHER_ABSENT" | "%future added value";
export type AddNewLessonBlockInput = {
  block: number;
  clientMutationId?: string | null;
  dayIndex: number;
  durationMinutes?: number | null;
  hours: number;
  minutes: number;
  school: string;
  startingYear: number;
  teacherId?: string | null;
  type?: string | null;
};
export type AddNewLessonBlockMutation$variables = {
  connections: ReadonlyArray<string>;
  input: AddNewLessonBlockInput;
};
export type AddNewLessonBlockMutation$data = {
  readonly addNewLessonBlock: {
    readonly errors: any | null;
    readonly lessonBlock: {
      readonly id: string;
      readonly lessonDay: number | null;
      readonly lessonTime: string | null;
      readonly lessons: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly rearrangedTimestamp: string | null;
            readonly replacementLessonColumnIndex: number | null;
            readonly scheduledTimestamp: string | null;
            readonly status: LessonModelStatus;
            readonly weekWasRearranged: boolean | null;
          } | null;
        } | null>;
      };
      readonly school: {
        readonly id: string;
        readonly name: string;
      } | null;
      readonly teacher: {
        readonly id: string;
      } | null;
      readonly type: LessonBlockModelType;
    } | null;
    readonly success: boolean | null;
  } | null;
};
export type AddNewLessonBlockMutation = {
  response: AddNewLessonBlockMutation$data;
  variables: AddNewLessonBlockMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lessonDay",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lessonTime",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ProfileNode",
  "kind": "LinkedField",
  "name": "teacher",
  "plural": false,
  "selections": [
    (v4/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "SchoolNode",
  "kind": "LinkedField",
  "name": "school",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scheduledTimestamp",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rearrangedTimestamp",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weekWasRearranged",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "replacementLessonColumnIndex",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddNewLessonBlockMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddNewLessonBlockPayload",
        "kind": "LinkedField",
        "name": "addNewLessonBlock",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "LessonBlockNode",
            "kind": "LinkedField",
            "name": "lessonBlock",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LessonNodeConnection",
                "kind": "LinkedField",
                "name": "lessons",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LessonNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LessonNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v14/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddNewLessonBlockMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddNewLessonBlockPayload",
        "kind": "LinkedField",
        "name": "addNewLessonBlock",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "LessonBlockNode",
            "kind": "LinkedField",
            "name": "lessonBlock",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LessonNodeConnection",
                "kind": "LinkedField",
                "name": "lessons",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LessonNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LessonNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "prependNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "lessonBlock",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "LessonBlockNodeEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2ca4e44f40964da44c199e251dd9048a",
    "id": null,
    "metadata": {},
    "name": "AddNewLessonBlockMutation",
    "operationKind": "mutation",
    "text": "mutation AddNewLessonBlockMutation(\n  $input: AddNewLessonBlockInput!\n) {\n  addNewLessonBlock(input: $input) {\n    success\n    errors\n    lessonBlock {\n      id\n      lessonDay\n      lessonTime\n      teacher {\n        id\n      }\n      school {\n        id\n        name\n      }\n      lessons {\n        edges {\n          node {\n            status\n            scheduledTimestamp\n            rearrangedTimestamp\n            weekWasRearranged\n            replacementLessonColumnIndex\n            id\n          }\n        }\n      }\n      type\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a1801179b718c873258bd67ff45c7ef1";

export default node;
