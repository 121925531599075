import React, {
    Suspense,
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
} from "react";
import type { FC, ReactElement, Dispatch, RefObject } from "react";

import emoji from "emoji-dictionary";
import {
    Box,
    Heading,
    VStack,
    HStack,
    Button,
    Text,
    useTheme,
    useToast,
    useDisclose,
    Center,
} from "native-base";
import { useWindowDimensions } from "react-native";
import {
    useQueryLoader,
    usePreloadedQuery,
    usePaginationFragment,
    useRelayEnvironment,
    useMutation,
} from "react-relay";
import type { PreloadedQuery } from "react-relay";
import {
    RecyclerListView,
    DataProvider,
    LayoutProvider,
} from "recyclerlistview";
import { fetchQuery } from "relay-runtime";
import type { Subscription } from "relay-runtime";

import { useAuth } from "pianofunclub-shared/providers/AuthProvider";

import RegisterMutationProgressIndicators from "../../components/Buttons/RegisterMutationProgressIndicators";
import type { RegisterMutationProgressIndicatorsRef } from "../../components/Buttons/RegisterMutationProgressIndicators";
import RegisterHubTableRow from "../../components/ListItems/RegisterHubTableRow";
import type {
    RegistersStackNavigatorProps,
    RegistersStackRouteProps,
} from "../../navigation/RegistersNavigator";
import LoadingBlobs from "pianofunclub-shared/components/Animations/LoadingBlobs";
import Row from "pianofunclub-shared/components/Base/Row";
import ButtonDebounced from "pianofunclub-shared/components/Buttons/ButtonDebounced";
import TextInput from "pianofunclub-shared/components/Inputs/TextInput";
import ListEmptyBanner from "pianofunclub-shared/components/ListItems/ListEmptyBanner";
import SchoolMultiSelectModal from "pianofunclub-shared/components/Modals/SchoolMultiSelectModal";
import TeacherMultiSelectModal from "pianofunclub-shared/components/Modals/TeacherMultiSelectModal";
import Actionsheet from "pianofunclub-shared/components/NativeBaseExtended/Actionsheet";
import AlertPopup from "pianofunclub-shared/components/NativeBaseExtended/AlertPopup";
import Select from "pianofunclub-shared/components/NativeBaseExtended/Select";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import CustomFlatListSpinner from "pianofunclub-shared/components/Other/CustomFlatListSpinner";
import {
    AccountsIcon,
    CopyCalendarIcon,
    RegisterIcon,
    RestartIcon,
    SchoolIcon,
    SortDescDate,
    UploadIcon,
} from "pianofunclub-shared/components/Other/Icons";
import SearchBar from "pianofunclub-shared/components/Other/SearchBar";

import type { LoadMutationProgressQuery } from "pianofunclub-shared/relay/graphql/general/__generated__/LoadMutationProgressQuery.graphql";
import { load_mutation_progress } from "pianofunclub-shared/relay/graphql/general/LoadMutationProgress";
import type {
    BulkUploadNewRegistersMutation,
    BulkUploadNewRegistersMutation$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/BulkUploadNewRegistersMutation.graphql";
import type {
    GenerateOrUpdateGoogleRegistersMutation,
    GenerateOrUpdateGoogleRegistersMutation$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/GenerateOrUpdateGoogleRegistersMutation.graphql";
import type {
    GenerateRegistersForNewBlockMutation,
    GenerateRegistersForNewBlockMutation$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/GenerateRegistersForNewBlockMutation.graphql";
import type {
    LoadRegisters_query_profiles$key,
    LoadRegisters_query_profiles$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/LoadRegisters_query_profiles.graphql";
import type { LoadRegistersPaginationQuery } from "pianofunclub-shared/relay/graphql/registers/__generated__/LoadRegistersPaginationQuery.graphql";
import type { LoadRegistersQuery } from "pianofunclub-shared/relay/graphql/registers/__generated__/LoadRegistersQuery.graphql";
import type {
    ReadInGoogleRegistersMutation,
    ReadInGoogleRegistersMutation$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/ReadInGoogleRegistersMutation.graphql";
import { bulk_upload_new_registers } from "pianofunclub-shared/relay/graphql/registers/BulkUploadNewRegisters";
import { generate_or_update_google_registers } from "pianofunclub-shared/relay/graphql/registers/GenerateOrUpdateGoogleRegisters";
import { generate_registers_for_new_block } from "pianofunclub-shared/relay/graphql/registers/GenerateRegistersForNewBlock";
import {
    load_registers,
    load_registers_pagination,
} from "pianofunclub-shared/relay/graphql/registers/LoadRegisters";
import { read_in_google_registers } from "pianofunclub-shared/relay/graphql/registers/ReadInGoogleRegisters";

import type { Mutable } from "pianofunclub-shared/types";
import { BLOCKS } from "pianofunclub-shared/utils/constants";
import { getScaledWindowDimension } from "pianofunclub-shared/utils/converters";
import {
    getCurrentBlock,
    getFullName,
    getDefaultStartingYearAndStartingYearOptions,
    getTermInWords,
    getPreviousBlockAndStartingYear,
} from "pianofunclub-shared/utils/extractors";
import { useDebounceFunction } from "pianofunclub-shared/utils/hooks";
import { createReducer } from "pianofunclub-shared/utils/reducers";
import type { State, Action } from "pianofunclub-shared/utils/reducers";

type NavigationProps = RegistersStackNavigatorProps<"RegistersHub">;

type RouteProps = RegistersStackRouteProps<"RegistersHub">;

interface ScreenProps {
    navigation: NavigationProps;
    route: RouteProps;
}

interface ContentProps {
    dispatchState: Dispatch<Action<ReducerValues, ReducerTypes>>;
    generateRegistersForNewBlockInFlight: boolean;
    navigation: NavigationProps;
    progressIndicatorRef: RefObject<RegisterMutationProgressIndicatorsRef>;
    queryReference: PreloadedQuery<LoadRegistersQuery, Record<string, unknown>>;
    state: State<ReducerValues>;
}

export type ShowMutationProgressIndicators = {
    bulkUploadNewRegisters: boolean;
    generateGoogleRegisters: boolean;
    generateRegistersForNewBlock: boolean;
    readInRegisters: boolean;
};

export type ReducerValues = {
    block: number;
    bulkUploadRegistersModalIsOpen: boolean;
    contentIsRendered: boolean;
    dataProvider: DataProvider;
    failedMutationItems: string[];
    generateNewRegistersAlertIsOpen: boolean;
    generateNewRegistersForTeachersAlertIsOpen: boolean;
    generateNewRegistersForTeachersTeacherSelectModalOpen: boolean;
    generateSchoolGoogleRegistersModalIsOpen: boolean;
    generateTeacherGoogleRegistersModalIsOpen: boolean;
    googleDriveNewRegistersUrl?: string;
    isRefetching: boolean;
    layoutProvider?: LayoutProvider;
    readInRegistersModalIsOpen: boolean;
    refreshHandler?: () => void;
    searchTerm: string;
    showMutationProgressIndicators: ShowMutationProgressIndicators;
    startingYear: number;
    subscription?: Subscription;
    teacherIds?: string[];
};

export type ReducerTypes =
    | string
    | number
    | boolean
    | Subscription
    | DataProvider
    | LayoutProvider
    | (() => void)
    | ShowMutationProgressIndicators
    | string[]
    | null
    | undefined;

export type TableData = NonNullable<
    LoadRegisters_query_profiles$data["profiles"]
>["edges"][0];

const LOAD_X_SCHOOLS = 100;
const TABLE_BORDER_COLOR = "surface.400";
const TABLE_BORDER_WIDTH = 1;
const TABLE_BORDER_RADIUS = 10;
const FLEX_ARRAY = [1, 5, 5];
const REGISTERS_TO_GENERATE_IN_BATCH = 1;
const REGISTERS_TO_READ_IN_BATCH = 1;

const RegistersHubContent: FC<ContentProps> = (props) => {
    const {
        dispatchState,
        generateRegistersForNewBlockInFlight,
        navigation,
        progressIndicatorRef,
        queryReference,
        state,
    } = props;

    const data = usePreloadedQuery(load_registers, queryReference);

    useEffect(() => {
        dispatchState({
            input: "generateGoogleRegistersProgress",
            value:
                data.me?.profile?.profileGroup
                    ?.generateGoogleRegistersProgress ?? null,
        });
        dispatchState({
            input: "readInRegistersProgress",
            value:
                data.me?.profile?.profileGroup?.readInRegistersProgress ?? null,
        });
        dispatchState({
            input: "bulkUploadNewRegistersProgress",
            value:
                data.me?.profile?.profileGroup
                    ?.bulkUploadNewRegistersProgress ?? null,
        });
        dispatchState({
            input: "generateRegistersForNewBlockProgress",
            value:
                data.me?.profile?.profileGroup
                    ?.generateRegistersForNewBlockProgress ?? null,
        });
    }, [
        data.me?.profile?.profileGroup?.bulkUploadNewRegistersProgress,
        data.me?.profile?.profileGroup?.generateGoogleRegistersProgress,
        data.me?.profile?.profileGroup?.generateRegistersForNewBlockProgress,
        data.me?.profile?.profileGroup?.readInRegistersProgress,
        dispatchState,
    ]);

    const {
        data: registersData,
        hasNext,
        isLoadingNext,
        loadNext,
        refetch: refresh,
    } = usePaginationFragment<
        LoadRegistersPaginationQuery,
        LoadRegisters_query_profiles$key
    >(load_registers_pagination, data);

    const [loadMutationProgressQueryReference, loadMutationProgressQuery] =
        useQueryLoader<LoadMutationProgressQuery>(load_mutation_progress);

    const { user } = useAuth();

    useEffect(() => {
        if (user?.profile?.id) {
            loadMutationProgressQuery(
                {
                    profileId: user.profile.id,
                    startingYear: state.values.startingYear,
                    block: state.values.block,
                    skipInvoiceTotals: true,
                },
                { fetchPolicy: "network-only" },
            );
        }
    }, [
        loadMutationProgressQuery,
        user?.profile?.id,
        state.values.startingYear,
        state.values.block,
    ]);

    useEffect(() => {
        if (
            registersData.profiles?.edges &&
            registersData.profiles.edges.length > 0
        ) {
            const mutableRegistersData = registersData.profiles
                .edges as Mutable<typeof registersData.profiles.edges>;
            dispatchState({
                input: "dataProvider",
                value: state.values.dataProvider.cloneWithRows(
                    // remove teachers with no lessons
                    mutableRegistersData.filter(
                        (item) => (item?.node?.totalLessons ?? 0) > 0,
                    ),
                ),
            });
            // if there is no data, create a fresh DataProvider
        } else {
            dispatchState({
                input: "dataProvider",
                value: new DataProvider((r1, r2) => {
                    return r1 !== r2;
                }),
            });
        }

        dispatchState({
            input: "refreshHandler",
            value: () => {
                dispatchState({ input: "contentIsRendered", value: false });
                refresh(
                    {
                        first: LOAD_X_SCHOOLS,
                        searchTerm: state.values.searchTerm.trim(),
                    },
                    {
                        fetchPolicy: "network-only",
                    },
                );
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registersData]);

    const onEndReached = useCallback(() => {
        if (hasNext && !isLoadingNext) {
            loadNext(LOAD_X_SCHOOLS);
        }
    }, [hasNext, isLoadingNext, loadNext]);

    // stop refetch useEffects firing on first render
    const renderCount = useRef(0);
    const environment = useRelayEnvironment();
    const { colors } = useTheme();

    const refetchRegisters = useCallback(
        (variables: {
            block: number;
            first: number;
            searchTerm?: string;
            startingYear: number;
        }): Subscription | undefined => {
            dispatchState({ input: "isRefetching", value: true });

            const subscription = fetchQuery<LoadRegistersQuery>(
                environment,
                load_registers,
                variables,
                { fetchPolicy: "store-or-network" },
            ).subscribe({
                complete: () => {
                    dispatchState({ input: "isRefetching", value: false });
                    refresh(variables, { fetchPolicy: "store-only" });
                },
                error: () => {
                    dispatchState({ input: "isRefetching", value: false });
                },
            });
            return subscription;
        },
        [dispatchState, environment, refresh],
    );

    useEffect(() => {
        if (renderCount.current < 2) {
            renderCount.current += 1;
            return;
        }

        dispatchState({ input: "isRefetching", value: false });
        state.values.subscription?.unsubscribe();

        // fire refetch immediately when orderBy or filters change
        dispatchState({
            input: "subscription",
            value: refetchRegisters({
                first: LOAD_X_SCHOOLS,
                searchTerm: state.values.searchTerm.trim(),
                startingYear: state.values.startingYear,
                block: state.values.block,
            }),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.values.startingYear, state.values.block]);

    useDebounceFunction(
        () => {
            if (renderCount.current < 2) {
                renderCount.current += 1;
                return;
            }

            dispatchState({ input: "isRefetching", value: false });
            state.values.subscription?.unsubscribe();

            // fire refetch immediately when orderBy or filters change
            dispatchState({
                input: "subscription",
                value: refetchRegisters({
                    first: LOAD_X_SCHOOLS,
                    searchTerm: state.values.searchTerm.trim(),
                    startingYear: state.values.startingYear,
                    block: state.values.block,
                }),
            });
        }, // no delay if clearing text
        state.values.searchTerm !== "" ? 750 : 0,

        [state.values.searchTerm],
    );

    const { scale, width: windowWidth } = useWindowDimensions();

    useEffect(() => {
        // this allows the RLV to resize when the window resizes
        dispatchState({
            input: "layoutProvider",
            value: new LayoutProvider(
                () => "VSEL",
                (type, dim) => {
                    switch (type) {
                        case "VSEL":
                            dim.width = getScaledWindowDimension(
                                windowWidth,
                                scale,
                            );
                            dim.height = 40;
                            break;
                        default:
                            dim.width = 0;
                            dim.height = 0;
                    }
                },
            ),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowWidth, scale]);

    const onPressProfile = useCallback(
        (profileId?: string | null) => {
            if (profileId) {
                navigation.navigate("Account", {
                    profileId: profileId,
                    accountType: "TEACHER",
                    block: state.values.block,
                    startingYear: state.values.startingYear,
                });
            }
        },
        [navigation, state.values.block, state.values.startingYear],
    );

    const rowRenderer = useCallback(
        (_: unknown, data: TableData, index: number): ReactElement | null => {
            if (data?.node) {
                return (
                    <Box
                        borderColor="surface.400"
                        borderLeftWidth={TABLE_BORDER_WIDTH}>
                        <RegisterHubTableRow
                            cellProps={{
                                hideTopBorder: index === 0,
                                pressableTextProps: {
                                    fontFamily: "Poppins-Regular",
                                },
                                textProps: {
                                    fontSize: "md",
                                    textAlign: "center",
                                    fontFamily: "Poppins-Light",
                                },
                                px: 2,
                            }}
                            data={data.node}
                            flexArray={FLEX_ARRAY}
                            onPressProfile={onPressProfile}
                            rowHeight={10}
                            tableBorderColor={TABLE_BORDER_COLOR}
                            tableBorderWidth={TABLE_BORDER_WIDTH}
                        />
                    </Box>
                );
            } else {
                return null;
            }
        },
        [onPressProfile],
    );

    const renderFooter = useCallback(() => {
        return isLoadingNext ? (
            <CustomFlatListSpinner
                borderColor={TABLE_BORDER_COLOR}
                borderTopWidth={1}
                position="relative"
                pt="8"
                top="0"
            />
        ) : null;
    }, [isLoadingNext]);

    useEffect(() => {
        dispatchState({ input: "contentIsRendered", value: true });
        // unsubscribe from refetch on unmount
        return () => state.values.subscription?.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryReference]);

    if (!state.values.contentIsRendered) {
        return <LoadingBlobs>Loading Registers...</LoadingBlobs>;
    }

    return (
        <>
            <Box flex={1} mx="30px">
                {state.values.dataProvider.getSize() > 0 &&
                state.values.layoutProvider ? (
                    <RecyclerListView
                        canChangeSize
                        dataProvider={state.values.dataProvider}
                        layoutProvider={state.values.layoutProvider}
                        onEndReached={onEndReached}
                        onEndReachedThreshold={2000}
                        renderAheadOffset={800}
                        renderFooter={renderFooter}
                        rowRenderer={rowRenderer}
                        scrollViewProps={{
                            contentContainerStyle: {
                                borderColor:
                                    // @ts-expect-error can't index with variable
                                    colors?.[TABLE_BORDER_COLOR.split(".")[0]][
                                        TABLE_BORDER_COLOR.split(".")[1]
                                    ],
                                borderBottomWidth:
                                    (registersData.profiles?.edges.length ??
                                        0 > 0) &&
                                    !isLoadingNext
                                        ? TABLE_BORDER_WIDTH
                                        : 0,
                                marginBottom: 40,
                                overflow: "hidden",
                                borderBottomRadius: TABLE_BORDER_RADIUS,
                            },
                            showsVerticalScrollIndicator: false,
                        }}
                        style={{
                            flex: 1,
                            minHeight: 1,
                        }}
                        suppressBoundedSizeException
                        useWindowScroll
                    />
                ) : (
                    <ListEmptyBanner
                        actionButton={
                            <Button
                                _hover={{ bg: "primary.500" }}
                                _pressed={{ bg: "primary.600" }}
                                _text={{ fontSize: "17" }}
                                bg="primary.400"
                                isLoading={
                                    generateRegistersForNewBlockInFlight ||
                                    Boolean(
                                        user?.profile?.profileGroup
                                            ?.generateRegistersForNewBlockProgress !=
                                            null &&
                                            user?.profile?.profileGroup
                                                ?.generateRegistersForNewBlockProgress >=
                                                0 &&
                                            user?.profile?.profileGroup
                                                ?.generateRegistersForNewBlockProgress <
                                                100,
                                    )
                                }
                                isLoadingText={"Generating Registers"}
                                leftIcon={<RegisterIcon size="md" />}
                                onPress={() => {
                                    dispatchState({
                                        input: "generateNewRegistersForTeachersTeacherSelectModalOpen",
                                        value: true,
                                    });
                                }}
                                px="4"
                                shadow={1}>
                                Copy Teacher Registers from Previous Block
                            </Button>
                        }
                        explainer={"No registers found..."}>
                        <Text fontSize="6xl">
                            {emoji.getUnicode("neutral_face")}
                        </Text>
                    </ListEmptyBanner>
                )}
            </Box>
            {loadMutationProgressQueryReference != null ? (
                <RegisterMutationProgressIndicators
                    ref={progressIndicatorRef}
                    dispatchState={dispatchState}
                    loadMutationProgressQuery={loadMutationProgressQuery}
                    loadMutationProgressQueryReference={
                        loadMutationProgressQueryReference
                    }
                    state={state}
                />
            ) : null}
        </>
    );
};

const RegistersHubScreen: FC<ScreenProps> = (props) => {
    const { navigation, route } = props;

    const { user } = useAuth();

    const [defaultStartingYear, startingYears] = useMemo(
        () => getDefaultStartingYearAndStartingYearOptions(),
        [],
    );

    const initialState = useMemo(() => {
        let initialStartingYear = route.params?.startingYear;
        if (initialStartingYear === undefined) {
            initialStartingYear =
                user?.profile?.profileGroup?.currentStartingYear ??
                defaultStartingYear;
            navigation.setParams({
                startingYear: initialStartingYear,
            });
        }
        let initialBlock = route.params?.block;
        if (initialBlock === undefined) {
            if (
                user?.profile?.profileGroup?.currentStartingYear &&
                user?.profile?.profileGroup?.currentStartingYear >
                    defaultStartingYear
            ) {
                initialBlock = 1;
            } else {
                initialBlock = user?.profile?.currentBlock ?? getCurrentBlock();
            }
            navigation.setParams({
                block: initialBlock,
            });
        }

        return {
            values: {
                searchTerm: route.params?.searchTerm ?? "",
                startingYear: initialStartingYear,
                block: initialBlock,
                contentIsRendered: false,
                isRefetching: false,
                generateNewRegistersAlertIsOpen: false,
                generateTeacherGoogleRegistersModalIsOpen: false,
                generateSchoolGoogleRegistersModalIsOpen: false,
                readInRegistersModalIsOpen: false,
                bulkUploadRegistersModalIsOpen: false,
                googleDriveNewRegistersUrl: undefined,
                failedMutationItems: [],
                subscription: undefined,
                dataProvider: new DataProvider((r1, r2) => {
                    return r1 !== r2;
                }),
                layoutProvider: undefined,
                refreshHandler: undefined,
                showMutationProgressIndicators: {
                    generateGoogleRegisters: false,
                    readInRegisters: false,
                    bulkUploadNewRegisters: false,
                    generateRegistersForNewBlock: false,
                },
                generateNewRegistersForTeachersAlertIsOpen: false,
                generateNewRegistersForTeachersTeacherSelectModalOpen: false,
                teacherIds: [],
            },
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [state, dispatchState] = useReducer(reducer, initialState);

    const [loadRegistersQueryReference, loadRegistersQuery] =
        useQueryLoader<LoadRegistersQuery>(load_registers);

    useEffect(() => {
        if (!state.values.contentIsRendered) {
            loadRegistersQuery(
                {
                    first: LOAD_X_SCHOOLS,
                    searchTerm: state.values.searchTerm.trim(),
                    startingYear: state.values.startingYear,
                    block: state.values.block,
                },
                { fetchPolicy: "store-or-network" },
            );
        }
    }, [
        loadRegistersQuery,
        state.values.block,
        state.values.contentIsRendered,
        state.values.searchTerm,
        state.values.startingYear,
    ]);

    const toast = useToast();

    const progressIndicatorRef =
        useRef<RegisterMutationProgressIndicatorsRef>(null);

    const [
        commitGenerateOrUpdateGoogleRegisters,
        generateOrUpdateGoogleRegistersInFlight,
    ] = useMutation<GenerateOrUpdateGoogleRegistersMutation>(
        generate_or_update_google_registers,
    );

    const generateOrUpdateGoogleRegisters = useCallback(
        (variables: {
            inputIds: string[];
            isSchoolRegisters: boolean;
            readInRegisterMarkings?: boolean;
        }) => {
            let hasErrored = false;
            const failedMutationItems: string[] = [];

            const generateOrUpdateGoogleRegistersConfig = (
                numberOfRegistersGenerated: number | undefined,
                inputIds: string[],
            ) => {
                progressIndicatorRef.current?.refreshMutationProgress();
                return {
                    variables: {
                        input: {
                            startingYear: state.values.startingYear,
                            block: state.values.block,
                            inputIds: inputIds,
                            isSchoolRegisters: variables.isSchoolRegisters,
                            numberOfRegistersGenerated:
                                numberOfRegistersGenerated,
                            registersInBatch: REGISTERS_TO_GENERATE_IN_BATCH,
                            readInRegisterMarkings:
                                variables.readInRegisterMarkings,
                        },
                    },
                    onCompleted: (
                        response: GenerateOrUpdateGoogleRegistersMutation$data,
                    ) => {
                        let label = "";
                        if (
                            response.generateOrUpdateGoogleRegisters
                                ?.erroredTeacher
                        ) {
                            if (
                                response.generateOrUpdateGoogleRegisters
                                    ?.erroredTeacher?.user.firstName
                            ) {
                                label = getFullName(
                                    response.generateOrUpdateGoogleRegisters
                                        .erroredTeacher.user.firstName,
                                    response.generateOrUpdateGoogleRegisters
                                        .erroredTeacher.user.lastName,
                                );
                            } else {
                                label =
                                    response.generateOrUpdateGoogleRegisters
                                        .erroredTeacher?.user.email ?? "";
                            }
                        } else if (
                            response.generateOrUpdateGoogleRegisters
                                ?.erroredSchool
                        ) {
                            label =
                                response.generateOrUpdateGoogleRegisters
                                    ?.erroredSchool?.name ?? "un-named school";
                        }
                        if (label != "") {
                            failedMutationItems.push(label);
                            toast.show({
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        description={failedMutationItems.join(
                                            "\n",
                                        )}
                                        id={id}
                                        status="error"
                                        title={
                                            "Couldn't generate registers for:"
                                        }
                                        toast={toast}
                                    />
                                ),
                                // never auto-dismiss
                                duration: null,
                            });
                        }
                        if (
                            response?.generateOrUpdateGoogleRegisters?.success
                        ) {
                            if (
                                response.generateOrUpdateGoogleRegisters
                                    .numberOfRegistersGenerated !==
                                    response.generateOrUpdateGoogleRegisters
                                        .totalToGenerate &&
                                !hasErrored
                            ) {
                                if (
                                    response.generateOrUpdateGoogleRegisters
                                        .profileGroup
                                        ?.generateGoogleRegistersProgress != 100
                                ) {
                                    commitGenerateOrUpdateGoogleRegisters(
                                        generateOrUpdateGoogleRegistersConfig(
                                            response
                                                .generateOrUpdateGoogleRegisters
                                                .numberOfRegistersGenerated ??
                                                undefined,
                                            variables.inputIds,
                                        ),
                                    );
                                }
                            }
                        } else {
                            hasErrored = true;
                            toast.show({
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        description={
                                            "Please get in touch with one of the team"
                                        }
                                        id={id}
                                        status="error"
                                        title={"Error generating registers"}
                                        toast={toast}
                                    />
                                ),
                                // never auto-dismiss
                                duration: null,
                            });
                        }
                    },
                    // this happens if the API gateway times out for a register
                    // this will happen for nearly every register, but is not a problem
                    // we just need to wait long enough for the register to finish generating
                    // before firing off another mutation and continuing
                    onError: () => {
                        progressIndicatorRef.current?.refreshMutationProgress();
                        if (
                            typeof numberOfRegistersGenerated !== "undefined" &&
                            numberOfRegistersGenerated + 1 <
                                variables.inputIds.length
                        ) {
                            setTimeout(() => {
                                commitGenerateOrUpdateGoogleRegisters(
                                    generateOrUpdateGoogleRegistersConfig(
                                        numberOfRegistersGenerated + 1,
                                        variables.inputIds,
                                    ),
                                );
                                // give each register 4 minutes to generate
                            }, 180000);
                        }
                    },
                };
            };

            // to avoid hitting the max AWS API Gateway execution time (29s)
            // split up the invoice generation into batches and recursively
            // fire the mutation, keeping a track of overall progress
            const numberOfRegistersGenerated = 0 as number | undefined;

            commitGenerateOrUpdateGoogleRegisters(
                generateOrUpdateGoogleRegistersConfig(
                    numberOfRegistersGenerated,
                    variables.inputIds,
                ),
            );
            dispatchState({
                input: "showMutationProgressIndicators",
                value: {
                    ...state.values.showMutationProgressIndicators,
                    generateGoogleRegisters: true,
                },
            });
        },
        [state.values, commitGenerateOrUpdateGoogleRegisters, toast],
    );

    const [commitReadInGoogleRegisters, readInGoogleRegistersInFlight] =
        useMutation<ReadInGoogleRegistersMutation>(read_in_google_registers);

    const readInGoogleRegisters = useCallback(
        (variables: { teacherIds: string[] }) => {
            let hasErrored = false;
            const failedMutationItems: string[] = [];

            const readInGoogleRegistersConfig = (
                numberOfRegistersReadIn: number | undefined,
                teacherIds: string[],
            ) => {
                return {
                    variables: {
                        input: {
                            startingYear: state.values.startingYear,
                            block: state.values.block,
                            teacherIds: teacherIds,
                            numberOfRegistersReadIn: numberOfRegistersReadIn,
                            registersInBatch: REGISTERS_TO_READ_IN_BATCH,
                        },
                    },
                    onCompleted: (
                        response: ReadInGoogleRegistersMutation$data,
                    ) => {
                        if (response.readInGoogleRegisters?.erroredTeacher) {
                            let label = "";
                            if (
                                response.readInGoogleRegisters?.erroredTeacher
                                    ?.user.firstName
                            ) {
                                label = getFullName(
                                    response.readInGoogleRegisters
                                        .erroredTeacher.user.firstName,
                                    response.readInGoogleRegisters
                                        .erroredTeacher.user.lastName,
                                );
                            } else {
                                label =
                                    response.readInGoogleRegisters
                                        .erroredTeacher?.user.email;
                            }

                            failedMutationItems.push(label);

                            toast.show({
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        description={failedMutationItems.join(
                                            "\n",
                                        )}
                                        id={id}
                                        status="error"
                                        title={
                                            "Couldn't read in registers for:"
                                        }
                                        toast={toast}
                                    />
                                ),
                                // never auto-dismiss
                                duration: null,
                            });
                        }
                        if (response?.readInGoogleRegisters?.success) {
                            if (
                                response.readInGoogleRegisters
                                    .numberOfRegistersReadIn !==
                                    response.readInGoogleRegisters
                                        .totalToReadIn &&
                                !hasErrored
                            ) {
                                if (
                                    response.readInGoogleRegisters.profileGroup
                                        ?.readInRegistersProgress != 100
                                ) {
                                    commitReadInGoogleRegisters(
                                        readInGoogleRegistersConfig(
                                            response.readInGoogleRegisters
                                                .numberOfRegistersReadIn ??
                                                undefined,
                                            variables.teacherIds,
                                        ),
                                    );
                                }
                            }
                        } else {
                            hasErrored = true;
                            toast.show({
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        description={
                                            "Please get in touch with one of the team"
                                        }
                                        id={id}
                                        status="error"
                                        title={"Error reading in registers"}
                                        toast={toast}
                                    />
                                ),
                                // never auto-dismiss
                                duration: null,
                            });
                        }
                    },
                    // this happens if the API gateway times out for a register
                    // in this case, just fire off another mutation and continue
                    onError: () => {
                        progressIndicatorRef.current?.refreshMutationProgress();
                        // wait for 30 seconds to let the previous register complete (if it is completing)
                        setTimeout(() => {
                            if (
                                typeof numberOfRegistersReadIn !== "undefined"
                            ) {
                                commitReadInGoogleRegisters(
                                    readInGoogleRegistersConfig(
                                        numberOfRegistersReadIn + 1,
                                        variables.teacherIds,
                                    ),
                                );
                            }
                        }, 30000);
                    },
                };
            };

            // to avoid hitting the max AWS API Gateway execution time (29s)
            // split up the invoice generation into batches and recursively
            // fire the mutation, keeping a track of overall progress
            const numberOfRegistersReadIn = 0 as number | undefined;

            commitReadInGoogleRegisters(
                readInGoogleRegistersConfig(
                    numberOfRegistersReadIn,
                    variables.teacherIds,
                ),
            );
            dispatchState({
                input: "showMutationProgressIndicators",
                value: {
                    ...state.values.showMutationProgressIndicators,
                    readInRegisters: true,
                },
            });
        },
        [state.values, commitReadInGoogleRegisters, toast],
    );

    const [commitBulkUploadNewRegisters, bulkUploadNewRegistersInFlight] =
        useMutation<BulkUploadNewRegistersMutation>(bulk_upload_new_registers);

    const bulkUploadNewRegisters = useCallback(
        (variables: {
            block: number;
            googleDriveNewRegistersUrl: string;
            startingYear: number;
        }) => {
            const bulkUploadNewRegistersConfig = {
                variables: {
                    input: {
                        startingYear: variables.startingYear,
                        block: variables.block,
                        googleDriveNewRegistersUrl:
                            variables.googleDriveNewRegistersUrl,
                    },
                },
                onCompleted: (
                    response: BulkUploadNewRegistersMutation$data,
                ) => {
                    if (response?.bulkUploadNewRegisters?.success) {
                        // refresh on complete
                        dispatchState({
                            input: "contentIsRendered",
                            value: false,
                        });
                        state.values.refreshHandler?.();
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={"Uploaded new registers"}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        response?.bulkUploadNewRegisters
                                            ?.erroredSchool?.name
                                            ? `The problem is with ${response.bulkUploadNewRegisters.erroredSchool.name}`
                                            : "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't upload new registers"}
                                    toast={toast}
                                />
                            ),
                            duration: null,
                        });
                    }
                },
            };

            commitBulkUploadNewRegisters(bulkUploadNewRegistersConfig);
            dispatchState({
                input: "showMutationProgressIndicators",
                value: {
                    ...state.values.showMutationProgressIndicators,
                    bulkUploadNewRegisters: true,
                },
            });
        },
        [commitBulkUploadNewRegisters, state.values, toast],
    );

    const [
        commitGenerateRegistersForNewBlock,
        generateRegistersForNewBlockInFlight,
    ] = useMutation<GenerateRegistersForNewBlockMutation>(
        generate_registers_for_new_block,
    );

    const generateRegistersForNewBlock = useCallback(
        (teacherIds?: string[]) => {
            const generateRegistersForNewBlockConfig = {
                variables: {
                    input: {
                        startingYear: state.values.startingYear,
                        block: state.values.block,
                        teacherIds: teacherIds ?? [],
                    },
                },
                onCompleted: (
                    response: GenerateRegistersForNewBlockMutation$data,
                ) => {
                    if (response?.generateRegistersForNewBlock?.success) {
                        // refresh on complete
                        setTimeout(() => {
                            dispatchState({
                                input: "contentIsRendered",
                                value: false,
                            });
                            state.values.refreshHandler?.();
                        }, 500);
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={"New Registers Generated"}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't generate new registers"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };
            commitGenerateRegistersForNewBlock(
                generateRegistersForNewBlockConfig,
            );
            dispatchState({
                input: "showMutationProgressIndicators",
                value: {
                    ...state.values.showMutationProgressIndicators,
                    generateRegistersForNewBlock: true,
                },
            });
        },
        [state.values, commitGenerateRegistersForNewBlock, toast],
    );

    const inputChangeHandler = useCallback(
        (_: unknown, inputValue?: string) => {
            dispatchState({
                input: "searchTerm",
                value: inputValue,
            });
            navigation.setParams({ searchTerm: inputValue });
        },
        [navigation],
    );

    const inputClearHandler = useCallback(() => {
        if (state.values.searchTerm !== "") {
            dispatchState({
                input: "searchTerm",
                value: "",
            });
            navigation.setParams({ searchTerm: undefined });
        }
    }, [navigation, state.values.searchTerm]);

    const bulkUploadNewRegistersHandler = useCallback(
        (googleDriveNewRegistersUrl?: string) => {
            if (
                googleDriveNewRegistersUrl ||
                state.values.googleDriveNewRegistersUrl
            ) {
                bulkUploadNewRegisters({
                    startingYear: state.values.startingYear,
                    block: state.values.block,
                    googleDriveNewRegistersUrl: (googleDriveNewRegistersUrl ??
                        state.values.googleDriveNewRegistersUrl) as string,
                });
            }
            dispatchState({
                input: "bulkUploadRegistersModalIsOpen",
                value: false,
            });
            dispatchState({
                input: "googleDriveCsvUrl",
                value: undefined,
            });
        },
        [
            bulkUploadNewRegisters,
            state.values.block,
            state.values.googleDriveNewRegistersUrl,
            state.values.startingYear,
        ],
    );

    const {
        isOpen: registerActionsActionsheetIsOpen,
        onClose: registerActionsActionsheetOnClose,
        onOpen: registerActionsActionsheetOnOpen,
    } = useDisclose();

    const renderHeader = useMemo(() => {
        return (
            <VStack mx="30px" pt="6" space="6">
                <HStack ml="1" space="3">
                    <RegisterIcon color="primary.600" size="2xl" />
                    <Heading color="primary.600" fontSize="xl">
                        Registers
                    </Heading>
                </HStack>
                <HStack justifyContent="space-between">
                    <HStack space="4">
                        <Box minWidth="400" width="25%">
                            <SearchBar
                                inputClearHandler={inputClearHandler}
                                inputOnChangeHandler={inputChangeHandler}
                                inputSearchHandler={inputChangeHandler}
                                placeholderText={
                                    "Search by teacher name or email"
                                }
                                searchText={state.values.searchTerm}
                                showSearchIcon
                            />
                        </Box>
                        <Select
                            borderRadius="2xl"
                            fontSize="md"
                            onValueChange={(itemValue) => {
                                const value = parseInt(itemValue);

                                if (value > state.values.startingYear) {
                                    dispatchState({
                                        input: "block",
                                        value: 1,
                                    });
                                    navigation.setParams({
                                        block: 1,
                                    });
                                }

                                dispatchState({
                                    input: "startingYear",
                                    value: value,
                                });
                                navigation.setParams({
                                    startingYear: value,
                                });
                            }}
                            placeholder="Select year"
                            py="2.5"
                            selectedValue={String(state.values.startingYear)}
                            width="40">
                            {startingYears.map((item) => {
                                return (
                                    <Select.Item
                                        key={item.value}
                                        actionSheetLabel={item.label}
                                        value={String(item.value)}
                                    />
                                );
                            })}
                        </Select>
                        <Select
                            borderRadius="2xl"
                            fontSize="md"
                            onValueChange={(itemValue) => {
                                dispatchState({
                                    input: "block",
                                    value: parseInt(itemValue),
                                });
                                navigation.setParams({
                                    block: parseInt(itemValue),
                                });
                            }}
                            placeholder="Select block"
                            py="2.5"
                            selectedValue={String(state.values.block)}
                            width="40">
                            {BLOCKS.map((item) => {
                                return (
                                    <Select.Item
                                        key={item.value}
                                        actionSheetLabel={item.label}
                                        value={item.value}
                                    />
                                );
                            })}
                        </Select>
                        <Button
                            bg="surface.400"
                            colorScheme="surface"
                            leftIcon={<RestartIcon size="5" />}
                            onPress={state.values.refreshHandler}
                            p="3"
                        />
                    </HStack>
                    <Box>
                        <Button
                            _hover={{ bg: "primary.500" }}
                            _pressed={{ bg: "primary.600" }}
                            _text={{ fontSize: "17" }}
                            bg="primary.400"
                            leftIcon={<RegisterIcon size="md" />}
                            px="4"
                            shadow={1}
                            {...(generateOrUpdateGoogleRegistersInFlight ||
                            Boolean(
                                user?.profile?.profileGroup
                                    ?.generateGoogleRegistersProgress != null &&
                                    user?.profile?.profileGroup
                                        ?.generateGoogleRegistersProgress >=
                                        0 &&
                                    user?.profile?.profileGroup
                                        ?.generateGoogleRegistersProgress < 100,
                            )
                                ? {
                                      isLoading: true,
                                      isLoadingText: "Generating Registers",
                                  }
                                : readInGoogleRegistersInFlight ||
                                    Boolean(
                                        user?.profile?.profileGroup
                                            ?.readInRegistersProgress != null &&
                                            user?.profile?.profileGroup
                                                ?.readInRegistersProgress >=
                                                0 &&
                                            user?.profile?.profileGroup
                                                ?.readInRegistersProgress < 100,
                                    )
                                  ? {
                                        isLoading: true,
                                        isLoadingText: "Reading In Registers",
                                    }
                                  : bulkUploadNewRegistersInFlight ||
                                      Boolean(
                                          user?.profile?.profileGroup
                                              ?.bulkUploadNewRegistersProgress !=
                                              null &&
                                              user?.profile?.profileGroup
                                                  ?.bulkUploadNewRegistersProgress >=
                                                  0 &&
                                              user?.profile?.profileGroup
                                                  ?.bulkUploadNewRegistersProgress <
                                                  100,
                                      )
                                    ? {
                                          isLoading: true,
                                          isLoadingText:
                                              "Uploading New Registers",
                                      }
                                    : generateRegistersForNewBlockInFlight ||
                                        Boolean(
                                            user?.profile?.profileGroup
                                                ?.generateRegistersForNewBlockProgress !=
                                                null &&
                                                user?.profile?.profileGroup
                                                    ?.generateRegistersForNewBlockProgress >=
                                                    0 &&
                                                user?.profile?.profileGroup
                                                    ?.generateRegistersForNewBlockProgress <
                                                    100,
                                        )
                                      ? {
                                            isLoading: true,
                                            isLoadingText:
                                                "Generating Registers",
                                        }
                                      : {})}
                            onPress={() => registerActionsActionsheetOnOpen()}>
                            View Register Actions
                        </Button>
                    </Box>
                </HStack>
                <Box bg="surface.100">
                    <Box
                        bg="primary.50"
                        borderColor={TABLE_BORDER_COLOR}
                        borderTopRadius={TABLE_BORDER_RADIUS}
                        borderWidth={TABLE_BORDER_WIDTH}>
                        <Row
                            cellProps={{
                                px: 2,
                                hideTopBorder: true,
                                hideOuterSideBorder: true,
                                textProps: {
                                    fontSize: "md",
                                    textAlign: "center",
                                },
                            }}
                            data={[
                                {
                                    data: "Teacher",
                                },
                                {
                                    data: "Register Completion",
                                },
                            ]}
                            flexArray={FLEX_ARRAY}
                            rowHeight={10}
                            rowIndex={0}
                            tableBorderColor={TABLE_BORDER_COLOR}
                            tableBorderWidth={TABLE_BORDER_WIDTH}
                        />
                    </Box>
                </Box>
            </VStack>
        );
    }, [
        bulkUploadNewRegistersInFlight,
        generateOrUpdateGoogleRegistersInFlight,
        generateRegistersForNewBlockInFlight,
        inputChangeHandler,
        inputClearHandler,
        navigation,
        readInGoogleRegistersInFlight,
        registerActionsActionsheetOnOpen,
        startingYears,
        state.values.block,
        state.values.refreshHandler,
        state.values.searchTerm,
        state.values.startingYear,
        user?.profile?.profileGroup?.bulkUploadNewRegistersProgress,
        user?.profile?.profileGroup?.generateGoogleRegistersProgress,
        user?.profile?.profileGroup?.generateRegistersForNewBlockProgress,
        user?.profile?.profileGroup?.readInRegistersProgress,
    ]);

    const safeGenerateRegistersAlertRef = useRef(null);
    const schoolModalKey = useRef(0);

    const renderRegisterActionsActionsheet = useMemo(() => {
        return (
            <Actionsheet
                animationType="fade"
                hideDragIndicator
                isOpen={registerActionsActionsheetIsOpen}
                justifyContent="center"
                onClose={registerActionsActionsheetOnClose}
                size="lg">
                <Actionsheet.Content
                    alignItems="center"
                    closeButtonProps={{ top: "5", right: "5" }}
                    justifyContent="center"
                    mx="auto"
                    roundedBottom={12}
                    roundedTop={12}
                    showCloseButton
                    w="40%">
                    <Text
                        alignSelf="center"
                        fontSize="xl"
                        fontWeight="bold"
                        pb="2"
                        pt="4">
                        Register Actions
                    </Text>
                    <Text alignSelf="center" fontSize="md" pb="5">
                        {getTermInWords(
                            state.values.block,
                            state.values.startingYear,
                        )}
                    </Text>
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="7">
                                <AccountsIcon color="primary.600" size="5" />
                            </Center>
                        }
                        onPress={() => {
                            registerActionsActionsheetOnClose();
                            dispatchState({
                                input: "generateTeacherGoogleRegistersModalIsOpen",
                                value: true,
                            });
                        }}>
                        Generate or update teacher registers
                    </Actionsheet.Item>
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="7">
                                <SchoolIcon color="primary.500" size="5" />
                            </Center>
                        }
                        onPress={() => {
                            registerActionsActionsheetOnClose();
                            dispatchState({
                                input: "generateSchoolGoogleRegistersModalIsOpen",
                                value: true,
                            });
                        }}>
                        Generate or update school registers
                    </Actionsheet.Item>
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="7">
                                <SortDescDate color="secondary.600" size="6" />
                            </Center>
                        }
                        onPress={() => {
                            registerActionsActionsheetOnClose();
                            dispatchState({
                                input: "readInRegistersModalIsOpen",
                                value: true,
                            });
                        }}>
                        {"Read in register data"}
                    </Actionsheet.Item>
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="7">
                                <UploadIcon color="orange.400" size="6" />
                            </Center>
                        }
                        onPress={() => {
                            registerActionsActionsheetOnClose();
                            dispatchState({
                                input: "bulkUploadRegistersModalIsOpen",
                                value: true,
                            });
                        }}>
                        Bulk upload new registers
                    </Actionsheet.Item>
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="7">
                                <CopyCalendarIcon color="red.500" size="6" />
                            </Center>
                        }
                        onPress={() => {
                            registerActionsActionsheetOnClose();
                            dispatchState({
                                input: "generateNewRegistersAlertIsOpen",
                                value: true,
                            });
                        }}>
                        Copy all registers from previous block
                    </Actionsheet.Item>
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="7">
                                <CopyCalendarIcon color="red.500" size="6" />
                            </Center>
                        }
                        onPress={() => {
                            registerActionsActionsheetOnClose();
                            dispatchState({
                                input: "generateNewRegistersForTeachersTeacherSelectModalOpen",
                                value: true,
                            });
                        }}>
                        Copy teacher registers from previous block
                    </Actionsheet.Item>
                    <Text
                        alignSelf="center"
                        fontFamily="Poppins-Regular"
                        fontSize="md"
                        mx="8"
                        py="4"
                        textAlign="center">
                        {
                            "After clicking on an action above, you have the option to select which teachers/schools to do it for."
                        }
                    </Text>
                </Actionsheet.Content>
            </Actionsheet>
        );
    }, [
        registerActionsActionsheetIsOpen,
        registerActionsActionsheetOnClose,
        state.values.block,
        state.values.startingYear,
    ]);

    const renderRegisterGenerateTeacherSelectModal = useMemo(() => {
        return (
            <TeacherMultiSelectModal
                // reset modal state each time it's opened
                buttonText="Generate"
                checkboxDefaultIsSelected={true}
                checkboxText="Read in register markings"
                hideModal={() => {
                    dispatchState({
                        input: "generateTeacherGoogleRegistersModalIsOpen",
                        value: false,
                    });
                }}
                includeCheckbox={true}
                initiallySelectAllTeachers
                onSave={({
                    checkboxOptionIsTrue: readInRegisterMarkings,
                    teacherIds,
                }) =>
                    generateOrUpdateGoogleRegisters({
                        inputIds: teacherIds,
                        isSchoolRegisters: false,
                        readInRegisterMarkings: readInRegisterMarkings,
                    })
                }
                showModal={
                    state.values.generateTeacherGoogleRegistersModalIsOpen
                }
                termInWords={getTermInWords(
                    state.values.block,
                    state.values.startingYear,
                )}
                title="Generate Or Update Registers"
            />
        );
    }, [
        generateOrUpdateGoogleRegisters,
        state.values.block,
        state.values.generateTeacherGoogleRegistersModalIsOpen,
        state.values.startingYear,
    ]);

    const renderRegisterReadInTeacherSelectModal = useMemo(() => {
        return (
            <TeacherMultiSelectModal
                // reset modal state each time it's opened
                buttonText="Read In"
                hideModal={() => {
                    dispatchState({
                        input: "readInRegistersModalIsOpen",
                        value: false,
                    });
                }}
                initiallySelectAllTeachers
                onSave={({ teacherIds }) =>
                    readInGoogleRegisters({
                        teacherIds,
                    })
                }
                showModal={state.values.readInRegistersModalIsOpen}
                termInWords={getTermInWords(
                    state.values.block,
                    state.values.startingYear,
                )}
                title="Read In Registers"
            />
        );
    }, [
        readInGoogleRegisters,
        state.values.block,
        state.values.readInRegistersModalIsOpen,
        state.values.startingYear,
    ]);

    const renderSchoolSelectModal = useMemo(() => {
        return (
            <SchoolMultiSelectModal
                // reset modal state each time it's opened
                key={schoolModalKey.current}
                buttonText="Generate"
                hideModal={() => {
                    dispatchState({
                        input: "generateSchoolGoogleRegistersModalIsOpen",
                        value: false,
                    });
                    schoolModalKey.current = Math.random();
                }}
                initiallySelectAllSchools
                onSave={({ schoolIds }) =>
                    generateOrUpdateGoogleRegisters({
                        inputIds: schoolIds,
                        isSchoolRegisters: true,
                    })
                }
                showModal={
                    state.values.generateSchoolGoogleRegistersModalIsOpen
                }
                termInWords={getTermInWords(
                    state.values.block,
                    state.values.startingYear,
                )}
                title="Generate Or Update Registers"
            />
        );
    }, [
        generateOrUpdateGoogleRegisters,
        state.values.block,
        state.values.generateSchoolGoogleRegistersModalIsOpen,
        state.values.startingYear,
    ]);

    const renderAlerts = useMemo(() => {
        return (
            <>
                <AlertPopup
                    alertIsOpen={state.values.bulkUploadRegistersModalIsOpen}
                    body={`${getTermInWords(
                        state.values.block,
                        state.values.startingYear,
                    )}\n\nPlease enter the Google Drive URL of the new registers Google Sheet below. Ensure that it conforms to the bulk upload template.`}
                    bodyStyle={{ _text: { fontSize: "md" }, mt: -5 }}
                    header="Bulk upload New Registers"
                    setAlertIsOpen={() => {
                        dispatchState({
                            input: "bulkUploadRegistersModalIsOpen",
                            value: false,
                        });
                        dispatchState({
                            input: "googleDriveCsvUrl",
                            value: undefined,
                        });
                    }}>
                    <VStack alignItems="center" space="8">
                        <Text fontSize="md" mt="-4" textAlign="center">
                            {
                                "How to prepare the bulk upload in Google Drive?\n"
                            }
                            <Text fontFamily="Poppins-Regular">
                                {"1. Make a copy of the "}
                                <Text
                                    color="primary.600"
                                    onPress={() =>
                                        window.open(
                                            "https://docs.google.com/spreadsheets/d/1_FKDT3IkQKoLK5erpbuSPs6y6jyzQRTu9EzTX-vwmSI/edit?usp=share_link",
                                            "_blank",
                                        )
                                    }
                                    textDecorationLine="underline">
                                    bulk upload template
                                </Text>
                                {
                                    ".\n2. Fill in the data.\n3. Copy the URL from the address bar into the box below."
                                }
                            </Text>
                        </Text>
                        <TextInput
                            autoCapitalize="none"
                            borderRadius="2xl"
                            fontFamily="Poppins-Regular"
                            fontSize="md"
                            id="googleDriveNewRegistersUrl"
                            keyboardType="default"
                            onFinishEditing={(_, inputValue) => {
                                dispatchState({
                                    input: "googleDriveNewRegistersUrl",
                                    value: inputValue,
                                });
                            }}
                            onSubmit={(_, inputValue) =>
                                bulkUploadNewRegistersHandler(inputValue)
                            }
                            placeholder="Enter Google Drive URL"
                            textAlign="center"
                        />
                        <Button.Group
                            alignItems="center"
                            flex={1}
                            flexDirection="row"
                            justifyContent="center"
                            mb="4"
                            p="0"
                            size="lg"
                            space={4}>
                            <ButtonDebounced
                                _text={{ fontSize: "lg" }}
                                height="50"
                                isDisabled={
                                    !state.values.googleDriveNewRegistersUrl
                                }
                                minWidth="56"
                                onPress={() => bulkUploadNewRegistersHandler()}
                                width="25%">
                                Bulk Upload
                            </ButtonDebounced>
                        </Button.Group>
                    </VStack>
                </AlertPopup>
                <AlertPopup
                    alertIsOpen={state.values.generateNewRegistersAlertIsOpen}
                    body={`This action will copy the registers from the preceeding block to this block.\n\nThe lesson dates will obey the term dates and blacklisted dates set for this block. Ensure these are correct before proceeding.${
                        state.values.block == 1
                            ? "\n\nAs this is the first block of a new school year, this action will also move the system to the new school year. This means that children in year 6 will be archived and not put on the register for the new term, and the year group of all other children will be increased."
                            : ""
                    }`}
                    bodyStyle={{ _text: { fontSize: "md" } }}
                    header={`Are you sure you want to generate new registers for this block${
                        state.values.block == 1
                            ? " and move to the next year?"
                            : "?"
                    }`}
                    safeButtonRef={safeGenerateRegistersAlertRef}
                    setAlertIsOpen={() => {
                        dispatchState({
                            input: "generateNewRegistersAlertIsOpen",
                            value: false,
                        });
                    }}>
                    <Button
                        ref={safeGenerateRegistersAlertRef}
                        _text={{ fontSize: "lg" }}
                        colorScheme="surface"
                        height="80px"
                        minWidth="56"
                        onPress={() => {
                            dispatchState({
                                input: "generateNewRegistersAlertIsOpen",
                                value: false,
                            });
                        }}
                        width="25%">
                        {"No, don't generate\nthe registers"}
                    </Button>
                    <ButtonDebounced
                        _loading={{ opacity: 1 }}
                        _spinner={{ size: "lg" }}
                        _text={{ fontSize: "lg" }}
                        height="80px"
                        minWidth="56"
                        onPress={() => {
                            dispatchState({
                                input: "generateNewRegistersAlertIsOpen",
                                value: false,
                            });
                            generateRegistersForNewBlock();
                        }}
                        width="25%">
                        {"Yes, generate\nnew registers"}
                    </ButtonDebounced>
                </AlertPopup>
                <TeacherMultiSelectModal
                    block={
                        getPreviousBlockAndStartingYear(
                            state.values.block,
                            state.values.startingYear,
                        )[0] ?? 0
                    }
                    buttonText="Copy"
                    hideModal={() => {
                        dispatchState({
                            input: "generateNewRegistersForTeachersTeacherSelectModalOpen",
                            value: false,
                        });
                    }}
                    includeCheckbox={false}
                    onSave={({ teacherIds }) => {
                        dispatchState({
                            input: "generateNewRegistersForTeachersAlertIsOpen",
                            value: true,
                        });
                        dispatchState({
                            input: "teacherIds",
                            value: teacherIds,
                        });
                    }}
                    showModal={
                        state.values
                            .generateNewRegistersForTeachersTeacherSelectModalOpen
                    }
                    showRegisterProgress={true}
                    startingYear={
                        getPreviousBlockAndStartingYear(
                            state.values.block,
                            state.values.startingYear,
                        )[1] ?? 0
                    }
                    title={`Copy teacher registers from ${getTermInWords(
                        getPreviousBlockAndStartingYear(
                            state.values.block,
                            state.values.startingYear,
                        )[0],
                        getPreviousBlockAndStartingYear(
                            state.values.block,
                            state.values.startingYear,
                        )[1],
                    )}`}
                />
                <AlertPopup
                    alertIsOpen={
                        state.values.generateNewRegistersForTeachersAlertIsOpen
                    }
                    body={`This action will copy the registers from the preceeding block to this block for ${
                        state.values.teacherIds?.length ?? 0
                    } teacher${
                        (state.values.teacherIds?.length ?? 0) > 1 ? "s" : ""
                    }.\n\nThe lesson dates will obey the term dates and blacklisted dates set for this block. Ensure these are correct before proceeding.${
                        state.values.block == 1
                            ? "\n\nAs this is the first block of a new school year, this action will also move the system to the new school year. This means that children in year 6 will be archived and not put on the register for the new term, and the year group of all other children will be increased."
                            : ""
                    }`}
                    bodyStyle={{ _text: { fontSize: "md" } }}
                    header={`Are you sure you want to generate new registers for ${
                        state.values.teacherIds?.length ?? 0
                    } teacher${
                        (state.values.teacherIds?.length ?? 0) > 1 ? "s" : ""
                    } this block${
                        state.values.block == 1
                            ? " and move to the next year?"
                            : "?"
                    }`}
                    safeButtonRef={safeGenerateRegistersAlertRef}
                    setAlertIsOpen={() => {
                        dispatchState({
                            input: "generateNewRegistersForTeachersAlertIsOpen",
                            value: false,
                        });
                    }}>
                    <Button
                        ref={safeGenerateRegistersAlertRef}
                        _text={{ fontSize: "lg" }}
                        colorScheme="surface"
                        height="80px"
                        minWidth="56"
                        onPress={() => {
                            dispatchState({
                                input: "generateNewRegistersForTeachersAlertIsOpen",
                                value: false,
                            });
                        }}
                        width="25%">
                        {"No, don't generate\nthe registers"}
                    </Button>
                    <ButtonDebounced
                        _loading={{ opacity: 1 }}
                        _spinner={{ size: "lg" }}
                        _text={{ fontSize: "lg" }}
                        height="80px"
                        minWidth="56"
                        onPress={() => {
                            dispatchState({
                                input: "generateNewRegistersForTeachersAlertIsOpen",
                                value: false,
                            });
                            generateRegistersForNewBlock(
                                state.values.teacherIds,
                            );
                        }}
                        width="25%">
                        {"Yes, generate\nnew registers"}
                    </ButtonDebounced>
                </AlertPopup>
            </>
        );
    }, [
        state.values.bulkUploadRegistersModalIsOpen,
        state.values.block,
        state.values.startingYear,
        state.values.googleDriveNewRegistersUrl,
        state.values.generateNewRegistersAlertIsOpen,
        state.values.generateNewRegistersForTeachersTeacherSelectModalOpen,
        state.values.generateNewRegistersForTeachersAlertIsOpen,
        state.values.teacherIds,
        bulkUploadNewRegistersHandler,
        generateRegistersForNewBlock,
    ]);

    const renderRefetchIndicator = useMemo(() => {
        return state.values.isRefetching ? (
            <CustomFlatListSpinner top="275" />
        ) : null;
    }, [state.values.isRefetching]);

    return (
        <Box bg="surface.100" flex={1} pt="70">
            {renderHeader}
            {loadRegistersQueryReference != null ? (
                <Suspense
                    fallback={
                        <LoadingBlobs>Loading Registers...</LoadingBlobs>
                    }>
                    <RegistersHubContent
                        dispatchState={dispatchState}
                        generateRegistersForNewBlockInFlight={
                            generateRegistersForNewBlockInFlight
                        }
                        navigation={navigation}
                        progressIndicatorRef={progressIndicatorRef}
                        queryReference={loadRegistersQueryReference}
                        state={state}
                    />
                </Suspense>
            ) : (
                <LoadingBlobs>Loading Registers...</LoadingBlobs>
            )}
            {renderRegisterActionsActionsheet}
            {renderRegisterGenerateTeacherSelectModal}
            {renderRegisterReadInTeacherSelectModal}
            {renderSchoolSelectModal}
            {renderAlerts}
            {renderRefetchIndicator}
        </Box>
    );
};

export const screenOptions = {
    headerTitle: "Registers",
};

export default RegistersHubScreen;
