import { graphql } from "react-relay";

export const update_term_dates = graphql`
    mutation UpdateTermDatesMutation($input: UpdateTermDatesInput!) {
        updateTermDates(input: $input) {
            success
            errors
            term1Dates {
                id
                startingYear
                term
                school {
                    id
                }
                firstDayOfTerm
                lastDayOfFirstHalfOfTerm
                firstDayOfSecondHalfOfTerm
                lastDayOfTerm
            }
            term2Dates {
                id
                startingYear
                term
                school {
                    id
                }
                firstDayOfTerm
                lastDayOfFirstHalfOfTerm
                firstDayOfSecondHalfOfTerm
                lastDayOfTerm
            }
            term3Dates {
                id
                startingYear
                term
                school {
                    id
                }
                firstDayOfTerm
                lastDayOfFirstHalfOfTerm
                firstDayOfSecondHalfOfTerm
                lastDayOfTerm
            }
        }
    }
`;
