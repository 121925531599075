import React, {
    useRef,
    useCallback,
    useMemo,
    useReducer,
    useEffect,
} from "react";
import type { ReactElement } from "react";

import { useNavigation, useRoute } from "@react-navigation/native";
import { Modal, VStack, Text, Box, Button } from "native-base";
import { Platform } from "react-native";

import { HomeworkIcon } from "../Other/Icons";

import type { NavigationProps as RegistersNavigationProps } from "pianofunclub-teaching-app/screens/RegistersScreen";

import ButtonDebounced from "pianofunclub-shared/components/Buttons/ButtonDebounced";
import TextInput from "pianofunclub-shared/components/Inputs/TextInput";
import Select from "pianofunclub-shared/components/NativeBaseExtended/Select";

import { getLessonStatuses } from "pianofunclub-shared/utils/extractors";
import { createReducer } from "pianofunclub-shared/utils/reducers";

export type RegisterUpdate = {
    lessonId: string;
    registerNote?: string;
    status: string;
};

export type LessonInfo =
    | {
          homeworkId?: string;
          id?: string;
          index?: number;
          instrument?: string;
          pupilName?: string;
          scheduledTimestamp?: string;
          staffRegisterNoteForTeacher?: string;
          status?: string;
          teacherRegisterNoteForStaff?: string;
      }
    | undefined;

interface Props {
    hideModal: () => void;
    isUpdatingForWeek?: boolean;
    lessonInfo?: LessonInfo;
    showModal: boolean;
    updateRegister?: (
        variables: {
            lessonId: string;
            staffRegisterNoteForTeacher?: string;
            status: string;
            teacherRegisterNoteForStaff?: string;
        },
        lessonIndex: number,
        progressChange?: number,
    ) => void;
    updateRegistersForWeek?: (variables: { status: string }) => void;
    userIsTeacher?: boolean;
}

type ReducerValues = {
    staffRegisterNoteForTeacher?: string;
    status: string;
    teacherRegisterNoteForStaff?: string;
};

type ReducerTypes = boolean | number | string | undefined;

const ManageRegisterLessonModal = (props: Props): ReactElement | null => {
    const {
        hideModal,
        isUpdatingForWeek,
        lessonInfo,
        showModal,
        updateRegister,
        updateRegistersForWeek,
        userIsTeacher,
    } = props;

    const route = useRoute();
    // navigation is only used on the registers screen so only that nav prop is typed
    const navigation = useNavigation<RegistersNavigationProps>();

    const initialState = useMemo(() => {
        return {
            values: {
                status:
                    lessonInfo?.status &&
                    lessonInfo.status !== "BLANK" &&
                    !isUpdatingForWeek
                        ? lessonInfo.status
                        : "PRESENT",
                teacherRegisterNoteForStaff:
                    lessonInfo?.teacherRegisterNoteForStaff,
                staffRegisterNoteForTeacher:
                    lessonInfo?.staffRegisterNoteForTeacher,
            },
        };
    }, [
        isUpdatingForWeek,
        lessonInfo?.staffRegisterNoteForTeacher,
        lessonInfo?.status,
        lessonInfo?.teacherRegisterNoteForStaff,
    ]);

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [state, dispatchState] = useReducer(reducer, initialState);

    const teacherRegisterNoteForStaffInputRef = useRef<{
        focus: () => void;
        setValue: (text: string, isValid: boolean) => void;
    }>();
    const staffRegisterNoteForTeacherInputRef = useRef<{
        focus: () => void;
        setValue: (text: string, isValid: boolean) => void;
    }>();

    const lessonStatuses = useMemo(() => {
        return getLessonStatuses({
            iconProps: { ml: "4", mr: "-2" },
            blankText: "Unmark Register",
        });
    }, []);

    const intialRender = useRef(true);

    useEffect(() => {
        // don't run on first render
        if (intialRender.current) {
            intialRender.current = false;
            return;
        }
        // update the fields whenever the modal is opened
        dispatchState({
            input: "status",
            value:
                lessonInfo?.status && lessonInfo.status !== "BLANK"
                    ? lessonInfo.status
                    : "PRESENT",
        });
        dispatchState({
            input: "teacherRegisterNoteForStaff",
            value: lessonInfo?.teacherRegisterNoteForStaff,
        });
        dispatchState({
            input: "staffRegisterNoteForTeacher",
            value: lessonInfo?.staffRegisterNoteForTeacher,
        });
        teacherRegisterNoteForStaffInputRef.current?.setValue(
            lessonInfo?.teacherRegisterNoteForStaff ?? "",
            true,
        );
        staffRegisterNoteForTeacherInputRef.current?.setValue(
            lessonInfo?.staffRegisterNoteForTeacher ?? "",
            true,
        );
    }, [
        lessonInfo?.staffRegisterNoteForTeacher,
        lessonInfo?.status,
        lessonInfo?.teacherRegisterNoteForStaff,
        userIsTeacher,
    ]);

    const selectChangeHandler = useCallback(
        (inputIdentifier: string, itemValue: string) => {
            dispatchState({
                input: inputIdentifier,
                value: itemValue,
            });
        },
        [],
    );

    const inputChangeHandler = useCallback(
        (inputIdentifier?: string | number, inputValue?: string | number) => {
            dispatchState({
                input: inputIdentifier as string,
                value: inputValue,
            });
        },
        [],
    );

    const updateRegisterHandler = useCallback(() => {
        if (
            lessonInfo?.id &&
            typeof lessonInfo?.index === "number" &&
            state.values.status &&
            updateRegister
        ) {
            updateRegister(
                {
                    lessonId: lessonInfo.id,
                    status: state.values.status,
                    teacherRegisterNoteForStaff:
                        state.values.teacherRegisterNoteForStaff,
                    staffRegisterNoteForTeacher:
                        state.values.staffRegisterNoteForTeacher,
                },
                lessonInfo.index,
                lessonInfo.status === "BLANK" && state.values.status !== "BLANK"
                    ? 1
                    : state.values.status === "BLANK" &&
                        lessonInfo.status !== "BLANK"
                      ? -1
                      : 0,
            );
            hideModal();
        } else if (isUpdatingForWeek && updateRegistersForWeek) {
            updateRegistersForWeek({
                status: state.values.status,
            });
        }
    }, [
        lessonInfo,
        state.values.status,
        state.values.teacherRegisterNoteForStaff,
        state.values.staffRegisterNoteForTeacher,
        updateRegister,
        isUpdatingForWeek,
        updateRegistersForWeek,
        hideModal,
    ]);

    if (!lessonInfo) {
        return null;
    }

    return (
        <Modal isOpen={showModal} onClose={() => hideModal()} size="xl">
            <Modal.Content bg="primary.800">
                <Modal.CloseButton
                    _hover={{ bg: "transparent", opacity: 0.7 }}
                    _icon={{ color: "surface.100" }}
                    _pressed={{ bg: "transparent", opacity: 0.7 }}
                />
                <Modal.Header _text={{ color: "surface.100" }} bg="primary.800">
                    {route.name === "Registers"
                        ? "Manage Lesson"
                        : "Update Register"}
                </Modal.Header>
                <Modal.Body alignItems="center">
                    <VStack
                        alignItems="center"
                        justifyContent="center"
                        mt="-18"
                        pb="6"
                        space={6}
                    >
                        <Text
                            color="surface.100"
                            fontSize="md"
                            lineHeight="xl"
                            pb="6"
                            textAlign="center"
                        >
                            {isUpdatingForWeek
                                ? lessonInfo.scheduledTimestamp
                                    ? `Lessons on ${lessonInfo.scheduledTimestamp}`
                                    : ""
                                : `${lessonInfo.pupilName}  •  ${
                                      lessonInfo.instrument ??
                                      "No Assigned Instrument"
                                  }  •  ${
                                      lessonInfo.scheduledTimestamp ??
                                      "No Lesson Time Set"
                                  }${
                                      route.name === "Registers"
                                          ? `\n${
                                                lessonInfo?.homeworkId
                                                    ? "Homework assigned"
                                                    : "No homework assigned"
                                            }`
                                          : ""
                                  }`}
                        </Text>
                        {route.name === "Registers" ? (
                            <Button
                                _pressed={{ bg: "secondary.600" }}
                                colorScheme="secondary"
                                leftIcon={<HomeworkIcon size="5" />}
                                mb="3"
                                mt="-6"
                                onPress={() => {
                                    if (lessonInfo?.id) {
                                        hideModal();
                                        navigation.navigate("AssignHomework", {
                                            lessonId: lessonInfo?.id,
                                        });
                                    }
                                }}
                                pr="4"
                            >
                                {lessonInfo?.homeworkId
                                    ? "Update Homework"
                                    : "Assign Homework"}
                            </Button>
                        ) : null}
                        <Select
                            bg="surface.100"
                            color="surface.900"
                            fontSize="md"
                            onValueChange={(itemValue) => {
                                selectChangeHandler("status", itemValue);
                                if (
                                    itemValue === "MISSED" &&
                                    !isUpdatingForWeek
                                ) {
                                    teacherRegisterNoteForStaffInputRef.current?.focus();
                                }
                            }}
                            placeholder="Mark Register"
                            selectedValue={state.values.status}
                            textAlign="center"
                            width="250"
                        >
                            {lessonStatuses.map((item) => {
                                return (
                                    <Select.Item
                                        key={item.value}
                                        actionSheetLabel={item.label}
                                        startIcon={item.icon}
                                        value={item.value}
                                    />
                                );
                            })}
                        </Select>
                        {!isUpdatingForWeek ? (
                            <>
                                <Box>
                                    <TextInput
                                        ref={
                                            teacherRegisterNoteForStaffInputRef
                                        }
                                        blurOnSubmit
                                        centerLabel
                                        floatingErrorMessage={
                                            state.values.status === "MISSED" &&
                                            userIsTeacher
                                                ? "You need to let us know the reason for the missed lesson"
                                                : undefined
                                        }
                                        floatingErrorOffset={-2}
                                        fontFamily="Poppins-Regular"
                                        id="teacherRegisterNoteForStaff"
                                        initialValue={
                                            state.values
                                                .teacherRegisterNoteForStaff
                                        }
                                        keyboardType="default"
                                        label="Teacher Note"
                                        maxLength={750}
                                        multiline
                                        numberOfLines={4}
                                        onInputChange={inputChangeHandler}
                                        onLayoutOrMount={() =>
                                            // this makes the input show any exsiting notes
                                            lessonInfo?.teacherRegisterNoteForStaff
                                        }
                                        onSubmit={updateRegisterHandler}
                                        placeholder={
                                            userIsTeacher
                                                ? "Anything we should know about this lesson? This will only be visible to the admin staff."
                                                : "The teacher has not left a note."
                                        }
                                        required={
                                            state.values.status === "MISSED"
                                        }
                                        textAlign="center"
                                        textAlignVertical="top"
                                        width={
                                            Platform.OS !== "web"
                                                ? "50%"
                                                : undefined
                                        }
                                    />
                                </Box>
                                {!userIsTeacher ? (
                                    <Box>
                                        <TextInput
                                            ref={
                                                staffRegisterNoteForTeacherInputRef
                                            }
                                            blurOnSubmit
                                            centerLabel
                                            fontFamily="Poppins-Regular"
                                            id="staffRegisterNoteForTeacher"
                                            initialValue={
                                                state.values
                                                    .staffRegisterNoteForTeacher
                                            }
                                            keyboardType="default"
                                            label="Staff Note"
                                            maxLength={750}
                                            multiline
                                            numberOfLines={4}
                                            onInputChange={inputChangeHandler}
                                            onLayoutOrMount={() =>
                                                // this makes the input show any exsiting notes
                                                lessonInfo?.staffRegisterNoteForTeacher
                                            }
                                            onSubmit={updateRegisterHandler}
                                            placeholder={
                                                userIsTeacher
                                                    ? "Anything we should know about this lesson? This will only be visible to the admin staff."
                                                    : "Leave a note for the teacher. This will only be visible to the teacher and other admin staff."
                                            }
                                            textAlign="center"
                                            textAlignVertical="top"
                                            width={
                                                Platform.OS !== "web"
                                                    ? "50%"
                                                    : undefined
                                            }
                                        />
                                    </Box>
                                ) : null}
                            </>
                        ) : null}
                        <ButtonDebounced
                            _disabled={{ opacity: 0.8 }}
                            _text={{ fontSize: "xl" }}
                            isDisabled={
                                state.values.status === undefined ||
                                (state.values.status === "MISSED" &&
                                    state.values.teacherRegisterNoteForStaff ===
                                        "" &&
                                    userIsTeacher)
                            }
                            mb="4"
                            mt="4"
                            onPress={updateRegisterHandler}
                            px="10"
                        >
                            {"Update"}
                        </ButtonDebounced>
                    </VStack>
                </Modal.Body>
            </Modal.Content>
        </Modal>
    );
};

export default React.memo(ManageRegisterLessonModal);
