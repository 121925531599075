/**
 * @generated SignedSource<<3ce3461c0799af731da309c0033b6c47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LoadRegistersPaginationQuery$variables = {
  after?: string | null;
  block: number;
  first: number;
  searchTerm?: string | null;
  startingYear: number;
};
export type LoadRegistersPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LoadRegisters_query_profiles">;
};
export type LoadRegistersPaginationQuery = {
  response: LoadRegistersPaginationQuery$data;
  variables: LoadRegistersPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "block"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startingYear"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "block",
  "variableName": "block"
},
v3 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v4 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v5 = {
  "kind": "Variable",
  "name": "startingYear",
  "variableName": "startingYear"
},
v6 = [
  {
    "kind": "Literal",
    "name": "accountType",
    "value": "TEACHER"
  },
  (v1/*: any*/),
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "user__firstName,user__lastName"
  },
  (v4/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  (v2/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoadRegistersPaginationQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "LoadRegisters_query_profiles"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoadRegistersPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "ProfileNodeConnection",
        "kind": "LinkedField",
        "name": "profiles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": (v8/*: any*/),
                    "kind": "ScalarField",
                    "name": "totalLessonsMarked",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v8/*: any*/),
                    "kind": "ScalarField",
                    "name": "totalLessons",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DjangoUserNode",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "filters": [
          "accountType",
          "searchTerm",
          "orderBy"
        ],
        "handle": "connection",
        "key": "LoadRegisters_query_profiles",
        "kind": "LinkedHandle",
        "name": "profiles"
      }
    ]
  },
  "params": {
    "cacheID": "a90e6779e611d2d1b3c8b94f586379c0",
    "id": null,
    "metadata": {},
    "name": "LoadRegistersPaginationQuery",
    "operationKind": "query",
    "text": "query LoadRegistersPaginationQuery(\n  $after: String\n  $block: Int!\n  $first: Int!\n  $searchTerm: String\n  $startingYear: Int!\n) {\n  ...LoadRegisters_query_profiles_2O59Mw\n}\n\nfragment LoadRegisters_query_profiles_2O59Mw on Query {\n  profiles(accountType: \"TEACHER\", searchTerm: $searchTerm, orderBy: \"user__firstName,user__lastName\", first: $first, after: $after) {\n    edges {\n      node {\n        id\n        totalLessonsMarked(startingYear: $startingYear, block: $block)\n        totalLessons(startingYear: $startingYear, block: $block)\n        user {\n          firstName\n          lastName\n          email\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "25594ef06aca75d1e526b7c0714c1e92";

export default node;
