import { graphql } from "react-relay";

export const remove_lesson_blocks = graphql`
    mutation RemoveLessonBlocksMutation(
        $connections: [ID!]!
        $input: RemoveLessonBlocksInput!
        $startingYear: Int!
        $block: Int!
    ) {
        removeLessonBlocks(input: $input) {
            success
            errors
            deletedLessonBlockIds @deleteEdge(connections: $connections)
            teacher {
                id
                totalLessons(startingYear: $startingYear, block: $block)
            }
        }
    }
`;
