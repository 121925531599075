import { graphql } from "react-relay";

export const load_lessons = graphql`
    query LoadLessonsQuery(
        $profileId: ID!
        $startDate: String!
        $endDate: String
        $orderBy: String
        $skip: Boolean!
    ) {
        lessons(
            profileId: $profileId
            startDate: $startDate
            endDate: $endDate
            orderBy: $orderBy
        ) @skip(if: $skip) {
            edges {
                node {
                    id
                    scheduledTimestamp
                    rearrangedTimestamp
                    status
                    teacherRegisterNoteForStaff
                    staffRegisterNoteForTeacher
                    lessonDuration
                    lessonBlock {
                        id
                        startingYear
                        block
                        school {
                            id
                            name
                            mainOfficeContact {
                                id
                                addressFirstLine
                                addressSecondLine
                                city
                                postcode
                            }
                        }
                        pupil {
                            id
                            schoolYear
                            staffNoteForTeacher
                            teacherNoteForStaff
                            user {
                                firstName
                                lastName
                            }
                        }
                        instrument {
                            name
                        }
                        lessonStage {
                            id
                            stage
                            lessonType
                            lessonDuration
                        }
                    }
                }
            }
        }
    }
`;
