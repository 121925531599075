import { graphql } from "react-relay";

export const create_lesson_stage = graphql`
    mutation CreateLessonStageMutation(
        $connections: [ID!]!
        $input: CreateLessonStageInput!
    ) {
        createLessonStage(input: $input) {
            success
            errors
            lessonStage
                @prependNode(
                    connections: $connections
                    edgeTypeName: "LessonStageNodeEdge"
                ) {
                id
                stage
                lessonDuration
                lessonType
                costPerLesson
            }
        }
    }
`;
