/* eslint-disable no-case-declarations */
import React, {
    Suspense,
    useMemo,
    useReducer,
    useEffect,
    useCallback,
    useRef,
} from "react";
import type { FC, Dispatch } from "react";

import type { NavigationAction } from "@react-navigation/native";
import { format, endOfWeek, startOfWeek } from "date-fns";
import emoji from "emoji-dictionary";
import {
    Box,
    Text,
    VStack,
    Button,
    Pressable,
    Avatar,
    useToast,
    useDisclose,
    PresenceTransition,
    HStack,
    CloseIcon,
    Center,
    Modal,
    Checkbox,
} from "native-base";
import { useBeforeunload } from "react-beforeunload";
import { Platform } from "react-native";
import {
    DatePickerModal,
    DateTimePickerModal,
    DayTimePickerModal,
} from "react-native-paper-dates";
import { useQueryLoader, usePreloadedQuery, useMutation } from "react-relay";
import type { PreloadedQuery } from "react-relay";
import type { Subscription, RecordSourceSelectorProxy } from "relay-runtime";

import { useAuth } from "pianofunclub-shared/providers/AuthProvider";
import { useData } from "pianofunclub-shared/providers/DataProvider";

import AccountStatus from "../../components/Accounts/AccountStatus";
import DbsStatus from "../../components/Accounts/DbsStatus";
import SideBar from "../../components/Drawers/SideBar";
import InvoiceAndEmail from "../../components/Invoicing/InvoiceAndEmail";
import CreateAccountModal from "../../components/Modals/CreateAccountModal";
import CreateBreakBlockModal from "../../components/Modals/CreateBreakBlockModal";
import PupilRegisters from "../../components/Tabs/PupilRegisters";
import type {
    AccountsStackNavigatorProps,
    AccountsStackRouteProps,
} from "../../navigation/AccountsNavigator";
import type {
    InvoicingStackNavigatorProps,
    InvoicingStackRouteProps,
} from "../../navigation/InvoicingNavigator";
import type {
    RegistersStackNavigatorProps,
    RegistersStackRouteProps,
} from "../../navigation/RegistersNavigator";
import type {
    SchoolsStackNavigatorProps,
    SchoolsStackRouteProps,
} from "../../navigation/SchoolsNavigator";
import LoadingBlobs from "pianofunclub-shared/components/Animations/LoadingBlobs";
import ButtonDebounced from "pianofunclub-shared/components/Buttons/ButtonDebounced";
import TopTabBar from "pianofunclub-shared/components/Buttons/TopTabBar";
import PercentageInput from "pianofunclub-shared/components/Inputs/PercentageInput";
import TextInput from "pianofunclub-shared/components/Inputs/TextInput";
import ListEmptyBanner from "pianofunclub-shared/components/ListItems/ListEmptyBanner";
import type { TimetableLessonInfo } from "pianofunclub-shared/components/Modals/LessonDetailsModal";
import type { RegisterUpdate } from "pianofunclub-shared/components/Modals/ManageRegisterLessonModal";
import type { LessonInfo as RegisterLessonInfo } from "pianofunclub-shared/components/Modals/ManageRegisterLessonModal";
import PupilsToArchiveModal from "pianofunclub-shared/components/Modals/PupilsToArchiveModal";
import type { PupilsToArchiveModalInfo } from "pianofunclub-shared/components/Modals/PupilsToArchiveModal";
import UpdateLessonBlockDetailsModal from "pianofunclub-shared/components/Modals/UpdateLessonBlockDetailsModal";
import UpdateLessonBlockTeacherOrPupilModal from "pianofunclub-shared/components/Modals/UpdateLessonBlockTeacherOrPupilModal";
import Actionsheet from "pianofunclub-shared/components/NativeBaseExtended/Actionsheet";
import AlertPopup from "pianofunclub-shared/components/NativeBaseExtended/AlertPopup";
import Select from "pianofunclub-shared/components/NativeBaseExtended/Select";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import {
    ArchivedIcon,
    CompleteIcon,
    ProfileIcon,
    TickIcon,
    TrashIcon,
} from "pianofunclub-shared/components/Other/Icons";
import TeacherRegisters from "pianofunclub-shared/components/Registers/TeacherRegisters";
import type { RegisterProgress } from "pianofunclub-shared/components/Registers/TeacherRegisters";
import TeacherTimetable from "pianofunclub-shared/components/Registers/TeacherTimetable";

import type {
    EditProfileMutation,
    EditProfileMutation$data,
} from "pianofunclub-shared/relay/graphql/accounts/__generated__/EditProfileMutation.graphql";
import type { LoadAccountQuery } from "pianofunclub-shared/relay/graphql/accounts/__generated__/LoadAccountQuery.graphql";
import type {
    RemoveParentRelationshipMutation,
    RemoveParentRelationshipMutation$data,
} from "pianofunclub-shared/relay/graphql/accounts/__generated__/RemoveParentRelationshipMutation.graphql";
import type {
    UpdateAccountStatusMutation,
    UpdateAccountStatusMutation$data,
} from "pianofunclub-shared/relay/graphql/accounts/__generated__/UpdateAccountStatusMutation.graphql";
import { edit_profile } from "pianofunclub-shared/relay/graphql/accounts/EditProfile";
import { load_account } from "pianofunclub-shared/relay/graphql/accounts/LoadAccount";
import { remove_parent_relationship } from "pianofunclub-shared/relay/graphql/accounts/RemoveParentRelationship";
import { update_account_status } from "pianofunclub-shared/relay/graphql/accounts/UpdateAccountStatus";
import type { LoadInvoiceQuery } from "pianofunclub-shared/relay/graphql/invoicing/__generated__/LoadInvoiceQuery.graphql";
import { load_invoice } from "pianofunclub-shared/relay/graphql/invoicing/LoadInvoice";
import type {
    AddNewLessonBlockMutation,
    AddNewLessonBlockMutation$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/AddNewLessonBlockMutation.graphql";
import type { LoadLessonBlocksQuery } from "pianofunclub-shared/relay/graphql/registers/__generated__/LoadLessonBlocksQuery.graphql";
import type { LoadLessonsQuery } from "pianofunclub-shared/relay/graphql/registers/__generated__/LoadLessonsQuery.graphql";
import type {
    RemoveLessonBlocksMutation,
    RemoveLessonBlocksMutation$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/RemoveLessonBlocksMutation.graphql";
import type {
    RescheduleLessonMutation,
    RescheduleLessonMutation$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/RescheduleLessonMutation.graphql";
import type {
    UpdateLessonBlockDetailsMutation,
    UpdateLessonBlockDetailsMutation$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/UpdateLessonBlockDetailsMutation.graphql";
import type {
    UpdateRegistersMutation,
    UpdateRegistersMutation$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/UpdateRegistersMutation.graphql";
import type {
    UpdateScheduledLessonBlockTimeMutation,
    UpdateScheduledLessonBlockTimeMutation$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/UpdateScheduledLessonBlockTimeMutation.graphql";
import { add_new_lesson_block } from "pianofunclub-shared/relay/graphql/registers/AddNewLessonBlock";
import { load_lesson_blocks } from "pianofunclub-shared/relay/graphql/registers/LoadLessonBlocks";
import { load_lessons } from "pianofunclub-shared/relay/graphql/registers/LoadLessons";
import { remove_lesson_blocks } from "pianofunclub-shared/relay/graphql/registers/RemoveLessonBlocks";
import { reschedule_lesson } from "pianofunclub-shared/relay/graphql/registers/RescheduleLesson";
import { update_lesson_block_details } from "pianofunclub-shared/relay/graphql/registers/UpdateLessonBlockDetails";
import { update_registers } from "pianofunclub-shared/relay/graphql/registers/UpdateRegisters";
import { update_scheduled_lesson_block_time } from "pianofunclub-shared/relay/graphql/registers/UpdateScheduledLessonBlockTime";
import { lessonBlockConnectionUpdater } from "pianofunclub-shared/relay/updaters";

import type {
    AccountType,
    UpdateBlockDetailsModalInfo,
} from "pianofunclub-shared/types";
import { isNavigationAction } from "pianofunclub-shared/types/guards";
import {
    DISCOUNT_CATEGORIES,
    SCHOOL_YEARS,
} from "pianofunclub-shared/utils/constants";
import {
    titleCaseConverter,
    getInitials,
    schoolYearConverter,
} from "pianofunclub-shared/utils/converters";
import {
    getCurrentBlock,
    getFullName,
    getDefaultStartingYearAndStartingYearOptions,
} from "pianofunclub-shared/utils/extractors";
import { createReducer } from "pianofunclub-shared/utils/reducers";
import type { State, Action } from "pianofunclub-shared/utils/reducers";

export type NavigationProps = AccountsStackNavigatorProps<"Account"> &
    InvoicingStackNavigatorProps<"Account"> &
    SchoolsStackNavigatorProps<"Account"> &
    RegistersStackNavigatorProps<"Account">;

export type RouteProps = AccountsStackRouteProps<"Account"> &
    InvoicingStackRouteProps<"Account"> &
    SchoolsStackRouteProps<"Account"> &
    RegistersStackRouteProps<"Account">;

interface ScreenProps {
    navigation: NavigationProps;
    route: RouteProps;
}

interface ContentProps {
    accountType: AccountType;
    dispatchState: Dispatch<Action<ReducerValues, ReducerTypes>>;
    loadAccountQueryReference: PreloadedQuery<
        LoadAccountQuery,
        Record<string, unknown>
    >;
    navigation: NavigationProps;
    route: RouteProps;
    startingYears: {
        label: string;
        value: number;
    }[];
    state: State<ReducerValues>;
}

export type ReducerValues = {
    block: number;
    clickedAccountId?: string;
    contentIsRendered: boolean;
    currentPageIndex: number;
    dateTimePickerModalDate?: Date;
    dateTimePickerModalLessonDuration?: number;
    dateTimePickerModalLessonId?: string;
    dayIndex: number;
    dbsExpiryDatePickerIsOpen: boolean;
    deleteAccountAlertIsOpen: boolean;
    discountCategorySelect?: string;
    discountPercentage?: number;
    hasBook?: boolean;
    hasInstrument?: boolean;
    hasReturnedInstrument?: boolean;
    hours?: number;
    instrumentSuppliedByUs?: boolean;
    invoiceId?: string;
    isAddLessonBreakModal?: boolean;
    isRefetching: boolean;
    leaveAlertAction?: NavigationAction | (() => void);
    lessonBlockActionsheetId?: string;
    lessonBlocksConnectionId?: string;
    minutes?: number;
    paidBySchoolSelect?: boolean;
    pupilsToArchiveModalInfo?: PupilsToArchiveModalInfo;
    refreshHandler?: () => void;
    registerUpdater: number;
    removeParentRelationshipAlertIsOpen: boolean;
    school?: string;
    // don't handle state for most text inputs (mutation is fired on blur)
    // do for the selects so that they respond immeditately
    schoolSelect?: string;
    schoolYearSelect?: string;
    searchTerm: string;
    selectSchoolModalIsOpen: boolean;
    selectedSchoolOnModal?: string;
    showAddBreakBlockModal?: boolean;
    showAddLessonBlockModal: boolean;
    showCreateAccountModal: boolean;
    sideBarIsCollapsed: boolean;
    startingYear: number;
    subscription?: Subscription;
    teachingDays?: readonly (number | null)[] | undefined;
    timetableDate: Date;
    timetableDatePickerIsOpen: boolean;
    timetableLessonModalInfo?: TimetableLessonInfo;
    timetableView: "DAY" | "WEEK";
    updateBlockDetailsModalInfo?: UpdateBlockDetailsModalInfo;
};

export type ReducerTypes =
    | string
    | number
    | boolean
    | Subscription
    | Date
    | PupilsToArchiveModalInfo
    | UpdateBlockDetailsModalInfo
    | TimetableLessonInfo
    | NavigationAction
    | RegisterLessonInfo
    | (() => void)
    | readonly (number | null)[]
    | undefined;

export const LOAD_X_LESSON_BLOCKS = Platform.OS === "web" ? 20 : 10;
const SIDE_BAR_EXPANDED_WIDTH = 400;
const SIDE_BAR_COLLAPSED_WIDTH = 12;

const AccountContent: FC<ContentProps> = (props) => {
    const {
        accountType,
        dispatchState,
        loadAccountQueryReference,
        navigation,
        route,
        startingYears,
        state,
    } = props;

    const accountData = usePreloadedQuery(
        load_account,
        loadAccountQueryReference,
    );

    useEffect(() => {
        if (accountData.profile?.id) {
            // initialise local state
            // day index defaults to current day, but adjust if teacher
            // doesn't teach on current day
            dispatchState({
                input: "dayIndex",
                value:
                    accountType === "TEACHER" &&
                    accountData.profile.teachingDays &&
                    accountData.profile.teachingDays.length > 0 &&
                    !accountData.profile.teachingDays.includes(
                        state.values.dayIndex,
                    )
                        ? (accountData.profile.teachingDays[0] ??
                          state.values.dayIndex)
                        : state.values.dayIndex,
            });
            dispatchState({
                input: "teachingDays",
                value: accountData.profile.teachingDays ?? undefined,
            });
            dispatchState({
                input: "schoolSelect",
                value: accountData.profile.school?.name,
            });
            dispatchState({
                input: "schoolYearSelect",
                value: String(accountData.profile.schoolYear ?? undefined),
            });
            dispatchState({
                input: "paidBySchoolSelect",
                value:
                    accountData.profile.paidBySchool !== null
                        ? accountData.profile.paidBySchool
                        : false,
            });
            dispatchState({
                input: "discountCategorySelect",
                value: String(
                    accountData.profile.discountCategory ?? "FULL_PRICE",
                ),
            });
            dispatchState({
                input: "discountPercentage",
                value: accountData.profile.discountPercentage ?? undefined,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountData.profile, accountType]);

    const childrenConnections = useMemo(() => {
        return accountData.profile?.children.__id;
    }, [accountData.profile?.children.__id]);

    const [loadLessonBlocksQueryReference, loadLessonBlocksQuery] =
        useQueryLoader<LoadLessonBlocksQuery>(load_lesson_blocks);

    useEffect(() => {
        if (!state.values.contentIsRendered) {
            loadLessonBlocksQuery(
                {
                    first: LOAD_X_LESSON_BLOCKS,
                    profileId: route.params.profileId,
                    searchTerm: state.values.searchTerm,
                    startingYear: state.values.startingYear,
                    block:
                        accountType === "TEACHER"
                            ? state.values.block
                            : undefined,
                    orderBy:
                        accountType === "TEACHER"
                            ? undefined
                            : "-block,lessonDay,lessonTime",
                    school: state.values.school,
                    dayIndex: state.values.dayIndex,
                    skipLessonBlocks:
                        accountType !== "TEACHER" && accountType !== "PUPIL",
                    skipRegisterProgress: accountType !== "TEACHER",
                    skipLessonStages: true,
                },
                { fetchPolicy: "store-or-network" },
            );
        }
    }, [
        accountType,
        loadLessonBlocksQuery,
        route.params.profileId,
        state.values.block,
        state.values.contentIsRendered,
        state.values.dayIndex,
        state.values.school,
        state.values.searchTerm,
        state.values.startingYear,
    ]);

    const [loadLessonsQueryReference, loadLessonsQuery] =
        useQueryLoader<LoadLessonsQuery>(load_lessons);

    useEffect(() => {
        if (!state.values.contentIsRendered) {
            let startDate = state.values.timetableDate;
            let endDate;
            if (state.values.timetableView === "WEEK") {
                startDate = startOfWeek(startDate, { weekStartsOn: 1 });
                endDate = endOfWeek(startDate, { weekStartsOn: 1 });
            }

            loadLessonsQuery(
                {
                    profileId: route.params.profileId,
                    startDate: startDate.toString(),
                    endDate: endDate?.toString(),
                    orderBy: "rearrangedTimestamp,scheduledTimestamp",
                    skip: accountType !== "TEACHER",
                },
                { fetchPolicy: "store-or-network" },
            );
        }
    }, [
        accountType,
        loadLessonsQuery,
        route.params.profileId,
        state.values.contentIsRendered,
        state.values.timetableDate,
        state.values.timetableView,
    ]);

    const [loadInvoiceQueryReference, loadInvoiceQuery] =
        useQueryLoader<LoadInvoiceQuery>(load_invoice);

    useEffect(() => {
        if (!state.values.contentIsRendered) {
            loadInvoiceQuery(
                {
                    profileId: route.params.profileId,
                    startingYear: state.values.startingYear,
                    block: state.values.block,
                    skip: accountType !== "PARENT",
                },
                { fetchPolicy: "store-or-network" },
            );
        }
    }, [
        accountType,
        loadInvoiceQuery,
        route.params.profileId,
        state.values.block,
        state.values.contentIsRendered,
        state.values.startingYear,
    ]);

    const { dataState } = useData();

    const allSchools = useMemo(() => {
        return dataState.values.schools?.edges ?? [];
    }, [dataState.values.schools?.edges]);

    const teacherSchools = useMemo(() => {
        return accountData?.profile?.schools;
    }, [accountData?.profile?.schools]);

    const pages = useMemo(() => {
        switch (accountType) {
            case "PARENT":
                return ["Invoices", "Notes"];
            case "PUPIL":
                return ["Registers", "Homework"];
            case "TEACHER":
                return ["Registers", "Timetable"];
            default:
                return ["Activity", "Notes"];
        }
    }, [accountType]);

    const safeLeaveRef = useRef(null);
    const safeRemoveRelationshipRef = useRef(null);
    const safeDeleteAccountRef = useRef(null);
    // use refs rather than state to track these, so that updates work properly
    // when run from nested children
    const registerUpdates = useRef<RegisterUpdate[]>([]);
    const registerProgressCounter = useRef<RegisterProgress>({
        marked: undefined,
        total: undefined,
    });

    // if unsaved updates have been made to registers, show an alert
    useEffect(() => {
        const unsubscribe = navigation.addListener("beforeRemove", (e) => {
            if (registerUpdates.current.length === 0) {
                return;
            }

            e.preventDefault();
            dispatchState({
                input: "leaveAlertAction",
                value: e.data.action,
            });
        });

        return () => unsubscribe();
    }, [dispatchState, navigation, registerUpdates.current.length]);

    // trigger a popup if the CRM is unloaded
    useBeforeunload((e) => {
        if (registerUpdates.current.length === 0) {
            return;
        }

        e.preventDefault();
    });

    const toast = useToast();

    const [commitUpdateRegisters, updateRegistersInFlight] =
        useMutation<UpdateRegistersMutation>(update_registers);

    const updateRegisters = useCallback(
        (
            variables: {
                lessonId: string;
                registerNote?: string;
                status: string;
            }[],
            onComplete?: () => void,
        ) => {
            const updateRegistersConfig = {
                variables: {
                    input: { updates: variables },
                },
                onCompleted: (response: UpdateRegistersMutation$data) => {
                    if (response.updateRegisters?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={"Register updated"}
                                    toast={toast}
                                />
                            ),
                        });
                        onComplete?.();
                        // delay to next render cycle for clean exit animation of save button
                        setTimeout(() => {
                            registerUpdates.current = [];
                            dispatchState({
                                input: "registerUpdater",
                                value: Math.random(),
                            });
                        }, 0);
                        if (response.updateRegisters?.pupilsToArchive?.length) {
                            dispatchState({
                                input: "pupilsToArchiveModalInfo",
                                value: {
                                    ids: response.updateRegisters.pupilsToArchive
                                        .map((pupil) => pupil?.id)
                                        .filter(Boolean) as string[],
                                    pupilNames:
                                        response.updateRegisters.pupilsToArchive.map(
                                            (pupil) =>
                                                getFullName(
                                                    pupil?.user?.firstName,
                                                    pupil?.user?.lastName,
                                                ),
                                        ),
                                },
                            });
                        }
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't update register"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitUpdateRegisters(updateRegistersConfig);
        },
        [commitUpdateRegisters, dispatchState, toast],
    );

    const commitRescheduleLesson =
        useMutation<RescheduleLessonMutation>(reschedule_lesson)[0];

    const rescheduleLesson = useCallback(
        (
            lessonId: string,
            rearrangedDatetime: Date,
            lessonDuration?: number,
        ) => {
            const rescheduleLessonConfig = {
                variables: {
                    input: {
                        lessonId: lessonId,
                        rearrangedDatetime: String(rearrangedDatetime),
                        lessonDuration: lessonDuration,
                    },
                },
                optimsticResponse: {
                    rescheduleLesson: {
                        success: true,
                        errors: null,
                        lesson: {
                            id: lessonId,
                            rearrangedTimestamp: rearrangedDatetime,
                        },
                    },
                },
                onCompleted: (response: RescheduleLessonMutation$data) => {
                    if (response?.rescheduleLesson?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={"Rescheduled lesson"}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't reschedule lesson"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitRescheduleLesson(rescheduleLessonConfig);
        },
        [commitRescheduleLesson, toast],
    );

    const [commitUpdateLessonBlockDetails, updateLessonBlockDetailsInFlight] =
        useMutation<UpdateLessonBlockDetailsMutation>(
            update_lesson_block_details,
        );

    const updateLessonBlockDetails = useCallback(
        (
            variables: {
                archiveBlock?: boolean;
                instrument?: string | null;
                keepFree?: boolean;
                lessonBlockIds: string[];
                pupilId?: string | null;
                staffNoteForTeacher?: string;
                stageId?: string | null;
                teacherId?: string | null;
                teacherNoteForStaff?: string;
            },
            lessonBlockCursor?: string,
            onComplete?: () => void,
        ) => {
            const updateLessonBlockDetailsConfig = {
                variables: {
                    connections: state.values.lessonBlocksConnectionId
                        ? [state.values.lessonBlocksConnectionId]
                        : [],
                    input: variables,
                    startingYear: state.values.startingYear,
                    block: state.values.block,
                },
                updater: (store: RecordSourceSelectorProxy) =>
                    lessonBlockConnectionUpdater(
                        store,
                        state.values.lessonBlocksConnectionId,
                        lessonBlockCursor,
                    ),
                onCompleted: (
                    response: UpdateLessonBlockDetailsMutation$data,
                ) => {
                    if (response?.updateLessonBlockDetails?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={`Updated lesson block${
                                        variables.lessonBlockIds.length > 1
                                            ? "s"
                                            : ""
                                    }`}
                                    toast={toast}
                                />
                            ),
                        });
                        onComplete?.();
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={`Couldn't update lesson block${
                                        variables.lessonBlockIds.length > 1
                                            ? "s"
                                            : ""
                                    }`}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitUpdateLessonBlockDetails(updateLessonBlockDetailsConfig);
        },
        [
            commitUpdateLessonBlockDetails,
            state.values.block,
            state.values.lessonBlocksConnectionId,
            state.values.startingYear,
            toast,
        ],
    );

    const [
        commitUpdateScheduledLessonBlockTime,
        updateScheduledLessonBlockTimeInFlight,
    ] = useMutation<UpdateScheduledLessonBlockTimeMutation>(
        update_scheduled_lesson_block_time,
    );

    const updateScheduledLessonBlockTime = useCallback(
        (
            lessonBlockIds: string[],
            dayIndex: number,
            hours?: number,
            minutes?: number,
            onComplete?: () => void,
            overrideRescheduledLessons?: boolean,
        ) => {
            const updateScheduledLessonBlockTimeConfig = {
                variables: {
                    input: {
                        lessonBlockIds,
                        dayIndex,
                        hours,
                        minutes,
                        overrideRescheduledLessons,
                    },
                },
                onCompleted: (
                    response: UpdateScheduledLessonBlockTimeMutation$data,
                ) => {
                    if (response?.updateScheduledLessonBlockTime?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={`Rescheduled lesson block slot${
                                        lessonBlockIds.length > 1 ? "s" : ""
                                    }`}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={`Couldn't reschedule lesson block slot${
                                        lessonBlockIds.length > 1 ? "s" : ""
                                    }`}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                    onComplete?.();
                },
            };

            commitUpdateScheduledLessonBlockTime(
                updateScheduledLessonBlockTimeConfig,
            );
        },
        [commitUpdateScheduledLessonBlockTime, toast],
    );

    const [commitRemoveLessonBlocks, removeLessonBlocksInFlight] =
        useMutation<RemoveLessonBlocksMutation>(remove_lesson_blocks);

    const removeLessonBlocks = useCallback(
        (
            lessonBlockIds: string[],
            connectionId?: string,
            onComplete?: () => void,
        ) => {
            const removeLessonBlocksConfig = {
                variables: {
                    connections: connectionId ? [connectionId] : [],
                    input: {
                        lessonBlockIds: lessonBlockIds,
                        isTeacherRegister: true,
                    },
                    startingYear: state.values.startingYear,
                    block: state.values.block,
                },
                onCompleted: (response: RemoveLessonBlocksMutation$data) => {
                    if (response?.removeLessonBlocks?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="error"
                                    title={`Deleted lesson block${
                                        lessonBlockIds.length > 1 ? "s" : ""
                                    }`}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={`Couldn't delete lesson block${
                                        lessonBlockIds.length > 1 ? "s" : ""
                                    }`}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                    onComplete?.();
                },
            };

            commitRemoveLessonBlocks(removeLessonBlocksConfig);
        },
        [
            commitRemoveLessonBlocks,
            state.values.block,
            state.values.startingYear,
            toast,
        ],
    );

    const [commitAddNewLessonBlock, addNewLessonBlockInFlight] =
        useMutation<AddNewLessonBlockMutation>(add_new_lesson_block);

    const addNewLessonBlock = useCallback(
        (variables: {
            block: number;
            dayIndex: number;
            durationMinutes?: number;
            hours: number;
            minutes: number;
            school: string;
            startingYear: number;
            type: string;
        }) => {
            const addNewLessonBlockConfig = {
                variables: {
                    connections: state.values.lessonBlocksConnectionId
                        ? [state.values.lessonBlocksConnectionId]
                        : [],
                    input: {
                        teacherId: route.params.profileId,
                        ...variables,
                    },
                },
                onCompleted: (response: AddNewLessonBlockMutation$data) => {
                    dispatchState({
                        input: "showAddLessonBlockModal",
                        value: false,
                    });
                    if (response?.addNewLessonBlock?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={"Added new lesson block"}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't add new lesson block"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitAddNewLessonBlock(addNewLessonBlockConfig);
        },
        [
            commitAddNewLessonBlock,
            dispatchState,
            route.params.profileId,
            state.values.lessonBlocksConnectionId,
            toast,
        ],
    );

    const commitEditProfile = useMutation<EditProfileMutation>(edit_profile)[0];

    const editProfile = useCallback(
        (variables: {
            addressFirstLine?: string;
            addressSecondLine?: string;
            city?: string;
            dbsExpirationDate?: string;
            dbsNumber?: string;
            discountCategory?: string;
            discountPercentage?: number;
            email?: string;
            firstName?: string;
            hasBook?: boolean;
            hasInstrument?: boolean;
            instrumentSuppliedByUs?: boolean;
            lastName?: string;
            paidBySchool?: boolean;
            phoneNumber?: string;
            postcode?: string;
            profileId: string;
            school?: string;
            schoolClass?: string;
            schoolYear?: number;
            staffNoteForTeacher?: string;
        }) => {
            const editProfileConfig = {
                variables: {
                    input: variables,
                },
                onCompleted: (response: EditProfileMutation$data) => {
                    if (response?.editProfile?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={"Profile updated"}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't update profile"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitEditProfile(editProfileConfig);
        },
        [commitEditProfile, toast],
    );

    const [commitRemoveParentRelationship, removeParentRelationshipInFlight] =
        useMutation<RemoveParentRelationshipMutation>(
            remove_parent_relationship,
        );

    const removeParentRelationship = useCallback(
        (variables: { pupilId: string }) => {
            const removeParentRelationshipConfig = {
                variables: {
                    connections:
                        childrenConnections &&
                        route.params.accountType === "PARENT"
                            ? [childrenConnections]
                            : route.params.previousChilrenConnectionId
                              ? [route.params.previousChilrenConnectionId]
                              : [],
                    input: variables,
                },
                onCompleted: (
                    response: RemoveParentRelationshipMutation$data,
                ) => {
                    if (response?.removeParentRelationship?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={"Parent/child relationship removed"}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={
                                        "Couldn't remove parent/child relationship"
                                    }
                                    toast={toast}
                                />
                            ),
                        });
                    }
                    dispatchState({
                        input: "clickedAccountId",
                        value: undefined,
                    });
                },
            };

            commitRemoveParentRelationship(removeParentRelationshipConfig);
        },
        [
            childrenConnections,
            commitRemoveParentRelationship,
            dispatchState,
            route.params.accountType,
            route.params.previousChilrenConnectionId,
            toast,
        ],
    );

    const [commitUpdateAccountStatus, updateAccountStatusInFlight] =
        useMutation<UpdateAccountStatusMutation>(update_account_status);

    const updateAccountStatus = useCallback(
        (variables: { status: string }) => {
            const connections = route.params.accountsHubConnectionIds ?? [];
            if (
                route.params.accountType === "PUPIL" &&
                route.params.previousChilrenConnectionId
            ) {
                connections.push(route.params.previousChilrenConnectionId);
            }
            const updateAccountStatusConfig = {
                variables: {
                    connections: connections,
                    input: { profileId: route.params.profileId, ...variables },
                },
                updater: (store: RecordSourceSelectorProxy) => {
                    // invalidate record in store so that queries for register action modals are refetched
                    // (archived teachers are removed from those modals)
                    if (route.params.accountType === "TEACHER") {
                        const profileRecord = store.get(route.params.profileId);
                        if (profileRecord) {
                            profileRecord.invalidateRecord();
                        }
                    }
                },
                onCompleted: (response: UpdateAccountStatusMutation$data) => {
                    if (response?.updateAccountStatus?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status={
                                        variables.status === "DELETE"
                                            ? "error"
                                            : "success"
                                    }
                                    title={
                                        variables.status === "ACTIVE"
                                            ? "Made account active"
                                            : variables.status === "ARCHIVED"
                                              ? "Archived account"
                                              : "Account deleted"
                                    }
                                    toast={toast}
                                />
                            ),
                        });
                        if (variables.status === "DELETE") {
                            navigation.popToTop();
                        }
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't update account status"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitUpdateAccountStatus(updateAccountStatusConfig);
        },
        [
            commitUpdateAccountStatus,
            navigation,
            route.params.accountType,
            route.params.accountsHubConnectionIds,
            route.params.previousChilrenConnectionId,
            route.params.profileId,
            toast,
        ],
    );

    const inputFinishEditingHandler = useCallback(
        (
            inputIdentifier?: string | number,
            inputValue?: string,
            isValid?: boolean,
        ) => {
            if (
                isValid &&
                inputIdentifier &&
                typeof inputValue !== "undefined" &&
                // check if the value has changed before firing mutation
                // @ts-expect-error string indexing
                inputValue !== accountData.profile?.[inputIdentifier] &&
                // @ts-expect-error string indexing
                inputValue !== accountData.profile.user?.[inputIdentifier]
            ) {
                switch (inputIdentifier) {
                    case "discountPercentage":
                        const parsedDiscount = parseInt(inputValue);
                        dispatchState({
                            input: "discountPercentage",
                            value: parsedDiscount,
                        });
                        if (parsedDiscount == 0) {
                            dispatchState({
                                input: "discountCategorySelect",
                                value: "FULL_PRICE",
                            });
                        }
                        editProfile({
                            profileId: route.params.profileId,
                            discountPercentage: parsedDiscount,
                        });
                        return;
                    default:
                        editProfile({
                            profileId: route.params.profileId,
                            [inputIdentifier]: inputValue?.trim(),
                        });
                }
            }
        },
        [
            accountData.profile,
            dispatchState,
            editProfile,
            route.params.profileId,
        ],
    );

    const discountPercentageInputRef = useRef<{
        setValue: (text: string, isValid: boolean) => void;
    }>(null);

    const selectChangeHandler = useCallback(
        (inputIdentifier: string, itemValue: string | boolean) => {
            if (
                state.values?.[
                    `${inputIdentifier}Select` as keyof ReducerValues
                ] != itemValue
            ) {
                dispatchState({
                    input: `${inputIdentifier}Select`,
                    value: itemValue,
                });
                if (
                    inputIdentifier === "discountCategory" &&
                    typeof itemValue === "string"
                ) {
                    if (itemValue.includes("DISCOUNT")) {
                        dispatchState({
                            input: "discountPercentage",
                            value: 100,
                        });
                        discountPercentageInputRef.current?.setValue(
                            "100%",
                            true,
                        );
                    } else {
                        dispatchState({
                            input: "discountPercentage",
                            value: 0,
                        });
                        discountPercentageInputRef.current?.setValue(
                            "0%",
                            true,
                        );
                    }
                }
                editProfile({
                    profileId: route.params.profileId,
                    [inputIdentifier]: itemValue,
                });
            }
        },
        [state.values, dispatchState, editProfile, route.params.profileId],
    );

    const showUpdateBlockDetailsModalHandler = useCallback(
        (variables: {
            keepFree?: boolean;
            lessonBlockCursor?: string;
            lessonBlockIds: string[];
            onUpdate?: () => void;
            school?: string;
            selectedProfileFullName?: string;
            selectedProfileId?: string;
            staffNoteForTeacher?: string;
            teacherNoteForStaff?: string;
            type: string;
        }) => {
            dispatchState({
                input: "updateBlockDetailsModalInfo",
                value: {
                    ids: variables.lessonBlockIds,
                    type: variables.type as
                        | "INSTRUMENT"
                        | "STAGE"
                        | "PUPIL"
                        | "TEACHER"
                        | "NOTES"
                        | "TEACHER_AND_DAY",
                    school: variables.school,
                    keepFree: variables.keepFree,
                    selectedProfileId: variables.selectedProfileId,
                    selectedProfileFullName: variables.selectedProfileFullName,
                    teacherNoteForStaff: variables.teacherNoteForStaff,
                    staffNoteForTeacher: variables.staffNoteForTeacher,
                    cursor: variables.lessonBlockCursor,
                    onUpdate: variables.onUpdate,
                },
            });
        },
        [dispatchState],
    );

    const updateRegisterStateHandler = useCallback(
        (
            variables: {
                lessonId: string;
                registerNote?: string;
                status: string;
            },
            progressChange?: number,
        ) => {
            const existingUpdateIndex = registerUpdates.current.findIndex(
                (item) => item.lessonId === variables.lessonId,
            );
            if (existingUpdateIndex === -1) {
                registerUpdates.current.push(variables);
            } else {
                registerUpdates.current.splice(
                    existingUpdateIndex,
                    1,
                    variables,
                );
            }
            // update the progress bar
            if (
                progressChange &&
                typeof registerProgressCounter.current.marked !== "undefined"
            ) {
                registerProgressCounter.current.marked += progressChange;
            }
            // this is a key used to force a rerender when these refs change (refs do not cause re-render)
            dispatchState({ input: "registerUpdater", value: Math.random() });
        },
        [dispatchState],
    );

    const {
        isOpen: optionsIsOpen,
        onClose: optionsOnClose,
        onOpen: optionsOnOpen,
    } = useDisclose();
    const {
        isOpen: changeStatusActionsheetIsOpen,
        onClose: changeStatusActionsheetOnClose,
        onOpen: changeStatusActionsheetOnOpen,
    } = useDisclose();

    const openLessonBlockOptionsHandler = useCallback(
        (lessonBlockId: string) => {
            dispatchState({
                input: "lessonBlockActionsheetId",
                value: lessonBlockId,
            });
            optionsOnOpen();
        },
        [dispatchState, optionsOnOpen],
    );

    const addressSecondLineInputRef = useRef<{ focus: () => void }>(null);
    const cityInputRef = useRef<{ focus: () => void }>(null);
    const postcodeInputRef = useRef<{ focus: () => void }>(null);
    const dbsExpirationDateRef = useRef<{
        setValue: (text: string, isValid: boolean) => void;
    }>(null);

    const renderSideBar = useMemo(() => {
        return (
            <SideBar
                isCollapsed={state.values.sideBarIsCollapsed}
                navigation={navigation}
                setIsCollapsed={() =>
                    dispatchState({
                        input: "sideBarIsCollapsed",
                        value: !state.values.sideBarIsCollapsed,
                    })
                }
                width={
                    state.values.sideBarIsCollapsed
                        ? SIDE_BAR_COLLAPSED_WIDTH
                        : SIDE_BAR_EXPANDED_WIDTH
                }>
                <VStack
                    alignItems="center"
                    mt="20"
                    pb="6"
                    space="4"
                    width="100%">
                    <VStack alignItems="center">
                        <Avatar
                            _text={{ color: "surface.200" }}
                            bg="primary.600"
                            size="md"
                            source={{
                                uri: undefined,
                            }}>
                            {getInitials(
                                accountData.profile?.user.firstName,
                                accountData.profile?.user.lastName,
                            )}
                        </Avatar>
                        <Text fontSize="xl" mt="2" textAlign="center">
                            {getFullName(
                                accountData.profile?.user.firstName,
                                accountData.profile?.user.lastName,
                            )}
                        </Text>
                        <Text fontSize="lg">
                            {titleCaseConverter(accountType)}
                        </Text>
                    </VStack>
                    {accountType !== "PUPIL" &&
                    (accountData.profile?.accountNumber ||
                        accountData.profile?.user.email ||
                        accountData.profile?.phoneNumber) ? (
                        <VStack alignItems="center">
                            {accountData.profile?.accountNumber ? (
                                <Text fontSize="md">
                                    Account #{accountData.profile.accountNumber}
                                </Text>
                            ) : null}
                            {accountData.profile?.user.email ? (
                                <Text fontSize="md">
                                    {accountData.profile.user.email}
                                </Text>
                            ) : null}
                            {accountData.profile?.phoneNumber ? (
                                <Text fontSize="md">
                                    {accountData.profile.phoneNumber}
                                </Text>
                            ) : null}
                        </VStack>
                    ) : accountData.profile?.school?.name ||
                      accountData.profile?.instruments ? (
                        <VStack alignItems="center">
                            {accountData.profile?.school?.name ? (
                                <Pressable
                                    onPress={() => {
                                        if (accountData.profile?.school?.id) {
                                            navigation.push("School", {
                                                schoolId:
                                                    accountData.profile.school
                                                        .id,
                                                startingYear:
                                                    state.values.startingYear,
                                            });
                                        }
                                    }}>
                                    <Text fontSize="md" textAlign="center">
                                        {titleCaseConverter(
                                            accountData.profile.school.name,
                                        )}
                                    </Text>
                                </Pressable>
                            ) : null}
                            {accountData.profile?.instruments ||
                            accountData.profile?.schoolYear ? (
                                <Text fontSize="md">
                                    {accountData.profile?.schoolYear != null
                                        ? schoolYearConverter(
                                              accountData.profile.schoolYear,
                                          ) +
                                          (accountData.profile?.instruments !=
                                              null &&
                                          accountData.profile?.instruments
                                              .length > 0
                                              ? " • "
                                              : "")
                                        : ""}
                                    {accountData.profile?.instruments != null
                                        ? titleCaseConverter(
                                              accountData.profile?.instruments
                                                  .toString()
                                                  .replace(",", ", "),
                                          )
                                        : ""}
                                </Text>
                            ) : null}
                        </VStack>
                    ) : null}
                    <Pressable
                        alignItems="center"
                        onPress={() => changeStatusActionsheetOnOpen()}>
                        <AccountStatus
                            isLoading={updateAccountStatusInFlight}
                            mt="2"
                            showEditArrow
                            status={accountData.profile?.status}
                        />
                    </Pressable>
                    {accountType === "TEACHER" ? (
                        <DbsStatus
                            dbsExpirationDate={
                                accountData.profile?.dbsExpirationDate
                            }
                            dbsNumber={accountData.profile?.dbsNumber}
                            showLongText
                        />
                    ) : null}
                    <VStack alignItems="center" space="2" width="100%">
                        {accountType === "PARENT" ? (
                            <VStack alignItems="center" mb="2" space="1">
                                {(accountData.profile?.children.edges?.length ??
                                    0) > 0 ? (
                                    <Text color="surface.700">{`${
                                        (accountData.profile?.children.edges
                                            ?.length ?? 2) > 1
                                            ? "Children"
                                            : "Child"
                                    }`}</Text>
                                ) : null}
                                {accountData.profile?.children.edges?.map(
                                    (item) => {
                                        if (item?.node) {
                                            return (
                                                <HStack key={item.node.id}>
                                                    <Pressable
                                                        _hover={{
                                                            bg: "surface.400",
                                                        }}
                                                        _pressed={{
                                                            bg: "surface.500",
                                                        }}
                                                        borderRadius="2xl"
                                                        onPress={() => {
                                                            if (item.node?.id) {
                                                                navigation.push(
                                                                    "Account",
                                                                    {
                                                                        profileId:
                                                                            item
                                                                                .node
                                                                                .id,
                                                                        accountType:
                                                                            "PUPIL",
                                                                        previousChilrenConnectionId:
                                                                            childrenConnections,
                                                                        startingYear:
                                                                            state
                                                                                .values
                                                                                .startingYear,
                                                                        block: state
                                                                            .values
                                                                            .block,
                                                                    },
                                                                );
                                                            }
                                                        }}
                                                        px="2">
                                                        <Text
                                                            fontSize="md"
                                                            fontWeight="600"
                                                            textAlign="center"
                                                            textDecorationLine="underline">
                                                            {getFullName(
                                                                item?.node?.user
                                                                    .firstName,
                                                                item?.node?.user
                                                                    .lastName,
                                                            )}
                                                        </Text>
                                                    </Pressable>
                                                    <Button
                                                        _hover={{
                                                            bg: "transparent",
                                                            opacity: 0.8,
                                                        }}
                                                        _pressed={{
                                                            bg: "transparent",
                                                            opacity: 0.7,
                                                        }}
                                                        _spinner={{
                                                            color: "surface.700",
                                                        }}
                                                        isLoading={
                                                            removeParentRelationshipInFlight &&
                                                            state.values
                                                                .clickedAccountId ===
                                                                item.node.id
                                                        }
                                                        leftIcon={
                                                            <CloseIcon
                                                                color="surface.700"
                                                                size="4"
                                                            />
                                                        }
                                                        onPress={() => {
                                                            if (item.node?.id) {
                                                                dispatchState({
                                                                    input: "clickedAccountId",
                                                                    value: item
                                                                        .node
                                                                        .id,
                                                                });
                                                                dispatchState({
                                                                    input: "removeParentRelationshipAlertIsOpen",
                                                                    value: true,
                                                                });
                                                            }
                                                        }}
                                                        p="1"
                                                        position="absolute"
                                                        right="-22"
                                                        variant="ghost"
                                                    />
                                                </HStack>
                                            );
                                        } else {
                                            return null;
                                        }
                                    },
                                )}
                                <Button
                                    bg="primary.400"
                                    leftIcon={<ProfileIcon size="5" />}
                                    mt="2"
                                    onPress={() => {
                                        dispatchState({
                                            input: "showCreateAccountModal",
                                            value: true,
                                        });
                                    }}
                                    shadow={1}>
                                    Add Child
                                </Button>
                            </VStack>
                        ) : accountType === "PUPIL" ? (
                            <VStack alignItems="center" mb="2" space="3">
                                <VStack alignItems="center" space="1">
                                    <Text color="surface.700">Parent</Text>
                                    {accountData.profile?.primaryParent ? (
                                        <HStack>
                                            <Pressable
                                                _hover={{ bg: "surface.400" }}
                                                _pressed={{ bg: "surface.500" }}
                                                borderRadius="2xl"
                                                onPress={() => {
                                                    if (
                                                        accountData.profile
                                                            ?.primaryParent?.id
                                                    ) {
                                                        navigation.push(
                                                            "Account",
                                                            {
                                                                profileId:
                                                                    accountData
                                                                        .profile
                                                                        .primaryParent
                                                                        .id,
                                                                accountType:
                                                                    "PARENT",
                                                                startingYear:
                                                                    state.values
                                                                        .startingYear,
                                                                block: state
                                                                    .values
                                                                    .block,
                                                            },
                                                        );
                                                    }
                                                }}
                                                px="2">
                                                <Text
                                                    fontSize="md"
                                                    fontWeight="600"
                                                    textDecorationLine="underline">
                                                    {getFullName(
                                                        accountData.profile
                                                            .primaryParent.user
                                                            .firstName,
                                                        accountData.profile
                                                            .primaryParent.user
                                                            .lastName,
                                                    )}
                                                </Text>
                                            </Pressable>
                                            <Button
                                                _hover={{
                                                    bg: "transparent",
                                                    opacity: 0.8,
                                                }}
                                                _pressed={{
                                                    bg: "transparent",
                                                    opacity: 0.7,
                                                }}
                                                _spinner={{
                                                    color: "surface.700",
                                                }}
                                                isLoading={
                                                    removeParentRelationshipInFlight &&
                                                    state.values
                                                        .clickedAccountId ===
                                                        route.params.profileId
                                                }
                                                leftIcon={
                                                    <CloseIcon
                                                        color="surface.700"
                                                        size="4"
                                                    />
                                                }
                                                onPress={() => {
                                                    dispatchState({
                                                        input: "clickedAccountId",
                                                        value: route.params
                                                            .profileId,
                                                    });
                                                    dispatchState({
                                                        input: "removeParentRelationshipAlertIsOpen",
                                                        value: true,
                                                    });
                                                }}
                                                p="1"
                                                position="absolute"
                                                right="-22"
                                                variant="ghost"
                                            />
                                        </HStack>
                                    ) : (
                                        <Button
                                            bg="primary.400"
                                            leftIcon={<ProfileIcon size="5" />}
                                            mt="2"
                                            onPress={() => {
                                                dispatchState({
                                                    input: "showCreateAccountModal",
                                                    value: true,
                                                });
                                            }}
                                            shadow={1}>
                                            Add Parent
                                        </Button>
                                    )}
                                </VStack>
                                {(accountData.profile?.teachers?.length ?? 0) >
                                0 ? (
                                    <VStack alignItems="center" space="1">
                                        <Text color="surface.700">
                                            Teacher
                                            {(accountData.profile?.teachers
                                                ?.length ?? 0) > 1
                                                ? "s"
                                                : ""}
                                        </Text>
                                        {accountData.profile?.teachers?.map(
                                            (item, index) => {
                                                return (
                                                    <Pressable
                                                        key={index}
                                                        _hover={{
                                                            bg: "surface.400",
                                                        }}
                                                        _pressed={{
                                                            bg: "surface.500",
                                                        }}
                                                        borderRadius="2xl"
                                                        onPress={() => {
                                                            if (
                                                                item?.profileId
                                                            ) {
                                                                navigation.push(
                                                                    "Account",
                                                                    {
                                                                        profileId:
                                                                            item.profileId,
                                                                        accountType:
                                                                            "TEACHER",
                                                                        startingYear:
                                                                            state
                                                                                .values
                                                                                .startingYear,
                                                                        block: state
                                                                            .values
                                                                            .block,
                                                                    },
                                                                );
                                                            }
                                                        }}
                                                        px="2">
                                                        <Text
                                                            fontSize="md"
                                                            fontWeight="600"
                                                            textDecorationLine="underline">
                                                            {getFullName(
                                                                item?.profileFirstName ??
                                                                    undefined,
                                                                item?.profileLastName ??
                                                                    undefined,
                                                            )}
                                                        </Text>
                                                    </Pressable>
                                                );
                                            },
                                        )}
                                    </VStack>
                                ) : null}
                            </VStack>
                        ) : null}
                        <TextInput
                            autoCapitalize="words"
                            id="firstName"
                            initiallyValid={Boolean(
                                accountData.profile?.user.firstName,
                            )}
                            initialValue={
                                accountData.profile?.user.firstName ?? ""
                            }
                            invalidIndicator
                            label="First name"
                            labelStyle={{
                                color: "surface.700",
                                fontSize: "sm",
                            }}
                            onFinishEditing={inputFinishEditingHandler}
                            size="md"
                        />
                        <TextInput
                            autoCapitalize="words"
                            id="lastName"
                            initiallyValid
                            initialValue={
                                accountData.profile?.user.lastName ?? ""
                            }
                            invalidIndicator
                            label="Last name"
                            labelStyle={{
                                color: "surface.700",
                                fontSize: "sm",
                            }}
                            onFinishEditing={inputFinishEditingHandler}
                            size="md"
                        />
                        {accountType !== "PUPIL" ? (
                            <>
                                <TextInput
                                    autoCapitalize="none"
                                    email
                                    id="email"
                                    initiallyValid
                                    initialValue={
                                        accountData.profile?.user.email ?? ""
                                    }
                                    invalidIndicator
                                    keyboardType="email-address"
                                    label="Email"
                                    labelStyle={{
                                        color: "surface.700",
                                        fontSize: "sm",
                                    }}
                                    onFinishEditing={inputFinishEditingHandler}
                                    size="md"
                                />
                                <TextInput
                                    autoCapitalize="none"
                                    id="phoneNumber"
                                    initiallyValid
                                    initialValue={
                                        accountData.profile?.phoneNumber ?? ""
                                    }
                                    invalidIndicator
                                    keyboardType="phone-pad"
                                    label="Phone number"
                                    labelStyle={{
                                        color: "surface.700",
                                        fontSize: "sm",
                                    }}
                                    onFinishEditing={inputFinishEditingHandler}
                                    size="md"
                                />
                            </>
                        ) : (
                            <>
                                <VStack mt="1" space="0.5" width="100%">
                                    <Text
                                        color="surface.700"
                                        fontSize="sm"
                                        pl="1">
                                        School
                                    </Text>
                                    <Select
                                        bg="surface.100"
                                        borderRadius="full"
                                        color="surface.900"
                                        fontSize="sm"
                                        onValueChange={(itemValue) =>
                                            selectChangeHandler(
                                                "school",
                                                itemValue,
                                            )
                                        }
                                        placeholder="Select school"
                                        selectedValue={
                                            state.values.schoolSelect
                                        }>
                                        {allSchools.map((item) => {
                                            return (
                                                <Select.Item
                                                    key={item?.node?.name}
                                                    actionSheetLabel={
                                                        titleCaseConverter(
                                                            item?.node?.name,
                                                        ) ?? ""
                                                    }
                                                    value={
                                                        item?.node?.name ?? ""
                                                    }
                                                />
                                            );
                                        })}
                                    </Select>
                                </VStack>
                                <VStack mt="1" space="0.5" width="100%">
                                    <Text
                                        color="surface.700"
                                        fontSize="sm"
                                        pl="1">
                                        School year
                                    </Text>
                                    <Select
                                        bg="surface.100"
                                        borderRadius="full"
                                        color="surface.900"
                                        fontSize="sm"
                                        onValueChange={(itemValue) =>
                                            selectChangeHandler(
                                                "schoolYear",
                                                itemValue,
                                            )
                                        }
                                        placeholder="Select school year"
                                        selectedValue={
                                            state.values.schoolYearSelect
                                        }>
                                        {SCHOOL_YEARS.map((item) => {
                                            return (
                                                <Select.Item
                                                    key={item.value}
                                                    actionSheetLabel={
                                                        item.label
                                                    }
                                                    value={item.value}
                                                />
                                            );
                                        })}
                                    </Select>
                                </VStack>
                                <TextInput
                                    autoCapitalize="words"
                                    id="schoolClass"
                                    initiallyValid
                                    initialValue={
                                        accountData.profile?.schoolClass ?? ""
                                    }
                                    label="Class"
                                    labelStyle={{
                                        color: "surface.700",
                                        fontSize: "sm",
                                    }}
                                    maxLength={200}
                                    onFinishEditing={inputFinishEditingHandler}
                                    size="md"
                                />
                                <VStack mt="1" space="0.5" width="100%">
                                    <Text
                                        color="surface.700"
                                        fontSize="sm"
                                        pl="1">
                                        Lessons paid by school?
                                    </Text>
                                    <Select
                                        _disabledBg="surface.100"
                                        _disabledBorderWidth={1}
                                        _disabledOpacity={0.9}
                                        bg="surface.100"
                                        borderRadius="full"
                                        color="surface.900"
                                        fontSize="sm"
                                        onValueChange={(itemValue) =>
                                            selectChangeHandler(
                                                "paidBySchool",
                                                itemValue === "YES",
                                            )
                                        }
                                        placeholder="Lessons paid by school?"
                                        selectedValue={
                                            state.values.paidBySchoolSelect
                                                ? "YES"
                                                : "NO"
                                        }>
                                        <Select.Item
                                            key={"YES"}
                                            actionSheetLabel={"Yes"}
                                            value={"YES"}
                                        />
                                        <Select.Item
                                            key={"NO"}
                                            actionSheetLabel={"No"}
                                            value={"NO"}
                                        />
                                    </Select>
                                </VStack>
                                <VStack mt="1" space="0.5" width="100%">
                                    <Text
                                        color="surface.700"
                                        fontSize="sm"
                                        pl="1">
                                        Fee type
                                    </Text>
                                    <Select
                                        bg="surface.100"
                                        borderRadius="full"
                                        color="surface.900"
                                        fontSize="sm"
                                        onValueChange={(itemValue) =>
                                            selectChangeHandler(
                                                "discountCategory",
                                                itemValue,
                                            )
                                        }
                                        placeholder="Select fee type"
                                        selectedValue={
                                            state.values.discountCategorySelect
                                        }>
                                        {DISCOUNT_CATEGORIES.map((item) => {
                                            return (
                                                <Select.Item
                                                    key={item.value}
                                                    actionSheetLabel={
                                                        item.label
                                                    }
                                                    value={item.value}
                                                />
                                            );
                                        })}
                                    </Select>
                                </VStack>
                                {state.values.discountCategorySelect?.includes(
                                    "DISCOUNT",
                                ) ? (
                                    <PercentageInput
                                        ref={discountPercentageInputRef}
                                        autoCapitalize="none"
                                        id="discountPercentage"
                                        initiallyValid={
                                            typeof accountData.profile
                                                ?.discountPercentage ===
                                            "number"
                                        }
                                        initialValue={
                                            state.values.discountPercentage
                                        }
                                        invalidIndicator
                                        label="Discount percentage"
                                        labelStyle={{
                                            color: "surface.700",
                                            fontSize: "sm",
                                        }}
                                        onFinishEditing={
                                            inputFinishEditingHandler
                                        }
                                        size="md"
                                    />
                                ) : null}
                                <HStack mt="3" space="3" width="100%">
                                    <Text
                                        color="surface.700"
                                        fontSize="sm"
                                        pl="1">
                                        Has book?
                                    </Text>
                                    <Checkbox
                                        defaultIsChecked={
                                            accountData.profile?.hasBook ??
                                            false
                                        }
                                        onChange={(isSelected) =>
                                            selectChangeHandler(
                                                "hasBook",
                                                isSelected,
                                            )
                                        }
                                        size="md"
                                        value="HAS_BOOK"
                                    />
                                </HStack>
                                <HStack space="3" width="100%">
                                    <Text
                                        color="surface.700"
                                        fontSize="sm"
                                        pl="1">
                                        Has instrument?
                                    </Text>
                                    <Checkbox
                                        defaultIsChecked={
                                            accountData.profile
                                                ?.hasInstrument ?? false
                                        }
                                        onChange={(isSelected) =>
                                            selectChangeHandler(
                                                "hasInstrument",
                                                isSelected,
                                            )
                                        }
                                        size="md"
                                        value="HAS_INSTRUMENT"
                                    />
                                </HStack>
                                <HStack space="3" width="100%">
                                    <Text
                                        color="surface.700"
                                        fontSize="sm"
                                        pl="1">
                                        Instrument supplied by us?
                                    </Text>
                                    <Checkbox
                                        defaultIsChecked={
                                            accountData.profile
                                                ?.instrumentSuppliedByUs ??
                                            false
                                        }
                                        onChange={(isSelected) =>
                                            selectChangeHandler(
                                                "instrumentSuppliedByUs",
                                                isSelected,
                                            )
                                        }
                                        size="md"
                                        value="INSTRUMENT_SUPPLIED_BY_US"
                                    />
                                </HStack>
                                <HStack mb="2" space="3" width="100%">
                                    <Text
                                        color="surface.700"
                                        fontSize="sm"
                                        pl="1">
                                        Has returned instrument?
                                    </Text>
                                    <Checkbox
                                        defaultIsChecked={
                                            accountData.profile
                                                ?.hasReturnedInstrument ?? false
                                        }
                                        onChange={(isSelected) =>
                                            selectChangeHandler(
                                                "hasReturnedInstrument",
                                                isSelected,
                                            )
                                        }
                                        size="md"
                                        value="HAS_RETURNED_INSTRUMENT"
                                    />
                                </HStack>
                                <TextInput
                                    autoCapitalize="sentences"
                                    id="staffNoteForTeacher"
                                    initiallyValid
                                    initialValue={
                                        accountData.profile
                                            ?.staffNoteForTeacher ?? ""
                                    }
                                    label="Notes"
                                    labelStyle={{
                                        color: "surface.700",
                                        fontSize: "sm",
                                    }}
                                    maxLength={500}
                                    onFinishEditing={inputFinishEditingHandler}
                                    size="md"
                                />
                            </>
                        )}
                        {accountType === "TEACHER" ? (
                            <>
                                <TextInput
                                    autoCapitalize="none"
                                    id="addressFirstLine"
                                    initiallyValid
                                    initialValue={
                                        accountData.profile?.addressFirstLine ??
                                        ""
                                    }
                                    invalidIndicator
                                    label="Address"
                                    labelStyle={{
                                        color: "surface.700",
                                        fontSize: "sm",
                                    }}
                                    maxLength={200}
                                    onFinishEditing={inputFinishEditingHandler}
                                    onSubmit={() =>
                                        addressSecondLineInputRef.current?.focus()
                                    }
                                    placeholder="1st line address"
                                    size="md"
                                />
                                <TextInput
                                    ref={addressSecondLineInputRef}
                                    autoCapitalize="none"
                                    id="addressSecondLine"
                                    initiallyValid
                                    initialValue={
                                        accountData.profile
                                            ?.addressSecondLine ?? ""
                                    }
                                    invalidIndicator
                                    maxLength={200}
                                    onFinishEditing={inputFinishEditingHandler}
                                    onSubmit={() =>
                                        cityInputRef.current?.focus()
                                    }
                                    placeholder="2nd line address"
                                    size="md"
                                />
                                <TextInput
                                    ref={cityInputRef}
                                    autoCapitalize="none"
                                    id="city"
                                    initiallyValid
                                    initialValue={
                                        accountData.profile?.city ?? ""
                                    }
                                    invalidIndicator
                                    maxLength={50}
                                    onFinishEditing={inputFinishEditingHandler}
                                    onSubmit={() =>
                                        postcodeInputRef.current?.focus()
                                    }
                                    placeholder="City or county"
                                    size="md"
                                />
                                <TextInput
                                    ref={postcodeInputRef}
                                    autoCapitalize="none"
                                    id="postcode"
                                    initiallyValid
                                    initialValue={
                                        accountData.profile?.postcode ?? ""
                                    }
                                    invalidIndicator
                                    maxLength={10}
                                    onFinishEditing={inputFinishEditingHandler}
                                    placeholder="Postcode"
                                    size="md"
                                />
                                <TextInput
                                    autoCapitalize="none"
                                    id="dbsNumber"
                                    initiallyValid
                                    initialValue={
                                        accountData.profile?.dbsNumber ?? ""
                                    }
                                    invalidIndicator
                                    label="DBS number"
                                    labelStyle={{
                                        color: "surface.700",
                                        fontSize: "sm",
                                    }}
                                    maxLength={50}
                                    onFinishEditing={inputFinishEditingHandler}
                                    size="md"
                                />
                                <TextInput
                                    ref={dbsExpirationDateRef}
                                    id="dbsExpirationDate"
                                    initiallyValid
                                    initialValue={
                                        accountData.profile?.dbsExpirationDate
                                            ? format(
                                                  new Date(
                                                      accountData.profile.dbsExpirationDate,
                                                  ),
                                                  "dd/MM/yyyy",
                                              )
                                            : ""
                                    }
                                    label="DBS expiration date"
                                    labelStyle={{
                                        color: "surface.700",
                                        fontSize: "sm",
                                    }}
                                    onPress={() =>
                                        dispatchState({
                                            input: "dbsExpiryDatePickerIsOpen",
                                            value: true,
                                        })
                                    }
                                    size="md"
                                />
                            </>
                        ) : null}
                    </VStack>
                </VStack>
            </SideBar>
        );
    }, [
        state.values.sideBarIsCollapsed,
        state.values.clickedAccountId,
        state.values.schoolSelect,
        state.values.schoolYearSelect,
        state.values.paidBySchoolSelect,
        state.values.discountCategorySelect,
        state.values.discountPercentage,
        state.values.startingYear,
        state.values.block,
        navigation,
        accountData.profile?.user.firstName,
        accountData.profile?.user.lastName,
        accountData.profile?.user.email,
        accountData.profile?.accountNumber,
        accountData.profile?.phoneNumber,
        accountData.profile?.school?.name,
        accountData.profile?.school?.id,
        accountData.profile?.instruments,
        accountData.profile?.schoolYear,
        accountData.profile?.status,
        accountData.profile?.dbsNumber,
        accountData.profile?.dbsExpirationDate,
        accountData.profile?.children.edges,
        accountData.profile?.primaryParent,
        accountData.profile?.teachers,
        accountData.profile?.schoolClass,
        accountData.profile?.discountPercentage,
        accountData.profile?.hasBook,
        accountData.profile?.hasInstrument,
        accountData.profile?.hasReturnedInstrument,
        accountData.profile?.instrumentSuppliedByUs,
        accountData.profile?.staffNoteForTeacher,
        accountData.profile?.addressFirstLine,
        accountData.profile?.addressSecondLine,
        accountData.profile?.city,
        accountData.profile?.postcode,
        accountType,
        updateAccountStatusInFlight,
        removeParentRelationshipInFlight,
        route.params.profileId,
        inputFinishEditingHandler,
        allSchools,
        dispatchState,
        changeStatusActionsheetOnOpen,
        childrenConnections,
        selectChangeHandler,
    ]);

    const renderTab = useMemo(() => {
        switch (accountType) {
            case "TEACHER":
                return (
                    <>
                        {state.values.currentPageIndex == 0 ? (
                            <TeacherRegisters
                                allSchools={allSchools}
                                dispatchState={dispatchState}
                                lessonBlocksConnectionId={
                                    state.values.lessonBlocksConnectionId
                                }
                                loadLessonBlocksQuery={loadLessonBlocksQuery}
                                loadLessonBlocksQueryReference={
                                    loadLessonBlocksQueryReference
                                }
                                // @ts-expect-error nav props are merged on component
                                navigation={navigation}
                                profileFirstName={
                                    accountData.profile?.user.firstName
                                }
                                profileId={route.params.profileId}
                                profileLastName={
                                    accountData.profile?.user.lastName
                                }
                                registerProgressCounter={
                                    registerProgressCounter
                                }
                                registerUpdates={registerUpdates}
                                removeLessonBlocks={removeLessonBlocks}
                                removeLessonBlocksInFlight={
                                    removeLessonBlocksInFlight
                                }
                                showUpdateBlockDetailsModalHandler={
                                    showUpdateBlockDetailsModalHandler
                                }
                                sideBarWidth={
                                    state.values.sideBarIsCollapsed
                                        ? SIDE_BAR_COLLAPSED_WIDTH
                                        : SIDE_BAR_EXPANDED_WIDTH
                                }
                                startingYears={startingYears}
                                state={state}
                                teacherSchools={teacherSchools}
                                updateLessonBlockDetails={
                                    updateLessonBlockDetails
                                }
                                updateRegisterState={updateRegisterStateHandler}
                                updateScheduledLessonBlockTime={
                                    updateScheduledLessonBlockTime
                                }
                                updateScheduledLessonBlockTimeInFlight={
                                    updateScheduledLessonBlockTimeInFlight
                                }
                                userIsTeacher={false}
                            />
                        ) : state.values.currentPageIndex == 1 ? (
                            <TeacherTimetable
                                dispatchState={dispatchState}
                                loadLessonsQuery={loadLessonsQuery}
                                loadLessonsQueryReference={
                                    loadLessonsQueryReference
                                }
                                // @ts-expect-error nav props are merged on component
                                navigation={navigation}
                                profileId={route.params.profileId}
                                state={state}
                                userIsTeacher={false}
                            />
                        ) : (
                            <ListEmptyBanner
                                explainer={"This hasn't been built yet..."}
                                flexGrow={1}
                                fontSize="3xl"
                                py="4">
                                <Text fontSize="110">
                                    {emoji.getUnicode(
                                        "construction_worker_man",
                                    )}
                                </Text>
                            </ListEmptyBanner>
                        )}
                    </>
                );
            case "PUPIL":
                return (
                    <>
                        {state.values.currentPageIndex == 0 ? (
                            loadLessonBlocksQueryReference != null ? (
                                <Suspense
                                    fallback={
                                        <LoadingBlobs>
                                            Loading Registers...
                                        </LoadingBlobs>
                                    }>
                                    <PupilRegisters
                                        dispatchState={dispatchState}
                                        loadLessonBlocksQuery={
                                            loadLessonBlocksQuery
                                        }
                                        loadLessonBlocksQueryReference={
                                            loadLessonBlocksQueryReference
                                        }
                                        navigation={navigation}
                                        openLessonBlockOptionsHandler={
                                            openLessonBlockOptionsHandler
                                        }
                                        profileId={route.params.profileId}
                                        registerUpdates={registerUpdates}
                                        showUpdateBlockDetailsModalHandler={
                                            showUpdateBlockDetailsModalHandler
                                        }
                                        sideBarWidth={
                                            state.values.sideBarIsCollapsed
                                                ? SIDE_BAR_COLLAPSED_WIDTH
                                                : SIDE_BAR_EXPANDED_WIDTH
                                        }
                                        startingYears={startingYears}
                                        state={state}
                                        updateRegisterState={
                                            updateRegisterStateHandler
                                        }
                                    />
                                </Suspense>
                            ) : (
                                <LoadingBlobs>
                                    Loading Registers...
                                </LoadingBlobs>
                            )
                        ) : (
                            <ListEmptyBanner
                                explainer={"This hasn't been built yet..."}
                                flexGrow={1}
                                fontSize="3xl"
                                py="4">
                                <Text fontSize="110">
                                    {emoji.getUnicode(
                                        "construction_worker_man",
                                    )}
                                </Text>
                            </ListEmptyBanner>
                        )}
                    </>
                );
            case "PARENT":
                return (
                    <>
                        {state.values.currentPageIndex == 0 ? (
                            <InvoiceAndEmail
                                accountScreenState={state}
                                dispatchAccountScreenState={dispatchState}
                                firstName={accountData.profile?.user.firstName}
                                isAccountScreen
                                isEditable
                                lastName={accountData.profile?.user.lastName}
                                loadInvoiceQuery={loadInvoiceQuery}
                                loadInvoiceQueryReference={
                                    loadInvoiceQueryReference
                                }
                                // @ts-expect-error nav props are merged
                                navigation={navigation}
                                previousOtherInvoicesConnectionIds={
                                    route.params
                                        .previousOtherInvoicesConnectionIds
                                }
                                profileId={route.params.profileId}
                                route={route}
                                sideBarWidth={
                                    state.values.sideBarIsCollapsed
                                        ? SIDE_BAR_COLLAPSED_WIDTH
                                        : SIDE_BAR_EXPANDED_WIDTH
                                }
                                startingYears={startingYears}
                            />
                        ) : (
                            <ListEmptyBanner
                                explainer={"This hasn't been built yet..."}
                                flexGrow={1}
                                fontSize="3xl"
                                py="4">
                                <Text fontSize="110">
                                    {emoji.getUnicode(
                                        "construction_worker_man",
                                    )}
                                </Text>
                            </ListEmptyBanner>
                        )}
                    </>
                );
            default:
                return (
                    <ListEmptyBanner
                        explainer={"This hasn't been built yet..."}
                        flex={1}
                        flexGrow={1}
                        fontSize="3xl"
                        py="4">
                        <Text fontSize="110">
                            {emoji.getUnicode("construction_worker_man")}
                        </Text>
                    </ListEmptyBanner>
                );
        }
    }, [
        accountType,
        state,
        dispatchState,
        startingYears,
        teacherSchools,
        allSchools,
        route,
        accountData.profile?.user.firstName,
        accountData.profile?.user.lastName,
        loadLessonBlocksQuery,
        loadLessonBlocksQueryReference,
        updateLessonBlockDetails,
        updateScheduledLessonBlockTime,
        updateScheduledLessonBlockTimeInFlight,
        updateRegisterStateHandler,
        showUpdateBlockDetailsModalHandler,
        removeLessonBlocks,
        removeLessonBlocksInFlight,
        navigation,
        loadLessonsQuery,
        loadLessonsQueryReference,
        openLessonBlockOptionsHandler,
        loadInvoiceQuery,
        loadInvoiceQueryReference,
    ]);

    const renderModals = useMemo(() => {
        return (
            <>
                {state.values.showCreateAccountModal ? (
                    <CreateAccountModal
                        initialAccountType={
                            accountType === "PARENT" ? "PUPIL" : "PARENT"
                        }
                        isAccountPage
                        profileId={route.params.profileId}
                        pupilConnections={
                            accountType === "PARENT" && childrenConnections
                                ? [childrenConnections]
                                : undefined
                        }
                        schools={allSchools}
                        setShowModal={(isOpen) => {
                            dispatchState({
                                input: "showCreateAccountModal",
                                value: isOpen,
                            });
                        }}
                        showModal={state.values.showCreateAccountModal}
                    />
                ) : null}
                {accountType === "PUPIL" || accountType === "TEACHER" ? (
                    <PupilsToArchiveModal
                        block={state.values.block}
                        hideModal={() =>
                            dispatchState({
                                input: "pupilsToArchiveModalInfo",
                                value: undefined,
                            })
                        }
                        pupilIds={state.values.pupilsToArchiveModalInfo?.ids}
                        pupilNames={
                            state.values.pupilsToArchiveModalInfo?.pupilNames
                        }
                        showModal={Boolean(
                            state.values.pupilsToArchiveModalInfo,
                        )}
                        startingYear={state.values.startingYear}
                    />
                ) : null}
                <DateTimePickerModal
                    animationType="fade"
                    canChooseEndTime
                    date={state.values.dateTimePickerModalDate}
                    duration={state.values.dateTimePickerModalLessonDuration}
                    hours={state.values.dateTimePickerModalDate?.getHours()}
                    label="Reschedule Lesson"
                    locale="en"
                    minutes={state.values.dateTimePickerModalDate?.getMinutes()}
                    onConfirm={(params) => {
                        if (state.values.dateTimePickerModalLessonId) {
                            rescheduleLesson(
                                state.values.dateTimePickerModalLessonId,
                                params.date as Date,
                                params.duration,
                            );
                        }
                        dispatchState({
                            input: "dateTimePickerModalDate",
                            value: undefined,
                        });
                        dispatchState({
                            input: "dateTimePickerModalLessonId",
                            value: undefined,
                        });
                    }}
                    onDismiss={() =>
                        dispatchState({
                            input: "dateTimePickerModalDate",
                            value: undefined,
                        })
                    }
                    saveLabel="Update"
                    uppercase={false}
                    visible={
                        typeof state.values.dateTimePickerModalDate !=
                        "undefined"
                    }
                />
                <DayTimePickerModal
                    animationType="fade"
                    dayIndex={(state.values.dayIndex ?? 0) + 1}
                    hideDayPicker
                    hours={9}
                    isLoading={addNewLessonBlockInFlight}
                    label="Add Lesson Block"
                    locale="en"
                    minutes={0}
                    onConfirm={(params) => {
                        if (!state.values.isAddLessonBreakModal) {
                            if (state.values.selectedSchoolOnModal) {
                                addNewLessonBlock({
                                    school: state.values.selectedSchoolOnModal,
                                    startingYear: state.values.startingYear,
                                    block: state.values.block,
                                    dayIndex: params.dayIndex,
                                    hours: params.hours,
                                    minutes: params.minutes,
                                    type: "LESSON",
                                });
                            }
                        } else {
                            if (state.values.selectedSchoolOnModal) {
                                dispatchState({
                                    input: "showAddLessonBlockModal",
                                    value: false,
                                });
                                dispatchState({
                                    input: "school",
                                    value: state.values.selectedSchoolOnModal,
                                });
                                dispatchState({
                                    input: "dayIndex",
                                    value: params.dayIndex - 1,
                                });
                                dispatchState({
                                    input: "hours",
                                    value: params.hours,
                                });
                                dispatchState({
                                    input: "minutes",
                                    value: params.minutes,
                                });

                                dispatchState({
                                    input: "showAddBreakBlockModal",
                                    value: true,
                                });
                            }
                        }
                    }}
                    onDismiss={() =>
                        dispatchState({
                            input: "showAddLessonBlockModal",
                            value: false,
                        })
                    }
                    saveLabel="Add"
                    uppercase={false}
                    visible={state.values.showAddLessonBlockModal}
                />
                <DatePickerModal
                    animationType="fade"
                    date={
                        accountData.profile?.dbsExpirationDate
                            ? new Date(accountData.profile.dbsExpirationDate)
                            : new Date()
                    }
                    label="Select Date"
                    locale="en"
                    mode="single"
                    onConfirm={(params) => {
                        if (params.date) {
                            dbsExpirationDateRef.current?.setValue(
                                format(params.date, "dd/MM/yyyy"),
                                true,
                            );
                            editProfile({
                                profileId: route.params.profileId,
                                dbsExpirationDate: params.date.toDateString(),
                            });
                        }
                        dispatchState({
                            input: "dbsExpiryDatePickerIsOpen",
                            value: false,
                        });
                    }}
                    onDismiss={() =>
                        dispatchState({
                            input: "dbsExpiryDatePickerIsOpen",
                            value: false,
                        })
                    }
                    saveLabel="Select"
                    uppercase={false}
                    visible={state.values.dbsExpiryDatePickerIsOpen}
                />
                <UpdateLessonBlockDetailsModal
                    block={state.values.block}
                    detailType={
                        state.values.updateBlockDetailsModalInfo?.type as
                            | "INSTRUMENT"
                            | "STAGE"
                            | undefined
                    }
                    hideModal={() => {
                        dispatchState({
                            input: "updateBlockDetailsModalInfo",
                            value: undefined,
                        });
                    }}
                    lessonBlockCursor={
                        state.values.updateBlockDetailsModalInfo?.cursor
                    }
                    lessonBlockIds={
                        state.values.updateBlockDetailsModalInfo?.ids
                    }
                    onUpdate={
                        state.values.updateBlockDetailsModalInfo?.onUpdate
                    }
                    school={state.values.updateBlockDetailsModalInfo?.school}
                    showModal={
                        (state.values.updateBlockDetailsModalInfo?.ids ??
                            false) &&
                        (state.values.updateBlockDetailsModalInfo?.ids
                            ?.length ?? 0) > 0 &&
                        (state.values.updateBlockDetailsModalInfo?.type ===
                            "INSTRUMENT" ||
                            state.values.updateBlockDetailsModalInfo?.type ===
                                "STAGE" ||
                            state.values.updateBlockDetailsModalInfo?.type ===
                                "NOTES")
                    }
                    startingYear={state.values.startingYear}
                    updateLessonBlockDetails={updateLessonBlockDetails}
                    updateLessonBlockDetailsInFlight={
                        updateLessonBlockDetailsInFlight
                    }
                />
                <UpdateLessonBlockTeacherOrPupilModal
                    dayIndex={state.values.dayIndex}
                    detailType={
                        state.values.updateBlockDetailsModalInfo?.type as
                            | "PUPIL"
                            | "TEACHER"
                            | undefined
                    }
                    hideModal={() => {
                        dispatchState({
                            input: "updateBlockDetailsModalInfo",
                            value: undefined,
                        });
                    }}
                    keepFree={
                        state.values.updateBlockDetailsModalInfo?.keepFree
                    }
                    lessonBlockIds={
                        state.values.updateBlockDetailsModalInfo?.ids
                    }
                    onUpdate={
                        state.values.updateBlockDetailsModalInfo?.onUpdate
                    }
                    schoolName={
                        state.values.updateBlockDetailsModalInfo?.school
                    }
                    selectedBlock={state.values.block}
                    selectedProfileFullName={
                        state.values.updateBlockDetailsModalInfo
                            ?.selectedProfileFullName
                    }
                    selectedProfileId={
                        state.values.updateBlockDetailsModalInfo
                            ?.selectedProfileId
                    }
                    selectedStartingYear={state.values.startingYear}
                    showModal={
                        Boolean(
                            state.values.updateBlockDetailsModalInfo?.ids,
                        ) &&
                        (state.values.updateBlockDetailsModalInfo?.type ===
                            "TEACHER" ||
                            state.values.updateBlockDetailsModalInfo?.type ===
                                "PUPIL" ||
                            state.values.updateBlockDetailsModalInfo?.type ===
                                "TEACHER_AND_DAY")
                    }
                    updateLessonBlockDetails={updateLessonBlockDetails}
                    updateLessonBlockDetailsInFlight={
                        updateLessonBlockDetailsInFlight
                    }
                    updateScheduledLessonBlockTime={
                        updateScheduledLessonBlockTime
                    }
                    updateScheduledLessonBlockTimeInFlight={
                        updateScheduledLessonBlockTimeInFlight
                    }
                />
                <Modal
                    isOpen={state.values.selectSchoolModalIsOpen}
                    onClose={() => {
                        dispatchState({
                            input: "selectSchoolModalIsOpen",
                            value: false,
                        });
                    }}
                    size="xl">
                    <Modal.Content bg="primary.800">
                        <Modal.CloseButton
                            _hover={{ bg: "transparent", opacity: 0.7 }}
                            _icon={{ color: "surface.100" }}
                            _pressed={{ bg: "transparent", opacity: 0.7 }}
                        />
                        <Modal.Header
                            _text={{ color: "surface.100", fontSize: "2xl" }}
                            bg="primary.800">
                            {state.values.isAddLessonBreakModal
                                ? "Add Lesson Break"
                                : "Add Lesson Block"}
                        </Modal.Header>
                        <Modal.Body alignItems="center" mb="4" mt="8" px="6">
                            <VStack alignItems="center" space="8">
                                <VStack alignItems="center" flex={1}>
                                    <Text
                                        color="surface.100"
                                        fontSize="md"
                                        mb="1"
                                        textAlign="center">
                                        School
                                    </Text>
                                    <Box width="100%">
                                        <Select
                                            bg="surface.100"
                                            borderColor="surface.200"
                                            borderRadius="2xl"
                                            fontSize="lg"
                                            onValueChange={(itemValue) => {
                                                dispatchState({
                                                    input: "selectedSchoolOnModal",
                                                    value: itemValue,
                                                });
                                            }}
                                            p="3"
                                            placeholder="Select school"
                                            selectedValue={
                                                state.values
                                                    .selectedSchoolOnModal
                                            }
                                            textAlign="center">
                                            {teacherSchools?.map((item) => {
                                                return (
                                                    <Select.Item
                                                        key={item?.name}
                                                        actionSheetLabel={
                                                            titleCaseConverter(
                                                                item?.name,
                                                            ) ?? ""
                                                        }
                                                        value={item?.name ?? ""}
                                                    />
                                                );
                                            }) ??
                                                allSchools.map((item) => {
                                                    return (
                                                        <Select.Item
                                                            key={
                                                                item?.node?.name
                                                            }
                                                            actionSheetLabel={
                                                                titleCaseConverter(
                                                                    item?.node
                                                                        ?.name,
                                                                ) ?? ""
                                                            }
                                                            value={
                                                                item?.node
                                                                    ?.name ?? ""
                                                            }
                                                        />
                                                    );
                                                })}
                                        </Select>
                                    </Box>
                                </VStack>
                                <ButtonDebounced
                                    _text={{ fontSize: "xl" }}
                                    height="56px"
                                    isDisabled={
                                        !state.values.selectedSchoolOnModal
                                    }
                                    mb="4"
                                    mt="8"
                                    onPress={() => {
                                        dispatchState({
                                            input: "selectSchoolModalIsOpen",
                                            value: false,
                                        });
                                        dispatchState({
                                            input: "showAddLessonBlockModal",
                                            value: true,
                                        });
                                    }}
                                    width="160px">
                                    {"Continue"}
                                </ButtonDebounced>
                            </VStack>
                        </Modal.Body>
                    </Modal.Content>
                </Modal>
                <CreateBreakBlockModal
                    addNewLessonBlock={addNewLessonBlock}
                    addNewLessonBlockInFlight={addNewLessonBlockInFlight}
                    block={state.values.block}
                    dayIndex={(state.values.dayIndex ?? 0) + 1}
                    hours={state.values.hours ?? 0}
                    minutes={state.values.minutes ?? 0}
                    school={state.values.school ?? ""}
                    setShowModal={(isHidden) =>
                        dispatchState({
                            input: "showAddBreakBlockModal",
                            value: isHidden,
                        })
                    }
                    showModal={state.values.showAddBreakBlockModal ?? false}
                    startingYear={state.values.startingYear}
                />
                <Actionsheet
                    animationType="fade"
                    hideDragIndicator
                    isOpen={optionsIsOpen}
                    justifyContent="center"
                    onClose={optionsOnClose}
                    size="lg">
                    <Actionsheet.Content
                        alignItems="center"
                        closeButtonProps={{ top: "5", right: "5" }}
                        justifyContent="center"
                        mx="auto"
                        roundedBottom={12}
                        roundedTop={12}
                        showCloseButton
                        w="40%">
                        <Text
                            alignSelf="center"
                            fontSize="xl"
                            fontWeight="bold"
                            py="4">
                            Lesson Block Options
                        </Text>
                        <Actionsheet.Item
                            _text={{ fontSize: "lg" }}
                            leftIcon={
                                <Center size="7">
                                    <TrashIcon color="surface.900" size="6" />
                                </Center>
                            }
                            onPress={() => {
                                if (state.values.lessonBlockActionsheetId) {
                                    removeLessonBlocks(
                                        [state.values.lessonBlockActionsheetId],
                                        state.values.lessonBlocksConnectionId,
                                    );
                                }
                                optionsOnClose();
                            }}>
                            Delete lesson block
                        </Actionsheet.Item>
                    </Actionsheet.Content>
                </Actionsheet>
                <Actionsheet
                    animationType="fade"
                    hideDragIndicator
                    isOpen={changeStatusActionsheetIsOpen}
                    justifyContent="center"
                    onClose={changeStatusActionsheetOnClose}
                    size="lg">
                    <Actionsheet.Content
                        alignItems="center"
                        closeButtonProps={{ top: "5", right: "5" }}
                        justifyContent="center"
                        mx="auto"
                        roundedBottom={12}
                        roundedTop={12}
                        showCloseButton
                        w="40%">
                        <Text
                            alignSelf="center"
                            fontSize="xl"
                            fontWeight="bold"
                            py="4">
                            Update Account Status
                        </Text>
                        <Text
                            alignSelf="center"
                            fontFamily="Poppins-Regular"
                            fontSize="md"
                            mx="8"
                            pb="2"
                            textAlign="center">
                            {`${
                                accountData.profile?.status !== "ARCHIVED"
                                    ? route.params.accountType === "PARENT"
                                        ? "Archiving this account will also archive all of their children's accounts."
                                        : route.params.accountType === "PUPIL"
                                          ? "Archiving this account will also archive their parent's account (if they don't have any other active children)."
                                          : "Only delete an account if it has been created by mistake."
                                    : "Only delete an account if it has been created by mistake."
                            }`}
                        </Text>
                        {accountData.profile?.status !== "ARCHIVED" ? (
                            <Actionsheet.Item
                                _text={{ fontSize: "lg" }}
                                leftIcon={
                                    <Center size="7">
                                        <ArchivedIcon
                                            color="surface.600"
                                            size="6"
                                        />
                                    </Center>
                                }
                                onPress={() => {
                                    updateAccountStatus({ status: "ARCHIVED" });
                                    changeStatusActionsheetOnClose();
                                }}>
                                Archive account
                            </Actionsheet.Item>
                        ) : (
                            <Actionsheet.Item
                                _text={{ fontSize: "lg" }}
                                leftIcon={
                                    <Center size="7">
                                        <TickIcon
                                            color="primary.400"
                                            size="7"
                                        />
                                    </Center>
                                }
                                onPress={() => {
                                    updateAccountStatus({ status: "ACTIVE" });
                                    changeStatusActionsheetOnClose();
                                }}>
                                Make account active
                            </Actionsheet.Item>
                        )}
                        <Actionsheet.Item
                            _text={{ fontSize: "lg" }}
                            leftIcon={
                                <Center size="7">
                                    <TrashIcon color="surface.800" size="6" />
                                </Center>
                            }
                            onPress={() => {
                                dispatchState({
                                    input: "deleteAccountAlertIsOpen",
                                    value: true,
                                });
                                changeStatusActionsheetOnClose();
                            }}>
                            Delete account
                        </Actionsheet.Item>
                    </Actionsheet.Content>
                </Actionsheet>
                <AlertPopup
                    alertIsOpen={Boolean(state.values.leaveAlertAction)}
                    body={`You have made unsaved changes to ${
                        accountData.profile?.user.firstName
                            ? accountData.profile.user.firstName + "'s"
                            : "these"
                    } registers.`}
                    header="Save your changes?"
                    safeButtonRef={safeLeaveRef}
                    setAlertIsOpen={(isOpen: boolean) =>
                        dispatchState({
                            input: "leaveAlertAction",
                            value: isOpen,
                        })
                    }>
                    <Button
                        _text={{ fontSize: "lg" }}
                        colorScheme="red"
                        minWidth="100"
                        onPress={() => {
                            if (
                                isNavigationAction(
                                    state.values.leaveAlertAction,
                                )
                            ) {
                                navigation.dispatch(
                                    state.values.leaveAlertAction,
                                );
                            } else {
                                state.values.leaveAlertAction?.();
                            }
                            dispatchState({
                                input: "leaveAlertAction",
                                value: undefined,
                            });
                            registerUpdates.current = [];
                            dispatchState({
                                input: "registerUpdater",
                                value: Math.random(),
                            });
                        }}
                        width="40%">
                        Discard
                    </Button>
                    <ButtonDebounced
                        ref={safeLeaveRef}
                        _text={{ fontSize: "lg" }}
                        colorScheme="primary"
                        minWidth="100"
                        onPress={() => {
                            dispatchState({
                                input: "leaveAlertAction",
                                value: undefined,
                            });
                            updateRegisters(registerUpdates.current, () => {
                                if (
                                    isNavigationAction(
                                        state.values.leaveAlertAction,
                                    )
                                ) {
                                    navigation.dispatch(
                                        state.values.leaveAlertAction,
                                    );
                                } else {
                                    state.values.leaveAlertAction?.();
                                }
                            });
                        }}
                        width="40%">
                        Save
                    </ButtonDebounced>
                </AlertPopup>
                <AlertPopup
                    alertIsOpen={
                        state.values.removeParentRelationshipAlertIsOpen
                    }
                    body="This does not delete either account."
                    header={`Are you sure you want to remove ${
                        route.params.accountType === "PARENT"
                            ? "this parents' relationship with this child?"
                            : "this childs' relationship with this parent?"
                    }`}
                    safeButtonRef={safeRemoveRelationshipRef}
                    setAlertIsOpen={(isOpen: boolean) =>
                        dispatchState({
                            input: "removeParentRelationshipAlertIsOpen",
                            value: isOpen,
                        })
                    }>
                    <Button
                        _text={{ fontSize: "lg" }}
                        colorScheme="muted"
                        minWidth="100"
                        onPress={() => {
                            dispatchState({
                                input: "clickedAccountId",
                                value: undefined,
                            });
                            dispatchState({
                                input: "removeParentRelationshipAlertIsOpen",
                                value: false,
                            });
                        }}
                        width="40%">
                        No, go back
                    </Button>
                    <ButtonDebounced
                        ref={safeRemoveRelationshipRef}
                        _text={{ fontSize: "lg" }}
                        colorScheme="primary"
                        minWidth="100"
                        onPress={() => {
                            if (state.values.clickedAccountId) {
                                removeParentRelationship({
                                    pupilId: state.values.clickedAccountId,
                                });
                                dispatchState({
                                    input: "removeParentRelationshipAlertIsOpen",
                                    value: false,
                                });
                            }
                        }}
                        width="40%">
                        Yes, remove it
                    </ButtonDebounced>
                </AlertPopup>
                <AlertPopup
                    alertIsOpen={state.values.deleteAccountAlertIsOpen}
                    body="This cannot be undone and all their data will be permanently lost."
                    header={`Are you sure you want to delete this ${route.params.accountType.toLowerCase()}?`}
                    safeButtonRef={safeDeleteAccountRef}
                    setAlertIsOpen={(isOpen: boolean) =>
                        dispatchState({
                            input: "deleteAccountAlertIsOpen",
                            value: isOpen,
                        })
                    }>
                    <Button
                        ref={safeDeleteAccountRef}
                        _text={{ fontSize: "lg" }}
                        colorScheme="muted"
                        minWidth="100"
                        onPress={() => {
                            dispatchState({
                                input: "deleteAccountAlertIsOpen",
                                value: false,
                            });
                        }}
                        width="40%">
                        No, go back
                    </Button>
                    <ButtonDebounced
                        _text={{ fontSize: "lg" }}
                        colorScheme="red"
                        minWidth="100"
                        onPress={() => {
                            updateAccountStatus({ status: "DELETE" });
                            dispatchState({
                                input: "deleteAccountAlertIsOpen",
                                value: false,
                            });
                        }}
                        width="40%">
                        Yes, delete
                    </ButtonDebounced>
                </AlertPopup>
            </>
        );
    }, [
        state.values,
        accountType,
        allSchools,
        childrenConnections,
        route.params.profileId,
        route.params.accountType,
        addNewLessonBlockInFlight,
        accountData.profile?.dbsExpirationDate,
        accountData.profile?.status,
        accountData.profile?.user.firstName,
        updateLessonBlockDetails,
        updateLessonBlockDetailsInFlight,
        updateScheduledLessonBlockTime,
        updateScheduledLessonBlockTimeInFlight,
        teacherSchools,
        addNewLessonBlock,
        optionsIsOpen,
        optionsOnClose,
        changeStatusActionsheetIsOpen,
        changeStatusActionsheetOnClose,
        dispatchState,
        rescheduleLesson,
        editProfile,
        removeLessonBlocks,
        updateAccountStatus,
        navigation,
        updateRegisters,
        removeParentRelationship,
    ]);

    const renderSaveButton = useMemo(() => {
        return (
            <PresenceTransition
                animate={{
                    opacity: 1,
                    scale: 1,
                    transition: {
                        duration: 250,
                    },
                }}
                initial={{
                    opacity: 0,
                    scale: 0,
                }}
                visible={registerUpdates.current.length > 0}>
                <Button
                    _focus={{ opacity: 1 }}
                    _hover={{ opacity: 1 }}
                    _pressed={{ opacity: 1 }}
                    _spinner={{ size: "lg" }}
                    _text={{ fontSize: "xl" }}
                    alignSelf="center"
                    bottom="5"
                    height="60px"
                    isLoading={updateRegistersInFlight}
                    leftIcon={<CompleteIcon size="8" />}
                    onPress={() => updateRegisters(registerUpdates.current)}
                    position="absolute"
                    shadow={5}
                    width="300px">
                    Save
                </Button>
            </PresenceTransition>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        updateRegisters,
        updateRegistersInFlight,
        state.values.registerUpdater,
    ]);

    return (
        <Box flex={1}>
            {renderSideBar}
            <Box
                flex={1}
                flexGrow={1}
                ml={
                    state.values.sideBarIsCollapsed
                        ? SIDE_BAR_COLLAPSED_WIDTH
                        : SIDE_BAR_EXPANDED_WIDTH
                }
                mt="70"
                pt="6"
                px="6">
                <TopTabBar
                    bg="primary.100"
                    borderRadius="lg"
                    buttonStyle={{
                        bg: "primary.50",
                        alignItems: "center",
                        pt: 2,
                        pb: 2,
                        _hover: { bg: "primary.200" },
                        _pressed: { bg: "primary.300" },
                    }}
                    containerProps={{ pt: 4 }}
                    currentPageIndex={state.values.currentPageIndex}
                    hideBottomBar
                    mb="6"
                    overflow="hidden"
                    pages={pages}
                    selectedButtonStyle={{ bg: "primary.100" }}
                    selectedTextStyle={{ color: "primary.600" }}
                    setCurrentPageIndex={(index) => {
                        dispatchState({
                            input: "currentPageIndex",
                            value: index,
                        });
                        navigation.setParams({ currentPageIndex: index });
                    }}
                    shadow={1}
                    textStyle={{ color: "primary.800" }}
                    width="100%"
                />
                {renderTab}
                {renderSaveButton}
            </Box>
            {renderModals}
        </Box>
    );
};

const AccountScreen: FC<ScreenProps> = (props) => {
    const { navigation, route } = props;

    const [defaultStartingYear, startingYears] = useMemo(
        () => getDefaultStartingYearAndStartingYearOptions(),
        [],
    );

    const { user } = useAuth();

    const initialState = useMemo(() => {
        let dayIndex = 0;
        // select the current day on the teacher register tab
        if (route.params?.accountType === "TEACHER") {
            dayIndex = new Date().getDay() - 1;
            // only allowed to select mon-fri
            if (dayIndex < 0 || dayIndex > 4) {
                dayIndex = 0;
            }
        }
        let initialStartingYear = route.params?.startingYear;
        if (initialStartingYear === undefined) {
            initialStartingYear =
                user?.profile?.profileGroup?.currentStartingYear ??
                defaultStartingYear;
            navigation.setParams({
                startingYear: initialStartingYear,
            });
        }
        let initialBlock = route.params?.block;
        if (initialBlock === undefined) {
            if (
                user?.profile?.profileGroup?.currentStartingYear &&
                user?.profile?.profileGroup?.currentStartingYear >
                    defaultStartingYear
            ) {
                initialBlock = 1;
            } else {
                initialBlock = user?.profile?.currentBlock ?? getCurrentBlock();
            }
            navigation.setParams({
                block: initialBlock,
            });
        }
        return {
            values: {
                currentPageIndex: Number(route.params?.currentPageIndex ?? 0),
                startingYear: initialStartingYear,
                block: initialBlock,
                school: route.params?.school,
                searchTerm: route.params?.searchTerm ?? "",
                isRefetching: false,
                contentIsRendered: false,
                subscription: undefined,
                sideBarIsCollapsed: false,
                showCreateAccountModal: false,
                schoolSelect: undefined,
                schoolYearSelect: undefined,
                paidBySchoolSelect: undefined,
                discountCategorySelect: undefined,
                discountPercentage: undefined,
                lessonBlockActionsheetId: undefined,
                lessonBlocksConnectionId: undefined,
                registerModalLessonInfo: undefined,
                dateTimePickerModalDate: undefined,
                dateTimePickerModalLessonId: undefined,
                dateTimePickerModalLessonDuration: undefined,
                updateBlockDetailsModalInfo: undefined,
                showAddLessonBlockModal: false,
                timetableDate: route.params?.timetableDate
                    ? new Date(route.params?.timetableDate)
                    : new Date(),
                timetableView: (route.params?.timetableView ?? "WEEK") as
                    | "DAY"
                    | "WEEK",
                timetableDatePickerIsOpen: false,
                timetableLessonModalInfo: undefined,
                registerUpdater: 0,
                dayIndex: route.params?.dayIndex ?? dayIndex,
                dbsExpiryDatePickerIsOpen: false,
                leaveAlertAction: undefined,
                refreshHandler: undefined,
                teachingDays: undefined,
                invoiceIsEdited: false,
                draftAlertIsOpen: false,
                draftAlertInvoiceId: undefined,
                invoiceId: undefined,
                clickedAccountId: undefined,
                removeParentRelationshipAlertIsOpen: false,
                deleteAccountAlertIsOpen: false,
                selectSchoolModalIsOpen: false,
                selectedSchoolOnModal: undefined,
            },
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [state, dispatchState] = useReducer(reducer, initialState);

    const [loadAccountQueryReference, loadAccountQuery] =
        useQueryLoader<LoadAccountQuery>(load_account);

    useEffect(() => {
        loadAccountQuery(
            {
                profileId: route.params.profileId,
                startingYear: state.values.startingYear,
                block: state.values.block,
            },
            { fetchPolicy: "store-or-network" },
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadAccountQuery, route.params.profileId]);

    const renderLoadingFallback = useMemo(() => {
        return (
            <HStack flex={1}>
                <SideBar
                    isCollapsed={state.values.sideBarIsCollapsed}
                    navigation={navigation}
                    position="relative"
                    setIsCollapsed={() =>
                        dispatchState({
                            input: "sideBarIsCollapsed",
                            value: !state.values.sideBarIsCollapsed,
                        })
                    }
                    top="0"
                    width={
                        state.values.sideBarIsCollapsed
                            ? SIDE_BAR_COLLAPSED_WIDTH
                            : SIDE_BAR_EXPANDED_WIDTH
                    }
                />
                <LoadingBlobs
                    ml={
                        state.values.sideBarIsCollapsed
                            ? SIDE_BAR_COLLAPSED_WIDTH + 1
                            : SIDE_BAR_EXPANDED_WIDTH + 1
                    }
                    pt="70">
                    Loading Account...
                </LoadingBlobs>
            </HStack>
        );
    }, [navigation, state.values.sideBarIsCollapsed]);

    return (
        <Box bg="surface.100" flex={1}>
            {loadAccountQueryReference != null ? (
                <Suspense fallback={renderLoadingFallback}>
                    <AccountContent
                        accountType={route.params.accountType}
                        dispatchState={dispatchState}
                        loadAccountQueryReference={loadAccountQueryReference}
                        navigation={navigation}
                        route={route}
                        startingYears={startingYears}
                        state={state}
                    />
                </Suspense>
            ) : (
                renderLoadingFallback
            )}
        </Box>
    );
};

export const screenOptions = {
    headerTitle: "Account",
};

export default AccountScreen;
