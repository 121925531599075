/**
 * @generated SignedSource<<518be6afe7ed9a6be7e4a3a19799fcf6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LessonModelStatus = "BLANK" | "BREAK" | "LEFT_SCHOOL" | "MISSED" | "NO_LESSON" | "OTHER_TEACHER" | "PRESENT" | "PRESENT_DOUBLE" | "PUPIL_ABSENT" | "STOPPED_LESSONS" | "TEACHER_ABSENT" | "%future added value";
export type RemoveLessonColumnInput = {
  block: number;
  clientMutationId?: string | null;
  dayIndex: number;
  replacementLessonColumnIndex?: number | null;
  scheduledDate?: string | null;
  startingYear: number;
  teacherId: string;
};
export type RemoveLessonColumnMutation$variables = {
  input: RemoveLessonColumnInput;
};
export type RemoveLessonColumnMutation$data = {
  readonly removeLessonColumn: {
    readonly errors: any | null;
    readonly lessonBlocks: ReadonlyArray<{
      readonly id: string;
      readonly lessons: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly rearrangedTimestamp: string | null;
            readonly scheduledTimestamp: string | null;
            readonly status: LessonModelStatus;
          } | null;
        } | null>;
      };
    } | null> | null;
    readonly success: boolean | null;
  } | null;
};
export type RemoveLessonColumnMutation = {
  response: RemoveLessonColumnMutation$data;
  variables: RemoveLessonColumnMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoveLessonColumnPayload",
    "kind": "LinkedField",
    "name": "removeLessonColumn",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LessonBlockNode",
        "kind": "LinkedField",
        "name": "lessonBlocks",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "LessonNodeConnection",
            "kind": "LinkedField",
            "name": "lessons",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LessonNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LessonNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "scheduledTimestamp",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rearrangedTimestamp",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveLessonColumnMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveLessonColumnMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "cdabb0b41d50669d2dbd4ecd0888ee9a",
    "id": null,
    "metadata": {},
    "name": "RemoveLessonColumnMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveLessonColumnMutation(\n  $input: RemoveLessonColumnInput!\n) {\n  removeLessonColumn(input: $input) {\n    success\n    errors\n    lessonBlocks {\n      id\n      lessons {\n        edges {\n          node {\n            id\n            scheduledTimestamp\n            rearrangedTimestamp\n            status\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "26ef9ba7acd0c9af0969f95ca3139de9";

export default node;
