import { graphql } from "react-relay";

export const load_payslips = graphql`
    query LoadPayslipsQuery(
        $searchTerm: String
        $orderBy: String
        $first: Int!
        $after: String
        $dateFrom: String!
        $dateTo: String!
    ) {
        ...LoadPayslips_query_payslips
            @arguments(
                searchTerm: $searchTerm
                orderBy: $orderBy
                first: $first
                after: $after
                dateFrom: $dateFrom
                dateTo: $dateTo
            )
        ...PayrollHeadlineDataFragment_query
            @arguments(dateFrom: $dateFrom, dateTo: $dateTo)
    }
`;

export const load_payslips_pagination = graphql`
    fragment LoadPayslips_query_payslips on Query
    @argumentDefinitions(
        searchTerm: { type: "String" }
        orderBy: { type: "String" }
        first: { type: "Int!" }
        after: { type: "String" }
        dateFrom: { type: "String!" }
        dateTo: { type: "String!" }
    )
    @refetchable(queryName: "LoadPayslipsPaginationQuery") {
        payslips(
            searchTerm: $searchTerm
            orderBy: $orderBy
            first: $first
            after: $after
            dateFrom: $dateFrom
            dateTo: $dateTo
        ) @connection(key: "LoadPayslips_query_payslips") {
            __id
            edges {
                node {
                    id
                    amountDue
                    status
                    amountAdjusted
                    adjustedReason
                    paidFromDate
                    paidToDate
                    totalMinutesTaught
                    teacher {
                        id
                        profileGroup {
                            estimatedTotalAmountUnpaid
                        }
                        user {
                            firstName
                            lastName
                            email
                        }
                        accountNumber
                    }
                }
            }
        }
    }
`;
