import React, {
    useCallback,
    Suspense,
    useRef,
    useMemo,
    useReducer,
    useEffect,
    useState,
} from "react";
import type {
    ReactElement,
    Dispatch,
    MutableRefObject,
    RefObject,
} from "react";

import { format } from "date-fns";
import {
    Box,
    VStack,
    HStack,
    useToast,
    Button,
    Checkbox,
    PresenceTransition,
    Center,
    Heading,
    Text,
    useTheme,
} from "native-base";
import { useWindowDimensions } from "react-native";
import type { ScrollView } from "react-native";
import { DatePickerModal } from "react-native-paper-dates";
import {
    usePreloadedQuery,
    useMutation,
    usePaginationFragment,
} from "react-relay";
import type { PreloadedQuery } from "react-relay";
import {
    RecyclerListView,
    DataProvider,
    LayoutProvider,
} from "recyclerlistview";

import LoadingBlobs from "pianofunclub-shared/components/Animations/LoadingBlobs";
import Row from "pianofunclub-shared/components/Base/Row";
import ButtonDebounced from "pianofunclub-shared/components/Buttons/ButtonDebounced";
import TeacherMultiSelectModal from "pianofunclub-shared/components/Modals/TeacherMultiSelectModal";
import AlertPopup from "pianofunclub-shared/components/NativeBaseExtended/AlertPopup";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import CustomFlatListSpinner from "pianofunclub-shared/components/Other/CustomFlatListSpinner";
import {
    AddCircleIcon,
    BlacklistedDateIcon,
    TrashIcon,
} from "pianofunclub-shared/components/Other/Icons";

import type {
    CancelAndRescheduleLessonsOnBlacklistedDateMutation,
    CancelAndRescheduleLessonsOnBlacklistedDateMutation$data,
} from "pianofunclub-shared/relay/graphql/schools/__generated__/CancelAndRescheduleLessonsOnBlacklistedDateMutation.graphql";
import type {
    CreateBlacklistedDateMutation,
    CreateBlacklistedDateMutation$data,
} from "pianofunclub-shared/relay/graphql/schools/__generated__/CreateBlacklistedDateMutation.graphql";
import type {
    DeleteBlacklistedDatesMutation,
    DeleteBlacklistedDatesMutation$data,
} from "pianofunclub-shared/relay/graphql/schools/__generated__/DeleteBlacklistedDatesMutation.graphql";
import type {
    EditBlacklistedDateMutation,
    EditBlacklistedDateMutation$data,
} from "pianofunclub-shared/relay/graphql/schools/__generated__/EditBlacklistedDateMutation.graphql";
import type {
    LoadBlacklistedDates_query_blacklistedDates$key,
    LoadBlacklistedDates_query_blacklistedDates$data,
} from "pianofunclub-shared/relay/graphql/schools/__generated__/LoadBlacklistedDates_query_blacklistedDates.graphql";
import type { LoadBlacklistedDatesPaginationQuery } from "pianofunclub-shared/relay/graphql/schools/__generated__/LoadBlacklistedDatesPaginationQuery.graphql";
import type { LoadBlacklistedDatesQuery } from "pianofunclub-shared/relay/graphql/schools/__generated__/LoadBlacklistedDatesQuery.graphql";
import { cancel_and_reschedule_lessons_on_blacklisted_date } from "pianofunclub-shared/relay/graphql/schools/CancelAndRescheduleLessonsOnBlacklistedDate";
import { create_blacklisted_date } from "pianofunclub-shared/relay/graphql/schools/CreateBlacklistedDate";
import { delete_blacklisted_dates } from "pianofunclub-shared/relay/graphql/schools/DeleteBlacklistedDates";
import { edit_blacklisted_date } from "pianofunclub-shared/relay/graphql/schools/EditBlacklistedDate";
import {
    load_blacklisted_dates,
    load_blacklisted_dates_pagination,
} from "pianofunclub-shared/relay/graphql/schools/LoadBlacklistedDates";

import type { Mutable } from "pianofunclub-shared/types";
import { getScaledWindowDimension } from "pianofunclub-shared/utils/converters";
import { pluralize } from "pianofunclub-shared/utils/helpers";
import { createReducer } from "pianofunclub-shared/utils/reducers";
import type { State, Action } from "pianofunclub-shared/utils/reducers";

import type {
    NavigationProps as DefaultTermDatesScreenNavigationProps,
    ReducerValues as DefaultTermDatesScreenReducerValues,
    ReducerTypes as DefaultTermDatesScreenReducerTypes,
} from "pianofunclub-crm/screens/schools/DefaultTermDatesScreen";
import type {
    NavigationProps as SchoolScreenNavigationProps,
    ReducerValues as SchoolScreenReducerValues,
    ReducerTypes as SchoolScreenReducerTypes,
} from "pianofunclub-crm/screens/schools/SchoolScreen";

import BlacklistedDatesTableRow from "pianofunclub-crm/components/ListItems/BlacklistedDatesTableRow";

export type BlacklistedDateData = NonNullable<
    LoadBlacklistedDates_query_blacklistedDates$data["blacklistedDates"]
>["edges"][0];

interface Props {
    dispatchScreenState: Dispatch<
        Action<
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            any,
            SchoolScreenReducerTypes & DefaultTermDatesScreenReducerTypes
        >
    >;
    loadBlacklistedDatesQueryReference:
        | PreloadedQuery<LoadBlacklistedDatesQuery, Record<string, unknown>>
        | undefined
        | null;
    navigation: SchoolScreenNavigationProps &
        DefaultTermDatesScreenNavigationProps;
    schoolId?: string;
    schoolName?: string;
    screenState: State<
        SchoolScreenReducerValues | DefaultTermDatesScreenReducerValues
    >;
    scrollViewRef: RefObject<ScrollView>;
    sideBarWidth: number;
}

interface ContentProps extends Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    blacklistedDateRefs: MutableRefObject<Map<any, any>>;
    dispatchState: Dispatch<Action<ReducerValues, ReducerTypes>>;
    editBlacklistedDate: (variables: {
        blacklistedDateId: string;
        date?: Date;
        reason?: string;
    }) => void;
    loadBlacklistedDatesQueryReference: PreloadedQuery<
        LoadBlacklistedDatesQuery,
        Record<string, unknown>
    >;
    state: State<ReducerValues>;
}

type BlacklistedDateModalInfo = {
    datePickerModalIsOpen?: boolean;
    editingBlacklistedDateId?: string;
    initialDate?: Date;
    initiallySelectedTeacherIds?: string[];
    selectedDate?: Date;
    teacherMultiSelectModalIsOpen?: boolean;
};

type CancelExistingLessonsModalInfo = {
    blacklistedDateId?: string;
    confirmationModalIsOpen?: boolean;
    date?: Date;
    numberOfAffectedExistingLessons?: number;
};

export type ReducerValues = {
    blacklistedDateIds: string[];
    blacklistedDateModalInfo?: BlacklistedDateModalInfo;
    blacklistedDatesConnectionId?: string | undefined;
    cancelExistingLessonsModalInfo?: CancelExistingLessonsModalInfo;
    dataProvider: DataProvider;
    selectedBlacklistedDateIds: string[];
};

export type ReducerTypes =
    | BlacklistedDateModalInfo
    | CancelExistingLessonsModalInfo
    | string[]
    | string
    | DataProvider
    | undefined;

const TABLE_BORDER_COLOR = "surface.400";
const TABLE_BORDER_WIDTH = 1;
const TABLE_BORDER_RADIUS = 10;
const FLEX_ARRAY = [0.2, 1, 4];
const FLEX_ARRAY_SCHOOL = [0.2, 1, 1, 2.5, 3.3];

export const LOAD_X_BLACKLISTED_DATES = 50;

const BlacklistedDatesContent = (props: ContentProps): ReactElement => {
    const {
        blacklistedDateRefs,
        dispatchState,
        editBlacklistedDate,
        loadBlacklistedDatesQueryReference,
        navigation,
        schoolName,
        screenState,
        sideBarWidth,
        state,
    } = props;

    const data = usePreloadedQuery(
        load_blacklisted_dates,
        loadBlacklistedDatesQueryReference,
    );

    const {
        data: blacklistedDatesData,
        hasNext,
        isLoadingNext,
        loadNext,
    } = usePaginationFragment<
        LoadBlacklistedDatesPaginationQuery,
        LoadBlacklistedDates_query_blacklistedDates$key
    >(load_blacklisted_dates_pagination, data);

    useEffect(() => {
        dispatchState({
            input: "blacklistedDatesConnectionId",
            value: blacklistedDatesData.blacklistedDates?.__id,
        });

        if (blacklistedDatesData.blacklistedDates?.edges?.length) {
            dispatchState({
                input: "dataProvider",
                value: state.values.dataProvider.cloneWithRows(
                    blacklistedDatesData.blacklistedDates.edges as Mutable<
                        typeof blacklistedDatesData.blacklistedDates.edges
                    >,
                ),
            });

            let selectableBlacklistedDates =
                blacklistedDatesData.blacklistedDates?.edges;

            // if on school screen, do not include blacklisted dates that apply to all schools
            if (schoolName) {
                selectableBlacklistedDates = selectableBlacklistedDates?.filter(
                    (edge) => edge?.node?.school?.id,
                );
            }

            dispatchState({
                input: "blacklistedDateIds",
                value: selectableBlacklistedDates?.map(
                    (edge) => edge?.node?.id ?? "",
                ),
            });
            // if there is no data, create a fresh DataProvider
        } else {
            dispatchState({
                input: "dataProvider",
                value: new DataProvider((r1, r2) => {
                    return r1 !== r2;
                }),
            });
            dispatchState({
                input: "blacklistedDateIds",
                value: [],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        blacklistedDatesData.blacklistedDates?.edges,
        blacklistedDatesData.blacklistedDates?.__id,
    ]);

    const { scale, width: windowWidth } = useWindowDimensions();

    const { colors } = useTheme();

    const [layoutProvider, setLayoutProvider] = useState(
        new LayoutProvider(
            () => "VSEL",
            (type, dim) => {
                switch (type) {
                    case "VSEL":
                        dim.width = getScaledWindowDimension(
                            windowWidth,
                            scale,
                        );
                        dim.height = 40;
                        break;
                    default:
                        dim.width = 0;
                        dim.height = 0;
                }
            },
        ),
    );

    const onEndReached = useCallback(() => {
        if (hasNext && !isLoadingNext) {
            loadNext(LOAD_X_BLACKLISTED_DATES);
        }
    }, [hasNext, isLoadingNext, loadNext]);

    const renderCount = useRef(0);

    useEffect(() => {
        if (renderCount.current < 1) {
            renderCount.current += 1;
            return;
        }
        // this allows the RLV to resize when the window resizes
        setLayoutProvider(
            new LayoutProvider(
                () => "VSEL",
                (type, dim) => {
                    switch (type) {
                        case "VSEL":
                            dim.width = windowWidth;
                            dim.height = 40;
                            break;
                        default:
                            dim.width = 0;
                            dim.height = 0;
                    }
                },
            ),
        );
    }, [windowWidth, sideBarWidth]);

    const onPressDate = useCallback(
        (variables: { blacklistedDateId: string; date: Date | undefined }) => {
            dispatchState({
                input: "blacklistedDateModalInfo",
                value: {
                    datePickerModalIsOpen: true,
                    editingBlacklistedDateId: variables.blacklistedDateId,
                    initialDate: variables.date,
                },
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const onPressTeachers = useCallback(
        (variables: {
            blacklistedDateId: string;
            teacherIds: string[] | undefined;
        }) => {
            dispatchState({
                input: "blacklistedDateModalInfo",
                value: {
                    teacherMultiSelectModalIsOpen: true,
                    editingBlacklistedDateId: variables.blacklistedDateId,
                    initiallySelectedTeacherIds: variables.teacherIds,
                },
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const checkboxChangeHandler = useCallback(
        (blacklistedDateId: string, isSelected: boolean) => {
            dispatchState({
                input: (values) => {
                    return {
                        ...values,
                        selectedBlacklistedDateIds: isSelected
                            ? [
                                  ...values.selectedBlacklistedDateIds,
                                  blacklistedDateId,
                              ]
                            : values.selectedBlacklistedDateIds.filter(
                                  (id) => id !== blacklistedDateId,
                              ),
                    };
                },
            });
        },
        [dispatchState],
    );

    const onFinishEditingReason = useCallback(
        (
            inputIdentifier?: string | number,
            inputValue?: string,
            isValid?: boolean,
        ) => {
            if (
                isValid &&
                typeof inputIdentifier === "string" &&
                typeof inputValue === "string"
            ) {
                editBlacklistedDate({
                    blacklistedDateId: inputIdentifier,
                    reason: inputValue,
                });
            }
        },
        [editBlacklistedDate],
    );

    const onPressAllSchoolsBlacklistedDateRow = useCallback(() => {
        navigation.navigate("DefaultTermDates", {
            startingYear: screenState.values.startingYear,
            scrollToBottom: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenState.values.startingYear]);

    const rowRenderer = useCallback(
        (
            _: unknown,
            data: BlacklistedDateData,
            index: number,
        ): ReactElement | null => {
            if (!data?.node) {
                return null;
            }

            return (
                <Box
                    borderColor={TABLE_BORDER_COLOR}
                    borderLeftWidth={TABLE_BORDER_WIDTH}>
                    <BlacklistedDatesTableRow
                        ref={(ref) => {
                            if (
                                ref &&
                                data.node?.id &&
                                !blacklistedDateRefs.current.get(data.node.id)
                            ) {
                                blacklistedDateRefs.current.set(
                                    data.node.id,
                                    ref,
                                );
                            }
                        }}
                        cellProps={{
                            hideTopBorder: index === 0,
                            pressableTextProps: {
                                fontFamily: "Poppins-Regular",
                            },
                            textProps: {
                                fontSize: "md",
                                textAlign: "center",
                                fontFamily: "Poppins-Light",
                            },
                            px: 2,
                        }}
                        checkboxChangeHandler={checkboxChangeHandler}
                        data={data.node}
                        flexArray={schoolName ? FLEX_ARRAY_SCHOOL : FLEX_ARRAY}
                        isSchoolScreen={Boolean(schoolName)}
                        onFinishEditingReason={onFinishEditingReason}
                        onPressAllSchoolsBlacklistedDateRow={
                            onPressAllSchoolsBlacklistedDateRow
                        }
                        onPressDate={onPressDate}
                        onPressTeachers={onPressTeachers}
                        rowHeight={10}
                        tableBorderColor={TABLE_BORDER_COLOR}
                        tableBorderWidth={TABLE_BORDER_WIDTH}
                    />
                </Box>
            );
        },
        [
            blacklistedDateRefs,
            checkboxChangeHandler,
            onFinishEditingReason,
            onPressAllSchoolsBlacklistedDateRow,
            onPressDate,
            onPressTeachers,
            schoolName,
        ],
    );

    const renderFooter = useCallback(() => {
        return isLoadingNext ? (
            <CustomFlatListSpinner
                borderColor={TABLE_BORDER_COLOR}
                borderTopWidth={1}
                position="relative"
                pt="8"
                top="0"
            />
        ) : null;
    }, [isLoadingNext]);

    return (
        <>
            {state.values.dataProvider.getSize() > 0 ? (
                <RecyclerListView
                    canChangeSize
                    dataProvider={state.values.dataProvider}
                    layoutProvider={layoutProvider}
                    onEndReached={onEndReached}
                    renderFooter={renderFooter}
                    rowRenderer={rowRenderer}
                    scrollViewProps={{
                        contentContainerStyle: {
                            borderColor:
                                // @ts-expect-error can't index with variable
                                colors?.[TABLE_BORDER_COLOR.split(".")[0]][
                                    TABLE_BORDER_COLOR.split(".")[1]
                                ],
                            borderBottomWidth:
                                blacklistedDatesData.blacklistedDates?.edges
                                    .length && !isLoadingNext
                                    ? TABLE_BORDER_WIDTH
                                    : 0,
                            marginBottom: 40,
                            overflow: "hidden",
                            borderBottomRadius: TABLE_BORDER_RADIUS,
                        },
                        showsVerticalScrollIndicator: false,
                    }}
                    style={{
                        flex: 1,
                        minHeight: 1,
                    }}
                    suppressBoundedSizeException
                    useWindowScroll
                />
            ) : null}
        </>
    );
};

const BlacklistedDates = (props: Props): ReactElement => {
    const {
        dispatchScreenState,
        loadBlacklistedDatesQueryReference,
        schoolId,
        schoolName,
        screenState,
        scrollViewRef,
    } = props;

    const initialState = useMemo(() => {
        return {
            values: {
                blacklistedDateIds: [] as string[],
                selectedBlacklistedDateIds: [] as string[],
                dataProvider: new DataProvider((r1, r2) => {
                    return r1 !== r2;
                }),
            },
        };
    }, []);

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [state, dispatchState] = useReducer(reducer, initialState);

    const toast = useToast();

    const blacklistedDateRefs = useRef(new Map());

    const [commitCreateBlacklistedDate] =
        useMutation<CreateBlacklistedDateMutation>(create_blacklisted_date);

    const createBlacklistedDate = useCallback(
        (variables: { date: Date; teacherIds?: string[] | undefined }) => {
            const createBlacklistedDateConfig = {
                variables: {
                    connections: state.values.blacklistedDatesConnectionId
                        ? [state.values.blacklistedDatesConnectionId]
                        : [],
                    input: {
                        date: variables.date.toDateString(),
                        schoolId,
                        teacherIds: variables.teacherIds,
                    },
                },
                onCompleted: (response: CreateBlacklistedDateMutation$data) => {
                    if (!response.createBlacklistedDate?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't create blacklisted date"}
                                    toast={toast}
                                />
                            ),
                        });
                    } else if (
                        response.createBlacklistedDate.blacklistedDate?.id &&
                        response.createBlacklistedDate
                            ?.numberOfAffectedExistingLessons
                    ) {
                        dispatchState({
                            input: "cancelExistingLessonsModalInfo",
                            value: {
                                confirmationModalIsOpen: true,
                                numberOfAffectedExistingLessons:
                                    response.createBlacklistedDate
                                        ?.numberOfAffectedExistingLessons,
                                date: variables.date,
                                blacklistedDateId:
                                    response.createBlacklistedDate
                                        .blacklistedDate?.id,
                            },
                        });
                    } else {
                        setTimeout(() => {
                            scrollViewRef.current?.scrollToEnd();
                        }, 500);
                    }
                },
            };

            commitCreateBlacklistedDate(createBlacklistedDateConfig);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            commitCreateBlacklistedDate,
            schoolId,
            state.values.blacklistedDatesConnectionId,
        ],
    );

    const [commitEditBlacklistedDate] =
        useMutation<EditBlacklistedDateMutation>(edit_blacklisted_date);

    const editBlacklistedDate = useCallback(
        (variables: {
            blacklistedDateId: string;
            date?: Date;
            reason?: string;
            teacherIds?: string[] | undefined;
        }) => {
            const editBlacklistedDateConfig = {
                variables: {
                    input: {
                        blacklistedDateId: variables.blacklistedDateId,
                        date: variables.date?.toDateString(),
                        reason: variables.reason,
                        teacherIds: variables.teacherIds,
                    },
                },
                onCompleted: (response: EditBlacklistedDateMutation$data) => {
                    if (!response?.editBlacklistedDate?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't edit blacklisted date"}
                                    toast={toast}
                                />
                            ),
                        });
                    } else if (
                        response.editBlacklistedDate
                            ?.numberOfAffectedExistingLessons
                    ) {
                        dispatchState({
                            input: "cancelExistingLessonsModalInfo",
                            value: {
                                confirmationModalIsOpen: true,
                                numberOfAffectedExistingLessons:
                                    response.editBlacklistedDate
                                        ?.numberOfAffectedExistingLessons,
                                date: variables.date,
                                blacklistedDateId:
                                    response.editBlacklistedDate.blacklistedDate
                                        ?.id,
                            },
                        });
                    }
                },
            };

            commitEditBlacklistedDate(editBlacklistedDateConfig);
        },
        [commitEditBlacklistedDate, toast],
    );

    const [commitDeleteBlacklistedDates] =
        useMutation<DeleteBlacklistedDatesMutation>(delete_blacklisted_dates);

    const deleteBlacklistedDates = useCallback(
        (variables: { blacklistedDateIds: string[] }) => {
            const deleteBlacklistedDatesConfig = {
                variables: {
                    connections: state.values.blacklistedDatesConnectionId
                        ? [state.values.blacklistedDatesConnectionId]
                        : [],
                    input: {
                        blacklistedDateIds: variables.blacklistedDateIds,
                    },
                },
                optimisticResponse: {
                    deleteBlacklistedDates: {
                        success: true,
                        deletedblacklistedDateIds: variables.blacklistedDateIds,
                    },
                },
                onCompleted: (
                    response: DeleteBlacklistedDatesMutation$data,
                ) => {
                    dispatchState({
                        input: "selectedBlacklistedDateIds",
                        value: [],
                    });
                    blacklistedDateRefs.current.forEach((ref) => {
                        ref.setIsChecked(false);
                    });

                    if (!response?.deleteBlacklistedDates?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't delete blacklisted dates"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitDeleteBlacklistedDates(deleteBlacklistedDatesConfig);
        },
        [
            commitDeleteBlacklistedDates,
            state.values.blacklistedDatesConnectionId,
            toast,
        ],
    );

    const [
        commitCancelAndRescheduleLessonsOnBlacklistedDate,
        cancelAndRescheduleLessonsOnBlacklistedDateInFlight,
    ] = useMutation<CancelAndRescheduleLessonsOnBlacklistedDateMutation>(
        cancel_and_reschedule_lessons_on_blacklisted_date,
    );

    const cancelAndRescheduleLessonsOnBlacklistedDate = useCallback(
        (variables: { blacklistedDateId: string; closePopup: () => void }) => {
            const cancelAndRescheduleLessonsOnBlacklistedDateConfig = {
                variables: {
                    input: {
                        blacklistedDateId: variables.blacklistedDateId,
                    },
                },
                onCompleted: (
                    response: CancelAndRescheduleLessonsOnBlacklistedDateMutation$data,
                ) => {
                    if (
                        response?.cancelAndRescheduleLessonsOnBlacklistedDate
                            ?.success
                    ) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={
                                        "Successfully cancelled & rescheduled lessons"
                                    }
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={
                                        "Couldn't cancel and rescheduled affected lessons"
                                    }
                                    toast={toast}
                                />
                            ),
                        });
                    }

                    variables.closePopup();
                },
            };

            commitCancelAndRescheduleLessonsOnBlacklistedDate(
                cancelAndRescheduleLessonsOnBlacklistedDateConfig,
            );
        },
        [commitCancelAndRescheduleLessonsOnBlacklistedDate, toast],
    );

    const selectAllCheckboxChangeHandler = useCallback(
        (isSelected: boolean) => {
            blacklistedDateRefs.current.forEach((ref) => {
                ref.setIsChecked(isSelected);
            });
            dispatchState({
                input: "selectedBlacklistedDateIds",
                value: isSelected ? state.values.blacklistedDateIds : [],
            });
        },
        [state.values.blacklistedDateIds],
    );

    const selectDateHandler = useCallback(
        (params: { date?: Date }) => {
            if (!params.date) {
                return;
            }

            if (
                !state.values.blacklistedDateModalInfo?.editingBlacklistedDateId
            ) {
                // if on school screen, move to second step of process (selecting teachers)
                if (schoolName) {
                    dispatchState({
                        input: "blacklistedDateModalInfo",
                        value: {
                            ...state.values.blacklistedDateModalInfo,
                            selectedDate: params.date,
                            teacherMultiSelectModalIsOpen: true,
                            datePickerModalIsOpen: false,
                        },
                    });
                    // otherwise create the blacklisted date
                } else {
                    createBlacklistedDate({
                        date: params.date,
                    });
                    dispatchState({
                        input: "blacklistedDateModalInfo",
                        value: undefined,
                    });
                }
            } else {
                editBlacklistedDate({
                    blacklistedDateId:
                        state.values.blacklistedDateModalInfo
                            ?.editingBlacklistedDateId,
                    date: params.date,
                    reason: "",
                });
                dispatchState({
                    input: "blacklistedDateModalInfo",
                    value: undefined,
                });
            }
        },
        [
            createBlacklistedDate,
            editBlacklistedDate,
            schoolName,
            state.values.blacklistedDateModalInfo,
        ],
    );

    const tableHeaders = useMemo(() => {
        return [
            {
                data: (
                    <Checkbox
                        isChecked={
                            state.values.selectedBlacklistedDateIds.length !=
                                0 &&
                            state.values.blacklistedDateIds.length <=
                                state.values.selectedBlacklistedDateIds
                                    .length &&
                            state.values.blacklistedDateIds.every((id) =>
                                state.values.selectedBlacklistedDateIds.includes(
                                    id,
                                ),
                            )
                        }
                        onChange={selectAllCheckboxChangeHandler}
                        size="md"
                        value="SELECT_ALL"
                    />
                ),
                onPress: () => {
                    return;
                },
            },
            {
                data: "Date",
            },
            ...(schoolName
                ? [
                      {
                          data: "All schools?",
                      },
                      {
                          data: "Teacher(s)",
                      },
                  ]
                : []),
            {
                data: "Reason",
            },
        ];
    }, [
        schoolName,
        selectAllCheckboxChangeHandler,
        state.values.blacklistedDateIds,
        state.values.selectedBlacklistedDateIds,
    ]);

    const renderHeader = useMemo(() => {
        return (
            <VStack mx="-6" px="6" space="4">
                <HStack ml="1" space="3">
                    <BlacklistedDateIcon color="primary.600" size="xl" />
                    <Heading color="primary.600" fontSize="xl">
                        {`Blacklisted Dates${!schoolName ? " (All Schools)" : ""}`}
                    </Heading>
                </HStack>
                <Text
                    fontFamily="Poppins-Regular"
                    fontSize="md"
                    lineHeight="2xl"
                    mb="2">
                    {`Lessons will not be scheduled${
                        !schoolName ? " at any school" : ""
                    } on the dates listed here.\nIf there are already lessons on that date, you will have the opportunity to cancel and reschedule the lessons.`}
                </Text>
                <HStack justifyContent="space-between" mb="4">
                    <Button
                        _hover={{ bg: "primary.500" }}
                        _pressed={{ bg: "primary.600" }}
                        _text={{ fontSize: "17" }}
                        bg="primary.400"
                        leftIcon={<AddCircleIcon size="md" />}
                        onPress={() => {
                            dispatchState({
                                input: "blacklistedDateModalInfo",
                                value: {
                                    datePickerModalIsOpen: true,
                                },
                            });
                        }}
                        px="4"
                        shadow={1}>
                        Create Blacklisted Date
                    </Button>
                    <PresenceTransition
                        animate={{
                            opacity: 1,
                            scale: 1,
                            transition: {
                                duration: 250,
                            },
                        }}
                        initial={{
                            opacity: 0,
                            scale: 0,
                        }}
                        visible={
                            state.values.selectedBlacklistedDateIds.length > 0
                        }>
                        <Button
                            _text={{ fontSize: "17" }}
                            colorScheme="error"
                            leftIcon={
                                <Center size="5">
                                    <TrashIcon color="surface.100" size="5" />
                                </Center>
                            }
                            onPress={() =>
                                deleteBlacklistedDates({
                                    blacklistedDateIds:
                                        state.values.selectedBlacklistedDateIds,
                                })
                            }
                            pr="4"
                            shadow={1}>
                            {`Delete ${
                                state.values.selectedBlacklistedDateIds.length
                            } Date${
                                state.values.selectedBlacklistedDateIds
                                    .length !== 1
                                    ? "s"
                                    : ""
                            }`}
                        </Button>
                    </PresenceTransition>
                </HStack>
                <Box
                    bg="surface.100"
                    minWidth={!schoolName ? 800 : undefined}
                    width={!schoolName ? "50%" : "100%"}>
                    <Box
                        bg="primary.50"
                        borderColor={TABLE_BORDER_COLOR}
                        borderTopRadius={TABLE_BORDER_RADIUS}
                        borderWidth={TABLE_BORDER_WIDTH}>
                        <Row
                            cellProps={{
                                px: 2,
                                hideTopBorder: true,
                                hideOuterSideBorder: true,
                                textProps: {
                                    fontSize: "md",
                                    textAlign: "center",
                                },
                            }}
                            data={tableHeaders}
                            flexArray={
                                schoolName ? FLEX_ARRAY_SCHOOL : FLEX_ARRAY
                            }
                            rowHeight={10}
                            rowIndex={0}
                            tableBorderColor={TABLE_BORDER_COLOR}
                            tableBorderWidth={TABLE_BORDER_WIDTH}
                        />
                    </Box>
                </Box>
            </VStack>
        );
    }, [
        deleteBlacklistedDates,
        schoolName,
        state.values.selectedBlacklistedDateIds,
        tableHeaders,
    ]);

    const renderDatePickerModal = useMemo(() => {
        return (
            <DatePickerModal
                animationType="fade"
                date={
                    state.values.blacklistedDateModalInfo?.initialDate ??
                    new Date()
                }
                endYear={Number(screenState.values.startingYear) + 1}
                label="Select Date"
                locale="en"
                mode="single"
                onConfirm={selectDateHandler}
                onDismiss={() =>
                    dispatchState({
                        input: "blacklistedDateModalInfo",
                        value: undefined,
                    })
                }
                saveLabel={
                    state.values.blacklistedDateModalInfo
                        ?.editingBlacklistedDateId
                        ? "Update"
                        : !schoolName
                          ? "Create"
                          : "Next"
                }
                startYear={Number(screenState.values.startingYear)}
                uppercase={false}
                visible={Boolean(
                    state.values.blacklistedDateModalInfo
                        ?.datePickerModalIsOpen,
                )}
            />
        );
    }, [
        schoolName,
        screenState.values.startingYear,
        selectDateHandler,
        state.values.blacklistedDateModalInfo?.datePickerModalIsOpen,
        state.values.blacklistedDateModalInfo?.editingBlacklistedDateId,
        state.values.blacklistedDateModalInfo?.initialDate,
    ]);

    const renderTeacherMultiSelectModal = useMemo(() => {
        return (
            <TeacherMultiSelectModal
                allowNoSelection
                buttonText={
                    !state.values.blacklistedDateModalInfo
                        ?.editingBlacklistedDateId
                        ? "Create"
                        : "Update"
                }
                hideCountFromButton
                hideModal={() =>
                    dispatchState({
                        input: "blacklistedDateModalInfo",
                        value: undefined,
                    })
                }
                includeBackButton={
                    !state.values.blacklistedDateModalInfo
                        ?.editingBlacklistedDateId
                }
                initiallySelectAllTeachers
                initiallySelectedTeacherIds={
                    state.values.blacklistedDateModalInfo
                        ?.initiallySelectedTeacherIds
                }
                modalBodyProps={{
                    _scrollview: {
                        contentContainerStyle: {
                            justifyContent: "center",
                            flexGrow: 1,
                        },
                    },
                }}
                onBackButtonPress={() =>
                    dispatchState({
                        input: "blacklistedDateModalInfo",
                        value: {
                            ...state.values.blacklistedDateModalInfo,
                            teacherMultiSelectModalIsOpen: false,
                            datePickerModalIsOpen: true,
                        },
                    })
                }
                onSave={({ hasSelectedAllTeachers, teacherIds }) => {
                    dispatchState({
                        input: "blacklistedDateModalInfo",
                        value: {
                            ...state.values.blacklistedDateModalInfo,
                            teacherMultiSelectModalIsOpen: false,
                        },
                    });

                    if (
                        !state.values.blacklistedDateModalInfo
                            ?.editingBlacklistedDateId
                    ) {
                        if (
                            state.values.blacklistedDateModalInfo?.selectedDate
                        ) {
                            createBlacklistedDate({
                                date: state.values.blacklistedDateModalInfo
                                    ?.selectedDate,
                                // do not set teacher ids if all teachers are selected
                                // these are just treated as teacher-independent blacklisted dates
                                teacherIds: !hasSelectedAllTeachers
                                    ? teacherIds
                                    : undefined,
                            });
                        }
                    } else {
                        editBlacklistedDate({
                            blacklistedDateId:
                                state.values.blacklistedDateModalInfo
                                    ?.editingBlacklistedDateId,
                            teacherIds: !hasSelectedAllTeachers
                                ? teacherIds
                                : undefined,
                        });
                    }

                    setTimeout(() => {
                        dispatchState({
                            input: "blacklistedDateModalInfo",
                            value: undefined,
                        });
                    }, 500);
                }}
                schoolName={schoolName}
                showModal={Boolean(
                    state.values.blacklistedDateModalInfo
                        ?.teacherMultiSelectModalIsOpen,
                )}
                title="Select Teachers for Blacklisted Date"
            />
        );
    }, [
        createBlacklistedDate,
        editBlacklistedDate,
        schoolName,
        state.values.blacklistedDateModalInfo,
    ]);

    const renderCancelExistingLessonsConfirmationModal = useMemo(() => {
        const closePopup = () => {
            dispatchState({
                input: "cancelExistingLessonsModalInfo",
                value: {
                    ...state.values.cancelExistingLessonsModalInfo,
                    confirmationModalIsOpen: false,
                },
            });

            setTimeout(() => {
                dispatchState({
                    input: "cancelExistingLessonsModalInfo",
                    value: undefined,
                });
            }, 500);
        };

        return (
            <AlertPopup
                alertIsOpen={Boolean(
                    state.values.cancelExistingLessonsModalInfo
                        ?.confirmationModalIsOpen &&
                        state.values.cancelExistingLessonsModalInfo
                            ?.numberOfAffectedExistingLessons,
                )}
                body={`There ${
                    state.values.cancelExistingLessonsModalInfo
                        ?.numberOfAffectedExistingLessons === 1
                        ? "is"
                        : "are"
                } ${
                    state.values.cancelExistingLessonsModalInfo
                        ?.numberOfAffectedExistingLessons
                } existing ${pluralize(
                    "lesson",
                    state.values.cancelExistingLessonsModalInfo
                        ?.numberOfAffectedExistingLessons,
                )} affected by this blacklisted date${
                    state.values.cancelExistingLessonsModalInfo?.date
                        ? " on " +
                          format(
                              state.values.cancelExistingLessonsModalInfo?.date,
                              "EEE do MMM yyyy",
                          )
                        : ""
                }. If possible, lessons will be rescheduled for the next available week in the term.`}
                header="Would you like to cancel and reschedule existing affected lessons?"
                setAlertIsOpen={closePopup}>
                <Button
                    _text={{ fontSize: "lg" }}
                    colorScheme="surface"
                    height="50"
                    minWidth="56"
                    onPress={closePopup}
                    width="25%">
                    No, do nothing
                </Button>
                <ButtonDebounced
                    _loading={{ opacity: 1 }}
                    _text={{ fontSize: "lg" }}
                    colorScheme="primary"
                    height="50"
                    isLoading={
                        cancelAndRescheduleLessonsOnBlacklistedDateInFlight
                    }
                    minWidth="56"
                    onPress={() => {
                        if (
                            state.values.cancelExistingLessonsModalInfo
                                ?.blacklistedDateId
                        ) {
                            cancelAndRescheduleLessonsOnBlacklistedDate({
                                blacklistedDateId:
                                    state.values.cancelExistingLessonsModalInfo
                                        ?.blacklistedDateId,
                                closePopup,
                            });
                        }
                    }}
                    width="25%">
                    Yes, go ahead
                </ButtonDebounced>
            </AlertPopup>
        );
    }, [
        cancelAndRescheduleLessonsOnBlacklistedDate,
        cancelAndRescheduleLessonsOnBlacklistedDateInFlight,
        state.values.cancelExistingLessonsModalInfo,
    ]);

    return (
        <Box flex={1}>
            {renderHeader}
            <Box
                minWidth={!schoolName ? 800 : undefined}
                width={!schoolName ? "50%" : "100%"}>
                {loadBlacklistedDatesQueryReference ? (
                    <Suspense
                        fallback={
                            <LoadingBlobs minHeight={200}>
                                Loading Blacklisted Dates...
                            </LoadingBlobs>
                        }>
                        <BlacklistedDatesContent
                            {...props}
                            blacklistedDateRefs={blacklistedDateRefs}
                            dispatchScreenState={dispatchScreenState}
                            dispatchState={dispatchState}
                            editBlacklistedDate={editBlacklistedDate}
                            loadBlacklistedDatesQueryReference={
                                loadBlacklistedDatesQueryReference
                            }
                            state={state}
                        />
                    </Suspense>
                ) : (
                    <LoadingBlobs minHeight={200}>
                        Loading Blacklisted Dates...
                    </LoadingBlobs>
                )}
            </Box>
            {renderDatePickerModal}
            {renderTeacherMultiSelectModal}
            {renderCancelExistingLessonsConfirmationModal}
        </Box>
    );
};

export default React.memo(BlacklistedDates);
