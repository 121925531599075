import React from "react";
import type { ReactElement } from "react";

import type { RouteProp } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import type { StackNavigationProp } from "@react-navigation/stack";
import { useTheme } from "native-base";

import ForgotPasswordScreen, {
    screenOptions as forgotPasswordScreenOptions,
} from "../screens/auth/ForgotPasswordScreen";
import LoginScreen, {
    screenOptions as oginScreenOptions,
} from "../screens/auth/LoginScreen";
import ResetPasswordScreen, {
    screenOptions as resetPasswordScreenOptions,
} from "../screens/auth/ResetPasswordScreen";

import defaultNavOptions from "./utils/DefaultNavigationOptions";

export type AuthNavigatorParamList = {
    ForgotPassword: { email?: string };
    Login: undefined;
    ResetPassword: { token: string };
};

export type AuthStackNavigatorProps<T extends keyof AuthNavigatorParamList> =
    StackNavigationProp<AuthNavigatorParamList, T>;

export type AuthStackRouteProps<T extends keyof AuthNavigatorParamList> =
    RouteProp<AuthNavigatorParamList, T>;

const AuthStackNavigator = createStackNavigator<AuthNavigatorParamList>();

const AuthNavigator = (): ReactElement => {
    const { colors } = useTheme();

    return (
        <AuthStackNavigator.Navigator
            screenOptions={{
                ...defaultNavOptions,
                cardStyle: { backgroundColor: colors.primary["800"] },
            }}
        >
            <AuthStackNavigator.Screen
                component={LoginScreen}
                name="Login"
                options={oginScreenOptions}
            />
            <AuthStackNavigator.Screen
                component={ForgotPasswordScreen}
                name="ForgotPassword"
                options={forgotPasswordScreenOptions}
            />
            <AuthStackNavigator.Screen
                component={ResetPasswordScreen}
                name="ResetPassword"
                options={resetPasswordScreenOptions}
            />
        </AuthStackNavigator.Navigator>
    );
};

export default AuthNavigator;
