/**
 * @generated SignedSource<<c89847f1ac2d6157d1760ac0507ef55e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SendReminderForUnpaidInvoiceInput = {
  clientMutationId?: string | null;
  invoiceId: string;
  sendByEmail?: boolean | null;
};
export type SendReminderForUnpaidInvoiceMutation$variables = {
  input: SendReminderForUnpaidInvoiceInput;
};
export type SendReminderForUnpaidInvoiceMutation$data = {
  readonly sendReminderForUnpaidInvoice: {
    readonly errors: any | null;
    readonly invoice: {
      readonly id: string;
      readonly reminder1SentTimestamp: string | null;
      readonly reminder2SentTimestamp: string | null;
      readonly reminder3SentTimestamp: string | null;
    } | null;
    readonly success: boolean | null;
  } | null;
};
export type SendReminderForUnpaidInvoiceMutation = {
  response: SendReminderForUnpaidInvoiceMutation$data;
  variables: SendReminderForUnpaidInvoiceMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SendReminderForUnpaidInvoicePayload",
    "kind": "LinkedField",
    "name": "sendReminderForUnpaidInvoice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceNode",
        "kind": "LinkedField",
        "name": "invoice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reminder1SentTimestamp",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reminder2SentTimestamp",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reminder3SentTimestamp",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendReminderForUnpaidInvoiceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendReminderForUnpaidInvoiceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d2faabbf1fbbbfcaf79903a8f5e60d8c",
    "id": null,
    "metadata": {},
    "name": "SendReminderForUnpaidInvoiceMutation",
    "operationKind": "mutation",
    "text": "mutation SendReminderForUnpaidInvoiceMutation(\n  $input: SendReminderForUnpaidInvoiceInput!\n) {\n  sendReminderForUnpaidInvoice(input: $input) {\n    success\n    errors\n    invoice {\n      id\n      reminder1SentTimestamp\n      reminder2SentTimestamp\n      reminder3SentTimestamp\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "93ac7308d3e4e790dcacbbb5af04937d";

export default node;
