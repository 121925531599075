import React from "react";
import type { ReactElement, ReactNode, ComponentProps } from "react";

import { VStack, ScrollView, Button } from "native-base";

import {
    CollapseIcon,
    ExpandIcon,
} from "pianofunclub-shared/components/Other/Icons";

import BackButton from "pianofunclub-crm/components/Buttons/BackButton";

type VStackProps = ComponentProps<typeof VStack>;
type ScrollViewProps = ComponentProps<typeof ScrollView>;

interface Props extends VStackProps {
    children?: ReactNode;
    isCollapsed: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    navigation: any;
    scrollViewProps?: ScrollViewProps;
    setIsCollapsed?: (isCollapsed: boolean) => void;
}

const SideBar = (props: Props): ReactElement => {
    const { isCollapsed, navigation, scrollViewProps, setIsCollapsed } = props;

    return (
        <ScrollView
            bg="surface.200"
            borderColor="surface.400"
            borderRightWidth={1}
            bottom="0"
            contentContainerStyle={{
                flexGrow: 1,
                flex: 1,
            }}
            left="0"
            position="absolute"
            pt="70"
            showsVerticalScrollIndicator={false}
            top="-70"
            {...scrollViewProps}
        >
            <VStack
                alignItems="center"
                flex={1}
                flexGrow={1}
                p="6"
                space={isCollapsed ? "4" : undefined}
                {...props}
            >
                <BackButton
                    onPress={() => navigation.goBack()}
                    {...(!isCollapsed
                        ? {
                              left: "3",
                              top: "3",
                              position: "absolute",
                              width: "90",
                              mt: "70",
                          }
                        : { hideText: true, height: 8, width: 8, mt: "60" })}
                />
                {setIsCollapsed ? (
                    <Button
                        _hover={{ bg: "transparent", opacity: 0.8 }}
                        _pressed={{ bg: "transparent", opacity: 0.7 }}
                        colorScheme="muted"
                        height={8}
                        leftIcon={
                            isCollapsed ? (
                                <ExpandIcon size={5} />
                            ) : (
                                <CollapseIcon size={5} />
                            )
                        }
                        onPress={() => setIsCollapsed?.(!isCollapsed)}
                        variant="ghost"
                        width={8}
                        {...(!isCollapsed
                            ? {
                                  right: "3",
                                  top: "3.5",
                                  position: "absolute",
                                  mt: "70",
                              }
                            : {})}
                    />
                ) : null}
                {!isCollapsed ? props.children : null}
            </VStack>
        </ScrollView>
    );
};

export default React.memo(SideBar);
