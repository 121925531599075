import { graphql } from "react-relay";

export const load_payrates = graphql`
    query LoadPayratesQuery($first: Int!, $after: String) {
        ...LoadPayrates_query_payrates
            @arguments(orderBy: "-start_date", first: $first, after: $after)
    }
`;

export const load_payrates_pagination = graphql`
    fragment LoadPayrates_query_payrates on Query
    @argumentDefinitions(
        orderBy: { type: "String" }
        first: { type: "Int!" }
        after: { type: "String" }
    )
    @refetchable(queryName: "LoadPayratesPaginationQuery") {
        payrates(
            orderBy: $orderBy
            first: $first
            after: $after
        ) @connection(key: "LoadPayrates_query_payrates") {
            __id
            edges {
                node {
                    id
                    hourlyRate
                    startDate
                }
            }
        }
    }
`;
