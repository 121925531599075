import { graphql } from "react-relay";

export const add_line_item = graphql`
    mutation AddLineItemMutation(
        $connections: [ID!]!
        $input: AddLineItemInput!
    ) {
        addLineItem(input: $input) {
            success
            errors
            lineItem
                @appendNode(
                    connections: $connections
                    edgeTypeName: "HomeworkNodeEdge"
                ) {
                id
                itemType
                quantity
                unitAmount
                timePeriodDescription
                pupilDescription
                instrumentDescription
                typeDescription
                pupil {
                    id
                }
            }
            invoice {
                id
                lastModified
                totalAmount
                status
            }
        }
    }
`;
