import React from "react";
import type { ReactElement, ComponentProps } from "react";

import { Center, Spinner } from "native-base";

type CenterProps = ComponentProps<typeof Center>;

const CustomFlatListSpinner = (props: CenterProps): ReactElement => {
    return (
        <Center pb="1" position="absolute" top="3%" width="100%" {...props}>
            <Center
                bg="white"
                borderRadius="full"
                height="10"
                shadow={5}
                width="10"
            >
                <Spinner color="primary.500" size="sm" />
            </Center>
        </Center>
    );
};

export default React.memo(CustomFlatListSpinner);
