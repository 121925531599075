import React, { useState, useImperativeHandle, forwardRef } from "react";
import type { ReactElement, ComponentProps } from "react";

import { Checkbox, Pressable, Text } from "native-base";
import type { Box } from "native-base";

type BoxProps = ComponentProps<typeof Box>;

interface Props extends BoxProps {
    id: string;
    index: number;
    initiallySelected: boolean;
    itemPressHandler: (varibales: { id: string; label?: string }) => void;
    label: string;
}

const MultiSelectRow = forwardRef((props: Props, ref): ReactElement => {
    const { id, index, initiallySelected, itemPressHandler, label } = props;

    const [checkBoxIsChecked, setCheckBoxIsChecked] =
        useState(initiallySelected);

    useImperativeHandle(ref, () => ({
        setIsChecked: (isChecked: boolean) => {
            setCheckBoxIsChecked(isChecked);
        },
    }));

    return (
        <Pressable
            key={index}
            _hover={{ opacity: 0.8 }}
            _pressed={{ opacity: 0.7 }}
            alignItems="center"
            bg="surface.50"
            borderBottomWidth={1}
            borderColor="surface.200"
            flexDirection="row"
            onPress={() => {
                setCheckBoxIsChecked(!checkBoxIsChecked);
                itemPressHandler({ id, label });
            }}
            px="4"
            py="2"
        >
            <Checkbox
                isChecked={checkBoxIsChecked}
                mr="3"
                pointerEvents="none"
                size="sm"
                value={id}
            />
            <Text
                flex={1}
                fontFamily="Poppins-Regular"
                fontSize="md"
                isTruncated
                numberOfLines={1}
            >
                {label}
            </Text>
        </Pressable>
    );
});

MultiSelectRow.displayName = "MultiSelectRow";

export default MultiSelectRow;
