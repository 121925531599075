import { graphql } from "react-relay";

export const update_email = graphql`
    mutation UpdateEmailMutation($input: UpdateEmailInput!) {
        updateEmail(input: $input) {
            success
            errors
            user {
                id
                email
                username
            }
        }
    }
`;
