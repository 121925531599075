import { graphql } from "react-relay";

export const match_card_payments_to_invoices = graphql`
    mutation MatchCardPaymentsToInvoicesMutation(
        $input: MatchCardPaymentsToInvoicesInput!
        $startingYear: Int!
        $block: Int!
    ) {
        matchCardPaymentsToInvoices(input: $input) {
            success
            errors
            profile {
                id
                totalAmountPaid(startingYear: $startingYear, block: $block)
                totalInvoicesUnpaid(startingYear: $startingYear, block: $block)
                profileGroup {
                    id
                    matchPaymentsProgress
                }
            }
        }
    }
`;
