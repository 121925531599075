import React, { useRef, useCallback, useMemo, useReducer } from "react";
import type { ReactElement } from "react";

import { Modal, VStack, Text, Box, HStack } from "native-base";

import ButtonDebounced from "pianofunclub-shared/components/Buttons/ButtonDebounced";
import CurrencyInput from "pianofunclub-shared/components/Inputs/CurrencyInput";
import TextInput from "pianofunclub-shared/components/Inputs/TextInput";
import Select from "pianofunclub-shared/components/NativeBaseExtended/Select";

import { LESSON_TYPES, NO_STAGE } from "pianofunclub-shared/utils/constants";
import { isNumeric } from "pianofunclub-shared/utils/converters";
import { createFormReducer } from "pianofunclub-shared/utils/reducers";

interface Props {
    availableStages: number[];
    createLessonStage: (
        variables: {
            costPerLesson: number;
            lessonDuration: number;
            lessonType: string;
            stage: number | undefined;
        },
        onComplete?: () => void,
    ) => void;
    createLessonStageInFlight: boolean;
    setShowModal: (isHidden: boolean) => void;
    showModal: boolean;
}

type ReducerValues = {
    costPerLesson?: number;
    lessonDuration?: number;
    lessonType?: string;
    stage?: string;
};

type ReducerTypes = boolean | number | string | undefined;

const CreateLessonStageModal = (props: Props): ReactElement => {
    const {
        availableStages,
        createLessonStage,
        createLessonStageInFlight,
        setShowModal,
        showModal,
    } = props;

    const initialState = useMemo(() => {
        return {
            inputValues: {
                stage: undefined,
                lessonType: undefined,
                lessonDuration: undefined,
                costPerLesson: undefined,
            },
            inputValidities: {
                stage: true,
                lessonType: false,
                lessonDuration: false,
                costPerLesson: false,
            },
            formIsValid: false,
            formIsEdited: false,
        };
    }, []);

    const reducer = createFormReducer<ReducerValues, ReducerTypes>(
        initialState,
    );
    const [state, dispatchState] = useReducer(reducer, initialState);

    const lessonDurationInputRef = useRef<{
        clear: () => void;
        focus: () => void;
    }>();
    const costPerLessonInputRef = useRef<{
        clear: () => void;
        focus: () => void;
    }>();
    const createButtonRef = useRef<{
        focus: () => void;
    }>();

    const selectChangeHandler = useCallback(
        (inputIdentifier: string, itemValue: string) => {
            dispatchState({
                input: inputIdentifier,
                value: itemValue !== "None" ? itemValue : undefined,
                isValid: true,
            });
        },
        [],
    );

    const inputChangeHandler = useCallback(
        (
            inputIdentifier?: string | number,
            inputValue?: string,
            isValid?: boolean,
        ) => {
            if (
                typeof inputIdentifier === "string" &&
                typeof inputValue === "string"
            ) {
                const cleanedInput = inputValue.replace("£", "");
                dispatchState({
                    input: inputIdentifier,
                    value: isNumeric(cleanedInput)
                        ? parseFloat(cleanedInput)
                        : undefined,
                    isValid: isValid && isNumeric(cleanedInput),
                });
            }
        },
        [],
    );

    const createLessonStageHandler = useCallback(() => {
        createButtonRef.current?.focus();
        if (
            state.formIsValid &&
            typeof state.inputValues.lessonType === "string" &&
            typeof state.inputValues.lessonDuration === "number" &&
            typeof state.inputValues.costPerLesson === "number"
        ) {
            createLessonStage(
                {
                    stage: state.inputValues.stage
                        ? parseInt(state.inputValues.stage)
                        : undefined,
                    lessonType: state.inputValues.lessonType,
                    lessonDuration: state.inputValues.lessonDuration,
                    costPerLesson: state.inputValues.costPerLesson,
                },
                () => dispatchState({ type: "RESET" }),
            );
        }
    }, [
        createLessonStage,
        state.formIsValid,
        state.inputValues.costPerLesson,
        state.inputValues.lessonDuration,
        state.inputValues.lessonType,
        state.inputValues.stage,
    ]);

    return (
        <Modal
            isOpen={showModal}
            onClose={() => {
                setShowModal(false);
            }}
            size="md"
        >
            <Modal.Content bg="primary.800">
                <Modal.CloseButton
                    _hover={{ bg: "transparent", opacity: 0.7 }}
                    _icon={{ color: "surface.100" }}
                    _pressed={{ bg: "transparent", opacity: 0.7 }}
                />
                <Modal.Header _text={{ color: "surface.100" }} bg="primary.800">
                    Create Lesson Stage
                </Modal.Header>
                <Modal.Body alignItems="center"></Modal.Body>
                <VStack
                    alignItems="center"
                    justifyContent="center"
                    pb="6"
                    pt="-2"
                    space={5}
                >
                    <VStack alignItems="center" space="2">
                        <Text color="surface.100" fontSize="sm">
                            Stage
                        </Text>
                        <Select
                            bg="surface.100"
                            color="surface.900"
                            fontSize="md"
                            onValueChange={(itemValue) =>
                                selectChangeHandler("stage", itemValue)
                            }
                            placeholder="Select stage"
                            selectedValue={
                                state.inputValues.stage?.toString() ?? "None"
                            }
                            textAlign="center"
                            width="160"
                        >
                            <Select.Item
                                key={NO_STAGE}
                                actionSheetLabel={"Any Stage"}
                                value={String(NO_STAGE)}
                            />
                            {availableStages.map((item) => {
                                return (
                                    <Select.Item
                                        key={item}
                                        actionSheetLabel={`Stage ${item}`}
                                        value={String(item)}
                                    />
                                );
                            })}
                        </Select>
                    </VStack>
                    <VStack alignItems="center" space="2">
                        <Text color="surface.100" fontSize="sm">
                            Lesson Type
                        </Text>
                        <Select
                            bg="surface.100"
                            color="surface.900"
                            fontSize="md"
                            onValueChange={(itemValue) => {
                                selectChangeHandler("lessonType", itemValue);
                                lessonDurationInputRef.current?.focus();
                            }}
                            placeholder="Select lesson type"
                            selectedValue={state.inputValues.lessonType}
                            textAlign="center"
                            width="210"
                        >
                            {LESSON_TYPES.map((item) => {
                                return (
                                    <Select.Item
                                        key={item.value}
                                        actionSheetLabel={item.label}
                                        value={item.value}
                                    />
                                );
                            })}
                        </Select>
                    </VStack>
                    <VStack
                        alignItems="center"
                        justifyContent="center"
                        space="2"
                    >
                        <Text color="surface.100" fontSize="sm">
                            Lesson Duration
                        </Text>
                        <HStack alignItems="center" space="2">
                            <Box width="70">
                                <TextInput
                                    ref={lessonDurationInputRef}
                                    id="lessonDuration"
                                    initialValue={state.inputValues.lessonDuration?.toString()}
                                    invalidIndicator
                                    keyboardType="number-pad"
                                    maxLength={3}
                                    numOnly
                                    onInputChange={inputChangeHandler}
                                    onSubmit={() =>
                                        costPerLessonInputRef.current?.focus()
                                    }
                                    textAlign="center"
                                />
                            </Box>
                            <Text color="surface.100" fontWeight="light">
                                minutes
                            </Text>
                        </HStack>
                    </VStack>
                    <VStack
                        alignItems="center"
                        justifyContent="center"
                        space="2"
                    >
                        <Text color="surface.100" fontSize="sm">
                            Cost Per Lesson
                        </Text>
                        <HStack alignItems="center" space="2">
                            <Box width="100">
                                <CurrencyInput
                                    ref={costPerLessonInputRef}
                                    blurOnSubmit
                                    fontSize="md"
                                    id="costPerLesson"
                                    initialValue={
                                        state.inputValues.costPerLesson
                                    }
                                    invalidIndicator
                                    isInvalid={
                                        !state.inputValidities.costPerLesson &&
                                        state.inputValues.costPerLesson !=
                                            undefined
                                    }
                                    onInputChange={inputChangeHandler}
                                    onSubmit={createLessonStageHandler}
                                    selectTextOnFocus={false}
                                    size="lg"
                                    textAlign="center"
                                />
                            </Box>
                            <Text color="surface.100" fontWeight="light">
                                per lesson
                            </Text>
                        </HStack>
                    </VStack>
                    <ButtonDebounced
                        ref={createButtonRef}
                        _text={{ fontSize: "xl" }}
                        height="50px"
                        isDisabled={!state.formIsValid}
                        isLoading={createLessonStageInFlight}
                        mb="4"
                        mt="8"
                        onPress={createLessonStageHandler}
                        width="35%"
                    >
                        Create
                    </ButtonDebounced>
                </VStack>
            </Modal.Content>
        </Modal>
    );
};

export default React.memo(CreateLessonStageModal);
