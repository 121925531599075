/**
 * @generated SignedSource<<e776dae44a8b17330be7d3ad8efba336>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LessonModelStatus = "BLANK" | "BREAK" | "LEFT_SCHOOL" | "MISSED" | "NO_LESSON" | "OTHER_TEACHER" | "PRESENT" | "PRESENT_DOUBLE" | "PUPIL_ABSENT" | "STOPPED_LESSONS" | "TEACHER_ABSENT" | "%future added value";
export type RescheduleLessonsForWeekInput = {
  block: number;
  clientMutationId?: string | null;
  dayIndex: number;
  rearrangedDate: string;
  replacementLessonColumnIndex?: number | null;
  scheduledDate: string;
  schoolName?: string | null;
  startingYear: number;
  teacherId: string;
};
export type RescheduleLessonsForWeekMutation$variables = {
  input: RescheduleLessonsForWeekInput;
};
export type RescheduleLessonsForWeekMutation$data = {
  readonly rescheduleLessonsForWeek: {
    readonly errors: any | null;
    readonly lessons: ReadonlyArray<{
      readonly id: string;
      readonly rearrangedTimestamp: string | null;
      readonly replacementLessonColumnIndex: number | null;
      readonly scheduledTimestamp: string | null;
      readonly status: LessonModelStatus;
      readonly weekWasRearranged: boolean | null;
    } | null> | null;
    readonly success: boolean | null;
  } | null;
};
export type RescheduleLessonsForWeekMutation = {
  response: RescheduleLessonsForWeekMutation$data;
  variables: RescheduleLessonsForWeekMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RescheduleLessonsForWeekPayload",
    "kind": "LinkedField",
    "name": "rescheduleLessonsForWeek",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LessonNode",
        "kind": "LinkedField",
        "name": "lessons",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scheduledTimestamp",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rearrangedTimestamp",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "replacementLessonColumnIndex",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "weekWasRearranged",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RescheduleLessonsForWeekMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RescheduleLessonsForWeekMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "47dc66b4c697c7671e5bd2c909365f03",
    "id": null,
    "metadata": {},
    "name": "RescheduleLessonsForWeekMutation",
    "operationKind": "mutation",
    "text": "mutation RescheduleLessonsForWeekMutation(\n  $input: RescheduleLessonsForWeekInput!\n) {\n  rescheduleLessonsForWeek(input: $input) {\n    success\n    errors\n    lessons {\n      id\n      scheduledTimestamp\n      rearrangedTimestamp\n      status\n      replacementLessonColumnIndex\n      weekWasRearranged\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4a3e5524a302861f0a200f1e1e077b70";

export default node;
