import React, {
    Suspense,
    useMemo,
    useReducer,
    useEffect,
    useCallback,
    useRef,
} from "react";
import type { FC, Dispatch } from "react";

import {
    Box,
    Text,
    VStack,
    Avatar,
    ScrollView,
    useToast,
    Center,
    Button,
} from "native-base";
import { useQueryLoader, usePreloadedQuery, useMutation } from "react-relay";
import type { PreloadedQuery } from "react-relay";
import type { Subscription } from "relay-runtime";

import LoadingBlobs from "pianofunclub-shared/components/Animations/LoadingBlobs";
import ButtonDebounced from "pianofunclub-shared/components/Buttons/ButtonDebounced";
import CurrencyInput from "pianofunclub-shared/components/Inputs/CurrencyInput";
import TextInput from "pianofunclub-shared/components/Inputs/TextInput";
import AlertPopup from "pianofunclub-shared/components/NativeBaseExtended/AlertPopup";
import Select from "pianofunclub-shared/components/NativeBaseExtended/Select";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import { TrashIcon } from "pianofunclub-shared/components/Other/Icons";

import type {
    DeletePayslipMutation,
    DeletePayslipMutation$data,
} from "pianofunclub-shared/relay/graphql/payslips/__generated__/DeletePayslipMutation.graphql";
import type {
    EditPayslipMutation,
    EditPayslipMutation$data,
} from "pianofunclub-shared/relay/graphql/payslips/__generated__/EditPayslipMutation.graphql";
import type { LoadPayslipQuery } from "pianofunclub-shared/relay/graphql/payslips/__generated__/LoadPayslipQuery.graphql";
import { delete_payslip } from "pianofunclub-shared/relay/graphql/payslips/DeletePayslip";
import { edit_payslip } from "pianofunclub-shared/relay/graphql/payslips/EditPayslip";
import { load_payslip } from "pianofunclub-shared/relay/graphql/payslips/LoadPayslip";

import { PAYSLIP_STATUS } from "pianofunclub-shared/utils/constants";
import { getInitials } from "pianofunclub-shared/utils/converters";
import { getFullName } from "pianofunclub-shared/utils/extractors";
import { createReducer } from "pianofunclub-shared/utils/reducers";
import type { Action, State } from "pianofunclub-shared/utils/reducers";

import type {
    PayrollStackNavigatorProps,
    PayrollStackRouteProps,
} from "pianofunclub-crm/navigation/PayrollNavigator";

import SideBar from "pianofunclub-crm/components/Drawers/SideBar";

export type NavigationProps = PayrollStackNavigatorProps<"Payslip">;
export type RouteProps = PayrollStackRouteProps<"Payslip">;

interface ScreenProps {
    navigation: NavigationProps;
    route: RouteProps;
}

interface ContentProps {
    dispatchState: Dispatch<Action<ReducerValues, ReducerTypes>>;
    loadPayslipQueryReference: PreloadedQuery<
        LoadPayslipQuery,
        Record<string, unknown>
    >;
    navigation: NavigationProps;
    route: RouteProps;
    state: State<ReducerValues>;
}

export type ReducerValues = {
    amountDue?: number;
    clickedEnrolmentId?: string;
    contentIsRendered: boolean;
    deletePayslipAlertIsOpen?: boolean;
    // don't handle state for most text inputs (mutation is fired on blur)
    // do for the selects so that they respond immeditately
    payslipStatusSelect?: string;
    refreshHandler?: () => void;
    subscription?: Subscription;
};

export type ReducerTypes =
    | string
    | number
    | boolean
    | Subscription
    | (() => void)
    | undefined;

const SIDE_BAR_WIDTH = 12;

const PayslipContent: FC<ContentProps> = (props) => {
    const {
        dispatchState,
        loadPayslipQueryReference: loadPayslipQueryReference,
        navigation,
        route,
        state,
    } = props;

    const payslipData = usePreloadedQuery(
        load_payslip,
        loadPayslipQueryReference,
    );

    const toast = useToast();

    const commitEditPayslip = useMutation<EditPayslipMutation>(edit_payslip)[0];

    const amountDueRef = useRef<{
        clear: () => void;
        focus: () => void;
        setValue: (text: string, isValid: boolean) => void;
    }>();

    useEffect(() => {
        if (payslipData.payslip) {
            // initialise local state
            dispatchState({
                input: "payslipStatusSelect",
                value: payslipData.payslip.status ?? undefined,
            });

            dispatchState({
                input: "amountDue",
                value: payslipData.payslip.amountDue ?? undefined,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payslipData.payslip]);

    useEffect(() => {
        if (state.values.amountDue !== undefined) {
            amountDueRef.current?.setValue(
                state.values.amountDue.toString(),
                true,
            );
        }
    }, [state.values.amountDue, amountDueRef]);

    const editPayslip = useCallback(
        (variables: {
            adjustedReason?: string;
            amountAdjusted?: number;
            amountDue?: number;
            payslipId: string;
        }) => {
            const editEnrolmentConfig = {
                variables: {
                    input: variables,
                },
                onCompleted: (response: EditPayslipMutation$data) => {
                    if (response?.editPayslip?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={"Partner payout updated"}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't update partner payout"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitEditPayslip(editEnrolmentConfig);
        },
        [commitEditPayslip, toast],
    );

    const [commitDeletePayslip, deletePayslipInFlight] =
        useMutation<DeletePayslipMutation>(delete_payslip);

    const deletePayslip = useCallback(
        (variables: { payslipId: string }, onComplete?: () => void) => {
            const deleteEnrolmentConfig = {
                variables: {
                    input: variables,
                    connections: route.params.payslipsConnectionId
                        ? [route.params.payslipsConnectionId]
                        : [],
                },
                onCompleted: (response: DeletePayslipMutation$data) => {
                    dispatchState({
                        input: "deletePayslipAlertIsOpen",
                        value: false,
                    });

                    if (response?.deletePayslip?.success) {
                        navigation.navigate("PayrollHub");
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="error"
                                    title={"Partner payout deleted"}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't delete partner payout"}
                                    toast={toast}
                                />
                            ),
                        });
                    }

                    onComplete?.();
                },
            };

            commitDeletePayslip(deleteEnrolmentConfig);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [commitDeletePayslip],
    );

    const inputFinishEditingHandler = useCallback(
        (
            inputIdentifier?: string | number,
            inputValue?: string,
            isValid?: boolean,
        ) => {
            if (
                isValid &&
                inputIdentifier &&
                typeof inputValue !== "undefined" &&
                // check if the value has changed before firing mutation
                // @ts-expect-error string indexing
                inputValue !== payslipData.payslip?.[inputIdentifier]
            ) {
                switch (inputIdentifier) {
                    case "amountAdjusted": {
                        const parsedCost = inputValue.replace("£", "");
                        editPayslip({
                            payslipId: route.params.payslipId,
                            amountAdjusted: parseFloat(parsedCost),
                        });

                        dispatchState({
                            input: "amountDue",
                            value: payslipData.payslip?.amountDue ?? undefined,
                        });

                        break;
                    }
                    default:
                        editPayslip({
                            payslipId: route.params.payslipId,
                            [inputIdentifier]: inputValue?.trim(),
                        });
                }
            }
        },
        [
            payslipData.payslip,
            editPayslip,
            route.params.payslipId,
            dispatchState,
        ],
    );

    const selectChangeHandler = useCallback(
        (inputIdentifier: string, itemValue: string | boolean) => {
            if (
                state.values?.[
                    `${inputIdentifier}Select` as keyof ReducerValues
                ] != itemValue
            ) {
                dispatchState({
                    input: `${inputIdentifier}Select`,
                    value: itemValue,
                });
                editPayslip({
                    payslipId: route.params.payslipId,
                    [inputIdentifier]: itemValue,
                });
            }
        },
        [state.values, dispatchState, editPayslip, route.params.payslipId],
    );

    const renderContent = useMemo(() => {
        return (
            <ScrollView
                bg="surface.100"
                contentContainerStyle={{
                    paddingTop: 100,
                    paddingBottom: 40,
                    alignItems: "center",
                }}>
                <VStack
                    alignItems="center"
                    bg="surface.200"
                    borderColor="surface.400"
                    borderRadius="3xl"
                    borderWidth={1}
                    p="4"
                    shadow={1}
                    space="4">
                    <VStack alignItems="center">
                        <Text fontSize="lg" marginBottom={4}>
                            Partner Payout
                        </Text>
                        <VStack alignItems="center">
                            <Avatar
                                _text={{ color: "surface.200" }}
                                bg="primary.600"
                                size="md"
                                source={{
                                    uri: undefined,
                                }}>
                                {getInitials(
                                    payslipData.payslip?.teacher?.user
                                        .firstName ?? "",
                                    payslipData.payslip?.teacher?.user
                                        .lastName ?? "",
                                )}
                            </Avatar>
                            <Text fontSize="xl" mt="2">
                                {getFullName(
                                    payslipData.payslip?.teacher?.user
                                        .firstName ?? "",
                                    payslipData.payslip?.teacher?.user
                                        .lastName ?? "",
                                )}
                            </Text>
                        </VStack>
                        <Button
                            _text={{ fontSize: "md", color: "surface.100" }}
                            colorScheme="error"
                            leftIcon={
                                <Center size="7">
                                    <TrashIcon color="surface.100" size="5" />
                                </Center>
                            }
                            marginTop={4}
                            onPress={() => {
                                dispatchState({
                                    input: "deletePayslipAlertIsOpen",
                                    value: true,
                                });
                            }}
                            paddingRight={4}>
                            Delete
                        </Button>
                    </VStack>
                    <VStack alignItems="center" space="2" width="100%">
                        <VStack mt="1" space="0.5" width="100%">
                            <Text color="surface.700" fontSize="sm" pl="1">
                                Paid From
                            </Text>
                            <TextInput
                                id="paidFromDate"
                                initiallyValid
                                initialValue={
                                    payslipData.payslip?.paidFromDate ?? ""
                                }
                                isReadOnly
                                labelStyle={{
                                    color: "surface.100",
                                    fontSize: "sm",
                                }}
                                pointerEvents="none"
                                size="md"
                            />
                        </VStack>
                        <VStack mt="1" space="0.5" width="100%">
                            <Text color="surface.700" fontSize="sm" pl="1">
                                Paid To
                            </Text>
                            <TextInput
                                id="paidToDate"
                                initiallyValid
                                initialValue={
                                    payslipData.payslip?.paidToDate ?? ""
                                }
                                isReadOnly
                                labelStyle={{
                                    color: "surface.100",
                                    fontSize: "sm",
                                }}
                                pointerEvents="none"
                                size="md"
                            />
                        </VStack>
                        <VStack mt="1" space="0.5" width="100%">
                            <Text color="surface.700" fontSize="sm" pl="1">
                                Total Minutes Taught
                            </Text>
                            <TextInput
                                id="totalMinutesTaught"
                                initiallyValid
                                initialValue={
                                    payslipData.payslip?.totalMinutesTaught?.toString() ??
                                    "0"
                                }
                                isReadOnly
                                labelStyle={{
                                    color: "surface.100",
                                    fontSize: "sm",
                                }}
                                pointerEvents="none"
                                size="md"
                            />
                        </VStack>
                        {payslipData.payslip?.amountAdjusted ? (
                            <VStack mt="1" space="0.5" width="100%">
                                <Text color="surface.700" fontSize="sm" pl="1">
                                    {"Total Amount Due (before adjustments)"}
                                </Text>
                                <CurrencyInput
                                    fontSize="sm"
                                    id="amountAdjusted"
                                    initialValue={
                                        (payslipData.payslip?.amountDue ?? 0) -
                                        (payslipData.payslip?.amountAdjusted ??
                                            0)
                                    }
                                    invalidIndicator
                                    isReadOnly
                                    keyboardType="number-pad"
                                    pointerEvents="none"
                                    size="md"
                                />
                            </VStack>
                        ) : null}
                        <VStack mt="1" space="0.5" width="100%">
                            <Text color="surface.700" fontSize="sm" pl="1">
                                Adjustment
                            </Text>
                            <CurrencyInput
                                fontSize="sm"
                                id="amountAdjusted"
                                initialValue={
                                    payslipData.payslip?.amountAdjusted ?? 0
                                }
                                invalidIndicator
                                keyboardType="number-pad"
                                onFinishEditing={inputFinishEditingHandler}
                                size="md"
                            />
                        </VStack>
                        <VStack mt="1" space="0.5" width="100%">
                            <Text color="surface.700" fontSize="sm" pl="1">
                                {"Total Amount Due (after adjustments)"}
                            </Text>
                            <CurrencyInput
                                ref={amountDueRef}
                                fontSize="sm"
                                id="amountDue"
                                initialValue={state.values.amountDue ?? 0}
                                invalidIndicator
                                isReadOnly
                                keyboardType="number-pad"
                                pointerEvents="none"
                                size="md"
                            />
                        </VStack>
                        <>
                            <VStack mt="1" space="0.5" width="100%">
                                <Text color="surface.700" fontSize="sm" pl="1">
                                    Status
                                </Text>
                                <Select
                                    bg="surface.100"
                                    borderRadius="full"
                                    color="surface.900"
                                    fontSize="sm"
                                    onValueChange={(itemValue) =>
                                        selectChangeHandler("status", itemValue)
                                    }
                                    placeholder="Select school"
                                    selectedValue={
                                        state.values.payslipStatusSelect
                                    }>
                                    {PAYSLIP_STATUS.map((item) => {
                                        return (
                                            <Select.Item
                                                key={item.value}
                                                actionSheetLabel={item.label}
                                                value={item.value}
                                            />
                                        );
                                    })}
                                </Select>
                            </VStack>
                            <TextInput
                                autoCapitalize="words"
                                id="adjustedReason"
                                initiallyValid={Boolean(
                                    payslipData.payslip?.adjustedReason,
                                )}
                                initialValue={
                                    payslipData.payslip?.adjustedReason ?? ""
                                }
                                invalidIndicator
                                label="Reason for Adjustment"
                                labelStyle={{
                                    color: "surface.700",
                                    fontSize: "sm",
                                }}
                                onFinishEditing={inputFinishEditingHandler}
                                size="md"
                            />
                        </>
                    </VStack>
                </VStack>
            </ScrollView>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        state.values.amountDue,
        state.values.payslipStatusSelect,
        payslipData.payslip?.teacher?.id,
        payslipData.payslip?.teacher?.user.firstName,
        payslipData.payslip?.teacher?.user.lastName,
        payslipData.payslip?.paidFromDate,
        payslipData.payslip?.paidToDate,
        payslipData.payslip?.totalMinutesTaught,
        payslipData.payslip?.amountAdjusted,
        payslipData.payslip?.amountDue,
        payslipData.payslip?.adjustedReason,
        inputFinishEditingHandler,
        dispatchState,
        selectChangeHandler,
    ]);

    const renderDeleteAlert = useMemo(() => {
        return (
            <AlertPopup
                alertIsOpen={Boolean(state.values.deletePayslipAlertIsOpen)}
                body="This cannot be undone and all of the payout's data will be permanently lost."
                header={"Are you sure you want to delete this payout?"}
                setAlertIsOpen={(isOpen: boolean) =>
                    dispatchState({
                        input: "deletePayslipAlertIsOpen",
                        value: isOpen,
                    })
                }>
                <Button
                    _text={{ fontSize: "lg" }}
                    colorScheme="muted"
                    height="52px"
                    minWidth="100"
                    onPress={() => {
                        dispatchState({
                            input: "deletePayslipAlertIsOpen",
                            value: false,
                        });
                    }}
                    width="40%">
                    No, go back
                </Button>
                <ButtonDebounced
                    _text={{ fontSize: "lg" }}
                    colorScheme="red"
                    height="52px"
                    isLoading={deletePayslipInFlight}
                    minWidth="100"
                    onPress={() => {
                        if (payslipData.payslip?.id) {
                            deletePayslip({
                                payslipId: payslipData.payslip?.id,
                            });
                        }
                    }}
                    width="40%">
                    Yes, delete
                </ButtonDebounced>
            </AlertPopup>
        );
    }, [
        deletePayslip,
        deletePayslipInFlight,
        dispatchState,
        payslipData.payslip?.id,
        state.values.deletePayslipAlertIsOpen,
    ]);

    return (
        <Box flex={1} ml={SIDE_BAR_WIDTH}>
            {renderContent}
            {renderDeleteAlert}
        </Box>
    );
};

const PayslipScreen: FC<ScreenProps> = (props) => {
    const { navigation, route } = props;

    const initialState = useMemo(() => {
        // select the current day on the teacher register tab
        return {
            values: {
                isRefetching: false,
                contentIsRendered: false,
                subscription: undefined,
                payslipStatusSelect: undefined,
                amountDue: undefined,
            },
        };
    }, []);

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [state, dispatchState] = useReducer(reducer, initialState);

    const [loadPayslipQueryReference, loadPayslipQuery] =
        useQueryLoader<LoadPayslipQuery>(load_payslip);

    useEffect(() => {
        loadPayslipQuery(
            {
                payslipId: route.params.payslipId,
            },
            { fetchPolicy: "store-or-network" },
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadPayslipQueryReference]);

    return (
        <Box bg="surface.100" flex={1}>
            <SideBar
                isCollapsed
                navigation={navigation}
                width={SIDE_BAR_WIDTH}
            />
            {loadPayslipQueryReference != null ? (
                <Suspense
                    fallback={
                        <LoadingBlobs pt="70">Loading Payout...</LoadingBlobs>
                    }>
                    <PayslipContent
                        dispatchState={dispatchState}
                        loadPayslipQueryReference={loadPayslipQueryReference}
                        navigation={navigation}
                        route={route}
                        state={state}
                    />
                </Suspense>
            ) : (
                <LoadingBlobs pt="70">Loading Payout...</LoadingBlobs>
            )}
        </Box>
    );
};

export const screenOptions = {
    headerTitle: "Partner Payout",
};

export default PayslipScreen;
