import React from "react";
import type { ReactElement, ComponentProps } from "react";

import {
    Ionicons,
    FontAwesome,
    MaterialCommunityIcons,
    MaterialIcons,
    Octicons,
    FontAwesome5,
    Foundation,
    Feather,
} from "@expo/vector-icons";
import { Icon } from "native-base";
import { G, Path } from "react-native-svg";

type IconProps = ComponentProps<typeof Icon>;

interface FavouriteIconProps extends IconProps {
    favourited?: boolean;
}

export const HomeworkIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="home-edit" {...props} />;
};

export const FindMusicIcon = (props: IconProps): ReactElement => {
    return (
        <Icon as={MaterialCommunityIcons} name="playlist-music" {...props} />
    );
};

export const MyPupilsIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialIcons} name="supervised-user-circle" {...props} />;
};

export const PdfIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialIcons} name="picture-as-pdf" {...props} />;
};

export const ProfileIcon = (props: IconProps): ReactElement => {
    return <Icon as={Ionicons} name="person-circle" {...props} />;
};

export const ClearIcon = (props: IconProps): ReactElement => {
    return <Icon as={Ionicons} name="close-circle-outline" {...props} />;
};

export const CloseIcon = (props: IconProps): ReactElement => {
    return <Icon as={Ionicons} name="close" {...props} />;
};

export const WarningIcon = (props: IconProps): ReactElement => {
    return <Icon as={Ionicons} name="warning" {...props} />;
};

export const FavouriteIcon = (props: FavouriteIconProps): ReactElement => {
    return (
        <Icon
            as={Ionicons}
            name={props.favourited ? "heart" : "heart-outline"}
            size="7"
            {...props}
        />
    );
};

export const AddIcon = (props: IconProps): ReactElement => {
    return <Icon as={Ionicons} name="add" {...props} />;
};

export const AddCircleIcon = (props: IconProps): ReactElement => {
    return <Icon as={Ionicons} name="add-circle" {...props} />;
};

export const TickIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="check" {...props} />;
};

export const DoubleTickIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="check-all" {...props} />;
};

export const EditIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialIcons} name="edit" {...props} />;
};

export const EditProfileIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="account-edit" {...props} />;
};

export const SettingsIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="cogs" {...props} />;
};

export const FindFriendsIcon = (props: IconProps): ReactElement => {
    return (
        <Icon as={MaterialCommunityIcons} name="account-search" {...props} />
    );
};

export const NotificationIcon = (props: IconProps): ReactElement => {
    return <Icon as={Ionicons} name="notifications" {...props} />;
};

export const OptionsIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="dots-vertical" {...props} />;
};

export const BlankIcon = (props: IconProps): ReactElement => {
    return (
        <Icon as={MaterialCommunityIcons} name="dots-horizontal" {...props} />
    );
};

export const BlockIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialIcons} name="block" {...props} />;
};

export const RemoveAccountIcon = (props: IconProps): ReactElement => {
    return (
        <Icon as={MaterialCommunityIcons} name="account-remove" {...props} />
    );
};

export const CompleteIcon = (props: IconProps): ReactElement => {
    return <Icon as={Ionicons} name="checkmark-circle" {...props} />;
};

export const TrashIcon = (props: IconProps): ReactElement => {
    return <Icon as={Ionicons} name="trash" {...props} />;
};

export const ImageIcon = (props: IconProps): ReactElement => {
    return <Icon as={Ionicons} name="image" {...props} />;
};

export const AddImageIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="image-plus" {...props} />;
};

export const AppPreferencesIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialIcons} name="app-settings-alt" {...props} />;
};

export const AccountSettingsIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="account-cog" {...props} />;
};

export const HelpFAQIcon = (props: IconProps): ReactElement => {
    return <Icon as={Ionicons} name="help-circle" {...props} />;
};

export const ContactUsIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="email-send" {...props} />;
};

export const AboutUsIcon = (props: IconProps): ReactElement => {
    return <Icon as={Ionicons} name="information-circle" {...props} />;
};

export const LegalIcon = (props: IconProps): ReactElement => {
    return <Icon as={Octicons} name="law" {...props} />;
};

export const LoginIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="login" {...props} />;
};

export const LogoutIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="logout" {...props} />;
};

export const FacebookIcon = (props: IconProps): ReactElement => {
    return (
        <Icon
            as={FontAwesome}
            color="#3b5998"
            name="facebook-square"
            {...props}
        />
    );
};

export const ClearFilterIcon = (props: IconProps): ReactElement => {
    return (
        <Icon
            as={MaterialCommunityIcons}
            name="filter-remove-outline"
            {...props}
        />
    );
};

export const SortAsc = (props: IconProps): ReactElement => {
    return <Icon as={FontAwesome5} name="sort-amount-up" size="4" {...props} />;
};

export const SortDesc = (props: IconProps): ReactElement => {
    return (
        <Icon as={FontAwesome5} name="sort-amount-down" size="4" {...props} />
    );
};

export const SortAscAZ = (props: IconProps): ReactElement => {
    return (
        <Icon
            as={MaterialCommunityIcons}
            name="sort-alphabetical-ascending"
            size="5"
            {...props}
        />
    );
};

export const SortDescAZ = (props: IconProps): ReactElement => {
    return (
        <Icon
            as={MaterialCommunityIcons}
            name="sort-alphabetical-descending"
            size="5"
            {...props}
        />
    );
};

export const SortAscDate = (props: IconProps): ReactElement => {
    return (
        <Icon
            as={MaterialCommunityIcons}
            name="calendar-export"
            size="5"
            {...props}
        />
    );
};

export const SortDescDate = (props: IconProps): ReactElement => {
    return (
        <Icon
            as={MaterialCommunityIcons}
            name="calendar-import"
            size="5"
            {...props}
        />
    );
};

export const SearchIcon = (props: IconProps): ReactElement => {
    return <Icon as={Ionicons} name="search" size="5" {...props} />;
};

export const InfoIcon = (props: IconProps): ReactElement => {
    return (
        <Icon
            as={Ionicons}
            name="information-circle-outline"
            size="5"
            {...props}
        />
    );
};

export const EmailIcon = (props: IconProps): ReactElement => {
    return (
        <Icon as={MaterialIcons} name="alternate-email" size="5" {...props} />
    );
};

export const MailIcon = (props: IconProps): ReactElement => {
    return <Icon as={Ionicons} name="mail" size="5" {...props} />;
};

export const SecurityIcon = (props: IconProps): ReactElement => {
    return (
        <Icon as={MaterialCommunityIcons} name="security" size="5" {...props} />
    );
};

export const LockIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="lock" size="5" {...props} />;
};

export const SendIcon = (props: IconProps): ReactElement => {
    return <Icon as={Ionicons} name={"send"} {...props} />;
};

export const NoConnectionIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialIcons} name={"wifi-off"} {...props} />;
};

export const RestartIcon = (props: IconProps): ReactElement => {
    return <Icon as={Ionicons} name="refresh" size="sm" {...props} />;
};

export const AccountsIcon = (props: IconProps): ReactElement => {
    return <Icon as={FontAwesome5} name="users" {...props} />;
};

export const InvoiceIcon = (props: IconProps): ReactElement => {
    return <Icon as={FontAwesome5} name="file-invoice-dollar" {...props} />;
};

export const SchoolIcon = (props: IconProps): ReactElement => {
    return <Icon as={FontAwesome5} name="school" {...props} />;
};

export const DraftIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="file-outline" {...props} />;
};

export const DraftUpdatedIcon = (props: IconProps): ReactElement => {
    return (
        <Icon
            as={MaterialCommunityIcons}
            name="file-refresh-outline"
            {...props}
        />
    );
};

export const DraftFilledIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="file" {...props} />;
};

export const DraftFilledUpdatedIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="file-refresh" {...props} />;
};

export const KeyboardIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="keyboard" {...props} />;
};

export const PianoIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="piano" {...props} />;
};

export const ViolinIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="violin" {...props} />;
};

export const GuitarIcon = (props: IconProps): ReactElement => {
    return (
        <Icon as={MaterialCommunityIcons} name="guitar-electric" {...props} />
    );
};

export const DrumsIcon = (props: IconProps): ReactElement => {
    return <Icon as={FontAwesome5} name="drum" {...props} />;
};

export const RecorderIcon = (props: IconProps): ReactElement => {
    return (
        <Icon
            height="1280pt"
            preserveAspectRatio="xMidYMid meet"
            viewBox="-320 -160 1600 1600"
            width="1280pt"
            {...props}
        >
            <G
                fill={(props.color ?? "white") as string}
                stroke="none"
                transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
            >
                <Path
                    d={`M12075 12767 c-389 -145 -599 -299 -863 -632 -161 -203 -172 -215
-192 -215 -37 0 -128 -43 -188 -88 -79 -60 -137 -137 -157 -210 -9 -31 -20
-67 -23 -80 -4 -12 -364 -405 -800 -872 l-794 -850 -56 0 c-102 -1 -175 -51
-196 -134 -10 -39 -27 -60 -125 -151 -72 -66 -122 -105 -135 -105 -38 0 -139
-30 -201 -59 -188 -88 -339 -275 -382 -474 -15 -70 -19 -77 -45 -82 -64 -15
-118 -87 -118 -160 0 -34 -22 -59 -322 -373 -1088 -1137 -1529 -1594 -4172
-4327 -298 -309 -346 -354 -406 -386 -60 -31 -75 -35 -123 -31 -73 6 -112 -15
-128 -68 -10 -33 -22 -45 -69 -72 -81 -46 -566 -499 -582 -544 -10 -28 -18
-34 -52 -39 -106 -17 -196 -87 -242 -188 -26 -55 -29 -73 -29 -163 l0 -102
-32 -11 c-38 -14 -49 -31 -69 -108 -52 -198 -260 -410 -614 -624 -131 -79
-444 -239 -529 -269 -35 -13 -72 -32 -82 -43 -16 -18 -23 -18 -63 -8 -69 17
-166 14 -206 -7 -72 -38 -96 -135 -65 -257 98 -381 639 -923 1020 -1020 122
-31 219 -7 257 65 21 40 24 137 6 207 -9 39 -9 47 5 54 8 5 28 42 43 82 42
110 183 386 276 539 117 194 187 287 308 409 111 112 205 175 299 199 80 21
102 32 116 61 8 15 15 34 15 41 0 10 25 13 99 14 91 0 104 3 164 32 87 43 141
104 167 190 11 35 20 69 20 76 0 7 15 17 33 24 42 15 497 501 547 584 19 32
39 58 43 58 5 0 24 7 42 15 39 19 55 51 55 110 0 108 -114 -11 2528 2624 1339
1334 2439 2424 2445 2422 7 -2 47 -6 89 -9 65 -4 83 -1 122 17 53 26 84 70 92
132 l6 45 87 27 c187 59 327 179 415 353 33 66 66 171 66 211 0 10 48 70 107
133 82 89 114 117 141 123 87 19 130 77 141 187 l6 69 859 798 c793 736 863
799 908 810 75 19 127 50 195 116 67 64 128 168 139 236 6 31 20 46 112 116
148 112 350 311 443 434 153 205 250 451 274 697 7 63 5 75 -18 120 -84 166
-321 347 -468 360 -44 3 -71 -2 -144 -29z m-1020 -1369 c62 -31 165 -103 165
-116 0 -4 -19 -29 -41 -55 -27 -31 -47 -46 -57 -42 -8 3 -73 40 -144 82 l-129
76 48 49 c55 56 59 56 158 6z m-3354 -3397 c68 -68 3 -175 -95 -157 -114 21
-92 186 24 186 33 0 48 -6 71 -29z m-769 -780 c28 -25 33 -36 33 -76 0 -41 -4
-51 -37 -81 -32 -29 -45 -34 -85 -34 -120 1 -154 137 -50 200 48 30 100 26
139 -9z m-773 -782 c36 -35 42 -89 16 -139 -45 -86 -198 -76 -224 14 -23 86
34 155 131 156 39 0 51 -5 77 -31z m-950 -924 c59 -30 64 -114 11 -159 -38
-32 -83 -41 -121 -26 -31 13 -59 55 -59 87 0 31 39 84 73 98 41 18 62 18 96 0z
m-919 -940 c88 -46 71 -174 -29 -219 -52 -24 -104 -15 -144 25 -27 27 -29 35
-25 80 10 99 113 158 198 114z m-866 -767 c24 -34 19 -66 -13 -99 -52 -52
-121 -30 -121 38 0 71 97 114 134 61z m117 -178 c24 -13 26 -66 2 -92 -37 -41
-111 -7 -97 46 7 27 38 56 62 56 7 0 22 -5 33 -10z m-1081 -461 c0 -53 -141
-138 -165 -99 -10 16 25 59 69 85 42 25 96 33 96 14z m58 -106 c-3 -19 -63
-63 -84 -63 -24 0 -15 27 17 54 36 30 72 35 67 9z m-2142 -2496 c111 -82 273
-271 251 -293 -19 -19 -212 152 -293 259 -69 92 -53 104 42 34z`}
                />
            </G>
        </Icon>
    );
};

export const UkeleleIcon = (props: IconProps): ReactElement => {
    return (
        <Icon as={MaterialCommunityIcons} name="guitar-acoustic" {...props} />
    );
};

export const MusicTheoryIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="book-music" {...props} />;
};

export const OneOnOneIcon = (props: IconProps): ReactElement => {
    return <Icon as={FontAwesome5} name="user-alt" {...props} />;
};

export const PairedIcon = (props: IconProps): ReactElement => {
    return <Icon as={FontAwesome5} name="user-friends" {...props} />;
};

export const GroupIcon = (props: IconProps): ReactElement => {
    return <Icon as={FontAwesome5} name="users" {...props} />;
};

export const CollapseIcon = (props: IconProps): ReactElement => {
    return (
        <Icon
            as={MaterialCommunityIcons}
            name="arrow-collapse-left"
            {...props}
        />
    );
};

export const ExpandIcon = (props: IconProps): ReactElement => {
    return (
        <Icon
            as={MaterialCommunityIcons}
            name="arrow-collapse-right"
            {...props}
        />
    );
};

export const CalendarIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="calendar" {...props} />;
};

export const RegisterIcon = (props: IconProps): ReactElement => {
    return (
        <Icon as={MaterialCommunityIcons} name="calendar-check" {...props} />
    );
};

export const TimetableIcon = (props: IconProps): ReactElement => {
    return (
        <Icon as={MaterialCommunityIcons} name="calendar-clock" {...props} />
    );
};

export const BlacklistedDateIcon = (props: IconProps): ReactElement => {
    return (
        <Icon as={MaterialCommunityIcons} name="calendar-remove" {...props} />
    );
};

export const ArchivedIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="archive" {...props} />;
};

export const WaitingListIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="human-queue" {...props} />;
};

export const NewEnrollmentIcon = (props: IconProps): ReactElement => {
    return <Icon as={Foundation} name="burst-new" {...props} />;
};

export const LessonTimeIcon = (props: IconProps): ReactElement => {
    return (
        <Icon as={MaterialCommunityIcons} name="calendar-clock" {...props} />
    );
};

export const LeftSchoolIcon = (props: IconProps): ReactElement => {
    return <Icon as={Ionicons} name="exit-outline" {...props} />;
};

export const NoLessonIcon = (props: IconProps): ReactElement => {
    return <Icon as={Ionicons} name="remove-outline" {...props} />;
};

export const BankTransferIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="bank" {...props} />;
};

export const CardIcon = (props: IconProps): ReactElement => {
    return <Icon as={Ionicons} name="card" {...props} />;
};

export const PayPalIcon = (props: IconProps): ReactElement => {
    return <Icon as={FontAwesome} name="paypal" {...props} />;
};

export const CashIcon = (props: IconProps): ReactElement => {
    return <Icon as={Ionicons} name="cash" {...props} />;
};

export const FinanceIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="finance" {...props} />;
};

export const UploadIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="upload" {...props} />;
};

export const CopyCalendarIcon = (props: IconProps): ReactElement => {
    return (
        <Icon
            as={MaterialCommunityIcons}
            name="calendar-arrow-right"
            {...props}
        />
    );
};

export const PhoneIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="phone-dial" {...props} />;
};

export const CopyIcon = (props: IconProps): ReactElement => {
    return <Icon as={Ionicons} name="copy" {...props} />;
};

export const SwapIcon = (props: IconProps): ReactElement => {
    return (
        <Icon as={MaterialCommunityIcons} name="swap-horizontal" {...props} />
    );
};

export const SwapIconCircle = (props: IconProps): ReactElement => {
    return (
        <Icon
            as={MaterialCommunityIcons}
            name="swap-horizontal-circle-outline"
            {...props}
        />
    );
};

export const ChevronLeftIcon = (props: IconProps): ReactElement => {
    return <Icon as={Feather} name="chevron-left" {...props} />;
};

export const ClockCheckIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="clock-check" {...props} />;
};

export const PayrollIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="cash-check" {...props} />;
};

export const PayrollEstimateIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="cash-plus" {...props} />;
};

export const PayrateIcon = (props: IconProps): ReactElement => {
    return <Icon as={MaterialCommunityIcons} name="cash-fast" {...props} />;
};

export const UserClockIcon = (props: IconProps): ReactElement => {
    return <Icon as={FontAwesome5} name="user-clock" {...props} />;
};

export const UserSlashIcon = (props: IconProps): ReactElement => {
    return <Icon as={FontAwesome5} name="user-slash" {...props} />;
};
