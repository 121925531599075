import { graphql } from "react-relay";

export const load_registers = graphql`
    query LoadRegistersQuery(
        $searchTerm: String
        $startingYear: Int!
        $block: Int!
        $first: Int!
        $after: String
    ) {
        ...LoadRegisters_query_profiles
            @arguments(
                searchTerm: $searchTerm
                startingYear: $startingYear
                block: $block
                first: $first
                after: $after
            )
        me {
            profile {
                profileGroup {
                    id
                    generateGoogleRegistersProgress
                    readInRegistersProgress
                    bulkUploadNewRegistersProgress
                    generateRegistersForNewBlockProgress
                }
            }
        }
    }
`;

export const load_registers_pagination = graphql`
    fragment LoadRegisters_query_profiles on Query
    @argumentDefinitions(
        searchTerm: { type: "String" }
        startingYear: { type: "Int!" }
        block: { type: "Int!" }
        first: { type: "Int!" }
        after: { type: "String" }
    )
    @refetchable(queryName: "LoadRegistersPaginationQuery") {
        profiles(
            accountType: "TEACHER"
            searchTerm: $searchTerm
            orderBy: "user__firstName,user__lastName"
            first: $first
            after: $after
        ) @connection(key: "LoadRegisters_query_profiles") {
            edges {
                node {
                    id
                    totalLessonsMarked(
                        startingYear: $startingYear
                        block: $block
                    )
                    totalLessons(startingYear: $startingYear, block: $block)
                    user {
                        firstName
                        lastName
                        email
                    }
                }
            }
        }
    }
`;
