import { graphql } from "react-relay";

export const send_reminders_for_unpaid_invoices_for_block = graphql`
    mutation SendRemindersForUnpaidInvoicesForBlockMutation(
        $input: SendRemindersForUnpaidInvoicesForBlockInput!
    ) {
        sendRemindersForUnpaidInvoicesForBlock(input: $input) {
            success
            errors
            numberOfRemindersSent
            totalToSend
            erroredInvoice {
                id
                assignedTo {
                    id
                    accountNumber
                    user {
                        firstName
                        lastName
                        email
                    }
                }
            }
            profile {
                id
                profileGroup {
                    id
                    sendRemindersProgress
                }
            }
        }
    }
`;
