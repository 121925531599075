import { graphql } from "react-relay";

export const enrolment_query = graphql`
    mutation EnrolmentMutation($input: EnrolmentInput!) {
        enrolment(input: $input) {
            success
            errors
            enrolledProfileId
            stageId
            instrumentName
            enrolment {
                id
                schoolName
                schoolBorough
                schoolPostcode
                pupilFirstName
                pupilLastName
                schoolYear
                schoolClass
                instrument
                paidBySchool
                premiumPupil
                discountPercentage
                teacherEmail
                lessonDay
                lessonStartTime
                lessonStage
                lessonType
                lessonDuration
                costPerLesson
                numberOfLessons
                parentName
                parentPhoneNumber
                pupilNotes
                lessonBlockNotes
                positionInWaitingList
                addedOn
                enroled
                offered
                offeredOn
                adminNotes
            }
        }
    }
`;
