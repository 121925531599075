import { graphql } from "react-relay";

export const invoicing_headline_data_fragment = graphql`
    fragment InvoicingHeadlineDataFragment_query on Query
    @argumentDefinitions(
        startingYear: { type: "Int!" }
        block: { type: "Int!" }
    )
    @refetchable(queryName: "InvoicingHeadlineDataRefreshQuery") {
        me {
            profile {
                id
                totalInvoices(startingYear: $startingYear, block: $block)
                totalAmountInvoiced(startingYear: $startingYear, block: $block)
                totalInvoicesSent(startingYear: $startingYear, block: $block)
                totalAmountPaid(startingYear: $startingYear, block: $block)
                totalInvoicesUnpaid(startingYear: $startingYear, block: $block)
                percentLessonBlocksInvoicedFor(
                    startingYear: $startingYear
                    block: $block
                )
                lessonBlocksNotInvoicedFor(
                    startingYear: $startingYear
                    block: $block
                ) {
                    id
                    school {
                        name
                    }
                    pupil {
                        id
                        schoolYear
                        user {
                            id
                            firstName
                            lastName
                        }
                    }
                }
            }
        }
    }
`;
