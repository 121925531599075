import React from "react";
import type { ReactElement } from "react";

import type {
    CompositeNavigationProp,
    RouteProp,
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import type { StackNavigationProp } from "@react-navigation/stack";

import AccountScreen, {
    screenOptions as accountScreenOptions,
} from "../screens/accounts/AccountScreen";
import InvoiceScreen, {
    screenOptions as invoiceScreenOptions,
} from "../screens/invoicing/InvoiceScreen";
import InvoicingHubScreen, {
    screenOptions as invoicingHubScreenOptions,
} from "../screens/invoicing/InvoicingHubScreen";

import type { HubTabNavigatorProps } from "./HubNavigator";
import defaultNavOptions from "./utils/DefaultNavigationOptions";
import type { AccountParams, InvoiceParams } from "./utils/ScreenParams";

export type InvoicingStackNavigatorParamList = {
    Account: AccountParams;
    Invoice: InvoiceParams;
    InvoicingHub?: {
        block?: number;
        orderBy?: string;
        school?: string;
        searchTerm?: string;
        startingYear?: number;
        status?: string;
    };
};

type NavigatorProps<T extends keyof InvoicingStackNavigatorParamList> =
    StackNavigationProp<InvoicingStackNavigatorParamList, T>;

export type InvoicingStackNavigatorProps<
    T extends keyof InvoicingStackNavigatorParamList,
> = CompositeNavigationProp<
    NavigatorProps<T>,
    HubTabNavigatorProps<"InvoicingHubTab">
>;

export type InvoicingStackRouteProps<
    T extends keyof InvoicingStackNavigatorParamList,
> = RouteProp<InvoicingStackNavigatorParamList, T>;

const InvoicingStackNavigator =
    createStackNavigator<InvoicingStackNavigatorParamList>();

const InvoicingNavigator = (): ReactElement => {
    return (
        <InvoicingStackNavigator.Navigator screenOptions={defaultNavOptions}>
            <InvoicingStackNavigator.Screen
                component={InvoicingHubScreen}
                name="InvoicingHub"
                options={invoicingHubScreenOptions}
            />
            <InvoicingStackNavigator.Screen
                component={InvoiceScreen}
                name="Invoice"
                options={invoiceScreenOptions}
            />
            <InvoicingStackNavigator.Screen
                component={AccountScreen}
                name="Account"
                options={accountScreenOptions}
            />
        </InvoicingStackNavigator.Navigator>
    );
};

export default InvoicingNavigator;
