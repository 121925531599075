/**
 * @generated SignedSource<<a2f26d9fe0f999b4c1904e0739c8f7d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InvoiceModelStatus = "CANCELLED" | "DRAFT" | "OVERPAID" | "PAID" | "PENDING" | "REFUND" | "SENT" | "%future added value";
export type RemovePaymentInput = {
  clientMutationId?: string | null;
  paymentId?: string | null;
};
export type RemovePaymentMutation$variables = {
  connections: ReadonlyArray<string>;
  input: RemovePaymentInput;
};
export type RemovePaymentMutation$data = {
  readonly removePayment: {
    readonly deletedPaymentId: string | null;
    readonly errors: any | null;
    readonly invoice: {
      readonly amountPaid: number | null;
      readonly id: string;
      readonly status: InvoiceModelStatus;
    } | null;
    readonly success: boolean | null;
  } | null;
};
export type RemovePaymentMutation = {
  response: RemovePaymentMutation$data;
  variables: RemovePaymentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedPaymentId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "InvoiceNode",
  "kind": "LinkedField",
  "name": "invoice",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amountPaid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemovePaymentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemovePaymentPayload",
        "kind": "LinkedField",
        "name": "removePayment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemovePaymentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemovePaymentPayload",
        "kind": "LinkedField",
        "name": "removePayment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteEdge",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedPaymentId",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e3fa83436a73da8ed326c83601bfe28c",
    "id": null,
    "metadata": {},
    "name": "RemovePaymentMutation",
    "operationKind": "mutation",
    "text": "mutation RemovePaymentMutation(\n  $input: RemovePaymentInput!\n) {\n  removePayment(input: $input) {\n    success\n    errors\n    deletedPaymentId\n    invoice {\n      id\n      amountPaid\n      status\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "18c76ab69796f8b97bc65878fd78a096";

export default node;
