import React from "react";
import type { FC } from "react";

import emoji from "emoji-dictionary";
import { Center, Text, Button, VStack } from "native-base";
import { Platform } from "react-native";

interface Props {
    bannerText?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    navigation?: any;
}

const ProblemSplash: FC<Props> = (props) => {
    const { bannerText, navigation } = props;

    return (
        <Center bg="surface.100" flex={1} px="8">
            <VStack alignItems="center" space={4}>
                <Text fontSize="2xl" fontWeight="600" textAlign="center">
                    {typeof bannerText === "string"
                        ? bannerText
                        : "Sorry, something went wrong..."}
                </Text>
                <Text fontSize="6xl">
                    {emoji.getUnicode("face_with_head_bandage")}
                </Text>
                {navigation?.canGoBack() ? (
                    <Button
                        _text={{ fontSize: "lg" }}
                        borderRadius="full"
                        colorScheme="surface"
                        mt="5"
                        onPress={() => {
                            navigation.goBack();
                        }}
                        px="4"
                    >
                        Go back
                    </Button>
                ) : (
                    <>
                        <Text
                            key="reload"
                            fontSize="2xl"
                            fontWeight="600"
                            textAlign="center"
                        >
                            {Platform.OS === "web"
                                ? "Try hitting refresh."
                                : "Try reloading the app."}
                        </Text>
                        <Text
                            key="contact"
                            fontSize="md"
                            pt="8"
                            textAlign="center"
                        >
                            {
                                "Please get in touch if the problem doesn't go away."
                            }
                        </Text>
                    </>
                )}
            </VStack>
        </Center>
    );
};

export default ProblemSplash;
