/**
 * @generated SignedSource<<e1849c84d996f16592aa6ac0b31bc033>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReadInGoogleRegistersInput = {
  block: number;
  clientMutationId?: string | null;
  numberOfRegistersReadIn?: number | null;
  registersInBatch: number;
  startingYear: number;
  teacherIds: ReadonlyArray<string | null>;
};
export type ReadInGoogleRegistersMutation$variables = {
  input: ReadInGoogleRegistersInput;
};
export type ReadInGoogleRegistersMutation$data = {
  readonly readInGoogleRegisters: {
    readonly erroredTeacher: {
      readonly id: string;
      readonly user: {
        readonly email: string;
        readonly firstName: string;
        readonly lastName: string;
      };
    } | null;
    readonly errors: any | null;
    readonly numberOfRegistersReadIn: number | null;
    readonly profileGroup: {
      readonly id: string;
      readonly readInRegistersProgress: number | null;
    } | null;
    readonly success: boolean | null;
    readonly totalToReadIn: number | null;
  } | null;
};
export type ReadInGoogleRegistersMutation = {
  response: ReadInGoogleRegistersMutation$data;
  variables: ReadInGoogleRegistersMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalToReadIn",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfRegistersReadIn",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "ProfileGroupNode",
  "kind": "LinkedField",
  "name": "profileGroup",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readInRegistersProgress",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReadInGoogleRegistersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReadInGoogleRegistersPayload",
        "kind": "LinkedField",
        "name": "readInGoogleRegisters",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "erroredTeacher",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DjangoUserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReadInGoogleRegistersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReadInGoogleRegistersPayload",
        "kind": "LinkedField",
        "name": "readInGoogleRegisters",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "erroredTeacher",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DjangoUserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4b5fc914fc94c8f336c7ff9d1e6b1649",
    "id": null,
    "metadata": {},
    "name": "ReadInGoogleRegistersMutation",
    "operationKind": "mutation",
    "text": "mutation ReadInGoogleRegistersMutation(\n  $input: ReadInGoogleRegistersInput!\n) {\n  readInGoogleRegisters(input: $input) {\n    success\n    errors\n    totalToReadIn\n    numberOfRegistersReadIn\n    erroredTeacher {\n      id\n      user {\n        firstName\n        lastName\n        email\n        id\n      }\n    }\n    profileGroup {\n      id\n      readInRegistersProgress\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bd57ac3c4a999d6973ad5e073e934412";

export default node;
