import React from "react";
import type { ReactElement, ComponentProps } from "react";

import { Box, Text, Pressable } from "native-base";

type BoxProps = ComponentProps<typeof Box>;

interface Props extends BoxProps {
    children?: string | number | ReactElement | null;
    hasInnerPressable?: boolean;
    isHeader?: boolean;
    isHidden?: boolean;
    onPress?: () => void;
    textStyle?: ComponentProps<typeof Text>;
}

const InvoiceTableCell = (props: Props): ReactElement => {
    const {
        children,
        hasInnerPressable,
        isHeader,
        isHidden,
        onPress,
        textStyle,
    } = props;

    return (
        <Box
            {...(isHidden
                ? { bg: "transparent", borderColor: "transparent" }
                : isHeader
                  ? { bg: "primary.50", borderColor: "primary.200" }
                  : { bg: "transparent", borderColor: "surface.200" })}
            borderRadius="0.5em"
            borderWidth={1}
            flex={1}
            height="2.9em"
            m="2px"
            p="0.5em"
            {...props}
        >
            {!isHidden ? (
                !hasInnerPressable ? (
                    <Pressable disabled={!onPress} onPress={() => onPress?.()}>
                        <Text
                            fontSize="18"
                            fontWeight={isHeader ? "bold" : "light"}
                            width="100%"
                            {...textStyle}
                        >
                            {children ?? ""}
                        </Text>
                    </Pressable>
                ) : (
                    <Text
                        fontSize="18"
                        fontWeight={isHeader ? "bold" : "light"}
                        width="100%"
                        {...textStyle}
                    >
                        {children ?? ""}
                    </Text>
                )
            ) : null}
        </Box>
    );
};

export default InvoiceTableCell;
