/**
 * @generated SignedSource<<c185cd2ee6c76d19a485ce275a3e2139>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PayslipModelStatus = "NOT_VIEWED" | "PAID" | "READY_TO_PAY" | "%future added value";
export type LoadPayslipQuery$variables = {
  payslipId: string;
};
export type LoadPayslipQuery$data = {
  readonly payslip: {
    readonly adjustedReason: string | null;
    readonly amountAdjusted: number | null;
    readonly amountDue: number | null;
    readonly id: string;
    readonly paidFromDate: string | null;
    readonly paidToDate: string | null;
    readonly status: PayslipModelStatus;
    readonly teacher: {
      readonly accountNumber: string | null;
      readonly id: string;
      readonly user: {
        readonly email: string;
        readonly firstName: string;
        readonly lastName: string;
      };
    } | null;
    readonly totalMinutesTaught: number | null;
  } | null;
};
export type LoadPayslipQuery = {
  response: LoadPayslipQuery$data;
  variables: LoadPayslipQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "payslipId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "payslipId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amountDue",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amountAdjusted",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adjustedReason",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paidFromDate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paidToDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalMinutesTaught",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumber",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoadPayslipQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PayslipNode",
        "kind": "LinkedField",
        "name": "payslip",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "teacher",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DjangoUserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoadPayslipQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PayslipNode",
        "kind": "LinkedField",
        "name": "payslip",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "teacher",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DjangoUserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bd14b1c858c68132d792d6ebeb8c81fa",
    "id": null,
    "metadata": {},
    "name": "LoadPayslipQuery",
    "operationKind": "query",
    "text": "query LoadPayslipQuery(\n  $payslipId: ID!\n) {\n  payslip(id: $payslipId) {\n    id\n    amountDue\n    status\n    amountAdjusted\n    adjustedReason\n    paidFromDate\n    paidToDate\n    totalMinutesTaught\n    teacher {\n      id\n      user {\n        firstName\n        lastName\n        email\n        id\n      }\n      accountNumber\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5d616e7495f743626e17b9a837d024d5";

export default node;
