import { graphql } from "react-relay";

export const update_account = graphql`
    mutation UpdateAccountMutation($input: UpdateAccountInput!) {
        updateAccount(input: $input) {
            success
            errors
        }
    }
`;
