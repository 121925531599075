import { graphql } from "react-relay";

export const create_payrate = graphql`
    mutation CreatePayrateMutation(
        $connections: [ID!]!
        $input: CreatePayrateInput!
    ) {
        createPayrate(input: $input) {
            success
            errors
            payrate
                @prependNode(
                    connections: $connections
                    edgeTypeName: "PayrateNodeEdge"
                ) {
                id
                startDate
                hourlyRate
            }
        }
    }
`;
