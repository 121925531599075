import { graphql } from "react-relay";

export const send_password_reset_email = graphql`
    mutation SendPasswordResetEmailMutation(
        $input: SendPasswordResetEmailInput!
    ) {
        sendPasswordResetEmail(input: $input) {
            success
            errors
        }
    }
`;
