import { graphql } from "react-relay";

export const load_teaching_days = graphql`
    query LoadTeachingDaysQuery($startingYear: Int, $block: Int) {
        me {
            profile {
                id
                teachingDays(startingYear: $startingYear, block: $block)
            }
        }
    }
`;
