import React, { useState } from "react";
import type { ReactElement, ComponentProps, Dispatch } from "react";

import { Box, Text, Pressable, Tooltip, Spinner } from "native-base";
import type { LessonData } from "pianofunclub-shared/types";
import { Platform } from "react-native";

import type {
    ReducerValues as AccountScreenReducerValues,
    ReducerTypes as AccountScreenReducerTypes,
} from "pianofunclub-crm/screens/accounts/AccountScreen";

import type {
    ReducerValues as TimetableScreenReducerValues,
    ReducerTypes as TimetableScreenReducerTypes,
} from "pianofunclub-teaching-app/screens/TimetableScreen";

import {
    getFullName,
    getInstrumentIcon,
    getLessonTypeIcon,
} from "pianofunclub-shared/utils/extractors";
import type { Action, State } from "pianofunclub-shared/utils/reducers";

type BoxProps = ComponentProps<typeof Box>;

interface Props extends BoxProps {
    dispatchState: Dispatch<
        Action<
            AccountScreenReducerValues & TimetableScreenReducerValues,
            AccountScreenReducerTypes | TimetableScreenReducerTypes
        >
    >;
    index: number;
    instrument: string | undefined | null;
    lessonData: LessonData;
    lessonTimestampDate: Date;
    lessonTimestampString: string;
    lessonType: string;
    pupilFirstName: string | undefined;
    pupilLastName: string | undefined;
    schoolAddress: string;
    state: State<AccountScreenReducerValues | TimetableScreenReducerValues>;
}

const TimetableLesson = (props: Props): ReactElement | null => {
    const {
        dispatchState,
        index,
        instrument,
        lessonData,
        lessonTimestampDate,
        lessonTimestampString,
        lessonType,
        pupilFirstName,
        pupilLastName,
        schoolAddress,
        state,
    } = props;

    const [lessonTooltipVisible, setLessonTooltipVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Tooltip
            isOpen={lessonTooltipVisible}
            label={
                pupilFirstName || pupilLastName
                    ? getFullName(pupilFirstName, pupilLastName)
                    : "Free Slot"
            }
            textAlign="center"
        >
            <Pressable
                flex={1}
                flexDirection="column"
                height="100%"
                onHoverIn={() => setLessonTooltipVisible(true)}
                onHoverOut={() => setLessonTooltipVisible(false)}
                onPress={() => {
                    if (Platform.OS === "web") {
                        dispatchState({
                            input: "timetableLessonModalInfo",
                            value: {
                                lessonData: lessonData,
                                lessonTimestampString: lessonTimestampString,
                                lessonTimestampDate: lessonTimestampDate,
                                schoolAddress: schoolAddress,
                                lessonType: lessonType,
                                dayIndex: lessonTimestampDate.getDay() - 1,
                            },
                        });
                    } else {
                        // use a loading indicator on the tablet app because modal
                        // is slow to appear
                        setIsLoading(true);
                        setTimeout(() => {
                            dispatchState({
                                input: "timetableLessonModalInfo",
                                value: {
                                    lessonData: lessonData,
                                    lessonTimestampString:
                                        lessonTimestampString,
                                    lessonTimestampDate: lessonTimestampDate,
                                    schoolAddress: schoolAddress,
                                    lessonType: lessonType,
                                    dayIndex: lessonTimestampDate.getDay() - 1,
                                },
                            });
                            setIsLoading(false);
                        }, 1);
                    }
                }}
            >
                <Box
                    alignItems="center"
                    bg={
                        lessonType === "INDIVIDUAL"
                            ? "primary.100"
                            : lessonType === "PAIRED"
                              ? "secondary.200"
                              : "primary.200"
                    }
                    borderRadius="lg"
                    flex={1}
                    flexDirection="row"
                    ml={index !== 0 ? "0" : undefined}
                    mx={"1"}
                    px="2"
                >
                    <Text
                        color={!pupilFirstName ? "surface.700" : undefined}
                        flex={1}
                        fontFamily="Poppins-Regular"
                        isTruncated
                        mr="1"
                    >
                        {pupilFirstName
                            ? state.values.timetableView === "DAY"
                                ? getFullName(pupilFirstName, pupilLastName)
                                : pupilFirstName
                            : "Free Slot"}
                    </Text>
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <>
                            {getLessonTypeIcon(lessonType, {
                                size: "3",
                                mr: "2",
                                minWidth: "4",
                                color: !pupilFirstName
                                    ? "surface.700"
                                    : "coolGray.800",
                            })}
                            {instrument
                                ? getInstrumentIcon(instrument, {
                                      color: !pupilFirstName
                                          ? "surface.700"
                                          : "coolGray.800",
                                      size: 4,
                                  })
                                : null}
                        </>
                    )}
                </Box>
            </Pressable>
        </Tooltip>
    );
};

export default React.memo(TimetableLesson);
