import React, {
    Suspense,
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
} from "react";
import type { FC, ReactElement, Dispatch, RefObject } from "react";

import emoji from "emoji-dictionary";
import * as Clipboard from "expo-clipboard";
import {
    Box,
    Heading,
    VStack,
    HStack,
    useTheme,
    Button,
    Text,
    useToast,
    useDisclose,
    Center,
} from "native-base";
import { useWindowDimensions } from "react-native";
import {
    useMutation,
    useQueryLoader,
    usePreloadedQuery,
    useRelayEnvironment,
    usePaginationFragment,
    useRefetchableFragment,
} from "react-relay";
import type { PreloadedQuery } from "react-relay";
import {
    RecyclerListView,
    DataProvider,
    LayoutProvider,
} from "recyclerlistview";
import { fetchQuery } from "relay-runtime";
import type { Subscription } from "relay-runtime";

import { useAuth } from "pianofunclub-shared/providers/AuthProvider";
import { useData } from "pianofunclub-shared/providers/DataProvider";

import LoadingBlobs from "pianofunclub-shared/components/Animations/LoadingBlobs";
import Column from "pianofunclub-shared/components/Base/Column";
import Row from "pianofunclub-shared/components/Base/Row";
import type { TableData } from "pianofunclub-shared/components/Base/Row";
import ButtonDebounced from "pianofunclub-shared/components/Buttons/ButtonDebounced";
import TextInput from "pianofunclub-shared/components/Inputs/TextInput";
import ListEmptyBanner from "pianofunclub-shared/components/ListItems/ListEmptyBanner";
import SchoolMultiSelectModal from "pianofunclub-shared/components/Modals/SchoolMultiSelectModal";
import Actionsheet from "pianofunclub-shared/components/NativeBaseExtended/Actionsheet";
import AlertPopup from "pianofunclub-shared/components/NativeBaseExtended/AlertPopup";
import Select from "pianofunclub-shared/components/NativeBaseExtended/Select";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import CustomFlatListSpinner from "pianofunclub-shared/components/Other/CustomFlatListSpinner";
import {
    BankTransferIcon,
    CardIcon,
    CopyIcon,
    DraftFilledIcon,
    InvoiceIcon,
    NotificationIcon,
    RestartIcon,
    SendIcon,
} from "pianofunclub-shared/components/Other/Icons";
import SearchBar from "pianofunclub-shared/components/Other/SearchBar";

import type { InvoicingHeadlineDataFragment_query$key } from "pianofunclub-shared/relay/graphql/fragments/__generated__/InvoicingHeadlineDataFragment_query.graphql";
import type { InvoicingHeadlineDataRefreshQuery } from "pianofunclub-shared/relay/graphql/fragments/__generated__/InvoicingHeadlineDataRefreshQuery.graphql";
import { invoicing_headline_data_fragment } from "pianofunclub-shared/relay/graphql/fragments/InvoicingHeadlineDataFragment";
import type { LoadMutationProgressQuery } from "pianofunclub-shared/relay/graphql/general/__generated__/LoadMutationProgressQuery.graphql";
import { load_mutation_progress } from "pianofunclub-shared/relay/graphql/general/LoadMutationProgress";
import type {
    GenerateOrUpdateDraftInvoicesForBlockMutation,
    GenerateOrUpdateDraftInvoicesForBlockMutation$data,
} from "pianofunclub-shared/relay/graphql/invoicing/__generated__/GenerateOrUpdateDraftInvoicesForBlockMutation.graphql";
import type {
    LoadInvoices_query_invoices$key,
    LoadInvoices_query_invoices$data,
} from "pianofunclub-shared/relay/graphql/invoicing/__generated__/LoadInvoices_query_invoices.graphql";
import type { LoadInvoicesPaginationQuery } from "pianofunclub-shared/relay/graphql/invoicing/__generated__/LoadInvoicesPaginationQuery.graphql";
import type { LoadInvoicesQuery } from "pianofunclub-shared/relay/graphql/invoicing/__generated__/LoadInvoicesQuery.graphql";
import type {
    MatchBankPaymentsToInvoicesMutation,
    MatchBankPaymentsToInvoicesMutation$data,
} from "pianofunclub-shared/relay/graphql/invoicing/__generated__/MatchBankPaymentsToInvoicesMutation.graphql";
import type {
    MatchCardPaymentsToInvoicesMutation,
    MatchCardPaymentsToInvoicesMutation$data,
} from "pianofunclub-shared/relay/graphql/invoicing/__generated__/MatchCardPaymentsToInvoicesMutation.graphql";
import type {
    SendDraftInvoicesForBlockMutation,
    SendDraftInvoicesForBlockMutation$data,
} from "pianofunclub-shared/relay/graphql/invoicing/__generated__/SendDraftInvoicesForBlockMutation.graphql";
import type {
    SendRemindersForUnpaidInvoicesForBlockMutation,
    SendRemindersForUnpaidInvoicesForBlockMutation$data,
} from "pianofunclub-shared/relay/graphql/invoicing/__generated__/SendRemindersForUnpaidInvoicesForBlockMutation.graphql";
import { generate_or_update_draft_invoices_for_block } from "pianofunclub-shared/relay/graphql/invoicing/GenerateOrUpdateDraftInvoicesForBlock";
import {
    load_invoices,
    load_invoices_pagination,
} from "pianofunclub-shared/relay/graphql/invoicing/LoadInvoices";
import { match_bank_payments_to_invoices } from "pianofunclub-shared/relay/graphql/invoicing/MatchBankPaymentsToInvoices";
import { match_card_payments_to_invoices } from "pianofunclub-shared/relay/graphql/invoicing/MatchCardPaymentsToInvoices";
import { send_draft_invoices_for_block } from "pianofunclub-shared/relay/graphql/invoicing/SendDraftInvoicesForBlock";
import { send_reminders_for_unpaid_invoices_for_block } from "pianofunclub-shared/relay/graphql/invoicing/SendRemindersForUnpaidInvoicesForBlock";

import type { Mutable } from "pianofunclub-shared/types";
import { BLOCKS, INVOICE_STATUS } from "pianofunclub-shared/utils/constants";
import {
    getScaledWindowDimension,
    roundToDecimalPlace,
    titleCaseConverter,
} from "pianofunclub-shared/utils/converters";
import {
    getCurrentBlock,
    getErroredInvoiceLabel,
    getOrderBy,
    getDefaultStartingYearAndStartingYearOptions,
    getTermInWords,
    sortIconExtractor,
    getFullName,
} from "pianofunclub-shared/utils/extractors";
import { useDebounceFunction } from "pianofunclub-shared/utils/hooks";
import { createReducer } from "pianofunclub-shared/utils/reducers";
import type { State, Action } from "pianofunclub-shared/utils/reducers";

import type {
    InvoicingStackNavigatorProps,
    InvoicingStackRouteProps,
} from "pianofunclub-crm/navigation/InvoicingNavigator";

import InvoiceMutationProgressIndicators from "pianofunclub-crm/components/Buttons/InvoiceMutationProgressIndicators";
import type { InvoiceMutationProgressIndicatorsRef } from "pianofunclub-crm/components/Buttons/InvoiceMutationProgressIndicators";
import InvoiceTableRow from "pianofunclub-crm/components/ListItems/InvoiceTableRow";

import { invoicingHubTableCsvConverter, missingPupilsCsvConverter } from "pianofunclub-crm/utils/tableCsvConverters";

type NavigationProps = InvoicingStackNavigatorProps<"InvoicingHub">;

type RouteProps = InvoicingStackRouteProps<"InvoicingHub">;

interface ScreenProps {
    navigation: NavigationProps;
    route: RouteProps;
}

interface ContentProps {
    dispatchState: Dispatch<Action<ReducerValues, ReducerTypes>>;
    loadInvoicesQueryReference: PreloadedQuery<
        LoadInvoicesQuery,
        Record<string, unknown>
    >;
    navigation: NavigationProps;
    progressIndicatorRef: RefObject<InvoiceMutationProgressIndicatorsRef>;
    state: State<ReducerValues>;
}

export type ShowMutationProgressIndicators = {
    generateDrafts: boolean;
    matchPayments: boolean;
    sendDrafts: boolean;
    sendReminders: boolean;
};

export type ReducerValues = {
    block: number;
    contentIsRendered: boolean;
    copyDataToClipboard?: () => void;
    dataProvider: DataProvider;
    generateDraftsModalIsOpen: boolean;
    googleDriveCsvUrl?: string;
    headlineData: TableData[];
    isRefetching: boolean;
    layoutProvider?: LayoutProvider;
    matchPaymentsModalOpenType: "BANK" | "CARD" | null;
    orderBy?: string;
    preferedCommunicationType: string;
    refreshHandler?: () => void;
    reminderNumber: number;
    school?: string;
    searchTerm: string;
    sendDraftsModalIsOpen: boolean;
    sendRemindersModalIsOpen: boolean;
    sendRemindersOptionsModalIsOpen: boolean;
    showMutationProgressIndicators: ShowMutationProgressIndicators;
    startingYear: number;
    status?: string;
    subscription?: Subscription;
};

export type ReducerTypes =
    | string
    | number
    | boolean
    | TableData[]
    | Subscription
    | DataProvider
    | LayoutProvider
    | (() => void)
    | ShowMutationProgressIndicators
    | null
    | undefined;

const LOAD_X_INVOICES = 100;
const TABLE_BORDER_COLOR = "surface.400";
const TABLE_BORDER_WIDTH = 1;
const TABLE_BORDER_RADIUS = 10;
const FLEX_ARRAY = [1, 1.5, 2, 1.8, 1.5, 1, 1.2, 1.2, 1.2, 1.2];
const INVOICES_TO_GENERATE_IN_BATCH = 6;
const INVOICES_TO_SEND_IN_BATCH = 15;
const REMINDERS_TO_SEND_IN_BATCH = 30;

const InvoicingHubContent: FC<ContentProps> = (props) => {
    const {
        dispatchState,
        loadInvoicesQueryReference,
        navigation,
        progressIndicatorRef,
        state,
    } = props;

    const data = usePreloadedQuery(load_invoices, loadInvoicesQueryReference);

    const [invoicingHeadlineData, refetchInvoicingHeadlineData] =
        useRefetchableFragment<
            InvoicingHeadlineDataRefreshQuery,
            InvoicingHeadlineDataFragment_query$key
        >(invoicing_headline_data_fragment, data);

    const [loadMutationProgressQueryReference, loadMutationProgressQuery] =
        useQueryLoader<LoadMutationProgressQuery>(load_mutation_progress);

    const { user } = useAuth();
    const toast = useToast();

    useEffect(() => {
        if (user?.profile?.id) {
            loadMutationProgressQuery(
                {
                    profileId: user.profile.id,
                    startingYear: state.values.startingYear,
                    block: state.values.block,
                    skipInvoiceTotals: false,
                },
                { fetchPolicy: "network-only" },
            );
        }
    }, [
        loadMutationProgressQuery,
        user?.profile?.id,
        state.values.startingYear,
        state.values.block,
    ]);

    const {
        data: invoicesData,
        hasNext,
        isLoadingNext,
        loadNext,
        refetch: refresh,
    } = usePaginationFragment<
        LoadInvoicesPaginationQuery,
        LoadInvoices_query_invoices$key
    >(load_invoices_pagination, data);

    useEffect(() => {
        if (
            invoicesData.invoices?.edges &&
            invoicesData.invoices.edges.length > 0
        ) {
            dispatchState({
                input: "dataProvider",
                value: state.values.dataProvider.cloneWithRows(
                    invoicesData.invoices.edges as Mutable<
                        typeof invoicesData.invoices.edges
                    >,
                ),
            });
            // if there is no data, create a fresh DataProvider
        } else {
            dispatchState({
                input: "dataProvider",
                value: new DataProvider((r1, r2) => {
                    return r1 !== r2;
                }),
            });
        }

        dispatchState({
            input: "refreshHandler",
            value: () => {
                dispatchState({ input: "contentIsRendered", value: false });
                refresh(
                    {
                        first: LOAD_X_INVOICES,
                        searchTerm:
                            state.values.searchTerm.trim() !== ""
                                ? state.values.searchTerm.trim()
                                : undefined,
                        startingYear: state.values.startingYear,
                        block: state.values.block,
                        school: state.values.school,
                        orderBy: state.values.orderBy,
                    },
                    {
                        fetchPolicy: "network-only",
                    },
                );
                refetchInvoicingHeadlineData(
                    {
                        startingYear: state.values.startingYear,
                        block: state.values.block,
                    },
                    {
                        fetchPolicy: "network-only",
                    },
                );
            },
        });

        dispatchState({
            input: "copyDataToClipboard",
            value: () => {
                Clipboard.setStringAsync(
                    invoicingHubTableCsvConverter(
                        invoicesData.invoices?.edges ?? [],
                    ),
                );
                toast.show({
                    render: ({ id }: { id: string }) => (
                        <ToastAlert
                            id={id}
                            status="success"
                            title={"Copied Data to Clipboard"}
                            toast={toast}
                        />
                    ),
                });
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoicesData]);

    // update the headline stats
    useEffect(() => {
        dispatchState({
            input: "headlineData",
            value: [
                state.values.headlineData[0],
                [
                    {
                        data: `${
                            invoicingHeadlineData.me?.profile?.totalInvoices?.toLocaleString() ??
                            "0"
                        } (${
                            invoicingHeadlineData.me?.profile
                                ?.percentLessonBlocksInvoicedFor
                        }%)`,
                        tooltipLabel:
                            invoicingHeadlineData.me?.profile
                                ?.lessonBlocksNotInvoicedFor?.length
                                ? `Missing pupils:\n${invoicingHeadlineData.me?.profile?.lessonBlocksNotInvoicedFor
                                      ?.slice(0, 21)
                                      .map((item, index) => {
                                          if (index === 20) {
                                              return `...and ${
                                                  (invoicingHeadlineData.me
                                                      ?.profile
                                                      ?.lessonBlocksNotInvoicedFor
                                                      ?.length ?? 20) - 20
                                              } more`;
                                          }
                                          return getFullName(
                                              item?.pupil?.user.firstName,
                                              item?.pupil?.user.lastName,
                                          );
                                      })
                                      ?.join("\n")}`
                                : undefined,
                        onPress: () => {
                            Clipboard.setStringAsync(
                                missingPupilsCsvConverter(
                                    invoicingHeadlineData.me?.profile?.lessonBlocksNotInvoicedFor ?? [],
                                ),
                            );
                            toast.show({
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        id={id}
                                        status="success"
                                        title={"Copied All Missing Pupils Data to Clipboard"}
                                        toast={toast}
                                    />
                                ),
                            });
                        },
                    },
                    {
                        data: `${
                            invoicingHeadlineData.me?.profile?.totalInvoicesSent?.toLocaleString() ??
                            0
                        } (${roundToDecimalPlace(
                            ((invoicingHeadlineData.me?.profile
                                ?.totalInvoicesSent ?? 0) /
                                ((invoicingHeadlineData.me?.profile
                                    ?.totalInvoices ?? 0) > 0
                                    ? (invoicingHeadlineData.me?.profile
                                          ?.totalInvoices ?? 1)
                                    : 1)) *
                                100,
                            1,
                        )}%)`,
                    },
                    {
                        data: `£${
                            invoicingHeadlineData.me?.profile?.totalAmountInvoiced?.toLocaleString() ??
                            0
                        }`,
                    },
                    {
                        data: `£${
                            invoicingHeadlineData.me?.profile?.totalAmountPaid?.toLocaleString() ??
                            0
                        } (${roundToDecimalPlace(
                            ((invoicingHeadlineData.me?.profile
                                ?.totalAmountPaid ?? 0) /
                                ((invoicingHeadlineData.me?.profile
                                    ?.totalAmountInvoiced ?? 0) > 0
                                    ? (invoicingHeadlineData.me?.profile
                                          ?.totalAmountInvoiced ?? 1)
                                    : 1)) *
                                100,
                            1,
                        )}%)`,
                    },
                    {
                        data:
                            invoicingHeadlineData.me?.profile?.totalInvoicesUnpaid?.toLocaleString() ??
                            "",
                    },
                ],
            ],
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        invoicingHeadlineData.me?.profile?.lessonBlocksNotInvoicedFor,
        invoicingHeadlineData.me?.profile?.percentLessonBlocksInvoicedFor,
        invoicingHeadlineData.me?.profile?.totalAmountInvoiced,
        invoicingHeadlineData.me?.profile?.totalAmountPaid,
        invoicingHeadlineData.me?.profile?.totalInvoices,
        invoicingHeadlineData.me?.profile?.totalInvoicesSent,
        invoicingHeadlineData.me?.profile?.totalInvoicesUnpaid,
    ]);

    const { colors } = useTheme();

    const onEndReached = useCallback(() => {
        if (hasNext && !isLoadingNext) {
            loadNext(LOAD_X_INVOICES);
        }
    }, [hasNext, isLoadingNext, loadNext]);

    // stop refetch useEffects firing on first render
    const environment = useRelayEnvironment();
    const renderCount = useRef(0);

    const refetchInvoices = useCallback(
        (variables: {
            block: number;
            first: number;
            orderBy?: string;
            school?: string;
            searchTerm?: string;
            startingYear: number;
            status?: string;
        }): Subscription | undefined => {
            dispatchState({ input: "isRefetching", value: true });

            const subscription = fetchQuery<LoadInvoicesQuery>(
                environment,
                load_invoices,
                variables,
                { fetchPolicy: "store-or-network" },
            ).subscribe({
                complete: () => {
                    dispatchState({ input: "isRefetching", value: false });
                    refetchInvoicingHeadlineData(variables, {
                        fetchPolicy: "store-only",
                    });
                    refresh(variables, { fetchPolicy: "store-only" });
                },
                error: () => {
                    dispatchState({ input: "isRefetching", value: false });
                },
            });
            return subscription;
        },
        [dispatchState, environment, refetchInvoicingHeadlineData, refresh],
    );

    useEffect(() => {
        if (renderCount.current < 2) {
            renderCount.current += 1;
            return;
        }

        dispatchState({ input: "isRefetching", value: false });
        state.values.subscription?.unsubscribe();

        // fire refetch immediately when orderBy or filters change
        dispatchState({
            input: "subscription",
            value: refetchInvoices({
                first: LOAD_X_INVOICES,
                searchTerm:
                    state.values.searchTerm.trim() !== ""
                        ? state.values.searchTerm.trim()
                        : undefined,
                startingYear: state.values.startingYear,
                block: state.values.block,
                status: state.values.status,
                school: state.values.school,
                orderBy: state.values.orderBy,
            }),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        state.values.block,
        state.values.orderBy,
        state.values.status,
        state.values.school,
        state.values.startingYear,
    ]);

    useDebounceFunction(
        () => {
            if (renderCount.current < 2) {
                renderCount.current += 1;
                return;
            }
            dispatchState({ input: "isRefetching", value: false });
            state.values.subscription?.unsubscribe();

            // remove any applied sorting when we search (sort by relevance instead)
            // and reapply sorting if search text is blank
            const orderBy =
                state.values.searchTerm.trim() === ""
                    ? "assignedTo__accountNumber"
                    : undefined;

            dispatchState({ input: "orderBy", value: orderBy });
            navigation.setParams({ orderBy: orderBy });
            dispatchState({
                input: "subscription",
                value: refetchInvoices({
                    first: LOAD_X_INVOICES,
                    searchTerm:
                        state.values.searchTerm.trim() !== ""
                            ? state.values.searchTerm.trim()
                            : undefined,
                    startingYear: state.values.startingYear,
                    block: state.values.block,
                    status: state.values.status,
                    school: state.values.school,
                    orderBy: orderBy,
                }),
            });
        },
        // no delay if clearing text
        state.values.searchTerm !== "" ? 750 : 0,

        [state.values.searchTerm],
    );

    const { scale, width: windowWidth } = useWindowDimensions();

    useEffect(() => {
        // this allows the RLV to resize when the window resizes
        dispatchState({
            input: "layoutProvider",
            value: new LayoutProvider(
                () => "VSEL",
                (type, dim) => {
                    switch (type) {
                        case "VSEL":
                            dim.width = getScaledWindowDimension(
                                windowWidth,
                                scale,
                            );
                            dim.height = 40;
                            break;
                        default:
                            dim.width = 0;
                            dim.height = 0;
                    }
                },
            ),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowWidth, scale]);

    const onPressProfile = useCallback(
        (
            profileId: string | null | undefined,
            accountType: "PARENT" | "PUPIL",
        ) => {
            if (!profileId) {
                return;
            }

            navigation.navigate("Account", {
                profileId,
                accountType,
                block: state.values.block,
                startingYear: state.values.startingYear,
            });
        },
        [navigation, state.values.block, state.values.startingYear],
    );

    const onPressInvoice = useCallback(
        (invoiceId: string | null | undefined) => {
            if (!invoiceId) {
                return;
            }

            navigation.navigate("Invoice", {
                invoiceId: invoiceId,
            });
        },
        [navigation],
    );

    const rowRenderer = useCallback(
        (
            _: unknown,
            data: NonNullable<
                LoadInvoices_query_invoices$data["invoices"]
            >["edges"][0],
            index: number,
        ): ReactElement | null => {
            if (data?.node) {
                return (
                    <Box
                        borderColor={TABLE_BORDER_COLOR}
                        borderLeftWidth={TABLE_BORDER_WIDTH}>
                        <InvoiceTableRow
                            cellProps={{
                                hideTopBorder: index === 0,
                                pressableTextProps: {
                                    fontFamily: "Poppins-Regular",
                                },
                                textProps: {
                                    fontSize: "md",
                                    textAlign: "center",
                                    fontFamily: "Poppins-Light",
                                },
                                px: 2,
                            }}
                            data={data.node}
                            flexArray={FLEX_ARRAY}
                            onPressInvoice={onPressInvoice}
                            onPressProfile={onPressProfile}
                            rowHeight={10}
                            tableBorderColor={TABLE_BORDER_COLOR}
                            tableBorderWidth={TABLE_BORDER_WIDTH}
                        />
                    </Box>
                );
            } else {
                return null;
            }
        },
        [onPressInvoice, onPressProfile],
    );

    const renderFooter = useCallback(() => {
        return isLoadingNext ? (
            <CustomFlatListSpinner
                borderColor={TABLE_BORDER_COLOR}
                borderTopWidth={1}
                position="relative"
                pt="8"
                top="0"
            />
        ) : null;
    }, [isLoadingNext]);

    const renderListEmptyBanner = useMemo(() => {
        return (
            <ListEmptyBanner explainer={"No invoices found..."}>
                <Text fontSize="6xl">{emoji.getUnicode("neutral_face")}</Text>
            </ListEmptyBanner>
        );
    }, []);

    useEffect(() => {
        dispatchState({ input: "contentIsRendered", value: true });
        // unsubscribe from refetch on unmount
        return () => state.values.subscription?.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadInvoicesQueryReference]);

    if (!state.values.contentIsRendered) {
        return <LoadingBlobs>Loading Invoices...</LoadingBlobs>;
    }

    return (
        <>
            <Box flex={1} mx="30px">
                {state.values.dataProvider.getSize() > 0 &&
                state.values.layoutProvider ? (
                    <RecyclerListView
                        canChangeSize
                        dataProvider={state.values.dataProvider}
                        layoutProvider={state.values.layoutProvider}
                        onEndReached={onEndReached}
                        onEndReachedThreshold={2000}
                        renderAheadOffset={800}
                        renderFooter={renderFooter}
                        rowRenderer={rowRenderer}
                        scrollViewProps={{
                            contentContainerStyle: {
                                borderColor:
                                    // @ts-expect-error can't index with variable
                                    colors?.[TABLE_BORDER_COLOR.split(".")[0]][
                                        TABLE_BORDER_COLOR.split(".")[1]
                                    ],
                                borderBottomWidth:
                                    (invoicesData.invoices?.edges.length ??
                                        0 > 0) &&
                                    !isLoadingNext
                                        ? TABLE_BORDER_WIDTH
                                        : 0,
                                marginBottom: 40,
                            },
                            showsVerticalScrollIndicator: false,
                        }}
                        style={{
                            flex: 1,
                            minHeight: 1,
                        }}
                        suppressBoundedSizeException
                        useWindowScroll
                    />
                ) : (
                    renderListEmptyBanner
                )}
            </Box>
            {loadMutationProgressQueryReference != null ? (
                <InvoiceMutationProgressIndicators
                    ref={progressIndicatorRef}
                    dispatchState={dispatchState}
                    loadMutationProgressQuery={loadMutationProgressQuery}
                    loadMutationProgressQueryReference={
                        loadMutationProgressQueryReference
                    }
                    state={state}
                />
            ) : null}
        </>
    );
};

const InvoicingHubScreen: FC<ScreenProps> = (props) => {
    const { navigation, route } = props;

    const { user } = useAuth();

    const [defaultStartingYear, startingYears] = useMemo(
        () => getDefaultStartingYearAndStartingYearOptions(),
        [],
    );

    const initialState = useMemo(() => {
        let initialStartingYear = route.params?.startingYear;
        if (initialStartingYear === undefined) {
            initialStartingYear =
                user?.profile?.profileGroup?.currentStartingYear ??
                defaultStartingYear;
            navigation.setParams({
                startingYear: initialStartingYear,
            });
        }
        let initialBlock = route.params?.block;
        if (initialBlock === undefined) {
            if (
                user?.profile?.profileGroup?.currentStartingYear &&
                user?.profile?.profileGroup?.currentStartingYear >
                    defaultStartingYear
            ) {
                initialBlock = 1;
            } else {
                initialBlock = user?.profile?.currentBlock ?? getCurrentBlock();
            }
            navigation.setParams({
                block: initialBlock,
            });
        }
        return {
            values: {
                startingYear: initialStartingYear,
                block: initialBlock,
                school: route.params?.school,
                searchTerm: route.params?.searchTerm ?? "",
                orderBy: route.params?.orderBy ?? "assignedTo__accountNumber",
                status: route.params?.status,
                contentIsRendered: false,
                isRefetching: false,
                subscription: undefined,
                dataProvider: new DataProvider((r1, r2) => {
                    return r1 !== r2;
                }),
                layoutProvider: undefined,
                refreshHandler: undefined,
                headlineData: [
                    [
                        { data: "No. Invoices" },
                        { data: "Sent" },
                        { data: "Total Invoiced" },
                        { data: "Paid" },
                    ],
                    [
                        { data: "" },
                        { data: "" },
                        { data: "" },
                        { data: "" },
                        { data: "" },
                    ],
                ],
                generateDraftsModalIsOpen: false,
                sendDraftsModalIsOpen: false,
                sendRemindersOptionsModalIsOpen: false,
                sendRemindersModalIsOpen: false,
                matchPaymentsModalOpenType: null,
                preferedCommunicationType: "TEXT",
                reminderNumber: 1,
                googleDriveCsvUrl: undefined,
                showMutationProgressIndicators: {
                    generateDrafts: false,
                    sendDrafts: false,
                    sendReminders: false,
                    matchPayments: false,
                },
            },
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [state, dispatchState] = useReducer(reducer, initialState);

    const [loadInvoicesQueryReference, loadInvoicesQuery] =
        useQueryLoader<LoadInvoicesQuery>(load_invoices);

    useEffect(() => {
        if (!state.values.contentIsRendered) {
            if (user?.profile) {
                loadInvoicesQuery(
                    {
                        first: LOAD_X_INVOICES,
                        searchTerm:
                            state.values.searchTerm.trim() !== ""
                                ? state.values.searchTerm.trim()
                                : undefined,
                        startingYear: state.values.startingYear,
                        block: state.values.block,
                        status: state.values.status,
                        school: state.values.school,
                        orderBy: state.values.orderBy,
                    },
                    { fetchPolicy: "store-or-network" },
                );
            }
        }
    }, [
        loadInvoicesQuery,
        state.values.contentIsRendered,
        state.values.block,
        state.values.orderBy,
        state.values.school,
        state.values.searchTerm,
        state.values.startingYear,
        state.values.status,
        user?.profile,
    ]);

    const sortHandler = useCallback(
        (column: string) => {
            const orderBy = getOrderBy(column, state.values.orderBy);
            dispatchState({
                input: "orderBy",
                value: orderBy,
            });
            navigation.setParams({
                orderBy: orderBy,
            });
        },
        [navigation, state.values.orderBy],
    );

    const { dataState } = useData();

    const schools = useMemo(() => {
        return dataState.values.schools?.edges ?? [];
    }, [dataState.values.schools?.edges]);

    const tableHeaders = useMemo(() => {
        const orderBy = state.values.orderBy;
        return [
            {
                data: "Account #",
                onPress: () => sortHandler("assignedTo__accountNumber"),
                icon: sortIconExtractor("assignedTo__accountNumber", orderBy),
            },
            {
                data: "Parent Name",
                onPress: () =>
                    sortHandler(
                        "assignedTo__user__firstName,assignedTo__user__lastName",
                    ),
                icon: sortIconExtractor(
                    "assignedTo__user__firstName,assignedTo__user__lastName",
                    orderBy,
                ),
            },
            {
                data: "Parent Email",
                onPress: () => sortHandler("assignedTo__user__email"),
                icon: sortIconExtractor("assignedTo__user__email", orderBy),
            },
            {
                data: "Parent Phone #",
            },
            {
                data: "Child(s) Name",
            },
            {
                data: "Amount",
                onPress: () => sortHandler("totalAmount"),
                icon: sortIconExtractor("totalAmount", orderBy),
            },
            {
                data: "Status",
                onPress: () => sortHandler("status"),
                icon: sortIconExtractor("status", orderBy),
            },
            {
                data: "Nudge 1",
                onPress: () => sortHandler("reminder1SentTimestamp"),
                icon: sortIconExtractor("reminder1SentTimestamp", orderBy),
            },
            {
                data: "Nudge 2",
                onPress: () => sortHandler("reminder2SentTimestamp"),
                icon: sortIconExtractor("reminder2SentTimestamp", orderBy),
            },
            {
                data: "Nudge 3",
                onPress: () => sortHandler("reminder3SentTimestamp"),
                icon: sortIconExtractor("reminder3SentTimestamp", orderBy),
            },
        ];
    }, [sortHandler, state.values.orderBy]);

    const headlineDataTotals = useMemo(() => {
        if (state.values.headlineData) {
            return {
                draftsToSend:
                    parseInt(
                        (
                            state.values.headlineData[1][0]?.data as string
                        )?.replace(",", ""),
                    ) -
                    parseInt(
                        (state.values.headlineData[1][1]?.data as string)
                            ?.split(" (")[0]
                            ?.replace(",", ""),
                    ),
                totalInvoicesSent: parseInt(
                    (state.values.headlineData[1][1]?.data as string)
                        ?.split(" (")[0]
                        ?.replace(",", ""),
                ),
                totalInvoicesUnpaid: parseInt(
                    (state.values.headlineData[1][4]?.data as string)?.replace(
                        ",",
                        "",
                    ),
                ),
            };
        }
    }, [state.values.headlineData]);

    const toast = useToast();
    const {
        isOpen: invoiceActionsActionsheetIsOpen,
        onClose: invoiceActionsActionsheetOnClose,
        onOpen: invoiceActionsActionsheetOnOpen,
    } = useDisclose();
    const progressIndicatorRef =
        useRef<InvoiceMutationProgressIndicatorsRef>(null);

    const [
        commitGenerateOrUpdateDraftInvoicesForBlock,
        generateOrUpdateDraftInvoicesForBlockInFlight,
    ] = useMutation<GenerateOrUpdateDraftInvoicesForBlockMutation>(
        generate_or_update_draft_invoices_for_block,
    );

    const generateOrUpdateDraftInvoicesForBlock = useCallback(
        (variables: {
            block: number;
            schoolIds: string[];
            startingYear: number;
        }) => {
            let hasErrored = false;
            const failedMutationItems: string[] = [];

            const generateOrUpdateDraftInvoicesForBlockConfig = (
                numberOfLessonBlocksProcessed: number | undefined,
                numberOfDraftsGenerated: number | undefined,
                totalToGenerate: number | undefined,
                schoolIds: string[],
            ) => {
                return {
                    variables: {
                        input: {
                            startingYear: variables.startingYear,
                            block: variables.block,
                            schoolIds: schoolIds,
                            totalToGenerate: totalToGenerate,
                            numberOfDraftsGenerated: numberOfDraftsGenerated,
                            numberOfLessonBlocksProcessed:
                                numberOfLessonBlocksProcessed,
                            invoicesInBatch: INVOICES_TO_GENERATE_IN_BATCH,
                        },
                        // required for update of invoice headline data on complete
                        startingYear: variables.startingYear,
                        block: variables.block,
                    },
                    onCompleted: (
                        response: GenerateOrUpdateDraftInvoicesForBlockMutation$data,
                    ) => {
                        if (
                            response.generateOrUpdateDraftInvoicesForBlock
                                ?.erroredInvoice
                        ) {
                            failedMutationItems.push(
                                getErroredInvoiceLabel(
                                    response
                                        .generateOrUpdateDraftInvoicesForBlock
                                        ?.erroredInvoice?.assignedTo
                                        ?.accountNumber,
                                    response
                                        .generateOrUpdateDraftInvoicesForBlock
                                        .erroredInvoice.assignedTo?.user
                                        .firstName,
                                    response
                                        .generateOrUpdateDraftInvoicesForBlock
                                        .erroredInvoice.assignedTo?.user
                                        .lastName,
                                    response
                                        .generateOrUpdateDraftInvoicesForBlock
                                        .erroredInvoice.assignedTo?.user.email,
                                ),
                            );

                            toast.show({
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        description={failedMutationItems.join(
                                            "\n",
                                        )}
                                        id={id}
                                        status="error"
                                        title={
                                            "Couldn't generate invoices for:"
                                        }
                                        toast={toast}
                                    />
                                ),
                                // never auto-dismiss
                                duration: null,
                            });
                        }
                        if (
                            response?.generateOrUpdateDraftInvoicesForBlock
                                ?.success
                        ) {
                            if (
                                (response.generateOrUpdateDraftInvoicesForBlock
                                    .numberOfDraftsGenerated ?? 0) <
                                    (response
                                        .generateOrUpdateDraftInvoicesForBlock
                                        .totalToGenerate ?? 0) &&
                                !hasErrored
                            ) {
                                numberOfLessonBlocksProcessed =
                                    response
                                        .generateOrUpdateDraftInvoicesForBlock
                                        .numberOfLessonBlocksProcessed ??
                                    undefined;
                                numberOfDraftsGenerated =
                                    response
                                        .generateOrUpdateDraftInvoicesForBlock
                                        .numberOfDraftsGenerated ?? undefined;
                                totalToGenerate =
                                    response
                                        .generateOrUpdateDraftInvoicesForBlock
                                        .totalToGenerate ?? undefined;
                                if (
                                    response
                                        .generateOrUpdateDraftInvoicesForBlock
                                        .profile?.profileGroup
                                        ?.generateDraftsProgress != 100
                                ) {
                                    commitGenerateOrUpdateDraftInvoicesForBlock(
                                        generateOrUpdateDraftInvoicesForBlockConfig(
                                            numberOfLessonBlocksProcessed,
                                            numberOfDraftsGenerated,
                                            totalToGenerate,
                                            variables.schoolIds,
                                        ),
                                    );
                                }
                            }
                        } else {
                            hasErrored = true;
                            toast.show({
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        description={
                                            "Please get in touch with one of the team"
                                        }
                                        id={id}
                                        status="error"
                                        title={
                                            "Error generating draft invoices"
                                        }
                                        toast={toast}
                                    />
                                ),
                                // never auto-dismiss
                                duration: null,
                            });
                        }
                    },
                    // this happens if the API gateway times out for a batch
                    // in this case, just fire off another mutation and continue
                    onError: () => {
                        progressIndicatorRef.current?.refreshMutationProgress();
                        // wait for 40 seconds to try and let the previous batch complete (if it is completing)
                        setTimeout(() => {
                            commitGenerateOrUpdateDraftInvoicesForBlock(
                                generateOrUpdateDraftInvoicesForBlockConfig(
                                    numberOfLessonBlocksProcessed,
                                    numberOfDraftsGenerated,
                                    totalToGenerate,
                                    variables.schoolIds,
                                ),
                            );
                        }, 40000);
                    },
                };
            };

            // to avoid hitting the max AWS API Gateway execution time (29s)
            // split up the invoice generation into batches and recursively
            // fire the mutation, keeping a track of overall progress
            const numberOfLessonBlocksProcessed = 0;
            const numberOfDraftsGenerated = 0;
            const totalToGenerate = undefined as number | undefined;

            commitGenerateOrUpdateDraftInvoicesForBlock(
                generateOrUpdateDraftInvoicesForBlockConfig(
                    numberOfLessonBlocksProcessed,
                    numberOfDraftsGenerated,
                    totalToGenerate,
                    variables.schoolIds,
                ),
            );
            dispatchState({
                input: "showMutationProgressIndicators",
                value: {
                    ...state.values.showMutationProgressIndicators,
                    generateDrafts: true,
                },
            });
        },
        [
            commitGenerateOrUpdateDraftInvoicesForBlock,
            state.values.showMutationProgressIndicators,
            toast,
        ],
    );

    const [commitSendDraftInvoicesForBlock, sendDraftInvoicesForBlockInFlight] =
        useMutation<SendDraftInvoicesForBlockMutation>(
            send_draft_invoices_for_block,
        );

    const sendDraftInvoicesForBlock = useCallback(
        (variables: {
            block: number;
            schoolIds: string[];
            startingYear: number;
        }) => {
            let hasErrored = false;
            const failedMutationItems: string[] = [];

            const sendDraftInvoicesForBlockConfig = (
                numberOfInvoicesSent: number | undefined,
                totalToSend: number | undefined,
                schoolIds: string[],
            ) => {
                return {
                    variables: {
                        input: {
                            startingYear: variables.startingYear,
                            block: variables.block,
                            schoolIds: schoolIds,
                            totalToSend: totalToSend,
                            numberOfInvoicesSent: numberOfInvoicesSent,
                            invoicesInBatch: INVOICES_TO_SEND_IN_BATCH,
                        },
                        // required for update of invoice headline data on complete
                        startingYear: variables.startingYear,
                        block: variables.block,
                    },
                    onCompleted: (
                        response: SendDraftInvoicesForBlockMutation$data,
                    ) => {
                        if (
                            response.sendDraftInvoicesForBlock?.erroredInvoice
                        ) {
                            failedMutationItems.push(
                                getErroredInvoiceLabel(
                                    response.sendDraftInvoicesForBlock
                                        ?.erroredInvoice?.assignedTo
                                        ?.accountNumber,
                                    response.sendDraftInvoicesForBlock
                                        .erroredInvoice.assignedTo?.user
                                        .firstName,
                                    response.sendDraftInvoicesForBlock
                                        .erroredInvoice.assignedTo?.user
                                        .lastName,
                                    response.sendDraftInvoicesForBlock
                                        .erroredInvoice.assignedTo?.user.email,
                                ),
                            );

                            toast.show({
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        description={failedMutationItems.join(
                                            "\n",
                                        )}
                                        id={id}
                                        status="error"
                                        title={"Couldn't send invoices for:"}
                                        toast={toast}
                                    />
                                ),
                                // never auto-dismiss
                                duration: null,
                            });
                        }
                        if (response?.sendDraftInvoicesForBlock?.success) {
                            if (
                                (response.sendDraftInvoicesForBlock
                                    .numberOfInvoicesSent ?? 0) <
                                    (response.sendDraftInvoicesForBlock
                                        .totalToSend ?? 0) &&
                                !hasErrored
                            ) {
                                numberOfInvoicesSent =
                                    response.sendDraftInvoicesForBlock
                                        .numberOfInvoicesSent ?? undefined;
                                totalToSend =
                                    response.sendDraftInvoicesForBlock
                                        .totalToSend ?? undefined;
                                if (
                                    response.sendDraftInvoicesForBlock.profile
                                        ?.profileGroup?.sendDraftsProgress !=
                                    100
                                ) {
                                    commitSendDraftInvoicesForBlock(
                                        sendDraftInvoicesForBlockConfig(
                                            numberOfInvoicesSent,
                                            totalToSend,
                                            variables.schoolIds,
                                        ),
                                    );
                                }
                            }
                        } else {
                            hasErrored = true;
                            toast.show({
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        description={
                                            "Please get in touch with one of the team"
                                        }
                                        id={id}
                                        status="error"
                                        title={"Error sending invoices"}
                                        toast={toast}
                                    />
                                ),
                                // never auto-dismiss
                                duration: null,
                            });
                        }
                    },
                    // this happens if the API gateway times out for a batch
                    // in this case, just fire off another mutation and continue
                    onError: () => {
                        // wait for 30 seconds to try and let the previous batch complete
                        setTimeout(() => {
                            if (
                                typeof numberOfInvoicesSent !== "undefined" &&
                                typeof totalToSend !== "undefined" &&
                                numberOfInvoicesSent +
                                    INVOICES_TO_SEND_IN_BATCH <
                                    totalToSend
                            ) {
                                commitSendDraftInvoicesForBlock(
                                    sendDraftInvoicesForBlockConfig(
                                        numberOfInvoicesSent +
                                            INVOICES_TO_SEND_IN_BATCH,
                                        totalToSend,
                                        variables.schoolIds,
                                    ),
                                );
                            }
                        }, 30000);
                    },
                };
            };

            // to avoid hitting the max AWS API Gateway execution time (29s)
            // split up the invoices to be sent into batches and recursively
            // fire the mutation, keeping a track of overall progress
            const numberOfInvoicesSent = 0 as number | undefined;
            const totalToSend = undefined as number | undefined;

            commitSendDraftInvoicesForBlock(
                sendDraftInvoicesForBlockConfig(
                    numberOfInvoicesSent,
                    totalToSend,
                    variables.schoolIds,
                ),
            );
            dispatchState({
                input: "showMutationProgressIndicators",
                value: {
                    ...state.values.showMutationProgressIndicators,
                    sendDrafts: true,
                },
            });
        },
        [
            commitSendDraftInvoicesForBlock,
            state.values.showMutationProgressIndicators,
            toast,
        ],
    );

    const [
        commitSendRemindersForUnpaidInvoicesForBlock,
        sendRemindersForUnpaidInvoicesForBlockInFlight,
    ] = useMutation<SendRemindersForUnpaidInvoicesForBlockMutation>(
        send_reminders_for_unpaid_invoices_for_block,
    );

    const sendRemindersForUnpaidInvoicesForBlock = useCallback(
        (variables: {
            block: number;
            preferEmail?: boolean;
            reminderNumber: number;
            schoolIds: string[];
            startingYear: number;
        }) => {
            let hasErrored = false;
            const failedMutationItems: string[] = [];

            const sendRemindersForUnpaidInvoicesForBlockConfig = (
                numberOfRemindersSent: number | undefined,
                totalToSend: number | undefined,
                schoolIds: string[],
            ) => {
                return {
                    variables: {
                        input: {
                            startingYear: variables.startingYear,
                            block: variables.block,
                            schoolIds: schoolIds,
                            reminderNumber: variables.reminderNumber,
                            preferEmail: variables.preferEmail,
                            totalToSend: totalToSend,
                            numberOfRemindersSent: numberOfRemindersSent,
                            remindersInBatch: REMINDERS_TO_SEND_IN_BATCH,
                        },
                        // required for update of invoice headline data on complete
                        startingYear: variables.startingYear,
                        block: variables.block,
                    },
                    onCompleted: (
                        response: SendRemindersForUnpaidInvoicesForBlockMutation$data,
                    ) => {
                        if (
                            response.sendRemindersForUnpaidInvoicesForBlock
                                ?.erroredInvoice
                        ) {
                            failedMutationItems.push(
                                getErroredInvoiceLabel(
                                    response
                                        .sendRemindersForUnpaidInvoicesForBlock
                                        ?.erroredInvoice?.assignedTo
                                        ?.accountNumber,
                                    response
                                        .sendRemindersForUnpaidInvoicesForBlock
                                        .erroredInvoice.assignedTo?.user
                                        .firstName,
                                    response
                                        .sendRemindersForUnpaidInvoicesForBlock
                                        .erroredInvoice.assignedTo?.user
                                        .lastName,
                                    response
                                        .sendRemindersForUnpaidInvoicesForBlock
                                        .erroredInvoice.assignedTo?.user.email,
                                ),
                            );

                            toast.show({
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        description={failedMutationItems.join(
                                            "\n",
                                        )}
                                        id={id}
                                        status="error"
                                        title={"Couldn't send a reminder to:"}
                                        toast={toast}
                                    />
                                ),
                                // never auto-dismiss
                                duration: null,
                            });
                        }
                        if (
                            response?.sendRemindersForUnpaidInvoicesForBlock
                                ?.success
                        ) {
                            if (
                                (response.sendRemindersForUnpaidInvoicesForBlock
                                    .numberOfRemindersSent ?? 0) <
                                    (response
                                        .sendRemindersForUnpaidInvoicesForBlock
                                        .totalToSend ?? 0) &&
                                !hasErrored
                            ) {
                                numberOfRemindersSent =
                                    response
                                        .sendRemindersForUnpaidInvoicesForBlock
                                        .numberOfRemindersSent ?? undefined;
                                totalToSend =
                                    response
                                        .sendRemindersForUnpaidInvoicesForBlock
                                        .totalToSend ?? undefined;
                                if (
                                    response
                                        .sendRemindersForUnpaidInvoicesForBlock
                                        .profile?.profileGroup
                                        ?.sendRemindersProgress != 100
                                ) {
                                    commitSendRemindersForUnpaidInvoicesForBlock(
                                        sendRemindersForUnpaidInvoicesForBlockConfig(
                                            numberOfRemindersSent,
                                            totalToSend,
                                            variables.schoolIds,
                                        ),
                                    );
                                }
                            }
                        } else {
                            hasErrored = true;
                            toast.show({
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        description={
                                            "Please get in touch with one of the team"
                                        }
                                        id={id}
                                        status="error"
                                        title={"Error sending reminders"}
                                        toast={toast}
                                    />
                                ),
                                // never auto-dismiss
                                duration: null,
                            });
                        }
                    },
                    // this happens if the API gateway times out for a batch
                    // in this case, just fire off another mutation and continue
                    onError: () => {
                        // wait for 30 seconds to try and let the previous batch complete
                        setTimeout(() => {
                            if (
                                typeof numberOfRemindersSent !== "undefined" &&
                                typeof totalToSend !== "undefined" &&
                                numberOfRemindersSent +
                                    REMINDERS_TO_SEND_IN_BATCH <
                                    totalToSend
                            ) {
                                commitSendRemindersForUnpaidInvoicesForBlock(
                                    sendRemindersForUnpaidInvoicesForBlockConfig(
                                        numberOfRemindersSent +
                                            REMINDERS_TO_SEND_IN_BATCH,
                                        totalToSend,
                                        variables.schoolIds,
                                    ),
                                );
                            }
                        }, 30000);
                    },
                };
            };

            // to avoid hitting the max AWS API Gateway execution time (29s)
            // split up the reminders to be sent into batches and recursively
            // fire the mutation, keeping a track of overall progress
            const numberOfRemindersSent = 0 as number | undefined;
            const totalToSend = undefined as number | undefined;

            commitSendRemindersForUnpaidInvoicesForBlock(
                sendRemindersForUnpaidInvoicesForBlockConfig(
                    numberOfRemindersSent,
                    totalToSend,
                    variables.schoolIds,
                ),
            );
            dispatchState({
                input: "showMutationProgressIndicators",
                value: {
                    ...state.values.showMutationProgressIndicators,
                    sendReminders: true,
                },
            });
        },
        [
            commitSendRemindersForUnpaidInvoicesForBlock,
            state.values.showMutationProgressIndicators,
            toast,
        ],
    );

    const [
        commitMatchBankPaymentsToInvoices,
        matchBankPaymentsToInvoicesInFlight,
    ] = useMutation<MatchBankPaymentsToInvoicesMutation>(
        match_bank_payments_to_invoices,
    );

    const matchBankPaymentsToInvoices = useCallback(
        (variables: {
            block: number;
            googleDriveCsvUrl: string;
            startingYear: number;
        }) => {
            const matchBankPaymentsToInvoicesConfig = {
                variables: {
                    input: variables,
                    // required for update of invoice headline data on complete
                    startingYear: variables.startingYear,
                    block: variables.block,
                },
                onCompleted: (
                    response: MatchBankPaymentsToInvoicesMutation$data,
                ) => {
                    if (response?.matchBankPaymentsToInvoices?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={"Finished matching bank payments"}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Error matching bank payments"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitMatchBankPaymentsToInvoices(
                matchBankPaymentsToInvoicesConfig,
            );
            dispatchState({
                input: "showMutationProgressIndicators",
                value: {
                    ...state.values.showMutationProgressIndicators,
                    matchPayments: true,
                },
            });
        },
        [
            commitMatchBankPaymentsToInvoices,
            state.values.showMutationProgressIndicators,
            toast,
        ],
    );

    const [
        commitMatchCardPaymentsToInvoices,
        matchCardPaymentsToInvoicesInFlight,
    ] = useMutation<MatchCardPaymentsToInvoicesMutation>(
        match_card_payments_to_invoices,
    );

    const matchCardPaymentsToInvoices = useCallback(
        (variables: {
            block: number;
            googleDriveCsvUrl: string;
            startingYear: number;
        }) => {
            const matchCardPaymentsToInvoicesConfig = {
                variables: {
                    input: variables,
                    // required for update of invoice headline data on complete
                    startingYear: variables.startingYear,
                    block: variables.block,
                },
                onCompleted: (
                    response: MatchCardPaymentsToInvoicesMutation$data,
                ) => {
                    if (response?.matchCardPaymentsToInvoices?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={"Finished matching card payments"}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Error matching card payments"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitMatchCardPaymentsToInvoices(
                matchCardPaymentsToInvoicesConfig,
            );
            dispatchState({
                input: "showMutationProgressIndicators",
                value: {
                    ...state.values.showMutationProgressIndicators,
                    matchPayments: true,
                },
            });
        },
        [
            commitMatchCardPaymentsToInvoices,
            state.values.showMutationProgressIndicators,
            toast,
        ],
    );

    const matchPaymentsHandler = useCallback(
        (googleDriveCsvUrl?: string) => {
            if (googleDriveCsvUrl || state.values.googleDriveCsvUrl) {
                if (state.values.matchPaymentsModalOpenType === "BANK") {
                    matchBankPaymentsToInvoices({
                        startingYear: state.values.startingYear,
                        block: state.values.block,
                        googleDriveCsvUrl: (googleDriveCsvUrl ??
                            state.values.googleDriveCsvUrl) as string,
                    });
                } else if (state.values.matchPaymentsModalOpenType === "CARD") {
                    matchCardPaymentsToInvoices({
                        startingYear: state.values.startingYear,
                        block: state.values.block,
                        googleDriveCsvUrl: (googleDriveCsvUrl ??
                            state.values.googleDriveCsvUrl) as string,
                    });
                }
            }
            dispatchState({
                input: "matchPaymentsModalOpenType",
                value: null,
            });
            dispatchState({
                input: "googleDriveCsvUrl",
                value: undefined,
            });
        },
        [
            matchBankPaymentsToInvoices,
            matchCardPaymentsToInvoices,
            state.values.block,
            state.values.googleDriveCsvUrl,
            state.values.matchPaymentsModalOpenType,
            state.values.startingYear,
        ],
    );

    const inputChangeHandler = useCallback(
        (_: unknown, inputValue?: string) => {
            dispatchState({
                input: "searchTerm",
                value: inputValue?.trim(),
            });
            navigation.setParams({ searchTerm: inputValue?.trim() });
        },
        [navigation],
    );

    const inputClearHandler = useCallback(() => {
        if (state.values.searchTerm !== "") {
            dispatchState({
                input: "searchTerm",
                value: "",
            });
            navigation.setParams({ searchTerm: undefined });
        }
    }, [navigation, state.values.searchTerm]);

    const renderHeader = useMemo(() => {
        return (
            <VStack bg="surface.100" mx="30px" pt="6" space="6">
                <HStack justifyContent="space-between">
                    <HStack
                        alignItems="flex-end"
                        flex={1}
                        justifyContent="space-between"
                        mr="6">
                        <VStack flex={1} space="4">
                            <HStack mb="3" ml="1" space="3">
                                <InvoiceIcon color="primary.600" size="xl" />
                                <Heading color="primary.600" fontSize="xl">
                                    Invoicing
                                </Heading>
                            </HStack>
                            <HStack space="4">
                                <Select
                                    borderRadius="2xl"
                                    fontSize="md"
                                    onValueChange={(itemValue) => {
                                        const value = parseInt(itemValue);

                                        if (value > state.values.startingYear) {
                                            dispatchState({
                                                input: "block",
                                                value: 1,
                                            });
                                            navigation.setParams({
                                                block: 1,
                                            });
                                        }

                                        dispatchState({
                                            input: "startingYear",
                                            value: value,
                                        });
                                        navigation.setParams({
                                            startingYear: value,
                                        });
                                    }}
                                    placeholder="Select year"
                                    py="2.5"
                                    selectedValue={state.values.startingYear.toString()}
                                    width="40">
                                    {startingYears.map((item) => {
                                        return (
                                            <Select.Item
                                                key={item.value}
                                                actionSheetLabel={item.label}
                                                value={String(item.value)}
                                            />
                                        );
                                    })}
                                </Select>
                                <Select
                                    borderRadius="2xl"
                                    fontSize="md"
                                    onValueChange={(itemValue) => {
                                        dispatchState({
                                            input: "block",
                                            value: parseInt(itemValue),
                                        });
                                        navigation.setParams({
                                            block: parseInt(itemValue),
                                        });
                                    }}
                                    placeholder="Select block"
                                    py="2.5"
                                    selectedValue={String(state.values.block)}
                                    width="40">
                                    {BLOCKS.map((item) => {
                                        return (
                                            <Select.Item
                                                key={item.value}
                                                actionSheetLabel={item.label}
                                                value={item.value}
                                            />
                                        );
                                    })}
                                </Select>
                                <Select
                                    borderRadius="2xl"
                                    color={
                                        state.values.school
                                            ? "surface.900"
                                            : "muted.400"
                                    }
                                    fontSize="md"
                                    onValueChange={(itemValue) => {
                                        const parsedValue =
                                            itemValue !== "ANY"
                                                ? itemValue
                                                : undefined;
                                        dispatchState({
                                            input: "school",
                                            value: parsedValue,
                                        });
                                        schoolModalKey.current = Math.random();
                                        navigation.setParams({
                                            school: parsedValue,
                                        });
                                    }}
                                    placeholder="Select school"
                                    py="2.5"
                                    selectedValue={state.values.school ?? "ANY"}
                                    width="56">
                                    <Select.Item
                                        key={"ANY"}
                                        actionSheetLabel={"Any school"}
                                        value={"ANY"}
                                    />
                                    {schools.map((item) => {
                                        return (
                                            <Select.Item
                                                key={item?.node?.name}
                                                actionSheetLabel={
                                                    titleCaseConverter(
                                                        item?.node?.name,
                                                    ) ?? ""
                                                }
                                                value={item?.node?.name ?? ""}
                                            />
                                        );
                                    })}
                                </Select>
                                <Select
                                    borderRadius="2xl"
                                    color={
                                        state.values.status
                                            ? "surface.900"
                                            : "muted.400"
                                    }
                                    fontSize="md"
                                    onValueChange={(itemValue) => {
                                        const parsedValue =
                                            itemValue !== "ANY"
                                                ? itemValue
                                                : undefined;
                                        dispatchState({
                                            input: "status",
                                            value: parsedValue,
                                        });
                                        navigation.setParams({
                                            status: parsedValue,
                                        });
                                    }}
                                    placeholder="Select status"
                                    py="2.5"
                                    selectedValue={state.values.status ?? "ANY"}
                                    width="48">
                                    <Select.Item
                                        key={"ANY"}
                                        actionSheetLabel={"Any status"}
                                        value={"ANY"}
                                    />
                                    {INVOICE_STATUS.map((item) => {
                                        return (
                                            <Select.Item
                                                key={item.value}
                                                actionSheetLabel={item.label}
                                                startIcon={item.icon}
                                                value={item.value}
                                            />
                                        );
                                    })}
                                </Select>
                            </HStack>
                            <HStack space="4" width="70%">
                                <Box flex={1}>
                                    <SearchBar
                                        inputClearHandler={inputClearHandler}
                                        inputOnChangeHandler={
                                            inputChangeHandler
                                        }
                                        inputSearchHandler={inputChangeHandler}
                                        placeholderText={
                                            "Search by name, email, invoice or account #"
                                        }
                                        searchText={state.values.searchTerm}
                                        showSearchIcon
                                    />
                                </Box>
                                <Button
                                    bg="surface.400"
                                    colorScheme="surface"
                                    leftIcon={<RestartIcon size="5" />}
                                    onPress={state.values.refreshHandler}
                                    p="3"
                                />
                            </HStack>
                        </VStack>
                        <HStack space="4">
                            <Button
                                _hover={{ bg: "primary.500" }}
                                _pressed={{ bg: "primary.600" }}
                                _text={{ fontSize: "17" }}
                                bg="primary.400"
                                leftIcon={<CopyIcon size="md" />}
                                onPress={state.values.copyDataToClipboard}
                                px="4"
                                shadow={1}>
                                Copy Table to Clipboard
                            </Button>
                            <Button
                                _hover={{ bg: "primary.500" }}
                                _pressed={{ bg: "primary.600" }}
                                _text={{ fontSize: "17" }}
                                bg="primary.400"
                                leftIcon={<InvoiceIcon size="md" />}
                                px="4"
                                shadow={1}
                                {...(generateOrUpdateDraftInvoicesForBlockInFlight ||
                                Boolean(
                                    user?.profile?.profileGroup
                                        ?.generateDraftsProgress != null &&
                                        user?.profile?.profileGroup
                                            ?.generateDraftsProgress >= 0 &&
                                        user?.profile?.profileGroup
                                            ?.generateDraftsProgress < 100,
                                )
                                    ? {
                                          isLoading: true,
                                          isLoadingText: "Generating Invoices",
                                      }
                                    : sendDraftInvoicesForBlockInFlight ||
                                        Boolean(
                                            user?.profile?.profileGroup
                                                ?.sendDraftsProgress != null &&
                                                user?.profile?.profileGroup
                                                    ?.sendDraftsProgress >= 0 &&
                                                user?.profile?.profileGroup
                                                    ?.sendDraftsProgress < 100,
                                        )
                                      ? {
                                            isLoading: true,
                                            isLoadingText: "Sending Invoices",
                                        }
                                      : sendRemindersForUnpaidInvoicesForBlockInFlight ||
                                          Boolean(
                                              user?.profile?.profileGroup
                                                  ?.sendRemindersProgress !=
                                                  null &&
                                                  user?.profile?.profileGroup
                                                      ?.sendRemindersProgress >=
                                                      0 &&
                                                  user?.profile?.profileGroup
                                                      ?.sendRemindersProgress <
                                                      100,
                                          )
                                        ? {
                                              isLoading: true,
                                              isLoadingText:
                                                  "Sending Reminders",
                                          }
                                        : matchBankPaymentsToInvoicesInFlight ||
                                            matchCardPaymentsToInvoicesInFlight ||
                                            Boolean(
                                                user?.profile?.profileGroup
                                                    ?.matchPaymentsProgress !=
                                                    null &&
                                                    user?.profile?.profileGroup
                                                        ?.matchPaymentsProgress >=
                                                        0 &&
                                                    user?.profile?.profileGroup
                                                        ?.matchPaymentsProgress <
                                                        100,
                                            )
                                          ? {
                                                isLoading: true,
                                                isLoadingText:
                                                    "Matching Payments",
                                            }
                                          : {})}
                                onPress={() =>
                                    invoiceActionsActionsheetOnOpen()
                                }>
                                View Invoice Actions
                            </Button>
                        </HStack>
                    </HStack>
                    <HStack
                        borderColor={TABLE_BORDER_COLOR}
                        borderRadius={TABLE_BORDER_RADIUS}
                        borderWidth={TABLE_BORDER_WIDTH}
                        flex={0}
                        minWidth="300px"
                        overflow="hidden">
                        {state.values.headlineData?.map((item, index) => {
                            return (
                                <Column
                                    key={index}
                                    bg={index === 0 ? "primary.50" : undefined}
                                    cellProps={{
                                        px: 2,
                                        hideTopBorder: true,
                                        hideOuterSideBorder: true,
                                        textProps: {
                                            fontSize: "md",
                                        },
                                    }}
                                    data={index == 1 ? item.slice(0, -1) : item}
                                    isFinalColumn={
                                        (state.values.headlineData?.length ??
                                            0) ==
                                        index + 1
                                    }
                                    rowHeight={10}
                                    tableBorderColor={TABLE_BORDER_COLOR}
                                    tableBorderWidth={TABLE_BORDER_WIDTH}
                                />
                            );
                        })}
                    </HStack>
                </HStack>
                <Box bg="surface.100">
                    <Box
                        bg="primary.50"
                        borderColor={TABLE_BORDER_COLOR}
                        borderTopRadius={TABLE_BORDER_RADIUS}
                        borderWidth={TABLE_BORDER_WIDTH}>
                        <Row
                            cellProps={{
                                px: 2,
                                hideTopBorder: true,
                                hideOuterSideBorder: true,
                                textProps: {
                                    fontSize: "md",
                                    textAlign: "center",
                                },
                            }}
                            data={tableHeaders}
                            flexArray={FLEX_ARRAY}
                            rowHeight={10}
                            rowIndex={0}
                            tableBorderColor={TABLE_BORDER_COLOR}
                            tableBorderWidth={TABLE_BORDER_WIDTH}
                        />
                    </Box>
                </Box>
            </VStack>
        );
    }, [
        state.values.startingYear,
        state.values.block,
        state.values.school,
        state.values.status,
        state.values.searchTerm,
        state.values.refreshHandler,
        state.values.copyDataToClipboard,
        state.values.headlineData,
        startingYears,
        schools,
        inputChangeHandler,
        inputClearHandler,
        generateOrUpdateDraftInvoicesForBlockInFlight,
        user?.profile?.profileGroup?.generateDraftsProgress,
        user?.profile?.profileGroup?.sendDraftsProgress,
        user?.profile?.profileGroup?.sendRemindersProgress,
        user?.profile?.profileGroup?.matchPaymentsProgress,
        sendDraftInvoicesForBlockInFlight,
        sendRemindersForUnpaidInvoicesForBlockInFlight,
        matchBankPaymentsToInvoicesInFlight,
        matchCardPaymentsToInvoicesInFlight,
        tableHeaders,
        navigation,
        invoiceActionsActionsheetOnOpen,
    ]);

    const renderRefetchIndicator = useMemo(() => {
        return state.values.isRefetching ? (
            <CustomFlatListSpinner top="340" />
        ) : null;
    }, [state.values.isRefetching]);

    const schoolModalKey = useRef(Math.random());

    const renderModals = useMemo(() => {
        return (
            <>
                <Actionsheet
                    animationType="fade"
                    hideDragIndicator
                    isOpen={invoiceActionsActionsheetIsOpen}
                    justifyContent="center"
                    onClose={invoiceActionsActionsheetOnClose}
                    size="lg">
                    <Actionsheet.Content
                        alignItems="center"
                        closeButtonProps={{ top: "5", right: "5" }}
                        justifyContent="center"
                        mx="auto"
                        roundedBottom={12}
                        roundedTop={12}
                        showCloseButton
                        w="40%">
                        <Text
                            alignSelf="center"
                            fontSize="xl"
                            fontWeight="bold"
                            pb="2"
                            pt="4">
                            Invoice Actions
                        </Text>
                        <Text alignSelf="center" fontSize="md" pb="5">
                            {getTermInWords(
                                state.values.block,
                                state.values.startingYear,
                            )}
                        </Text>
                        <Actionsheet.Item
                            _text={{ fontSize: "lg" }}
                            leftIcon={
                                <Center size="7">
                                    <DraftFilledIcon
                                        color="primary.400"
                                        size="6"
                                    />
                                </Center>
                            }
                            onPress={() => {
                                invoiceActionsActionsheetOnClose();
                                dispatchState({
                                    input: "generateDraftsModalIsOpen",
                                    value: true,
                                });
                            }}>
                            Generate drafts
                        </Actionsheet.Item>
                        {(headlineDataTotals?.draftsToSend ?? 0 > 0) ? (
                            <Actionsheet.Item
                                _text={{ fontSize: "lg" }}
                                leftIcon={
                                    <Center size="7">
                                        <SendIcon
                                            color="primary.500"
                                            size="5"
                                        />
                                    </Center>
                                }
                                onPress={() => {
                                    invoiceActionsActionsheetOnClose();
                                    dispatchState({
                                        input: "sendDraftsModalIsOpen",
                                        value: true,
                                    });
                                }}>
                                {`Send drafts${
                                    headlineDataTotals
                                        ? " (" +
                                          headlineDataTotals.draftsToSend +
                                          ")"
                                        : ""
                                }`}
                            </Actionsheet.Item>
                        ) : null}
                        {(headlineDataTotals?.totalInvoicesSent ?? 0 > 0) ? (
                            <Actionsheet.Item
                                _text={{ fontSize: "lg" }}
                                leftIcon={
                                    <Center size="7">
                                        <BankTransferIcon
                                            color="secondary.600"
                                            size="6"
                                        />
                                    </Center>
                                }
                                onPress={() => {
                                    invoiceActionsActionsheetOnClose();
                                    dispatchState({
                                        input: "matchPaymentsModalOpenType",
                                        value: "BANK",
                                    });
                                }}>
                                {"Match bank payments to invoices"}
                            </Actionsheet.Item>
                        ) : null}
                        {(headlineDataTotals?.totalInvoicesSent ?? 0 > 0) ? (
                            <Actionsheet.Item
                                _text={{ fontSize: "lg" }}
                                leftIcon={
                                    <Center size="7">
                                        <CardIcon
                                            color="secondary.500"
                                            size="6"
                                        />
                                    </Center>
                                }
                                onPress={() => {
                                    invoiceActionsActionsheetOnClose();
                                    dispatchState({
                                        input: "matchPaymentsModalOpenType",
                                        value: "CARD",
                                    });
                                }}>
                                {"Match card payments to invoices"}
                            </Actionsheet.Item>
                        ) : null}
                        {(headlineDataTotals?.totalInvoicesUnpaid ?? 0 > 0) ? (
                            <Actionsheet.Item
                                _text={{ fontSize: "lg" }}
                                leftIcon={
                                    <Center size="7">
                                        <NotificationIcon
                                            color="orange.400"
                                            size="6"
                                        />
                                    </Center>
                                }
                                onPress={() => {
                                    invoiceActionsActionsheetOnClose();
                                    dispatchState({
                                        input: "sendRemindersOptionsModalIsOpen",
                                        value: true,
                                    });
                                }}>
                                {`Send reminders${
                                    headlineDataTotals
                                        ? " (" +
                                          headlineDataTotals?.totalInvoicesUnpaid +
                                          ")"
                                        : ""
                                }`}
                            </Actionsheet.Item>
                        ) : null}
                        <Text
                            alignSelf="center"
                            fontFamily="Poppins-Regular"
                            fontSize="md"
                            mx="8"
                            py="4"
                            textAlign="center">
                            {
                                "After clicking on an action above, you have the option to select which schools to do it for."
                            }
                        </Text>
                    </Actionsheet.Content>
                </Actionsheet>
                <SchoolMultiSelectModal
                    // reset modal state each time it's opened
                    key={schoolModalKey.current}
                    initiallySelectAllSchools={!state.values.school}
                    initiallySelectedSchoolNames={
                        state.values.school ? [state.values.school] : undefined
                    }
                    showModal={
                        state.values.generateDraftsModalIsOpen ||
                        state.values.sendDraftsModalIsOpen ||
                        state.values.sendRemindersModalIsOpen
                    }
                    termInWords={getTermInWords(
                        state.values.block,
                        state.values.startingYear,
                    )}
                    {...(state.values.generateDraftsModalIsOpen
                        ? {
                              title: "Generate Or Update Draft Invoices",
                              buttonText: "Generate",
                              onSave: ({ schoolIds }) =>
                                  generateOrUpdateDraftInvoicesForBlock({
                                      startingYear: state.values.startingYear,
                                      block: state.values.block,
                                      schoolIds: schoolIds,
                                  }),
                              hideModal: () => {
                                  dispatchState({
                                      input: "generateDraftsModalIsOpen",
                                      value: false,
                                  });
                                  dispatchState({
                                      input: "sendDraftsModalIsOpen",
                                      value: false,
                                  });
                                  dispatchState({
                                      input: "sendRemindersModalIsOpen",
                                      value: false,
                                  });
                                  schoolModalKey.current = Math.random();
                              },
                          }
                        : state.values.sendDraftsModalIsOpen
                          ? {
                                title: "Send Draft Invoices",
                                buttonText: "Send",
                                onSave: ({ schoolIds }) =>
                                    sendDraftInvoicesForBlock({
                                        startingYear: state.values.startingYear,
                                        block: state.values.block,
                                        schoolIds: schoolIds,
                                    }),
                                hideModal: () => {
                                    dispatchState({
                                        input: "generateDraftsModalIsOpen",
                                        value: false,
                                    });
                                    dispatchState({
                                        input: "sendDraftsModalIsOpen",
                                        value: false,
                                    });
                                    dispatchState({
                                        input: "sendRemindersModalIsOpen",
                                        value: false,
                                    });
                                    schoolModalKey.current = Math.random();
                                },
                            }
                          : {
                                title: "Send Reminders for Unpaid Invoices",
                                buttonText: "Send",
                                onSave: ({ schoolIds }) =>
                                    sendRemindersForUnpaidInvoicesForBlock({
                                        startingYear: state.values.startingYear,
                                        block: state.values.block,
                                        schoolIds: schoolIds,
                                        reminderNumber:
                                            state.values.reminderNumber,
                                        preferEmail:
                                            state.values
                                                .preferedCommunicationType ===
                                            "EMAIL",
                                    }),
                                hideModal: () => {
                                    dispatchState({
                                        input: "generateDraftsModalIsOpen",
                                        value: false,
                                    });
                                    dispatchState({
                                        input: "sendDraftsModalIsOpen",
                                        value: false,
                                    });
                                    dispatchState({
                                        input: "sendRemindersModalIsOpen",
                                        value: false,
                                    });
                                    schoolModalKey.current = Math.random();
                                },
                            })}
                />
                <AlertPopup
                    alertIsOpen={state.values.sendRemindersOptionsModalIsOpen}
                    header="Send Reminders for Unpaid Invoices"
                    setAlertIsOpen={() => {
                        dispatchState({
                            input: "sendRemindersOptionsModalIsOpen",
                            value: false,
                        });
                    }}>
                    <VStack alignItems="center" space="4">
                        <Select
                            borderRadius="2xl"
                            fontSize="lg"
                            onValueChange={(itemValue) => {
                                dispatchState({
                                    input: "preferedCommunicationType",
                                    value: itemValue,
                                });
                            }}
                            placeholder="Prefer email or text for reminders"
                            py="2.5"
                            selectedValue={
                                state.values.preferedCommunicationType
                            }
                            textAlign="center"
                            width="300px">
                            <Select.Item
                                key={"TEXT"}
                                actionSheetLabel={"Send by text"}
                                value={"TEXT"}
                            />
                            <Select.Item
                                key={"EMAIL"}
                                actionSheetLabel={"Send by email"}
                                value={"EMAIL"}
                            />
                        </Select>
                        <Select
                            borderRadius="2xl"
                            fontSize="lg"
                            mb="4"
                            onValueChange={(itemValue) => {
                                dispatchState({
                                    input: "reminderNumber",
                                    value: parseInt(itemValue),
                                });
                            }}
                            placeholder="Select reminder to send"
                            py="2.5"
                            selectedValue={String(state.values.reminderNumber)}
                            textAlign="center"
                            width="300px">
                            <Select.Item
                                key={"1"}
                                actionSheetLabel={"First reminder"}
                                value={"1"}
                            />
                            <Select.Item
                                key={"2"}
                                actionSheetLabel={"Second reminder"}
                                value={"2"}
                            />
                            <Select.Item
                                key={"3"}
                                actionSheetLabel={"Third reminder"}
                                value={"3"}
                            />
                        </Select>
                        <Button.Group
                            alignItems="center"
                            flex={1}
                            flexDirection="row"
                            justifyContent="center"
                            mb="4"
                            p="0"
                            size="lg"
                            space={4}>
                            <ButtonDebounced
                                _text={{ fontSize: "xl" }}
                                height="50"
                                onPress={() => {
                                    dispatchState({
                                        input: "sendRemindersOptionsModalIsOpen",
                                        value: false,
                                    });
                                    dispatchState({
                                        input: "sendRemindersModalIsOpen",
                                        value: true,
                                    });
                                }}
                                px="6">
                                Continue to Select Schools
                            </ButtonDebounced>
                        </Button.Group>
                    </VStack>
                </AlertPopup>
                <AlertPopup
                    alertIsOpen={Boolean(
                        state.values.matchPaymentsModalOpenType,
                    )}
                    body={`${getTermInWords(
                        state.values.block,
                        state.values.startingYear,
                    )}\n\nPlease enter the Google Drive URL of the downloaded ${
                        state.values.matchPaymentsModalOpenType === "BANK"
                            ? "bank statement"
                            : "card payments"
                    } CSV below.`}
                    bodyStyle={{ _text: { fontSize: "md" }, mt: -5 }}
                    header={`Match ${
                        state.values.matchPaymentsModalOpenType === "BANK"
                            ? "Bank"
                            : "Card"
                    } Payments to Invoices`}
                    setAlertIsOpen={() => {
                        dispatchState({
                            input: "matchPaymentsModalOpenType",
                            value: null,
                        });
                        dispatchState({
                            input: "googleDriveCsvUrl",
                            value: undefined,
                        });
                    }}>
                    <VStack alignItems="center" space="8">
                        <Text fontSize="md" mt="-4" textAlign="center">
                            {"How to find the CSV's Google Drive URL?\n"}
                            <Text fontFamily="Poppins-Regular">
                                {
                                    "1. Upload the CSV to Google Drive.\n2. Open the file and look at the browser address bar.\n3. Copy the URL into the box below."
                                }
                            </Text>
                        </Text>
                        <TextInput
                            autoCapitalize="none"
                            borderRadius="2xl"
                            fontFamily="Poppins-Regular"
                            fontSize="md"
                            id="googleDriveCsvUrl"
                            keyboardType="default"
                            onFinishEditing={(_, inputValue) => {
                                dispatchState({
                                    input: "googleDriveCsvUrl",
                                    value: inputValue,
                                });
                            }}
                            onSubmit={(_, inputValue) =>
                                matchPaymentsHandler(inputValue)
                            }
                            placeholder={`Enter Google Drive URL for ${
                                state.values.matchPaymentsModalOpenType ===
                                "BANK"
                                    ? "bank statement"
                                    : "card payments"
                            } CSV`}
                            textAlign="center"
                        />
                        <Button.Group
                            alignItems="center"
                            flex={1}
                            flexDirection="row"
                            justifyContent="center"
                            mb="4"
                            p="0"
                            size="lg"
                            space={4}>
                            <ButtonDebounced
                                _text={{ fontSize: "lg" }}
                                height="50"
                                isDisabled={!state.values.googleDriveCsvUrl}
                                minWidth="56"
                                onPress={() => matchPaymentsHandler()}
                                width="25%">
                                Match Payments
                            </ButtonDebounced>
                        </Button.Group>
                    </VStack>
                </AlertPopup>
            </>
        );
    }, [
        invoiceActionsActionsheetIsOpen,
        invoiceActionsActionsheetOnClose,
        state.values.block,
        state.values.startingYear,
        state.values.generateDraftsModalIsOpen,
        state.values.sendDraftsModalIsOpen,
        state.values.sendRemindersModalIsOpen,
        state.values.school,
        state.values.sendRemindersOptionsModalIsOpen,
        state.values.preferedCommunicationType,
        state.values.reminderNumber,
        state.values.matchPaymentsModalOpenType,
        state.values.googleDriveCsvUrl,
        headlineDataTotals,
        generateOrUpdateDraftInvoicesForBlock,
        sendDraftInvoicesForBlock,
        sendRemindersForUnpaidInvoicesForBlock,
        matchPaymentsHandler,
    ]);

    if (!user?.profile?.id) {
        return null;
    }

    return (
        <Box bg="surface.100" flex={1} pt="70">
            {renderHeader}
            {loadInvoicesQueryReference != null ? (
                <Suspense
                    fallback={<LoadingBlobs>Loading Invoices...</LoadingBlobs>}>
                    <InvoicingHubContent
                        dispatchState={dispatchState}
                        loadInvoicesQueryReference={loadInvoicesQueryReference}
                        navigation={navigation}
                        progressIndicatorRef={progressIndicatorRef}
                        state={state}
                    />
                </Suspense>
            ) : (
                <LoadingBlobs>Loading Invoices...</LoadingBlobs>
            )}
            {renderRefetchIndicator}
            {renderModals}
        </Box>
    );
};

export const screenOptions = {
    headerTitle: "Invoicing",
};

export default InvoicingHubScreen;
