import { graphql } from "react-relay";

export const match_bank_payments_to_invoices = graphql`
    mutation MatchBankPaymentsToInvoicesMutation(
        $input: MatchBankPaymentsToInvoicesInput!
        $startingYear: Int!
        $block: Int!
    ) {
        matchBankPaymentsToInvoices(input: $input) {
            success
            errors
            profile {
                id
                totalAmountPaid(startingYear: $startingYear, block: $block)
                totalInvoicesUnpaid(startingYear: $startingYear, block: $block)
                profileGroup {
                    id
                    matchPaymentsProgress
                }
            }
        }
    }
`;
