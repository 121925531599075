import { graphql } from "react-relay";

export const create_blacklisted_date = graphql`
    mutation CreateBlacklistedDateMutation(
        $connections: [ID!]!
        $input: CreateBlacklistedDateInput!
    ) {
        createBlacklistedDate(input: $input) {
            success
            errors
            numberOfAffectedExistingLessons
            blacklistedDate
                @appendNode(
                    connections: $connections
                    edgeTypeName: "BlacklistedDateNodeEdge"
                ) {
                id
                date
                reason
                school {
                    id
                }
                teachers {
                    edges {
                        node {
                            id
                            user {
                                id
                                firstName
                                lastName
                            }
                        }
                    }
                }
            }
        }
    }
`;
