import React from "react";
import type { ReactElement, ComponentProps } from "react";

import { Button, Text, ChevronLeftIcon } from "native-base";
import type { Icon } from "native-base";

type ButtonProps = Omit<ComponentProps<typeof Button>, "onPress">;

interface Props extends ButtonProps {
    hideText?: boolean;
    iconProps?: ComponentProps<typeof Icon>;
    onPress: () => void;
    textProps?: ComponentProps<typeof Text>;
}

const BackButton = (props: Props): ReactElement => {
    const { hideText, iconProps, onPress, textProps } = props;

    return (
        <Button
            _hover={{ bg: "transparent", opacity: 0.8 }}
            _pressed={{ bg: "transparent", opacity: 0.7 }}
            px="0.5"
            py="1.5"
            variant="ghost"
            zIndex={2}
            {...props}
            leftIcon={
                <ChevronLeftIcon color="primary.600" size="5" {...iconProps} />
            }
            onPress={onPress}
        >
            {!hideText ? (
                <Text color="primary.600" fontSize="md" {...textProps}>
                    {"Back"}
                </Text>
            ) : null}
        </Button>
    );
};

export default React.memo(BackButton);
