import { graphql } from "react-relay";

export const payroll_headline_data_fragment = graphql`
    fragment PayrollHeadlineDataFragment_query on Query
    @argumentDefinitions(
        dateFrom: { type: "String!" }
        dateTo: { type: "String!" }
    )
    @refetchable(queryName: "PayrollHeadlineDataRefreshQuery") {
        me {
            profile {
                id
                totalPayslipsUnpaidAmount(dateFrom: $dateFrom, dateTo: $dateTo)
                totalPayslipsPaidAmount(dateFrom: $dateFrom, dateTo: $dateTo)
            }
        }
        payrate(date: $dateFrom) {
            id
            hourlyRate
        }
    }
`;
