import { ConnectionHandler } from "react-relay";
import type { RecordSourceSelectorProxy } from "relay-runtime";

export const lessonBlockConnectionUpdater = (
    store: RecordSourceSelectorProxy,
    lessonBlocksConnectionId: string | undefined,
    lessonBlockCursor: string | undefined,
): void => {
    if (!lessonBlocksConnectionId) {
        return;
    }

    const connectionRecord = store.get(lessonBlocksConnectionId);
    const payload = store.getRootField("updateLessonBlockDetails");

    const newLessonBlockRecords = payload?.getLinkedRecords(
        "newPairedLessonBlocks",
    );

    if (connectionRecord && newLessonBlockRecords) {
        newLessonBlockRecords.forEach((newLessonBlockRecord) => {
            const newEdge = ConnectionHandler.createEdge(
                store,
                connectionRecord,
                newLessonBlockRecord,
                "LessonBlockNodeEdge",
            );
            if (lessonBlockCursor) {
                ConnectionHandler.insertEdgeAfter(
                    connectionRecord,
                    newEdge,
                    lessonBlockCursor,
                );
            } else {
                // no cursor if dealing with a newly added lesson block
                // in this case just insert at the top of the table
                ConnectionHandler.insertEdgeBefore(connectionRecord, newEdge);
            }
        });
    }
};
