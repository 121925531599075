import React, {
    Suspense,
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
} from "react";
import type { FC, ReactElement, Dispatch } from "react";

import emoji from "emoji-dictionary";
import * as Clipboard from "expo-clipboard";
import {
    Box,
    Center,
    Heading,
    VStack,
    HStack,
    useTheme,
    Button,
    Text,
    PresenceTransition,
    Spinner,
    useToast,
    useDisclose,
} from "native-base";
import { useWindowDimensions } from "react-native";
import {
    useQueryLoader,
    usePreloadedQuery,
    usePaginationFragment,
    useRelayEnvironment,
    useMutation,
} from "react-relay";
import type { PreloadedQuery } from "react-relay";
import {
    RecyclerListView,
    DataProvider,
    LayoutProvider,
} from "recyclerlistview";
import { fetchQuery } from "relay-runtime";
import type { Subscription } from "relay-runtime";

import { useAuth } from "pianofunclub-shared/providers/AuthProvider";
import { useData } from "pianofunclub-shared/providers/DataProvider";

import AccountMutationProgressIndicators from "../../components/Buttons/AccountMutationProgressIndicators";
import AccountTableRow from "../../components/ListItems/AccountTableRow";
import CreateAccountModal from "../../components/Modals/CreateAccountModal";
import SendBulkCommunicationModal from "../../components/Modals/SendBulkCommunicationModal";
import type {
    AccountsStackNavigatorProps,
    AccountsStackRouteProps,
} from "../../navigation/AccountsNavigator";
import { accountsHubTableCsvConverter } from "../../utils/tableCsvConverters";
import LoadingBlobs from "pianofunclub-shared/components/Animations/LoadingBlobs";
import Row from "pianofunclub-shared/components/Base/Row";
import type { TableData } from "pianofunclub-shared/components/Base/Row";
import TopTabBar from "pianofunclub-shared/components/Buttons/TopTabBar";
import ListEmptyBanner from "pianofunclub-shared/components/ListItems/ListEmptyBanner";
import Actionsheet from "pianofunclub-shared/components/NativeBaseExtended/Actionsheet";
import Select from "pianofunclub-shared/components/NativeBaseExtended/Select";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import CustomFlatListSpinner from "pianofunclub-shared/components/Other/CustomFlatListSpinner";
import {
    AccountsIcon,
    AddCircleIcon,
    CopyIcon,
    RestartIcon,
    SendIcon,
} from "pianofunclub-shared/components/Other/Icons";
import SearchBar from "pianofunclub-shared/components/Other/SearchBar";

import type {
    LoadAccounts_query_profiles$data,
    LoadAccounts_query_profiles$key,
} from "pianofunclub-shared/relay/graphql/accounts/__generated__/LoadAccounts_query_profiles.graphql";
import type { LoadAccountsPaginationQuery } from "pianofunclub-shared/relay/graphql/accounts/__generated__/LoadAccountsPaginationQuery.graphql";
import type { LoadAccountsQuery } from "pianofunclub-shared/relay/graphql/accounts/__generated__/LoadAccountsQuery.graphql";
import type {
    SendBulkCommunicationMutation,
    SendBulkCommunicationMutation$data,
} from "pianofunclub-shared/relay/graphql/accounts/__generated__/SendBulkCommunicationMutation.graphql";
import {
    load_accounts,
    load_accounts_pagination,
} from "pianofunclub-shared/relay/graphql/accounts/LoadAccounts";
import { send_bulk_communication } from "pianofunclub-shared/relay/graphql/accounts/SendBulkCommunication";
import type { LoadMutationProgressQuery } from "pianofunclub-shared/relay/graphql/general/__generated__/LoadMutationProgressQuery.graphql";
import { load_mutation_progress } from "pianofunclub-shared/relay/graphql/general/LoadMutationProgress";

import type { AccountType, Mutable } from "pianofunclub-shared/types";
import { ACCOUNT_STATUS } from "pianofunclub-shared/utils/constants";
import {
    getScaledWindowDimension,
    isNumeric,
    titleCaseConverter,
} from "pianofunclub-shared/utils/converters";
import {
    getErroredBulkCommunicationLabel,
    getOrderBy,
    sortIconExtractor,
} from "pianofunclub-shared/utils/extractors";
import { useDebounceFunction } from "pianofunclub-shared/utils/hooks";
import { createReducer } from "pianofunclub-shared/utils/reducers";
import type { State, Action } from "pianofunclub-shared/utils/reducers";

export type NavigationProps = AccountsStackNavigatorProps<"AccountsHub">;

type RouteProps = AccountsStackRouteProps<"AccountsHub">;

interface ScreenProps {
    navigation: NavigationProps;
    route: RouteProps;
}

interface ContentProps {
    accountType: string;
    dispatchState: Dispatch<Action<ReducerValues, ReducerTypes>>;
    flexArray: number[];
    navigation: NavigationProps;
    queryReference: PreloadedQuery<LoadAccountsQuery, Record<string, unknown>>;
    state: State<ReducerValues>;
}

export type ReducerValues = {
    contentIsRendered: boolean;
    copyDataToClipboard?: () => void;
    currentPageIndex: number;
    dataProvider: DataProvider;
    discountType?: string;
    isRefetching: boolean;
    layoutProvider?: LayoutProvider;
    orderBy?: string;
    parentConnections?: string[];
    pupilConnections?: string[];
    refreshHandler?: (orderBy?: string) => void;
    school?: string;
    searchTerm: string;
    showCreateAccountModal: boolean;
    showSendBulkCommunicationModal: boolean;
    showSendBulkCommunicationProgressIndicator: boolean;
    staffConnections?: string[];
    status?: string;
    subscription?: Subscription;
    teacherConnections?: string[];
};

export type ReducerTypes =
    | string
    | number
    | boolean
    | Subscription
    | DataProvider
    | LayoutProvider
    | ((orderBy: string) => void)
    | string[]
    | undefined;

const LOAD_X_ACCOUNTS = 50;
const TABLE_BORDER_COLOR = "surface.400";
const TABLE_BORDER_WIDTH = 1;
const TABLE_BORDER_RADIUS = 10;
const MESSAGES_TO_SEND_IN_BULK_COMMUNICATION_BATCH = 15;

const AccountsHubContent: FC<ContentProps> = (props) => {
    const {
        accountType,
        dispatchState,
        flexArray,
        navigation,
        queryReference,
        state,
    } = props;

    const data = usePreloadedQuery(load_accounts, queryReference);

    const {
        data: accountsData,
        hasNext,
        isLoadingNext,
        loadNext,
        refetch: refresh,
    } = usePaginationFragment<
        LoadAccountsPaginationQuery,
        LoadAccounts_query_profiles$key
    >(load_accounts_pagination, data);

    const toast = useToast();

    useEffect(() => {
        if (
            accountsData.profiles?.edges &&
            accountsData.profiles.edges.length > 0
        ) {
            dispatchState({
                input: "dataProvider",
                value: state.values.dataProvider.cloneWithRows(
                    accountsData.profiles.edges as Mutable<
                        typeof accountsData.profiles.edges
                    >,
                ),
            });
            // if there is no data, create a fresh DataProvider
        } else {
            dispatchState({
                input: "dataProvider",
                value: new DataProvider((r1, r2) => {
                    return r1 !== r2;
                }),
            });
        }

        dispatchState({
            input: "refreshHandler",
            value: (orderBy: string) => {
                dispatchState({ input: "contentIsRendered", value: false });
                refresh(
                    {
                        first: LOAD_X_ACCOUNTS,
                        searchTerm:
                            state.values.searchTerm !== ""
                                ? state.values.searchTerm
                                : undefined,
                        accountType: accountType,
                        status: state.values.status,
                        school: state.values.school,
                        orderBy: orderBy,
                    },
                    {
                        fetchPolicy: "network-only",
                    },
                );
            },
        });

        dispatchState({
            input: "copyDataToClipboard",
            value: () => {
                Clipboard.setStringAsync(
                    accountsHubTableCsvConverter(
                        accountsData.profiles?.edges ?? [],
                        accountType,
                    ),
                );
                toast.show({
                    render: ({ id }: { id: string }) => (
                        <ToastAlert
                            id={id}
                            status="success"
                            title={"Copied Data to Clipboard"}
                            toast={toast}
                        />
                    ),
                });
            },
        });

        switch (accountType) {
            case "PARENT":
                dispatchState({
                    input: "parentConnections",
                    value: accountsData.profiles?.__id
                        ? [accountsData.profiles.__id]
                        : undefined,
                });
                return;
            case "PUPIL":
                dispatchState({
                    input: "pupilConnections",
                    value: accountsData.profiles?.__id
                        ? [accountsData.profiles.__id]
                        : undefined,
                });
                return;
            case "TEACHER":
                dispatchState({
                    input: "teacherConnections",
                    value: accountsData.profiles?.__id
                        ? [accountsData.profiles.__id]
                        : undefined,
                });
                return;
            default:
                dispatchState({
                    input: "staffConnections",
                    value: accountsData.profiles?.__id
                        ? [accountsData.profiles.__id]
                        : undefined,
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountsData]);

    const { colors } = useTheme();

    const onEndReached = useCallback(() => {
        if (hasNext && !isLoadingNext) {
            loadNext(LOAD_X_ACCOUNTS);
        }
    }, [hasNext, isLoadingNext, loadNext]);

    // stop refetch useEffects firing on first render
    const renderCount = useRef(0);
    const environment = useRelayEnvironment();

    const refetchAccounts = useCallback(
        (variables: {
            accountType: string;
            first: number;
            isDiscounted?: boolean;
            orderBy?: string;
            school?: string;
            searchTerm?: string;
            status?: string;
        }): Subscription | undefined => {
            dispatchState({ input: "isRefetching", value: true });

            const subscription = fetchQuery<LoadAccountsQuery>(
                environment,
                load_accounts,
                variables,
                { fetchPolicy: "store-or-network" },
            ).subscribe({
                complete: () => {
                    dispatchState({ input: "isRefetching", value: false });
                    refresh(variables, { fetchPolicy: "store-only" });
                },
                error: () => {
                    dispatchState({ input: "isRefetching", value: false });
                },
            });
            return subscription;
        },
        [dispatchState, environment, refresh],
    );

    useEffect(() => {
        if (renderCount.current < 3) {
            renderCount.current += 1;
            return;
        }

        dispatchState({ input: "isRefetching", value: false });
        state.values.subscription?.unsubscribe();

        // fire refetch immediately when orderBy or filters change
        dispatchState({
            input: "subscription",
            value: refetchAccounts({
                first: LOAD_X_ACCOUNTS,
                searchTerm:
                    state.values.searchTerm !== ""
                        ? state.values.searchTerm
                        : undefined,
                accountType: accountType,
                status: state.values.status,
                isDiscounted: state.values.discountType
                    ? state.values.discountType === "DISCOUNTED"
                    : undefined,
                school: state.values.school,
                orderBy: state.values.orderBy,
            }),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        state.values.orderBy,
        state.values.school,
        state.values.status,
        state.values.discountType,
    ]);

    useDebounceFunction(
        () => {
            if (renderCount.current < 3) {
                renderCount.current += 1;
                return;
            }
            dispatchState({ input: "isRefetching", value: false });
            state.values.subscription?.unsubscribe();

            // remove any applied sorting when we search (sort by relevance instead)
            // and reapply default sorting if search text is blank
            const orderBy =
                state.values.searchTerm === ""
                    ? "user__firstName,user__lastName"
                    : undefined;
            dispatchState({ input: "orderBy", value: orderBy });
            navigation.setParams({ orderBy: orderBy });
            dispatchState({
                input: "subscription",
                value: refetchAccounts({
                    first: LOAD_X_ACCOUNTS,
                    searchTerm:
                        state.values.searchTerm !== ""
                            ? state.values.searchTerm
                            : undefined,
                    accountType: accountType,
                    status: state.values.status,
                    isDiscounted: state.values.discountType
                        ? state.values.discountType === "DISCOUNTED"
                        : undefined,
                    school: state.values.school,
                    orderBy: orderBy,
                }),
            });
        },
        // no delay if clearing text
        state.values.searchTerm !== "" ? 750 : 0,

        [state.values.searchTerm],
    );

    useEffect(() => {
        if (renderCount.current < 3) {
            renderCount.current += 1;
            return;
        }

        dispatchState({ input: "isRefetching", value: false });
        state.values.subscription?.unsubscribe();

        // fire refresh rather than refetch (to hide current content) when changing tab
        state.values.refreshHandler?.(state.values.orderBy);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.values.currentPageIndex]);

    const { scale, width: windowWidth } = useWindowDimensions();

    useEffect(() => {
        // this allows the RLV to resize when the window resizes
        dispatchState({
            input: "layoutProvider",
            value: new LayoutProvider(
                () => "VSEL",
                (type, dim) => {
                    switch (type) {
                        case "VSEL":
                            dim.width = getScaledWindowDimension(
                                windowWidth,
                                scale,
                            );
                            dim.height = 40;
                            break;
                        default:
                            dim.width = 0;
                            dim.height = 0;
                    }
                },
            ),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowWidth, scale]);

    const onPressProfile = useCallback(
        (profileId?: string | null, accountType?: AccountType | null) => {
            if (profileId && accountType) {
                navigation.push("Account", {
                    profileId: profileId,
                    accountType: accountType,
                    accountsHubConnectionIds:
                        accountType === "PARENT"
                            ? state.values.parentConnections
                            : accountType === "PUPIL"
                              ? state.values.pupilConnections
                              : accountType === "TEACHER"
                                ? state.values.teacherConnections
                                : state.values.staffConnections,
                });
            }
        },
        [
            navigation,
            state.values.parentConnections,
            state.values.pupilConnections,
            state.values.staffConnections,
            state.values.teacherConnections,
        ],
    );

    const onPressSchool = useCallback(
        (schoolId?: string | null) => {
            if (schoolId) {
                navigation.navigate("SchoolsHubTab", {
                    screen: "School",
                    params: {
                        schoolId: schoolId,
                    },
                });
            }
        },
        [navigation],
    );

    const rowRenderer = useCallback(
        (
            _: unknown,
            data: NonNullable<
                LoadAccounts_query_profiles$data["profiles"]
            >["edges"][0],
            index: number,
        ): ReactElement | null => {
            if (data?.node) {
                return (
                    <Box
                        borderColor={TABLE_BORDER_COLOR}
                        borderLeftWidth={TABLE_BORDER_WIDTH}
                    >
                        <AccountTableRow
                            cellProps={{
                                hideTopBorder: index === 0,
                                pressableTextProps: {
                                    fontFamily: "Poppins-Regular",
                                },
                                textProps: {
                                    fontSize: "md",
                                    textAlign: "center",
                                    fontFamily: "Poppins-Regular",
                                },
                                px: 2,
                            }}
                            currentPageIndex={state.values.currentPageIndex}
                            data={data.node}
                            flexArray={flexArray}
                            onPressProfile={onPressProfile}
                            onPressSchool={onPressSchool}
                            rowHeight={10}
                            tableBorderColor={TABLE_BORDER_COLOR}
                            tableBorderWidth={TABLE_BORDER_WIDTH}
                        />
                    </Box>
                );
            } else {
                return null;
            }
        },
        [
            flexArray,
            onPressProfile,
            onPressSchool,
            state.values.currentPageIndex,
        ],
    );

    const renderFooter = useCallback(() => {
        return isLoadingNext ? (
            <CustomFlatListSpinner
                borderColor={TABLE_BORDER_COLOR}
                borderTopWidth={1}
                position="relative"
                pt="8"
                top="0"
            />
        ) : null;
    }, [isLoadingNext]);

    const renderListEmptyBanner = useCallback(() => {
        return (
            <ListEmptyBanner
                explainer={`No ${accountType.toLowerCase()}${
                    accountType !== "STAFF" ? "s" : ""
                } found... Try expanding your search!`}
            >
                <Text fontSize="6xl">{emoji.getUnicode("neutral_face")}</Text>
            </ListEmptyBanner>
        );
    }, [accountType]);

    useEffect(() => {
        dispatchState({ input: "contentIsRendered", value: true });
        // unsubscribe from refetch on unmount
        return () => state.values.subscription?.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryReference]);

    if (!state.values.contentIsRendered) {
        return <LoadingBlobs>Loading Accounts...</LoadingBlobs>;
    }

    return (
        <Box flex={1} mx="30px">
            {state.values.dataProvider.getSize() > 0 &&
            state.values.layoutProvider ? (
                <RecyclerListView
                    canChangeSize
                    dataProvider={state.values.dataProvider}
                    layoutProvider={state.values.layoutProvider}
                    onEndReached={onEndReached}
                    onEndReachedThreshold={2000}
                    renderAheadOffset={800}
                    renderFooter={renderFooter}
                    rowRenderer={rowRenderer}
                    scrollViewProps={{
                        contentContainerStyle: {
                            borderColor:
                                // @ts-expect-error can't index with variable
                                colors?.[TABLE_BORDER_COLOR.split(".")[0]][
                                    TABLE_BORDER_COLOR.split(".")[1]
                                ],
                            borderBottomWidth:
                                (accountsData.profiles?.edges.length ??
                                    0 > 0) &&
                                !isLoadingNext
                                    ? TABLE_BORDER_WIDTH
                                    : 0,
                            marginBottom: 40,
                            overflow: "hidden",
                            borderBottomRadius: TABLE_BORDER_RADIUS,
                        },
                        showsVerticalScrollIndicator: false,
                    }}
                    style={{
                        flex: 1,
                        minHeight: 1,
                    }}
                    suppressBoundedSizeException
                    useWindowScroll
                />
            ) : (
                renderListEmptyBanner()
            )}
        </Box>
    );
};

const AccountsHubScreen: FC<ScreenProps> = (props) => {
    const { navigation, route } = props;

    const { user } = useAuth();

    const initialState = useMemo(() => {
        let currentPageIndex = route.params?.currentPageIndex
            ? Number(route.params?.currentPageIndex)
            : undefined;
        if (currentPageIndex === undefined) {
            navigation.setParams({ currentPageIndex: 0 });
            currentPageIndex = 0;
        }
        let orderBy = route.params?.orderBy;
        if (orderBy === undefined) {
            navigation.setParams({ orderBy: "user__firstName,user__lastName" });
            orderBy = "user__firstName,user__lastName";
        }
        return {
            values: {
                currentPageIndex: currentPageIndex,
                school: route.params?.school,
                status: route.params?.status,
                discountType: route.params?.discountType,
                searchTerm: route.params?.searchTerm ?? "",
                orderBy: orderBy,
                contentIsRendered: false,
                isRefetching: false,
                showCreateAccountModal: false,
                showSendBulkCommunicationModal: false,
                showSendBulkCommunicationProgressIndicator: false,
                subscription: undefined,
                dataProvider: new DataProvider((r1, r2) => {
                    return r1 !== r2;
                }),
                layoutProvider: undefined,
                parentConnections: undefined,
                pupilConnections: undefined,
                teacherConnections: undefined,
                staffConnections: undefined,
                refreshHandler: undefined,
            },
        };
    }, [
        navigation,
        route.params?.currentPageIndex,
        route.params?.discountType,
        route.params?.orderBy,
        route.params?.school,
        route.params?.searchTerm,
        route.params?.status,
    ]);

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [state, dispatchState] = useReducer(reducer, initialState);

    const accountType = useMemo(() => {
        switch (state.values.currentPageIndex.toString()) {
            case "0":
                return "PARENT";
            case "1":
                return "PUPIL";
            case "2":
                return "TEACHER";
            default:
                return "STAFF";
        }
    }, [state.values.currentPageIndex]);

    const [loadAccountsQueryReference, loadAccountsQuery] =
        useQueryLoader<LoadAccountsQuery>(load_accounts);

    useEffect(() => {
        if (!state.values.contentIsRendered) {
            loadAccountsQuery(
                {
                    first: LOAD_X_ACCOUNTS,
                    searchTerm:
                        state.values.searchTerm !== ""
                            ? state.values.searchTerm
                            : undefined,
                    accountType: accountType,
                    status: state.values.status,
                    isDiscounted: state.values.discountType
                        ? state.values.discountType === "DISCOUNTED"
                        : undefined,
                    school: state.values.school,
                    orderBy: state.values.orderBy,
                },
                { fetchPolicy: "store-or-network" },
            );
        }
    }, [
        accountType,
        loadAccountsQuery,
        state.values.contentIsRendered,
        state.values.discountType,
        state.values.orderBy,
        state.values.school,
        state.values.searchTerm,
        state.values.status,
    ]);

    const { dataState } = useData();

    const schools = useMemo(() => {
        return dataState.values.schools?.edges ?? [];
    }, [dataState.values.schools?.edges]);

    const toast = useToast();

    const [loadMutationProgressQueryReference, loadMutationProgressQuery] =
        useQueryLoader<LoadMutationProgressQuery>(load_mutation_progress);

    useEffect(() => {
        if (user?.profile?.id) {
            loadMutationProgressQuery(
                {
                    profileId: user.profile.id,
                    skipInvoiceTotals: true,
                    // TODO (FUTURE): not actually used but required by GraphQL schema - could be cleaned up
                    startingYear: 2022,
                    block: 1,
                },
                { fetchPolicy: "network-only" },
            );
        }
    }, [loadMutationProgressQuery, user?.profile?.id]);

    const [commitSendBulkCommunication, sendBulkCommunicationInFlight] =
        useMutation<SendBulkCommunicationMutation>(send_bulk_communication);

    const sendBulkCommunication = useCallback(
        (variables: {
            excludePupilsWhoHaveBook: boolean;
            excludePupilsWhoHaveInstrument: boolean;
            excludePupilsWhoHaveInstrumentSuppliedByUs: boolean;
            excludePupilsWhoHaveReturnedInstrument: boolean;
            messageString: string;
            messageSubject: string;
            schoolIds: string[];
            schoolYears: number[];
            sendType: string;
            teacherIds?: string[];
        }) => {
            const sendBulkCommunicationConfig = (
                numberSent: number | undefined,
                totalToSend: number | undefined,
            ) => {
                let hasErrored = false;
                const failedMutationItems: string[] = [];

                return {
                    variables: {
                        input: {
                            ...variables,
                            totalToSend: totalToSend,
                            numberSent: numberSent,
                            numberInBatch:
                                MESSAGES_TO_SEND_IN_BULK_COMMUNICATION_BATCH,
                        },
                    },
                    onCompleted: (
                        response: SendBulkCommunicationMutation$data,
                    ) => {
                        if (response.sendBulkCommunication?.erroredProfile) {
                            failedMutationItems.push(
                                getErroredBulkCommunicationLabel(
                                    response.sendBulkCommunication
                                        .erroredProfile?.user.firstName,
                                    response.sendBulkCommunication
                                        .erroredProfile?.user.lastName,
                                    response.sendBulkCommunication
                                        .erroredProfile?.user.email,
                                ),
                            );

                            toast.show({
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        description={failedMutationItems.join(
                                            "\n",
                                        )}
                                        id={id}
                                        status="error"
                                        title={"Failed to send to:"}
                                        toast={toast}
                                    />
                                ),
                                // never auto-dismiss
                                duration: null,
                            });
                        }
                        if (response?.sendBulkCommunication?.success) {
                            if (
                                response.sendBulkCommunication.numberSent !==
                                    response.sendBulkCommunication
                                        .totalToSend &&
                                !hasErrored
                            ) {
                                numberSent =
                                    response.sendBulkCommunication.numberSent ??
                                    undefined;
                                totalToSend =
                                    response.sendBulkCommunication
                                        .totalToSend ?? undefined;
                                if (
                                    response.sendBulkCommunication.profile
                                        ?.profileGroup
                                        ?.sendBulkCommunicationProgress != 100
                                ) {
                                    commitSendBulkCommunication(
                                        sendBulkCommunicationConfig(
                                            numberSent,
                                            totalToSend,
                                        ),
                                    );
                                }
                            }
                        } else {
                            hasErrored = true;
                            toast.show({
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        description={
                                            "Please get in touch with one of the team"
                                        }
                                        id={id}
                                        status="error"
                                        title={
                                            "Error sending bulk communication"
                                        }
                                        toast={toast}
                                    />
                                ),
                                // never auto-dismiss
                                duration: null,
                            });
                        }
                    },
                    // this happens if the API gateway times out for a batch
                    // in this case, just fire off another mutation and continue
                    onError: () => {
                        // wait for 30 seconds to try and let the previous batch complete
                        setTimeout(() => {
                            if (
                                typeof numberSent !== "undefined" &&
                                typeof totalToSend !== "undefined" &&
                                numberSent +
                                    MESSAGES_TO_SEND_IN_BULK_COMMUNICATION_BATCH <
                                    totalToSend
                            ) {
                                commitSendBulkCommunication(
                                    sendBulkCommunicationConfig(
                                        numberSent +
                                            MESSAGES_TO_SEND_IN_BULK_COMMUNICATION_BATCH,
                                        totalToSend,
                                    ),
                                );
                            }
                        }, 30000);
                    },
                };
            };

            // to avoid hitting the max AWS API Gateway execution time (29s)
            // split up the reminders to be sent into batches and recursively
            // fire the mutation, keeping a track of overall progress
            const numberOfSent = 0 as number | undefined;
            const totalToSend = undefined as number | undefined;

            commitSendBulkCommunication(
                sendBulkCommunicationConfig(numberOfSent, totalToSend),
            );
            dispatchState({
                input: "showSendBulkCommunicationProgressIndicator",
                value: true,
            });
        },
        [commitSendBulkCommunication, dispatchState, toast],
    );

    const inputChangeHandler = useCallback(
        (_: unknown, inputValue?: string) => {
            // if it's a numeric input, clean it so that we match phone numbers
            // if there are spaces in the number and so that we match both 07 and +44
            let parsedInputValue = inputValue;
            if (parsedInputValue?.startsWith("+44")) {
                parsedInputValue = parsedInputValue.replace("+44", "0");
            }
            if (isNumeric(parsedInputValue?.replace(" ", ""))) {
                parsedInputValue = parsedInputValue?.replace(" ", "");
            }
            dispatchState({
                input: "searchTerm",
                value: parsedInputValue?.trim(),
            });
            navigation.setParams({
                searchTerm: parsedInputValue?.trim(),
            });
        },
        [navigation],
    );

    const inputClearHandler = useCallback(() => {
        if (state.values.searchTerm !== "") {
            dispatchState({
                input: "searchTerm",
                value: "",
            });
        }
    }, [state.values.searchTerm]);

    const sortHandler = useCallback(
        (column: string) => {
            const orderBy = getOrderBy(column, state.values.orderBy);
            dispatchState({
                input: "orderBy",
                value: orderBy,
            });
            navigation.setParams({
                orderBy: orderBy,
            });
        },
        [navigation, state.values.orderBy],
    );

    const tableHeaders = useMemo(() => {
        const orderBy = state.values.orderBy;
        // these headers are used by all account types
        const headers = [
            {
                data: `${
                    state.values.currentPageIndex == 1 ? "Parent " : ""
                }Account #`,
                onPress: () => sortHandler("accountNumber"),
                icon: sortIconExtractor("accountNumber", orderBy),
            },
            {
                data: `${
                    state.values.currentPageIndex == 1 ? "Pupil " : ""
                }Name`,
                onPress: () => sortHandler("user__firstName,user__lastName"),
                icon: sortIconExtractor(
                    "user__firstName,user__lastName",
                    orderBy,
                ),
            },
        ] as TableData;
        if (state.values.currentPageIndex !== 1) {
            headers.push(
                {
                    data: "Email",
                    onPress: () => sortHandler("user__email"),
                    icon: sortIconExtractor("user__email", orderBy),
                },
                {
                    data: "Phone #",
                },
            );
        }
        switch (state.values.currentPageIndex) {
            // parents
            case 0:
                return headers.concat([
                    {
                        data: "Status",
                        onPress: () => sortHandler("status"),
                        icon: sortIconExtractor("status", orderBy),
                    },
                ]);
            // pupils
            case 1:
                return headers.concat([
                    {
                        data: "Parent Name",
                        onPress: () =>
                            sortHandler(
                                "primaryParent__user__firstName,primaryParent__user__lastName",
                            ),
                        icon: sortIconExtractor(
                            "primaryParent__user__firstName,primaryParent__user__lastName",
                            orderBy,
                        ),
                    },
                    {
                        data: "School",
                        onPress: () => sortHandler("school__name"),
                        icon: sortIconExtractor("school__name", orderBy),
                    },
                    {
                        data: "School Year",
                        onPress: () => sortHandler("schoolYear"),
                        icon: sortIconExtractor("schoolYear", orderBy),
                    },
                    {
                        data: "Discount",
                        onPress: () => sortHandler("discountCategory"),
                        icon: sortIconExtractor("discountCategory", orderBy),
                    },
                    {
                        data: "Status",
                        onPress: () => sortHandler("status"),
                        icon: sortIconExtractor("status", orderBy),
                    },
                ]);
            case 2:
                return headers.concat([
                    {
                        data: "DBS Status",
                        onPress: () => sortHandler("dbsExpirationDate"),
                        icon: sortIconExtractor("dbsExpirationDate", orderBy),
                    },
                    {
                        data: "Status",
                        onPress: () => sortHandler("status"),
                        icon: sortIconExtractor("status", orderBy),
                    },
                ]);
            default:
                return headers.concat([
                    {
                        data: "Admin Privileges ",
                    },
                ]);
        }
    }, [sortHandler, state.values.currentPageIndex, state.values.orderBy]);

    const flexArray = useMemo(() => {
        switch (state.values.currentPageIndex) {
            // parents
            case 0:
                return [1, 1.5, 2, 1.5, 1];
            // pupils
            case 1:
                return [1, 2, 2, 1.5, 1, 1, 1];
            // teachers
            case 2:
                return [1, 1.5, 2, 1.5, 1.5, 1];
            // staff
            default:
                return [1, 1.5, 2, 1.5, 1];
        }
    }, [state.values.currentPageIndex]);

    const {
        isOpen: accountActionsActionsheetIsOpen,
        onClose: accountActionsActionsheetOnClose,
        onOpen: accountActionsActionsheetOnOpen,
    } = useDisclose();

    const renderHeader = useMemo(() => {
        return (
            <VStack space="6">
                <HStack mb="4" ml="1" space="5">
                    <AccountsIcon color="primary.600" size="xl" />
                    <Heading color="primary.600" fontSize="xl">
                        Accounts
                    </Heading>
                </HStack>
                <TopTabBar
                    bg="primary.100"
                    borderRadius="lg"
                    // reset filters and ordering when changing tab
                    buttonStyle={{
                        bg: "primary.50",
                        alignItems: "center",
                        pt: 2,
                        pb: 2,
                        _hover: { bg: "primary.200" },
                        _pressed: { bg: "primary.300" },
                    }}
                    currentPageIndex={state.values.currentPageIndex}
                    hideBottomBar
                    onChangePage={() => {
                        let orderBy = state.values.orderBy;
                        if (state.values.searchTerm === "") {
                            orderBy = "user__firstName,user__lastName";
                            dispatchState({
                                input: "orderBy",
                                value: orderBy,
                            });
                        }
                        dispatchState({
                            input: "status",
                            value: undefined,
                        });
                        dispatchState({
                            input: "school",
                            value: undefined,
                        });
                        navigation.setParams({
                            status: undefined,
                            school: undefined,
                            orderBy: orderBy,
                        });
                    }}
                    overflow="hidden"
                    pages={["Parents", "Pupils", "Teachers", "Staff"]}
                    selectedButtonStyle={{ bg: "primary.100" }}
                    selectedTextStyle={{ color: "primary.600" }}
                    setCurrentPageIndex={(index) => {
                        dispatchState({
                            input: "currentPageIndex",
                            value: index,
                        });
                        navigation.setParams({ currentPageIndex: index });
                    }}
                    shadow={1}
                    textStyle={{ color: "primary.800" }}
                    width="100%"
                />
                <HStack justifyContent="space-between">
                    <HStack alignItems="center" space="4">
                        <Box width="350px">
                            <SearchBar
                                inputClearHandler={inputClearHandler}
                                inputOnChangeHandler={inputChangeHandler}
                                inputSearchHandler={inputChangeHandler}
                                placeholderText={`Search for a ${accountType.toLowerCase()}`}
                                searchText={state.values.searchTerm}
                                showSearchIcon
                            />
                        </Box>
                        {state.values.currentPageIndex == 1 ? (
                            <Select
                                borderRadius="2xl"
                                color={
                                    state.values.school
                                        ? "surface.900"
                                        : "muted.400"
                                }
                                fontSize="md"
                                onValueChange={(itemValue) => {
                                    const parsedValue =
                                        itemValue !== "ANY"
                                            ? itemValue
                                            : undefined;
                                    dispatchState({
                                        input: "school",
                                        value: parsedValue,
                                    });
                                    navigation.setParams({
                                        school: parsedValue,
                                    });
                                }}
                                placeholder="Select school"
                                py="2.5"
                                selectedValue={state.values.school ?? "ANY"}
                                width="220px"
                            >
                                <Select.Item
                                    key={"ANY"}
                                    actionSheetLabel={"Any school"}
                                    value={"ANY"}
                                />
                                {schools.map((item) => {
                                    return (
                                        <Select.Item
                                            key={item?.node?.name}
                                            actionSheetLabel={
                                                titleCaseConverter(
                                                    item?.node?.name,
                                                ) ?? ""
                                            }
                                            value={item?.node?.name ?? ""}
                                        />
                                    );
                                })}
                            </Select>
                        ) : null}
                        <Select
                            borderRadius="2xl"
                            color={
                                state.values.status
                                    ? "surface.900"
                                    : "muted.400"
                            }
                            fontSize="md"
                            onValueChange={(itemValue) => {
                                const parsedValue =
                                    itemValue !== "ANY" ? itemValue : undefined;
                                dispatchState({
                                    input: "status",
                                    value: parsedValue,
                                });
                                navigation.setParams({
                                    status: parsedValue,
                                });
                            }}
                            placeholder="Select account status"
                            py="2.5"
                            selectedValue={state.values.status ?? "ANY"}
                            width="220px"
                        >
                            <Select.Item
                                key={"ANY"}
                                actionSheetLabel={"Any status"}
                                value={"ANY"}
                            />
                            {ACCOUNT_STATUS.map((item) => {
                                if (
                                    item.value === "WAITING_LIST" ||
                                    item.value === "NEW_ENROLLMENT"
                                    // hide these from user whilst not properly implemented
                                    // && state.values.currentPageIndex > 1
                                ) {
                                    return null;
                                } else {
                                    return (
                                        <Select.Item
                                            key={item.value}
                                            actionSheetLabel={item.label}
                                            startIcon={item.icon}
                                            value={item.value}
                                        />
                                    );
                                }
                            })}
                        </Select>
                        {accountType === "PUPIL" ? (
                            <Select
                                borderRadius="2xl"
                                color={
                                    state.values.discountType
                                        ? "surface.900"
                                        : "muted.400"
                                }
                                fontSize="md"
                                onValueChange={(itemValue) => {
                                    const parsedValue =
                                        itemValue !== "ANY"
                                            ? itemValue
                                            : undefined;
                                    dispatchState({
                                        input: "discountType",
                                        value: parsedValue,
                                    });
                                    navigation.setParams({
                                        discountType: parsedValue,
                                    });
                                }}
                                placeholder="Select discount type"
                                py="2.5"
                                selectedValue={
                                    state.values.discountType ?? "ANY"
                                }
                                width="150px"
                            >
                                <Select.Item
                                    key={"ANY"}
                                    actionSheetLabel={"Any type"}
                                    value={"ANY"}
                                />
                                <Select.Item
                                    key={"DISCOUNTED"}
                                    actionSheetLabel={"Discounted"}
                                    value={"DISCOUNTED"}
                                />
                                <Select.Item
                                    key={"FULL_PRICE"}
                                    actionSheetLabel={"Full Price"}
                                    value={"FULL_PRICE"}
                                />
                            </Select>
                        ) : null}
                        <Button
                            bg="surface.400"
                            colorScheme="surface"
                            leftIcon={<RestartIcon size="5" />}
                            onPress={() =>
                                state.values.refreshHandler?.(
                                    state.values.orderBy,
                                )
                            }
                            p="3"
                        />
                    </HStack>
                    <HStack space="4">
                        <Button
                            _hover={{ bg: "primary.500" }}
                            _pressed={{ bg: "primary.600" }}
                            _text={{ fontSize: "17" }}
                            bg="primary.400"
                            leftIcon={<CopyIcon size="md" />}
                            onPress={state.values.copyDataToClipboard}
                            px="4"
                            shadow={1}
                        >
                            Copy Table to Clipboard
                        </Button>
                        <Button
                            _hover={{ bg: "primary.500" }}
                            _pressed={{ bg: "primary.600" }}
                            _text={{ fontSize: "17" }}
                            bg="primary.400"
                            leftIcon={<AccountsIcon mr="2" size="md" />}
                            onPress={() => accountActionsActionsheetOnOpen()}
                            px="4"
                            shadow={1}
                        >
                            View Account Actions
                        </Button>
                    </HStack>
                </HStack>
            </VStack>
        );
    }, [
        state.values,
        accountType,
        inputChangeHandler,
        inputClearHandler,
        schools,
        navigation,
        accountActionsActionsheetOnOpen,
    ]);

    const renderTableHeaders = useMemo(() => {
        return (
            <Box bg="surface.100">
                <Box
                    bg="primary.50"
                    borderColor={TABLE_BORDER_COLOR}
                    borderTopRadius={TABLE_BORDER_RADIUS}
                    borderWidth={TABLE_BORDER_WIDTH}
                >
                    <Row
                        cellProps={{
                            px: 2,
                            hideTopBorder: true,
                            hideOuterSideBorder: true,
                            textProps: {
                                fontSize: "md",
                                textAlign: "center",
                            },
                        }}
                        data={tableHeaders}
                        flexArray={flexArray}
                        rowHeight={10}
                        rowIndex={0}
                        tableBorderColor={TABLE_BORDER_COLOR}
                        tableBorderWidth={TABLE_BORDER_WIDTH}
                    />
                </Box>
            </Box>
        );
    }, [flexArray, tableHeaders]);

    const renderRefetchIndicator = useMemo(() => {
        return state.values.isRefetching ? (
            <CustomFlatListSpinner top="270" />
        ) : null;
    }, [state.values.isRefetching]);

    const accountModalKey = useRef(0);
    const schoolModalKey = useRef(0);
    const bulkCommunicationModalKey = useRef(0);

    const renderAccountOptionsActionsheet = useMemo(() => {
        const sendBulkCommunicationIsInProgress =
            sendBulkCommunicationInFlight ||
            Boolean(
                user?.profile?.profileGroup?.sendBulkCommunicationProgress !=
                    null &&
                    user?.profile?.profileGroup
                        ?.sendBulkCommunicationProgress >= 0 &&
                    user?.profile?.profileGroup?.sendBulkCommunicationProgress <
                        100,
            );
        return (
            <Actionsheet
                animationType="fade"
                hideDragIndicator
                isOpen={accountActionsActionsheetIsOpen}
                justifyContent="center"
                onClose={accountActionsActionsheetOnClose}
                size="lg"
            >
                <Actionsheet.Content
                    alignItems="center"
                    closeButtonProps={{ top: "5", right: "5" }}
                    justifyContent="center"
                    mx="auto"
                    roundedBottom={12}
                    roundedTop={12}
                    showCloseButton
                    w="40%"
                >
                    <Text
                        alignSelf="center"
                        fontSize="xl"
                        fontWeight="bold"
                        py="4"
                    >
                        Account Actions
                    </Text>
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="7">
                                <AddCircleIcon color="primary.500" size="6" />
                            </Center>
                        }
                        onPress={() => {
                            accountActionsActionsheetOnClose();
                            dispatchState({
                                input: "showCreateAccountModal",
                                value: true,
                            });
                        }}
                    >
                        Create Account
                    </Actionsheet.Item>
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        isDisabled={sendBulkCommunicationIsInProgress}
                        leftIcon={
                            <Center size="7">
                                {sendBulkCommunicationIsInProgress ? (
                                    <Spinner />
                                ) : (
                                    <SendIcon color="primary.400" size="5" />
                                )}
                            </Center>
                        }
                        onPress={() => {
                            accountActionsActionsheetOnClose();
                            dispatchState({
                                input: "showSendBulkCommunicationModal",
                                value: true,
                            });
                        }}
                    >
                        Send Bulk Email/SMS
                    </Actionsheet.Item>
                </Actionsheet.Content>
            </Actionsheet>
        );
    }, [
        accountActionsActionsheetIsOpen,
        accountActionsActionsheetOnClose,
        sendBulkCommunicationInFlight,
        user?.profile?.profileGroup?.sendBulkCommunicationProgress,
    ]);

    const renderCreateAccountModal = useMemo(() => {
        // conditional render due to bug where modal eats clicks despite being closed
        return state.values.showCreateAccountModal ? (
            <CreateAccountModal
                // reset modal state each time it's opened
                key={accountModalKey.current}
                initialAccountType={accountType}
                parentConnections={state.values.parentConnections}
                pupilConnections={state.values.pupilConnections}
                schools={schools}
                setShowModal={(isOpen) => {
                    dispatchState({
                        input: "showCreateAccountModal",
                        value: isOpen,
                    });
                    schoolModalKey.current = Math.random();
                }}
                showModal={state.values.showCreateAccountModal}
                staffConnections={state.values.staffConnections}
                teacherConnections={state.values.teacherConnections}
            />
        ) : null;
    }, [
        accountType,
        schools,
        state.values.parentConnections,
        state.values.pupilConnections,
        state.values.showCreateAccountModal,
        state.values.staffConnections,
        state.values.teacherConnections,
    ]);

    const renderSendBulkCommunicationModal = useMemo(() => {
        return (
            <SendBulkCommunicationModal
                key={bulkCommunicationModalKey.current}
                hideModal={() => {
                    dispatchState({
                        input: "showSendBulkCommunicationModal",
                        value: false,
                    });
                    // reset state on close
                    bulkCommunicationModalKey.current = Math.random();
                }}
                sendBulkCommunication={sendBulkCommunication}
                showModal={state.values.showSendBulkCommunicationModal}
            />
        );
    }, [sendBulkCommunication, state.values.showSendBulkCommunicationModal]);

    return (
        <>
            <Box bg="surface.100" flex={1} pt="70">
                <PresenceTransition
                    animate={{
                        opacity: 1,
                        transition: {
                            delay: 0,
                            duration: 800,
                        },
                    }}
                    initial={{
                        opacity: 0,
                    }}
                    style={{ flex: 1 }}
                    visible={Boolean(user?.profile?.id)}
                >
                    <VStack bg="surface.100" mx="30px" pt="6" space="6">
                        {renderHeader}
                        {renderTableHeaders}
                    </VStack>
                    {loadAccountsQueryReference != null ? (
                        <Suspense
                            fallback={
                                <LoadingBlobs>Loading Accounts...</LoadingBlobs>
                            }
                        >
                            <AccountsHubContent
                                accountType={accountType}
                                dispatchState={dispatchState}
                                flexArray={flexArray}
                                navigation={navigation}
                                queryReference={loadAccountsQueryReference}
                                state={state}
                            />
                        </Suspense>
                    ) : (
                        <LoadingBlobs>Loading Accounts...</LoadingBlobs>
                    )}
                    {renderRefetchIndicator}
                </PresenceTransition>
                {renderAccountOptionsActionsheet}
                {renderCreateAccountModal}
                {renderSendBulkCommunicationModal}
            </Box>
            {loadMutationProgressQueryReference != null ? (
                <AccountMutationProgressIndicators
                    dispatchState={dispatchState}
                    loadMutationProgressQuery={loadMutationProgressQuery}
                    loadMutationProgressQueryReference={
                        loadMutationProgressQueryReference
                    }
                    state={state}
                />
            ) : null}
        </>
    );
};

export const screenOptions = {
    headerTitle: "Accounts",
};

export default AccountsHubScreen;
