import { formatCurrency, formatPercentage } from "./converters";

export const currencyMask = (value: string): string => {
    // prefix with GBP if not already
    if (!value.startsWith("£")) {
        value = "£" + value;
    }

    return value;
};

export const currencyFinishEditMask = (value: string): string => {
    return formatCurrency(value.slice(1));
};

export const percentageMask = (value: string): string => {
    return value.replace(/[^0-9]/g, "");
};

export const percentageFinishEditMask = (value: string): string => {
    if (value != "") {
        return formatPercentage(parseFloat(value.replace("%", "")));
    } else {
        return "";
    }
};
