import React from "react";

import {
    ArchivedIcon,
    BlankIcon,
    BlockIcon,
    CashIcon,
    DoubleTickIcon,
    DraftFilledIcon,
    GroupIcon,
    NewEnrollmentIcon,
    OneOnOneIcon,
    PairedIcon,
    SendIcon,
    TickIcon,
    WaitingListIcon,
} from "../components/Other/Icons";

export const SCHOOL_YEARS = [
    {
        label: "Year 11",
        value: "11",
    },
    {
        label: "Year 10",
        value: "10",
    },
    {
        label: "Year 9",
        value: "9",
    },
    {
        label: "Year 8",
        value: "8",
    },
    {
        label: "Year 7",
        value: "7",
    },
    {
        label: "Year 6",
        value: "6",
    },
    {
        label: "Year 5",
        value: "5",
    },
    {
        label: "Year 4",
        value: "4",
    },
    {
        label: "Year 3",
        value: "3",
    },
    {
        label: "Year 2",
        value: "2",
    },
    {
        label: "Year 1",
        value: "1",
    },
    {
        label: "Reception",
        value: "0",
    },
    {
        label: "Nursery",
        value: "-1",
    },
    {
        label: "Other",
        value: "-2",
    },
];

export const GRADES = [
    {
        label: "No Grade",
        value: "0",
    },
    {
        label: "Grade 1",
        value: "1",
    },
    {
        label: "Grade 2",
        value: "2",
    },
    {
        label: "Grade 3",
        value: "3",
    },
    {
        label: "Grade 4",
        value: "4",
    },
    {
        label: "Grade 5",
        value: "5",
    },
    {
        label: "Grade 6",
        value: "6",
    },
    {
        label: "Grade 7",
        value: "7",
    },
    {
        label: "Grade 8",
        value: "8",
    },
];

export const ACCOUNT_TYPES = [
    { label: "Parent", value: "PARENT" },
    { label: "Pupil", value: "PUPIL" },
    { label: "Teacher", value: "TEACHER" },
    { label: "Staff", value: "STAFF" },
];

export const ACCOUNT_STATUS = [
    {
        label: "Active",
        value: "ACTIVE",
        icon: <TickIcon color="primary.600" ml="1" mr="-2" size="7" />,
    },
    {
        label: "Waiting List",
        value: "WAITING_LIST",
        icon: <WaitingListIcon color="surface.800" ml="1" mr="-2" size="7" />,
    },
    {
        label: "New Enrollment",
        value: "NEW_ENROLLMENT",
        icon: <NewEnrollmentIcon color="primary.400" ml="1" mr="-2" size="7" />,
    },
    {
        label: "Archived",
        value: "ARCHIVED",
        icon: <ArchivedIcon color="surface.600" ml="1" mr="-2" size="6" />,
    },
];

export const REGISTER_STATUS = [
    {
        label: "Present",
        value: "PRESENT",
        icon: <TickIcon color="primary.600" ml="1" mr="-2" size="7" />,
    },
    {
        label: "Waiting List",
        value: "WAITING_LIST",
        icon: <WaitingListIcon color="surface.800" ml="1" mr="-2" size="7" />,
    },
    {
        label: "New Enrollment",
        value: "NEW_ENROLLMENT",
        icon: <NewEnrollmentIcon color="primary.400" ml="1" mr="-2" size="7" />,
    },
    {
        label: "Archived",
        value: "ARCHIVED",
        icon: <ArchivedIcon color="surface.600" ml="1" mr="-2" size="6" />,
    },
];

export const INVOICE_STATUS = [
    {
        label: "Draft",
        value: "DRAFT",
        icon: <DraftFilledIcon color="surface.700" ml="1" mr="-2" size="5" />,
    },
    {
        label: "Sent",
        value: "SENT",
        icon: <SendIcon color="primary.500" ml="1" mr="-2" size="4" />,
    },
    {
        label: "Paid",
        value: "PAID",
        icon: <TickIcon color="primary.600" ml="1" mr="-2" size="6" />,
    },
    {
        label: "Pending",
        value: "PENDING",
        icon: <BlankIcon color="orange.600" ml="1" mr="-2" size="6" />,
    },
    {
        label: "Overpaid",
        value: "OVERPAID",
        icon: <DoubleTickIcon color="primary.700" ml="1" mr="-2" size="6" />,
    },
    {
        label: "Cancelled",
        value: "CANCELLED",
        icon: <BlockIcon color="surface.800" ml="1" mr="-2" size="5" />,
    },
    {
        label: "Refund",
        value: "REFUND",
        icon: <CashIcon color="secondary.600" ml="1" mr="-2" size="6" />,
    },
];

export const TEACHING_DAYS = [
    { label: "Monday", value: "0" },
    { label: "Tuesday", value: "1" },
    { label: "Wednesday", value: "2" },
    { label: "Thursday", value: "3" },
    { label: "Friday", value: "4" },
];

export const BLOCKS = [
    { label: "Autumn 1", value: "1" },
    { label: "Autumn 2", value: "2" },
    { label: "Spring 1", value: "3" },
    { label: "Spring 2", value: "4" },
    { label: "Summer 1", value: "5" },
    { label: "Summer 2", value: "6" },
];

export const DISCOUNT_CATEGORIES = [
    { label: "Full price", value: "FULL_PRICE" },
    { label: "Discounted (PP kid)", value: "DISCOUNT_PUPIL_PPKID" },
    { label: "Discounted (other)", value: "DISCOUNT_PUPIL_OTHER" },
];

export const TERMS = [
    { label: "Autumn Term", value: "1" },
    { label: "Spring Term", value: "2" },
    { label: "Summer Term", value: "3" },
];

export const LESSONS = [
    { label: "Lesson 1", value: "1" },
    { label: "Lesson 2", value: "2" },
    { label: "Lesson 3", value: "3" },
    { label: "Lesson 4", value: "4" },
    { label: "Lesson 5", value: "5" },
    { label: "Lesson 6", value: "6" },
    { label: "Lesson 7", value: "7" },
    { label: "Lesson 8", value: "8" },
    { label: "Lesson 9", value: "9" },
    { label: "Lesson 10", value: "10" },
];

export const INSTRUMENTS = [
    { label: "Piano", value: "Piano" },
    { label: "Guitar", value: "Guitar" },
    { label: "Violin", value: "Violin" },
    { label: "Drums", value: "Drums" },
    { label: "Recorder", value: "Recorder" },
    { label: "Ukelele", value: "Ukelele" },
    { label: "Theory", value: "Theory" },
    { label: "Any Instrument", value: "Any Instrument" },
];

export const NO_STAGE = -1;

export const LESSON_STAGES = [
    { label: "Any Stage", value: "-1" },
    { label: "Stage 0", value: "0" },
    { label: "Stage 1", value: "1" },
    { label: "Stage 2", value: "2" },
    { label: "Stage 3", value: "3" },
];

export const LESSON_TYPES = [
    {
        label: "Individual",
        value: "INDIVIDUAL",
        icon: <OneOnOneIcon color="surface.900" ml="4" />,
    },
    {
        label: "Paired",
        value: "PAIRED",
        icon: <PairedIcon color="surface.900" ml="4" />,
    },
    {
        label: "Group",
        value: "GROUP",
        icon: <GroupIcon color="surface.900" ml="4" />,
    },
];

export const HOUR_TIMETABLE_HEIGHT = 120; // in px

export const MONTHS = [
    { label: "January", value: "0" },
    { label: "February", value: "1" },
    { label: "March", value: "2" },
    { label: "April", value: "3" },
    { label: "May", value: "4" },
    { label: "June", value: "5" },
    { label: "July", value: "6" },
    { label: "August", value: "7" },
    { label: "September", value: "8" },
    { label: "October", value: "9" },
    { label: "November", value: "10" },
    { label: "December", value: "11" },
];

export const PAYSLIP_STATUS = [
    { label: "Not Viewed", value: "NOT_VIEWED" },
    { label: "Ready To Pay", value: "READY_TO_PAY" },
    { label: "Paid", value: "PAID" },
];
