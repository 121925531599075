import React, { useEffect } from "react";
import type { FC } from "react";

import { Box } from "native-base";
import { useQueryLoader } from "react-relay";

import InvoiceAndEmail from "../../components/Invoicing/InvoiceAndEmail";
import type {
    InvoicingStackNavigatorProps,
    InvoicingStackRouteProps,
} from "../../navigation/InvoicingNavigator";

import type { LoadInvoiceQuery } from "pianofunclub-shared/relay/graphql/invoicing/__generated__/LoadInvoiceQuery.graphql";
import { load_invoice } from "pianofunclub-shared/relay/graphql/invoicing/LoadInvoice";

export type NavigationProps = InvoicingStackNavigatorProps<"Invoice">;

export type RouteProps = InvoicingStackRouteProps<"Invoice">;

interface ScreenProps {
    navigation: NavigationProps;
    route: RouteProps;
}

const InvoiceScreen: FC<ScreenProps> = (props) => {
    const [loadInvoiceQueryReference, loadInvoiceQuery] =
        useQueryLoader<LoadInvoiceQuery>(load_invoice);

    useEffect(() => {
        loadInvoiceQuery(
            {
                invoiceId: props.route.params?.invoiceId,
                skip: false,
            },
            { fetchPolicy: "store-or-network" },
        );
    }, [loadInvoiceQuery, props.route.params?.invoiceId]);

    return (
        <Box bg="surface.100" flex={1}>
            <InvoiceAndEmail
                isEditable
                loadInvoiceQuery={loadInvoiceQuery}
                loadInvoiceQueryReference={loadInvoiceQueryReference}
                // @ts-expect-error nav props are merged on component
                navigation={props.navigation}
                previousOtherInvoicesConnectionIds={
                    props.route.params.previousOtherInvoicesConnectionIds
                }
                route={props.route}
            />
        </Box>
    );
};

export const screenOptions = {
    headerTitle: "Invoice",
};

export default InvoiceScreen;
