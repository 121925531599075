/**
 * @generated SignedSource<<6d1f0ec3766b63a1576f68738217a94f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ObtainJSONWebTokenInput = {
  clientMutationId?: string | null;
  email?: string | null;
  password: string;
  username?: string | null;
};
export type TokenAuthMutation$variables = {
  input: ObtainJSONWebTokenInput;
};
export type TokenAuthMutation$data = {
  readonly tokenAuth: {
    readonly errors: any | null;
    readonly refreshToken: string | null;
    readonly success: boolean | null;
    readonly token: string | null;
    readonly user: {
      readonly id: string;
      readonly profile: {
        readonly id: string;
      } | null;
      readonly username: string;
      readonly verified: boolean | null;
    } | null;
  } | null;
};
export type TokenAuthMutation = {
  response: TokenAuthMutation$data;
  variables: TokenAuthMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ObtainJSONWebTokenPayload",
    "kind": "LinkedField",
    "name": "tokenAuth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "refreshToken",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "verified",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TokenAuthMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TokenAuthMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "e3b8392cb726294cfb9c25e60adef4ae",
    "id": null,
    "metadata": {},
    "name": "TokenAuthMutation",
    "operationKind": "mutation",
    "text": "mutation TokenAuthMutation(\n  $input: ObtainJSONWebTokenInput!\n) {\n  tokenAuth(input: $input) {\n    success\n    errors\n    token\n    refreshToken\n    user {\n      id\n      username\n      verified\n      profile {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b74964faef2302dab3901ac14069f1f5";

export default node;
